import React, { useState } from 'react';
import { Grid2, Tab, Tabs, Typography } from '@mui/material';
import { IDashboard } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { capitalizeFirstLetter } from '@vegaplatformui/utils';
import { QuicksightReportLoading } from '../jotai/atom';
import { QuicksightReport } from './quicksight-report';
import { DashboardExperience } from 'amazon-quicksight-embedding-sdk';
import { useAtom } from 'jotai';

export interface IQuicksightReportTabsProps {
    reports: IDashboard[];
    dashboardUrl: string;
    setDashboardExperience: React.Dispatch<React.SetStateAction<DashboardExperience | undefined>>;
}

const QuicksightReportTabs: React.FC<IQuicksightReportTabsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [currentTab, setCurrentTab] = useState(0);
    const [dashboardLoading, setDashboardLoading] = useAtom(QuicksightReportLoading);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
        setDashboardLoading(true);
    };

    return props.reports && props.reports.length > 1 ? (
        <Grid2 container spacing={2} direction={'column'} className={cx(classes.tabsContainer)}>
            <Grid2 size={12}>
                <Tabs
                    variant={'scrollable'}
                    id={'reportTabs'}
                    value={currentTab}
                    onChange={handleTabChange}
                    aria-label='Select the report you want to work with'
                >
                    {props.reports &&
                        props.reports.length > 0 &&
                        props.reports.map((dashboard, index) => (
                            <Tab
                                disabled={dashboardLoading}
                                id={`${dashboard.name}-${index}`}
                                key={index}
                                label={capitalizeFirstLetter(dashboard.name)}
                                value={index}
                            />
                        ))}
                </Tabs>
            </Grid2>
            <Grid2 size={12} className={cx(classes.DashboardGrid)}>
                {props.reports && props.reports.length > 0 ? (
                    <QuicksightReport
                        isTabbed={props.reports.length > 1}
                        dashboardUrl={props.dashboardUrl}
                        dashboardId={props.reports && props.reports.length > 0 ? props.reports[currentTab].dashboardId : ''}
                        setDashboardExperience={props.setDashboardExperience}
                    />
                ) : (
                    <Typography>
                        Sorry, this page isn't ready yet or you might not have permission to view it. Please check back later or contact support if
                        you believe this is an error.
                    </Typography>
                )}
            </Grid2>
        </Grid2>
    ) : props.reports && props.reports.length > 0 ? (
        <QuicksightReport
            isTabbed={props.reports.length > 1}
            dashboardUrl={props.dashboardUrl}
            dashboardId={props.reports && props.reports.length > 0 ? props.reports[currentTab].dashboardId : ''}
            setDashboardExperience={props.setDashboardExperience}
        />
    ) : (
        <Typography className={cx(classes.NoDashboardTypography)}>
            Sorry, this page isn't ready yet or you might not have permission to view it. Please check back later or contact support if you believe
            this is an error.
        </Typography>
    );
};

const useStyles = customMakeStyles<IQuicksightReportTabsProps>()((theme, props) => ({
    tabsContainer: {
        // marginTop: '-2rem',
    },
    NoDashboardTypography: { paddingTop: '1rem', paddingLeft: '1rem' },
    DashboardGrid: { marginTop: '-1rem' },
}));

export { QuicksightReportTabs };
