import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid } from '@mui/material';
import { ILogoVariation } from '@vegaplatformui/models';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CustomFaviconState, CustomPrimaryLogoState, CustomSecondaryLogoState, OrganizationId, SnackBarOptions } from '../../../recoil/atom';
import { ImageUpload } from '../../image-upload';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationLogoInputProps {
    onPhotoRemove: (orgId: string, variation: ILogoVariation) => void;
    onPhotoUpload: (orgId: string, variation: ILogoVariation, file: File) => void;
}

const OrganizationLogoInput: React.FC<IOrganizationLogoInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { onPhotoRemove, onPhotoUpload } = props;
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const organizationId = useRecoilValue(OrganizationId);
    const primaryLogoUrl = useRecoilValue(CustomPrimaryLogoState).url;
    const secondaryLogoUrl = useRecoilValue(CustomSecondaryLogoState).url;
    const faviconUrl = useRecoilValue(CustomFaviconState).url;

    const handleLogoRemoval = (variation: ILogoVariation) => {
        onPhotoRemove(organizationId, variation);
    };

    const fileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/ico', 'image/vnd.microsoft.icon', 'image/x-icon'];
    const validateFileType = (type: string) => {
        return fileTypes.includes(type);
    };

    const validateFileSize = (size: any) => {
        // Restrict max size to 1MB
        return size <= 1000000;
    };

    const handleLogoUpload = (evt: React.ChangeEvent<HTMLInputElement>, variation: ILogoVariation) => {
        if (evt.target.files?.length) {
            const logoFile = evt.target.files[0];
            if (!validateFileType(logoFile.type)) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'The file type is not supported.',
                });
                evt.target.value = '';
            } else if (!validateFileSize(logoFile.size)) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'The file size exceeds the maximum allowed 1MB.',
                });
                evt.target.value = '';
            } else {
                onPhotoUpload(organizationId, variation, logoFile);
            }
        }
    };

    return (
        <Grid container spacing={2} mb={'40px'}>
            <Grid className={cx(classes.ImageGrid)} item md={12} lg={4}>
                <ImageUpload
                    imageSrc={primaryLogoUrl}
                    alt={'Primary Organization Logo'}
                    sizes={{ maxWidth: 200, maxHeight: 200 }}
                    handleUpload={handleLogoUpload}
                    handleRemove={handleLogoRemoval}
                    title={'Primary Logo'}
                    subtitle={'Max dimensions: 200x200'}
                    variation={ILogoVariation.PRIMARY}
                />
            </Grid>
            <Grid className={cx(classes.ImageGrid)} item md={12} lg={4}>
                <ImageUpload
                    imageSrc={secondaryLogoUrl}
                    alt={'Secondary Organization Logo'}
                    sizes={{ maxWidth: 50, maxHeight: 50 }}
                    handleUpload={handleLogoUpload}
                    handleRemove={handleLogoRemoval}
                    title={'Secondary Logo'}
                    subtitle={'Max dimensions: 50x50'}
                    variation={ILogoVariation.SECONDARY}
                />
            </Grid>
            <Grid className={cx(classes.ImageGrid)} item md={12} lg={4}>
                <ImageUpload
                    imageSrc={faviconUrl}
                    alt={'Organization Favicon'}
                    sizes={{ maxWidth: 16, maxHeight: 16 }}
                    handleUpload={handleLogoUpload}
                    handleRemove={handleLogoRemoval}
                    title={'Favicon'}
                    subtitle={'Max dimensions: 16x16'}
                    variation={ILogoVariation.FAVICON}
                ></ImageUpload>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IOrganizationLogoInputProps>()((theme, props) => ({
    ImageGrid: { marginBottom: '10px' },
}));

export { OrganizationLogoInput };
