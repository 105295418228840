import React from 'react';
import { IAssignmentRule, IAssignmentRuleFormValue } from '@vegaplatformui/models';
import {
    BusinessGroupingsAssignmentRulesCard,
    useBusinessGroupingsApi,
    useRulesEnumsApi,
    useRulesApi,
    tableIdentifierKeys,
} from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingsAssignmentRulesControllerProps {}

const BusinessGroupingsAssignmentRulesController: React.FC<IBusinessGroupingsAssignmentRulesControllerProps> = (props) => {
    const { enumValues, isLoading } = useRulesEnumsApi<IAssignmentRuleFormValue>({ url: '/rules/ui/enums?name=form_fields' });
    const { validParentBusinessGroupings, isValidParentBusinessGroupingsLoading, myBusinessUnitTree } = useBusinessGroupingsApi({});

    const {
        assignmentRules,
        isAssignmentRulesLoading,
        onPostAssignmentRule,
        isAssignmentRulePosting,
        onPutAssignmentRule,
        isAssignmentRulePutting,
        onDeleteAssignmentRule,
        isAssignmentRuleDeleting,
        setIsAssignmentRulesDrawerOpen,
        isAssignmentRulesDrawerOpen,
        assignmentRuleToEdit,
        setAssignmentRuleToEdit,
        isAssignmentRulesLoadingPlaceholderData,
        isAssignmentRulesApplying,
        applyAssignmentRules,
        getApplyAssignmentRulesData,
    } = useRulesApi({});

    const onClickAddAssignmentRule = () => {
        setIsAssignmentRulesDrawerOpen(true);
    };

    const onClickViewAssignmentRule = (assignmentRule: IAssignmentRule) => {
        setAssignmentRuleToEdit(assignmentRule);
        setIsAssignmentRulesDrawerOpen(true);
    };

    const onClickSaveAssignmentRule = (assignmentRule: IAssignmentRule, isEdit: boolean) => {
        isEdit ? onPutAssignmentRule(assignmentRule) : onPostAssignmentRule(assignmentRule);
    };

    return (
        <BusinessGroupingsAssignmentRulesCard
            isAssignmentRuleSaving={isAssignmentRulePosting || isAssignmentRulePutting}
            applyAssignmentRulesStatus={getApplyAssignmentRulesData ?? ''}
            isAssignmentRuleLoading={isAssignmentRulesLoading || isAssignmentRulesLoadingPlaceholderData}
            assignmentRuleToEdit={assignmentRuleToEdit}
            onDeleteAssignmentRule={onDeleteAssignmentRule}
            onClickSaveAssignmentRule={onClickSaveAssignmentRule}
            isLoading={isLoading || isValidParentBusinessGroupingsLoading}
            businessGroupings={validParentBusinessGroupings ?? []}
            businessGroupingsTree={myBusinessUnitTree ?? []}
            assignmentRuleFormValues={enumValues ?? []}
            onCloseAssignmentRulesDrawer={() => setIsAssignmentRulesDrawerOpen(false)}
            onClickAddAssignmentRule={onClickAddAssignmentRule}
            onClickViewAssignmentRule={onClickViewAssignmentRule}
            isAssignmentRulesDrawerOpen={isAssignmentRulesDrawerOpen}
            assignmentRules={assignmentRules ?? []}
            assignmentRulesTableIdentifier={tableIdentifierKeys.businessGroupingAssignmentRulesTable}
            applyAssignmentRules={applyAssignmentRules}
        />
    );
};

export { BusinessGroupingsAssignmentRulesController };
