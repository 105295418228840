import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { IBusinessGroupingAddUsersForm, IBusinessGroupingDetailsForm, IUser } from '@vegaplatformui/models';
import { Control, Controller, useForm, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { FormField } from '../../../../forms/form-field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardUsersAddDialogProps {
    isAddUsersDialogOpen: boolean;
    onCloseDialog: () => void;
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
    control: Control<IBusinessGroupingDetailsForm>;
    watch: UseFormWatch<IBusinessGroupingDetailsForm>;
    trigger: UseFormTrigger<IBusinessGroupingDetailsForm>;

    users: IUser[];
}
const validationSchema: yup.ObjectSchema<IBusinessGroupingAddUsersForm> = yup.object().shape({
    business_grouping_users: yup.array<IUser[]>().min(1, 'At least one user must be selected').required('Users are required').label('Users'),
});
const BusinessGroupingWizardAddUsersDialog: React.FC<IBusinessGroupingWizardUsersAddDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const theme = useTheme();

    const {
        watch,
        register,
        handleSubmit,
        control,
        reset,
        trigger,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields },
    } = useForm<IBusinessGroupingAddUsersForm>({
        reValidateMode: 'onBlur',
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            business_grouping_users: [],
        },
    });

    const onCloseDialog = () => {
        reset({ business_grouping_users: [] });
        props.onCloseDialog();
    };

    return (
        <Dialog
            id={'add-users-to-assigned-users-form'}
            onSubmit={handleSubmit((data) => {})}
            open={props.isAddUsersDialogOpen}
            onClose={onCloseDialog}
            fullWidth
            maxWidth={'sm'}
        >
            <DialogTitle variant={'h6'} id='business-grouping-add-users-dialog'>
                <Stack>
                    Assign users to business grouping
                    <Typography variant={'caption'}>You must click the 'Save Business Grouping' button to apply changes.</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <>
                    <FormField label='Users' htmlFor='business_grouping_users'>
                        <Controller
                            name={'business_grouping_users'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    multiple
                                    value={value}
                                    disableCloseOnSelect
                                    size={'small'}
                                    limitTags={4}
                                    options={props.users}
                                    getOptionLabel={(user) => `${user.email + ' - ' + user.given_name + ' ' + user.family_name}`}
                                    fullWidth={true}
                                    onChange={(event: any, users: IUser[] | null, reason) => {
                                        switch (reason) {
                                            case 'clear': {
                                                return onChange(users ?? []);
                                            }
                                            default:
                                                return onChange(users ?? []);
                                        }
                                    }}
                                    getOptionDisabled={(option: IUser) =>
                                        control._formValues.business_grouping_owner && option.id === control._formValues.business_grouping_owner.id
                                    }
                                    renderOption={(props, user: IUser, { selected }) => (
                                        <li {...props} key={user.id}>
                                            <ListItemIcon>
                                                <Checkbox checked={value?.find((u) => u.id === user.id) ? true : false} tabIndex={-1} />
                                            </ListItemIcon>
                                            <Tooltip title={`${user.given_name} ${user.family_name} - ${user.email}`}>
                                                <ListItemText
                                                    primary={`${user.given_name} ${user.family_name}`}
                                                    primaryTypographyProps={{ noWrap: true }}
                                                    secondary={user.email}
                                                    secondaryTypographyProps={{ noWrap: true }}
                                                />
                                            </Tooltip>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={'Assign users'}
                                            error={!!errors.business_grouping_users}
                                            helperText={errors.business_grouping_users?.message as string}
                                        />
                                    )}
                                    renderTags={(users, getTagProps) =>
                                        users.map((user, index) => (
                                            <Chip
                                                variant='outlined'
                                                label={user.email}
                                                size='small'
                                                {...getTagProps({ index })}
                                                disabled={
                                                    control._formValues.business_grouping_owner &&
                                                    user.id === control._formValues.business_grouping_owner.id
                                                }
                                            />
                                        ))
                                    }
                                />
                            )}
                        />
                    </FormField>
                </>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} disableElevation={true} color={'secondary'} autoFocus onClick={onCloseDialog}>
                    Cancel
                </Button>
                <Button
                    disableElevation={true}
                    type={'button'}
                    variant={'contained'}
                    onClick={(e) => {
                        e.preventDefault();
                        const currentUsers = props.watch('business_grouping_users');
                        const newUsers = watch('business_grouping_users');
                        trigger('business_grouping_users')
                            .then((result) => {
                                if (newUsers.length > 0 && result) {
                                    props.setValue('business_grouping_users', [...new Set([...currentUsers, ...newUsers])], {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                    props.trigger().then(() => onCloseDialog());
                                }
                            })
                            .finally(() => reset({}, { keepErrors: true }));
                    }}
                >
                    Assign Users
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardUsersAddDialogProps>()((theme, props) => ({}));

export { BusinessGroupingWizardAddUsersDialog };
