import { useKeycloak } from '@react-keycloak-fork/web';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    BusinessGroupingLanding,
    ExpandedBusinessGroupTreeNodes,
    queryKeys,
    tableIdentifierKeys,
    useBusinessGroupingsApi,
    useTableUtilities,
    useUsersApi,
} from '@vegaplatformui/sharedcomponents';
import { IBusinessGrouping, IUser, IBusinessGroupTreeItem } from '@vegaplatformui/models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRouteUrls } from '@vegaplatformui/utils';
import { useQueryClient } from '@tanstack/react-query';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingsControllerProps {}

const BusinessGroupingsController: React.FC<IBusinessGroupingsControllerProps> = (props) => {
    const { keycloak } = useKeycloak();
    const queryClient = useQueryClient();
    // setIsLoading is never used??
    const [isLoading, setIsLoading] = useState(true);
    const [, setBusinessGroupingUsers] = useState<IUser[]>([]);
    const [selectedBusinessGroupings, setSelectedBusinessGroupings] = useState<IBusinessGrouping[]>([]);
    const [groupingToEdit, setGroupingToEdit] = useState<IBusinessGrouping | undefined>(undefined);
    const [selectedBusinessGroupingType, setSelectedBusinessGroupingType] = useState<number>(groupingToEdit?.type ?? 1);
    const [businessGroupingDrawerOpen, setBusinessGroupingDrawerOpen] = useState<boolean>(false);
    const businessGroupingsTableUtilities = useTableUtilities(tableIdentifierKeys.businessGroupingsTable);
    const navigate = useNavigate();
    const [expandedNodes, setExpandedNodes] = useRecoilState<string[]>(ExpandedBusinessGroupTreeNodes);
    const usersApi = useUsersApi({});
    const businessGroupingApi = useBusinessGroupingsApi({});
    const { routeUrls } = useRouteUrls({});

    useEffect(() => {
        return () => {
            window.location.pathname.split('/')[1] !== routeUrls.businessGroupings.path && setExpandedNodes && setExpandedNodes([]);
        };
    }, [window.location]);

    const onCloseDrawer = () => {
        setBusinessGroupingDrawerOpen(false);
        setTimeout(() => {
            setBusinessGroupingUsers([]);
            setGroupingToEdit(undefined);
            businessGroupingApi.setBusinessGroupToEdit(undefined);
            setSelectedBusinessGroupingType(1);
        }, 250);
    };

    useEffect(() => {
        if (businessGroupingApi.isBusinessGroupingEditSuccessful || businessGroupingApi.isBusinessGroupingCreateSuccessful) {
            onCloseDrawer();
        }
    }, [businessGroupingApi.isBusinessGroupingEditSuccessful, businessGroupingApi.isBusinessGroupingCreateSuccessful]);

    const loadValidParents = (bg_id?: string) => {
        businessGroupingApi
            .refetchValidParentBusinessGroupings()
            .then(() =>
                queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] }).then(() => setBusinessGroupingDrawerOpen(true))
            );
    };

    const loadBusinessGroupings = (page: number, size: number, filter: string, ordering: string, fields: string) => {
        businessGroupingApi.refetchBusinessGroupings();
    };

    const loadInitialBusinessGroupings = () => {
        businessGroupingApi.refetchInitialBusinessGroupings();
    };

    useEffect(() => {
        loadBusinessGroupings(0, 100, '', 'id', '*');
        getBusinessGroupingTypes();
        loadInitialBusinessGroupings();
    }, [keycloak.token]);

    const onClickOpenCreateBusinessGroupingDialog = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
        navigate(`create`);
    };

    const onClickOpenEditBusinessGroupingDialog = (grouping: IBusinessGroupTreeItem) => {
        setSelectedBusinessGroupingType(grouping.type);
        const bgToEdit =
            businessGroupingApi.businessGroupings &&
            businessGroupingApi.businessGroupings
                .filter((group) => grouping.id === group.id)
                .map((group: IBusinessGrouping) => {
                    return {
                        ...group,
                        owned_by:
                            usersApi.usersV2 && usersApi.usersV2.find((user) => user.email === grouping.owner)
                                ? usersApi.usersV2.find((user) => user.email === grouping.owner)!.id
                                : '',
                        parent_id: grouping.parent_id,
                        users: Object.values(group.users.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})),
                    } as IBusinessGrouping;
                })
                .find((group) => group.id === grouping.id);
        setGroupingToEdit(bgToEdit);
        businessGroupingApi.setBusinessGroupToEdit(bgToEdit);
        setBusinessGroupingUsers(bgToEdit?.users ?? []);
        loadValidParents(grouping.id);
    };

    const getBusinessGroupingTypes = () => {
        businessGroupingApi.refetchBusinessGroupingTypes();
    };

    return (
        <BusinessGroupingLanding
            usersBusinessGroupings={businessGroupingApi.validParentBusinessGroupings ?? []}
            businessGroupingTypes={businessGroupingApi.businessGroupingTypes ?? []}
            selectedGroupings={selectedBusinessGroupings}
            onClickDeleteSelectedGroupings={() => {}}
            onClickOpenCreateBusinessGroupingDialog={onClickOpenCreateBusinessGroupingDialog}
            onClickOpenEditBusinessGroupingDialog={onClickOpenEditBusinessGroupingDialog}
            isLoading={isLoading}
            onClickEditBusinessGrouping={onClickOpenEditBusinessGroupingDialog}
            onClickDeleteBusinessGrouping={() => {}}
            setSelectedBusinessGrouping={setSelectedBusinessGroupings}
            initialBusinessGroups={businessGroupingApi.initialBusinessGroupings ?? []}
        />
    );
};
const useStyles = customMakeStyles<IBusinessGroupingsControllerProps>()((theme, props) => ({}));

export { BusinessGroupingsController };
