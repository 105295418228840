import { GeminiResponse } from '@vegaplatformui/utils';
import { IDashboard, ListDashboardsRequest } from '@vegaplatformui/models';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';

export class ReportsApi extends HttpClient {
    protected static classInstance?: ReportsApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        // super(`https://${process.env.NX_REPORTS_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ReportsApi();
        }

        return this.classInstance;
    }

    public postEmbeddedDashboardURL = (postEmbeddedDashboardURLRequest: { dashboardId: string }): GeminiResponse<string> =>
        this.instance.post(`reporting/GetEmbeddedDashboardUrl?dashboardId=${postEmbeddedDashboardURLRequest.dashboardId}`);

    public postEmbeddedConsoleURL = (postEmbeddedConsoleURLRequest: { initialConsolePath: string }): GeminiResponse<string> =>
        this.instance.post(`reporting/GetEmbeddedConsoleUrl?initialConsolePath=${postEmbeddedConsoleURLRequest.initialConsolePath}`);
    public listDashboards = (listDashboardsRequest: ListDashboardsRequest): GeminiResponse<IDashboard[]> =>
        this.instance.post('reporting/ListDashboards', { folderNames: listDashboardsRequest.folderNames, tags: listDashboardsRequest.tags });
    // public postEmbeddedDashboardURL = (postEmbeddedDashboardURLRequest: { dashboardId: string }): GeminiResponse<string> =>
    //     this.instance.post(`/GetEmbeddedDashboardUrl?dashboardId=${postEmbeddedDashboardURLRequest.dashboardId}`);
    //
    // public postEmbeddedConsoleURL = (postEmbeddedConsoleURLRequest: { initialConsolePath: string }): GeminiResponse<string> =>
    //     this.instance.post(`/GetEmbeddedConsoleUrl?initialConsolePath=${postEmbeddedConsoleURLRequest.initialConsolePath}`);
    // public listDashboards = (listDashboardsRequest: ListDashboardsRequest): GeminiResponse<IDashboard[]> =>
    //     this.instance.post('/ListDashboards', { folderNames: listDashboardsRequest.folderNames, tags: listDashboardsRequest.tags });
}
