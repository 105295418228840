import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import {
    Inventory,
    Settings,
    Error,
    MilitaryTechSharp,
    FilePresent,
    LocalOffer,
    CalendarMonthRounded,
    DataUsageRounded,
    NavigationRounded,
    TrendingUpRounded,
    ListRounded,
    Analytics,
    MonetizationOn,
    DashboardCustomizeRounded,
    NetworkCheckRounded,
    DashboardRounded,
    ViewComfyRounded,
} from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { useRouteUrls } from './use-route-urls';
import { CloudProvidersTypes } from '@vegaplatformui/models';
import { capitalizeFirstLetter } from './utils';

export interface IUseMenuItemsHook {
    menuItems: GeminiMenuItem[];
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseMenuItemsProps {}

export function useMenuItems(props: IUseMenuItemsProps): IUseMenuItemsHook {
    const { routeUrls } = useRouteUrls({});

    const emptyIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string } = () => null;
    emptyIcon.muiName = 'SvgIcon';

    const menuItems: GeminiMenuItem[] = [
        /**
         * Inform
         */
        // {
        //     icon: DashboardCustomize,
        //     title: 'Dashboard',
        //     route: routeUrls.dashboard,
        //     isSelected: false,
        //     heading: 'Inform',
        // },
        //{ title: 'Cloud Hero', route: routeUrls.cloudHeroSummaries, icon: MilitaryTechSharp, isSelected: false, isHeaderMenuItem: true },
        {
            title: 'Executive KPIs',
            route: routeUrls.executivekpis.path,
            icon: DataUsageRounded,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.executivekpis.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Navigator',
            route: routeUrls.navigator.path,
            icon: NavigationRounded,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.navigator.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Anomalies',
            route: routeUrls.anomalies.path,
            icon: Error,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.anomalies.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Forecasting',
            route: routeUrls.forecasting.path,
            icon: TrendingUpRounded,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.forecasting.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Datametry',
            route: routeUrls.datametry.path,
            icon: Analytics,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.datametry.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Custom Dashboards',
            route: routeUrls.customDashboards.path,
            icon: DashboardRounded,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.customDashboards.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Finance',
            route: routeUrls.finance.path,
            icon: MonetizationOn,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.finance.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Dashboard Author',
            route: routeUrls.dashboardAuthor.path,
            icon: DashboardCustomizeRounded,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.dashboardAuthor.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Tag Compliance',
            route: routeUrls.tagCompliance.path,
            icon: LocalOffer,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.tagCompliance.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        // { title: 'vScore', route: routeUrls.vScore, icon: Scoreboard, isSelected: false, heading: 'Inform' },
        {
            title: 'Organize',
            route: routeUrls.organize.path,
            icon: Inventory,
            isSelected: false,
            heading: 'Inform',
            enabled: routeUrls.organize.isEnabled,
            subMenu: [
                {
                    title: 'Spaces',
                    route: routeUrls.spaces.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.spaces.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                },

                {
                    title: 'Business Groupings',
                    route: routeUrls.businessGroupings.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.businessGroupings.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                },

                // { title: 'Cloud Accounts', route: routeUrls.cloudAccounts, icon: emptyIcon, isSelected: false },
                {
                    title: 'Resources',
                    route: routeUrls.resources.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.resources.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                },

                /*                    { title: 'Workloads', route: routeUrls.workloads, icon: AutoAwesomeMotion, isSelected: false },
       { title: 'Resource Pools', route: routeUrls.resourcePools, icon: GroupWork, isSelected: false },
      { title: 'VLabels', route: routeUrls.vLabels, icon: Label, isSelected: false },*/
            ],
        },
        // { title: 'Tag Manager', route: routeUrls.tagManager, icon: Style, isSelected: false, heading: 'Inform' },
        /**
         * Optimize
         */
        {
            title: 'Recommendations',
            route: routeUrls.recommendations.path,
            icon: ListRounded,
            isSelected: false,
            heading: 'Optimize',
            enabled: routeUrls.recommendations.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Committed Use',
            route: routeUrls.committedUse.path,
            icon: NetworkCheckRounded,
            isSelected: false,
            heading: 'Optimize',
            documentationLink: 'https://docs.vegacloud.io',
            enabled: routeUrls.committedUse.isEnabled,
            subMenu: [
                {
                    title: CloudProvidersTypes.Aws.toUpperCase(),
                    route: routeUrls.committedUseAws.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.committedUseAws.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                },
                {
                    title: capitalizeFirstLetter(CloudProvidersTypes.Azure),
                    route: routeUrls.committedUseAzure.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.committedUseAzure.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                },
                {
                    title: CloudProvidersTypes.Gcp.toUpperCase(),
                    route: routeUrls.committedUseGcp.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.committedUseGcp.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                },
            ],
        },
        {
            icon: CalendarMonthRounded,
            title: 'Scheduler',
            route: routeUrls.parking.path,
            isSelected: false,
            heading: 'Operate',
            enabled: routeUrls.parking.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        /*
    { title: 'Take Action', route: routeUrls.takeAction, icon: SwitchAccessShortcut, isSelected: false, heading: 'vOperate' },
*/

        {
            title: 'Settings',
            route: routeUrls.settings.route.path,
            icon: Settings,
            isSelected: false,
            isFooterMenuItem: true,
            enabled: routeUrls.settings.route.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Shared Report Viewer',
            route: routeUrls.sharedReport.path,
            icon: ViewComfyRounded,
            isSelected: false,
            isFooterMenuItem: true,
            enabled: routeUrls.sharedReport.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
        {
            title: 'Transfer Files',
            route: routeUrls.myFiles.path,
            icon: FilePresent,
            isSelected: false,
            isFooterMenuItem: true,
            enabled: routeUrls.myFiles.isEnabled,
            subMenu: [
                {
                    title: 'From Vega',
                    route: routeUrls.fileDownloads.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.fileDownloads.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                    /*trailingIcon: Download*/
                },
                {
                    title: 'To Vega',
                    route: routeUrls.fileTransfer.path,
                    icon: emptyIcon,
                    isSelected: false,
                    enabled: routeUrls.fileTransfer.isEnabled,
                    documentationLink: 'https://docs.vegacloud.io',
                    /*trailingIcon: Upload*/
                },
            ],
        },
        {
            title: 'Cloud Hero',
            route: routeUrls.cloudHeroSummaries.path,
            icon: MilitaryTechSharp,
            isSelected: false,
            isFooterMenuItem: true,
            enabled: routeUrls.cloudHeroSummaries.isEnabled,
            documentationLink: 'https://docs.vegacloud.io',
        },
    ];

    return { menuItems };
}
