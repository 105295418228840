import {
    IDeleteOrganizationIntegrationRequest,
    IOrganizationNotificationConfiguration,
    IPostOrganizationIntegrationRequest,
    IPutOrganizationIntegrationRequest,
    OrganizationNotificationLoadingType,
} from '@vegaplatformui/models';
import { useKeycloak } from '@react-keycloak-fork/web';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SnackBarOptions } from '../../jotai/atom';
import { queryKeys } from '../query-keys';
import { SnackbarErrorOutput } from '../../utilities/snackbar-error-output';
import { useMemo, useState } from 'react';
import { NotificationsAPI } from '@vegaplatformui/apis';
import { useSetAtom } from 'jotai';
import { StringCapitalizeAndSpace } from '../../utilities/string-formatter';

export interface IUseOrganizationNotificationSettingsApiHook {
    organizationNotificationConfigurations: IOrganizationNotificationConfiguration[];
    areWebhooksLoading: boolean;
    deletingLoadingStates: Record<string, boolean>;
    submittingLoadingStates: Record<string, boolean>;
    updateWebhook: (hookRequest: IPutOrganizationIntegrationRequest) => void;
    createWebhook: (hookRequest: IPostOrganizationIntegrationRequest) => void;
    deleteWebhook: (hookRequest: IDeleteOrganizationIntegrationRequest) => void;
}

export interface IUseOrganizationNotificationSettingsApiProps {}

export function useOrganizationNotificationSettingsApi(
    props: IUseOrganizationNotificationSettingsApiProps
): IUseOrganizationNotificationSettingsApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const queryClient = useQueryClient();
    const flags = useFlags();
    const [deletingLoadingStates, setDeletingLoadingStates] = useState<Record<string, boolean>>({});
    const [submittingLoadingStates, setSubmittingLoadingStates] = useState<Record<string, boolean>>({});

    const setLoading = (type: OrganizationNotificationLoadingType, id: string, isLoading: boolean) => {
        if (type === OrganizationNotificationLoadingType.Submit) {
            setSubmittingLoadingStates((prevState) => ({
                ...prevState,
                [id]: isLoading,
            }));
        } else if (type === OrganizationNotificationLoadingType.Delete) {
            setDeletingLoadingStates((prevState) => ({
                ...prevState,
                [id]: isLoading,
            }));
        }
    };

    const notificationsApi = useMemo(() => {
        const apiInstance = new NotificationsAPI();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const { data: notificationChannels, isLoading: isNotificationChannelsLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getNotificationChannels],
        queryFn: async ({ signal }) => {
            const response = await notificationsApi.getNotificationChannels(signal);
            if (response.data.data === null) return [];
            return response.data.data;
        },
        placeholderData: keepPreviousData,
        enabled: flags.notificationService && window.location.pathname.includes('notification'),
        meta: {
            errorMessage: 'Unable to get user notification channels',
        },
    });

    const { data: organizationNotificationConfigurations, isLoading: areWebhooksLoading } = useQuery({
        queryKey: [queryKeys.notificationSettings.getWebhooks, notificationChannels],
        queryFn: async ({ signal }) => {
            const response = await notificationsApi.getOrganizationNotificationConfigurations(signal);
            return !!response.data.configurations && response.data.configurations.length > 0
                ? response.data.configurations.map((config) => {
                      const channel = notificationChannels?.find((channel) => channel.id === config.notification_channel_id);
                      return {
                          ...config,
                          notification_channel: channel?.name,
                      } as IOrganizationNotificationConfiguration;
                  })
                : [];
        },
        placeholderData: keepPreviousData,
        enabled: flags.notificationService,
        meta: {
            errorMessage: `Unable to get organization's webhook url`,
        },
    });

    const { mutate: createWebhook } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem saving the ${StringCapitalizeAndSpace(variables.notification_channel)} webhook:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationSettings.getWebhooks, notificationChannels], organizationNotificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `${StringCapitalizeAndSpace(variables.notification_channel)} webhook has been saved`,
            });
            queryClient.invalidateQueries({ queryKey: [queryKeys.notificationSettings.getWebhooks] });
        },
        onSettled: (data, error, variables, context) => {
            setLoading(OrganizationNotificationLoadingType.Submit, variables.notification_channel, false);
        },
        mutationFn: async (hookRequest: IPostOrganizationIntegrationRequest) => {
            if (flags.notificationService) {
                setLoading(OrganizationNotificationLoadingType.Submit, hookRequest.notification_channel, true);
                return await notificationsApi.postOrganizationNotificationConfiguration(hookRequest);
            }
        },
    });

    const { mutate: updateWebhook } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem saving the ${StringCapitalizeAndSpace(variables.notification_channel)} webhook:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationSettings.getWebhooks, notificationChannels], organizationNotificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `${StringCapitalizeAndSpace(variables.notification_channel)} webhook has been saved`,
            });

            queryClient.invalidateQueries({ queryKey: [queryKeys.notificationSettings.getWebhooks] });
        },
        onSettled: (data, error, variables, context) => {
            setLoading(OrganizationNotificationLoadingType.Submit, variables.notification_channel, false);
        },
        mutationFn: async (hookRequest: IPutOrganizationIntegrationRequest) => {
            if (flags.notificationService) {
                setLoading(OrganizationNotificationLoadingType.Submit, hookRequest.notification_channel, true);
                return await notificationsApi.putOrganizationNotificationConfiguration(hookRequest);
            }
        },
    });

    const { mutate: deleteWebhook } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting the ${StringCapitalizeAndSpace(variables.notification_channel)} webhook:  ${SnackbarErrorOutput(error ?? '')}`,
            });
            queryClient.setQueryData([queryKeys.notificationSettings.getWebhooks, notificationChannels], organizationNotificationConfigurations);
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `${StringCapitalizeAndSpace(variables.notification_channel)} webhook has been deleted`,
            });
            if (organizationNotificationConfigurations) {
                queryClient.setQueryData(
                    [queryKeys.notificationSettings.getWebhooks, notificationChannels],
                    organizationNotificationConfigurations.filter((config) => config.id !== variables.id)
                );
            } else {
                queryClient.invalidateQueries({ queryKey: [queryKeys.notificationSettings.getWebhooks] });
            }
        },
        onSettled: (data, error, variables, context) => {
            setLoading(OrganizationNotificationLoadingType.Delete, variables.notification_channel, false);
        },
        mutationFn: async (hookRequest: IDeleteOrganizationIntegrationRequest) => {
            if (flags.notificationService) {
                setLoading(OrganizationNotificationLoadingType.Delete, hookRequest.notification_channel, true);
                return await notificationsApi.deleteOrganizationNotificationConfigurtaion(hookRequest.id);
            }
        },
    });

    return {
        areWebhooksLoading: areWebhooksLoading || isNotificationChannelsLoading,
        submittingLoadingStates,
        deletingLoadingStates,
        organizationNotificationConfigurations: organizationNotificationConfigurations ?? [],
        updateWebhook,
        createWebhook,
        deleteWebhook,
    };
}
