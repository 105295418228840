import { PaletteMode } from '@mui/material';
import { atom } from 'jotai';
import { IVegaPageTitle } from '@vegaplatformui/sharedassets';
import { IVegaContainer } from '@vegaplatformui/models';

export const menuExpandState = atom<boolean>(true);

export const isLoading = atom<boolean>(false);

export const pageWrapperMargin = atom<string>('');

export const authenticationState = atom<boolean>(false);

export const spacesState = atom<IVegaContainer[]>([]);

export const vegaPageTitleState = atom<IVegaPageTitle>({
    title: 'Efficient Cloud Management Services | Vegacloud | United States',
    subTitle: '',
});
