import { HttpClient } from './http-common';
import { IUser, IPostUserV2Request, IPutUserV2AddressRequest, IPutUserV2DetailsRequest } from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';

export class UsersApiV2 extends HttpClient {
    protected static classInstance?: UsersApiV2;
    public baseURL!: string;
    public keycloak!: Keycloak;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new UsersApiV2();
        }

        return this.classInstance;
    }

    public getUserV2 = (user_id: string, signal: AbortSignal): GeminiResponse<IUser> => this.instance.get(`vegaapi/users/v2/${user_id}`, { signal });
    public getUsersV2 = (signal: AbortSignal): GeminiResponse<IUser[]> => this.instance.get(`vegaapi/users/v2/`, { signal });
    public postUserV2 = (request: IPostUserV2Request): GeminiResponse<IUser> => this.instance.post(`vegaapi/users/v2/standard/`, request);
    public putUserV2 = (request: IPutUserV2AddressRequest | IPutUserV2DetailsRequest, user_id: string): GeminiResponse<IUser> =>
        this.instance.put(`vegaapi/users/v2/${user_id}`, request);
    public deleteUserV2 = (user_id: string): GeminiResponse<any> => this.instance.delete(`vegaapi/users/v2/${user_id}`);
}
