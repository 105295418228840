import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps, Stack, Typography } from '@mui/material';

import { ICloudProviderAccount } from '@vegaplatformui/models';
import Draggable from 'react-draggable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmDeleteCloudAccountProps {
    accountToDelete?: ICloudProviderAccount;
    isConfirmDeleteDialogOpen: boolean;
    onCloseConfirmDeleteDialog: () => void;
    confirmDeleteAccount: (account: ICloudProviderAccount) => void;
}

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle='#confirm-delete-account-dialog' cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

const ConfirmDeleteCloudAccountDialog: React.FC<IConfirmDeleteCloudAccountProps> = (props) => {
    const { cx, classes } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog
            fullWidth
            open={props.isConfirmDeleteDialogOpen}
            onClose={props.onCloseConfirmDeleteDialog}
            PaperComponent={PaperComponent}
            aria-labelledby='confirm-create-account-dialog'
        >
            <DialogTitle
                className={cx(commonStyles.classes.FormTitlePadding, commonStyles.classes.FormTitle)}
                style={{ cursor: 'move' }}
                id='confirm-delete-account-dialog'
            >
                <Typography className={cx(classes.DeleteTitle)} fontWeight={'600'} variant={'h6'}>
                    Are you sure you want to delete {props.accountToDelete?.canonical_name ?? props.accountToDelete?.account_id}?
                </Typography>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Typography variant={'subtitle1'}> You cannot undo this action.</Typography>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack height={'100%'} direction={'row'} spacing={1.5}>
                    <Button
                        variant={'cancel'}
                        className={cx(commonStyles.classes.LowercaseTextButton)}
                        disableElevation={true}
                        color={'secondary'}
                        autoFocus
                        onClick={props.onCloseConfirmDeleteDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={cx(commonStyles.classes.LowercaseTextButton)}
                        color={'error'}
                        variant={'contained'}
                        onClick={() => {
                            props.confirmDeleteAccount(props.accountToDelete!);
                        }}
                        disableElevation={true}
                    >
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmDeleteCloudAccountProps>()((theme, props) => ({
    DeleteTitle: { overflowWrap: 'anywhere' },
}));

export { ConfirmDeleteCloudAccountDialog };
