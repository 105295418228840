import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Chip, Link, Typography } from '@mui/material';
import { StyledToolTip } from '../utilities/styled-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IExpandableRolesCellProps {
    roles: string[];
    tabIndex: 0 | -1;
}

const ExpandableRolesCell: React.FC<IExpandableRolesCellProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { roles, tabIndex } = props;
    const [expanded, setExpanded] = React.useState(false);
    const limit = 3;
    const handleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <Box className={cx(classes.Container)}>
            <Box className={cx(classes.ChipsContainer)}>
                {(expanded ? roles : roles.slice(0, limit)).map((role) => (
                    <Box key={role} className={cx(classes.ChipWrapper)}>
                        <StyledToolTip arrow title={role} placement='top'>
                            <Chip
                                size={'small'}
                                variant='outlined'
                                className={cx(classes.Chip)}
                                label={
                                    <Typography className={cx(classes.Typography)} variant={'body2'}>
                                        {role}
                                    </Typography>
                                }
                            />
                        </StyledToolTip>
                    </Box>
                ))}
            </Box>
            {roles.length > limit && (
                <Box className={cx(classes.LinkContainer)}>
                    <Typography variant={'body2'} align={'left'}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <Link
                            tabIndex={tabIndex}
                            className={cx(classes.LinkStyle)}
                            type={'button'}
                            component={'button'}
                            onClick={() => handleExpand()}
                        >
                            {expanded ? 'Show less' : `+ ${roles.length - limit} more`}
                        </Link>
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

const useStyles = customMakeStyles<IExpandableRolesCellProps>()((theme, props) => ({
    Container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    ChipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '4px',
        minHeight: '24px',
        maxWidth: '100%',
        transition: 'all 0.2s ease-in-out',
    },
    ChipWrapper: {
        display: 'inline-flex',
        flexShrink: 0,
        maxWidth: '6.2rem', // Fixed width for all chips
        transition: 'all 0.2s ease-in-out',
    },
    Chip: {
        width: '100%',
        transition: 'all 0.2s ease-in-out',
        '& .MuiChip-label': {
            padding: '0 8px',
            width: '100%',
            display: 'block',
        },
    },
    Typography: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
        display: 'block',
    },
    LinkContainer: {
        marginTop: '4px',
        transition: 'all 0.2s ease-in-out',
    },
    LinkStyle: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
        },
    },
}));

export { ExpandableRolesCell };
