import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Control, Controller, FieldErrors, useForm, UseFormHandleSubmit, UseFormRegister, UseFormReset, UseFormSetValue } from 'react-hook-form';
import {
    Autocomplete,
    AutocompleteChangeReason,
    Checkbox,
    FormHelperText,
    ListItemIcon,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { FormField } from '../../../forms';
import { IBusinessGroupingAddUsersForm, IBusinessGroupingChangeOwnerForm, IBusinessGroupingDetailsForm, IUser } from '@vegaplatformui/models';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingOwnerFormFieldProps {
    users: IUser[];
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
    control: Control<IBusinessGroupingDetailsForm>;
    errors: FieldErrors<IBusinessGroupingDetailsForm>;
    register: UseFormRegister<IBusinessGroupingDetailsForm>;
    handleSubmit: UseFormHandleSubmit<IBusinessGroupingDetailsForm>;
    isDisabled: boolean;
}

const BusinessGroupingOwnerFormField: React.FC<IBusinessGroupingOwnerFormFieldProps> = (props) => {
    const { control, setValue, errors, register, users } = props;
    const theme = useTheme();
    const { classes, cx } = useStyles(props);

    return (
        <>
            <FormField label='Owner' htmlFor='business_grouping_owner'>
                <Controller
                    name={'business_grouping_owner'}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                            {...register('business_grouping_owner')}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={value}
                            disabled={props.isDisabled}
                            size={'small'}
                            id='business_grouping_owner'
                            options={users}
                            fullWidth={true}
                            onChange={(event: any, user: IUser | null, reason) => {
                                const previousOwnerId =
                                    control._formValues.business_grouping_owner !== null ? control._formValues.business_grouping_owner.id : '';
                                onChange(user);
                                switch (reason) {
                                    case 'clear':
                                        previousOwnerId !== '' &&
                                            setValue(
                                                'business_grouping_users',
                                                [
                                                    ...new Set([
                                                        ...control._formValues.business_grouping_users.filter(
                                                            (usr: IUser) => usr.id !== previousOwnerId
                                                        ),
                                                    ]),
                                                ],
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                }
                                            );
                                        break;
                                    default:
                                        user &&
                                            setValue(
                                                'business_grouping_users',
                                                !control._formValues.business_grouping_users.map((usr: IUser) => usr.id).includes(user!.id)
                                                    ? [
                                                          ...new Set([
                                                              ...control._formValues.business_grouping_users.filter(
                                                                  (usr: IUser) => usr.id !== previousOwnerId
                                                              ),
                                                              user,
                                                          ]),
                                                      ]
                                                    : previousOwnerId === user!.id
                                                      ? [
                                                            ...new Set([
                                                                ...control._formValues.business_grouping_users.filter(
                                                                    (usr: IUser) => usr.id !== previousOwnerId
                                                                ),
                                                                user,
                                                            ]),
                                                        ]
                                                      : [
                                                            ...new Set([
                                                                ...control._formValues.business_grouping_users.filter(
                                                                    (usr: IUser) => usr.id !== previousOwnerId
                                                                ),
                                                            ]),
                                                        ],
                                                {
                                                    shouldValidate: true,
                                                    shouldDirty: true,
                                                }
                                            );
                                        break;
                                }
                            }}
                            getOptionLabel={(option) => {
                                return `${option.email + ' - ' + option.given_name + ' ' + option.family_name}`;
                            }}
                            renderOption={(props, user: IUser, { selected }) => (
                                <li {...props} key={user.id}>
                                    <ListItemIcon>
                                        <Checkbox checked={user.id === value?.id} />
                                    </ListItemIcon>
                                    <Tooltip title={`${user.given_name} ${user.family_name} - ${user.email}`}>
                                        <ListItemText
                                            primary={`${user.given_name} ${user.family_name}`}
                                            primaryTypographyProps={{ noWrap: true }}
                                            secondary={user.email}
                                            secondaryTypographyProps={{ noWrap: true }}
                                        />
                                    </Tooltip>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField placeholder={'Assign an owner'} {...params} error={!!errors.business_grouping_owner} />
                            )}
                        />
                    )}
                />
            </FormField>
            <FormHelperText>
                <Typography variant={'caption'} color={theme.palette.error.main}>
                    {errors.business_grouping_owner?.message}
                </Typography>
            </FormHelperText>
        </>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingOwnerFormFieldProps>()((theme, props) => ({}));

export { BusinessGroupingOwnerFormField };
