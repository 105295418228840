import React, { ReactNode } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Stack, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileDetailTileProps extends React.PropsWithChildren {
    title: string;
    titleClassName?: string;
    subtitle?: ReactNode;
}

const SettingsDetailTile: React.FC<IProfileDetailTileProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid direction='row' justifyContent='center' item container xs={12} spacing={2}>
            <Grid item xs={12} sm={4}>
                <Stack direction={'column'}>
                    <Typography className={props.titleClassName ? cx(classes.TileTitle, props.titleClassName) : cx(classes.TileTitle)} variant={'h6'}>
                        {props.title}
                    </Typography>
                    {props.subtitle && (
                        <Typography className={cx(classes.TileSubtitle)} variant={'subtitle1'}>
                            {props.subtitle}
                        </Typography>
                    )}
                </Stack>
            </Grid>
            <Grid item container xs={12} sm={8}>
                {props.children}
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IProfileDetailTileProps>()((theme, props) => ({
    TileTitle: { color: theme.palette.grey[700] },
    TileSubtitle: { color: theme.palette.grey[600] },
}));

export { SettingsDetailTile };
