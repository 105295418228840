import React from 'react';
import { Route, Routes as RRoutes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak-fork/web';
import { AuthController } from '@vegaplatformui/sharedcomponents';
import { PortalLayoutController } from '../components/portal-layout/portal-layout-controller';
import { ExecutiveKpiController } from '../components/executive-kpis/executive-kpi-controller';
import { FileDownloadsController } from '../components/file-downloads/file-downloads-controller';
import { FileTransferController } from '../components/file-transfer/file-transfer-controller';
import { CostNavigatorController } from '../components/cost-navigator/cost-navigator-controller';
import { TagManagerController } from '../components/tag-manager/tag-manager-controller';
import { getVegaRoute, sessionTexts, useRouteUrls } from '@vegaplatformui/utils';
import { RecommendationsController } from '../components/recommendations/recommendations-controller';
import { SpacesController } from '../components/spaces/spaces-controller';
import { ResourcesController } from '../components/resources/resources-controller';
import { CloudHeroesController } from '../components/cloud-heroes/cloud-heroes-controller';
import { SettingsController } from '../components/settings/settings-controller';
import { ResourceDetailController } from '../components/resources/resources-detail-controller';
import { BusinessGroupingsController } from '../components/business-groupings/business-groupings-controller';
import { AnomaliesController } from '../components/anomalies/anomalies-controller';
import { VScoreController } from '../components/v-score/v-score-controller';
import { ParkingController } from '../components/parking/parking-controller';
import { ForecastingController } from '../components/forecasting/forecasting-controller';
import { TagComplianceController } from '../components/tag-manager/tag-compliance-controller';
import { QuicksightConsoleController } from '../components/quicksight-console/quicksight-console-controller';
import { ProtectedRoute, RedirectOnNonExistingRoute } from './protected-route';
import { CommittedUseDiscountsAws } from '../components/committed-use-discounts/committed-use-discounts-aws';
import { CommittedUseDiscountsAzure } from '../components/committed-use-discounts/committed-use-discounts-azure';
import { CommittedUseDiscountsGcp } from '../components/committed-use-discounts/committed-use-discounts-gcp';
import { BusinessGroupingDetailsController } from '../components/business-groupings/business-grouping-details-controller';
import { BusinessGroupingsAssignmentRulesController } from '../components/business-groupings/business-groupings-assignment-rules-controller';
import { Datametry } from '../components/datametry/datametry';
import { Finance } from '../components/finance/finance';
import { CustomDashboards } from '../components/custom-dashboards/custom-dashboards';
import { PermissionDetailsController } from '../components/permission-details/permission-details-controller';
import { SharedReportsViewController } from '../components/shared-reports-viewer/shared-reports-view-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoutesProps {}

const Routes: React.FC<IRoutesProps> = (props) => {
    const { keycloak } = useKeycloak();
    const { routeUrls } = useRouteUrls({});

    return (
        <RRoutes data-testid='routes'>
            <Route
                index
                element={<AuthController authenticatedRedirectPath={localStorage.getItem(sessionTexts.route) || routeUrls.navigator.path} />}
            />

            <Route path='/*' element={<RedirectOnNonExistingRoute />} />

            {/*This is for an entire page loading screen*/}
            {/*<Route path={routeUrls.linearLoadingPage} element={<LinearIndeterminateLoadingPageController />} />*/}
            <Route element={<ProtectedRoute keycloak={keycloak} layoutComponent={PortalLayoutController} />}>
                {getVegaRoute({
                    route: routeUrls.executivekpis,
                    element: <ExecutiveKpiController />,
                })}
                {getVegaRoute({
                    route: routeUrls.tagCompliance,
                    element: <TagComplianceController />,
                })}
                {getVegaRoute({
                    route: routeUrls.forecasting,
                    element: <ForecastingController />,
                })}
                {getVegaRoute({
                    route: routeUrls.navigator,
                    element: <CostNavigatorController />,
                })}
                {getVegaRoute({ route: routeUrls.datametry, element: <Datametry /> })}
                {getVegaRoute({ route: routeUrls.finance, element: <Finance /> })}
                {getVegaRoute({
                    route: routeUrls.anomalies,
                    element: <AnomaliesController />,
                })}
                {getVegaRoute({
                    route: routeUrls.optimize,
                    element: <div>Optimize</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.operate,
                    element: <div>Operate</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.fileDownloads,
                    element: <FileDownloadsController />,
                })}
                {getVegaRoute({
                    route: routeUrls.fileTransfer,
                    element: <FileTransferController />,
                })}
                {getVegaRoute({
                    route: routeUrls.tagManager,
                    element: <TagManagerController />,
                })}
                {getVegaRoute({
                    route: routeUrls.customDashboards,
                    element: <CustomDashboards />,
                })}
                {getVegaRoute({
                    route: routeUrls.sharedReport,
                    children: [
                        { route: { isEnabled: routeUrls.sharedReport.isEnabled }, index: true, element: <SharedReportsViewController /> },
                        {
                            route: routeUrls.sharedReportId,
                            element: <SharedReportsViewController />,
                            children: [
                                {
                                    route: routeUrls.sharedReportView,
                                    element: <SharedReportsViewController />,
                                    children: [{ route: routeUrls.sharedReportViewId, element: <SharedReportsViewController /> }],
                                },
                            ],
                        },
                    ],
                })}
                {getVegaRoute({
                    route: routeUrls.recommendations,
                    element: <RecommendationsController />,
                })}
                {getVegaRoute({
                    route: routeUrls.spaces,
                    element: <SpacesController />,
                })}
                {getVegaRoute({
                    route: routeUrls.resources,
                    children: [
                        { route: { isEnabled: routeUrls.resources.isEnabled }, index: true, element: <ResourcesController /> },
                        {
                            route: routeUrls.resourcesDetail,
                            element: <ResourceDetailController />,
                        },
                        {
                            route: routeUrls.resourcesDetailTabs,
                            element: <ResourceDetailController />,
                        },
                    ],
                })}
                {getVegaRoute({
                    route: routeUrls.cloudHeroSummaries,
                    element: <CloudHeroesController />,
                })}
                {getVegaRoute({
                    route: routeUrls.settings.route,
                    children: [
                        {
                            route: { isEnabled: routeUrls.settings.route.isEnabled },
                            index: true,
                            element: <SettingsController />,
                        },
                        {
                            route: routeUrls.settingsTabs,
                            element: <SettingsController />,
                            children: [
                                {
                                    route: routeUrls.notificationsTabs,
                                    element: <SettingsController />,
                                },
                            ],
                        },
                        {
                            route: routeUrls.permissionDetails,
                            element: <PermissionDetailsController />,
                            children: [
                                {
                                    route: routeUrls.permissionsDetailTabs,
                                    element: <PermissionDetailsController />,
                                },
                            ],
                        },
                    ],
                })}
                {getVegaRoute({
                    route: routeUrls.businessGroupings,
                    children: [
                        {
                            route: { isEnabled: routeUrls.businessGroupings.isEnabled },
                            index: true,
                            element: <BusinessGroupingsController />,
                        },
                        {
                            route: routeUrls.businessGroupingAssignmentRules,
                            element: <BusinessGroupingsAssignmentRulesController />,
                        },
                        {
                            route: routeUrls.businessGroupingDetails,
                            element: <BusinessGroupingDetailsController />,
                        },
                        {
                            route: routeUrls.businessGroupingDetailsTabs,
                            element: <BusinessGroupingDetailsController />,
                        },
                    ],
                })}
                {getVegaRoute({
                    route: routeUrls.vScore,
                    element: <VScoreController />,
                })}
                {getVegaRoute({
                    route: routeUrls.parking,
                    element: <ParkingController />,
                })}
                {getVegaRoute({
                    route: routeUrls.dashboardAuthor,
                    element: <QuicksightConsoleController />,
                })}
                {getVegaRoute({
                    route: routeUrls.committedUseAws,
                    element: <CommittedUseDiscountsAws />,
                })}
                {getVegaRoute({
                    route: routeUrls.committedUseAzure,
                    element: <CommittedUseDiscountsAzure />,
                })}
                {getVegaRoute({
                    route: routeUrls.committedUseGcp,
                    element: <CommittedUseDiscountsGcp />,
                })}
                {/*Stubbed out routes to support refresh/nav*/}
                {getVegaRoute({
                    route: routeUrls.organize,
                    element: <div>{routeUrls.organize.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.workloads,
                    element: <div>{routeUrls.workloads.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.resourcePools,
                    element: <div>{routeUrls.resourcePools.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.vLabels,
                    element: <div>{routeUrls.vLabels.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.vPolicies,
                    element: <div>{routeUrls.vPolicies.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.quotasBudgets,
                    element: <div>{routeUrls.quotasBudgets.path}</div>,
                })}

                {/*Stubbed out routes to support refresh/nav*/}
                {/*TODO This is here as an existing route but there is also one above keeping it commented out here just in case */}
                {/*<Route path={routeUrls.tagCompliance} element={<div>{routeUrls.tagCompliance}</div>} />*/}
                {getVegaRoute({
                    route: routeUrls.alerting,
                    element: <div>{routeUrls.alerting.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.contacts,
                    element: <div>{routeUrls.contacts.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.hushes,
                    element: <div>{routeUrls.hushes.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.myFiles,
                    element: <div>{routeUrls.myFiles.path}</div>,
                })}
                {getVegaRoute({
                    route: routeUrls.cloudHeroBadges,
                    element: <div>{routeUrls.cloudHeroBadges.path}</div>,
                })}
            </Route>
        </RRoutes>
    );
};

export { Routes };
