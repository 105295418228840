import { useKeycloak } from '@react-keycloak-fork/web';
import React, { useEffect, useMemo, useState } from 'react';
import { SnackBarOptions } from '../../jotai/atom';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { IBusinessGroupTreeItem } from '@vegaplatformui/models';
import { BusinessGroupingsApi } from '@vegaplatformui/apis';
import { queryKeys } from '../query-keys';
import { useSetAtom } from 'jotai';

export interface IUseBusinessGroupingsTreeItemApiHook {
    setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    businessGroupingTreeChildNodes: IBusinessGroupTreeItem[] | undefined;
    isBusinessGroupingTreeChildNodesLoading: boolean;
}

export interface IUseBusinessGroupingsTreeItemApiProps {
    businessGroupTreeItem?: IBusinessGroupTreeItem | undefined;
    childNodesToClear?: IBusinessGroupTreeItem[] | undefined;
    setChildNodesToClear?: React.Dispatch<React.SetStateAction<IBusinessGroupTreeItem[] | undefined>>;
}

export function useBusinessGroupingsTreeItemApi(props: IUseBusinessGroupingsTreeItemApiProps): IUseBusinessGroupingsTreeItemApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const [isEnabled, setIsEnabled] = useState<boolean>(false);

    const businessGroupingsApi = useMemo(() => {
        const businessGroupingsApi = new BusinessGroupingsApi();
        businessGroupingsApi.keycloak = keycloak;
        return businessGroupingsApi;
    }, [keycloak]);

    const {
        data: businessGroupingTreeChildNodes,
        isLoading: isBusinessGroupingTreeChildNodesLoading,
        isError: isBusinessGroupingTreeChildNodesError,
        error: businessGroupingTreeChildNodesError,
        isSuccess: isBusinessGroupingTreeChildNodesSuccess,
    } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.getBusinessGroupingTreeNodes, props.businessGroupTreeItem?.id],

        queryFn: async () => {
            const response = await businessGroupingsApi.getBusinessGroupingTreeNodes({ root_uuid: props.businessGroupTreeItem?.id });

            props.setChildNodesToClear &&
                props.setChildNodesToClear((prevVal) => [...new Set(response.data.concat(prevVal ?? []).concat([props.businessGroupTreeItem!]))]);
            const sorted = response.data.sort((a, b) => {
                function getRaw(s: string) {
                    return s.replace("'", '');
                }
                return getRaw(a.name).localeCompare(getRaw(b.name), 'en', { sensitivity: 'base' });
            });

            return sorted;
        },

        enabled: props.businessGroupTreeItem?.id !== undefined && isEnabled,
        meta: {
            errorMessage: 'There was a problem loading the Business Groupings',
        },
    });

    return {
        setIsEnabled: setIsEnabled,
        businessGroupingTreeChildNodes: businessGroupingTreeChildNodes,
        isBusinessGroupingTreeChildNodesLoading: isBusinessGroupingTreeChildNodesLoading,
    };
}

export default useBusinessGroupingsTreeItemApi;
