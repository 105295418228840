import { useKeycloak } from '@react-keycloak-fork/web';
import { SnackBarOptions } from '../jotai/atom';
import { useEffect, useMemo, useState } from 'react';
import { FederationManagementApi } from '@vegaplatformui/apis';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { ISSORoleMapping } from '@vegaplatformui/models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSetAtom } from 'jotai';

export interface IUseFederationManagementApiHook {
    roleMappings: ISSORoleMapping[];
    isRoleMappingsLoading: boolean;
    onCreateRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onSaveRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onDeleteRoleMapping: (roleMapping: ISSORoleMapping) => void;
}
export interface IUseFederationManagementApiProps {}

export function useFederationManagementApi(props: IUseFederationManagementApiProps): IUseFederationManagementApiHook {
    const { keycloak } = useKeycloak();
    const { roleMappings: isRoleMappings } = useFlags();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const queryClient = useQueryClient();
    const federationManagementApi = useMemo(() => {
        const apiInstance = new FederationManagementApi();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const {
        data: roleMappings,
        isLoading: isRoleMappingsLoading,
        isError: isRoleMappingsError,
        error: roleMappingsError,
    } = useQuery({
        queryKey: [queryKeys.federationManagement.getRoleMappings],

        queryFn: async () => {
            const response = await federationManagementApi.getRoleMappings();

            return response.data.roleMappings;
        },
        enabled: window.location.pathname.includes('sso') && isRoleMappings,
        meta: {
            errorMessage: 'Failed to get role mappings',
        },
    });

    const { isPending: isCreateRoleMappingLoading, mutate: onCreateRoleMapping } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                message: 'Failed to create role mappings: ' + SnackbarErrorOutput(error),
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'error' },
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                message: `Role mappings created successfully.`,
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'info' },
            });
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.federationManagement.getRoleMappings] });
        },
        mutationFn: async (roleMapping: ISSORoleMapping) => {
            if (isRoleMappings) {
                const response = await federationManagementApi.postRoleMappings({ roleMappings: [roleMapping] });
                return response.data;
            }
        },
    });

    const { isPending: isSaveRoleMappingLoading, mutate: onSaveRoleMapping } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                message: 'Failed to update role mappings: ' + SnackbarErrorOutput(error),
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'error' },
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                message: `Role mappings updated successfully.`,
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'info' },
            });
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.federationManagement.getRoleMappings] });
        },
        mutationFn: async (roleMapping: ISSORoleMapping) => {
            if (isRoleMappings) {
                const response = await federationManagementApi.putRoleMappings({ roleMappings: [roleMapping] }, roleMapping.id!);
                return response.data;
            }
        },
    });

    const { isPending: isDeleteRoleMappingLoading, mutate: onDeleteRoleMapping } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                message: `Failed to delete ${variables.externalGroupName}: ` + SnackbarErrorOutput(error),
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'error' },
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                message: `${variables.externalGroupName} deleted successfully.`,
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'info' },
            });
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.federationManagement.getRoleMappings] });
        },
        mutationFn: async (roleMapping: ISSORoleMapping) => {
            if (isRoleMappings) {
                const response = await federationManagementApi.deleteRoleMapping(roleMapping.id!);
                return response.data;
            }
        },
    });

    return {
        roleMappings: roleMappings ?? [],
        isRoleMappingsLoading: isRoleMappingsLoading || isCreateRoleMappingLoading || isSaveRoleMappingLoading || isDeleteRoleMappingLoading,
        onCreateRoleMapping,
        onSaveRoleMapping,
        onDeleteRoleMapping,
    };
}
