import { HttpClient } from './http-common';
import { ITimezoneResponse } from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';
//Named exports allow multiple items to be exported from a single file, while a default export represents the main export of a module. A module can have multiple named exports but only one default export.

export class TimezonesApi extends HttpClient {
    // this is a singleton pattern which means that the class instance is created once and then reused
    // and there can only be one instance of the class in the application
    protected static classInstance?: TimezonesApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new TimezonesApi();
        }
        return this.classInstance;
    }

    public getTimezones = (): GeminiResponse<ITimezoneResponse[]> => this.instance.get(`/vegaapi/timezones`);
}
