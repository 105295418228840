import React from 'react';
import { Box, TextField, FormControlLabel, Checkbox, InputAdornment, Typography, Stack } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { BudgetDetails } from '@vegaplatformui/models';

export interface IBudgetDetailsFormProps {
    details: BudgetDetails;
    onDetailsChange: (details: BudgetDetails) => void;
}

const BudgetDetailsForm: React.FC<IBudgetDetailsFormProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { details, onDetailsChange } = props;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        onDetailsChange({
            ...details,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <Stack spacing={3} className={cx(classes.FormContainer)}>
            <Box className={cx(classes.FormGroup)}>
                <Typography variant='body2' color='text.secondary' className={cx(classes.Label)}>
                    Budget amount*
                </Typography>
                <TextField
                    type='number'
                    id='amount'
                    name='amount'
                    value={details.amount}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    className={cx(classes.Input)}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position='start' className={cx(classes.CurrencySymbol)}>
                                    $
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </Box>

            <Box className={cx(classes.FormGroup)}>
                <Typography variant='body2' color='text.secondary' className={cx(classes.Label)}>
                    Budget name*
                </Typography>
                <TextField
                    type='text'
                    id='name'
                    name='name'
                    value={details.name}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    className={cx(classes.Input)}
                />
            </Box>

            <Box className={cx(classes.FormGroup)}>
                <Typography variant='body2' color='text.secondary' className={cx(classes.Label)}>
                    Budget period starts from*
                </Typography>
                <TextField
                    type='date'
                    id='startDate'
                    name='startDate'
                    value={details.startDate}
                    onChange={handleInputChange}
                    required
                    fullWidth
                    className={cx(classes.Input)}
                />
            </Box>

            <Box className={cx(classes.CheckboxGroup)}>
                <FormControlLabel
                    control={
                        <Checkbox
                            name='addGrowthRate'
                            checked={details.addGrowthRate}
                            onChange={handleInputChange}
                            className={cx(classes.Checkbox)}
                        />
                    }
                    label='Add growth rate to the budget amount'
                    className={cx(classes.CheckboxLabel)}
                />
            </Box>
        </Stack>
    );
};

const useStyles = customMakeStyles<IBudgetDetailsFormProps>()((theme) => ({
    FormContainer: {
        margin: `${theme.spacing(2)} 0`,
    },
    FormGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    Label: {
        fontSize: '0.9rem',
    },
    Input: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 2px ${theme.palette.primary.main}20`,
            },
        },
    },
    CurrencySymbol: {
        color: theme.palette.text.secondary,
    },
    CheckboxGroup: {
        marginTop: theme.spacing(1),
    },
    Checkbox: {
        padding: theme.spacing(0.5),
    },
    CheckboxLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '0.9rem',
            color: theme.palette.text.primary,
        },
    },
}));

export { BudgetDetailsForm };
