import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid2 } from '@mui/material';
import { SettingsContentHeader } from '../settings-content-header';
import { SettingsV2Divider } from '../settings-v2-divider';
import { SettingsDetailTile } from '../settings-detail-tile';
import { OrganizationNotificationInput } from './organization-notification-input';
import { useOrganizationNotificationSettingsApi } from '../../../api-hooks/notifications/use-organization-notification-settings-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationNotificationsV2Props {}

const OrganizationNotificationsV2: React.FC<IOrganizationNotificationsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const {
        organizationNotificationConfigurations,
        updateWebhook,
        createWebhook,
        deletingLoadingStates,
        submittingLoadingStates,
        areWebhooksLoading,
        deleteWebhook,
    } = useOrganizationNotificationSettingsApi({});

    return (
        <>
            <Grid2 container spacing={2}>
                <SettingsContentHeader title={'Organization Notifications'} subtitle={`Manage your organization's notification settings.`} />
                <SettingsV2Divider />
                <SettingsDetailTile
                    title={'Integrations'}
                    children={
                        <OrganizationNotificationInput
                            deletingLoadingStates={deletingLoadingStates}
                            submittingLoadingStates={submittingLoadingStates}
                            organizationNotificationConfigurations={organizationNotificationConfigurations}
                            areWebhooksLoading={areWebhooksLoading}
                            updateWebhook={updateWebhook}
                            createWebhook={createWebhook}
                            deleteWebhook={deleteWebhook}
                        />
                    }
                />
                {/*<Grid2 sx={{ paddingLeft: '1rem' }} item xs={12}>*/}
                {/*    <OrganizationNotificationInput*/}
                {/*        isTeamsWebhookUpdating={isTeamsWebhookUpdating}*/}
                {/*        teamsWebhook={teamsWebhook}*/}
                {/*        isTeamsWebhookLoading={isTeamsWebhookLoading}*/}
                {/*        updateTeamsWebhook={updateTeamsWebhook}*/}
                {/*    />*/}
                {/*</Grid2>*/}
            </Grid2>
        </>
    );
};

const useStyles = customMakeStyles<IOrganizationNotificationsV2Props>()((theme, props) => ({}));

export { OrganizationNotificationsV2 };
