import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button, Grid2, Stack, Typography } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { SupportEmailRecipient } from '@vegaplatformui/models';
import { ShowSupportForm } from '../jotai/atom';
import { useAtom } from 'jotai';

export interface IErrorScreenProps extends FallbackProps {
    menuItems: GeminiMenuItem[];
    setSidebarMenuItems: React.Dispatch<React.SetStateAction<GeminiMenuItem[]>>;
}

const ErrorScreen: React.FC<IErrorScreenProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [showSupport, setShowSupport] = useAtom(ShowSupportForm);

    const onCickSupport = () => {
        setShowSupport({ showSupportForm: !showSupport.showSupportForm, contactType: SupportEmailRecipient.Support });
    };

    return (
        <Grid2 className={cx(classes.Container)} container direction='column' justifyContent='center' alignItems='center'>
            <Grid2 className={cx(classes.TypographyGrid)}>
                {process.env.REACT_APP_DEVELOPMENT === 'false' ? (
                    <Typography fontWeight={400} variant={'h1'}>
                        {props.error.message}
                    </Typography>
                ) : (
                    <Stack justifyContent='center' alignItems='center' direction={'column'} spacing={1}>
                        <Typography fontWeight={400} variant={'h1'}>
                            Something went wrong.
                        </Typography>
                        <Typography fontWeight={400} className={cx(classes.Subtitle)} variant={'h6'}>
                            We’re sorry for the trouble. If the issue persists, please contact our support team for assistance.
                        </Typography>
                    </Stack>
                )}
            </Grid2>
            <Grid2>
                <Button color={'primary'} onClick={onCickSupport} disabled={false}>
                    Contact Support
                </Button>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IErrorScreenProps>()((theme, props) => ({
    Container: { height: '100vh' },
    Vcow: { width: '100%' },
    TypographyGrid: { marginBottom: '2.2rem' },
    Subtitle: { color: theme.palette.text.secondary },
}));

export { ErrorScreen };
