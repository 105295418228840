import React, { useEffect, useMemo } from 'react';
import { Box, List, ListItem, Skeleton, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridValueOptionsParams, ValueOptions } from '@mui/x-data-grid-premium';
import { GridPagination, GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { CloudProviderIcon } from '../../utilities/logo-selector';
import { Link as RouterLink } from 'react-router';
import { Block, CheckCircle, InfoOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { DataGridCustomToolbar, DataGridCustomToolbarProps } from '../../utilities/datagrid-custom-toolbar';
import { DataGridCellTooltip } from '../../utilities/datagrid-cell-tooltip';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { CustomDatagridColumnMenu } from '../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import {
    CustomGridColSingleSelectOperatorDef,
    CustomGridColStringOperatorDef,
    CustomGridColStringContainsOnlyDef,
} from '../../utilities/custom-grid-col-string-operator-def';
import { ResourcesTableActionsMenu } from './resources-table-actions-menu';
import { IResource, IResourceType } from '@vegaplatformui/models';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../api-hooks/query-keys';
import { useResourceFilterOptionsApi } from '../../api-hooks/use-resource-filter-options-api';
import { ResourcesBusinessGroupingsDrawer } from '../resources-business-groupings-drawer';
import { tableIdentifierKeys } from '../../use-table-utilities/table-identifier-keys';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { IconButtonWithAlertPopover } from '../../icon-button-with-alert-popover/icon-button-with-alert-popover';
import { useResourceMetadataApi } from '../../api-hooks/use-resource-metadata-api';
import { useProviderFilterOptions } from '@vegaplatformui/utils';
import { ExpandableBusinessGroupingCell } from '../../utilities/expandable-business-grouping-cell';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourcePoolsTableProps {
    resources: IResource[];
    setSelectedResources: React.Dispatch<React.SetStateAction<IResource[]>>;
    selectedResources: IResource[];
    isLoading: boolean;
    resourcesTableIdentifier: string;
    isServerPaginated: boolean;
    activeFields?: string[];
    checkBoxSelection?: boolean;
    isDynamicRowSize?: boolean;
    shouldUnregisterTableControl?: boolean;
    shouldRegisterTableControl?: boolean;
    shouldLimitRowSelection?: boolean;
}

const ResourcesTable: React.FC<IResourcePoolsTableProps> = (props) => {
    const theme = useTheme();
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const resourcesTableUtilities = useTableUtilities(props.resourcesTableIdentifier, undefined, queryKeys.resources.resourcesTable);
    const { resourceTypes } = useResourceFilterOptionsApi({ tableIdentifier: props.resourcesTableIdentifier });
    const { resourceMetadata, setResourceId, isResourceMetadataLoading } = useResourceMetadataApi({ resources: props.resources });
    const queryClient = useQueryClient();
    const [resourceWithBusinessGroupings, setResourceWithBusinessGroupings] = React.useState<IResource | undefined>(undefined);
    const [isResourcesBusinessGroupingsDetailOpen, setIsResourcesBusinessGroupingsDetailOpen] = React.useState<boolean>(false);
    const { vOperate } = useFlags();
    const resourceTypeOptions: string[] = useMemo(() => {
        if (resourceTypes?.length && resourceTypes.length > 0) {
            const options = new Set(resourceTypes.map((item: IResourceType) => item.type));
            return [...options];
        } else {
            return [];
        }
    }, [resourceTypes]);

    const { providerOptions } = useProviderFilterOptions({});

    useEffect(() => {
        if (props.shouldRegisterTableControl) {
            resourcesTableUtilities.registerTableControl({ is_parking_capable: false });
        }
        return () => {
            if (props.shouldUnregisterTableControl) {
                resourcesTableUtilities.unregisterTableControl();
            }
        };
    }, [props.shouldRegisterTableControl]);

    useEffect(() => {
        setSelectionModel([...new Set([...selectionModel, ...(props.selectedResources?.map((resource) => resource?.id) ?? [])])]);
    }, [props.selectedResources]);

    function ResourceIdRender(resource: IResource) {
        return props.isDynamicRowSize ? (
            <StyledToolTip arrow title={resource.resource_id} placement='bottom-start'>
                <Typography variant={'body2'} className={cx(classes.BusinessGroupingTypography)}>
                    {resource.resource_id}
                </Typography>
            </StyledToolTip>
        ) : (
            <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
                <StyledToolTip arrow title={resource.resource_id} placement='bottom-start'>
                    <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                        {resource.resource_id}
                    </Stack>
                </StyledToolTip>
            </Stack>
        );
    }

    function NameRenderer(resource: IResource, tabIndex: number) {
        return props.isDynamicRowSize ? (
            <RouterLink className={cx(classes.LinkStyle)} tabIndex={tabIndex} to={`/resource/${resource.id}`}>
                <Typography variant={'body2'} className={cx(classes.BusinessGroupingTypography)}>
                    {resource.name}
                </Typography>
            </RouterLink>
        ) : (
            <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
                <RouterLink className={cx(classes.LinkStyle)} tabIndex={tabIndex} to={`/resource/${resource.id}`}>
                    <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                        {resource.name}
                    </Stack>
                </RouterLink>
            </Stack>
        );
    }

    const onClickGetResourceMetaData = (resource: IResource) => {
        setResourceId(resource.id);
    };
    const AlertValueGetter = (row: IResource, apiRef: React.MutableRefObject<GridApiCommunity>) => {
        return apiRef.current.isRowSelectable(row.id);
    };

    function ResourceSelectionNotice() {
        return (
            <Stack direction={'column'} spacing={0}>
                <Box>{`Unable to select resource since it is already attached to an existing schedule.`}</Box>
                {isResourceMetadataLoading ? (
                    <Skeleton height={'5rem'} width={'12rem'} />
                ) : resourceMetadata ? (
                    <List className={cx(classes.InstructionList)}>
                        {resourceMetadata.schedule_owner_email && (
                            <ListItem className={cx(classes.InstructionStep)}>
                                <>
                                    Contact:{' '}
                                    <Box display='inline' fontWeight={'bold'}>
                                        {resourceMetadata.schedule_owner_email}
                                    </Box>
                                </>
                            </ListItem>
                        )}
                        {resourceMetadata.schedule_name && (
                            <ListItem className={cx(classes.InstructionStep)}>
                                <>
                                    Schedule:{' '}
                                    <Box display='inline' fontWeight={'bold'}>
                                        {resourceMetadata.schedule_name}
                                    </Box>
                                </>
                            </ListItem>
                        )}
                        {resourceMetadata.schedule_business_group_name && (
                            <ListItem className={cx(classes.InstructionStep)}>
                                <>
                                    Business Grouping:{' '}
                                    <Box display='inline' fontWeight={'bold'}>
                                        {resourceMetadata.schedule_business_group_name}
                                    </Box>
                                </>
                            </ListItem>
                        )}
                    </List>
                ) : (
                    <></>
                )}
            </Stack>
        );
    }

    let columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'alert',
            headerName: '',
            flex: 0.25,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (value: boolean, row: IResource, column, apiRef) => AlertValueGetter(row, apiRef),
            renderCell: (params: GridRenderCellParams<IResource>) => {
                switch (params.value) {
                    case false:
                        return (
                            <Stack height={'100%'}>
                                <IconButtonWithAlertPopover
                                    buttonTitle={'Resource Selection Notice'}
                                    buttonColor={'error'}
                                    buttonIcon={<InfoOutlined />}
                                    alertSeverity={'info'}
                                    alertColor={'error'}
                                    shouldBindOnHover={false}
                                    iconButtonClassName={cx(classes.InfoButtonFocus)}
                                    alertContent={<ResourceSelectionNotice />}
                                    iconButtonSize={'small'}
                                    onClick={() => {
                                        onClickGetResourceMetaData(params.row);
                                    }}
                                />
                            </Stack>
                        );
                    default:
                        return <></>;
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Name',
            flex: 2,
            renderCell: (params: GridRenderCellParams<IResource>) => NameRenderer(params.row, params.tabIndex),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'resource_id',
            headerName: 'Resource ID',
            flex: 2,
            renderCell: (params: GridRenderCellParams<IResource>) => ResourceIdRender(params.row),
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'provider_str',
            headerName: 'Provider',
            flex: 0.7,
            renderCell: (params: GridRenderCellParams<IResource>) =>
                params.value ? <CloudProviderIcon cloudProvider={params.value} isDynamicRowSize={props.isDynamicRowSize ?? false} /> : '',
            valueFormatter: (value: string, row: IResource) => (value ? value : ''),
            type: 'singleSelect',
            valueOptions: (params: GridValueOptionsParams<IResource>): ValueOptions[] => {
                return providerOptions;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'provider_account_canonical_name',
            headerName: 'Account',
            flex: 1,
            valueGetter: (value: string, row: IResource) => {
                return row.provider_account?.canonical_name;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'cloud_account_id',
            headerName: 'Account ID',
            flex: 1,
            valueGetter: (value: string, row: IResource) => {
                return row.provider_account?.account_id;
            },
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'type_str',
            headerName: 'Type',
            flex: 1,
            valueFormatter: (value: string, row: IResource) => (value ? value : ''),
            type: 'singleSelect',
            valueOptions: resourceTypeOptions,
        },
        /*        {
            field: 'cost',
            headerName: 'Cost/Mo',
            flex: 1,
            valueGetter: (value: string, row: IResource) => FormatNumberUSDHundredth(value),
        },*/
        {
            ...CustomGridColStringOperatorDef,
            field: 'region',
            headerName: 'Region',
            flex: 1,
        },
        {
            ...CustomGridColStringContainsOnlyDef,
            field: 'business_group_name',
            headerName: 'Business Groupings',
            flex: 1.5,
            sortable: false,
            valueFormatter: (value: string, row: IResource) => {
                return row.business_groups.map((bg) => bg.name).join('\n');
            },
            valueGetter: (value: string, row: IResource) => {
                return row.business_groups;
            },
            renderCell: (params: GridRenderCellParams<IResource>) => {
                if (params.value !== null && params.value.length > 0) {
                    return <ExpandableBusinessGroupingCell businessGroupings={params.value} tabIndex={params.tabIndex} limit={2} />;
                }
            },
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'is_attached_to_schedule',
            headerName: 'Attached to Schedule',
            flex: 1,
            valueFormatter: (value: boolean, row: IResource) => value,
            renderCell: (params: GridRenderCellParams<IResource>) => {
                if (params.value === true) {
                    return (
                        <StyledToolTip title={'Is Attached'} open={params.hasFocus}>
                            <Stack
                                height={'100%'}
                                direction='row'
                                justifyContent='flex-start'
                                alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                            >
                                <CheckCircle className={cx(classes.CheckCircle)} titleAccess={'Is Attached'} />
                            </Stack>
                        </StyledToolTip>
                    );
                } else {
                    return (
                        <StyledToolTip title={'Is not Attached'} open={params.hasFocus}>
                            <Stack
                                height={'100%'}
                                direction='row'
                                justifyContent='flex-start'
                                alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                            >
                                <Block color={'error'} titleAccess={'Is not Attached'} />
                            </Stack>
                        </StyledToolTip>
                    );
                }
            },
            type: 'singleSelect',
            valueOptions: [
                { value: true, label: 'True' },
                { value: false, label: 'False' },
            ],
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'is_parking_capable',
            headerName: 'Action Capable',
            flex: 1,
            renderCell: (params: GridRenderCellParams<IResource>) => {
                if (params.value === true) {
                    return (
                        <StyledToolTip title={'Actionable'}>
                            <Stack
                                height={'100%'}
                                direction='row'
                                justifyContent='flex-start'
                                alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                            >
                                <CheckCircle className={cx(classes.CheckCircle)} />
                            </Stack>
                        </StyledToolTip>
                    );
                } else {
                    return (
                        <StyledToolTip title={'Not Actionable'}>
                            <Stack
                                height={'100%'}
                                direction='row'
                                justifyContent='flex-start'
                                alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                            >
                                <Block color={'error'} />
                            </Stack>
                        </StyledToolTip>
                    );
                }
            },
            valueFormatter: (value: boolean, row: IResource) => value,
            type: 'singleSelect',
            valueOptions: [
                { value: true, label: 'True' },
                { value: false, label: 'False' },
            ],
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'state',
            headerName: 'Status',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            disableColumnMenu: true,
            headerName: 'Actions',
            flex: 1,
            filterable: false,
            sortable: false,
            disableExport: true,
            renderCell: (params: GridRenderCellParams<IResource>) => {
                return <ResourcesTableActionsMenu isLoading={props.isLoading} selectedResources={props.selectedResources} resource={params.row} />;
            },
        },
    ];
    columns =
        vOperate && props.activeFields
            ? columns.filter((column) => props.activeFields?.includes(column.field))
            : !vOperate && props.activeFields
              ? columns.filter((column) => props.activeFields?.includes(column.field)).filter((column) => column.field !== 'is_attached_to_schedule')
              : !vOperate
                ? columns.filter((column) => column.field !== 'is_attached_to_schedule')
                : !props.shouldLimitRowSelection
                  ? columns.filter((column) => column.field !== 'alert')
                  : columns;
    columns.map((column) => {
        if (!column.renderCell) column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns
            .filter((column) => column.field !== 'actions' && column.field !== '__check__' && column.field !== 'alert')
            .map((column) => column.field);
    };

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        props.setSelectedResources(
            gridSelectionModel.map(
                (id) => [...new Set([...props.resources, ...props.selectedResources].flat())].find((resource) => resource.id === id) as IResource
            )
        );
    };

    useEffect(() => {
        const selectedResourceIDs = props.selectedResources.map((x) => x.id);
        setSelectionModel(selectedResourceIDs);
    }, [props.selectedResources]);

    return (
        <Box>
            <DataGridPremium
                className={cx(commonStyles.classes.MuiDataGridAutoRowSize)}
                initialState={{
                    columns: {
                        columnVisibilityModel: { is_parking_capable: false },
                    },
                }}
                getEstimatedRowHeight={() => (props.isDynamicRowSize ? 77 : null)}
                getRowHeight={() => (props.isDynamicRowSize ? 'auto' : undefined)}
                isRowSelectable={(params: GridRowParams<IResource>) => {
                    return props.shouldLimitRowSelection ? !params.row.is_attached_to_schedule : true;
                }}
                autoHeight={true}
                columns={columns}
                columnVisibilityModel={resourcesTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={resourcesTableUtilities.onColumnVisibilityModelChange}
                keepNonExistentRowsSelected={true}
                rowCount={props.isServerPaginated ? (resourcesTableUtilities.currentTableControl?.totalRows ?? 0) : undefined}
                filterMode={'server'}
                sortingMode={'server'}
                paginationMode={props.isServerPaginated ? 'server' : 'client'}
                checkboxSelectionVisibleOnly={!props.isServerPaginated}
                checkboxSelection={props.checkBoxSelection ?? true}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    toolbar: {
                        tableIdentifier: props.resourcesTableIdentifier,
                        defaultHidden: { is_parking_capable: false },
                        showRefresh: true,
                        requestToInvalidate: () =>
                            queryClient.invalidateQueries({
                                queryKey:
                                    props.resourcesTableIdentifier === tableIdentifierKeys.attachResourcesDialogTable
                                        ? [queryKeys.resources.parkableResources]
                                        : [queryKeys.resources.resourcesTable],
                            }),
                    } as DataGridCustomToolbarProps,
                    columnsManagement: {
                        getTogglableColumns,
                    },
                    pagination: {
                        showLastButton: true,
                        showFirstButton: true,
                        slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                    },
                    noRowsOverlay: {
                        action: 'linking a provider account',
                        url: 'accounts',
                    },
                }}
                rows={props.resources}
                density={resourcesTableUtilities.currentTableControl?.density}
                onDensityChange={resourcesTableUtilities.onDensityChange}
                paginationModel={resourcesTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={resourcesTableUtilities.onPaginationModelChange}
                sortModel={resourcesTableUtilities.currentTableControl?.sortModel}
                loading={props.isLoading}
                onSortModelChange={resourcesTableUtilities.onSortModelChange}
                onFilterModelChange={resourcesTableUtilities.onFilterModelChange}
                filterModel={resourcesTableUtilities.currentTableControl?.filterModel}
                pagination
            />
            <ResourcesBusinessGroupingsDrawer
                resource={resourceWithBusinessGroupings}
                onBackDrawer={() => {
                    setResourceWithBusinessGroupings(undefined);
                    setIsResourcesBusinessGroupingsDetailOpen(false);
                }}
                isDrawerOpen={isResourcesBusinessGroupingsDetailOpen}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IResourcePoolsTableProps>()((theme, props) => ({
    BusinessGroupingTypography: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    ToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    ToolBarFilter: {
        color: theme.palette.primary.main,
        marginBottom: '1rem',
    },
    ActionsHeader: { fontSize: 0 },
    CloudProviderIcon: {
        marginLeft: theme.spacing(1),
    },
    ContainerButton: {
        textTransform: 'none',
    },
    CheckCircle: {
        fill: 'green',
    },
    InfoButtonFocus: {
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
    InstructionList: { listStyleType: 'disc', paddingLeft: '1rem' },
    InstructionStep: { display: 'list-item' },
    LinkStyle: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': { textDecoration: 'underline', color: theme.palette.primary.dark },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

export { ResourcesTable };
