import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AssignmentRulesCondition } from '../assignment-rules-condition/assignment-rules-condition';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IAssignmentRuleCondition, IAssignmentRuleConditionGroup, IAssignmentRuleFormValue } from '@vegaplatformui/models';
import { Add, DeleteForever } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesConditionsWidgetProps {
    shouldShowCombinatior: boolean;
    assignmentRuleConditionGroup: IAssignmentRuleConditionGroup;
    onChangeAssignmentRuleConditionGroup: (assignmentRuleConditionGroup: IAssignmentRuleConditionGroup) => void;
    assignmentRuleFormValues: IAssignmentRuleFormValue[];
    errors: Merge<FieldError, FieldErrorsImpl<IAssignmentRuleConditionGroup>> | undefined;
    onDeleteAssignmentRuleConditionGroup: (conditionGroup: IAssignmentRuleConditionGroup) => void;
    shouldShowDeleteAssignmentRuleConditionGroup: boolean;
}

const AssignmentRulesConditionGroup: React.FC<IAssignmentRulesConditionsWidgetProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const onChangeAssignmentRuleCondition = (assignmentRuleCondition: IAssignmentRuleCondition) => {
        const update = {
            ...props.assignmentRuleConditionGroup,
            conditions: props.assignmentRuleConditionGroup.conditions?.map((cond) => {
                if (cond.id === assignmentRuleCondition.id) {
                    return assignmentRuleCondition;
                }
                return cond;
            }),
        };

        props.onChangeAssignmentRuleConditionGroup(update);
    };

    const onClickAddCondition = () => {
        const assigntmentGroupUpdate = {
            ...props.assignmentRuleConditionGroup,
            conditions: [
                ...props.assignmentRuleConditionGroup.conditions!,
                {
                    id: uuidv4(),
                    isNew: true,
                    operator: '',
                    field: '',
                    value: '',
                },
            ],
        };

        props.onChangeAssignmentRuleConditionGroup(assigntmentGroupUpdate);
    };

    const onClickRemoveCondition = (assignmentRuleCondition: IAssignmentRuleCondition) => {
        const updatedConditions = props.assignmentRuleConditionGroup.conditions?.filter((condition) => condition.id !== assignmentRuleCondition.id);

        props.onChangeAssignmentRuleConditionGroup({ ...props.assignmentRuleConditionGroup, conditions: updatedConditions });
    };

    return (
        <Box>
            <Stack direction={'column'} spacing={1}>
                {props.assignmentRuleConditionGroup.conditions?.map((assignmentRuleCondition, index) => (
                    <Box key={`${assignmentRuleCondition.id}_${index}`} className={index > 0 ? cx(classes.AssignmentRuleBox) : ''}>
                        <AssignmentRulesCondition
                            error={props.errors && props.errors.conditions ? props.errors.conditions[index] : undefined}
                            assignmentRuleFormValues={props.assignmentRuleFormValues}
                            isRemovable={props.assignmentRuleConditionGroup.conditions && props.assignmentRuleConditionGroup.conditions.length > 1}
                            onClickRemoveCondition={onClickRemoveCondition}
                            onChangeAssignmentRuleCondition={onChangeAssignmentRuleCondition}
                            assignmentRuleCondition={assignmentRuleCondition}
                        />
                    </Box>
                ))}
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Button onClick={onClickAddCondition} className={cx(classes.AddConditionButton)} startIcon={<Add />} variant={'text'}>
                    Condition
                </Button>
                {props.shouldShowDeleteAssignmentRuleConditionGroup && (
                    <Button
                        onClick={() => props.onDeleteAssignmentRuleConditionGroup(props.assignmentRuleConditionGroup)}
                        className={cx(classes.AddConditionButton)}
                        startIcon={<DeleteForever />}
                        color={'error'}
                        variant={'text'}
                    >
                        Remove Group
                    </Button>
                )}
            </Stack>
        </Box>
    );
};

const useStyles = customMakeStyles<IAssignmentRulesConditionsWidgetProps>()((theme, props) => ({
    AddConditionButton: {
        marginTop: '.4rem',
    },
    AssignmentRuleBox: {
        marginTop: '.5rem',
    },
}));

export { AssignmentRulesConditionGroup };
