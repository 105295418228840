import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { BlackOnWhiteButton } from '../../../utilities/black-on-white-button';
import { UseFormSetValue } from 'react-hook-form';
import { IUserSettingSSOSamlForm } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoPlaceHolderInfoAlertProps {
    setValue: UseFormSetValue<IUserSettingSSOSamlForm>;
}

const SsoPlaceHolderInfoAlert: React.FC<ISsoPlaceHolderInfoAlertProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { setValue } = props;

    return (
        <Alert severity={'info'}>
            <AlertTitle>Don’t know Issuer URI and Identity Provider Single Sign-On URL?</AlertTitle>
            <Stack spacing={2}>
                <Typography className={cx(classes.TypographyBody)} variant='body2'>
                    Use our placeholder values if your Identity Provider requires more info before generating an Issuer URI and an Identity Provider
                    Single Sign-On URL. After setting up the Identity Provider config initially, you’ll be prompted to go back and update these
                    fields.
                </Typography>
                <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                    <BlackOnWhiteButton
                        size={'small'}
                        onClick={() => {
                            setValue('config.idp_entity_id', 'urn:idp:default', { shouldDirty: true, shouldValidate: true });
                            setValue('config.single_sign_on_service_url', 'https://localhost', { shouldDirty: true, shouldValidate: true });
                        }}
                    >
                        Fill With Placeholder Values
                    </BlackOnWhiteButton>
                </Stack>
            </Stack>
        </Alert>
    );
};

const useStyles = customMakeStyles<ISsoPlaceHolderInfoAlertProps>()((theme, props) => ({
    TypographyBody: { color: theme.palette.grey[400] },
}));

export { SsoPlaceHolderInfoAlert };
