import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IParkingSchedule, IBusinessUnitTreeItem, IResource, ParkingScheduleType } from '@vegaplatformui/models';
import { DrawerSchedulerTabs } from './drawer-scheduler-tabs/drawer-scheduler-tabs';
import { Drawer } from '@mui/material';
import { IParkingScheduleForm } from '../parking/parking-schedules-card';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDrawerSchedulerProps {
    isOpen: boolean;
    width: string;
    resources: IResource[];
    isLoading: boolean;
    scheduleToEdit?: IParkingSchedule;
    enableEditAttachResourcesDrawer?: boolean;
    onCloseDrawer: () => void;
    onSave: (parkingSchedule: IParkingSchedule) => void;
    onChangesMade?: (parkingSchedule: IParkingScheduleForm) => void;
    onDelete?: (parkingSchedule: IParkingSchedule) => void;
    isServerPaginated?: boolean;
    parkingScheduleType: ParkingScheduleType;
    hideResourcesTab?: boolean;
    usersBusinessGroupings: IBusinessUnitTreeItem[];
    loadParkableResources?: (bg_id?: string) => void;
    selectedResources?: IResource[];
    setResourcesSelectedForParking: (resources: IResource[]) => void;
}

const DrawerScheduler: React.FC<IDrawerSchedulerProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Drawer
            slotProps={{ paper: { className: cx(classes.DrawerPaper) } }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isOpen}
            onClose={() => undefined}
        >
            <DrawerSchedulerTabs
                setResourcesSelectedForParking={props.setResourcesSelectedForParking}
                selectedResources={props.selectedResources}
                onCloseDrawer={props.onCloseDrawer}
                scheduleToEdit={props.scheduleToEdit}
                resources={props.resources}
                isLoading={props.isLoading}
                onSave={props.onSave}
                parkingScheduleType={props.parkingScheduleType}
                hideResourcesTab={props.hideResourcesTab}
                usersBusinessGroupings={props.usersBusinessGroupings}
                loadParkableResources={props.loadParkableResources}
            />
        </Drawer>
    );
};

const useStyles = customMakeStyles<IDrawerSchedulerProps>()((theme, props) => ({
    DrawerRoot: {
        zIndex: '1300 !important',
    },
    DrawerPaper: { width: props.width },
}));

export { DrawerScheduler };
