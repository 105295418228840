import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Divider, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { IParkingPriorityGroup, ParkingDelayUnit } from '@vegaplatformui/models';
import { PriorityGroup } from './priority-group';
import { Add, Info } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { areParkingScheduleTabsDirty } from '../../../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPriorityGroupsTabProps {
    priorityGroups: IParkingPriorityGroup[];
    onChangePriorityGroups: (priorityGroups: IParkingPriorityGroup[]) => void;
}

const PriorityGroupsTab: React.FC<IPriorityGroupsTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [areTabsDirty, setAreTabsAreDirty] = useRecoilState(areParkingScheduleTabsDirty);

    const onChangePriorityGroup = (priorityGroup: IParkingPriorityGroup) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        const newPriorityGroups = props.priorityGroups.map((pg) => (pg.priority === priorityGroup.priority ? priorityGroup : pg));
        props.onChangePriorityGroups(newPriorityGroups);
    };

    const onDeletePriorityGroup = (priorityGroup: IParkingPriorityGroup) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        const newPriorityGroups = props.priorityGroups.filter((pg) => pg.priority !== priorityGroup.priority);
        const reorganizedPriorityGroups = newPriorityGroups.map((pg): IParkingPriorityGroup => {
            return {
                ...pg,
                // If the current priority group is less then the one to be removed and the current priority group is now the last item make parking delay 0
                parking_delay: pg.priority < priorityGroup.priority && pg.priority === newPriorityGroups.length - 1 ? 0 : pg.parking_delay,
                priority: pg.priority > priorityGroup.priority ? pg.priority - 1 : pg.priority,
            };
        });
        props.onChangePriorityGroups(reorganizedPriorityGroups);
    };

    const onClickAddPriorityGroup = () => {
        !areTabsDirty && setAreTabsAreDirty(true);
        props.onChangePriorityGroups([
            ...props.priorityGroups,
            {
                priority: props.priorityGroups.length,
                description: '',
                parking_delay: 0,
                parking_delay_unit: ParkingDelayUnit.Seconds,
                unparking_delay: 0,
                unparking_delay_unit: ParkingDelayUnit.Seconds,
                resources: [],
            },
        ]);
    };

    return (
        <Grid alignItems={'flex-start'} justifyContent={'flex-start'} spacing={1} container direction={'row'}>
            <Grid item xs={12} className={cx(classes.PriorityGroupSubtitle)}>
                <Typography variant={'h6'}>Advanced Schedule Configuration</Typography>
                <Typography variant={'subtitle1'}>
                    Set the delay time between priority groups. Delays represent the amount of time priority groups will wait to action resources
                    assuming the previous priority group was successful
                </Typography>
            </Grid>
            <Grid className={cx(classes.PriorityGroupContainer)} item xs={4}>
                <Stack direction={'row'}>
                    <Typography className={cx(classes.PriorityGroupHeaderText)}>Priority Group</Typography>
                    {/*<Tooltip*/}
                    {/*    title={'Priority Group 1 has the highest priority and is the first to park, but is the last to unpark'}*/}
                    {/*    placement={'right'}*/}
                    {/*    arrow*/}
                    {/*>*/}
                    {/*    <Info className={commonStyles.classes.TooltipIcons} />*/}
                    {/*</Tooltip>*/}
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction={'row'}>
                    <Typography className={cx(classes.PriorityGroupHeaderText)}>Starting Delay</Typography>
                    <Tooltip title={'The order will start from the first priority group to the last.'} placement={'top'} arrow>
                        <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                    </Tooltip>
                </Stack>
            </Grid>
            <Grid item xs={3}>
                <Stack direction={'row'}>
                    <Typography className={cx(classes.PriorityGroupHeaderText)}>Stopping Delay</Typography>
                    <Tooltip title={' The order will start from the last priority group to the first.'} placement={'top'} arrow>
                        <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                    </Tooltip>
                </Stack>
            </Grid>
            <Grid className={cx(classes.PriorityGroupContainer)} item xs={2} />
            <Grid item xs={12}>
                {/*props.priorityGroup.priority === 0*/}
                {props.priorityGroups.map((priorityGroup) => (
                    <Stack key={priorityGroup.priority} spacing={1}>
                        <PriorityGroup
                            priorityGroup={priorityGroup}
                            onDeletePriorityGroup={onDeletePriorityGroup}
                            onChangePriorityGroup={onChangePriorityGroup}
                            isUnparkingDisabled={priorityGroup.priority === 0}
                            isParkingDisabled={priorityGroup.priority === props.priorityGroups.length - 1}
                        />
                        <Divider />
                    </Stack>
                ))}
            </Grid>
            <Grid item xs={12}>
                <Button startIcon={<Add />} onClick={onClickAddPriorityGroup} className={cx(classes.AddPriorityGroupButton)}>
                    Priority Group
                </Button>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IPriorityGroupsTabProps>()((theme, props) => ({
    PriorityGroupContainer: {
        marginLeft: '1rem',
    },
    PriorityGroupHeaderText: {
        fontWeight: 'bold',
    },
    AddPriorityGroupButton: { marginTop: '1rem' },
    PriorityGroupSubtitle: { marginBottom: '1rem' },
}));

export { PriorityGroupsTab };
