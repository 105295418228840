import React, { forwardRef, useImperativeHandle } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, ButtonProps, IconButton } from '@mui/material';
import { TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-premium';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomDataGridRenderCellButtonProps {
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
    iconButtonProps?: IconButtonProps;
    buttonProps?: ButtonProps;
}

export interface CustomDataGridRenderCellButtonRef {
    rippleRef: React.RefObject<TouchRippleActions>;
    buttonElement: React.RefObject<HTMLButtonElement>;
}

//Button to be used in render cells to fix hover state clean up when using keyboard navigation
const CustomDataGridRenderCellButton = forwardRef(function (props: ICustomDataGridRenderCellButtonProps, ref) {
    const { classes, cx } = useStyles(props);
    const { hasFocus, value } = props.params;
    const buttonElement = React.useRef<HTMLButtonElement>(null);
    const rippleRef = React.useRef<TouchRippleActions>(null);

    React.useLayoutEffect(() => {
        if (hasFocus) {
            const input = buttonElement.current!.querySelector('input');
            input?.focus();
        } else if (rippleRef.current) {
            // Only available in @mui/material v5.4.1 or later
            rippleRef.current.stop({} as any);
        }
    }, [hasFocus]);

    return props.buttonProps ? (
        <Button {...props.buttonProps} ref={buttonElement} touchRippleRef={rippleRef} />
    ) : props.iconButtonProps ? (
        <IconButton {...props.iconButtonProps} ref={buttonElement} touchRippleRef={rippleRef} />
    ) : (
        <></>
    );
});

const useStyles = customMakeStyles<ICustomDataGridRenderCellButtonProps>()((theme, props) => ({}));

export { CustomDataGridRenderCellButton };
