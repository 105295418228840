import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Alert, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid2, Stack, Typography } from '@mui/material';
import { IParkingScheduleExemption, IParkingScheduleSummary, ITimezone, IUser } from '@vegaplatformui/models';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { FormField } from '../../forms/form-field';
import { IsEditingExemption, timezonesAtom } from '../../jotai/atom';
import { TimezoneFormField } from '../../utilities/timezone-form-field';
import { useAtom, useAtomValue } from 'jotai';
dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangeScheduleStatusScheduledContentProps {
    onChangeScheduleStatuses: (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimezone
    ) => void;
    schedulesToChangeStatusOn: IParkingScheduleSummary[];
    onClose: () => void;
}

const ChangeScheduleStatusScheduledContent: React.FC<IChangeScheduleStatusScheduledContentProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const timezones = useAtomValue(timezonesAtom);
    const [isEditingExemption, setIsEditingExemption] = useAtom(IsEditingExemption);
    const [enabled, setIsEnabled] = React.useState<boolean>(false);
    const [exemptionToEdit, setExemptionToEdit] = React.useState<IParkingScheduleExemption | undefined>(undefined);
    const [disableStartDateTime, setDisableStartDateTime] = React.useState<Date | undefined>(
        (exemptionToEdit && dayjs(exemptionToEdit.starts_at).toDate()) ?? undefined
    );
    const [enableStartDateTime, setEnableStartDateTime] = React.useState<Date | undefined>(
        (exemptionToEdit && dayjs(exemptionToEdit.ends_at).toDate()) ?? undefined
    );
    const [shouldStartNow, setShouldStartNow] = React.useState<boolean>(false);
    const [timezone, setTimezone] = React.useState<ITimezone | null>(
        timezones.find((t: ITimezone) => t.tzCode === exemptionToEdit?.time_zone_str) ??
            timezones.find((t: ITimezone) => {
                return t.tzCode === dayjs.tz.guess();
            }) ??
            null
    );
    const [previousStartDateTime, setPreviousStartDateTime] = React.useState<Date | undefined>(undefined);
    const [isCurrentlyExempt, setIsCurrentlyExempt] = React.useState<boolean>(false);
    const [isExemptExpired, setIsExemptExpired] = React.useState<boolean>(false);

    useEffect(() => {
        if (isEditingExemption && props.schedulesToChangeStatusOn.length === 1 && props.schedulesToChangeStatusOn[0].exemption !== null) {
            setExemptionToEdit(props.schedulesToChangeStatusOn[0].exemption);
            setIsCurrentlyExempt(props.schedulesToChangeStatusOn[0].is_currently_exempt);
            if (dayjs(props.schedulesToChangeStatusOn[0].exemption.ends_at) < dayjs()) {
                setIsExemptExpired(true);
            }
        }
    }, [props.schedulesToChangeStatusOn]);

    useEffect(() => {
        if (isEditingExemption && exemptionToEdit !== undefined) {
            setDisableStartDateTime(dayjs(exemptionToEdit?.starts_at).toDate());
            setEnableStartDateTime(dayjs(exemptionToEdit?.ends_at).toDate());
            setTimezone(
                timezones.find((t: ITimezone) => t.tzCode === exemptionToEdit?.time_zone_str) ??
                    timezones.find((t: ITimezone) => {
                        return t.tzCode === dayjs.tz.guess();
                    }) ??
                    null
            );
        }
    }, [exemptionToEdit]);
    const onClose = () => {
        props.onClose();
        setIsEditingExemption(false);
        setIsCurrentlyExempt(false);
        setIsExemptExpired(false);
    };
    return (
        <>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>
                <Grid2 container direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                    {exemptionToEdit && isCurrentlyExempt && (
                        <Grid2 size={12}>
                            <Alert severity={'info'} color={'error'}>
                                <Typography variant={'caption'}>
                                    Schedule is currently disabled until:{' '}
                                    {dayjs(exemptionToEdit?.ends_at).tz(timezone?.tzCode).format('MM/DD/YYYY hh:mm A (Z)')}.
                                </Typography>
                            </Alert>
                        </Grid2>
                    )}
                    {exemptionToEdit && isExemptExpired && (
                        <Grid2 size={12}>
                            <Alert severity={'info'} color={'error'}>
                                <Typography variant={'caption'}>
                                    The scheduled Disable Between has expired. Please either delete or update it with a valid date range to set a new
                                    Disable Between period.
                                </Typography>
                            </Alert>
                        </Grid2>
                    )}
                    <Grid2 size={12}>
                        <Stack spacing={0}>
                            <Typography variant={'h6'} fontWeight={500}>
                                {props.schedulesToChangeStatusOn.length > 1 &&
                                    `${enabled ? 'Enable' : 'Disable'} between for ${props.schedulesToChangeStatusOn.length} schedules`}
                                {props.schedulesToChangeStatusOn.length === 1 &&
                                    `${enabled ? 'Enable' : 'Disable'} between for ${props.schedulesToChangeStatusOn[0]?.name}`}
                            </Typography>
                            <Typography variant={'body2'}>{`Choose a time range to disable the ${
                                props.schedulesToChangeStatusOn.length === 1
                                    ? 'schedule'
                                    : props.schedulesToChangeStatusOn.length > 1
                                      ? 'schedules'
                                      : ''
                            }, overriding any attached
                            schedules and priority groups. Note: The ${enabled ? 'enabled' : 'disabled'} status doesn't trigger immediate action;
                            resources will persist in their current running or stopped state until the schedule becomes
                            active again within the designated timeframe.`}</Typography>
                        </Stack>
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                {!enabled ? (
                    <Stack className={cx(classes.DateTimePickerContainer)} spacing={2}>
                        <TimezoneFormField setTimezone={setTimezone} timezone={timezone} />
                        <FormField label={'Select start date and time'} htmlFor={'start_date_time'}>
                            <Stack>
                                <DateTimePicker
                                    timezone={timezone && timezone.tzCode === 'Europe/London' ? 'UTC' : (timezone?.tzCode ?? undefined)}
                                    disablePast={!shouldStartNow && !isCurrentlyExempt}
                                    disabled={shouldStartNow || isCurrentlyExempt}
                                    disableOpenPicker={shouldStartNow}
                                    value={disableStartDateTime !== undefined ? dayjs(disableStartDateTime) : null}
                                    onChange={(event: any) => {
                                        event ? setDisableStartDateTime(event['$d']) : setDisableStartDateTime(undefined);
                                    }}
                                    slotProps={{ popper: { sx: { zIndex: 1302 } }, textField: { fullWidth: true, size: 'small' } }}
                                    label='Select start date and time'
                                />
                                <FormControlLabel
                                    className={cx(classes.StartNowFormLabel)}
                                    control={
                                        <Checkbox
                                            className={cx(classes.StartNowCheckbox)}
                                            checked={shouldStartNow}
                                            disabled={isCurrentlyExempt}
                                            onChange={() => {
                                                const now = new Date();
                                                if (!shouldStartNow) {
                                                    setPreviousStartDateTime(disableStartDateTime);
                                                    setDisableStartDateTime(now);
                                                } else {
                                                    setDisableStartDateTime(previousStartDateTime ?? undefined);
                                                }
                                                setShouldStartNow(!shouldStartNow);
                                            }}
                                        />
                                    }
                                    label='Start now'
                                />
                            </Stack>
                        </FormField>
                        <FormField label={'Select end date and time'} htmlFor={'end_date_time'}>
                            <DateTimePicker
                                timezone={timezone && timezone.tzCode === 'Europe/London' ? 'UTC' : (timezone?.tzCode ?? undefined)}
                                disablePast={true}
                                minDateTime={dayjs(disableStartDateTime).tz(timezone?.tzCode)}
                                value={enableStartDateTime !== undefined ? dayjs(enableStartDateTime) : null}
                                disabled={disableStartDateTime === undefined}
                                onChange={(event: any) => {
                                    event ? setEnableStartDateTime(event['$d']) : setEnableStartDateTime(undefined);
                                }}
                                slotProps={{ popper: { sx: { zIndex: 1302 } }, textField: { fullWidth: true, size: 'small' } }}
                                label='Select end date and time'
                            />
                        </FormField>
                    </Stack>
                ) : (
                    <Stack className={cx(classes.DateTimePickerContainer)} spacing={2}>
                        <TimezoneFormField setTimezone={setTimezone} timezone={timezone} />
                        <FormField label={'Select start date and time'} htmlFor={'start_date_time'}>
                            <Stack>
                                <DateTimePicker
                                    disablePast={!shouldStartNow && !isCurrentlyExempt}
                                    timezone={timezone && timezone.tzCode === 'Europe/London' ? 'UTC' : (timezone?.tzCode ?? undefined)}
                                    value={enableStartDateTime !== undefined ? dayjs(enableStartDateTime) : null}
                                    onChange={(event: any) => {
                                        event ? setEnableStartDateTime(event['$d']) : setEnableStartDateTime(undefined);
                                    }}
                                    slotProps={{ popper: { sx: { zIndex: 1302 } }, textField: { fullWidth: true, size: 'small' } }}
                                    label='Select start date and time'
                                    disabled={shouldStartNow || isCurrentlyExempt}
                                />
                                <FormControlLabel
                                    className={cx(classes.StartNowFormLabel)}
                                    control={
                                        <Checkbox
                                            className={cx(classes.StartNowCheckbox)}
                                            checked={shouldStartNow}
                                            disabled={isCurrentlyExempt}
                                            onChange={() => {
                                                const now = new Date();
                                                if (!shouldStartNow) {
                                                    setPreviousStartDateTime(disableStartDateTime);
                                                    setEnableStartDateTime(now);
                                                } else {
                                                    setEnableStartDateTime(previousStartDateTime ?? undefined);
                                                }
                                                setShouldStartNow(!shouldStartNow);
                                            }}
                                        />
                                    }
                                    label='Start now'
                                />
                            </Stack>
                        </FormField>
                        <FormField label={'Select end date and time'} htmlFor={'end_date_time'}>
                            <DateTimePicker
                                disablePast={true}
                                timezone={timezone && timezone.tzCode === 'Europe/London' ? 'UTC' : (timezone?.tzCode ?? undefined)}
                                value={disableStartDateTime !== undefined ? dayjs(disableStartDateTime) : null}
                                minDateTime={dayjs(enableStartDateTime).tz(timezone?.tzCode)}
                                disabled={enableStartDateTime === undefined}
                                onChange={(event: any) => {
                                    event ? setDisableStartDateTime(event['$d']) : setDisableStartDateTime(undefined);
                                }}
                                slotProps={{ popper: { sx: { zIndex: 1302 } }, textField: { fullWidth: true, size: 'small' } }}
                                label='Select end date and time'
                            />
                        </FormField>
                    </Stack>
                )}
                <>
                    {/*TODO: Can be used once we might want an enable between or disable between */}
                    {/*<Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={0}>*/}
                    {/*    <Typography>Disable Between</Typography>*/}
                    {/*    <Switch*/}
                    {/*        onChange={() => {*/}
                    {/*            setEnableStartDateTime(undefined);*/}
                    {/*            setDisableStartDateTime(undefined);*/}
                    {/*            setIsEnabled(!enabled);*/}
                    {/*        }}*/}
                    {/*        checked={enabled}*/}
                    {/*    />*/}
                    {/*    <Typography>Enable Between</Typography>*/}
                    {/*</Stack>*/}
                    {/*<DialogContentText variant={'body2'} id='confirm-close-dialog-description'>*/}
                    {/*    Selected schedules will*/}
                    {/*    {!enabled ? ' not ' : ' '}*/}
                    {/*    execute during the scheduled times throughout the selected timeframe.*/}
                    {/*</DialogContentText>*/}
                </>
            </DialogContent>
            <DialogActions className={commonStyles.classes.FormActionsPadding}>
                <Stack direction={'row'} spacing={1.5} justifyContent={'flex-end'} width={'100%'}>
                    <Button variant={'cancel'} onClick={onClose} autoFocus>
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            disableStartDateTime === undefined ||
                            enableStartDateTime === undefined ||
                            timezone === null ||
                            (isExemptExpired && !shouldStartNow && dayjs(disableStartDateTime) < dayjs()) ||
                            (isExemptExpired && dayjs(enableStartDateTime) < dayjs())
                        }
                        onClick={() =>
                            props.onChangeScheduleStatuses(
                                props.schedulesToChangeStatusOn,
                                enabled,
                                disableStartDateTime,
                                enableStartDateTime,
                                timezone!
                            )
                        }
                    >
                        {exemptionToEdit ? 'Save' : 'Create'}
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
};

const useStyles = customMakeStyles<IChangeScheduleStatusScheduledContentProps>()((theme, props) => ({
    CancelButton: { marginTop: '-3rem', marginRight: '-1.25rem' },
    DateTimePickerContainer: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    TimeZoneInputLabel: {
        marginTop: '-.5rem',
        color: '#888',
    },
    Dropdowns: {
        minWidth: '100%',
        zIndex: 1302,
    },
    StartNowCheckbox: { pointerEvents: 'auto' },
    StartNowFormLabel: { pointerEvents: 'none' },
}));

export { ChangeScheduleStatusScheduledContent };
