import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps, Stack, Typography } from '@mui/material';

import { ICloudProviderAccount } from '@vegaplatformui/models';
import Draggable from 'react-draggable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmDeleteCloudAccountsBatchDialogProps {
    selectedAccounts: ICloudProviderAccount[];
    isConfirmDeleteDialogBatchOpen: boolean;
    onCloseConfirmDeleteBatchDialog: () => void;
    confirmDeleteAccounts: () => void;
}

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle='#confirm-delete-accounts-dialog' cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}
const ConfirmDeleteCloudAccountsBatchDialog: React.FC<IConfirmDeleteCloudAccountsBatchDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    return (
        <Dialog
            fullWidth
            open={props.isConfirmDeleteDialogBatchOpen}
            onClose={props.onCloseConfirmDeleteBatchDialog}
            PaperComponent={PaperComponent}
            aria-labelledby='confirm-delete-accounts-dialog'
        >
            <DialogTitle
                className={cx(commonStyles.classes.FormTitlePadding, commonStyles.classes.FormTitle)}
                style={{ cursor: 'move' }}
                id='confirm-delete-accounts-dialog'
            >
                <Typography className={cx(classes.DeleteTitle)} fontWeight={'600'} variant={'h6'}>
                    Are you sure you want to delete {props.selectedAccounts.length} accounts?
                </Typography>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Typography variant={'subtitle1'}> You cannot undo this action.</Typography>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack height={'100%'} direction={'row'} spacing={1.5}>
                    <Button
                        variant={'cancel'}
                        className={cx(commonStyles.classes.LowercaseTextButton)}
                        disableElevation={true}
                        color={'secondary'}
                        autoFocus
                        onClick={props.onCloseConfirmDeleteBatchDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={cx(commonStyles.classes.LowercaseTextButton)}
                        color={'error'}
                        variant={'contained'}
                        onClick={() => {
                            props.confirmDeleteAccounts();
                        }}
                        disableElevation={true}
                    >
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmDeleteCloudAccountsBatchDialogProps>()((theme, props) => ({
    DeleteTitle: { overflowWrap: 'anywhere' },
}));

export { ConfirmDeleteCloudAccountsBatchDialog };
