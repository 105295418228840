import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    CloudProviderAccountsCard,
    queryKeys,
    SnackbarErrorOutput,
    SnackBarOptions,
    useCloudProviderAccountApi,
    useWebsocketApi,
} from '@vegaplatformui/sharedcomponents';
import { useSetRecoilState } from 'recoil';
import { useKeycloak } from '@react-keycloak-fork/web';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { CloudProviderAccountApi } from '@vegaplatformui/apis';
import {
    CloudProvidersTypes,
    DiscoveryEvents,
    ICloudProviderAccount,
    IPostCloudAccountsBatchResponse,
    LinkAwsAccountForm,
    LinkAzureAccountForm,
    LinkGcpAccountForm,
    LinkOciAccountForm,
} from '@vegaplatformui/models';
import { AxiosResponse } from 'axios';
import { useQueryClient } from '@tanstack/react-query';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICloudProviderAccountsControllerProps {}

const CloudProviderAccountsController: React.FC<ICloudProviderAccountsControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const queryClient = useQueryClient();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const [selectedAccounts, setSelectedAccounts] = useState<ICloudProviderAccount[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [providerFiles, setProviderFiles] = useState<File[]>([]);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [accountToDelete, setAccountToDelete] = useState<ICloudProviderAccount>();
    const [accountToEdit, setAccountToEdit] = useState<ICloudProviderAccount | undefined>(undefined);
    const [isEditAwsAccountDialogOpen, setIsEditAwsAccountDialogOpen] = useState(false);
    const [isEditAzureAccountDialogOpen, setIsEditAzureAccountDialogOpen] = useState(false);
    const [isEditGcpAccountDialogOpen, setIsEditGcpAccountDialogOpen] = useState(false);
    const [isEditOciAccountDialogOpen, setIsEditOciAccountDialogOpen] = useState(false);
    const [serviceAccountJson, setServiceAccountJson] = useState<string>(``);
    const {
        accounts,
        awsParentAccounts,
        isAccountsLoading,
        submitLinkOciAccountForm,
        submitEditAzureAccountForm,
        submitEditAwsAccountForm,
        submitLinkAwsAccountForm,
        submitLinkAzureAccountForm,
        submitLinkGcpAccountForm,
        submitEditOciAccountForm,
        submitEditGcpAccountForm,
        deleteAccount,
        deleteSelectedAccounts,
    } = useCloudProviderAccountApi({ setIsLoading, setSelectedAccounts });
    const { keycloak } = useKeycloak();

    const cloudAccountApi = new CloudProviderAccountApi();
    cloudAccountApi.keycloak = keycloak;

    const { sendJsonMessage } = useWebsocketApi({
        loadData: () => queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] }),
    });

    const handleClickSendDiscoveryRequest = (accountIds: string[]) => {
        accountIds.length === 0
            ? sendJsonMessage({ event: DiscoveryEvents.RequestDiscoveryAll })
            : sendJsonMessage({ event: DiscoveryEvents.RequestDiscovery, data: { accounts: accountIds } });
    };

    const getCloudAccountDetails = (internalId: string, cloudAccountId: string) => {
        cloudAccountApi
            .getCloudAccount({ id: internalId })
            .then((response) => {
                setAccountToEdit(response?.data);
                setServiceAccountJson(response.data.secret_json ?? ``);
                onEditDialogOpen(response?.data);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was a problem getting cloud provider account details for ${cloudAccountId}: ${SnackbarErrorOutput(error)}`,
                });
                setAccountToEdit(undefined);
            });
    };

    const onClickDeleteAccount = (account: ICloudProviderAccount) => {
        deleteAccount(account);
    };

    const onClickDeleteSelectedAccounts = () => {
        const selectedAccountIds = selectedAccounts.map((selectedAccount) => selectedAccount.id);
        deleteSelectedAccounts(selectedAccountIds);
    };

    const onSubmitLinkAwsAccountForm = (data: LinkAwsAccountForm) => {
        submitLinkAwsAccountForm(data);
    };

    const onSubmitLinkAzureAccountForm = (data: LinkAzureAccountForm) => {
        submitLinkAzureAccountForm(data);
    };

    const onSubmitLinkGcpAccountForm = (data: LinkGcpAccountForm) => {
        submitLinkGcpAccountForm(data);
    };

    const onSubmitLinkOciAccountForm = (data: LinkOciAccountForm) => {
        submitLinkOciAccountForm(data);
    };

    const onSubmitEditAwsAccountForm = (data: LinkAwsAccountForm) => {
        accountToEdit && submitEditAwsAccountForm({ request: data, accountToEdit });
    };
    const onSubmitEditAzureAccountForm = (data: LinkAzureAccountForm) => {
        accountToEdit && submitEditAzureAccountForm({ request: data, accountToEdit });
    };

    const onSubmitEditGcpAccountForm = (data: LinkGcpAccountForm) => {
        accountToEdit && submitEditGcpAccountForm({ request: data, accountToEdit });
    };

    const onSubmitEditOciAccountForm = (data: LinkOciAccountForm) => {
        accountToEdit && submitEditOciAccountForm({ request: data, accountToEdit });
    };

    function BulkSnackbarSuccessMessage(response: AxiosResponse<IPostCloudAccountsBatchResponse, any>) {
        return (
            <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={1}>
                <Typography variant={'h6'}>
                    {response.data.number_of_failed_rows < 1 ? 'Account creations successful' : 'Account creations successful with failures'}
                </Typography>
                <Typography variant={'body2'}>Accounts successfully created: {response.data.number_of_successful_rows}</Typography>
                <Typography variant={'body2'}>Accounts failed to created: {response.data.number_of_failed_rows}</Typography>
                {response.data.number_of_failed_rows > 0 && (
                    <List className={cx(classes.IntructionList)}>
                        <Stack
                            className={cx(classes.InstructionStack)}
                            direction='column'
                            justifyContent='center'
                            alignItems='flex-start'
                            spacing={0}
                        >
                            {response.data.failed_rows.map((message) => (
                                <ListItem key={message} className={cx(classes.InstructionStep)}>
                                    <Typography variant={'caption'}>{message}</Typography>
                                </ListItem>
                            ))}
                        </Stack>
                    </List>
                )}
            </Stack>
        );
    }

    const onSubmitBulkAccount = (file: Blob) => {
        setIsLoading(true);
        cloudAccountApi
            .createCloudAccounts({ file: file })
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 10000 },
                    alertProps: { severity: response.data.number_of_failed_rows < 1 ? 'info' : 'warning' },
                    message: BulkSnackbarSuccessMessage(response),
                });
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was a problem creating the cloud accounts: ${SnackbarErrorOutput(error)}`,
                });
            })
            .finally(() => {
                setIsLoading(false);
                setProviderFiles([]);
                queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
            });
    };

    const onEditDialogOpen = (account: ICloudProviderAccount | undefined) => {
        switch (account?.provider_str.toLowerCase()) {
            case CloudProvidersTypes.Aws:
                queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.getAwsParentProviderAccounts] });
                setIsEditAwsAccountDialogOpen(true);
                break;
            case CloudProvidersTypes.Azure:
                setIsEditAzureAccountDialogOpen(true);
                break;
            case CloudProvidersTypes.Gcp:
                setIsEditGcpAccountDialogOpen(true);
                break;
            case CloudProvidersTypes.Oci:
                setIsEditOciAccountDialogOpen(true);
                break;
        }
    };

    const onCloseEditDialog = () => {
        switch (accountToEdit?.provider_str.toLowerCase()) {
            case CloudProvidersTypes.Aws:
                setIsEditAwsAccountDialogOpen(false);
                break;
            case CloudProvidersTypes.Azure:
                setIsEditAzureAccountDialogOpen(false);
                break;
            case CloudProvidersTypes.Gcp:
                setIsEditGcpAccountDialogOpen(false);
                break;
            case CloudProvidersTypes.Oci:
                setIsEditOciAccountDialogOpen(false);
                break;
        }
        setTimeout(() => {
            setAccountToEdit(undefined);
            setServiceAccountJson(``);
        }, 150);
    };

    return (
        <Stack direction={'column'} spacing={1}>
            <CloudProviderAccountsCard
                getCloudAccountDetails={getCloudAccountDetails}
                accountToEdit={accountToEdit}
                setAccountToEdit={setAccountToEdit}
                setSelectedFiles={setProviderFiles}
                selectedFiles={providerFiles}
                isFilesLoading={false}
                onSubmitBulkAccount={onSubmitBulkAccount}
                selectedAccounts={selectedAccounts}
                awsParentAccounts={awsParentAccounts}
                cloudProviderAccounts={accounts}
                setSelectedAccounts={setSelectedAccounts}
                isLoading={isLoading || isAccountsLoading}
                onClickDeleteAccount={onClickDeleteAccount}
                onClickDeleteSelectedAccounts={onClickDeleteSelectedAccounts}
                onSubmitLinkAwsAccountForm={onSubmitLinkAwsAccountForm}
                onSubmitLinkAzureAccountForm={onSubmitLinkAzureAccountForm}
                onSubmitLinkGcpAccountForm={onSubmitLinkGcpAccountForm}
                onSubmitLinkOciAccountForm={onSubmitLinkOciAccountForm}
                onSubmitEditAwsAccountForm={onSubmitEditAwsAccountForm}
                onSubmitEditAzureAccountForm={onSubmitEditAzureAccountForm}
                onSubmitEditGcpAccountForm={onSubmitEditGcpAccountForm}
                onSubmitEditOciAccountForm={onSubmitEditOciAccountForm}
                accountToDelete={accountToDelete}
                confirmDeleteAccount={onClickDeleteAccount}
                setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
                isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
                setAccountToDelete={setAccountToDelete}
                onCloseEditDialog={onCloseEditDialog}
                isEditAwsAccountDialogOpen={isEditAwsAccountDialogOpen}
                isEditAzureAccountDialogOpen={isEditAzureAccountDialogOpen}
                isEditGcpAccountDialogOpen={isEditGcpAccountDialogOpen}
                isEditOciAccountDialogOpen={isEditOciAccountDialogOpen}
                serviceAccountJson={serviceAccountJson}
                setServiceAccountJson={setServiceAccountJson}
                handleClickSendDiscoveryRequest={handleClickSendDiscoveryRequest}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<ICloudProviderAccountsControllerProps>()((theme, props) => ({
    IntructionList: { listStyleType: 'disc', pl: 1 },
    InstructionStack: { marginLeft: '1.2rem' },
    InstructionStep: { display: 'list-item' },
}));

export { CloudProviderAccountsController };
