import { ResourcesEntity } from './recommendations-types';
import { IResource } from './resource-types';

export interface IActionResponse {
    type: string;
    id: string;
}
export interface IPostTakeActionRequest {
    resources: IResource[];
    recommendationId: string;
    schedule?: string;
    timezone?: string;
}

export interface IPostTakeImmediateActionRequest {
    resourceId: string;
    action: string;
}

export interface IPutPoliciesRequest {
    policyId: string;
    data: any;
}

export interface IMutateScheduleStatusesHookObject {
    schedules: IParkingScheduleSummary[];
    isEnabled: boolean;
    starts_at?: Date;
    ends_at?: Date;
    timeZone?: string;
}

export interface IUpdateScheduleExemptionsHookObject {
    schedule: IParkingScheduleSummary;
    isEnabled: boolean;
    starts_at: Date;
    ends_at: Date;
    timeZone: string;
}

export interface IPostBatchPolicyStatusUpdateRequest {
    policies: IParkingScheduleSummary[];
    is_enabled: boolean;
}

export interface IPostBatchPolicyExemptionRequest {
    policies: IParkingScheduleSummary[];
    is_enabled: boolean;
    starts_at: string;
    ends_at: string;
    time_zone_str: string;
}

export interface IPutPolicyExemptionRequest {
    is_enabled: boolean;
    starts_at: string;
    ends_at: string;
    time_zone_str: string;
}

export interface IGetOptimizedResourceSchedules {
    scheduled_on_times: string[];
    resource_ids: string[];
    timezone: string;
}

export interface IGetOptimizedResourceSchedulesHookRequest {
    resource_ids: string[];
    setSchedule: React.Dispatch<React.SetStateAction<Date[]>>;
}

export enum RecommendationActionType {
    park = 'park',
    unpark = 'unpark',
}

export interface IParkingSchedule_Deprecated {
    id?: string;
    name?: string;
    description?: string;
    is_active?: boolean;
    utc_offset?: string;
    scheduled_on_times?: Date[];
    resources?: IResource[];
    num_hourly_chunks?: number;
    last_modified?: Date;
    observe_daylight_savings?: boolean;
    recommendation_id?: string;
}

export interface IParkingScheduleSummary {
    id: string;
    name: string;
    is_enabled: boolean;
    exemption: IParkingScheduleExemption;
    status: ParkingScheduleStatus;
    business_group_name: string;
    type_str: ParkingScheduleType;
    description: string;
    utc_offset: string;
    timezone: string;
    number_of_resources: number;
    is_currently_exempt: boolean;
    updated_at: Date;
}

export enum ParkingScheduleStatus {
    Disabled = 'disabled',
    Active = 'active',
    Inactive = 'inactive',
}

export interface IParkingScheduleExemption {
    id: string;
    parking_priority_group_id: string;
    is_enabled: boolean;
    starts_at: string;
    ends_at: string;
    time_zone_str: string;
}

export interface IGetParkingPolicySummariesResponse {
    total_rows: number;
    policies: IParkingScheduleSummary[];
}

export interface IParkingSchedule {
    id?: string;
    is_enabled: boolean;
    name: string;
    business_group_id?: string;
    description?: string;
    intervals: number; // 1, 2, 4  number of intervals an hour with 1 being entire hour and 4 being 15 minute chunks
    timezone: string; // "aka America/Los_Angeles"
    scheduled_on_times?: Date[];
    updated_at?: Date;
    priority_groups: IParkingPriorityGroup[];
    type_str: ParkingScheduleType;
    recommendation_id?: string; // optional if the schedule was createde in association with a recommendation this is the id of the recommendation
}

export interface IParkingPriorityGroup {
    id?: string;
    priority: number; // the order in which to park the group starting at 0
    description: string;
    parking_delay: number; // in seconds or minutes
    parking_delay_unit: ParkingDelayUnit; // "seconds" or "minutes"
    unparking_delay: number; // in seconds
    unparking_delay_unit: ParkingDelayUnit; // "seconds" or "minutes"
    updated_at?: Date;
    resources: IResource[];
}

export enum ParkingScheduleType {
    Basic = 'basic',
    Advanced = 'advanced',
}

export enum ParkingDelayUnit {
    Minutes = 'minutes',
    Seconds = 'seconds',
}

enum VegaAction {
    Park = 'park',
    Unpark = 'unpark',
}
