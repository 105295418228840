import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { BusinessGroupingTreeItem } from './business-grouping-tree-item';
import { IBusinessGroupingType, IBusinessGroupTreeItem, IUserBusinessGroups } from '@vegaplatformui/models';
import { Box, Button, Skeleton, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { SimpleTreeView } from '@mui/x-tree-view';
import useBusinessGroupingsApi from '../../api-hooks/business-groupings-hooks/use-business-groupings-api';
import { v4 as uuidv4 } from 'uuid';
import { BusinessGroupingTreeViewColumnHeader } from './business-grouping-tree-view-column-header';
import { ExpandedBusinessGroupTreeNodes } from '../../jotai/atom';
import { useTheme } from '@mui/material/styles';
import { useAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupTreeViewProps {
    initialBusinessGroups: IBusinessGroupTreeItem[];
    onClickOpenEditBusinessGroupingDialog: (grouping: IBusinessGroupTreeItem) => void;
    usersBusinessGroupings: IUserBusinessGroups[];
    businessGroupingTypes: IBusinessGroupingType[];
}

const BusinessGroupingTreeView: React.FC<IBusinessGroupTreeViewProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [expandedNodes, setExpandedNodes] = useAtom<string[]>(ExpandedBusinessGroupTreeNodes);
    const [isExpandAll, setIsExpandAll] = useState<boolean>(false);
    const businessGroupingsApi = useBusinessGroupingsApi({});
    const theme = useTheme();
    const isSmaller922PxWidth = useMediaQuery(theme.breakpoints.down(922), {
        noSsr: true,
    });
    const isSmaller650PxWidth = useMediaQuery(theme.breakpoints.down(650), {
        noSsr: true,
    });

    //handle toggles as they occur
    const onToggleNodes = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpandedNodes(nodeIds);
    };

    const onHandleExpandAllClick = () => {
        if (isExpandAll || expandedNodes.length > 0) {
            setExpandedNodes([]);
            setIsExpandAll(false);
        } else {
            setExpandedNodes([]);
            setExpandedNodes([...props.initialBusinessGroups.map((bg) => bg.id), ...props.usersBusinessGroupings.map((bg) => bg.id)]);
            setIsExpandAll(true);
        }
    };

    const onChangeExpandAll = () => {
        setIsExpandAll(false);
    };

    return (
        <>
            <Box className={cx(classes.ExpandAllButtonContainer)}>
                <Button
                    size={'small'}
                    variant={'text'}
                    onClick={onHandleExpandAllClick}
                    endIcon={!isExpandAll && expandedNodes.length === 0 ? <ExpandMore /> : <ExpandLess />}
                >
                    {!isExpandAll && expandedNodes.length === 0 ? 'Expand all business groupings' : 'Collapse all business groupings'}
                </Button>
            </Box>
            <BusinessGroupingTreeViewColumnHeader isSmaller922PxWidth={isSmaller922PxWidth} />
            <SimpleTreeView
                className={cx(classes.TreeView)}
                slots={{ collapseIcon: KeyboardArrowDown, expandIcon: KeyboardArrowRight }}
                expandedItems={expandedNodes}
                onExpandedItemsChange={onToggleNodes}
            >
                {businessGroupingsApi.isInitialBusinessGroupingsLoading ? (
                    <Skeleton key={uuidv4()} variant='text' width={'100%'} height={'2rem'} />
                ) : (
                    props.initialBusinessGroups.map((initialBusinessGroup) => (
                        <BusinessGroupingTreeItem
                            amountToShiftColumnsLeft={0}
                            isInitialGroup={true}
                            businessGroupingTypes={props.businessGroupingTypes}
                            onClickOpenEditBusinessGroupingDialog={props.onClickOpenEditBusinessGroupingDialog}
                            onChangeExpandAll={onChangeExpandAll}
                            setExpandedNodes={setExpandedNodes}
                            key={initialBusinessGroup?.id}
                            isExpandAll={isExpandAll}
                            businessGroupTreeItem={initialBusinessGroup}
                            expandedNodes={expandedNodes}
                            isSmaller650PxWidth={isSmaller650PxWidth}
                            isSmaller922PxWidth={isSmaller922PxWidth}
                        />
                    ))
                )}
            </SimpleTreeView>
        </>
    );
};

const useStyles = customMakeStyles<IBusinessGroupTreeViewProps>()((theme, props) => ({
    ExpandAllButtonContainer: { marginBottom: '.5rem' },
    TreeView: {
        '& .MuiTreeItem-content': {
            '&:hover': {
                backgroundColor: `${theme.palette.primary.light}20`,
            },
        },
    },
}));

export { BusinessGroupingTreeView };
