import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
  IAssignmentRuleConditionGroup,
  IAssignmentRuleFormValue,
} from '@vegaplatformui/models';
import { AssignmentRulesConditionGroup } from './assignment-rules-condition-group';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesConditonMultiGroupsProps {
  assignmentRuleConditionGroups: IAssignmentRuleConditionGroup[];
  assignmentRuleFormValues: IAssignmentRuleFormValue[];
  onChangeAssignmentRuleConditionGroups: (
    assignmentRuleConditionGroups: IAssignmentRuleConditionGroup[]
  ) => void;
  errors:
    | Merge<
        FieldError,
        (
          | Merge<FieldError, FieldErrorsImpl<IAssignmentRuleConditionGroup>>
          | undefined
        )[]
      >
    | undefined;
}

const AssignmentRulesConditonMultiGroups: React.FC<
  IAssignmentRulesConditonMultiGroupsProps
> = (props) => {
  const { classes, cx } = useStyles(props);
  const theme = useTheme();

  const onChangeAssignmentRuleConditionGroup = (
    assignmentRuleConditionGroup: IAssignmentRuleConditionGroup
  ) => {
    const assignmentRuleConditionGroups =
      props.assignmentRuleConditionGroups.map((arc) => {
        if (arc.idx === assignmentRuleConditionGroup.idx) {
          return assignmentRuleConditionGroup;
        }

        return arc;
      });

    props.onChangeAssignmentRuleConditionGroups(assignmentRuleConditionGroups);
  };

  const onRemoveAssignmentRuleConditionGroup = (
    assignmentRuleConditionGroup: IAssignmentRuleConditionGroup
  ) => {
    // remove item and fix indexes
    const index = props.assignmentRuleConditionGroups.findIndex(
      (arc) => arc.idx === assignmentRuleConditionGroup.idx
    );
    const assignmentRuleConditionGroups =
      props.assignmentRuleConditionGroups.filter(
        (arc) => arc.idx !== assignmentRuleConditionGroup.idx
      );
    assignmentRuleConditionGroups.forEach((arc) => {
      if (arc.idx > index) {
        arc.idx--;
      }
    });

    assignmentRuleConditionGroups[0].combinator = 'and';

    props.onChangeAssignmentRuleConditionGroups(assignmentRuleConditionGroups);
  };

  const onChangeCombinatorValue = (
    assignmentRuleConditionGroup: IAssignmentRuleConditionGroup,
    combinator: string
  ) => {
    onChangeAssignmentRuleConditionGroup({
      ...assignmentRuleConditionGroup,
      combinator: combinator,
    });
  };

  return (
    <>
      {props.assignmentRuleConditionGroups
        .sort((a, b) => a.idx - b.idx)
        .map((assignmentRuleConditionGroup, index) => (
          <div key={index}>
            {assignmentRuleConditionGroup.idx > 1 && (
              <FormControl
                size={'small'}
                sx={{ maxWidth: '7rem' }}
                key={assignmentRuleConditionGroup.idx}
              >
                <ToggleButtonGroup
                  classes={{ grouped: cx(classes.ToggleButtonGroup) }}
                  size={'small'}
                  value={assignmentRuleConditionGroup.combinator}
                  exclusive
                  onChange={(event, value) => {
                    if (value !== null) {
                      return onChangeCombinatorValue(
                        assignmentRuleConditionGroup,
                        value
                      );
                    }
                  }}
                  color={'primary'}
                >
                  <ToggleButton
                    className={cx(classes.ToggleButton)}
                    key={'and'}
                    value={'and'}
                  >
                    And
                  </ToggleButton>
                  <ToggleButton
                    className={cx(classes.ToggleButton)}
                    key={'or'}
                    value={'or'}
                  >
                    Or
                  </ToggleButton>
                </ToggleButtonGroup>
                {/*<InputLabel id='combinator-select-label'>Combinator</InputLabel>*/}
                {/*<Select*/}
                {/*    error={props.errors?.combinator !== undefined}*/}
                {/*    value={props.assignmentRuleConditionGroup.combinator}*/}
                {/*    labelId='combinator-select-label'*/}
                {/*    label='Combinator'*/}
                {/*    id='combinator-select'*/}
                {/*>*/}
                {/*    <MenuItem onClick={() => onChangeCombinatorValue('and')} value={'and'} key={`and`}>*/}
                {/*        and*/}
                {/*    </MenuItem>*/}
                {/*    <MenuItem onClick={() => onChangeCombinatorValue('or')} value={'or'} key={`or`}>*/}
                {/*        or*/}
                {/*    </MenuItem>*/}
                {/*</Select>*/}
              </FormControl>
            )}
            <Box
              key={`${assignmentRuleConditionGroup.idx}_${index}`}
              sx={{
                border: `1px solid ${
                  props.errors && props.errors[index]
                    ? theme.palette.error.main
                    : theme.palette.grey[200]
                }`,
              }}
              className={cx(classes.Box)}
            >
              <AssignmentRulesConditionGroup
                shouldShowCombinatior={assignmentRuleConditionGroup.idx > 1}
                key={index}
                assignmentRuleConditionGroup={assignmentRuleConditionGroup}
                onChangeAssignmentRuleConditionGroup={
                  onChangeAssignmentRuleConditionGroup
                }
                assignmentRuleFormValues={props.assignmentRuleFormValues}
                errors={props.errors ? props.errors[index] : undefined}
                onDeleteAssignmentRuleConditionGroup={
                  onRemoveAssignmentRuleConditionGroup
                }
                shouldShowDeleteAssignmentRuleConditionGroup={
                  props.assignmentRuleConditionGroups.length > 1
                }
              />
            </Box>
            <FormHelperText error>
              {props.errors &&
                props.errors[index] &&
                'All condition fields must be filled '}
            </FormHelperText>
          </div>
        ))}{' '}
    </>
  );
};

const useStyles = customMakeStyles<IAssignmentRulesConditonMultiGroupsProps>()(
  (theme, props) => ({
    Box: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '1rem',
      borderRadius: '4px',
    },
    ToggleButton: {
      textTransform: 'none',
    },
    ToggleButtonGroup: {
      marginBottom: '.70rem',
      height: '2rem',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
);

export { AssignmentRulesConditonMultiGroups };
