import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUnassignedResourcesWarningDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
}

const UnassignedResourcesWarningDialog: React.FC<IUnassignedResourcesWarningDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.isOpen} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>
                <Stack direction={'column'} spacing={0}>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
                        <Typography fontWeight={500}>
                            You are about to save a schedule with resources not assigned to priority groups. These resources will be lost if you
                            continue.
                        </Typography>
                        <IconButton aria-label={'Close and return to editing the schedule.'} size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "Yes" to save schedule discarding resources not assigned to priority groups.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "No" to go back and assign priority groups for these resources.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack spacing={1.5} direction={'row'} justifyContent={'flex-end'} width={'100%'}>
                    <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                        No
                    </Button>
                    <Button onClick={props.onSave}>Yes</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IUnassignedResourcesWarningDialogProps>()((theme, props) => ({}));

export { UnassignedResourcesWarningDialog };
