import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Grid2, Stack, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISettingsContentHeaderProps extends React.PropsWithChildren {
    title: string;
    subtitle?: string;
}

const SettingsContentHeader: React.FC<ISettingsContentHeaderProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid2 container size={12} justifyContent={'space-between'}>
            <Stack spacing={0.5}>
                <Typography className={cx(classes.Title)} variant='h5'>
                    {props.title}
                </Typography>
                {props.subtitle ? (
                    <Typography className={cx(classes.Subtitle)} variant='body2'>
                        {props.subtitle}
                    </Typography>
                ) : (
                    <Box component={'div'} sx={{ height: '19.01px' }} />
                )}
            </Stack>
            {props.children}
        </Grid2>
    );
};

const useStyles = customMakeStyles<ISettingsContentHeaderProps>()((theme, props) => ({
    Title: { color: theme.palette.grey[700] },
    Subtitle: { color: theme.palette.text.secondary },
}));

export { SettingsContentHeader };
