import React, { useMemo } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography,
    createFilterOptions,
    Box,
    Divider,
} from '@mui/material';
import { IBusinessGroupingAddUsersForm, IBusinessGroupingDetailsForm, IBusinessGroupingUser, IUser } from '@vegaplatformui/models';
import { Control, Controller, useForm, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { FormField } from '../../../../forms/form-field';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardUsersAddDialogProps {
    isAddUsersDialogOpen: boolean;
    onCloseDialog: () => void;
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
    control: Control<IBusinessGroupingDetailsForm>;
    watch: UseFormWatch<IBusinessGroupingDetailsForm>;
    trigger: UseFormTrigger<IBusinessGroupingDetailsForm>;

    users: IUser[];
}
const validationSchema: yup.ObjectSchema<IBusinessGroupingAddUsersForm> = yup.object().shape({
    business_grouping_users: yup
        .array<IBusinessGroupingUser[]>()
        .min(1, 'At least one user must be selected')
        .required('Users are required')
        .label('Users'),
});
const BusinessGroupingWizardAddUsersDialog: React.FC<IBusinessGroupingWizardUsersAddDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const availableFormUsers = useMemo(() => {
        return props.users.map((user) => {
            return { id: user.id, label: `${user.given_name} ${user.family_name}`, email: user.email } as IBusinessGroupingUser;
        });
    }, [props.users]);
    //Dummy user to input the select all since the option items are of IUser type
    const selectAllUser: IBusinessGroupingUser = {
        id: 'all',
        label: 'Select All',
    };

    const {
        watch,
        register,
        handleSubmit,
        control,
        reset,
        trigger,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields },
    } = useForm<IBusinessGroupingAddUsersForm>({
        reValidateMode: 'onBlur',
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            business_grouping_users: [],
        },
    });

    const onCloseDialog = () => {
        reset({ business_grouping_users: [] });
        props.onCloseDialog();
    };

    return (
        <Dialog
            id={'add-users-to-assigned-users-form'}
            onSubmit={handleSubmit((data) => {})}
            open={props.isAddUsersDialogOpen}
            onClose={onCloseDialog}
            fullWidth
            maxWidth={'sm'}
        >
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} variant={'h6'} id='business-grouping-add-users-dialog'>
                <Stack>
                    Assign users to business grouping
                    <Typography variant={'caption'}>You must click the 'Save' button to apply changes.</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <FormField label='Users' htmlFor='business_grouping_users'>
                    <Controller
                        name={'business_grouping_users'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                multiple
                                value={value}
                                disableCloseOnSelect
                                size={'small'}
                                limitTags={4}
                                openOnFocus={true}
                                options={availableFormUsers}
                                getOptionLabel={(user) => `${user.email + ' - ' + user.label}`}
                                fullWidth={true}
                                filterOptions={(options, params) => {
                                    const filter = createFilterOptions<IBusinessGroupingUser>();
                                    const filtered = filter(options, params);
                                    //If there hasn't been any searching for specific user then append the Select all else don't show it as an option
                                    if (!params.inputValue) {
                                        return [selectAllUser, ...filtered];
                                    } else {
                                        return filtered;
                                    }
                                }}
                                onChange={(event, users: IBusinessGroupingUser[], reason) => {
                                    if (users.find((option) => option.id === 'all'))
                                        return onChange(value.length === availableFormUsers.length || value.length > 0 ? [] : availableFormUsers);

                                    switch (reason) {
                                        case 'clear': {
                                            return onChange(users ?? []);
                                        }
                                        default:
                                            return onChange(users ?? []);
                                    }
                                }}
                                getOptionDisabled={(option: IBusinessGroupingUser) =>
                                    control._formValues.business_grouping_owner && option.id === control._formValues.business_grouping_owner.id
                                }
                                renderOption={(htmlProps, user: IBusinessGroupingUser, { selected }) => (
                                    <Box role={'presentation'} key={user.id}>
                                        <li {...htmlProps} key={user.id}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    indeterminate={value.length !== props.users.length && user.id === 'all' && value.length > 0}
                                                    checked={
                                                        (user.id === 'all' && value.length === props.users.length) ||
                                                        !!value?.find((u) => u.id === user.id)
                                                    }
                                                    tabIndex={-1}
                                                />
                                            </ListItemIcon>
                                            {user.id === 'all' ? (
                                                <ListItemText primary={`${user.label}`} slotProps={{ primary: { noWrap: true } }} />
                                            ) : (
                                                <Tooltip title={`${user.label} - ${user.email}`}>
                                                    <ListItemText
                                                        primary={`${user.label}`}
                                                        slotProps={{ primary: { noWrap: true }, secondary: { noWrap: true } }}
                                                        secondary={user.email}
                                                    />
                                                </Tooltip>
                                            )}
                                        </li>
                                        {user.id === 'all' && <Divider />}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={'Assign users'}
                                        error={!!errors.business_grouping_users}
                                        helperText={errors.business_grouping_users?.message as string}
                                    />
                                )}
                                renderTags={(users, getTagProps) => (
                                    <Box onKeyDown={(event) => event.stopPropagation()} className={cx(classes.ChipBox)}>
                                        {users.map((user, index) => {
                                            const tagProps = getTagProps({ index });
                                            const { key, ...otherTagProps } = tagProps;
                                            return (
                                                <Chip
                                                    key={key}
                                                    variant='outlined'
                                                    size='small'
                                                    label={user.email}
                                                    {...otherTagProps}
                                                    disabled={
                                                        control._formValues.business_grouping_owner &&
                                                        user.id === control._formValues.business_grouping_owner.id
                                                    }
                                                />
                                            );
                                        })}
                                    </Box>
                                )}
                            />
                        )}
                    />
                </FormField>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack direction={'row'} spacing={1.5} width={'100%'} justifyContent={'flex-end'}>
                    <Button variant={'cancel'} disableElevation={true} color={'secondary'} autoFocus onClick={onCloseDialog}>
                        Cancel
                    </Button>
                    <Button
                        disableElevation={true}
                        type={'button'}
                        variant={'contained'}
                        onClick={(e) => {
                            e.preventDefault();
                            const currentUsers = props.watch('business_grouping_users');
                            const newOptionUsers = watch('business_grouping_users');
                            const newUsers = props.users.filter((user) => newOptionUsers.map((u) => u.id).includes(user.id));
                            trigger('business_grouping_users')
                                .then((result) => {
                                    if (newUsers.length > 0 && result) {
                                        props.setValue('business_grouping_users', [...new Set([...currentUsers, ...newUsers])], {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                        props.trigger().then(() => onCloseDialog());
                                    }
                                })
                                .finally(() => reset({}, { keepErrors: true }));
                        }}
                    >
                        Assign
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardUsersAddDialogProps>()((theme, props) => ({
    ChipBox: { maxHeight: '112px', overflowY: 'auto' },
}));

export { BusinessGroupingWizardAddUsersDialog };
