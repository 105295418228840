import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Link } from '@mui/material';
import { useRouteUrls } from '@vegaplatformui/utils';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkToProfileProps {
    color: string;
}

const LinkToProfile: React.FC<ILinkToProfileProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const navigate = useNavigate();
    const { routeUrls } = useRouteUrls({});

    return (
        <Link
            component='button'
            style={{ color: props.color, textDecorationColor: props.color, cursor: 'pointer' }}
            onClick={() => {
                const profileTabId = routeUrls.settings.tabs.find((tab) => tab.id === 'profile')?.id;
                profileTabId && navigate(`/${routeUrls.settings.route.path}/${profileTabId}`);
            }}
        >
            {'Settings > Profile Details}'}
        </Link>
    );
};

const useStyles = customMakeStyles<ILinkToProfileProps>()((theme, props) => ({}));

export { LinkToProfile };
