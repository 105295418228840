import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Switch, Typography } from '@mui/material';
import { IUserSettingMFAStatusEnum } from '@vegaplatformui/models';
import { SubmitHandler } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMfaTileProps {
    mfaType: string;
    mfaDescription?: string;
    status: IUserSettingMFAStatusEnum;
    onSubmit: (data: string) => void;
}

const MfaTile: React.FC<IMfaTileProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { mfaType, mfaDescription, onSubmit, status } = props;
    const [isTileActive, setIsTileActive] = useState<boolean>(status === IUserSettingMFAStatusEnum.REQUIRED);

    useEffect(() => {
        setIsTileActive(status === IUserSettingMFAStatusEnum.REQUIRED);
    }, [status]);

    const onToggleSwitch: SubmitHandler<any> = (isEnabled: boolean) => {
        setIsTileActive(isEnabled);
        onSubmit(isEnabled ? IUserSettingMFAStatusEnum.REQUIRED : IUserSettingMFAStatusEnum.DISABLED);
    };

    return (
        <Grid item container xs={9} className={cx(classes.MfaTile)} direction='row' justifyContent='space-between' alignItems='center'>
            <Grid xs={10} item container>
                <Grid item xs={12}>
                    <Typography variant={'subtitle2'}>{mfaType}</Typography>
                </Grid>
                {mfaDescription && (
                    <Grid item xs={12}>
                        <Typography variant={'body2'}>{mfaDescription}</Typography>
                    </Grid>
                )}
            </Grid>
            <Grid xs item>
                <Switch
                    inputProps={{ 'aria-label': `Toggle switch for ${mfaType}` }}
                    id={`${mfaType}-switch`}
                    checked={isTileActive}
                    onChange={(event, checked) => onToggleSwitch(checked)}
                />
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IMfaTileProps>()((theme, props) => ({
    MfaTile: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '1rem' },
}));

export { MfaTile };
