import * as React from 'react';
import { useEffect } from 'react';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import { Divider, getContrastRatio, PaletteMode, Skeleton, Stack, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { MainMenuItem } from './drawer-menu-item/main-menu-item';
import IconButton from '@mui/material/IconButton';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { GeminiMenuItem, VegaLogo, VegaLogoShort, WhiteVegaLogo, WhiteVegaLogoShort } from '@vegaplatformui/sharedassets';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { customMakeStyles } from '@vegaplatformui/styling';
import { UserProfile } from '../user-profile/user-profile';
import Keycloak from 'keycloak-js';
import { selectNewMenuItem } from '@vegaplatformui/utils';
import { ShowSupportForm, CustomPrimaryLogoState, CustomSecondaryLogoState, ShowUpdatedUi } from '../recoil/atom';
import { Help } from '@mui/icons-material';
import { MenuHeading } from './drawer-menu-item/menu-heading';
import { SupportEmailRecipient } from '@vegaplatformui/models';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { closedDrawerWidth, openDrawerWidth } from '../constants/constants';

const openedMixin = (theme: Theme, showUpdatedUi: boolean): CSSObject => ({
    width: openDrawerWidth,
    border: 'none',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),

    backgroundColor: showUpdatedUi ? theme.palette.secondary.main : theme.palette.common.white,
    color: theme.palette.text.secondary,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    overflowX: 'hidden',
    padding: '0 1rem',
    '&::-webkit-scrollbar': {
        width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
    },
});

const closedMixin = (theme: Theme, showUpdatedUi: boolean): CSSObject => ({
    border: 'none',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    overflowX: 'hidden',
    width: closedDrawerWidth,
    background: showUpdatedUi ? theme.palette.secondary.main : theme.palette.common.white,
    color: theme.palette.text.secondary,
    padding: '0 1rem',
});

const DrawerHeader = styled('div')(({ theme }) => ({
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 0 ',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface CustomDrawerProps extends DrawerProps {
    showUpdatedUi: boolean;
}

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open' && prop !== 'showUpdatedUi',
})<CustomDrawerProps>(({ theme, open, showUpdatedUi }) => ({
    width: openDrawerWidth,
    flexShrink: 0,
    border: 'none',
    background: theme.palette.common.white,
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme, showUpdatedUi),
        '& .MuiDrawer-paper': openedMixin(theme, showUpdatedUi),
    }),
    ...(!open && {
        ...closedMixin(theme, showUpdatedUi),
        '& .MuiDrawer-paper': closedMixin(theme, showUpdatedUi),
    }),
}));

export interface IMiniDrawerProps {
    setNavMargin: SetterOrUpdater<string>;
    selectedMenuItem: GeminiMenuItem;
    isDrawerOpen: boolean;
    menuItems: GeminiMenuItem[];
    keycloak: Keycloak;
    setAuthenticated: SetterOrUpdater<boolean>;
    logoutUrl: string;
    userName: string | undefined;
    themeState: PaletteMode;
    setThemeState: SetterOrUpdater<PaletteMode>;
    setSidebarMenuItems: React.Dispatch<React.SetStateAction<GeminiMenuItem[]>>;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    profilePhotoUrl: string;
}

const CustomDrawer: React.FC<IMiniDrawerProps> = (props) => {
    const theme = useTheme();
    const { classes, cx } = useStyles(props);
    const [showSupportForm, setShowSupportForm] = useRecoilState(ShowSupportForm);
    const primaryLogoState = useRecoilValue(CustomPrimaryLogoState);
    const secondaryLogoState = useRecoilValue(CustomSecondaryLogoState);
    const showUpdatedUi = useRecoilValue(ShowUpdatedUi);
    const navigate = useNavigate();
    const flags = useFlags();

    useEffect(() => {
        props.setNavMargin(props.isDrawerOpen ? `${openDrawerWidth}px` : `${closedDrawerWidth}px`);
    }, [props.isDrawerOpen, props.setNavMargin]);

    const onSelectMenuItem = (menuItem: GeminiMenuItem) => {
        localStorage.removeItem('previous_route');
        // TODO selecting a menuItem should work with passing in the selected menu items name only
        selectNewMenuItem(props.menuItems, menuItem, props.setSidebarMenuItems);
        menuItem.route && navigate(menuItem.route);
    };

    // Group menu items by heading with un-grouped items in the first, default group
    const menuGroups = React.useMemo(
        () =>
            props.menuItems.reduce<GeminiMenuItem[][]>(
                (groups, item) => {
                    if (item.enabled) {
                        if (item.heading) {
                            const idx = groups.findIndex((g) => g.length > 0 && g[0].heading === item.heading);
                            if (idx >= 0) {
                                groups[idx].push(item);
                            } else {
                                groups.push([item]);
                            }
                        } else {
                            groups[0].push(item);
                        }
                    }
                    return groups;
                },
                [[]]
            ),
        [props.menuItems, props.selectedMenuItem]
    );

    const getSkeletonItem = (sxProps?: SxProps<Theme> | undefined) => {
        return (
            <Skeleton width={'93%'} variant='rounded' sx={{ marginLeft: '1rem', ...sxProps }}>
                <MainMenuItem
                    onSelectMenuItem={onSelectMenuItem}
                    key={'loading Menu Item'}
                    isDrawerOpen={props.isDrawerOpen}
                    setIsDrawerOpen={props.setIsDrawerOpen}
                    menuItem={{
                        icon: Help,
                        isSelected: showSupportForm.showSupportForm,
                        enabled: true,
                        title: 'Help & Support',
                        isFooterMenuItem: true,
                    }}
                    theme={theme}
                />
            </Skeleton>
        );
    };

    return (
        <Box data-testid={'custom-drawer'} sx={{ display: 'flex' }}>
            <Drawer variant='permanent' open={props.isDrawerOpen} theme={theme} showUpdatedUi={showUpdatedUi}>
                <DrawerHeader sx={{ marginBottom: '1rem', marginTop: '0.2rem' }}>
                    <IconButton
                        className={cx(props.isDrawerOpen ? classes.VegaIconOpen : classes.VegaIconClosed)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                        }}
                        color='inherit'
                        aria-label='company logo'
                        edge='start'
                        onClick={() => {
                            navigate(props.logoutUrl);
                        }}
                    >
                        {props.isDrawerOpen && (!flags.organizationSettingsTab || !primaryLogoState.isCustom) ? (
                            showUpdatedUi &&
                            getContrastRatio(theme.palette.common.white, theme.palette.secondary.main) >= theme.palette.contrastThreshold ? (
                                <WhiteVegaLogo aria-label='vega primary logo' />
                            ) : (
                                <VegaLogo aria-label='vega primary logo' />
                            )
                        ) : props.isDrawerOpen && primaryLogoState.isCustom ? (
                            <Box
                                component={'img'}
                                className={cx(classes.CustomLogo)}
                                src={primaryLogoState.url}
                                aria-label='organization primary logo'
                            />
                        ) : !props.isDrawerOpen && flags.organizationSettingsTab && secondaryLogoState.isCustom ? (
                            <Box
                                component={'img'}
                                className={cx(classes.CustomLogoShort)}
                                src={secondaryLogoState.url}
                                aria-label='organization secondary logo'
                            />
                        ) : showUpdatedUi &&
                          getContrastRatio(theme.palette.common.white, theme.palette.secondary.main) >= theme.palette.contrastThreshold ? (
                            <WhiteVegaLogoShort aria-label='vega secondary logo' />
                        ) : (
                            <VegaLogoShort aria-label='vega secondary logo' />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Stack className={cx(classes.HeaderMenuContainer)}>
                    {props.menuItems.length > 0 ? (
                        props.menuItems.map(
                            (menuItem) =>
                                menuItem.isHeaderMenuItem && (
                                    <MainMenuItem
                                        onSelectMenuItem={onSelectMenuItem}
                                        key={menuItem.title}
                                        isDrawerOpen={props.isDrawerOpen}
                                        setIsDrawerOpen={props.setIsDrawerOpen}
                                        menuItem={menuItem}
                                        theme={theme}
                                    />
                                )
                        )
                    ) : (
                        <>
                            {getSkeletonItem()}
                            {getSkeletonItem({ mt: '1rem' })}
                        </>
                    )}
                </Stack>
                <Stack direction={'column'}>
                    {menuGroups
                        .filter((group) => group.length > 0)
                        .map((group, i) => (
                            <Stack key={i}>
                                {group[0].heading ? <MenuHeading heading={group[0].heading} theme={theme} isDrawerOpen={props.isDrawerOpen} /> : null}
                                <Stack direction={'column'}>
                                    {group.map((item) =>
                                        !item.isFooterMenuItem && !item.isHeaderMenuItem ? (
                                            <MainMenuItem
                                                onSelectMenuItem={onSelectMenuItem}
                                                key={item.title}
                                                isDrawerOpen={props.isDrawerOpen}
                                                setIsDrawerOpen={props.setIsDrawerOpen}
                                                menuItem={item}
                                                theme={theme}
                                            />
                                        ) : null
                                    )}
                                </Stack>
                            </Stack>
                        ))}
                </Stack>
                {!showUpdatedUi && (
                    <Stack className={cx(classes.FooterMenuContainer)}>
                        <Divider className={cx(classes.FooterMenuDivider)} />
                        {props.menuItems.length > 0
                            ? props.menuItems.map(
                                  (menuItem) =>
                                      menuItem.isFooterMenuItem &&
                                      menuItem.route !== 'sharedreport' && (
                                          <MainMenuItem
                                              onSelectMenuItem={onSelectMenuItem}
                                              key={menuItem.title}
                                              isDrawerOpen={props.isDrawerOpen}
                                              setIsDrawerOpen={props.setIsDrawerOpen}
                                              menuItem={menuItem}
                                              theme={theme}
                                          />
                                      )
                              )
                            : getSkeletonItem()}
                        <MainMenuItem
                            onSelectMenuItem={() => setShowSupportForm({ showSupportForm: true, contactType: SupportEmailRecipient.Support })}
                            isDrawerOpen={props.isDrawerOpen}
                            setIsDrawerOpen={props.setIsDrawerOpen}
                            menuItem={{
                                icon: Help,
                                isSelected: showSupportForm.showSupportForm,
                                title: 'Help & Support',
                                enabled: true,
                                isFooterMenuItem: true,
                            }}
                            theme={theme}
                        />
                        <UserProfile
                            keycloak={props.keycloak}
                            setAuthenticated={props.setAuthenticated}
                            logoutUrl={props.logoutUrl}
                            userName={props.userName}
                            themeState={props.themeState}
                            setThemeState={props.setThemeState}
                            selectedMenuItem={props.selectedMenuItem}
                            isDrawerOpen={props.isDrawerOpen}
                            profilePhotoUrl={props.profilePhotoUrl}
                        />
                    </Stack>
                )}
            </Drawer>
        </Box>
    );
};

const useStyles = customMakeStyles<IMiniDrawerProps>()((theme, props) => ({
    VegaIconOpen: { height: '3rem', marginBlock: theme.spacing(0.5) },
    VegaIconClosed: {
        width: '4.75rem',
        height: '3rem',
        display: 'block',
        marginLeft: '.3rem',
    },
    CustomLogo: { maxHeight: 70 },
    CustomLogoShort: { maxWidth: 50, maxHeight: 50 },
    DrawerHeader: {
        marginBottom: '1rem',
    },
    FooterMenuContainer: {
        marginTop: 'auto',
        marginBottom: '0.5rem',
    },
    HeaderMenuContainer: {
        marginLeft: `${props.isDrawerOpen ? '-1rem' : ''}`,
    },
    FooterMenuDivider: {
        backgroundColor: theme.palette.grey[100],
        marginLeft: `-1rem`,
        marginRight: '-1rem',
        marginBottom: '0.5rem',
    },
}));

export { CustomDrawer };
