import { Box, Button, CardContent, IconButton, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { OktaLogo, SamlLogo } from '@vegaplatformui/sharedassets';
import { ISSONameIDPolicyFormat, IUserSettingSSO, IUserSettingSSOSamlForm, SSOButtonEnum, SSOTypeEnum } from '@vegaplatformui/models';
import { SSOOpenIDCreateForm } from './sso-openid-create-form';
import { SSOSamlCreateForm } from './sso-saml/sso-saml-create-form';

export interface ISSOSettingsProps {
    nameIDPolicyFormatOptions: ISSONameIDPolicyFormat[];
    onCreate: (data: IUserSettingSSO, type: SSOTypeEnum, byXml: boolean) => void;
    onCreateSaml: (data: IUserSettingSSOSamlForm) => void;
}

const SSOSettings: React.FC<ISSOSettingsProps> = (props) => {
    const { cx, classes } = useStyles();
    const theme = useTheme();

    const [hasSSO, setHasSSO] = useState(false);
    const [selectedSSOType, setSelectedSSOType] = useState<SSOTypeEnum | null>(null);
    const [selectedButton, setSelectedButton] = useState<SSOButtonEnum | null>(null);

    const onClickSSOButton = (type: SSOTypeEnum, button: SSOButtonEnum) => {
        setSelectedSSOType(type);
        setSelectedButton(button);
    };
    const handleBack = useCallback(() => {
        setHasSSO(false);
        setSelectedButton(null);
        setSelectedSSOType(null);
    }, []);

    return (
        <CardContent>
            <Box className={cx(classes.SSOCreationBox)}>
                {!hasSSO && (
                    <Stack alignItems={'flex-start'} marginBottom={2} spacing={3}>
                        <Stack spacing={0.5}>
                            <Typography variant='h3'>Create a new SSO configuration</Typography>
                            <Typography variant='body2' color={theme.palette.grey[600]}>
                                Select a method to set up SSO.
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} columnGap={2} marginTop={4}>
                            {/*<IconButton*/}
                            {/*    className={`${cx(classes.SSOButton)} ${selectedSSOType === SSOTypeEnum.OPENID && cx(classes.IsSelectedSSOButton)}`}*/}
                            {/*    onClick={() => onClickSSOButton(SSOTypeEnum.OPENID)}*/}
                            {/*>*/}
                            {/*    <img src={OpenIDLogo} alt='openid-logo' />*/}
                            {/*</IconButton>*/}
                            <IconButton
                                className={`${cx(classes.SSOButton)} ${
                                    selectedSSOType === SSOTypeEnum.SAML && selectedButton === SSOButtonEnum.SAML && cx(classes.IsSelectedSSOButton)
                                }`}
                                onClick={() => onClickSSOButton(SSOTypeEnum.SAML, SSOButtonEnum.SAML)}
                            >
                                <SamlLogo className={cx(classes.SamlIconButton)} />
                            </IconButton>
                            <IconButton
                                className={`${cx(classes.SSOButton)} ${
                                    selectedSSOType === SSOTypeEnum.SAML && selectedButton === SSOButtonEnum.OKTA && cx(classes.IsSelectedSSOButton)
                                }`}
                                onClick={() => onClickSSOButton(SSOTypeEnum.SAML, SSOButtonEnum.OKTA)}
                            >
                                <OktaLogo className={cx(classes.OktaIconButton)} />
                            </IconButton>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'flex-start'} width={'13rem'}>
                            <Button onClick={() => setHasSSO(true)} variant='contained' disabled={!selectedSSOType}>
                                Next Step
                            </Button>
                        </Stack>
                    </Stack>
                )}

                {hasSSO && selectedSSOType === SSOTypeEnum.OPENID && <SSOOpenIDCreateForm onCreate={props.onCreate} onBack={handleBack} />}
                {hasSSO && selectedSSOType === SSOTypeEnum.SAML && (
                    <SSOSamlCreateForm
                        nameIDPolicyFormatOptions={props.nameIDPolicyFormatOptions}
                        onCreate={props.onCreateSaml}
                        onClose={handleBack}
                        selectedButton={selectedButton}
                    />
                )}
            </Box>
        </CardContent>
    );
};

const useStyles = customMakeStyles()((theme) => ({
    SSOButton: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: theme.spacing(1),
        height: '72px',
        width: '207px',
        borderColor: theme.palette.grey['200'],
    },
    IsSelectedSSOButton: {
        borderColor: theme.palette.primary.main,
    },
    OktaIconButton: {
        width: '6rem',
        height: 'auto',
    },
    SamlIconButton: {
        width: '4rem',
        height: 'auto',
    },
    SSOCreationBox: { border: '1px solid #ccc', padding: '20px', borderRadius: '8px' },
}));

export { SSOSettings };
