import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { AddComment, Close } from '@mui/icons-material';
import { ChatbotNavigationTabTypes, IChatbotTopic } from '@vegaplatformui/models';
import { CosmooNew, CosmooV2 } from '@vegaplatformui/sharedassets';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChatbotHeaderProps {
    close: () => void;
    currentTopic?: IChatbotTopic;
    currentLocation: ChatbotNavigationTabTypes;
    initStartTopic: () => void;
}

const ChatbotHeader: React.FC<IChatbotHeaderProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <DialogTitle className={cx(classes.Header)}>
            <Stack direction={'row'} justifyContent='space-between' alignItems='flex-start'>
                <Stack direction={'row'} spacing={1} className={cx(classes.HeaderStartStack)}>
                    <Avatar alt={'cosmoo header avatar'} sx={{ width: '53px', height: '51px', backgroundColor: 'black' }}>
                        <CosmooV2 aria-label={'cosmoo header avatar'} />
                    </Avatar>
                    <Stack className={cx(classes.HeaderTypography)} direction={'column'}>
                        <Tooltip title={'Chat with Cosmoo: Your Virtual FinOps Analyst'}>
                            <Typography fontWeight={'600'} className={cx(classes.HeaderColor, classes.HeaderTypography)} variant={'body1'}>
                                Chat with Cosmoo: Your Virtual FinOps Analyst
                            </Typography>
                        </Tooltip>
                        <Typography className={cx(classes.HeaderColor, classes.HeaderTypography)} variant={'body2'}>
                            {props.currentLocation === ChatbotNavigationTabTypes.Chat
                                ? props.currentTopic?.conversation_name ?? 'New Topic'
                                : 'Past Topics'}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack className={cx(classes.HeaderColor)} alignItems={'flex-start'} direction={'row'} spacing={1}>
                    <Tooltip title={'New topic'}>
                        <IconButton
                            size={'small'}
                            color={'inherit'}
                            aria-label={'start a new topic'}
                            onClick={() => {
                                return props.initStartTopic();
                            }}
                        >
                            <AddComment fontSize={'small'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Close'}>
                        <IconButton size={'small'} aria-label={'close chatbot'} onClick={props.close} color={'inherit'}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        </DialogTitle>
    );
};

const useStyles = customMakeStyles<IChatbotHeaderProps>()((theme, props) => ({
    Header: {
        backgroundColor: theme.palette.primary.main,
        borderBottom: `4px solid ${theme.palette.background.paper}`,
        borderRadius: '24px 24px 0 0',
    },
    HeaderStartStack: { width: '80%' },
    HeaderTypography: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
    HeaderColor: { color: theme.palette.getContrastText(theme.palette.primary.main) },
}));

export { ChatbotHeader };
