import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Typography } from '@mui/material';
import { QuicksightConsoleFrame } from './quicksight-console-frame';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuicksightConsoleProps {
    consoleUrl: string;
}

const QuicksightConsole: React.FC<IQuicksightConsoleProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <>
            {(props.consoleUrl && <QuicksightConsoleFrame consoleUrl={props.consoleUrl} />) ?? (
                <Typography>
                    Sorry, this page isn't ready yet or you might not have permission to view it. Please check back later or contact support if you
                    believe this is an error.
                </Typography>
            )}
        </>
    );
};

const useStyles = customMakeStyles<IQuicksightConsoleProps>()((theme, props) => ({}));

export { QuicksightConsole };
