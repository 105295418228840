import React, { useMemo } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import {
    INotificationChannel,
    IUpdateNotificationStatusHook,
    IUpdateChannelPreferenceHook,
    IVegaNotification,
    NotificationChannelName,
    IUpsertNotificationPreferenceHook,
    NotificationFrequency,
} from '@vegaplatformui/models';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { GreenTrackSwitch } from '../../utilities/green-track-switch';
import { StringCapitalizeAndSpace } from '../../utilities/string-formatter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreferenceItemProps {
    notification: IVegaNotification;
    notificationChannels: INotificationChannel[];
    isPhoneNumberSet: boolean;
    updateChannelPreference: (hookRequest: IUpsertNotificationPreferenceHook) => void;
    updateNotificationStatus: (hookRequest: IUpdateNotificationStatusHook) => void;
    isLoading: boolean;
    setLoading: (id: string, isLoading: boolean) => void;
}

const PreferenceItem: React.FC<IPreferenceItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const isNotificationEnabled = useMemo(() => {
        return props.notification.is_enabled;
    }, [props.notification]);
    const isEmailChecked = useMemo(() => {
        return props.notification.channels.find((channel) => channel.channel_name === NotificationChannelName.Email)?.is_enabled ?? false;
    }, [props.notification]);
    const isSMSChecked = useMemo(() => {
        return props.notification.channels.find((channel) => channel.channel_name === NotificationChannelName.Sms)?.is_enabled ?? false;
    }, [props.notification]);

    const onChangeCheckbox = (checked: boolean, channelName: NotificationChannelName) => {
        props.setLoading(props.notification.name, true);
        const currentChannel = props.notificationChannels.find((channel) => channel.name === channelName)!;
        const hookRequest: IUpsertNotificationPreferenceHook = {
            is_enabled: checked,
            settings: {
                frequency: ['anomaly_detected'].includes(props.notification.name) ? NotificationFrequency.Digest : NotificationFrequency.OnDemand,
            },
            notification_channel_name: currentChannel.name,
            notification_type_name: props.notification.name,
        };

        props.updateChannelPreference(hookRequest);
    };

    return (
        <Grid className={cx(classes.PreferenceItem)} container spacing={2} direction={'row'} justifyContent='flex-start' alignItems='center'>
            <Grid className={cx(classes.ColumnMinWidth)} item xs={2}>
                <FormControlLabel
                    control={
                        <GreenTrackSwitch
                            checked={isNotificationEnabled}
                            aria-label={`${props.notification.name} status switch`}
                            onChange={(event, checked) => {
                                // Will be used again if we end up using a bulk on/off api call for notification types (currently just going to be using email)
                                // So the API call was not imported into the new service
                                // props.setLoading(props.notification.name, true);
                                // props.updateNotificationStatus({ notification_type_id: props.notification.id, is_enabled: checked });
                                onChangeCheckbox(checked, NotificationChannelName.Email);
                            }}
                            disabled={props.isLoading}
                        />
                    }
                    title={'Notification Status Switch'}
                    label={isNotificationEnabled ? 'On' : 'Off'}
                />
            </Grid>
            <Grid
                className={cx(classes.ColumnMinWidth, classes.PreferenceNameTypography)}
                container
                direction='column'
                justifyContent='center'
                alignItems='flex-start'
                item
                //xs={6}
                xs={10}
            >
                <Grid item>
                    <Typography className={cx(classes.PreferenceNameTypography)} variant={'body1'}>
                        {StringCapitalizeAndSpace(props.notification.name)}
                    </Typography>
                </Grid>
                {(props.notification.description || props.notification.name === 'anomaly_detected') && (
                    <Grid item>
                        <Typography className={cx(classes.PreferenceNameTypography)} variant={'caption'}>
                            {props.notification.description}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            {/*{props.notificationChannels.map((channel) => channel.name).includes(NotificationChannelName.Email) && (*/}
            {/*    <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={2}>*/}
            {/*        <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox*/}
            {/*                    aria-label={`${props.notification.name}'s Email checkbox`}*/}
            {/*                    checked={isEmailChecked}*/}
            {/*                    onChange={(event, checked) => onChangeCheckbox(checked, NotificationChannelName.Email)}*/}
            {/*                    disabled={props.isLoading}*/}
            {/*                />*/}
            {/*            }*/}
            {/*            title={'Email Checkbox'}*/}
            {/*            label={undefined}*/}
            {/*        />*/}
            {/*    </Grid>*/}
            {/*)}*/}
            {/*{props.notificationChannels.map((channel) => channel.name).includes(NotificationChannelName.Sms) && (*/}
            {/*    <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={2}>*/}
            {/*        <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox*/}
            {/*                    aria-label={`${props.notification.name}'s SMS checkbox`}*/}
            {/*                    checked={isSMSChecked}*/}
            {/*                    onChange={(event, checked) => onChangeCheckbox(checked, NotificationChannelName.Sms)}*/}
            {/*                    disabled={!props.isPhoneNumberSet || props.isLoading}*/}
            {/*                />*/}
            {/*            }*/}
            {/*            title={'SMS Checkbox'}*/}
            {/*            label={undefined}*/}
            {/*        />*/}
            {/*    </Grid>*/}
            {/*)}*/}
        </Grid>
    );
};

const useStyles = customMakeStyles<IPreferenceItemProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ColumnCheckboxMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '95px',
        },
    },
    PreferenceNameTypography: { overflowWrap: 'break-word' },
    PreferenceItem: {
        marginBottom: '1rem',
    },
    StatusSubtext: { color: theme.palette.grey[600] },
}));

export { PreferenceItem };
