import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import process from 'process';
import { SnackbarProvider } from 'notistack';
import { Routes } from '../routes/routes';
import { UserId, useStyledComponentsHook } from '@vegaplatformui/sharedcomponents';
import { IVegaPageTitle } from '@vegaplatformui/sharedassets';
import { vegaPageTitleState } from '../jotai/atom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAtomValue } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAppProps {}

const App: React.FC<IAppProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const userId = useAtomValue(UserId);
    const { StyledMaterialDesignContent } = useStyledComponentsHook({});
    const vegaPageTitle = useAtomValue<IVegaPageTitle>(vegaPageTitleState);
    const flags = useFlags();

    useEffect(() => {
        document.title =
            vegaPageTitle.subTitle !== '' && vegaPageTitleState !== undefined
                ? `${vegaPageTitle.subTitle} - ${vegaPageTitle.title}`
                : `${vegaPageTitle.title}`;
    }, [vegaPageTitle.subTitle, vegaPageTitle.title]);

    //TODO: Can use a conditional render if we want to use the Knock provider for their UI components
    return (
        <SnackbarProvider
            Components={{
                info: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
            }}
            maxSnack={5}
        >
            <Routes data-testid={'routes'} />
        </SnackbarProvider>
    );
};

const useStyles = customMakeStyles<IAppProps>()((theme, props) => ({}));

export { App };
