import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Button, Grid2, IconButton, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { Close, Warning } from '@mui/icons-material';
import { BusinessGroupingWizardDetailsTab } from './business-grouping-wizard-details-tab';
import {
    IBusinessGrouping,
    IBusinessGroupingDetailsForm,
    IBusinessGroupingType,
    IBusinessUnitTreeItem,
    IParkingScheduleSummary,
    IUser,
    IUserBusinessGroups,
    ParkingScheduleType,
} from '@vegaplatformui/models';
import { BusinessGroupingWizardUsersTab } from './business-grouping-wizard-users-tab/business-grouping-wizard-users-tab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useKeycloak } from '@react-keycloak-fork/web';
import { BusinessGroupingWizardResourcesTab } from './business-grouping-wizard-resources-tab/business-grouping-wizard-resources-tab';
import { BusinessGroupingWizardParkingSchedulesTab } from './business-grouping-wizard-parking-schedules-tab';
import { useNavigate, useParams } from 'react-router';
import { IParkingScheduleTableProps } from '../../../parking/parking-types';
import { BusinessGroupingUnsavedChangesWarningDialog } from './business-grouping-unsaved-changes-warning-dialog';
import { useTheme } from '@mui/material/styles';
import useUsersApi from '../../../api-hooks/use-users-api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { StyledToolTip } from '../../../utilities/styled-tooltip';
import { useRouteUrls } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardTabsProps extends IParkingScheduleTableProps {
    onCloseDrawer?: () => void;
    isBackPage?: boolean;
    businessGroupingToEdit: IBusinessGrouping | undefined;
    businessGroupingTypes: IBusinessGroupingType[];
    availableUsers: IUser[];
    userBusinessGroupings: IBusinessUnitTreeItem[];
    validParentBusinessGroupings: IBusinessUnitTreeItem[];
    onSubmitForm: (data: IBusinessGroupingDetailsForm) => void;
    unassignUsers: (users: IUser[]) => void;
    isLoading: boolean;
    parkingScheduleTableIdentifier: string;
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    setParkingScheduleType: React.Dispatch<React.SetStateAction<ParkingScheduleType>>;
    usersBusinessGroupingsFlat: IUserBusinessGroups[];
}

const validationSchema: yup.ObjectSchema<IBusinessGroupingDetailsForm> = yup.object().shape({
    business_grouping_name: yup
        .string()
        .required('Name is required')
        .matches(/^[\W\w]*[a-zA-Z\d]+[\W\w]*$/g, 'Name must have at least one alphanumeric'),
    business_grouping_description: yup.string().notRequired(),
    business_grouping_parent: yup.mixed<IBusinessGrouping>().required('Parent is required'),
    business_grouping_owner: yup.mixed<IUser>().required('Owner is required'),
    business_grouping_users: yup.array<IUser[]>().min(1).required('Users are required').label('Users'),
    business_grouping_type: yup.number().required('Type is required'),
});

const businessGroupingWizardTabs = ['details', 'resources', 'users', 'enterprise schedules'];

const BusinessGroupingWizardTabs: React.FC<IBusinessGroupingWizardTabsProps> = (props) => {
    const keycloak = useKeycloak();
    const theme = useTheme();
    const { tab } = useParams();
    const navigate = useNavigate();
    const usersApi = useUsersApi({});
    const { routeUrls } = useRouteUrls({});
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState(false);
    const [users, setUsers] = useState<IUser[]>(props.availableUsers);
    const [currentTab, setCurrentTab] = useState(tab ?? 'details');
    const [isUnsavedChanges, setIsUnsavedChanges] = useState<boolean>(false);
    const { vOperate } = useFlags();

    const {
        watch,
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        trigger,
        formState: { errors, isDirty, isValid, defaultValues, isSubmitSuccessful },
    } = useForm<IBusinessGroupingDetailsForm>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            business_grouping_name: props.businessGroupingToEdit?.name ?? '',
            business_grouping_description: props.businessGroupingToEdit?.description ?? '',
            business_grouping_parent: props.businessGroupingToEdit?.parent_id
                ? (props.usersBusinessGroupingsFlat.find((bg) => bg.id === props.businessGroupingToEdit?.parent_id) ?? null)
                : (props.usersBusinessGroupingsFlat.find((bg) => bg.name === 'default') ??
                  (props.businessGroupingToEdit && { name: 'Current Parent', id: props.businessGroupingToEdit.parent_id }) ??
                  null),
            business_grouping_owner: props.businessGroupingToEdit?.owned_by
                ? (users.find((user) => user.id === props.businessGroupingToEdit?.owned_by) ?? null)
                : (users.find((user) => user.email === keycloak?.keycloak?.tokenParsed?.email) ?? null), //what if user is not in list current user doesnt have access?
            business_grouping_users: props.businessGroupingToEdit?.users ?? [],
            business_grouping_type: props.businessGroupingToEdit?.type ?? 1,
        },
    });

    const [owner, setOwner] = useState<IUser | undefined>(
        props.businessGroupingToEdit &&
            props.businessGroupingToEdit?.users.find((user) => props.businessGroupingToEdit && user.id === props.businessGroupingToEdit.owned_by) !==
                undefined
            ? props.businessGroupingToEdit?.users.find((user) => props.businessGroupingToEdit && user.id === props.businessGroupingToEdit.owned_by)
            : undefined
    );
    const { classes, cx } = useStyles(props);
    const [currentUsers, setCurrentUsers] = useState<IUser[]>(props.businessGroupingToEdit?.users ?? []);

    const onClickContinue = () => {
        const arrayToUse = businessGroupingWizardTabs;

        const found = arrayToUse.find((tab) => tab === currentTab);

        if (currentTab === 'details') {
            found && setCurrentTab('users');
            navigate(`/${routeUrls.businessGroupings.path}/${props.businessGroupingToEdit?.id ?? 'create'}/users`, { replace: true });
        }
    };

    const onClickBack = () => {
        const arrayToUse = businessGroupingWizardTabs;

        const found = arrayToUse.find((tab) => tab === currentTab);

        if (currentTab === 'users') {
            found && setCurrentTab('details');
            navigate(`/${routeUrls.businessGroupings.path}/${props.businessGroupingToEdit?.id ?? 'create'}/details`, { replace: true });
        }
    };

    const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (isDirty) {
            setIsUnsavedChanges(true);
        } else {
            setCurrentTab(newValue);
            navigate(`/${routeUrls.businessGroupings.path}/${props.businessGroupingToEdit?.id ?? 'create'}/${newValue}`, { replace: true });
        }
    };

    useEffect(() => {
        const currentOwner = watch('business_grouping_owner');
        if (currentOwner !== null) {
            setOwner(currentOwner);
        } else if (
            props.businessGroupingToEdit &&
            props.businessGroupingToEdit?.users.find((user) => props.businessGroupingToEdit && user.id === props.businessGroupingToEdit.owned_by) !==
                undefined
        ) {
            setOwner(
                props.businessGroupingToEdit?.users.find((user) => props.businessGroupingToEdit && user.id === props.businessGroupingToEdit.owned_by)
            );
        }

        const users = watch('business_grouping_users');
        if (users !== null && control._formValues.business_grouping_users) {
            setCurrentUsers(users);
        } else if (props.businessGroupingToEdit) {
            setCurrentUsers(props.businessGroupingToEdit.users);
        }
    }, [watch, props.businessGroupingToEdit, control._formValues.business_grouping_owner, control._formValues.business_grouping_users]);

    useEffect(() => {
        if (props.isBackPage) {
            reset({});
        }
    }, [props.isBackPage]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful]);

    useEffect(() => {
        if (usersApi.usersV2 !== undefined) setUsers(usersApi.usersV2);
    }, [usersApi.usersV2]);

    useEffect(() => {
        if (props.usersBusinessGroupingsFlat && props.businessGroupingToEdit) {
            reset(
                {
                    business_grouping_name: props.businessGroupingToEdit?.name ?? '',
                    business_grouping_description: props.businessGroupingToEdit?.description ?? '',
                    business_grouping_owner: props.businessGroupingToEdit?.owned_by
                        ? (users.find((user) => user.id === props.businessGroupingToEdit?.owned_by) ?? null)
                        : (users.find((user) => user.email === keycloak?.keycloak?.tokenParsed?.email) ?? null), //what if user is not in list current user doesnt have access?
                    business_grouping_users: props.businessGroupingToEdit?.users ?? [],
                    business_grouping_type: props.businessGroupingToEdit?.type ?? 1,
                    business_grouping_parent: props.businessGroupingToEdit?.parent_id
                        ? (props.usersBusinessGroupingsFlat.find((bg) => bg.id === props.businessGroupingToEdit?.parent_id) ?? null)
                        : (props.usersBusinessGroupingsFlat.find((bg) => bg.name === 'default') ??
                          (props.businessGroupingToEdit && { name: 'Current Parent', id: props.businessGroupingToEdit.parent_id }) ??
                          null),
                },
                { keepDirtyValues: true, keepIsValid: true }
            );
        }
    }, [props.businessGroupingToEdit, props.usersBusinessGroupingsFlat]);

    return (
        <>
            {props.isLoading && isError && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Grid2 className={cx(classes.Container)} size={12} container direction={'row'}>
                {props.onCloseDrawer !== undefined && (
                    <Grid2 container alignItems={'center'} justifyContent={'flex-end'} size={12}>
                        <IconButton onClick={props.onCloseDrawer} className={cx(classes.CloseButton)}>
                            <Close />
                        </IconButton>
                    </Grid2>
                )}
                <Grid2 className={cx(classes.TitleGrid)} size={12}>
                    <Typography variant={'subtitle1'} fontSize={'24px'}>
                        {props.businessGroupingToEdit && props.businessGroupingToEdit.name ? props.businessGroupingToEdit.name : 'Create'}
                    </Typography>
                </Grid2>
                <Grid2 size={12}>
                    {props.businessGroupingToEdit && (
                        <Tabs variant={'fullWidth'} value={currentTab} onChange={onTabChange}>
                            <Tab
                                value='details'
                                label='Details'
                                icon={
                                    isDirty && currentTab === 'details' ? (
                                        <StyledToolTip arrow title={'This business grouping has unsaved changes.'}>
                                            <Warning color={'warning'} role={'img'} aria-label={'warning icon'} />
                                        </StyledToolTip>
                                    ) : undefined
                                }
                                iconPosition={'start'}
                            />
                            <Tab value='resources' label='Assigned Resources' />
                            <Tab
                                value='users'
                                label='Users'
                                icon={
                                    isDirty && currentTab === 'users' ? (
                                        <StyledToolTip arrow title={'This business grouping has unsaved changes.'}>
                                            <Warning color={'warning'} role={'img'} aria-label={'warning icon'} />
                                        </StyledToolTip>
                                    ) : undefined
                                }
                                iconPosition={'start'}
                            />
                            {vOperate && <Tab value='scheduler' label='Enterprise Scheduler' />}
                        </Tabs>
                    )}
                </Grid2>
                <Grid2
                    id='business-group-details-form'
                    component={'form'}
                    onSubmit={handleSubmit((data) => {
                        data.business_grouping_parent = data.business_grouping_parent ? (data.business_grouping_parent as any)[0] : null;
                        props.onSubmitForm(data);
                    })}
                    className={cx(classes.TabContentContainer)}
                    size={12}
                >
                    {currentTab === 'details' && (
                        <BusinessGroupingWizardDetailsTab
                            setIsLoading={setIsLoading}
                            setIsError={setIsError}
                            userBusinessGroupings={props.businessGroupingToEdit ? props.validParentBusinessGroupings : props.userBusinessGroupings}
                            availableUsers={props.availableUsers}
                            businessGroupingTypes={props.businessGroupingTypes}
                            businessGroupingToEdit={props.businessGroupingToEdit}
                            isDirty={isDirty}
                            isValid={isValid}
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            setUsers={setUsers}
                            users={users}
                            handleSubmit={handleSubmit}
                            reset={reset}
                        />
                    )}
                    {currentTab === 'resources' && <BusinessGroupingWizardResourcesTab />}
                    {currentTab === 'users' && (
                        <BusinessGroupingWizardUsersTab
                            //This needs to be the loaded users from BG or the changed assigned users as the form is changed?
                            trigger={trigger}
                            assignedUsers={currentUsers}
                            businessGroupingToEdit={props.businessGroupingToEdit}
                            owner={owner}
                            availableUsers={props.availableUsers}
                            isLoading={props.isLoading}
                            unassignUsers={props.unassignUsers}
                            control={control}
                            watch={watch}
                            register={register}
                            handleSubmit={handleSubmit}
                            reset={reset}
                            setValue={setValue}
                            errors={errors}
                        />
                    )}
                    {vOperate && currentTab === 'scheduler' && (
                        <BusinessGroupingWizardParkingSchedulesTab
                            isDeleteExemptionDialogOpen={props.isDeleteExemptionDialogOpen}
                            setIsDeleteExemptionDialogOpen={props.setIsDeleteExemptionDialogOpen}
                            scheduleWithExemption={props.scheduleWithExemption}
                            setScheduleWithExemption={props.setScheduleWithExemption}
                            onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                            onClickDeleteExemption={props.onClickDeleteExemption}
                            parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                            scheduleToEdit={props.scheduleToEdit}
                            setScheduleToEdit={props.setScheduleToEdit}
                            selectedSchedules={props.selectedSchedules}
                            setSelectedSchedules={props.setSelectedSchedules}
                            isParkingLoading={props.isParkingLoading}
                            parkingSchedules={props.parkingSchedules}
                            onClickDeleteSchedule={props.onClickDeleteSchedule}
                            onClickDeleteSelectedSchedules={props.onClickDeleteSelectedSchedules}
                            isChangeScheduleDialogOpen={props.isChangeScheduleDialogOpen}
                            setIsChangeScheduleDialogOpen={props.setIsChangeScheduleDialogOpen}
                            isRemoveAdvancedParkingDialogOpen={props.isRemoveAdvancedParkingDialogOpen}
                            setIsRemoveAdvancedParkingDialogOpen={props.setIsRemoveAdvancedParkingDialogOpen}
                            onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                            onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                            setScheduleToDelete={props.setScheduleToDelete}
                            scheduleToDelete={props.scheduleToDelete}
                            isScheduled={props.isScheduled}
                            isConfirmDeleteDialogOpen={props.isConfirmDeleteDialogOpen}
                            setIsConfirmDeleteDialogOpen={props.setIsConfirmDeleteDialogOpen}
                            onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                            onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                            schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                            onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                            setParkingScheduleType={props.setParkingScheduleType}
                        />
                    )}
                </Grid2>
            </Grid2>
            <Grid2 container className={cx(classes.ButtonContainer)}>
                <Grid2 justifyContent={'flex-end'} container size={12}>
                    {!props.businessGroupingToEdit
                        ? currentTab !== 'details' && (
                              <Button variant={'cancel'} className={cx(classes.CancelButton)} onClick={onClickBack}>
                                  Back
                              </Button>
                          )
                        : isDirty &&
                          isValid && (
                              <Button
                                  variant={'cancel'}
                                  className={cx(classes.CancelButton)}
                                  onClick={() => {
                                      reset(defaultValues, { keepDefaultValues: true, keepIsValid: false });
                                  }}
                              >
                                  Reset Changes
                              </Button>
                          )}
                    {currentTab === 'users' || props.businessGroupingToEdit ? (
                        <Button
                            disabled={!isDirty || !isValid}
                            form={'business-group-details-form'}
                            type={'submit'}
                            className={cx(classes.ContinueButton)}
                        >
                            {props.businessGroupingToEdit ? 'Save' : 'Create'}
                        </Button>
                    ) : (
                        <Button
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                trigger(['business_grouping_name', 'business_grouping_parent', 'business_grouping_owner']).then((isValid) => {
                                    if (isValid) {
                                        onClickContinue();
                                    }
                                });
                            }}
                            className={cx(classes.ContinueButton)}
                        >
                            Next
                        </Button>
                    )}
                </Grid2>
            </Grid2>
            <BusinessGroupingUnsavedChangesWarningDialog
                isUnsavedChangesWarningDialog={isUnsavedChanges}
                onCloseDialog={() => {
                    setIsUnsavedChanges(false);
                }}
            />
        </>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardTabsProps>()((theme, props) => ({
    Container: { overflow: 'auto', marginTop: '-1rem' },
    CloseButton: { float: 'right', marginRight: '-2rem', marginTop: '-2rem' },
    TabContentContainer: { marginTop: '1rem', height: 'calc(100vh - 27rem)' },
    CancelButton: { marginRight: '1rem' },
    ContinueButton: { float: 'right' },
    ButtonContainer: {
        paddingTop: '1.5rem',
        marginTop: 'auto',
        width: '100%',
    },
    TitleGrid: { marginBottom: '1rem' },
}));

export { BusinessGroupingWizardTabs };
