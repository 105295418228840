import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { IAssignmentRule } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeleteAssignmentRuleDialogProps {
    isOpen: boolean;
    onClose: () => void;
    assignmentRule: IAssignmentRule;
    onDeleteAssignmentRule: (assignmentRule: IAssignmentRule) => void;
}

const DeleteAssignmentRuleDialog: React.FC<IDeleteAssignmentRuleDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{`Delete ${props.assignmentRule.name}`}</DialogTitle>
            <DialogContent>
                <DialogContentText variant={'body2'}>This action cannot be undone.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    color={'error'}
                    onClick={() => {
                        props.onDeleteAssignmentRule(props.assignmentRule);
                        props.onClose();
                    }}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IDeleteAssignmentRuleDialogProps>()((theme, props) => ({}));

export { DeleteAssignmentRuleDialog };
