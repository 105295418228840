export const sessionTexts = {
    menuState: `${window.location.hostname.split('.')[0]}-menuState`,
    authenticated: `${window.location.hostname.split('.')[0]}-authenticated`,
    route: `${window.location.hostname.split('.')[0]}-route`,
    businessGroupToEdit: `${window.location.hostname.split('.')[0]}-businessGroupToEdit`,
    businessGroupRulesApplyJobId: `${window.location.hostname.split('.')[0]}-businessGroupRulesApplyJobId`,
    keycloakUserId: `${window.location.hostname.split('.')[0]}-keycloakUserId`,
};

export const localStorageTexts = {
    tableControls: `${window.location.hostname.split('.')[0]}-tableControls`,
};
