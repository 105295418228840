import { customMakeStyles } from '@vegaplatformui/styling';
import { Theme } from '@mui/material/styles';
import { getContrastRatio } from '@mui/material';

const menuItemHeight = '30px';
export const useCommonDrawerStyles = customMakeStyles<{ isDrawerOpen: boolean; isCollapsible: boolean; theme: Theme; showUpdatedUi: boolean }>()(
    (theme, { isDrawerOpen, isCollapsible, showUpdatedUi }) => ({
        SelectedMenuItem: {
            '&.Mui-selected': {
                /*
                backgroundColor: 'rgba(237, 233, 254, 0.9)', this can change opqacity of the background color but this current color will put a purple tint on everything
*/
                '&:hover': {
                    /*
                    backgroundColor: `rgba(237, 233, 254, 0.9) !important`,
*/
                },
                '& .MuiSvgIcon-root': {
                    fill: theme.palette.primary.main,
                },
            },
        },
        HideSelectedMenuItem: {
            '&.Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.0)',
                color:
                    showUpdatedUi && getContrastRatio(theme.palette.grey[200], theme.palette.secondary.main) >= theme.palette.contrastThreshold
                        ? theme.palette.grey[200]
                        : theme.palette.grey[400],
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.0) !important',
                },
                '& .MuiSvgIcon-root': {
                    fill:
                        showUpdatedUi && getContrastRatio(theme.palette.grey[200], theme.palette.secondary.main) >= theme.palette.contrastThreshold
                            ? theme.palette.grey[200]
                            : theme.palette.grey[400],
                },
            },
        },
        SuperMenuItemOpen: {
            width: '100%',
            padding: '10px 16px',
            height: menuItemHeight,
        },
        SuperMenuItemClosed: {
            padding: '12px 16px',
        },
        MenuItemHover: {
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        },
        MenuItem: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            padding: 0,
            marginBottom: '0.25rem',
            '& .MuiSvgIcon-root': {
                fill:
                    showUpdatedUi && getContrastRatio(theme.palette.grey[200], theme.palette.secondary.main) >= theme.palette.contrastThreshold
                        ? theme.palette.grey[200]
                        : theme.palette.grey[400],
            },
        },
        MainMenuItem: {
            width: '100%',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        SubMenuIcon: {
            maxWidth: '1rem',
            marginRight: '1rem',
            width: '24px',
            height: '24px',
        },
        CommonMenuStyling: {
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
        },
        SubMenuItem: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '10px 16px',
            height: menuItemHeight,
        },
    })
);
