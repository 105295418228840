import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, IconButton, Typography } from '@mui/material';
import { IUserSettingSSOSaml } from '@vegaplatformui/models';
import { Delete, Edit } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoConfigurationTileProps {
    ssoItem: IUserSettingSSOSaml;
    onClickOpenEditConfiguration: (idpToEdit: IUserSettingSSOSaml) => void;
    onClickDeleteTile: (idpToDelete: IUserSettingSSOSaml) => void;
}

const SquareTileIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[700],
    borderRadius: '8px',
    padding: '10px',
    border: '1px solid  #D0D5DD',
    '&:hover': {
        backgroundColor: theme.palette.grey.A200,
    },
}));

const SsoConfigurationTile: React.FC<ISsoConfigurationTileProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { ssoItem, onClickDeleteTile, onClickOpenEditConfiguration } = props;

    return (
        <Grid
            key={`${ssoItem.alias}`}
            item
            container
            xs={12}
            className={cx(classes.SSOTileItemGrid)}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
        >
            <Grid xs={12} sm={7} item container>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>{ssoItem.display_name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={cx(classes.SSOProviderType)} variant={'body2'}>
                        {ssoItem.alias}
                    </Typography>
                </Grid>
            </Grid>
            <Grid xs={12} sm={5} item container spacing={1.5} justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <SquareTileIconButton
                        onClick={() => onClickOpenEditConfiguration(ssoItem)}
                        aria-label={`edit ${ssoItem.display_name}'s configuration`}
                    >
                        <Edit />
                    </SquareTileIconButton>
                </Grid>
                <Grid item>
                    <SquareTileIconButton onClick={() => onClickDeleteTile(ssoItem)} aria-label={`delete ${ssoItem.display_name}'s configuration`}>
                        <Delete />
                    </SquareTileIconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ISsoConfigurationTileProps>()((theme, props) => ({
    SSOProviderType: { color: theme.palette.grey[500] },
    SSOTileItemGrid: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '1rem', marginTop: '1rem', minWidth: '168px' },
}));

export { SsoConfigurationTile };
