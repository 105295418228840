import { ContainerType, IVegaContainer } from '@vegaplatformui/models';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import React, { ReactNode } from 'react';
import { GridRowId } from '@mui/x-data-grid';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { Button, Stack } from '@mui/material';
import { AutoAwesomeMotion, FolderSharp, GroupWork, Inventory2 } from '@mui/icons-material';
import { ILinkToProfileProps } from './link-to-profile';
import { customMakeStyles } from '@vegaplatformui/styling';

export interface IGetDescendantCountProps {
    row: IVegaContainer;
    apiRef: React.MutableRefObject<GridApiPremium>;
    spaces: IVegaContainer[];
    onClickTableItem: (container: IVegaContainer[], containerType?: ContainerType) => void;
}

const GetVegaContainerDescendantCount: React.FC<IGetDescendantCountProps> = (props) => {
    const { cx, classes } = useStyles(props);
    const rows = props.apiRef.current.getRowGroupChildren({ groupId: props.row.id! });

    const ContainerNameRender = (id: GridRowId, isEnabled: boolean) => {
        const currentContainer = props.spaces.find((space) => space.id === id);
        const type = currentContainer?.container_type;
        const name = currentContainer?.name;
        const containerId = currentContainer?.id;

        let container: IVegaContainer[];

        switch (type) {
            case ContainerType.Space.toLowerCase():
                container = props.spaces.filter((space) => space.path?.split('/')[0] === containerId);

                break;

            case ContainerType.Workload.toLowerCase():
                container = props.spaces.filter((workload) => workload.path?.split('/')[1] === containerId);

                break;

            case ContainerType.ResourcePool.toLowerCase():
                container = props.spaces.filter((resourcepool) => resourcepool.path?.split('/')[2] === containerId);

                break;
        }
        return (
            <Stack spacing={0.5} direction={'row'} alignItems={'center'}>
                {type === ContainerType.Space.toLowerCase() ? (
                    <FolderSharp />
                ) : type === ContainerType.Workload.toLowerCase() ? (
                    <AutoAwesomeMotion />
                ) : type === ContainerType.ResourcePool.toLowerCase() ? (
                    <GroupWork />
                ) : type === ContainerType.Resource.toLowerCase() ? (
                    <Inventory2 />
                ) : (
                    ''
                )}
                <Button
                    disabled={!isEnabled && type !== ContainerType.Resource.toLowerCase()}
                    onClick={() => {
                        props.onClickTableItem(container, type);
                    }}
                    variant={'text'}
                    className={cx(classes.ContainerButton)}
                >
                    {name}
                </Button>
            </Stack>
        );
    };

    return ContainerNameRender(props.row.id!, rows.length > 0);
};

const useStyles = customMakeStyles<IGetDescendantCountProps>()((theme, props) => ({
    ContainerButton: {
        textTransform: 'none',
    },
}));

export { GetVegaContainerDescendantCount };
