import { Grid2, Typography } from '@mui/material';
import { GridSlotProps } from '@mui/x-data-grid';
import { Link } from 'react-router';
import { useRouteUrls } from '@vegaplatformui/utils';

declare module '@mui/x-data-grid-premium' {
    interface NoRowsOverlayPropsOverrides extends GridSlotProps {
        action?: string;
        url?: string;
    }
}

// renders when no data is fed to the table
const CustomNoRowsOverlay = ({ action = 'adding data', url }: { action?: string; url?: string }) => {
    const { routeUrls } = useRouteUrls({});

    return (
        <Grid2 aria-live='assertive' height={'100%'} container direction='row' justifyContent='center' alignItems='center'>
            <Grid2 size={12}>
                <Typography variant={'body1'} align={'center'}>
                    No data to show
                </Typography>
                <Typography variant={'body2'} align={'center'}>
                    Start by {!!url ? <Link to={`/${routeUrls.settings.route.path}/${url}`}>{action}</Link> : action} or checking the filter settings.
                </Typography>
            </Grid2>
        </Grid2>
    );
};

// renders when the filter results are empty
const CustomNoResultsOverlay = () => {
    return (
        <Grid2 aria-live='assertive' height={'100%'} container direction='row' justifyContent='center' alignItems='center'>
            <Grid2 size={12}>
                <Typography variant={'body1'} align={'center'}>
                    No data matches the filter criteria
                </Typography>
            </Grid2>
        </Grid2>
    );
};

export { CustomNoRowsOverlay, CustomNoResultsOverlay };
