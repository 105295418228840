import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { FrontendFileDownloadBlob } from './frontend-file-download-blob';

//Only for local files (right now)
//The main intention is that the file is never going to change so its stored locally (in the frontend directories) like {root}/libs/sharedassets/src/files
export interface IFetchFileBlobAndDownloadHook {
    fetchFileToDownload: (
        fileName: string,
        fileToDownload: string,
        setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void
    ) => void;
    downloadJsonAsFile: (data: any, fileName: string) => void;
}

//Only for local files (right now)
//The main intention is that the file is never going to change so its stored locally (in the frontend directories) like {root}/libs/sharedassets/src/files
const useFetchFileBlobAndDownload = (): IFetchFileBlobAndDownloadHook => {
    const fetchFile = (
        fileName: string,
        fileToDownload: string,
        setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void
    ) => {
        fetch(fileToDownload).then((response) => {
            response
                .blob()
                .then((blob) => {
                    FrontendFileDownloadBlob(blob, fileName);
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: 'There was a problem downloading the file',
                    });
                })
                .finally(() => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'info' },
                        message: 'File downloaded',
                    });
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: 'There was a problem fetching the file',
                    });
                });
        });
    };

    const downloadJsonAsFile = (data: any, fileName: string) => {
        // Create a blob of the data
        const fileToSave = new Blob([JSON.stringify(data, undefined, 2)], {
            type: 'application/json',
        });
        FrontendFileDownloadBlob(fileToSave, fileName);
    };
    return {
        fetchFileToDownload: fetchFile,
        downloadJsonAsFile: downloadJsonAsFile,
    };
};

export { useFetchFileBlobAndDownload };
