import { useKeycloak } from '@react-keycloak-fork/web';
import { useMemo } from 'react';
import { useQuery, useMutation, UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { OrganizationsApi } from '@vegaplatformui/apis';
import {
    OrganizationId,
    SnackBarOptions,
    CustomColorsState,
    CustomPrimaryLogoState,
    CustomSecondaryLogoState,
    CustomFaviconState,
    IsLogoFirstLoad,
    IsThemeFirstLoad,
} from '../jotai/atom';
import {
    IGetOrganizationResponse,
    ISaveOrgLogoVariables,
    IOrganizationLogo,
    IGetLogoResponse,
    IOrganizationThemeColors,
    IPostOrganizationThemeHookObject,
    IPutOrganizationThemeHookObject,
    ILogoVariation,
} from '@vegaplatformui/models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAtomValue, useSetAtom } from 'jotai';

export interface IUseOrganizationsApiHook {
    organizationData: IGetOrganizationResponse | undefined;
    isOrganizationDataLoading: boolean;
    isOrganizationDataSuccess: boolean;
    organizationLogoData: IGetLogoResponse | undefined;
    isOrganizationLogoDataLoading: boolean;
    isOrganizationLogoDataSuccess: boolean;
    saveOrganizationLogo: UseMutateFunction<any, any, ISaveOrgLogoVariables, unknown>; // TODO: add type
    deleteOrganizationLogo: UseMutateFunction<any, unknown, any, unknown>; // TODO: add type
    updateOrganizationData: UseMutateFunction<any, unknown, any, unknown>; // TODO: add type
    themeData: IOrganizationThemeColors | undefined;
    isThemeDataLoading: boolean;
    createOrganizationTheme: (hookRequest: IPostOrganizationThemeHookObject) => void;
    updateOrganizationTheme: (hookRequest: IPutOrganizationThemeHookObject) => void;
    orgUpdateStatus?: string;
    themeCreateStatus?: string;
    themeUpdateStatus?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseOrganizationApiProps {}

export function useOrganizationsApi(props: IUseOrganizationApiProps): IUseOrganizationsApiHook {
    const { keycloak } = useKeycloak();
    const orgId = useAtomValue<string | undefined>(OrganizationId);
    const queryClient = useQueryClient();
    const setSnackBarOptions = useSetAtom(SnackBarOptions);
    const setCustomColors = useSetAtom(CustomColorsState);
    const setCustomPrimaryLogo = useSetAtom(CustomPrimaryLogoState);
    const setCustomSecondaryLogo = useSetAtom(CustomSecondaryLogoState);
    const setCustomFavicon = useSetAtom(CustomFaviconState);
    const setIsLogoFirstLoad = useSetAtom(IsLogoFirstLoad);
    const setIsThemeFirstLoad = useSetAtom(IsThemeFirstLoad);
    const flags = useFlags();
    const organizationsApi = useMemo(() => {
        const organizationApi = new OrganizationsApi();
        organizationApi.keycloak = keycloak;
        return organizationApi;
    }, [keycloak]);

    // get organization data
    const {
        data: organizationData,
        isLoading: isOrganizationDataLoading,
        isSuccess: isOrganizationDataSuccess,
    } = useQuery({
        queryKey: [queryKeys.organizationsApi.getOrganization, orgId],
        queryFn: async () => {
            if (orgId) {
                const response = await organizationsApi.getOrganization(orgId);
                return response.data;
            }
        },
        enabled: flags.organizationSettingsTab !== undefined && !!orgId,
        meta: {
            errorMessage: 'There was a problem loading the organization data.',
        },
    });

    // update organization data
    const {
        mutate: updateOrganizationData,
        isSuccess: isUpdateOrganizationDataSuccess,
        isError: isUpdateOrganizationDataError,
        data: updateOrganizationDataData,
        status: orgUpdateStatus,
    } = useMutation({
        onError: (error: any) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem updating the organization data. Error: ${error.response?.data?.message}}`,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganization, orgId] });
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'success' },
                message: 'Organization data updated successfully!',
            });
        },
        mutationFn: async (variables: any) => {
            if (flags.organizationSettingsTab) {
                return organizationsApi.putOrganization(variables.orgId, variables.payload);
            }
        },
    });

    // get org logo
    const {
        data: organizationLogoData,
        isLoading: isOrganizationLogoDataLoading,
        isSuccess: isOrganizationLogoDataSuccess,
    } = useQuery({
        queryKey: [queryKeys.organizationsApi.getOrganizationLogo, orgId],
        queryFn: async () => {
            if (orgId) {
                const response = await organizationsApi.getOrganizationLogo(orgId);
                setCustomPrimaryLogo({ isCustom: !!response.data.primary.url ? true : false, url: response.data.primary.url });
                setCustomSecondaryLogo({ isCustom: !!response.data.secondary.url ? true : false, url: response.data.secondary.url });
                setCustomFavicon({ isCustom: !!response.data.favicon.url ? true : false, url: response.data.favicon.url });
                setIsLogoFirstLoad(false);
                return response.data;
            }
        },
        enabled: flags.organizationSettingsTab !== undefined && !!orgId,
        meta: {
            errorMessage: 'There was a problem loading the organization logo data.',
        },
    });

    // save org logo
    const {
        mutate: saveOrganizationLogo,
        isSuccess: isSaveOrganizationLogoSuccess,
        isPending: isSaveOrganizationLogoPending,
        isError: isSaveOrganizationLogoError,
        data: saveOrganizationLogoData,
    } = useMutation({
        onError: (error: any) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem saving the organization logo. Error: ${error.response?.data?.detail}`,
            });
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationLogo, orgId] });
        },
        onSuccess: (response, variables, context) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'success' },
                message: `Logo uploaded successfully!`,
            });
            switch (variables.variation) {
                case ILogoVariation.PRIMARY:
                    setCustomPrimaryLogo({ isCustom: !!response?.data, url: response?.data ?? '' });
                    break;
                case ILogoVariation.SECONDARY:
                    setCustomSecondaryLogo({ isCustom: !!response?.data, url: response?.data ?? '' });
                    break;
                case ILogoVariation.FAVICON:
                    setCustomFavicon({ isCustom: !!response?.data, url: response?.data ?? '' });
                    break;
            }
            if (!response) {
                queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationLogo, orgId] });
            }
        },
        mutationFn: async (variables: ISaveOrgLogoVariables) => {
            if (flags.organizationSettingsTab) {
                return organizationsApi.postOrganizationLogo(variables);
            }
        },
    });

    // delete org logo
    const { mutate: deleteOrganizationLogo } = useMutation({
        onError: (error: any) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting the organization logo: ${error.response?.data?.detail}`,
            });
            if (flags.organizationSettingsTab) {
                queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationLogo, orgId] });
            }
        },
        onSuccess: (data, variables, context) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'success' },
                message: `Logo deleted successfully!`,
            });
            const favicon = document.getElementById('favicon');
            switch (variables.variation) {
                case ILogoVariation.PRIMARY:
                    setCustomPrimaryLogo({ isCustom: false, url: '' });
                    break;
                case ILogoVariation.SECONDARY:
                    setCustomSecondaryLogo({ isCustom: false, url: '' });
                    break;
                case ILogoVariation.FAVICON:
                    setCustomFavicon({ isCustom: false, url: '' });
                    //check if element and org logo data exist
                    if (favicon) {
                        favicon.setAttribute('href', 'assets/public/favicon.ico');
                    }
                    break;
            }
        },
        mutationFn: async (hookRequest: IOrganizationLogo) => {
            return await organizationsApi.deleteOrganizationLogo(hookRequest.orgId, hookRequest.variation);
        },
    });

    // get branding style
    const { data: themeData, isLoading: isThemeDataLoading } = useQuery({
        queryKey: [queryKeys.organizationsApi.getOrganizationThemeData, orgId],
        queryFn: async ({ signal }) => {
            if (orgId) {
                const response = await organizationsApi.getOrganizationBranding(orgId, signal);
                setCustomColors({
                    isCustomColors: response.data.primary_color || response.data.secondary_color ? true : false,
                    primary_color: response.data.primary_color ? response.data.primary_color : '#7F56D9',
                    secondary_color: response.data.secondary_color ? response.data.secondary_color : '#101828',
                });
                const data: IOrganizationThemeColors = { primary_color: response.data.primary_color, secondary_color: response.data.secondary_color };
                setIsThemeFirstLoad(false);
                return data;
            }
        },
        staleTime: 0,
        enabled: !!flags.organizationSettingsTab && !!orgId,
        meta: {
            errorMessage: 'There was a problem loading the organization theme data.',
        },
    });

    // post branding style
    const { mutate: createOrganizationTheme, status: themeCreateStatus } = useMutation({
        onError: (error, variables, context) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the organization theme: ${error}`,
            });
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationThemeData, orgId] });
        },
        onSuccess: (response, variables, context) => {
            setSnackBarOptions({
                message: `Organization theme created successfully.`,
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'info' },
            });
            setCustomColors({
                isCustomColors: !!(response && (response.data.primary_color || response.data.secondary_color)),
                primary_color: response && response.data.primary_color ? response.data.primary_color : '#7F56D9',
                secondary_color: response && response.data.secondary_color ? response.data.secondary_color : '#101828',
            });
            if (!response) {
                queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationThemeData, orgId] });
            }
        },
        mutationFn: async (hookRequest: IPostOrganizationThemeHookObject) => {
            if (flags.organizationSettingsTab) {
                return organizationsApi.postOrganizationBranding(hookRequest.orgId, {
                    primary_color: hookRequest.primary_color,
                    secondary_color: hookRequest.secondary_color,
                    created_by: hookRequest.created_by,
                    created_at: hookRequest.created_at,
                });
            }
        },
    });

    // update branding style
    const { mutate: updateOrganizationTheme, status: themeUpdateStatus } = useMutation({
        onError: (error, variables, context) => {
            setSnackBarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem updating the organization theme: ${error}`,
            });
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationThemeData, orgId] });
        },
        onSuccess: (response) => {
            setSnackBarOptions({
                message: `Organization theme updated successfully.`,
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'info' },
            });
            setCustomColors({
                isCustomColors: !!(response && (response.data.primary_color || response.data.secondary_color)),
                primary_color: response && response.data.primary_color ? response.data.primary_color : '#7F56D9',
                secondary_color: response && response.data.secondary_color ? response.data.secondary_color : '#101828',
            });
            if (!response) {
                queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationThemeData, orgId] });
            }
        },
        mutationFn: async (hookRequest: IPutOrganizationThemeHookObject) => {
            if (flags.organizationSettingsTab) {
                return organizationsApi.putOrganizationBranding(hookRequest.orgId, {
                    primary_color: hookRequest.primary_color,
                    secondary_color: hookRequest.secondary_color,
                    updated_by: hookRequest.updated_by,
                    updated_at: hookRequest.updated_at,
                });
            }
        },
    });

    return {
        organizationData,
        isOrganizationDataLoading,
        isOrganizationDataSuccess,
        organizationLogoData,
        isOrganizationLogoDataLoading,
        isOrganizationLogoDataSuccess,
        saveOrganizationLogo,
        deleteOrganizationLogo,
        updateOrganizationData,
        themeData,
        isThemeDataLoading,
        createOrganizationTheme,
        updateOrganizationTheme,
        orgUpdateStatus,
        themeUpdateStatus,
        themeCreateStatus,
    };
}
