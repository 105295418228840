import {
    addVegaTableControl,
    defaultVegaTableControl,
    IVegaTableControl,
    IVegaTableControls,
    removeVegaTableControl,
    vegaTableControls,
} from '../jotai/atom';
import { useEffect } from 'react';
import { GridColumnVisibilityModel, GridDensity, GridFilterItem, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GridFilterModel } from '@mui/x-data-grid';
import { localStorageTexts, sessionTexts } from '@vegaplatformui/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';

interface ITableUtilities {
    updateTotalRows: (numberOfRows: number) => void;
    currentTableControl: IVegaTableControl | undefined;
    onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
    onColumnVisibilityModelChange: (columnModel: GridColumnVisibilityModel) => void;
    onSortModelChange: (sortModel: GridSortModel) => void;
    onFilterModelChange: (filterModel: GridFilterModel) => void;
    onDensityChange: (density: GridDensity) => void;
    addFilterItemToFilterModel: (gridFilterItemToAdd: GridFilterItem) => GridFilterModel | null;
    registerTableControl(initialHiddenColumns?: GridColumnVisibilityModel): void;
    unregisterTableControl(): void;
    onPaginationModelPageChange: (event: any | null, page: number) => void;
    resetTableControl(): void;
}

export function useTableUtilities(tableIdentifier: string, defaultHidden?: GridColumnVisibilityModel, queryKey?: string): ITableUtilities {
    const [tableControls, setTableControls] = useAtom<IVegaTableControls[]>(vegaTableControls);
    const addTableControl = useSetAtom(addVegaTableControl);
    const removeTableControl = useSetAtom(removeVegaTableControl);
    const currentTableControl = tableControls.find((control: IVegaTableControls) => control.key === tableIdentifier);
    const queryClient = useQueryClient();

    const cancelQuery = () => {
        queryKey && queryClient.cancelQueries({ queryKey: [queryKey], exact: false });
    };

    useEffect(() => {
        //serialize the tableControls to local storage
        localStorage.setItem(
            `${localStorageTexts.tableControls}_${sessionStorage.getItem(sessionTexts.keycloakUserId)}`,
            JSON.stringify(tableControls)
        );
    }, [tableControls]);

    const registerTableControl = (initialHiddenColumns?: GridColumnVisibilityModel) => {
        if (!tableControls.find((control: IVegaTableControls) => control.key === tableIdentifier)) {
            addTableControl({
                key: tableIdentifier,
                value: initialHiddenColumns ? { ...defaultVegaTableControl, columnModel: initialHiddenColumns } : { ...defaultVegaTableControl },
            });
        }
    };

    const unregisterTableControl = () => {
        onPaginationModelPageChange(null, 0);
        removeTableControl(tableIdentifier);
    };

    const resetTableControl = () => {
        onPaginationModelPageChange(null, 0);
        setTableControls((controls: IVegaTableControls[]) => {
            return controls.map((control: IVegaTableControls) => {
                if (control.key === tableIdentifier) {
                    if (defaultHidden) {
                        control.value = { ...defaultVegaTableControl, totalRows: control.value.totalRows, columnModel: defaultHidden };
                    } else {
                        control.value = { ...defaultVegaTableControl, totalRows: control.value.totalRows };
                    }
                }
                return control;
            });
        });
    };

    const addFilterItemToFilterModel = (gridFilterItemToAdd: GridFilterItem): GridFilterModel | null => {
        if (currentTableControl !== undefined) {
            const newTableControls = {
                ...currentTableControl.value,
                filterModel: { ...currentTableControl.value.filterModel, items: [...currentTableControl.value.filterModel.items] },
            };

            const index = newTableControls.filterModel.items.findIndex(
                (item) =>
                    item.field === gridFilterItemToAdd.field &&
                    item.operator === gridFilterItemToAdd.operator &&
                    item.value === gridFilterItemToAdd.value
            );
            if (index === -1) {
                newTableControls.filterModel.items.push(gridFilterItemToAdd);

                return newTableControls.filterModel;
            } else {
                return newTableControls.filterModel;
            }
        }
        return null;
    };

    function updateTotalRows(numberOfRows: number) {
        setTableControls((controls: IVegaTableControls[]) => {
            return controls.map((control: IVegaTableControls) => {
                if (control.key === tableIdentifier) {
                    control.value.totalRows = numberOfRows;
                }
                return control;
            });
        });
    }

    function onPaginationModelChange(paginationModel: GridPaginationModel) {
        cancelQuery();
        setTableControls((controls: IVegaTableControls[]) => {
            return controls.map((control: IVegaTableControls) => {
                if (control.key === tableIdentifier) {
                    control.value.paginationModel = paginationModel;
                }
                return control;
            });
        });
    }

    function onPaginationModelPageChange(event: any | null, page: number) {
        //change pagination model page
        cancelQuery();
        setTableControls((controls: IVegaTableControls[]) => {
            return controls.map((control: IVegaTableControls) => {
                if (control.key === tableIdentifier) {
                    control.value.paginationModel.page = page;
                }
                return control;
            });
        });
    }

    function onSortModelChange(sortModel: GridSortModel) {
        cancelQuery();
        setTableControls((oldTableControls: IVegaTableControls[]) => {
            const newTableControls = [...oldTableControls];
            const tableControlIndex = newTableControls.findIndex((control: IVegaTableControls) => control.key === tableIdentifier);
            newTableControls[tableControlIndex].value.sortModel = sortModel;
            return newTableControls;
        });
    }

    function onFilterModelChange(filterModel: GridFilterModel) {
        cancelQuery();
        setTableControls((oldTableControls: IVegaTableControls[]) => {
            const newTableControls = [...oldTableControls];
            const tableControlIndex = newTableControls.findIndex((control: IVegaTableControls) => control.key === tableIdentifier);
            newTableControls[tableControlIndex].value.filterModel = filterModel;
            return newTableControls;
        });
    }

    function onColumnVisibilityModelChange(columnModel: GridColumnVisibilityModel | undefined) {
        setTableControls((oldTableControls: IVegaTableControls[]) => {
            const newTableControls = [...oldTableControls];
            const tableControlIndex = newTableControls.findIndex((control: IVegaTableControls) => control.key === tableIdentifier);
            newTableControls[tableControlIndex].value.columnModel = columnModel;
            return newTableControls;
        });
    }

    function onDensityChange(density: GridDensity) {
        setTableControls((oldTableControls: IVegaTableControls[]) => {
            const newTableControls = [...oldTableControls];
            const tableControlIndex = newTableControls.findIndex((control: IVegaTableControls) => control.key === tableIdentifier);
            newTableControls[tableControlIndex].value.density = density;
            return newTableControls;
        });
    }

    return {
        updateTotalRows,
        currentTableControl: currentTableControl?.value,
        onPaginationModelChange,
        onSortModelChange,
        onFilterModelChange,
        addFilterItemToFilterModel,
        onColumnVisibilityModelChange,
        registerTableControl,
        unregisterTableControl,
        onPaginationModelPageChange,
        resetTableControl,
        onDensityChange,
    };
}

const debounce = (fn: any, delay: number) => {
    let timerId: any;
    return (...args: any[]) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    };
};
