import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormField } from '../forms';
import { Autocomplete, TextField } from '@mui/material';
import { ITimeZone, timezoneSet } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITimezoneFormfieldProps {
    timezone: ITimeZone | null;
    setTimezone: React.Dispatch<React.SetStateAction<ITimeZone | null>>;
}

const TimezoneFormField: React.FC<ITimezoneFormfieldProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { timezone, setTimezone } = props;

    return (
        <FormField label={'Timezone'} htmlFor={'timezone_input'}>
            <Autocomplete
                size={'small'}
                value={timezone ?? null}
                options={timezoneSet}
                fullWidth={true}
                onChange={(e, timezone: ITimeZone | null, reason) => {
                    setTimezone(timezone);
                }}
                renderInput={(params) => <TextField {...params} label='Select a timezone' />}
            />
        </FormField>
    );
};

const useStyles = customMakeStyles<ITimezoneFormfieldProps>()((theme, props) => ({}));

export { TimezoneFormField };
