import React from 'react';
import { useCommonStyles, customMakeStyles } from '@vegaplatformui/styling';
import { Button, Card, CardContent, Grid, LinearProgress, Stack, Typography, Tooltip } from '@mui/material';
import { Add, ArrowBack, Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AssignmentRulesTable } from '../../assignment-rules/assignment-rules-table/assignment-rules-table';
import { AssignmentRulesDrawer } from '../../assignment-rules/assignment-rules-drawer/assignment-rules-drawer';
import { IAssignmentRule, IAssignmentRuleFormValue, IBusinessGrouping } from '@vegaplatformui/models';
import { ApplyAssignmentRulesDialog } from '../../assignment-rules/apply-assignment-rules-dialog';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { GlobalCardHeight } from '../../constants/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingsAssignmentRulesCardProps {
    isLoading: boolean;
    assignmentRulesTableIdentifier: string;
    assignmentRules: IAssignmentRule[];
    isAssignmentRulesDrawerOpen: boolean;
    onClickAddAssignmentRule: () => void;
    onClickViewAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    onCloseAssignmentRulesDrawer: () => void;
    assignmentRuleFormValues: IAssignmentRuleFormValue[];
    businessGroupings: IBusinessGrouping[];
    businessGroupingsTree: TreeNodeProps[];
    onClickSaveAssignmentRule: (assignmentRule: IAssignmentRule, isEdit: boolean) => void;
    onDeleteAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    assignmentRuleToEdit?: IAssignmentRule;
    isAssignmentRuleLoading: boolean;
    applyAssignmentRules: (rule: string) => void;
    applyAssignmentRulesStatus: string;
    isAssignmentRuleSaving: boolean;
}

const BusinessGroupingsAssignmentRulesCard: React.FC<IBusinessGroupingsAssignmentRulesCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const navigate = useNavigate();
    const [isApplyAssignmentRulesDialogOpen, setIsApplyAssignmentRulesDialogOpen] = React.useState<boolean>(false);
    const commonStyles = useCommonStyles();

    return (
        <>
            <ApplyAssignmentRulesDialog
                isOpen={isApplyAssignmentRulesDialogOpen}
                onClose={() => setIsApplyAssignmentRulesDialogOpen(false)}
                onApplyAssignmentRules={props.applyAssignmentRules}
            />
            <AssignmentRulesDrawer
                isAssignmentRuleSaving={props.isAssignmentRuleSaving}
                assignmentRuleToEdit={props.assignmentRuleToEdit}
                onClickSaveAssignmentRule={props.onClickSaveAssignmentRule}
                businessGroupings={props.businessGroupingsTree}
                businessGroupingsFlat={props.businessGroupings}
                assignmentRuleFormValues={props.assignmentRuleFormValues}
                onCloseDrawer={props.onCloseAssignmentRulesDrawer}
                isOpen={props.isAssignmentRulesDrawerOpen}
                width={'70%'}
            />
            <Card className={cx(classes.Card)}>
                {props.isLoading ? (
                    <CardContent>
                        <LinearProgress />
                    </CardContent>
                ) : (
                    <CardContent>
                        <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                            <Grid item xs={4}>
                                <Button
                                    onClick={() => {
                                        navigate('/businessgroupings');
                                    }}
                                    variant='text'
                                    startIcon={<ArrowBack className={cx(classes.BackArrow)} />}
                                >
                                    <Typography color={'black'} variant={'h6'}>
                                        Assignment Rules
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid xs={8} item container justifyContent={'flex-end'}>
                                <Stack direction={'row'} spacing={1}>
                                    {/* <Button
                                        disabled={props.applyAssignmentRulesStatus.toLowerCase() === 'running'}
                                        onClick={() => setIsApplyAssignmentRulesDialogOpen(true)}
                                        variant={'outlined'}
                                    >
                                        Re-apply Rules
                                    </Button> */}
                                    <Button startIcon={<Add />} onClick={props.onClickAddAssignmentRule}>
                                        Assignment Rule
                                    </Button>
                                    <Tooltip
                                        title='These rules are only applied at this time as new resources are discovered.'
                                        placement={'top'}
                                        arrow
                                    >
                                        <Info className={commonStyles.classes.TooltipIcons} />
                                    </Tooltip>
                                </Stack>
                            </Grid>
                        </Grid>
                        <AssignmentRulesTable
                            businessGroupings={props.businessGroupings}
                            onChangeAssignmentRule={props.onClickSaveAssignmentRule}
                            onDeleteAssignmentRule={props.onDeleteAssignmentRule}
                            onClickViewAssignmentRule={props.onClickViewAssignmentRule}
                            isLoading={props.isAssignmentRuleLoading}
                            assignmentRules={props.assignmentRules}
                            assignmentRulesTableIdentifier={props.assignmentRulesTableIdentifier}
                        />
                    </CardContent>
                )}
            </Card>
        </>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingsAssignmentRulesCardProps>()((theme, props) => ({
    BackArrow: {
        fill: 'black',
    },
    Card: {
        minHeight: '100%',
        overflow: 'hidden',
    },
}));

export { BusinessGroupingsAssignmentRulesCard };
