import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { IParkingPriorityGroup, IParkingSchedule, IResource } from '@vegaplatformui/models';
import { CreateAdvancedParkingResourcesTable, IPrioritizedResource } from './create-advanced-parking-resources-table';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { PriorityGroupListItem } from './priority-group-list-item';
import { ResourcesTabActionMenu } from './resources-tab-action-menu';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { AttachResourcesDialog } from '../../attach-resources-dialog/attach-resources-dialog';
import { useRecoilState } from 'recoil';
import { areParkingScheduleTabsDirty } from '../../../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAdvancedParkingResourcesTabProps {
    resources: IResource[];
    isLoading: boolean;
    priorityGroups: IParkingPriorityGroup[];
    setPriorityGroups: React.Dispatch<React.SetStateAction<IParkingPriorityGroup[]>>;
    scheduleToEdit?: IParkingSchedule;
    onAttachResources: (resources: IResource[]) => void;
    resourcesToAdd: IResource[];
    setResourcesToAdd: React.Dispatch<React.SetStateAction<IResource[]>>;
    onDeleteResources: (resources: IResource[]) => void;
}

const AdvancedParkingResourcesTab: React.FC<IAdvancedParkingResourcesTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isActionsMenuOpen = Boolean(anchorEl);
    const [isAttachResourcesDialogOpen, setIsAttachResourcesDialogOpen] = React.useState(false);
    const [selectedResources, setSelectedResources] = React.useState<IResource[]>([]);
    const [areTabsDirty, setAreTabsAreDirty] = useRecoilState(areParkingScheduleTabsDirty);

    const onChangeResources = (resources: IPrioritizedResource[], selectionModel?: GridRowSelectionModel) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        const newPriorityGroups =
            props.priorityGroups?.map((pg) => ({
                ...pg,
                resources: [
                    ...resources.filter((res) => res.priority === pg.priority),
                    ...pg.resources.filter((res) => selectionModel?.includes(res.id)).filter((res) => !resources.find((res2) => res2.id === res.id)),
                ],
            })) ?? [];
        props.setPriorityGroups(newPriorityGroups);
    };

    const onClearSelectedResources = () => {
        !areTabsDirty && setAreTabsAreDirty(true);
        setSelectedResources([]);
    };

    const onClickAttachResources = () => {
        localStorage.setItem('previous_route', '/parking');
        setIsAttachResourcesDialogOpen(true);
    };

    return (
        <>
            <ResourcesTabActionMenu
                onClearSelectedResources={onClearSelectedResources}
                priorityGroups={props.priorityGroups}
                setPriorityGroups={props.setPriorityGroups}
                resources={props.resourcesToAdd}
                selectedResources={selectedResources}
                onDeleteResources={props.onDeleteResources}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
            />
            <AttachResourcesDialog
                onAttachResources={props.onAttachResources}
                isLoading={props.isLoading}
                attachableResources={props.resources}
                isDialogOpen={isAttachResourcesDialogOpen}
                onCloseDialog={() => setIsAttachResourcesDialogOpen(false)}
            />
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Typography variant={'h6'}>Attached Resources</Typography>
                    <Typography variant={'subtitle1'}>Manage resources in this schedule</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                        <Button
                            variant={'cancel'}
                            color={'inherit'}
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            endIcon={isActionsMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                            disabled={selectedResources.length < 2}
                        >
                            Actions
                        </Button>
                        <Button size={'small'} variant={'outlined'} onClick={onClickAttachResources}>
                            Attach Resources
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={0} className={cx(classes.Card)}>
                        <Stack
                            className={cx(classes.CustomCardContent)}
                            direction='column'
                            justifyContent='center'
                            alignItems='flex-start'
                            spacing={0.5}
                        >
                            {props.priorityGroups.map((priorityGroup) => (
                                <PriorityGroupListItem key={priorityGroup.priority} priorityGroup={priorityGroup} />
                            ))}
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <CreateAdvancedParkingResourcesTable
                        onDeleteResources={props.onDeleteResources}
                        selectedResources={selectedResources}
                        setSelectedResources={setSelectedResources}
                        priorityGroups={props.priorityGroups}
                        resources={props.resourcesToAdd}
                        isLoading={props.isLoading}
                        isServerPaginated={true}
                        onChangeResources={onChangeResources}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = customMakeStyles<IAdvancedParkingResourcesTabProps>()((theme, props) => ({
    Card: { border: '1.5px solid', borderColor: theme.palette.grey[100] },
    CustomCardContent: { paddingTop: '0.5rem', paddingBottom: '0.5rem', paddingLeft: '1rem', paddingRight: '1rem' },
}));

export { AdvancedParkingResourcesTab };
