export const maintenancePageHtml = `<!doctype html>

<html lang="en">

<head>
    <meta charset="utf-8" />
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap" rel="stylesheet">
    <link rel="shortcut icon" type="image/png" sizes="16x16" href="/16x16_Vega V.png" />
    <link rel="shortcut icon" type="image/png" sizes="32x32" href="/32x32_Vega V.png" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png">
    <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
    <meta name="theme-color" content="#ffffff" />
    <title>Vega Command Center Web Application</title>
    <link href="/static/css/2.2bbe33c5.chunk.css" rel="stylesheet">
    <link href="/static/css/main.5ebd6c88.chunk.css" rel="stylesheet">
    <style>
        .center {
            margin: auto;
            width: 50%;
            padding: 10px;
        }
    </style>
</head>

<body>
    <br />
    <p style="text-align:center;">
        <img
            src="https://static.wixstatic.com/media/06f943_f8debf1602234f63ba91aa999fa80887~mv2.png/v1/crop/x_0,y_27,w_528,h_257/fill/w_158,h_77,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Screen%20Shot%202020-03-23%20at%2010_06_29%20AM.png" />
    </p>
    <br />
    <div class="center">
        <h1>Our platform is getting a little tuneup and some love.</h1>
        <br />
        <p> We apologize for the inconvenience, but we're performing maintenance today and will be intermittently up and down as we perform this maintenance.
         You can still contact <a href="mailto:support@vegacloud.io">support@vegacloud.io</a>. We'll be back up soon!
            <br />
        <p><strong>Thank you from the Vega Team</strong></p>
    </div>
</body>

</html>`;
