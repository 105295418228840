import { useMemo, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak-fork/web';
import { ClientRegistrationApi } from '@vegaplatformui/apis';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { SnackBarOptions } from '../recoil/atom';
import { queryKeys } from './query-keys';
import { IClient, INewClient, INewClientRegistrationInformation } from '@vegaplatformui/models';
import { UserId } from '../recoil/atom';

export interface IUseClientRegistrationApiHook {
    isCreateApiClientRegistrationLoading: boolean;
    areRegisteredClientsLoading: boolean;
    callClientRegistration: (payload: INewClient) => void;
    deleteClientRegistration: (clientId: string) => void;
    expirationOptions: {} | undefined;
    registeredClients: IClient[];
    isClientRegistrationSuccess: boolean;
    isClientDeletionSuccess: boolean;
    isDeleteLoading: boolean;
    newClientInformation: INewClientRegistrationInformation | undefined;
    unsetClientInformation: () => void;
}
export interface IUseClientRegistrationApiProps {}

export function useClientRegistrationApi(props: IUseClientRegistrationApiProps): IUseClientRegistrationApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const userId = useRecoilValue(UserId);
    const queryClient = useQueryClient();
    const [newClientInformation, setNewClientInformation] = useState<INewClientRegistrationInformation | undefined>(undefined);

    const clientRegistrationApi = useMemo(() => {
        const clientRegistrationApi = new ClientRegistrationApi();
        clientRegistrationApi.keycloak = keycloak;
        return clientRegistrationApi;
    }, [keycloak]);

    const unsetClientInformation = () => {
        setNewClientInformation(undefined);
    };

    const {
        isPending: isCreateApiClientRegistrationLoading,
        mutate: callClientRegistration,
        isSuccess: isClientRegistrationSuccess,
    } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem generating Client Registration Key: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response) => {
            setNewClientInformation({ client_secret: response.data.client.client_secret, client_id: response.data.client.client_id });
            if (userId) queryClient.invalidateQueries({ queryKey: [queryKeys.clientRegistration.getRegisteredClients, userId] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'success' },
                message: 'Client Registration Was Successful',
            });
        },
        mutationFn: async (payload: INewClient) => {
            return await clientRegistrationApi.createApiClientRegistration(payload);
        },
    });

    const { data: expirationOptions } = useQuery({
        queryKey: [queryKeys.clientRegistration.getExpirationOptions],
        queryFn: async () => {
            const response = await clientRegistrationApi.getExpirationOptions();
            return response.data.expiration_duration_mappings;
        },
        meta: { errorMessage: 'Failed to fetch expiration list' },
    });

    const { data: registeredClients, isLoading: areRegisteredClientsLoading } = useQuery({
        queryKey: [queryKeys.clientRegistration.getRegisteredClients, userId],
        queryFn: async () => {
            if (userId) {
                const response = await clientRegistrationApi.getRegisteredClients(userId);
                return response.data.clients;
            }
        },
        enabled: !!userId,
        meta: { errorMessage: 'Failed to fetch registered clients' },
    });

    const {
        mutate: deleteClientRegistration,
        isPending: isDeleteLoading,
        isSuccess: isClientDeletionSuccess,
    } = useMutation({
        mutationFn: async (clientId: string) => {
            return await clientRegistrationApi.deleteClientRegistration(clientId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.clientRegistration.getRegisteredClients, userId] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'success' },
                message: 'Client Registration Was Deleted',
            });
        },
        onError: (error: any, variables) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `Unable to delete client registration:  ${error.response.data.errors[0].message ?? ''}`,
            });
        },
    });

    return {
        isCreateApiClientRegistrationLoading,
        areRegisteredClientsLoading,
        isDeleteLoading,
        callClientRegistration,
        expirationOptions,
        registeredClients: registeredClients ?? [],
        isClientRegistrationSuccess,
        deleteClientRegistration,
        isClientDeletionSuccess,
        newClientInformation,
        unsetClientInformation,
    };
}
