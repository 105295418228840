import React, { ReactNode } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps, Stack, Typography } from '@mui/material';

import { IVegaContainer } from '@vegaplatformui/models';
import Draggable from 'react-draggable';
import { ContainerTypeFormatter } from '../utilities/container-type-formatter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmDeleteContainerDialogProps {
    container: IVegaContainer | undefined;
    isConfirmDeleteDialogOpen: boolean;
    onCloseConfirmDeleteDialog: () => void;
    confirmDeleteContainer: (container?: IVegaContainer) => void;
}

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle='#confirm-delete-container-dialog' cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

const ConfirmDeleteContainerDialog: React.FC<IConfirmDeleteContainerDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog
            fullWidth
            open={props.isConfirmDeleteDialogOpen}
            onClose={props.onCloseConfirmDeleteDialog}
            PaperComponent={PaperComponent}
            aria-labelledby='confirm-delete-container-dialog'
        >
            <DialogTitle
                className={cx(commonStyles.classes.FormTitlePadding, classes.FormTitle)}
                style={{ cursor: 'move' }}
                variant={'h5'}
                id='confirm-delete-container-dialog'
            >
                <Typography fontWeight={'600'} variant={'inherit'}>
                    Are you sure you want to delete the {props.container && ContainerTypeFormatter(props.container.container_type).toLowerCase()}
                    {props.container?.name}?
                </Typography>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Typography variant={'subtitle1'}> You cannot undo this action.</Typography>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack spacing={1.5} direction={'row'} justifyContent={'flex-end'} width={'100%'}>
                    <Button
                        variant={'cancel'}
                        className={cx(classes.DialogButtons)}
                        disableElevation={true}
                        color={'secondary'}
                        autoFocus
                        onClick={props.onCloseConfirmDeleteDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={cx(classes.DialogButtons)}
                        color={'error'}
                        variant={'contained'}
                        onClick={() => {
                            props.confirmDeleteContainer(props.container);
                        }}
                        disableElevation={true}
                    >
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmDeleteContainerDialogProps>()((theme, props) => ({
    CancelButton: { color: theme.palette.grey[50] },
    FormTitle: {
        cursor: 'move',
        fontWeight: 600,
    },
    DialogButtons: {
        textTransform: 'none',
    },
}));

export { ConfirmDeleteContainerDialog };
