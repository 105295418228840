import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IChatbotPutTopicRequest, IChatbotTopic } from '@vegaplatformui/models';
import { Button, ListSubheader, Stack, Typography } from '@mui/material';
import { TopicItem } from './topic-item';
import { DeleteTopicDialog } from './delete-topic-dialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHistoryWindowProps {
    history: IChatbotTopic[];
    initStartTopic: () => void;
    loadTopic: (topicId: string) => void;
    reopenSelectedTopic: () => void;
    renameTopic: (hookRequest: IChatbotPutTopicRequest) => void;
    deleteTopic: (topicId: string) => void;
    renamingStates: Record<string, boolean>;
    setRenaming: (id: string, isBeingRenamed: boolean) => void;
    clearTopics: () => void;
    currentTopic?: IChatbotTopic;
}

const HistoryWindow: React.FC<IHistoryWindowProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const {
        history,
        initStartTopic,
        loadTopic,
        renameTopic,
        deleteTopic,
        setRenaming,
        renamingStates,
        clearTopics,
        currentTopic,
        reopenSelectedTopic,
    } = props;
    const [selectedTopic, setSelectedTopic] = React.useState<IChatbotTopic | undefined>(undefined);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
    const [topicToDelete, setTopicToDelete] = React.useState<IChatbotTopic | undefined>(undefined);

    const onCloseTopicDeletion = () => {
        setIsDeleteDialogOpen(false);
        setTimeout(() => {
            setTopicToDelete(undefined);
        }, 100);
    };

    const onStartClearTopics = () => {
        setIsDeleteDialogOpen(true);
    };

    return (
        <Stack direction={'column'} component={'ol'} spacing={1.5} className={cx(classes.ChatBoxStack)}>
            {/*This button allows for a new topic to be created when viewing the topic history, removing for now as requested*/}
            {/*<Stack component={'li'} direction='row' alignItems='center' spacing={1}>*/}
            {/*    <Button*/}
            {/*        aria-label={'start new topic'}*/}
            {/*        onClick={() => {*/}
            {/*            initStartTopic();*/}
            {/*        }}*/}
            {/*        sx={{ textAlign: 'left' }}*/}
            {/*        color={'primary'}*/}
            {/*        startIcon={<Add />}*/}
            {/*        className={cx(classes.TopicBox)}*/}
            {/*    >*/}
            {/*        <ListItemText primary={<Typography variant={'body2'}>New Topic</Typography>} />*/}
            {/*    </Button>*/}
            {/*</Stack>*/}
            <ListSubheader disableGutters disableSticky className={cx(classes.Subheader)}>
                <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
                    <Typography className={cx(classes.SubheaderTitle)} variant={'body1'}>{`Past topics`}</Typography>
                    <Button variant={'text'} onClick={() => onStartClearTopics()}>
                        <Typography variant={'body2'}>Clear All</Typography>
                    </Button>
                </Stack>
            </ListSubheader>
            {history.map((top, index) => [
                <TopicItem
                    key={top.conversation_id}
                    loadTopic={loadTopic}
                    renameTopic={renameTopic}
                    setRenaming={setRenaming}
                    setTopicToDelete={setTopicToDelete}
                    setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                    isRenaming={renamingStates[top.conversation_id]}
                    selectedTopic={selectedTopic}
                    setSelectedTopic={setSelectedTopic}
                    top={top}
                    isSelected={currentTopic?.conversation_id === top.conversation_id ?? false}
                    reopenSelectedTopic={reopenSelectedTopic}
                />,
            ])}
            <DeleteTopicDialog
                isOpen={isDeleteDialogOpen}
                onClose={() => onCloseTopicDeletion()}
                topicToDelete={topicToDelete}
                deleteTopic={deleteTopic}
                clearTopics={clearTopics}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<IHistoryWindowProps>()((theme, props) => ({
    ChatBoxStack: { paddingInlineStart: '0px' },
    Subheader: { backgroundColor: theme.palette.background.paper },
    SubheaderTitle: { color: theme.palette.text.primary },
    TopicBox: {
        padding: '10px',
        borderRadius: '4px',
        width: '100%',
        minHeight: '62px',
        whiteSpace: 'pre-wrap',
        // border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    },
}));

export { HistoryWindow };
