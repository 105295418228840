import dayjs from 'dayjs';
// @ts-ignore
import { minimalTimezoneSet } from 'compact-timezone-list';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface ITimeZone {
    label: string;
    offset: string;
    tzCode: string;
}

export const getSignedTimezoneOffset = (zoneTime: dayjs.Dayjs) => {
    const offset = zoneTime.utcOffset();
    const offsetSign = offset > 0 || offset === 0 ? '+' : offset < 0 ? '-' : '';
    const offsetNormalized = offset !== 0 ? Math.abs(offset / 60) : 0;
    const offsetDateTime = new Date(0, 0);
    offsetDateTime.setSeconds(offsetNormalized * 60 * 60);
    //Splice and get the hours and minutes of the date time
    return `${offsetSign}${offsetDateTime.toTimeString().slice(0, 5)}`;
};

export const timezoneSet: ITimeZone[] = minimalTimezoneSet
    .map((timezone: ITimeZone): ITimeZone => {
        const zoneTime = dayjs().local().tz(timezone.tzCode);
        const signedOffsetTime = getSignedTimezoneOffset(zoneTime);
        const offsetText = `(GMT${signedOffsetTime})`;

        return {
            label: `${timezone.label.replace(/\(.*\)/, offsetText)}`,
            offset: signedOffsetTime,
            tzCode: timezone.tzCode,
        };
    })
    .sort(
        (a: ITimeZone, b: ITimeZone) =>
            parseFloat(a.offset.replace(':', '.')) - parseFloat(b.offset.replace(':', '.')) || a.label.localeCompare(b.label)
    );
