import React, { useEffect, useRef } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowModel, GridRowOrderChangeParams } from '@mui/x-data-grid-premium';
import { CustomGridColStringOperatorDef } from '../../utilities/custom-grid-col-string-operator-def';
import { AssignmentRulesTableMenu } from './assignment-rules-table-menu';
import { Stack, Typography } from '@mui/material';
import { IAssignmentRule, IBusinessGrouping } from '@vegaplatformui/models';
import { Circle } from '@mui/icons-material';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../utilities/custom-data-grid-render-cell-button';
import { DataGridCustomToolbarProps } from '../../utilities/datagrid-custom-toolbar';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesTableProps {
    isLoading: boolean;
    assignmentRules: IAssignmentRule[];
    assignmentRulesTableIdentifier: string;
    onClickViewAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    onDeleteAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    onChangeAssignmentRule: (assignmentRule: IAssignmentRule, isEdit: boolean) => void;
    businessGroupings: IBusinessGrouping[];
}

const AssignmentRulesTable: React.FC<IAssignmentRulesTableProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [rows, setRows] = React.useState(props.assignmentRules);
    const [isLoading, setIsLoading] = React.useState(props.isLoading);
    const assignmentRulesTableUtilities = useTableUtilities(props.assignmentRulesTableIdentifier);
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);

    useEffect(() => {
        assignmentRulesTableUtilities.registerTableControl();
    }, []);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [[props.isLoading]]);

    useEffect(() => {
        const update = props.assignmentRules.length > 0 ? [...props.assignmentRules].sort((a, b) => a.priority! - b.priority!) : [];
        setRows(update);
    }, [props.assignmentRules]);

    const columns: GridColDef<IAssignmentRule>[] = [
        {
            sortable: false,
            field: 'name',
            headerName: 'Rule Name',
            flex: 1.5,
            renderCell: (params: GridRenderCellParams<IAssignmentRule>) => (
                <CustomDataGridRenderCellButton
                    ref={ref}
                    buttonProps={{
                        tabIndex: params.tabIndex,
                        className: cx(classes.nameButton),
                        variant: 'text',
                        onClick: () => props.onClickViewAssignmentRule(params.row),
                        children: params.row.name,
                    }}
                    params={params}
                />
            ),
        },
        {
            sortable: false,
            field: 'assigned_to',
            headerName: 'Assigned to',
            flex: 1.5,
            valueGetter: (value: string, row: IAssignmentRule) => props.businessGroupings.find((bg) => bg.id === row.business_unit_id)?.name,
        },
        {
            sortable: false,
            align: 'left',
            headerAlign: 'left',
            type: 'number',
            field: 'condition_groups',
            headerName: 'Conditions',
            flex: 0.75,
            valueGetter: (value: number, row: IAssignmentRule) => row.condition_groups.flatMap((g) => g.conditions).length,
        },
        {
            sortable: false,
            align: 'left',
            headerAlign: 'left',
            type: 'number',
            field: 'priority',
            headerName: 'Priority',
            flex: 0.5,
        },
        {
            sortable: false,
            field: 'is_active',
            headerName: 'Active',
            align: 'left',
            headerAlign: 'left',
            type: 'boolean',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<IAssignmentRule>) => {
                switch (params.row.is_active) {
                    case false:
                        return (
                            <Stack direction='row' justifyContent='center' alignItems='center' spacing={0.5}>
                                <Circle color={'error'} sx={{ width: 8, height: 8 }} />
                                <Typography variant={'body2'}>Inactive</Typography>
                            </Stack>
                        );
                    case true:
                        return (
                            <Stack direction='row' justifyContent='center' alignItems='center' spacing={0.5}>
                                <Circle color={'success'} sx={{ width: 8, height: 8 }} />
                                <Typography variant={'body2'}>Active</Typography>
                            </Stack>
                        );
                    default:
                        return '';
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            sortable: false,
            field: 'actions',
            type: 'actions',
            disableColumnMenu: true,
            headerName: 'Actions',
            flex: 0.5,
            filterable: false,
            renderCell: (params: GridRenderCellParams<IAssignmentRule>) => (
                <strong>
                    <AssignmentRulesTableMenu
                        params={params}
                        onDeleteAssignmentRule={props.onDeleteAssignmentRule}
                        onClickViewAssignmentRule={props.onClickViewAssignmentRule}
                        assignmentRules={rows}
                        currentAssignmentRule={params.row}
                        updateRowPosition={onChangeRowPosition}
                    />
                </strong>
            ),
        },
    ];

    const updateRowPosition = (initialIndex: number, newIndex: number, rows: GridRowModel<IAssignmentRule>[]): Promise<IAssignmentRule[]> => {
        return new Promise((resolve) => {
            const rowsClone = [...rows];
            const row = rowsClone.splice(initialIndex, 1)[0];

            const newPriority = rows[newIndex].priority;
            const oldPriority = row.priority;

            //fix priorities
            //update priority of the row that is being moved
            row.priority = newPriority;

            props.onChangeAssignmentRule(row, true);

            //update priority of the rest of the rows
            rowsClone.forEach((r) => {
                if (r.priority! > oldPriority! && r.priority! <= newPriority!) {
                    r.priority!--;
                } else if (r.priority! < oldPriority! && r.priority! >= newPriority!) {
                    r.priority!++;
                }
            });

            //make api call to update row priority and possibly reload data

            rowsClone.splice(newIndex, 0, row);
            resolve(rowsClone);
        });
    };

    const onChangeRowPosition = async (initialIndex: number, newIndex: number, rows: GridRowModel<IAssignmentRule>[]) => {
        setIsLoading(true);
        const newRows = await updateRowPosition(initialIndex, newIndex, rows);

        setRows(newRows);
        setIsLoading(false);
    };

    const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
        setIsLoading(true);
        const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, rows);

        setRows(newRows);
        setIsLoading(false);
    };

    return (
        <DataGridPremium
            autoHeight={true}
            columns={columns}
            loading={isLoading}
            rows={rows}
            rowReordering
            slotProps={{
                toolbar: {
                    tableIdentifier: props.assignmentRulesTableIdentifier,
                } as DataGridCustomToolbarProps,
                noRowsOverlay: {
                    action: 'creating an assignment rule',
                },
            }}
            onRowOrderChange={handleRowOrderChange}
            filterModel={assignmentRulesTableUtilities.currentTableControl?.filterModel}
            onFilterModelChange={assignmentRulesTableUtilities.onFilterModelChange}
            columnVisibilityModel={assignmentRulesTableUtilities.currentTableControl?.columnModel}
            onColumnVisibilityModelChange={assignmentRulesTableUtilities.onColumnVisibilityModelChange}
            density={assignmentRulesTableUtilities.currentTableControl?.density}
            onDensityChange={assignmentRulesTableUtilities.onDensityChange}
            disableRowSelectionOnClick
        />
    );
};

const useStyles = customMakeStyles<IAssignmentRulesTableProps>()((theme, props) => ({
    nameButton: {
        textTransform: 'none',
    },
}));

export { AssignmentRulesTable };
