import React, { useState, useCallback, useEffect } from 'react';
import { CommonPageHeader, ViewPortHeightAndWidth } from '../jotai/atom';
import { CustomCommonPageHeader, ICustomCommonPageHeaderProps } from '../custom-common-page-header/custom-common-page-header';
import { useAtom, useSetAtom } from 'jotai';

export function useCommonPageHeader(props: ICustomCommonPageHeaderProps) {
    const setCommonPageHeader = useSetAtom(CommonPageHeader);
    const [viewPortHeightAndWidth, setViewPortHeightAndWidth] = useAtom(ViewPortHeightAndWidth);

    useEffect(() => {
        setCommonPageHeader(<CustomCommonPageHeader {...props} />);

        //clean up common page header
        return () => {
            setCommonPageHeader(undefined);
            /*setViewPortHeightAndWidth({ height: 0, width: 0 });*/
        };
    }, [props]);
}

export default useCommonPageHeader;
