import React, { useEffect, useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    Icon,
    IconButton,
    Stack,
    Switch,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { Close, Info } from '@mui/icons-material';
import { IParkingPriorityGroup, IParkingSchedule, IResource, ITimezone, ParkingDelayUnit, ParkingScheduleType } from '@vegaplatformui/models';
import { useRouteUrls } from '@vegaplatformui/utils';
import { DetailsTab } from './details-tab';
import { ResourcesTab } from './resources-tab';
import { SchedulerTab } from './scheduler-tab';
import dayjs from 'dayjs';
import { ConfirmCloseDrawer } from './confirmation-dialogs/confirm-close-drawer';
import { PriorityGroupsTab } from './priority-groups-tab/priority-groups-tab';
import { AdvancedParkingResourcesTab } from './advanced-parking-resources-tab/advanced-parking-resources-tab';
import { ScheduleInactiveWarningDialog } from './confirmation-dialogs/schedule-inactive-warning-dialog';
import { areParkingScheduleTabsDirty, ParkingScheduleBusinessGroupId, SnackBarOptions, timezonesAtom } from '../../jotai/atom';
import { UnassignedResourcesWarningDialog } from './confirmation-dialogs/unassigned-resources-warning-dialog';
import { useLocation } from 'react-router';
import { IBusinessUnitTreeItem } from '@vegaplatformui/models';
import { useTheme } from '@mui/material/styles';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useOptimizedScheduleApi } from '../../api-hooks/use-optimized-schedule-api';
import { tableIdentifierKeys } from '../../use-table-utilities/table-identifier-keys';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { useAtom, useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDrawerSchedulerTabsProps {
    onCloseDrawer: () => void;
    scheduleToEdit?: IParkingSchedule;
    resources: IResource[];
    isLoading: boolean;
    onSave: (parkingSchedule: IParkingSchedule) => void;
    parkingScheduleType: ParkingScheduleType;
    hideResourcesTab?: boolean;
    loadParkableResources?: (bg_id?: string) => void;
    selectedResources?: IResource[];
    setResourcesSelectedForParking: (resources: IResource[]) => void;
    usersBusinessGroupings: IBusinessUnitTreeItem[];
}

const regularParkingTabValues = ['details', 'resources', 'scheduler'];
const regularParkingTabValuesWithoutResources = ['details', 'scheduler'];
const advancedParkingTabValues = ['details', 'priority_groups', 'resources', 'scheduler'];

const defaultPriorityGroup: IParkingPriorityGroup = {
    priority: 0,
    description: 'This is your default Vega priority group.',
    parking_delay: 0,
    parking_delay_unit: ParkingDelayUnit.Seconds,
    unparking_delay: 0,
    unparking_delay_unit: ParkingDelayUnit.Seconds,
    resources: [],
};

const DrawerSchedulerTabs: React.FC<IDrawerSchedulerTabsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const timezones = useAtomValue(timezonesAtom);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const [currentTab, setCurrentTab] = React.useState('details');
    const [priorityGroups, setPriorityGroups] = useState<IParkingPriorityGroup[]>(
        props.scheduleToEdit?.priority_groups && props.scheduleToEdit?.priority_groups.length > 0
            ? props.scheduleToEdit.priority_groups
            : [defaultPriorityGroup]
    );
    const [scheduleDescription, setScheduleDescription] = React.useState<string | undefined>(props.scheduleToEdit?.description ?? '');
    const [scheduleName, setScheduleName] = React.useState<string | undefined>(props.scheduleToEdit?.name ?? '');
    const [timezone, setTimezone] = React.useState<ITimezone | null>(
        timezones.find((t: ITimezone) => t.tzCode === props.scheduleToEdit?.timezone) ??
            timezones.find((t: ITimezone) => {
                return t.tzCode === dayjs.tz.guess();
            }) ??
            null
    );
    const [selectionStyle, setSelectionStyle] = React.useState('linear');
    const [intervals, setIntervals] = React.useState<number | undefined>(props.scheduleToEdit?.intervals ?? 1);
    const [intervalToChangeTo, setIntervalToChangeTo] = React.useState<number | undefined>(undefined);
    const [showConfirmChangeIntervalDialog, setShowConfirmChangeIntervalDialog] = React.useState<boolean>(false);
    const [schedule, setSchedule] = React.useState<Date[]>(props.scheduleToEdit?.scheduled_on_times ?? []);
    const [isScheduleActive, setIsScheduleActive] = React.useState<boolean>(props.scheduleToEdit?.is_enabled ?? false);
    const [hasCloseBeenClicked, setHasCloseBeenClicked] = React.useState<boolean>(false);
    const [showInactiveWarning, setShowInactiveWarning] = React.useState<boolean>(false);
    const [showNoTimesInvalidWarning, setShowNoTimesInvalidWarning] = React.useState<boolean>(false);
    const [showAllTimesInvalidWarning, setShowAllTimesInvalidWarning] = React.useState<boolean>(false);
    const [businessGroupingId, setBusinessGroupingId] = useAtom(ParkingScheduleBusinessGroupId);
    const [resourcesToAdd, setResourcesToAdd] = React.useState<IResource[]>([]);
    const [showUnnassignedResourcesDialog, setShowUnnassignedResourcesDialog] = React.useState<boolean>(false);
    const location = useLocation();
    const [areTabsDirty, setAreTabsAreDirty] = useAtom(areParkingScheduleTabsDirty);
    const { routeUrls } = useRouteUrls({});
    const { createOptimizedScheduleForPolicy } = useOptimizedScheduleApi({});
    const resourcesTableUtilities = useTableUtilities(tableIdentifierKeys.attachResourcesDialogTable);
    const [areDetailsValid, setAreDetailsValid] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        resourcesTableUtilities.registerTableControl({ is_parking_capable: false });
        return () => {
            resourcesTableUtilities.unregisterTableControl();
        };
    }, []);

    useEffect(() => {
        props.setResourcesSelectedForParking(resourcesToAdd);
    }, [resourcesToAdd]);

    useEffect(() => {
        const update = [...(props.scheduleToEdit?.priority_groups?.map((pg) => pg.resources).flat() ?? [])];
        props.scheduleToEdit === undefined && props.selectedResources && update.push(...props.selectedResources);
        setResourcesToAdd(update);
    }, []);

    //track if the schedule is invalid, meaning no times or all times selected
    useEffect(() => {
        if (schedule.length === 0) {
            setShowNoTimesInvalidWarning(true);
            setShowAllTimesInvalidWarning(false);
        } else if (schedule.length === 168 * (intervals || 1)) {
            setShowAllTimesInvalidWarning(true);
            setShowNoTimesInvalidWarning(false);
        } else {
            setShowAllTimesInvalidWarning(false);
            setShowNoTimesInvalidWarning(false);
        }
    }, [props.scheduleToEdit?.scheduled_on_times, props.scheduleToEdit?.intervals, schedule, intervals]);

    const onAttachResources = (resources: IResource[]) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        setResourcesToAdd([...new Set([...resourcesToAdd, ...resources])]);
    };

    const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setCurrentTab(newValue);
    };

    const onCloseDrawer = () => {
        localStorage.removeItem('previous_route');
        if (!location.pathname.includes(routeUrls.businessGroupings.path)) {
            setBusinessGroupingId('');
        } else {
            const currentBusinessGroupId = window.location.pathname.split('/')[2]; // string at this index is supposed to be ID of the viewed BG
            setBusinessGroupingId(currentBusinessGroupId);
        }
        setPriorityGroups([defaultPriorityGroup]);
        setAreTabsAreDirty(false);
        props.onCloseDrawer();
    };

    const onClickContinue = () => {
        const arrayToUse =
            props.parkingScheduleType === ParkingScheduleType.Basic && props.hideResourcesTab
                ? regularParkingTabValuesWithoutResources
                : props.parkingScheduleType === ParkingScheduleType.Basic
                  ? regularParkingTabValues
                  : advancedParkingTabValues;

        const found = arrayToUse.find((tab) => tab === currentTab);

        const resources = priorityGroups.flatMap((pg) => pg.resources);

        if (
            found === 'details' &&
            (scheduleName === '' || !scheduleName?.match(/\S{1}/i) || scheduleName === undefined || businessGroupingId === '')
        ) {
            setAreDetailsValid(false);
            return;
        } else if (scheduleName === '' || !scheduleName?.match(/\S{1}/i) || scheduleName === undefined || businessGroupingId === '') {
            setAreDetailsValid(false);
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `${scheduleName === '' || !scheduleName?.match(/\S{1}/i) || scheduleName === undefined ? 'Schedule Name is required' : ''}${(scheduleName === '' || !scheduleName?.match(/\S{1}/i) || scheduleName === undefined) && businessGroupingId === '' ? ', and Business Group is required.' : businessGroupingId === '' ? 'Business Group is required.' : ''}`,
            });
            setCurrentTab('details');
            return;
        } else {
            setAreDetailsValid(true);
        }

        if (props.scheduleToEdit && resources.length < resourcesToAdd.length) {
            setShowUnnassignedResourcesDialog(true);
        } else if (props.scheduleToEdit && !isScheduleActive) {
            setShowInactiveWarning(true);
        } else if (props.scheduleToEdit) {
            onClickSave();
        } else if (found && arrayToUse.indexOf(found) < arrayToUse.length - 1) {
            found && setCurrentTab(arrayToUse[arrayToUse.indexOf(found) + 1]);
        } else if (found === 'scheduler' && resources.length < resourcesToAdd.length) {
            setShowUnnassignedResourcesDialog(true);
        } else if (found === 'scheduler' && !isScheduleActive) {
            setShowInactiveWarning(true);
        } else if (found === 'scheduler') {
            onClickSave();
        }
    };

    const onClickBack = () => {
        const arrayToUse =
            props.parkingScheduleType === ParkingScheduleType.Basic && props.hideResourcesTab
                ? regularParkingTabValuesWithoutResources
                : props.parkingScheduleType === ParkingScheduleType.Basic
                  ? regularParkingTabValues
                  : advancedParkingTabValues;

        const found = arrayToUse.find((tab) => tab === currentTab);

        if (found && arrayToUse.indexOf(found) > 0) {
            found && setCurrentTab(arrayToUse[arrayToUse.indexOf(found) - 1]);
        }
    };

    const onClickSave = (isEnabled?: boolean) => {
        if (props.hideResourcesTab) {
            props.onSave({
                ...props.scheduleToEdit,
                name: scheduleName!,
                description: scheduleDescription,
                timezone: timezone!.tzCode!,
                intervals: intervals!,
                business_group_id: businessGroupingId,
                is_enabled: isEnabled ?? isScheduleActive,
                priority_groups: [{ ...defaultPriorityGroup, resources: props.resources }],
                scheduled_on_times: schedule,
                type_str: props.parkingScheduleType,
            });
        } else {
            props.onSave({
                ...props.scheduleToEdit,
                name: scheduleName!,
                description: scheduleDescription,
                timezone: timezone!.tzCode!,
                intervals: intervals!,
                business_group_id: businessGroupingId,
                is_enabled: isEnabled ?? isScheduleActive,
                priority_groups: priorityGroups,
                scheduled_on_times: schedule,
                type_str: props.parkingScheduleType,
            });
        }
        onCloseDrawer();
    };

    const onDeleteResources = (resources: IResource[]) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        const newPriorityGroups = priorityGroups?.map((pg) => ({
            ...pg,
            resources: pg.resources.filter((res) => !resources.find((res2) => res2.id === res.id)),
        }));

        setResourcesToAdd(resourcesToAdd.filter((res) => !resources.find((res2) => res2.id === res.id)));
        setPriorityGroups(newPriorityGroups);
    };

    const onClickGetOptimizedSchedules = () => {
        createOptimizedScheduleForPolicy({ resource_ids: resourcesToAdd.map((resource) => resource.id), setSchedule: setSchedule });
    };

    return (
        <>
            <ConfirmCloseDrawer
                show={hasCloseBeenClicked}
                onClose={() => setHasCloseBeenClicked(false)}
                onSave={onClickSave}
                onDontSave={onCloseDrawer}
                scheduleToEdit={props.scheduleToEdit}
                saveIsDisabled={scheduleName === '' || !scheduleName?.match(/\S{1}/i) || scheduleName === undefined || !areTabsDirty}
            />
            <UnassignedResourcesWarningDialog
                isOpen={showUnnassignedResourcesDialog}
                onClose={() => setShowUnnassignedResourcesDialog(false)}
                onSave={() => onClickSave()}
            />
            <ScheduleInactiveWarningDialog
                show={showInactiveWarning}
                onClose={() => setShowInactiveWarning(false)}
                onToggleYes={() => {
                    onClickSave(true);
                }}
                onToggleNo={() => {
                    onClickSave();
                }}
            />
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>
                <Grid2 container>
                    <Grid2 container alignItems={'center'} justifyContent={'flex-end'} size={12}>
                        <IconButton
                            className={cx(classes.CloseButton)}
                            aria-label={'close'}
                            onClick={() =>
                                scheduleName === '' || !scheduleName?.match(/\S{1}/i) || scheduleName === undefined || !areTabsDirty
                                    ? onCloseDrawer()
                                    : setHasCloseBeenClicked(true)
                            }
                        >
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid2>
                    <Grid2 size={12}>
                        {props.scheduleToEdit && (
                            <Tabs variant={'fullWidth'} value={currentTab} onChange={onTabChange}>
                                <Tab value='details' label='Details' />
                                {props.parkingScheduleType === ParkingScheduleType.Advanced && (
                                    <Tab value='priority_groups' label='Priority Groups' />
                                )}
                                <Tab value='resources' label='Attached Resources' />
                                <Tab value='scheduler' label='Scheduler' />
                            </Tabs>
                        )}
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Grid2 container direction={'row'}>
                    <Grid2 className={cx(classes.TabContentContainer)} size={12}>
                        {currentTab === 'details' && (
                            <DetailsTab
                                areDetailsValid={areDetailsValid}
                                loadParkableResources={props.loadParkableResources}
                                scheduleDescription={scheduleDescription}
                                setScheduleDescription={setScheduleDescription}
                                scheduleName={scheduleName}
                                setScheduleName={setScheduleName}
                                timezone={timezone}
                                setTimezone={setTimezone}
                                scheduleToEdit={props.scheduleToEdit}
                                businessGroupingId={businessGroupingId}
                                businessGroupings={props.usersBusinessGroupings}
                                businessGroupHasResources={props.resources.length > 0}
                                isLoading={props.isLoading}
                            />
                        )}
                        {currentTab === 'priority_groups' && (
                            <PriorityGroupsTab
                                priorityGroups={priorityGroups.sort((a, b) => a.priority - b.priority)}
                                onChangePriorityGroups={setPriorityGroups}
                            />
                        )}
                        {currentTab === 'resources' &&
                            !props.hideResourcesTab &&
                            (props.parkingScheduleType === ParkingScheduleType.Basic ? (
                                <ResourcesTab
                                    priorityGroups={priorityGroups}
                                    setPriorityGroups={setPriorityGroups}
                                    resources={props.resources}
                                    isLoading={props.isLoading}
                                    scheduleToEdit={props.scheduleToEdit}
                                    isServerPaginated={true}
                                    resourcesToAdd={resourcesToAdd}
                                    setResourcesToAdd={setResourcesToAdd}
                                    onAttachResources={onAttachResources}
                                    onDeleteResources={onDeleteResources}
                                />
                            ) : (
                                <AdvancedParkingResourcesTab
                                    onDeleteResources={onDeleteResources}
                                    onAttachResources={onAttachResources}
                                    resourcesToAdd={resourcesToAdd}
                                    setResourcesToAdd={setResourcesToAdd}
                                    resources={props.resources}
                                    isLoading={props.isLoading}
                                    priorityGroups={priorityGroups}
                                    setPriorityGroups={setPriorityGroups}
                                    scheduleToEdit={props.scheduleToEdit}
                                />
                            ))}
                        {currentTab === 'scheduler' && (
                            <SchedulerTab
                                selectionStyle={selectionStyle}
                                setSelectionStyle={setSelectionStyle}
                                intervals={intervals}
                                setIntervals={setIntervals}
                                intervalToChangeTo={intervalToChangeTo}
                                setIntervalToChangeTo={setIntervalToChangeTo}
                                showConfirmChangeIntervalDialog={showConfirmChangeIntervalDialog}
                                setShowConfirmChangeIntervalDialog={setShowConfirmChangeIntervalDialog}
                                schedule={schedule}
                                setSchedule={setSchedule}
                                onClickGetOptimizedSchedules={onClickGetOptimizedSchedules}
                                showAllTimesInvalidWarning={showAllTimesInvalidWarning}
                                showNoTimesInvalidWarning={showNoTimesInvalidWarning}
                            />
                        )}
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding, classes.ButtonContainer)}>
                <Grid2 width={'100%'} container>
                    <Grid2 size={6}>
                        <Stack direction='row' spacing={1} alignItems='center'>
                            <Stack direction='row' alignItems={'flex-start'}>
                                <Typography>Schedule Status: </Typography>
                                <Tooltip
                                    tabIndex={0}
                                    title={
                                        'When active, the schedule will be executed during the selected times in the schedule.' +
                                        'When inactive, the schedule will not be executed even at the selected times.'
                                    }
                                    placement={'right'}
                                    arrow
                                >
                                    <Info aria-hidden={false} className={commonStyles.classes.TooltipIcons} />
                                </Tooltip>
                            </Stack>
                            <Stack direction='row' spacing={0} alignItems='center'>
                                <Typography>{isScheduleActive ? ' Active' : ' Inactive'}</Typography>
                                <Switch
                                    className={commonStyles.classes.RedGreenSwitch}
                                    onChange={() => {
                                        !areTabsDirty && setAreTabsAreDirty(true);
                                        setIsScheduleActive(!isScheduleActive);
                                    }}
                                    checked={isScheduleActive}
                                    slotProps={{ input: { 'aria-label': 'ant design' } }}
                                />
                            </Stack>
                        </Stack>
                    </Grid2>
                    <Grid2 justifyContent={'flex-end'} alignItems={'center'} container size={6}>
                        <Stack direction={'row'} width={'100%'} justifyContent={'flex-end'} spacing={1.5}>
                            {currentTab !== 'details' && (
                                <Button
                                    variant={'cancel'}
                                    disabled={currentTab === 'details'}
                                    className={cx(classes.CancelButton)}
                                    onClick={onClickBack}
                                >
                                    Back
                                </Button>
                            )}
                            <Button
                                disabled={props.isLoading || (props.scheduleToEdit && !areTabsDirty)}
                                variant={'contained'}
                                onClick={onClickContinue}
                                className={cx(classes.ContinueButton)}
                                loading={props.isLoading}
                                startIcon={props.isLoading ? <Icon></Icon> : undefined}
                                loadingPosition={props.isLoading ? 'start' : undefined}
                            >
                                {props.scheduleToEdit ? 'Save' : currentTab === 'scheduler' ? 'Create' : 'Next'}
                            </Button>
                        </Stack>
                    </Grid2>
                </Grid2>
            </DialogActions>
        </>
    );
};

const useStyles = customMakeStyles<IDrawerSchedulerTabsProps>()((theme, props) => ({
    Container: { overflow: 'auto' },
    CloseButton: { padding: '6px' },
    CancelButton: { marginRight: '1rem' },
    ButtonContainer: {},
    TabContentContainer: { maxHeight: '100%' },
    ContinueButton: { float: 'right' },
}));

export { DrawerSchedulerTabs };
