import { GeminiResponse, removeKeysFromObject } from '@vegaplatformui/utils';
import {
    IUserSettingProfilePutRequest,
    IUserSettingAddressPutRequest,
    IUserSettingRolePostRequest,
    IUserSettingRoleBaseRequest,
    IUserSettingUserRolesGetRequest,
    IUserSettingUserPostRequest,
    IUserSettingBaseRequest,
    IUserSettingSSOBaseRequest,
    IUserSettingSSOSamlForm,
    IUserSettingSSOSamlRequest,
    IUserSettingRealmRole,
} from '@vegaplatformui/models';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';

export class UserSettingApi extends HttpClient {
    protected static classInstance?: UserSettingApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new UserSettingApi();
        }

        return this.classInstance;
    }

    // User Profile Section
    public getUser = (data: IUserSettingBaseRequest, signal: AbortSignal): GeminiResponse<any> =>
        this.instance.get(`/keycloak/keycloak/${data.username}/user`, { signal });

    public getPhoto = (data: IUserSettingBaseRequest): GeminiResponse<any> => this.instance.get(`/keycloak/keycloak/${data.username}/photo`);

    public updateProfile = (data: IUserSettingProfilePutRequest): GeminiResponse<any> =>
        this.instance.put<IUserSettingProfilePutRequest>(
            `/keycloak/keycloak/${data.username}/profile`,
            data.business_grouping !== undefined ? data.business_grouping : null,
            { params: { ...removeKeysFromObject(data, ['business_grouping']) } }
        );

    public uploadImg = (data: IUserSettingBaseRequest, formData: FormData): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak/photo`, formData, { params: { ...data } });

    public deleteImg = (username: string): GeminiResponse<any> =>
        this.instance.delete(`/keycloak/keycloak/photo`, { params: { username: username } });

    public updateAddress = (data: IUserSettingAddressPutRequest): GeminiResponse<any> =>
        this.instance.put<IUserSettingAddressPutRequest>(`/keycloak/keycloak/${data.username}/address`, null, { params: { ...data } });

    public updatePassword = (data: IUserSettingBaseRequest, formData: FormData): GeminiResponse<any> =>
        this.instance.put<IUserSettingBaseRequest>(`/keycloak/keycloak/${data.username}/password`, formData);

    // Users Table Section
    public getUsers = (data: string): GeminiResponse<any> => this.instance.get<string>(`/keycloak/keycloak_admin/users?size=50`);

    public createUser = (data: IUserSettingUserPostRequest): GeminiResponse<any> =>
        this.instance.post<IUserSettingUserPostRequest>(`/keycloak/keycloak_admin/user`, data.business_groupings, {
            params: { ...removeKeysFromObject(data, ['business_groupings']) },
        });

    public deleteUser = (data: IUserSettingBaseRequest): GeminiResponse<any> =>
        this.instance.delete<IUserSettingBaseRequest>(`/keycloak/keycloak_admin/${data.username}`);

    // Permissions/Role Section
    public getRealmClients = (data: string) => this.instance.get<string>(`/keycloak/keycloak_admin/clients`);

    public getRealmRoleByUsername = (data: IUserSettingBaseRequest): GeminiResponse<any> =>
        this.instance.get<IUserSettingBaseRequest>(`/keycloak/keycloak_admin/${data.username}/roles`);

    public getRealmRoles = (data: string, signal?: AbortSignal): GeminiResponse<IUserSettingRealmRole[]> =>
        this.instance.get<IUserSettingRealmRole[]>(`/keycloak/keycloak_admin/realmRoles`, { signal: signal ?? undefined });

    public getUserClientRoles = (data: IUserSettingUserRolesGetRequest): GeminiResponse<any> =>
        this.instance.get<IUserSettingUserRolesGetRequest>(`/keycloak/keycloak_admin/${data.username}/roles?client=${data.client}`);

    public createRole = (data: IUserSettingRolePostRequest, username: string): GeminiResponse<any> =>
        this.instance.post<IUserSettingRolePostRequest>(`/keycloak/keycloak_admin/roles?role_name=${data.role_name}`);

    public assignRoleToUsers = (role_name: string, usernames: string[]): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/users/${role_name}`, usernames);

    public updateUserRole = (data: IUserSettingRoleBaseRequest): GeminiResponse<any> =>
        this.instance.put<IUserSettingRoleBaseRequest>(`/keycloak/keycloak_admin/${data.username}/roles`, null, {
            params: { ...removeKeysFromObject(data, ['username']) },
        });

    public updateRoleName = (role_name: string, new_role_name: string): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/roles/${role_name}?new_role_name=${new_role_name}`);

    public removeRolesFromUser = (data: IUserSettingRoleBaseRequest): GeminiResponse<any> =>
        this.instance.delete<IUserSettingRoleBaseRequest>(`/keycloak/keycloak_admin/${data.username}/roles?role_name=${data.role_name}`);

    public removeRoleFromUsers = (role_name: string, usernames: string[]): GeminiResponse<any> =>
        this.instance.delete(`/keycloak/keycloak_admin/users/${role_name}`, {
            data: usernames,
        });

    // SSO
    public getIDPList = () => this.instance.get(`/keycloak/keycloak_admin/idp`);

    public createOpenIdIDP = (data: IUserSettingSSOBaseRequest): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak_admin/idp/openID`, data);

    public createSamlIDP = (data: IUserSettingSSOSamlRequest): GeminiResponse<any> => this.instance.post(`/keycloak/keycloak_admin/idp/saml`, data);

    public updateOpenIdIDP = (alias: string, data: IUserSettingSSOBaseRequest): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/idp/openID/${alias}`, data);

    public updateSamlIDP = (alias: string, data: IUserSettingSSOSamlRequest): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/idp/saml/${alias}`, data);

    public deleteIDP = (alias: string): GeminiResponse<any> => this.instance.delete(`/keycloak/keycloak_admin/idp/${alias}`);

    public generateSSOXML = (url: string): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak_admin/generate-xml`, null, { params: { url: url } });

    public importConfigIDP = (provider_id: string, config_file_path: string): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak_admin/idp/import-config`, null, {
            params: { provider_id: provider_id, config_file_path: config_file_path },
        });

    // MFA
    public getMFAStatus = (): GeminiResponse<any> => this.instance.get('/keycloak/keycloak_admin/browser_flow/conditional_otp');

    public updateMFAStatus = (type: string): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/browser_flow/conditional_otp?parameter_type=${type}`);
}
