import { GeminiResponse, removeKeysFromObject } from '@vegaplatformui/utils';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import {
    IGetNotificationChannelsResponse,
    IGetNotificationTypesResponse,
    IGenericUpsertUserNotifications,
    IGenericUpsertUserNotificationResponse,
    INotificationConfigurationResponse,
    IPostOrganizationIntegrationRequest,
    IGetOrganizationConfigurationsResponse,
    IPutOrganizationIntegrationRequest,
    IPostIntegrationResponse,
    IPutIntegrationResponse,
} from '@vegaplatformui/models';

export class NotificationsAPI extends HttpClient {
    protected static classInstance?: NotificationsAPI;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new NotificationsAPI();
        }

        return this.classInstance;
    }

    public getOrganizationNotificationConfigurations = (signal: AbortSignal): GeminiResponse<IGetOrganizationConfigurationsResponse> =>
        this.instance.get(`/notify/v1/organization/configurations`, { signal });

    public postOrganizationNotificationConfiguration = (request: IPostOrganizationIntegrationRequest): GeminiResponse<IPostIntegrationResponse> =>
        this.instance.post(
            `/notify/v1/organization/webhooks/?notification_type=${request.notification_type}&notification_channel=${request.notification_channel}`,
            { ...removeKeysFromObject(request, ['notification_type', 'notification_channel']) }
        );

    public putOrganizationNotificationConfiguration = (request: IPutOrganizationIntegrationRequest): GeminiResponse<IPutIntegrationResponse> =>
        this.instance.put(`/notify/v1/organization/webhooks/${request.id}`, {
            ...removeKeysFromObject(request, ['id', 'notification_channel']),
        });

    public deleteOrganizationNotificationConfigurtaion = (id: string): GeminiResponse<any> =>
        this.instance.delete(`/notify/v1/organization/webhooks/${id}`);

    public getNotificationConfigurations = (user_id: string, signal: AbortSignal): GeminiResponse<INotificationConfigurationResponse> =>
        this.instance.get(`/notify/v1/users/${user_id}/subscriptions`, { signal });
    public getNotificationChannels = (signal: AbortSignal): GeminiResponse<IGetNotificationChannelsResponse> =>
        this.instance.get(`/notify/v1/channels`, { signal });
    public getNotificationTypes = (signal: AbortSignal): GeminiResponse<IGetNotificationTypesResponse> =>
        this.instance.get('/notify/v1/types', { signal });

    public putNotificationConfiguration = (request: IGenericUpsertUserNotifications): GeminiResponse<IGenericUpsertUserNotificationResponse> =>
        this.instance.put(`/notify/v1/users/${request.user_id}/subscriptions/settings`, { ...removeKeysFromObject(request, ['user_id']) });

    public postNotificationConfiguration = (request: IGenericUpsertUserNotifications): GeminiResponse<IGenericUpsertUserNotificationResponse> =>
        this.instance.post(`/notify/v1/users/${request.user_id}/subscriptions`, { ...removeKeysFromObject(request, ['user_id']) });
}
