import { HttpClient } from './http-common';
import {
    ISpacesRequest,
    IResourcesGetRequest,
    ICreateSpaceRequest,
    ICreateWorkloadRequest,
    IUpdateWorkloadRequest,
    ICreateResourcePoolRequest,
    IUpdateResourcePoolRequest,
    IVegaContainer,
    IDataGridRequest,
    IGetResourcesResponse,
    IResourceType,
    IResource,
    IResourceSummaryActiveResourcesResponse,
    IResourceSummaryOnScheduleResponse,
    IResourceSummaryTotalResourcesResponse,
} from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';
import { GenericAbortSignal } from 'axios';

export class ContainerApi extends HttpClient {
    protected static classInstance?: ContainerApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ContainerApi();
        }

        return this.classInstance;
    }

    // Spaces
    public loadSpaces = (request: ISpacesRequest): GeminiResponse<IVegaContainer[]> => this.instance.get(`/vegaapi/spaces/summary`);
    public createSpace = (request: ICreateSpaceRequest): GeminiResponse<any> => this.instance.post(`/vegaapi/spaces`, request);
    public deleteSpace = (spaceId: string): GeminiResponse<any> => this.instance.delete(`/vegaapi/spaces/${spaceId}`);
    public updateSpace = (request: ICreateSpaceRequest, spaceId: string): GeminiResponse<any> =>
        this.instance.put(`/vegaapi/spaces/${spaceId}`, request);

    // Resources
    public getResourceTypes = (): GeminiResponse<IResourceType[]> => this.instance.get(`/vegaapi/resource-types`);

    public loadResources = (request: IDataGridRequest, signal?: GenericAbortSignal): GeminiResponse<IGetResourcesResponse> =>
        this.instance.get(`/vegaapi/resources`, {
            params: {
                filters: JSON.stringify(request.filterModel),
                sorted_by: JSON.stringify(request.sortModel),
                grid_pagination_model: JSON.stringify(request.paginationModel),
            },
            signal,
        });

    public getResourceById = (resourceId: string, signal?: GenericAbortSignal, get_schedule_ownership_metadata = false): GeminiResponse<IResource> =>
        this.instance.get(`/vegaapi/resources/${resourceId}`, {
            params: { get_schedule_ownership_metadata: get_schedule_ownership_metadata },
            signal,
        });
    public getResourcesSummary = (): GeminiResponse<any> => this.instance.get(`/vegaapi/resources/summary/`);
    public getResourcesSummaryActiveResourcesCount = (): GeminiResponse<IResourceSummaryActiveResourcesResponse> =>
        this.instance.get('vegaapi/resources/summary/active_resources');
    public getResourcesSummaryOnScheduleCount = (): GeminiResponse<IResourceSummaryOnScheduleResponse> =>
        this.instance.get('vegaapi/resources/summary/on_schedule');
    public getResourcesSummaryTotalCount = (): GeminiResponse<IResourceSummaryTotalResourcesResponse> =>
        this.instance.get('vegaapi/resources/summary/total_count');

    // Workloads
    public loadWorkloads = (): GeminiResponse<any> => this.instance.get(`/vegaapi/workloads`);
    public createWorkload = (request: ICreateWorkloadRequest): GeminiResponse<any> => this.instance.post(`/vegaapi/workloads`, request);
    public deleteWorkload = (workloadId: string): GeminiResponse<any> => this.instance.delete(`/vegaapi/workloads/${workloadId}`);
    public updateWorkload = (request: IUpdateWorkloadRequest, workloadId: string): GeminiResponse<any> =>
        this.instance.put(`/vegaapi/workloads/${workloadId}`, request);

    // Resource Pool
    public createResourcePool = (request: ICreateResourcePoolRequest): GeminiResponse<any> => this.instance.post(`/vegaapi/resource-pools`, request);
    public deleteResourcePool = (resourcePoolId: string): GeminiResponse<any> => this.instance.delete(`/vegaapi/resource-pools/${resourcePoolId}`);
    public updateResourcePool = (request: IUpdateResourcePoolRequest, resourcePoolId: string): GeminiResponse<any> =>
        this.instance.put(`/vegaapi/resource-pools/${resourcePoolId}`, request);
    public getResourcesByResourcePoolId = (request: string): GeminiResponse<any> => this.instance.get(`/vegaapi/resource-pools/resources/${request}`);
    public getFamilyByResourcePoolId = (resourcePoolId: string): GeminiResponse<any> =>
        this.instance.get(`/vegaapi/resource-pools/family/${resourcePoolId}`);
}
