import React from 'react';
import { Box, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, TooltipProps } from 'recharts';
import { customMakeStyles } from '@vegaplatformui/styling';
import { LegendPayload, CostTrendData, ChartDataPoint } from '@vegaplatformui/models';

export interface ICostTrendChartProps {
    data: CostTrendData[];
    budgetAmount: number;
}

interface ICustomTooltipProps extends TooltipProps<number, string> {
    active?: boolean;
    payload?: Array<{
        value: number;
        name: string;
        color: string;
    }>;
    label?: string;
    classes: Record<string, string>;
    cx: (...args: any[]) => string;
}

interface ICustomLegendProps {
    payload?: LegendPayload[];
    classes: Record<string, string>;
    cx: (...args: any[]) => string;
}

const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};

const formatCurrency = (value: number) => {
    return `$${value.toLocaleString()}`;
};

const CustomTooltip: React.FC<ICustomTooltipProps> = ({ active, payload, label, classes, cx }) => {
    if (!active || !payload) return null;

    return (
        <Box className={cx(classes.Tooltip)}>
            <Box className={cx(classes.TooltipLabel)}>{label}</Box>
            {payload.map((entry, index) => (
                <Box key={index} className={cx(classes.TooltipItem)} sx={{ color: entry.color }}>
                    <Box component='span' className={cx(classes.TooltipItemName)}>
                        {entry.name}:
                    </Box>
                    {formatCurrency(entry.value)}
                </Box>
            ))}
        </Box>
    );
};

const CustomLegend: React.FC<ICustomLegendProps> = ({ payload = [], classes, cx }) => {
    return (
        <Box className={cx(classes.Legend)}>
            {payload.map((entry, index) => (
                <Box key={index} className={cx(classes.LegendItem)}>
                    <Box className={cx(classes.LegendColor)} sx={{ backgroundColor: entry.color }} />
                    {entry.value}
                </Box>
            ))}
        </Box>
    );
};

const CostTrendChart: React.FC<ICostTrendChartProps> = (props) => {
    const { classes, cx } = costTrendChartUseStyles(props);
    const { data, budgetAmount } = props;
    const theme = useTheme();

    const chartData: ChartDataPoint[] = data.map((item) => ({
        ...item,
        date: formatDate(item.date),
        budget: budgetAmount,
    }));

    return (
        <Box className={cx(classes.ChartContainer)}>
            <ResponsiveContainer width='100%' height='100%'>
                <LineChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
                    <CartesianGrid strokeDasharray='3 3' stroke={theme.palette.divider} />
                    <XAxis
                        dataKey='date'
                        tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                        tickLine={{ stroke: theme.palette.divider }}
                        axisLine={{ stroke: theme.palette.divider }}
                    />
                    <YAxis
                        tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                        tickLine={{ stroke: theme.palette.divider }}
                        axisLine={{ stroke: theme.palette.divider }}
                        tickFormatter={formatCurrency}
                    />
                    <Tooltip content={<CustomTooltip classes={classes} cx={cx} />} />
                    <Legend content={<CustomLegend classes={classes} cx={cx} />} />
                    <Line
                        type='monotone'
                        dataKey='actualCost'
                        stroke={theme.palette.primary.main}
                        strokeWidth={2.5}
                        dot={{
                            fill: theme.palette.primary.main,
                            strokeWidth: 2,
                            r: 4,
                        }}
                        activeDot={{ r: 6, strokeWidth: 2 }}
                        name='Actual Cost'
                    />
                    <Line
                        type='monotone'
                        dataKey='forecastCost'
                        stroke={theme.palette.primary.main}
                        strokeWidth={2.5}
                        strokeDasharray='5 5'
                        dot={false}
                        name='Forecast'
                    />
                    <Line
                        type='monotone'
                        dataKey='budget'
                        stroke={theme.palette.error.main}
                        strokeWidth={2}
                        strokeDasharray='3 3'
                        dot={false}
                        name='Budget Threshold'
                    />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

const costTrendChartUseStyles = customMakeStyles<ICostTrendChartProps>()((theme) => ({
    ChartContainer: {
        width: '100%',
        height: 400,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    Tooltip: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
    },
    TooltipLabel: {
        margin: `0 0 ${theme.spacing(1)}`,
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    TooltipItem: {
        margin: `${theme.spacing(0.5)} 0`,
        fontSize: '0.95rem',
    },
    TooltipItemName: {
        fontWeight: 500,
        marginRight: theme.spacing(0.5),
    },
    Legend: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    LegendItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '0.95rem',
    },
    LegendColor: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        display: 'inline-block',
    },
}));

const useStyles = customMakeStyles<ICostTrendChartProps>()((theme) => ({
    ChartContainer: {
        width: '100%',
        height: 400,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    Tooltip: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
    },
    TooltipLabel: {
        margin: `0 0 ${theme.spacing(1)}`,
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    TooltipItem: {
        margin: `${theme.spacing(0.5)} 0`,
        fontSize: '0.95rem',
    },
    TooltipItemName: {
        fontWeight: 500,
        marginRight: theme.spacing(0.5),
    },
    Legend: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    LegendItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '0.95rem',
    },
    LegendColor: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        display: 'inline-block',
    },
}));

export { CostTrendChart };
