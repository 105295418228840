import { VegaHigherAdminRoles, VegaLowerAdminRoles, VegaRoleHelperTypes, VegaRoles } from '@vegaplatformui/models';

export function getVegaRoleTypeValues(roleLevel: VegaRoleHelperTypes): string[] {
    const values: string[] = [];
    //Get string list of the VegaHigherAdminRoles
    const higherAdmins = Object.entries(VegaHigherAdminRoles)
        .map(([key, value]) => {
            return value;
        })
        .map((value) => value.toString());

    //Get string list of the VegaLowerAdminRoles
    const lowerAdmins = Object.entries(VegaLowerAdminRoles)
        .map(([key, value]) => {
            return value;
        })
        .map((value) => value.toString());

    //Get string list of the VegaRoles
    const normalRoles = Object.entries(VegaRoles)
        .map(([key, value]) => {
            return value;
        })
        .map((value) => value.toString());

    switch (roleLevel) {
        case VegaRoleHelperTypes.All:
            //Combine all 3 enum objects
            values.push(...higherAdmins, ...lowerAdmins, ...normalRoles);
            break;
        case VegaRoleHelperTypes.LowerLevel:
            //Combine all 2 admin level enum objects
            values.push(...lowerAdmins, ...higherAdmins);
            break;
        case VegaRoleHelperTypes.HigherLevel:
            //Use highest level admin enum object
            values.push(...higherAdmins);
            break;
    }

    return values;
}
