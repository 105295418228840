import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUnassignedResourcesWarningDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
}

const UnassignedResourcesWarningDialog: React.FC<IUnassignedResourcesWarningDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.isOpen} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <Grid
                    container
                    className={cx(classes.GridContainer)}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    spacing={2}
                >
                    <Grid xs={11} item>
                        <Typography fontWeight={500}>
                            You are about to save a schedule with resources not assigned to priority groups. These resources will be lost if you
                            continue.
                        </Typography>
                    </Grid>
                    <Grid xs={1} item className={cx(classes.CancelButton)}>
                        <IconButton aria-label={'Close'} size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "Yes" to save schedule discarding resources not assigned to priority groups.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "No" to go back and assign priority groups for these resources.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                    No
                </Button>
                <Button onClick={props.onSave}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IUnassignedResourcesWarningDialogProps>()((theme, props) => ({
    GridContainer: { marginTop: '-1.5rem' },
    CancelButton: { marginRight: '-1rem' },
}));

export { UnassignedResourcesWarningDialog };
