import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormControl } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ITimeZone } from '@vegaplatformui/utils';

dayjs.extend(utc);
dayjs.extend(timezone);
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMonthlyReoccurrenceFieldProps {
    dateTime: Date | undefined;
    setDateTime: React.Dispatch<React.SetStateAction<Date | undefined>>;
    timezone: ITimeZone | null;
}

const MonthlyReoccurrenceField: React.FC<IMonthlyReoccurrenceFieldProps> = (props) => {
    const { classes, cx } = useStyles(props);
    props.dateTime && console.log(props.dateTime);
    return (
        <FormControl fullWidth aria-label={'Monthly reoccurrence date and time selection'}>
            <DateTimePicker
                label='Select monthly day and time'
                timezone={props.timezone && props.timezone.tzCode === 'Europe/London' ? 'UTC' : props.timezone?.tzCode ?? undefined}
                views={['day', 'hours', 'minutes']}
                value={props.dateTime !== undefined ? dayjs(props.dateTime) : null}
                onChange={(event: any) => (event ? props.setDateTime(event['$d']) : props.setDateTime(undefined))}
                slotProps={{ textField: { fullWidth: true, size: 'small' } }}
            />
        </FormControl>
    );
};

const useStyles = customMakeStyles<IMonthlyReoccurrenceFieldProps>()((theme, props) => ({}));

export { MonthlyReoccurrenceField };
