import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SnackBarOptions } from '../recoil/atom';
import { Dialog, DialogTitle, DialogContent, TextField, InputAdornment, IconButton, DialogActions, Button, Stack, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFetchFileBlobAndDownload } from '../utilities/use-fetch-file-blob-and-download';

export interface IApiKeyManagementDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    apiKey: string;
}

const ApiKeyManagementDialog: React.FC<IApiKeyManagementDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const fetchFileBlobAndDownload = useFetchFileBlobAndDownload();
    const { isDialogOpen, onClose, apiKey } = props;

    const [showAPIKey, setShowAPIKey] = useState(false);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const toggleAPIKeyVisibility = () => {
        setShowAPIKey(!showAPIKey);
    };

    const handleCopyAPIKey = () => {
        navigator.clipboard.writeText(apiKey).then(
            () => {
                navigator.clipboard.readText().then(
                    () =>
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 3000 },
                            alertProps: { severity: 'success' },
                            message: `API key copied to clipboard`,
                        }),
                    () =>
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 3000 },
                            alertProps: { severity: 'error' },
                            message: `Failed to copy API key to clipboard`,
                        })
                );
            },
            () => alert('Failed to copy API key to clipboard')
        );
    };

    const handleOnClose = () => {
        setShowAPIKey(false);
        onClose();
    };

    const handleJSONDownload = () => {
        fetchFileBlobAndDownload.downloadJsonAsFile({ api_key: apiKey }, `VegaApiKey_${Date.parse(new Date().toString())}`);
    };

    return (
        <Dialog open={props.isDialogOpen} aria-labelledby='api-key-management-dialog-title' aria-describedby='api-key-management-dialog-content'>
            <DialogTitle id='api-key-management-dialog-title'>
                <Stack>
                    API key successfully generated
                    <Typography role={'presentation'} variant={'caption'}>
                        This is your unique key. Generating a new API Key will invalidate any existing key for this user.
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent id='api-key-management-dialog-content' className={cx(classes.DialogContent)}>
                <Stack spacing={1}>
                    <TextField
                        size='medium'
                        type={showAPIKey ? 'text' : 'password'}
                        label='API Key'
                        value={apiKey}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton aria-label='toggle APIKey visibility' onClick={toggleAPIKeyVisibility}>
                                        {showAPIKey ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <Stack justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                        <Button className={cx(classes.DownloadButton)} variant='text' onClick={handleJSONDownload}>
                            Download as JSON
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Button onClick={handleOnClose} variant='cancel'>
                    Close
                </Button>
                <Button onClick={handleCopyAPIKey}>Copy</Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IApiKeyManagementDialogProps>()((theme, props) => ({
    DialogContent: {
        paddingTop: '10px !important',
    },
    DialogActions: {
        '& button': {
            width: '50%',
        },
    },
    DownloadButton: {
        color: theme.palette.mode === 'light' ? theme.palette.info.dark : theme.palette.info.main,
    },
}));

export { ApiKeyManagementDialog };
