import React, { useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { ParkingSchedulesTable } from './parking-schedule-table/parking-schedules-table';
import { IParkingScheduleSummary, IResource, ParkingScheduleType } from '@vegaplatformui/models';
import { IRecurringScheduleForm } from '../drawer-scheduler/recurring-schedule';
import { BulkParkingSchedulesActions } from './bulk-parking-schedules-actions';
import { IBulkParkingSchedulesActionsProps } from './parking-types';
import { GlobalCardHeight } from '../constants/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IParkingCardProps extends IBulkParkingSchedulesActionsProps {
    onClickCopySchedule: () => void;
    onClickSetCreateSnackbarTemp: (context: string) => void;
    setResourcesSelectedForParking: (resources: IResource[]) => void;
    parkingScheduleTableIdentifier: string;
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
}

export interface IParkingScheduleForm extends Partial<IRecurringScheduleForm> {
    selectedResources: IResource[];
}

const ParkingSchedulesCard: React.FC<IParkingCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [parkingScheduleType, setParkingScheduleType] = React.useState<ParkingScheduleType>(ParkingScheduleType.Basic);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const onClickAction = (schedule: IParkingScheduleSummary, scheduleType?: ParkingScheduleType) => {
        props.setScheduleToEdit(props.parkingSchedules.find((scheduleToFind) => scheduleToFind.id === schedule.id));
        scheduleType ? setParkingScheduleType(scheduleType) : setParkingScheduleType(schedule.type_str);
    };

    return (
        <Card className={cx(classes.Card)} elevation={0}>
            <CardContent>
                <Grid container direction={'column'}>
                    <Grid item xs={12} container direction={'row'} justifyContent={'space-between'}>
                        <Grid xs={4} item>
                            <Typography variant={'h1'}>Enterprise Scheduler</Typography>
                        </Grid>
                        <BulkParkingSchedulesActions
                            isDeleteExemptionDialogOpen={props.isDeleteExemptionDialogOpen}
                            setIsDeleteExemptionDialogOpen={props.setIsDeleteExemptionDialogOpen}
                            scheduleWithExemption={props.scheduleWithExemption}
                            setScheduleWithExemption={props.setScheduleWithExemption}
                            onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                            onClickDeleteExemption={props.onClickDeleteExemption}
                            setResourcesSelectedForParking={props.setResourcesSelectedForParking}
                            isParkableResourcesLoading={props.isParkableResourcesLoading}
                            selectedSchedules={props.selectedSchedules}
                            setSelectedSchedules={props.setSelectedSchedules}
                            onClickEditSchedule={props.onClickEditSchedule}
                            onClickCreateSchedule={props.onClickCreateSchedule}
                            onClickDeleteSelectedSchedules={props.onClickDeleteSelectedSchedules}
                            onClickDeleteSchedule={props.onClickDeleteSchedule}
                            onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                            parkableResources={props.parkableResources}
                            isDrawerOpen={props.isDrawerOpen}
                            usersBusinessGroupings={props.usersBusinessGroupings}
                            userHasGroupings={props.userHasGroupings}
                            loadParkableResources={props.loadParkableResources}
                            setScheduleToEdit={props.setScheduleToEdit}
                            isChangeScheduleDialogOpen={props.isChangeScheduleDialogOpen}
                            setIsChangeScheduleDialogOpen={props.setIsChangeScheduleDialogOpen}
                            isRemoveAdvancedParkingDialogOpen={props.isRemoveAdvancedParkingDialogOpen}
                            setIsRemoveAdvancedParkingDialogOpen={props.setIsRemoveAdvancedParkingDialogOpen}
                            onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                            isScheduled={props.isScheduled}
                            onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                            onToggleDrawer={props.onToggleDrawer}
                            scheduleToEdit={props.scheduleToEdit}
                            parkingScheduleType={parkingScheduleType}
                            isParkingLoading={props.isParkingLoading}
                            parkingSchedules={props.parkingSchedules}
                            isConfirmDeleteDialogOpen={props.isConfirmDeleteDialogOpen}
                            setIsConfirmDeleteDialogOpen={props.setIsConfirmDeleteDialogOpen}
                            parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                            setScheduleToDelete={props.setScheduleToDelete}
                            scheduleToDelete={props.scheduleToDelete}
                            onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                            schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                            isCreationEnabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} container direction={'row'} justifyContent={'space-between'}>
                        <Grid xs={6} item>
                            <Typography role={'presentation'} variant={'subtitle1'} className={commonStyles.classes.SubTitle}>
                                Manage your scheduled resources
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Stack>
                    <ParkingSchedulesTable
                        parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                        parkingSchedules={props.parkingSchedules}
                        selectedSchedules={props.selectedSchedules}
                        setSelectedSchedules={props.setSelectedSchedules}
                        isLoading={props.isParkingLoading}
                        onClickAction={onClickAction}
                        onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                        onOpenScheduleChangeDialog={props.onOpenScheduleChangeDialog}
                        onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                        onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                        onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
};

const useStyles = customMakeStyles<IParkingCardProps>()((theme, props) => ({
    Card: {
        minHeight: '100%',
    },
}));

export { ParkingSchedulesCard };
