import { useKeycloak } from '@react-keycloak-fork/web';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { RulesApi } from '@vegaplatformui/apis';
import { sessionTexts } from '@vegaplatformui/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IUsePollingApiHook {
    getApplyAssignmentRulesData: string;
}
export interface IUsePollingApiProps {}

export function usePollingApi(props: IUsePollingApiProps): IUsePollingApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const flags = useFlags();

    const rulesApi = useMemo(() => {
        const rulesApi = new RulesApi();
        rulesApi.keycloak = keycloak;
        return rulesApi;
    }, [keycloak]);

    const {
        data: getApplyAssignmentRulesData,
        isLoading: isGetApplyAssignmentRulesStatusLoading,
        isError: isGetApplyAssignmentRulesStatusLoadingError,
        error: getApplyAssignmentRulesStatusLoadingError,
        isSuccess: isGetApplyAssignmentRulesStatusLoadingSuccess,
    } = useQuery({
        queryKey: [queryKeys.rulesApi.getApplyAssignmentRulesStatus, sessionStorage.getItem(sessionTexts.businessGroupRulesApplyJobId)],
        enabled:
            sessionStorage.getItem(sessionTexts.businessGroupRulesApplyJobId) !== null &&
            sessionStorage.getItem(sessionTexts.businessGroupRulesApplyJobId) !== undefined &&
            flags?.businessGroupingAssignmentRules,
        queryFn: async () => {
            const response = await rulesApi.getApplyAssignmentRulesStatus(sessionStorage.getItem(sessionTexts.businessGroupRulesApplyJobId) ?? '');
            switch (response.data.toLowerCase()) {
                case 'running':
                    break;
                case 'complete':
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'info' },
                        message: `Assignment rules have been successfully applied`,
                    });
                    sessionStorage.removeItem(sessionTexts.businessGroupRulesApplyJobId);
                    break;
                default:
                    sessionStorage.removeItem(sessionTexts.businessGroupRulesApplyJobId);
            }
            return response.data;
        },
        refetchInterval: 10000,
        meta: {
            errorMessage: '`Unable to get apply assignment rules status',
        },
    });

    return {
        getApplyAssignmentRulesData: getApplyAssignmentRulesData ?? '',
    };
}
