import React from 'react';
import { NotificationSettingsTab } from '../../utilities/notification-settings-tab';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationHistoryProps {}

const NotificationHistory: React.FC<INotificationHistoryProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <NotificationSettingsTab title={'History'} subtitle={'View your notification and report history.'} isLoading={false}>
            <></>
        </NotificationSettingsTab>
    );
};

const useStyles = customMakeStyles<INotificationHistoryProps>()((theme, props) => ({}));

export { NotificationHistory };
