import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, Chip, Link, ListItem, ListItemText, Stack } from '@mui/material';
import { SupportEmailRecipient } from '@vegaplatformui/models';
import { SmartToy } from '@mui/icons-material';
import { ShowSupportForm } from '../../jotai/atom';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IHelpWindowProps {}

const HelpWindow: React.FC<IHelpWindowProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setShowSupportForm = useSetAtom(ShowSupportForm);

    const contactSupport = () => {
        setShowSupportForm({ showSupportForm: true, contactType: SupportEmailRecipient.Support });
    };

    return (
        <Stack direction={'column'} component={'ol'} spacing={1} className={cx(classes.ChatBoxStack)}>
            <Stack component={'li'} direction='row' justifyContent={'flex-start'} alignItems='center' spacing={1}>
                <Avatar className={cx(classes.BotAvatar)}>
                    <SmartToy />
                </Avatar>
                <ListItem component={'span'} className={cx(classes.BotMessageBox)}>
                    <Stack direction={'column'} spacing={1.25}>
                        <ListItemText primary={'Hi, please select one of the following options below and I can help you'} />
                        <Chip
                            className={cx(classes.ChipButton)}
                            component={Link}
                            variant='outlined'
                            color='primary'
                            target={'_blank'}
                            aria-label='navigate to documenation'
                            label='Documentation and FAQS'
                            href={'https://docs.vegacloud.io'}
                            rel='noreferrer noopener'
                            clickable
                        />
                        <Chip
                            className={cx(classes.ChipButton)}
                            variant='outlined'
                            color='primary'
                            label='Contact Support'
                            clickable
                            onClick={contactSupport}
                        />
                    </Stack>
                </ListItem>
            </Stack>
        </Stack>
    );
};

const useStyles = customMakeStyles<IHelpWindowProps>()((theme, props) => ({
    ChipButton: {
        '&.MuiChip-root': {
            '&:hover': {
                backgroundColor: `${theme.palette.grey[300]}60`, // Replace with your desired hover color
            },
        },
    },
    BotAvatar: { backgroundColor: 'black' },
    ChatBoxStack: { overflowY: 'auto', overflowWrap: 'break-word', paddingInlineStart: '0px' },
    BotMessageBox: {
        padding: '10px',
        width: '80%',
        borderRadius: '5px',
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.getContrastText(theme.palette.grey[100]),
        whiteSpace: 'pre-wrap',
    },
}));

export { HelpWindow };
