import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import {
    Checkbox,
    FormControl,
    FormHelperText,
    Grid2,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { ISSORoleMappingForm, IUserSettingRealmRole, roleAssignment } from '@vegaplatformui/models';
import { OrganizationId } from '../../../jotai/atom';
import { FormField } from '../../../forms';
import { StyledToolTip } from '../../../utilities/styled-tooltip';
import { useAtomValue } from 'jotai/index';
import { Control, Controller, FieldErrors, UseFormHandleSubmit } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material/Select';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleMappingItemProps {
    roles: IUserSettingRealmRole[];
    errors: FieldErrors<ISSORoleMappingForm>;
    control: Control<ISSORoleMappingForm>;
    handleSubmit: UseFormHandleSubmit<ISSORoleMappingForm>;
    onSaveOrCreateRoleMapping: (roleMappingData: ISSORoleMappingForm) => void;
}

const RoleMappingItem: React.FC<IRoleMappingItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const organizationId = useAtomValue(OrganizationId);
    const theme = useTheme();

    const handleRoleChange = (event: SelectChangeEvent<string[]>, onChange: (...event: any[]) => void) => {
        const value = event.target.value as string[];

        // Handle "Select All" option
        if (value[value.length - 1] === 'all') {
            const nonVegaRoles = props.roles.filter((role) => !role.name.includes('vega_'));
            const currentRoleIds = props.control._formValues.roleAssignments.map((ra: roleAssignment) => ra.roleId);
            const allSelected = nonVegaRoles.every((role) => currentRoleIds.includes(role.id));

            // If all roles are already selected, clear the selection, otherwise select all
            onChange(
                allSelected
                    ? []
                    : nonVegaRoles.map((role) => ({
                          orgId: organizationId,
                          roleId: role.id,
                      }))
            );
            return;
        }

        // Convert selected role IDs to roleAssignment objects
        const selectedRoleIds = value;
        const roleAssignments = selectedRoleIds.map((roleId) => ({
            orgId: organizationId,
            roleId,
        }));

        onChange(roleAssignments);
    };

    return (
        <Grid2
            id={'role-mapping-form'}
            component={'form'}
            onSubmit={props.handleSubmit(
                (data) => props.onSaveOrCreateRoleMapping(data),
                (errors) => {
                    console.error('Form validation errors:', errors);
                }
            )}
            direction={'column'}
            container
            spacing={2}
            justifyContent='flex-start'
            alignItems='flex-start'
        >
            <Grid2 container direction={'column'} spacing={0.5} size={12}>
                <Grid2 size={12}>
                    <Stack direction={'row'}>
                        <Typography>SSO IDP Group Name</Typography>
                        <Tooltip title={'Type the name of the group as it is displayed in your IDP in this field.'} placement={'right'} arrow>
                            <Info className={commonStyles.classes.TooltipIcons} />
                        </Tooltip>
                    </Stack>
                </Grid2>
                <Grid2 size={12} className={cx(classes.FullWidthInput)}>
                    <FormField htmlFor={'externalGroupName'}>
                        <Controller
                            name={'externalGroupName'}
                            control={props.control}
                            render={({ field: { value, onChange } }) => (
                                <FormControl size={'small'} className={cx(classes.FullWidthInput)}>
                                    <TextField
                                        error={!!props.errors.externalGroupName}
                                        onChange={onChange}
                                        id={'externalGroupName'}
                                        size='small'
                                        placeholder={'Enter IDP Group name'}
                                        fullWidth
                                        helperText={props.errors.externalGroupName?.message}
                                        value={value}
                                    />
                                </FormControl>
                            )}
                        />
                    </FormField>
                </Grid2>
            </Grid2>
            <Grid2 container direction={'column'} spacing={0.5} size={12}>
                <Grid2 size={12}>
                    <Stack direction={'row'}>
                        <Typography>Platform Role</Typography>
                        <Tooltip title={'Select each role that you want to assign to the IDP group.'} placement={'right'} arrow>
                            <Info className={commonStyles.classes.TooltipIcons} />
                        </Tooltip>
                    </Stack>
                </Grid2>
                <Grid2 size={12} className={cx(classes.FullWidthInput)}>
                    <FormField htmlFor='platform_role'>
                        <Controller
                            name={'roleAssignments'}
                            control={props.control}
                            render={({ field: { value, onChange } }) => {
                                // Extract just the roleIds from the roleAssignments array for the Select value
                                const selectedRoleIds = (value || []).map((assignment: roleAssignment) => assignment.roleId);

                                return (
                                    <FormControl size={'small'} className={cx(classes.FullWidthInput)}>
                                        <Select
                                            className={cx(classes.FullWidthInput)}
                                            id={'platform_role-label'}
                                            size={'small'}
                                            multiple
                                            fullWidth
                                            error={!!props.errors.roleAssignments}
                                            slotProps={{ input: { placeholder: 'Select roles' } }}
                                            value={selectedRoleIds}
                                            onChange={(event) => handleRoleChange(event as SelectChangeEvent<string[]>, onChange)}
                                            renderValue={(selected) => {
                                                return props.roles
                                                    .filter((role) => (selected as string[]).includes(role.id))
                                                    .map((role) => role.name)
                                                    .join(', ');
                                            }}
                                        >
                                            <MenuItem value='all'>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={
                                                            props.roles.filter((role) => !role.name.includes('vega_')).length > 0 &&
                                                            props.roles
                                                                .filter((role) => !role.name.includes('vega_'))
                                                                .every((role) => selectedRoleIds.includes(role.id))
                                                        }
                                                        indeterminate={
                                                            selectedRoleIds.length > 0 &&
                                                            selectedRoleIds.length < props.roles.filter((role) => !role.name.includes('vega_')).length
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary='Select All' />
                                            </MenuItem>
                                            {props.roles.map((role) => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    <StyledToolTip key={role.id} placement={'left'} arrow title={role.description}>
                                                        <Stack
                                                            className={cx(classes.FullWidthInput)}
                                                            direction='row'
                                                            justifyContent='flex-start'
                                                            alignItems='center'
                                                            spacing={0}
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox checked={selectedRoleIds.includes(role.id)} />
                                                            </ListItemIcon>
                                                            <ListItemText primary={role.name} />
                                                        </Stack>
                                                    </StyledToolTip>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            <Typography variant={'caption'} color={theme.palette.error.main}>
                                                {props.errors.roleAssignments?.message ?? ''}
                                            </Typography>
                                        </FormHelperText>
                                    </FormControl>
                                );
                            }}
                        />
                    </FormField>
                </Grid2>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IRoleMappingItemProps>()((theme, props) => ({
    FullWidthInput: { width: '100%' },
}));

export { RoleMappingItem };
