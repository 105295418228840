import React from 'react';
import {
    GridColumnMenuColumnsItem,
    GridColumnMenuContainer,
    GridColumnMenuFilterItem,
    GridColumnMenuProps,
    GridColumnMenuSortItem,
} from '@mui/x-data-grid-premium';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomDatagridColumnMenuProps extends GridColumnMenuProps {}

const CustomDatagridColumnMenu: React.FC<ICustomDatagridColumnMenuProps> = (props) => {
    const { hideMenu, colDef, color, ...other } = props;

    return (
        <GridColumnMenuContainer hideMenu={hideMenu} colDef={colDef} {...other}>
            <GridColumnMenuSortItem onClick={hideMenu} colDef={colDef!} />
            <GridColumnMenuFilterItem onClick={hideMenu} colDef={colDef!} />
            {/*TODO wont do it for now but added this since we can add back individual column menu actions. Just dont know what this might affect so don't want to just add it in*/}
            {/*<GridColumnMenuColumnsItem onClick={hideMenu} colDef={colDef!} />*/}
        </GridColumnMenuContainer>
    );
};

export { CustomDatagridColumnMenu };
