import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Skeleton } from '@mui/material';
import { SettingsContentHeader } from '../settings-content-header';
import { SettingsV2Divider } from '../settings-v2-divider';
import { SettingsDetailTile } from '../settings-detail-tile';
import { ProfileGeneralInputs } from './profile-general-inputs';
import { IPutUserV2HookRequest, IUserSettingAddress, IUserSettingProfile } from '@vegaplatformui/models';
import { ProfileImageInput } from './profile-image-input';
import { ProfileAddressInput } from './profile-address-input';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileDetailsCardProps {
    isLoading: boolean;
    profile: IUserSettingProfile;
    address: IUserSettingAddress;
    onProfileUpdate: (data: IPutUserV2HookRequest) => void;
    onAddressUpdate: (data: IPutUserV2HookRequest) => void;
    onPhotoUpload: (data: File) => void;
    onPhotoRemove: () => void;
}

const ProfileDetailsCard: React.FC<IProfileDetailsCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const flags = useFlags();

    return (
        <>
            {props.isLoading ? (
                <Grid container spacing={2}>
                    <SettingsContentHeader title={'Profile Details'} subtitle={' Manage your personal information.'} />
                    <SettingsV2Divider />
                    <SettingsDetailTile title={'General'} children={<Skeleton width={'100%'} height={'200px'} />} />
                    <SettingsV2Divider />
                    <SettingsDetailTile title={'Image'} children={<Skeleton width={'100%'} height={'109px'} />} />
                    <SettingsV2Divider />
                    <SettingsDetailTile
                        title={'Address'}
                        subtitle={'This is where we’ll send Cloud Heroes rewards'}
                        children={<Skeleton width={'100%'} height={'275px'} />}
                    />
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <SettingsContentHeader
                        title={'Profile Details'}
                        subtitle={'Manage your general information.'}
                    />
                    <SettingsV2Divider />
                    <SettingsDetailTile
                        title={'General'}
                        children={
                            <ProfileGeneralInputs profile={props.profile} onProfileUpdate={props.onProfileUpdate} isLoading={props.isLoading} />
                        }
                    />
                    <SettingsV2Divider />
                    <SettingsDetailTile
                        title={'Image'}
                        subtitle={'Valid file extensions are .jpeg, .png, .jpg, and .gif.'}
                        children={
                            <ProfileImageInput image={props.profile.image} onPhotoUpload={props.onPhotoUpload} onPhotoRemove={props.onPhotoRemove} />
                        }
                    />
                    <SettingsV2Divider />
                    <SettingsDetailTile
                        title={'Address'}
                        subtitle={'This is where we’ll send Cloud Heroes rewards.'}
                        children={<ProfileAddressInput address={props.address} onAddressUpdate={props.onAddressUpdate} />}
                    />
                </Grid>
            )}
        </>
    );
};

const useStyles = customMakeStyles<IProfileDetailsCardProps>()((theme, props) => ({}));

export { ProfileDetailsCard };
