import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingUnsavedChangesWarningDialogProps {
    isUnsavedChangesWarningDialog: boolean;
    onCloseDialog: () => void;
}

const BusinessGroupingUnsavedChangesWarningDialog: React.FC<IBusinessGroupingUnsavedChangesWarningDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog open={props.isUnsavedChangesWarningDialog} onClose={props.onCloseDialog} fullWidth maxWidth={'sm'}>
            <DialogTitle variant={'h6'} id='unassign-users-dialog'>
                There are unsaved changes on current tab.
            </DialogTitle>
            <DialogContent>
                <Typography>Save before selecting a different tab, or reset changes done to current tab.</Typography>
            </DialogContent>
            <Stack direction={'row'} justifyContent={'flex-end'} columnGap={2} padding={2}>
                <Button variant='contained' className={commonStyles.classes.GreyButton} onClick={props.onCloseDialog}>
                    Cancel
                </Button>
                <Button variant='contained' form={'business-group-details-form'} type={'submit'} onClick={() => props.onCloseDialog()}>
                    Save Changes
                </Button>
            </Stack>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingUnsavedChangesWarningDialogProps>()((theme, props) => ({}));

export { BusinessGroupingUnsavedChangesWarningDialog };
