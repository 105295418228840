import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { IPostUserV2Request, IPutUserV2BusinessGroupings, IPutUserV2DetailsRequest, ISubmitUserV2, IUser } from '@vegaplatformui/models';
import { SubmitHandler } from 'react-hook-form';
import { Form, FormField } from '../../forms';
import { IBusinessGroupingNode, IBusinessGroupingType, IUserSettingRealmRole, IBusinessUnitTreeItem } from '@vegaplatformui/models';
import React, { useEffect, useState } from 'react';
import { fieldToStr, regexList } from '@vegaplatformui/utils';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { BusinessGroupingPicker } from '../../business-grouping-picker/business-grouping-picker';
import { TreeNodeProps } from 'react-dropdown-tree-select';

const userFormFields = ['given_name', 'family_name', 'email'] as const;
type UserFormFieldUnion = (typeof userFormFields)[number];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const regex = {
    ...regexList,
    given_name: regexList.alphanumeric_contains_space_and_hyphen,
    family_name: regexList.alphanumeric_contains_space_and_hyphen,
};

export interface IUserFormDialogProps {
    user?: IUser;
    users: IUser[];
    roles: IUserSettingRealmRole[];
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (data: ISubmitUserV2, user_id: string) => void;
    onInvite: (data: ISubmitUserV2) => void;
    businessGroupingTypes: IBusinessGroupingType[];
    usersBusinessGroupings: IBusinessUnitTreeItem[];
    ownedBusinessGroupingsByUser: string[];
}

const UserFormDialog: React.FC<IUserFormDialogProps> = (props) => {
    const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
    const [selectedBusinessGroups, setSelectedBusinessGroups] = useState<string[] | undefined>(
        props.user?.business_groupings?.map((item) => item.id)
    );
    const [businessGroupings, setBusinessGroupings] = useState<TreeNodeProps[]>(props.usersBusinessGroupings);
    const [tooltipToShow, setTooltipToShow] = useState('');

    useEffect(() => {
        if (props.user && props.user.business_groupings) {
            setSelectedBusinessGroups(props.user.business_groupings.map((item) => item.id));
        }
    }, [props.user]);

    useEffect(() => {
        if (!selectedBusinessGroups) return;
        const searchAndUpdateSelectedBusinessGroups = (BGs: TreeNodeProps[], selectedBusinessGroups: string[]) => {
            return BGs.map((item): TreeNodeProps => {
                if (item.children) {
                    return {
                        ...item,
                        checked: selectedBusinessGroups.includes(item.value),
                        children: searchAndUpdateSelectedBusinessGroups(item.children, selectedBusinessGroups),
                        disabled: props.ownedBusinessGroupingsByUser.includes(item.value),
                    };
                }
                return { ...item, checked: selectedBusinessGroups.includes(item.value) };
            });
        };
        setBusinessGroupings(
            searchAndUpdateSelectedBusinessGroups(
                businessGroupings.length > 0 ? businessGroupings : props.usersBusinessGroupings,
                selectedBusinessGroups
            )
        );
    }, [props.usersBusinessGroupings, selectedBusinessGroups, props.ownedBusinessGroupingsByUser]);

    const onSubmit: SubmitHandler<ISubmitUserV2> = (data: ISubmitUserV2) => {
        data.business_groupings = selectedBusinessGroups?.map((groupingId) => {
            const grouping: IPutUserV2BusinessGroupings = { id: groupingId };
            return grouping;
        });
        data.platform_roles = selectedRoles;
        if (props.user) {
            props.onUpdate(data, props.user.id);
        } else {
            props.onInvite(data);
        }
    };

    const handleRoleSelect = (event: SelectChangeEvent<typeof selectedRoles>) => {
        const {
            target: { value },
        } = event;
        setSelectedRoles(typeof value === 'string' ? value.split(',') : value);
    };
    const handleBusinessGroupSelect = (businessGroupings: IBusinessGroupingNode[]) => {
        setSelectedBusinessGroups(businessGroupings.map((item) => item.id));
    };

    useEffect(() => {
        if (props.isOpen) {
            const defaultRoles = props.user && props.user.platform_roles ? props.user.platform_roles : [];
            setSelectedBusinessGroups(props.user && props.user.business_groupings ? props.user.business_groupings.map((item) => item.id) : []);
            setSelectedRoles(defaultRoles);
        } else {
            setSelectedRoles([]);
            setSelectedBusinessGroups([]);
        }
    }, [props.isOpen]);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} fullWidth>
            <DialogTitle variant={'h6'} style={{ cursor: 'move' }} id='choose-cloud-provider-dialog'>
                {props.user ? 'Edit User' : 'Invite User'}
            </DialogTitle>
            <Form onSubmit={onSubmit}>
                {({ errors, register, watch, control }) => {
                    return (
                        <>
                            <DialogContent sx={{ height: props.user ? '400px' : '200px' }}>
                                <Grid2 container spacing={2}>
                                    {userFormFields.map((field: UserFormFieldUnion) => (
                                        <Grid2 size={{ xs: 12, sm: 6 }} key={`${field}`}>
                                            <FormField
                                                label={fieldToStr(
                                                    field === 'family_name' ? 'last_name' : field === 'given_name' ? 'first_name' : field
                                                )}
                                                htmlFor={field}
                                            >
                                                <TextField
                                                    id={field}
                                                    size='small'
                                                    fullWidth={true}
                                                    slotProps={{ input: { readOnly: field === 'email' && !!props.user } }}
                                                    {...register(field, {
                                                        required: {
                                                            value: true,
                                                            message: 'Required',
                                                        },
                                                        validate: (value: string) => {
                                                            if (!String(value).match(regex[field])) {
                                                                if (field === 'given_name' || field === 'family_name') {
                                                                    return 'Must begin and end with an alphanumeric, and contains hyphens or spaces.';
                                                                }
                                                                if (field === 'email') {
                                                                    return 'Invalid email address.';
                                                                }
                                                            }
                                                            if (field === 'email' && !props.user) {
                                                                const filtered = props.users.filter((x) => x.email === value);
                                                                if (filtered.length > 0) {
                                                                    return 'That email address is already taken.';
                                                                }
                                                            }
                                                        },
                                                    })}
                                                    error={!!errors[field]}
                                                    defaultValue={props.user ? props.user[field] : ''}
                                                    helperText={errors[field]?.message}
                                                />
                                            </FormField>
                                        </Grid2>
                                    ))}
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Role' id={'platform_roles'} htmlFor='platform_roles'>
                                            <Select
                                                id={'platform_roles'}
                                                labelId={'platform_roles'}
                                                size='small'
                                                multiple
                                                fullWidth={true}
                                                {...register('platform_roles')}
                                                error={!!errors.platform_roles}
                                                MenuProps={MenuProps}
                                                value={selectedRoles}
                                                renderValue={(selected) => selected.join(', ')}
                                                onChange={handleRoleSelect}
                                            >
                                                {props.roles.map((option) => (
                                                    <MenuItem
                                                        onFocus={(focusEvent) => setTooltipToShow(option.name)}
                                                        onMouseEnter={() => setTooltipToShow(option.name)}
                                                        onMouseLeave={() => setTooltipToShow('')}
                                                        key={option.name}
                                                        value={option.name}
                                                    >
                                                        <StyledToolTip
                                                            open={option.name === tooltipToShow}
                                                            placement={'left'}
                                                            arrow
                                                            title={option.description}
                                                        >
                                                            <Stack
                                                                spacing={0.5}
                                                                direction={'row'}
                                                                alignItems={'center'}
                                                                justifyContent={'flex-start'}
                                                            >
                                                                <Checkbox checked={selectedRoles.includes(option.name)} />
                                                                <Typography>{option.name}</Typography>
                                                            </Stack>
                                                        </StyledToolTip>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormField>
                                    </Grid2>
                                    {props.user && (
                                        <Grid2 size={12}>
                                            <BusinessGroupingPicker
                                                data={businessGroupings}
                                                mode='hierarchical'
                                                changeHandler={handleBusinessGroupSelect}
                                                setData={setBusinessGroupings}
                                            />
                                        </Grid2>
                                    )}
                                </Grid2>
                            </DialogContent>
                            <DialogActions>
                                <Button variant={'cancel'} color={'secondary'} autoFocus onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button variant={'contained'} type='submit' disabled={false}>
                                    {props.user ? 'Save Changes' : 'Send Invite'}
                                </Button>
                            </DialogActions>
                        </>
                    );
                }}
            </Form>
        </Dialog>
    );
};

export { UserFormDialog };
