import { useEffect } from 'react';
import { LinearProgress, Typography, Grid } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinearDeterminateLoadingProps {
    progress: number;
    setProgress: React.Dispatch<React.SetStateAction<number>>;
    intervalProgression: number;
    timerDelay: number;
}

const LinearDeterminateLoading: React.FC<ILinearDeterminateLoadingProps> = (props) => {
    const { progress, setProgress, intervalProgression, timerDelay } = props;
    const { classes, cx } = useStyles(props);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + intervalProgression));
        }, timerDelay);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Grid container direction='column' justifyContent='space-between' alignItems='center' spacing={30}>
            <Grid className={cx(classes.LoadingGrid)} item xs={12}>
                <LinearProgress variant={'determinate'} value={progress} />
            </Grid>
            <Grid xs={12} item container direction='column' justifyContent='center' alignItems='center' wrap='nowrap' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Please wait a moment while we set things up.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={cx(classes.ProgressText)} variant='h6'>{`${Math.round(progress)}%`}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ILinearDeterminateLoadingProps>()((theme, props) => ({
    VCow: { width: '100%' },
    ContentGrid: {},
    LoadingGrid: { width: '100%' },
    ProgressText: { color: theme.palette.text.secondary },
}));

export { LinearDeterminateLoading };
