import React from 'react';
import {
    Box,
    Card,
    IconButton,
    LinearProgress,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { BudgetData } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { StyledToolTip } from '../../utilities/styled-tooltip';

export interface IBudgetListProps {
    budgets: BudgetData[];
    onEditBudget?: (budget: BudgetData) => void;
    onDeleteBudget?: (budget: BudgetData) => void;
}

const BudgetList: React.FC<IBudgetListProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { budgets, onEditBudget, onDeleteBudget } = props;

    const handleEditBudget = (budget: BudgetData) => {
        if (onEditBudget) {
            onEditBudget(budget);
        }
    };

    const handleDeleteBudget = (budget: BudgetData) => {
        if (onDeleteBudget) {
            onDeleteBudget(budget);
        }
    };

    return (
        <TableContainer component={Card} className={cx(classes.TableContainer)}>
            <Table size='medium' aria-label='budget table' className={cx(classes.Table)}>
                <TableHead>
                    <TableRow>
                        <TableCell className={cx(classes.HeaderCell)}>Budget name</TableCell>
                        <TableCell className={cx(classes.HeaderCell)}>Budget amount</TableCell>
                        <TableCell className={cx(classes.HeaderCell)}>Spend till date vs budget</TableCell>
                        <TableCell className={cx(classes.HeaderCell)}>Alert when</TableCell>
                        <TableCell align='center' className={cx(classes.HeaderCell)}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {budgets.map((budget) => (
                        <TableRow key={budget.id} className={cx(classes.TableRow)}>
                            {/* Budget Name + Date Range + Frequency */}
                            <TableCell className={cx(classes.TableCell)}>
                                <Typography variant='subtitle1' className={cx(classes.BudgetName)}>
                                    {budget.name}
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    {budget.frequency} | {budget.dateRange}
                                </Typography>
                            </TableCell>

                            {/* Budget Amount */}
                            <TableCell className={cx(classes.TableCell)}>
                                <Typography variant='subtitle2'>${budget.budgetAmount.toLocaleString()}</Typography>
                            </TableCell>

                            {/* Spend (Progress Bar + amounts) */}
                            <TableCell className={cx(classes.TableCell)}>
                                <Stack direction='column' spacing={1}>
                                    <Stack direction='row' alignItems='center' spacing={2}>
                                        <Typography variant='body2' color='text.secondary'>
                                            ${budget.spentToDate.toLocaleString()}
                                        </Typography>
                                        <Box className={cx(classes.ProgressBarContainer)}>
                                            <LinearProgress
                                                variant='determinate'
                                                value={Math.min(budget.usagePercent, 100)}
                                                className={cx(classes.ProgressBar)}
                                            />
                                        </Box>
                                        <Typography variant='body2' color='text.secondary'>
                                            ${budget.budgetAmount.toLocaleString()}
                                        </Typography>
                                    </Stack>
                                    {/* Add percentage used */}
                                    <Stack direction='row' alignItems='center'>
                                        <Typography variant='body2' color='text.secondary'>
                                            {budget.usagePercent.toFixed(2)}% used
                                        </Typography>
                                        {budget.usagePercent > 100 && (
                                            <Typography variant='body2' color='error.main' sx={{ marginLeft: 1 }}>
                                                ({budget.overBudgetAmount?.toLocaleString()} over budget)
                                            </Typography>
                                        )}
                                    </Stack>
                                </Stack>
                            </TableCell>

                            {/* Alert thresholds */}
                            <TableCell className={cx(classes.TableCell)}>
                                <Typography variant='body2' color='text.secondary'>
                                    {budget.alertWhen}
                                </Typography>
                            </TableCell>

                            {/* Edit/Delete Actions */}
                            <TableCell align='center' className={cx(classes.TableCell)}>
                                <StyledToolTip title='Edit Budget'>
                                    <IconButton onClick={() => handleEditBudget(budget)} className={cx(classes.ActionButton)}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                </StyledToolTip>
                                <StyledToolTip title='Delete Budget'>
                                    <IconButton onClick={() => handleDeleteBudget(budget)} className={cx(classes.ActionButton)}>
                                        <DeleteIcon fontSize='small' />
                                    </IconButton>
                                </StyledToolTip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const useStyles = customMakeStyles<IBudgetListProps>()((theme, props) => ({
    TableContainer: {
        elevation: 0,
        boxShadow: 'none',
    },
    Table: {
        borderCollapse: 'collapse',
    },
    TableRow: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    HeaderCell: {
        border: 'none',
        fontWeight: 'bold',
    },
    TableCell: {
        border: 'none',
    },
    BudgetName: {
        fontWeight: 'bold',
    },
    ProgressBarContainer: {
        flexGrow: 1,
        position: 'relative',
    },
    ProgressBar: {
        height: 8,
        borderRadius: 4,
        backgroundColor: theme.palette.grey[200],
        '&.MuiLinearProgress-determinate': {
            transition: 'none',
        },
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.success.main,
        },
    },
    ActionButton: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}));

export { BudgetList };
