import { Add } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { IBusinessGrouping, IBusinessGroupingType, IBusinessGroupTreeItem, IUserBusinessGroups } from '@vegaplatformui/models';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Dispatch, SetStateAction } from 'react';
import { BusinessGroupingTreeView } from './business-grouping-tree-view/business-grouping-tree-view';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouteUrls } from '@vegaplatformui/utils';
import { GlobalCardHeight } from '../constants/constants';

/* eslint-disable-next-line */
export interface IBusinessGroupingLandingProps {
    isLoading: boolean;
    onClickEditBusinessGrouping: (grouping: IBusinessGroupTreeItem) => void;
    onClickDeleteBusinessGrouping: (grouping: IBusinessGrouping) => void;
    setSelectedBusinessGrouping: Dispatch<SetStateAction<IBusinessGrouping[]>>;
    onClickOpenCreateBusinessGroupingDialog: () => void;
    onClickOpenEditBusinessGroupingDialog: (grouping: IBusinessGroupTreeItem) => void;
    initialBusinessGroups: IBusinessGroupTreeItem[];
    selectedGroupings: IBusinessGrouping[];
    onClickDeleteSelectedGroupings: () => void;
    businessGroupingTypes: IBusinessGroupingType[];
    businessGroupingToEdit?: any;
    usersBusinessGroupings: IUserBusinessGroups[];
}

export const BusinessGroupingLanding: React.FC<IBusinessGroupingLandingProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const navigate = useNavigate();
    const flags = useFlags();
    const { routeUrls } = useRouteUrls({});

    return (
        <Card className={cx(classes.Card)} elevation={0}>
            <CardContent>
                <Grid container direction={'row'}>
                    <Grid item xs={8}>
                        <Grid container direction={'column'}>
                            <Grid xs={12} item>
                                <Typography variant={'h1'}>Business Groupings</Typography>
                            </Grid>
                            <Grid xs={12} item>
                                <Typography variant={'subtitle1'} className={commonStyles.classes.SubTitle}>
                                    Manage your Business Groups for reporting and authorization purposes.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Stack
                            className={cx(classes.ButtonPlaceHolder)}
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                        >
                            {/*{props.selectedUnits.length > 1 && (*/}
                            {/*    <MultiDelete*/}
                            {/*        size='small'*/}
                            {/*        startIcon={<Delete />}*/}
                            {/*        className={cx(classes.UnitButtons)}*/}
                            {/*        variant={'contained'}*/}
                            {/*        onClick={props.onClickDeleteselectedUnits}*/}
                            {/*    >*/}
                            {/*        Delete Selected Units*/}
                            {/*    </MultiDelete>*/}
                            {/*)}*/}
                            {flags.businessGroupingAssignmentRules && (
                                <Button
                                    size='small'
                                    className={cx(classes.UnitButtons)}
                                    variant={'outlined'}
                                    onClick={() => navigate(routeUrls.businessGroupingAssignmentRules.path)}
                                >
                                    Assignment Rules
                                </Button>
                            )}
                            <Button
                                startIcon={<Add />}
                                size='small'
                                className={cx(classes.UnitButtons)}
                                variant={'contained'}
                                onClick={props.onClickOpenCreateBusinessGroupingDialog}
                            >
                                Business Grouping
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <BusinessGroupingTreeView
                    businessGroupingTypes={props.businessGroupingTypes}
                    usersBusinessGroupings={props.usersBusinessGroupings}
                    onClickOpenEditBusinessGroupingDialog={props.onClickOpenEditBusinessGroupingDialog}
                    initialBusinessGroups={props.initialBusinessGroups}
                />
            </CardContent>
        </Card>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingLandingProps>()((theme, props) => ({
    ButtonPlaceHolder: {
        height: '2.25rem',
    },
    UnitButtons: {
        textTransform: 'none',
    },
    Card: { height: '100%', overflow: 'auto' },
}));

export default BusinessGroupingLanding;
