import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid2, Switch, Typography } from '@mui/material';
import { IUserSettingMFAStatusEnum } from '@vegaplatformui/models';
import { SubmitHandler } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMfaTileProps {
    mfaType: string;
    mfaDescription?: string;
    status: IUserSettingMFAStatusEnum;
    onSubmit: (data: string) => void;
}

const MfaTile: React.FC<IMfaTileProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { mfaType, mfaDescription, onSubmit, status } = props;
    const [isTileActive, setIsTileActive] = useState<boolean>(status === IUserSettingMFAStatusEnum.CONDITIONAL);

    useEffect(() => {
        setIsTileActive(status === IUserSettingMFAStatusEnum.CONDITIONAL);
    }, [status]);

    const onToggleSwitch: SubmitHandler<any> = (isEnabled: boolean) => {
        setIsTileActive(isEnabled);
        onSubmit(isEnabled ? IUserSettingMFAStatusEnum.CONDITIONAL : IUserSettingMFAStatusEnum.DISABLED);
    };

    return (
        <Grid2 container size={9} className={cx(classes.MfaTile)} direction='row' justifyContent='space-between' alignItems='center'>
            <Grid2 size={10} container>
                <Grid2 size={12}>
                    <Typography variant={'subtitle2'}>{mfaType}</Typography>
                </Grid2>
                {mfaDescription && (
                    <Grid2 size={12}>
                        <Typography variant={'body2'}>{mfaDescription}</Typography>
                    </Grid2>
                )}
            </Grid2>
            <Grid2 size={'auto'}>
                <Switch
                    inputProps={{ 'aria-label': `Toggle switch for ${mfaType}` }}
                    id={`${mfaType}-switch`}
                    checked={isTileActive}
                    onChange={(event, checked) => onToggleSwitch(checked)}
                />
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IMfaTileProps>()((theme, props) => ({
    MfaTile: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '1rem' },
}));

export { MfaTile };
