import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';

import { IParkingPriorityGroup, IResource } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPriorityGroupSelectorDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedResources: IResource[];
    priorityGroups: IParkingPriorityGroup[];
    setPriorityGroups: React.Dispatch<React.SetStateAction<IParkingPriorityGroup[]>>;
    onClearSelectedResources: () => void;
}

const PriorityGroupSelectorDialog: React.FC<IPriorityGroupSelectorDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [priority, setPriority] = React.useState(-1);

    const onChangePriority = () => {
        const priorityGroupsWithResourcesRemoved =
            props.priorityGroups?.map((pg) => ({
                ...pg,
                resources: [...pg.resources.filter((res) => !props.selectedResources.find((res2) => res2.id === res.id))],
            })) ?? [];

        const priorityGroupToAddTo = priorityGroupsWithResourcesRemoved.find((pg) => pg.priority === priority);

        if (priorityGroupToAddTo) {
            priorityGroupToAddTo.resources = [
                ...priorityGroupToAddTo.resources,
                ...props.selectedResources.filter((res) => !priorityGroupToAddTo.resources.find((res2) => res2.id === res.id)),
            ];
        }

        const priorityGroupsUpdated = priorityGroupsWithResourcesRemoved.map((pg) => {
            if (pg.priority === priorityGroupToAddTo?.priority) {
                return priorityGroupToAddTo;
            } else {
                return pg;
            }
        });

        props.onClearSelectedResources();
        props.setPriorityGroups(priorityGroupsUpdated);
        setPriority(-1);
    };

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>Select the priority group you wish to move resources into.</DialogTitle>
            <DialogContent>
                <Select
                    size='small'
                    labelId='priority_group'
                    id='priority_group'
                    value={priority}
                    onChange={(e) => setPriority(e.target.value as number)}
                >
                    <MenuItem value={-1}>None</MenuItem>
                    {props.priorityGroups
                        .sort((a, b) => a.priority - b.priority)
                        .map((group: IParkingPriorityGroup) => (
                            <MenuItem key={group.priority} value={group.priority}>
                                {group.priority + 1}
                            </MenuItem>
                        ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onChangePriority();
                        props.onClose();
                    }}
                    autoFocus
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IPriorityGroupSelectorDialogProps>()((theme, props) => ({}));

export { PriorityGroupSelectorDialog };
