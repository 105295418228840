import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CopyReportUrlDialog, QuicksightReportLoadingSkeleton, QuicksightReportTabs, useReportsApi } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuicksightReportsControllerProps {
    folderNames: string[];
}

const QuicksightReportsController: React.FC<IQuicksightReportsControllerProps> = (props) => {
    const reportsApi = useReportsApi({ folderNames: props.folderNames });

    return (
        <>
            {reportsApi.isUrlLoading || reportsApi.isDashboardLoading ? (
                <QuicksightReportLoadingSkeleton />
            ) : (
                <QuicksightReportTabs
                    reports={reportsApi.reports}
                    dashboardUrl={reportsApi.embeddedUrl}
                    setDashboardExperience={reportsApi.setDashboardExperience}
                />
            )}
            {reportsApi.shareLink && (
                <CopyReportUrlDialog
                    reportUrl={reportsApi.shareLink}
                    onClose={() => {
                        reportsApi.setShareLink(undefined);
                    }}
                    isOpen={!!reportsApi.shareLink}
                />
            )}
        </>
    );
};

const useStyles = customMakeStyles<IQuicksightReportsControllerProps>()((theme, props) => ({}));

export { QuicksightReportsController };
