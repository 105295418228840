import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { MoreHoriz, MoreVert } from '@mui/icons-material';
import { Divider, Menu, MenuItem } from '@mui/material';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { ParkingScheduleActionMenu } from '../parking-schedule-action-menu';
import { IParkingScheduleSummary, ParkingScheduleType } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../utilities/custom-data-grid-render-cell-button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IParkingScheduleTableMenuProps {
    selectedSchedules: IParkingScheduleSummary[];
    scheduleSummary: IParkingScheduleSummary;
    onClickShowDetails: (schedule: IParkingScheduleSummary, scheduleType?: ParkingScheduleType) => void;
    onClickDeleteDialog?: (schedule?: IParkingScheduleSummary) => void;
    onOpenEnableDisableSchedulesDialog?: (schedules: IParkingScheduleSummary[] | undefined, isScheduledChangeStatus: boolean) => void;
    onOpenRemoveAdvancedParkingDialog?: (schedule: any) => void;
    onClickOpenDeleteExemptionDialog?: (schedule: IParkingScheduleSummary) => void;
    params: GridRenderCellParams<IParkingScheduleSummary>;
}

const ParkingScheduleTableMenu: React.FC<IParkingScheduleTableMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);

    const handleToggleCreateDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <StyledToolTip title={props.selectedSchedules.length > 1 ? '' : 'Options'}>
                <span>
                    <CustomDataGridRenderCellButton
                        ref={ref}
                        iconButtonProps={{
                            title: 'Options',
                            tabIndex: props.params.tabIndex,
                            onClick: handleToggleCreateDropDown,
                            children: <MoreHoriz />,
                            'aria-label': `${props.scheduleSummary.name}'s Options`,
                            'aria-expanded': !!anchorEl,
                            disabled: props.selectedSchedules.length > 1,
                            size: 'small',
                        }}
                        params={props.params}
                    />
                </span>
            </StyledToolTip>
            <ParkingScheduleActionMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                scheduleSummary={props.scheduleSummary}
                selectedSchedules={props.selectedSchedules}
                onClickOpenDeleteDialog={props.onClickDeleteDialog}
                onClickAction={props.onClickShowDetails}
                onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
            />
        </>
    );
};

const useStyles = customMakeStyles<IParkingScheduleTableMenuProps>()((theme, props) => ({}));

export { ParkingScheduleTableMenu };
