import { useKeycloak } from '@react-keycloak-fork/web';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import { useEffect, useMemo, useState } from 'react';
import { FileApi, FileUploadApi } from '@vegaplatformui/apis';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IFile, IGetFileDownloadUrlsResponse, IPutFileUploadUrl } from '@vegaplatformui/models';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { downloadZip } from 'client-zip';
import streamSaver from 'streamsaver';
import { GenerateRandomString } from '@vegaplatformui/utils';
import { queryKeys } from './query-keys';
import { AxiosResponse } from 'axios';

export interface IUseFilesApiHook {
    isLoading: boolean;
    availableFiles: IFile[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<IFile[] | undefined>>;
    uploadFileToS3: UseMutateFunction<any, unknown, IPutFileUploadUrl, unknown>;
    setFileToUpload: React.Dispatch<React.SetStateAction<File | undefined>>;
    deleteFiles: UseMutateFunction<AxiosResponse<any, any>, unknown, IFile[], unknown>;
    fileToUpload: File | undefined;
    isFileUploading: boolean;
}

export interface IUseFilesApiProps {
    isFileUpload: boolean;
    setFilesToUpload?: React.Dispatch<React.SetStateAction<File[]>>;
}

export function useFilesApi(props: IUseFilesApiProps): IUseFilesApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const [selectedFiles, setSelectedFiles] = useState<IFile[] | undefined>(undefined);
    const [fileUploadUrl, setFileUploadUrl] = useState<string | undefined>(undefined);
    const queryClient = useQueryClient();
    const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);

    useEffect(() => {
        queryClient.removeQueries({
            queryKey: [queryKeys.filesApi.availableFilesToDownload],
        });
    }, [props.isFileUpload]);

    const filesApi = useMemo(() => {
        const filesApi = new FileApi();
        filesApi.keycloak = keycloak;
        return filesApi;
    }, [keycloak]);

    const filesUploadApi = useMemo(() => {
        const filesUploadApi = new FileUploadApi(fileUploadUrl ?? '');
        return filesUploadApi;
    }, [keycloak, fileUploadUrl]);

    const {
        data: availableFilesToDownload,
        isLoading: isFilesLoading,
        isError: isFilesLoadingError,
        isSuccess: isFilesLoadingSuccess,
        error: loadingFilesError,
    } = useQuery({
        queryKey: [queryKeys.filesApi.availableFilesToDownload],

        queryFn: async () => {
            const response = await filesApi.getFiles(props.isFileUpload.toString());

            return response.data.items;
        },

        enabled: true,

        meta: {
            errorMessage: 'There was a problem loading files',
        },
    });

    const { data, isError, error, isLoading } = useQuery({
        queryKey: [queryKeys.filesApi.downloadFile, selectedFiles],

        queryFn: async () => {
            if (!selectedFiles) return;
            const response = await filesApi.getFileDownloadUrls({ items: selectedFiles });

            await onDownloadFiles(response.data, createFileArchiveName()).then(() => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Downloading ${selectedFiles.length} files.`,
                });
                setSelectedFiles([]);
            });

            return response.data;
        },

        // The query will not execute until the reports exists
        enabled: selectedFiles !== undefined,
        gcTime: 0,
        meta: {
            errorMessage:
                selectedFiles && selectedFiles.length > 1
                    ? 'There was a problem downloading your files'
                    : 'There was a problem downloading your file',
        },
    });

    const {
        data: fileUploadData,
        isLoading: isGetFileUploadUrlLoading,
        isError: isGetFileUploadUrlError,
        isSuccess: isGetFileUploadUrlSuccess,
        error: getFilesUploadUrlError,
    } = useQuery({
        queryKey: [queryKeys.filesApi.getFileUploadUrl, fileToUpload],

        queryFn: async () => {
            const response = await filesApi.getFileUploadUrl({ filename: fileToUpload?.name });
            response.data && fileToUpload && uploadFileToS3({ fileDownloadUrl: response.data, filePath: fileToUpload });
            return response.data;
        },
        enabled: fileToUpload !== undefined && fileToUpload !== null,
        meta: {
            errorMessage: 'There was a problem getting the file presigned URL',
        },
    });

    const { isPending: isFileUploadingToDb, mutate: uploadFileToDatabase } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem uploading the file: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.filesApi.availableFilesToDownload] });
            setFileToUpload(undefined);
            props.setFilesToUpload && props.setFilesToUpload([]);
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `File has been uploaded`,
            });
        },
        mutationFn: async (filePath: File) => {
            return await filesApi.putFileUpload({ fileName: filePath?.name });
        },
    });

    const { isPending: isFileUploadingToS3, mutate: uploadFileToS3 } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem uploading the file: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `File has been uploaded`,
            });
        },
        mutationFn: async (request: IPutFileUploadUrl) => {
            setFileUploadUrl(request.fileDownloadUrl);
            return filesUploadApi.putFileUploadUrl(request).then((response) => {
                setTimeout(() => {
                    return uploadFileToDatabase(request.filePath);
                }, 100);
            });
        },
    });

    const { isPending: isDeletingFiles, mutate: deleteFiles } = useMutation({
        onError: (error, files, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a error deleting the ${files.length > 1 ? 'files' : 'file'}: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (data, files, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.filesApi.availableFilesToDownload] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: files.length > 1 ? `${files.length} files have been deleted` : `The uploaded file, ${files[0].filename}, has been deleted`,
            });
        },
        mutationFn: async (files: IFile[]) => {
            return filesApi.deleteUploadedFile({ items: files });
        },
    });

    const onDownloadFiles = async (fileDownloadResponse: IGetFileDownloadUrlsResponse, archiveName: string) => {
        if (fileDownloadResponse.items !== null) {
            const files = await Promise.all(
                fileDownloadResponse?.items.map(async (file) => {
                    return {
                        name: file.filename,
                        input: await fetch(decodeURI(file.url)),
                    };
                })
            );
            await downloadZip(files).body?.pipeTo(streamSaver.createWriteStream(archiveName));
        }
    };

    const createFileArchiveName = () => {
        return `vega-file-download-${Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(Date.now())}-${GenerateRandomString(5)}.zip`;
    };

    return {
        isLoading: isFilesLoading || isFileUploadingToDb || isFileUploadingToS3 || isDeletingFiles,
        availableFiles: availableFilesToDownload ?? [],
        setSelectedFiles: setSelectedFiles,
        uploadFileToS3: uploadFileToS3,
        setFileToUpload: setFileToUpload,
        deleteFiles: deleteFiles,
        fileToUpload: fileToUpload,
        isFileUploading: isFileUploadingToDb || isFileUploadingToS3,
    };
}
