import React, { ReactNode, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Alert, AlertColor, IconButton, Popover } from '@mui/material';
import { CustomDraggableDialog } from '../custom-draggable-dialog/custom-draggable-dialog';
import PopupState, { bindTrigger, bindPopover, bindHover, bindFocus } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { chainEventHandlers } from 'material-ui-popup-state/chainEventHandlers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIconButtonWithAlertDialogProps {
    buttonTitle: string;
    buttonIcon: ReactNode;
    buttonColor: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    alertContent: ReactNode | ReactNode[];
    alertSeverity: AlertColor;
    shouldBindOnHover: boolean;
    popperClassName?: string;
    onClick?: () => void;
    onClose?: () => void;
    iconButtonClassName?: string;
    alertColor?: AlertColor;
    iconButtonSize?: 'small' | 'medium' | 'large';
}

const IconButtonWithAlertPopover: React.FC<IIconButtonWithAlertDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <PopupState variant='popover' popupId='alert-popup-popover' disableAutoFocus={false} parentPopupState={undefined}>
            {(popupState) => (
                <>
                    <IconButton
                        className={props.iconButtonClassName ?? undefined}
                        title={props.buttonTitle}
                        {...chainEventHandlers(bindTrigger(popupState), {
                            onClick: (e: React.MouseEvent) => {
                                bindTrigger(popupState).onClick(e);
                                props.onClick && props.onClick();
                            },
                        })}
                        {...(props.shouldBindOnHover && bindHover(popupState))}
                        aria-owns={popupState.isOpen ? 'alert-popover' : undefined}
                        color={props.buttonColor}
                        size={props.iconButtonSize ?? 'small'}
                    >
                        {props.buttonIcon}
                    </IconButton>
                    <Popover
                        id={'alert-popover'}
                        slotProps={{ paper: { className: props.popperClassName ? props.popperClassName : cx(classes.PopoverPaper) } }}
                        {...bindPopover(popupState)}
                        {...chainEventHandlers(bindPopover(popupState), {
                            onClose: (e: React.MouseEvent) => {
                                bindPopover(popupState).onClose();
                                props.onClose && props.onClose();
                            },
                        })}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert
                            id='alert-popover-content'
                            aria-label={'Popover with Alert Content'}
                            onClose={(event) => {
                                popupState.close();
                                props.onClose && props.onClose();
                            }}
                            severity={props.alertSeverity}
                            color={props.alertColor}
                        >
                            {props.alertContent}
                        </Alert>
                    </Popover>
                </>
            )}
        </PopupState>
    );
};

const useStyles = customMakeStyles<IIconButtonWithAlertDialogProps>()((theme, props) => ({
    PopoverPaper: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '30%',
        },
    },
}));

export { IconButtonWithAlertPopover };
