import React, { useEffect, useMemo, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormHelperText, Grid2, MenuItem, Select, SelectChangeEvent, TextField, Theme, Typography } from '@mui/material';
import {
    IBusinessGrouping,
    IBusinessGroupingDetailsForm,
    IBusinessGroupingNode,
    IBusinessGroupingType,
    IUser,
    IUserBusinessGroups,
} from '@vegaplatformui/models';
import { useTheme } from '@mui/material/styles';
import { Controller, UseFormReset, UseFormSetValue, Control, FieldErrors, UseFormRegister, UseFormHandleSubmit } from 'react-hook-form';
import { useKeycloak } from '@react-keycloak-fork/web';
import { FormField } from '../../../forms';
import { BusinessGroupingOwnerFormField } from './business-grouping-owner-form-field';
import { BusinessGroupingPicker } from '../../../business-grouping-picker/business-grouping-picker';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { setSelectedNodes } from '../../../utilities/selecting-assigned-business-grouping';

export interface IBusinessGroupingWizardDetailsTabProps {
    businessGroupingToEdit: IBusinessGrouping | undefined;
    businessGroupingTypes: IBusinessGroupingType[];
    availableUsers: IUser[];
    userBusinessGroupings: TreeNodeProps[];
    setIsError: (isError: boolean) => void;
    setIsLoading: (isLoading: boolean) => void;
    reset: UseFormReset<IBusinessGroupingDetailsForm>;
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
    control: Control<IBusinessGroupingDetailsForm>;
    isDirty: boolean;
    isValid: boolean;
    errors: FieldErrors<IBusinessGroupingDetailsForm>;
    register: UseFormRegister<IBusinessGroupingDetailsForm>;
    handleSubmit: UseFormHandleSubmit<IBusinessGroupingDetailsForm>;
    users: IUser[];
    setUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
}

const BusinessGroupingWizardDetailsTab: React.FC<IBusinessGroupingWizardDetailsTabProps> = (props) => {
    const { control, setValue, isDirty, isValid, errors, register, handleSubmit, users } = props;
    const theme = useTheme();
    const keycloak = useKeycloak();
    const [businessGroupings, setBusinessGroupings] = useState<TreeNodeProps[]>(props.userBusinessGroupings);
    const [isBGPickerDisabled, setIsBGPickerDisabled] = useState(false);

    const businessGroupingParentHasErrors = useMemo(() => {
        return !!errors.business_grouping_parent;
    }, [errors]);
    useEffect(() => {
        if (users && props.businessGroupingToEdit) {
            setValue(
                'business_grouping_owner',
                props.businessGroupingToEdit?.owned_by
                    ? (users.find((user) => user.id === props.businessGroupingToEdit?.owned_by) ?? null)
                    : (users.find((user) => user.email === keycloak?.keycloak?.tokenParsed?.email) ?? null),
                { shouldDirty: false }
            );
        }
    }, [users, props.businessGroupingToEdit]);

    useEffect(() => {
        if (!props.businessGroupingToEdit) {
            setIsBGPickerDisabled(false);
            return;
        }
        if (props.businessGroupingToEdit.parent_id) {
            setBusinessGroupings(setSelectedNodes(props.userBusinessGroupings, [props.businessGroupingToEdit?.parent_id]));
        } else if (props.businessGroupingToEdit.type === props.businessGroupingTypes.find((type) => type.name === 'default')?.id) {
            setIsBGPickerDisabled(true);
        }
    }, [props.userBusinessGroupings, props.businessGroupingToEdit]);

    useEffect(() => {
        if (isDirty) {
            props.setIsError(isValid || isDirty);
        }
    }, [isValid, isDirty]);

    useEffect(() => {
        if (isDirty) {
            control._formValues.business_grouping_owner &&
                setValue(
                    'business_grouping_users',
                    [
                        ...new Set([
                            ...control._formValues.business_grouping_users.filter(
                                (user: IUser) => user.id !== control._formValues.business_grouping_owner.id
                            ),
                            control._formValues.business_grouping_owner,
                        ]),
                    ],
                    { shouldDirty: false }
                );
        }
    }, [isDirty]);

    function getBusinessGroupingMenuitemStyles(id: any, data: any[], theme: Theme) {
        return {
            fontWeight: data.filter((item) => item.id === id).length < 1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
        };
    }

    const onChangeNodes = (nodes: IBusinessGroupingNode[], onChange: (...event: any[]) => void) => {
        if (nodes.length === 0) {
            onChange(null);
        } else {
            onChange(nodes);
        }
    };

    return (
        <Grid2 spacing={2} container direction={'row'}>
            <Grid2 size={6}>
                <FormField label='Business Grouping Name' htmlFor='business_grouping_name'>
                    <Controller
                        name={'business_grouping_name'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'business_grouping_name'}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                                fullWidth={true}
                                error={!!errors.business_grouping_name}
                                helperText={errors.business_grouping_name?.message as string}
                                size='small'
                                placeholder={'Create a name'}
                                value={value}
                            />
                        )}
                    />
                </FormField>
            </Grid2>
            <Grid2 size={6}>
                <FormField label='Type' htmlFor='business_grouping_type'>
                    <>
                        <Controller
                            name={'business_grouping_type'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    name={'business_grouping_type'}
                                    error={!!errors.business_grouping_type}
                                    size={'small'}
                                    labelId='business_grouping_type-label'
                                    id='business_grouping_type'
                                    fullWidth={true}
                                    slotProps={{ input: { placeholder: 'Select a type' } }}
                                    disabled={
                                        props.businessGroupingToEdit?.type ===
                                        (props.businessGroupingTypes.find((type) => type.name === 'default')?.id ?? 0)
                                    }
                                    value={value}
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        onChange(e.target.value as number);
                                    }}
                                >
                                    {props.businessGroupingToEdit?.type ===
                                    (props.businessGroupingTypes.find((type) => type.name === 'default')?.id ?? 0)
                                        ? props.businessGroupingTypes
                                              .filter((type) => type.name === 'default')
                                              .map((businessGroupingType) => (
                                                  <MenuItem
                                                      style={getBusinessGroupingMenuitemStyles(
                                                          businessGroupingType.id,
                                                          props.businessGroupingTypes,
                                                          theme
                                                      )}
                                                      key={'business_account_type_' + businessGroupingType.id}
                                                      value={businessGroupingType.id}
                                                  >
                                                      {businessGroupingType.name}
                                                  </MenuItem>
                                              ))
                                        : props.businessGroupingTypes
                                              .filter((type) => type.name !== 'default')
                                              .sort(function (a, b) {
                                                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                                  return 0;
                                              })
                                              .map((businessGroupingType) => (
                                                  <MenuItem
                                                      style={getBusinessGroupingMenuitemStyles(
                                                          businessGroupingType.id,
                                                          props.businessGroupingTypes,
                                                          theme
                                                      )}
                                                      key={'business_account_type_' + businessGroupingType.id}
                                                      value={businessGroupingType.id}
                                                  >
                                                      {businessGroupingType.name}
                                                  </MenuItem>
                                              ))}
                                </Select>
                            )}
                        />
                        <FormHelperText>
                            <Typography variant={'caption'} color={theme.palette.error.main}>
                                {errors.business_grouping_type?.message}
                            </Typography>
                        </FormHelperText>
                    </>
                </FormField>
            </Grid2>
            <Grid2 size={6}>
                <FormField label='Parent' htmlFor='business_grouping_parent'>
                    <Controller
                        name={'business_grouping_parent'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <BusinessGroupingPicker
                                mode='radioSelect'
                                data={businessGroupings}
                                setData={setBusinessGroupings}
                                changeHandler={(nodes) => {
                                    onChangeNodes(nodes, onChange);
                                }}
                                hasError={businessGroupingParentHasErrors}
                                disabled={isBGPickerDisabled}
                            />
                        )}
                    />
                </FormField>
                <FormHelperText>
                    <Typography variant={'caption'} color={theme.palette.error.main}>
                        {errors.business_grouping_parent?.message}
                    </Typography>
                </FormHelperText>
            </Grid2>

            <Grid2 size={6}>
                <BusinessGroupingOwnerFormField
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    register={register}
                    users={users}
                    isDisabled={!!props.businessGroupingToEdit}
                    handleSubmit={handleSubmit}
                />
            </Grid2>

            <Grid2 size={12}>
                <FormField label='Description' htmlFor='business_grouping_description'>
                    <Controller
                        name={'business_grouping_description'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                fullWidth={true}
                                id='business_grouping_description'
                                placeholder='Write a description'
                                error={!!errors.business_grouping_description}
                                multiline
                                minRows={4}
                                value={value}
                                helperText={errors.business_grouping_description?.message as string}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                }}
                            />
                        )}
                    />
                </FormField>
            </Grid2>
        </Grid2>
    );
};

export { BusinessGroupingWizardDetailsTab };
