import React from 'react';
import { useCommonStyles } from '@vegaplatformui/styling';
import { Grid2 } from '@mui/material';
import { FormControl, Stack, Typography } from '@mui/material';
import { ContainerFormTable } from './container-form-table';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { CloudProviderIcons } from '../utilities/logo-selector';
import { ContainerType, IResource, IVegaContainer } from '@vegaplatformui/models';
import { CreateContainerForm } from '../spaces/spaces-landing/spaces-landing';
import { FormField } from '../forms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourcePoolTableFormFieldProps {
    containerToEditForm: CreateContainerForm;
    children: IResource[];
    setSelectedChildren: React.Dispatch<React.SetStateAction<IResource[]>>;
    childrenTableName: string;
    containerToEdit?: IVegaContainer;
    isResourcesLoading: boolean;
    selectedChildren: IResource[] | undefined;
}

const ChildrenPoolTableFormField: React.FC<IResourcePoolTableFormFieldProps> = (props) => {
    const commonStyles = useCommonStyles();

    return (
        <Grid2 size={12}>
            <FormField label={`${props.childrenTableName}`} htmlFor={'childrenTable'}>
                <Stack direction={'column'} spacing={0.5}>
                    <Typography className={commonStyles.classes.CaptionText} variant={'caption'}>
                        {props.containerToEditForm.containerType === ContainerType.Space
                            ? 'Select workloads to add to your space. You can skip this step and add workloads at a later time.'
                            : props.containerToEditForm.containerType === ContainerType.Workload
                              ? 'Select resource pools to add to your workload. You can skip this step and add resource pools at a later time.'
                              : 'Select resources to add to your resource pool. You can skip this step and add resources at a later time.'}
                    </Typography>
                    <FormControl id={'childrenTable'} fullWidth>
                        <ContainerFormTable
                            selectedChildren={props.selectedChildren}
                            columns={[
                                {
                                    field: 'resource_id',
                                    headerName: 'Resource ID',
                                    flex: 1,
                                },
                                //TODO I could see this being an issue since it was only one field. I won't change it since it has not been requested but keeping this here when that request might happen.
                                //TODO I have checked as of adding this in, it filtered and sorted just fine (for each field). I am just wondering/can't remember if it was request to have fewer fields in this table
                                // {
                                //     field: 'provider_str',
                                //     headerName: 'Provider',
                                //     flex: 0.5,
                                //     renderCell: (params: GridRenderCellParams<IResource>) => (
                                //         <CloudProviderIcons cloudProviders={[params.row?.provider_str]} />
                                //     ),
                                // },
                                // {
                                //     field: 'cloud_account_id',
                                //     headerName: 'Cloud Account',
                                //     flex: 1,
                                // },
                                //TODO Current way that the cloud account/provider is being displayed
                                {
                                    field: 'provider_str',
                                    headerName: 'Cloud Account',
                                    flex: 1,
                                    renderCell: (params: GridRenderCellParams<IResource>) => (
                                        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                            <CloudProviderIcons cloudProviders={[params.row?.provider_str]} />
                                            {params.row.cloud_account_id}
                                        </Stack>
                                    ),
                                },
                                {
                                    field: 'type_str',
                                    headerName: 'Instance Type',
                                    flex: 1,
                                },
                                /*                 {
                                    field: 'cost',
                                    headerName: 'Cost',
                                    flex: 1,
                                    valueFormatter: (params: GridValueFormatterParams) => FormatNumberUSDHundredth(params.value),
                                },*/
                                // {
                                //     field: 'budget',
                                //     headerName: 'Budget',
                                //     flex: 1,
                                // },
                            ]}
                            rows={props.children}
                            setSelectedChildren={props.setSelectedChildren}
                            isLoading={props.isResourcesLoading}
                            containerToEdit={props.containerToEdit}
                        />
                    </FormControl>
                </Stack>
            </FormField>
        </Grid2>
    );
};

export { ChildrenPoolTableFormField };
