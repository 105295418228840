import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { IParkingSchedule } from '@vegaplatformui/models';
import { scheduleTarget } from 'nx/src/adapter/ngcli-adapter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmCloseDrawerProps {
    show: boolean;
    onClose: () => void;
    onSave: () => void;
    onDontSave: () => void;
    scheduleToEdit?: IParkingSchedule;
    saveIsDisabled: boolean;
}

const ConfirmCloseDrawer: React.FC<IConfirmCloseDrawerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>
                <Stack direction={'column'} spacing={0}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Typography fontWeight={500}>
                            Do you want to save the changes made{props.scheduleToEdit ? ` to ${props.scheduleToEdit.name}?` : '?'}
                        </Typography>
                        <IconButton
                            aria-label={'Close schedule confirmation and return to editing the schedule.'}
                            size={'small'}
                            onClick={props.onClose}
                        >
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Your changes will be lost if you do not save them.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Saving will {props.scheduleToEdit ? 'submit the changes.' : 'create the schedule.'}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1.5} width={'100%'}>
                    <Button variant={'cancel'} onClick={props.onDontSave} autoFocus>
                        Don't Save
                    </Button>
                    <Button disabled={props.saveIsDisabled} onClick={() => props.onSave()}>
                        Save
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmCloseDrawerProps>()((theme, props) => ({
    CancelButton: { marginRight: '-1rem' },
}));

export { ConfirmCloseDrawer };
