import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Menu, MenuItem } from '@mui/material';
import { IParkingPriorityGroup, IResource } from '@vegaplatformui/models';
import { PriorityGroupSelectorDialog } from './priority-group-selector-dialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAdvancedParkingResourcesTabActionMenuProps {
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    selectedResources: IResource[];
    onDeleteResources: (resource: IResource[]) => void;
    resources: IResource[];
    priorityGroups: IParkingPriorityGroup[];
    setPriorityGroups: React.Dispatch<React.SetStateAction<IParkingPriorityGroup[]>>;
    onClearSelectedResources: () => void;
}

const ResourcesTabActionMenu: React.FC<IAdvancedParkingResourcesTabActionMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isPriorityGroupSelectorDialogOpen, setIsPriorityGroupSelectorDialogOpen] = React.useState(false);

    const handleClose = () => {
        props.setAnchorEl(null);
    };

    const onClosePriorityGroupSelectorDialod = () => {
        setIsPriorityGroupSelectorDialogOpen(false);
    };

    return (
        <>
            <PriorityGroupSelectorDialog
                onClearSelectedResources={props.onClearSelectedResources}
                priorityGroups={props.priorityGroups}
                setPriorityGroups={props.setPriorityGroups}
                selectedResources={props.selectedResources}
                isOpen={isPriorityGroupSelectorDialogOpen}
                onClose={onClosePriorityGroupSelectorDialod}
            />
            <Menu
                id='parking-schedule-table-menu'
                anchorEl={props.anchorEl}
                open={Boolean(props.anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {props.priorityGroups.length > 1 && (
                    <MenuItem
                        onClick={(event) => {
                            setIsPriorityGroupSelectorDialogOpen(true);
                            handleClose();
                        }}
                    >
                        Assign to priority group
                    </MenuItem>
                )}
                <MenuItem
                    onClick={(event) => {
                        props.selectedResources && props.selectedResources.length > 0 && props.onDeleteResources(props.selectedResources);
                        handleClose();
                    }}
                >
                    Remove resources
                </MenuItem>
            </Menu>
        </>
    );
};

const useStyles = customMakeStyles<IAdvancedParkingResourcesTabActionMenuProps>()((theme, props) => ({}));

export { ResourcesTabActionMenu };
