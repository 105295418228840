import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import IconButton from '@mui/material/IconButton';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { IResource, RecommendationActionType } from '@vegaplatformui/models';
import { Link, useNavigate } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ResourceIdToRefresh, SnackBarOptions } from '../../jotai/atom';
import { useRecommendationsApi } from '../../api-hooks/use-recommendations-api';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourcesTableActionsMenuProps {
    resource: IResource;
    selectedResources: IResource[];
    isLoading: boolean;
}

const ResourcesTableActionsMenu: React.FC<IResourcesTableActionsMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOptionMenuOpen = Boolean(anchorEl);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState<boolean>(props.isLoading);
    const [isRunning, setIsRunning] = React.useState<boolean>(props.resource?.is_running || false);
    const { vOperate } = useFlags();
    const setResourceIdToRefresh = useSetAtom(ResourceIdToRefresh);
    const { takeImmediateAction } = useRecommendationsApi({ setIsRunning: setIsRunning, setIsLoading: setIsLoading });

    const onClickParkingToggle = () => {
        setIsLoading(true);
        if (!isRunning) {
            takeImmediateAction({ action: RecommendationActionType.unpark, resourceId: props.resource.id });
        } else {
            takeImmediateAction({ action: RecommendationActionType.park, resourceId: props.resource.id });
        }
    };

    const onClickRefreshResource = (resource: IResource) => {
        setResourceIdToRefresh(resource.id);
    };

    const handleTogglDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <StyledToolTip title={'Options'}>
                <IconButton disabled={props.selectedResources.length > 1} onClick={handleTogglDropDown} size='small'>
                    <MoreHoriz />
                </IconButton>
            </StyledToolTip>
            <Menu
                id='parking-schedule-table-menu'
                anchorEl={anchorEl}
                open={isOptionMenuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                /*        onClick={() => {
                        navigate(`/resource/${props.resource.id}`);
                        handleClose();
                    }}*/
                >
                    <Link style={{ color: 'black', textDecoration: 'none' }} to={`/resource/${props.resource.id}`}>
                        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                            <Typography variant={'body2'} color='inherit'>
                                Details
                            </Typography>
                        </Stack>
                    </Link>
                </MenuItem>
                {vOperate && props.resource.is_parking_capable && (
                    <MenuItem
                        onClick={() => {
                            onClickParkingToggle();
                            handleClose();
                        }}
                    >
                        {isRunning ? 'Stop' : 'Start'}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        onClickRefreshResource(props.resource);
                        handleClose();
                    }}
                >
                    Refresh
                </MenuItem>
            </Menu>
        </>
    );
};

const useStyles = customMakeStyles<IResourcesTableActionsMenuProps>()((theme, props) => ({}));

export { ResourcesTableActionsMenu };
