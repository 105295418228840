import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Dialog, DialogProps, Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomDraggableDialogProps extends DialogProps {}

const CustomDraggableDialog: React.FC<ICustomDraggableDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    function PaperComponent(props: PaperProps) {
        const nodeRef = React.useRef(null);
        return (
            <Draggable nodeRef={nodeRef} handle={`#${props['aria-labelledby']}`} cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper ref={nodeRef} {...props} />
            </Draggable>
        );
    }

    return <Dialog {...props} PaperComponent={PaperComponent} />;
};

const useStyles = customMakeStyles<ICustomDraggableDialogProps>()((theme, props) => ({}));

export { CustomDraggableDialog };
