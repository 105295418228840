import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ISSORoleMapping, ISSORoleMappingForm, IUserSettingRealmRole } from '@vegaplatformui/models';
import { RoleMappingItem } from '../role-mapping-item';
import { OrganizationId } from '../../../../jotai/atom';
import { useAtomValue } from 'jotai/index';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateEditRoleMappingDialogProps {
    roleMappingToEdit?: ISSORoleMapping;
    roleMappings: ISSORoleMapping[];
    isDialogOpen: boolean;
    onCloseDialog: () => void;
    roles: IUserSettingRealmRole[];
    onSaveRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onCreateRoleMapping: (roleMapping: ISSORoleMapping) => void;
}

const SSORoleMappingValidationSchema: yup.ObjectSchema<ISSORoleMappingForm> = yup.object({
    externalGroupName: yup
        .string()
        .required('Group name is required')
        .test('not-empty', 'Group name cannot be empty', (value) => value !== '' && !!value?.match(/\S{1}/i))
        .test('no-spaces', 'Group name must not contain a space', (value) => !value?.match('(.*?)\\s(.*?)'))
        .test('is-unique', 'Group name must be unique', function (value) {
            if (!value) return true; // Skip validation if no value

            // Get the current context to access other values
            const { roleMappings, roleMappingToEdit } = this.options.context || {};

            // If we don't have access to roleMappings, skip this validation
            if (!roleMappings) return true;

            // Check if the group name is unique among existing mappings
            // Exclude the current mapping being edited from the check
            return !roleMappings.some(
                (mapping: ISSORoleMapping) => mapping.externalGroupName === value && roleMappingToEdit?.externalGroupName !== value
            );
        }),
    roleAssignments: yup
        .array()
        .of(
            yup.object({
                orgId: yup.string().required('Organization ID is required'),
                roleId: yup.string().required('Role ID is required'),
            })
        )
        .required('At least one role assignment is required'),
});

const CreateEditRoleMappingDialog: React.FC<ICreateEditRoleMappingDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const organizationId = useAtomValue(OrganizationId);
    const BlankRoleMapping: ISSORoleMapping = {
        roleAssignments: [
            {
                roleId: props.roles.find((role) => role.name === 'read_only') ? props.roles.find((role) => role.name === 'read_only')!.id : '',
                orgId: organizationId,
            },
        ],
        externalGroupName: '',
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<ISSORoleMappingForm>({
        resolver: yupResolver(SSORoleMappingValidationSchema),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        context: {
            roleMappings: props.roleMappings,
            roleMappingToEdit: props.roleMappingToEdit,
        },
        defaultValues: props.roleMappingToEdit
            ? {
                  roleAssignments:
                      props.roleMappingToEdit && props.roleMappingToEdit.roleAssignments.length
                          ? props.roleMappingToEdit.roleAssignments
                          : props.roles.find((role) => role.name === 'read_only')
                            ? [
                                  {
                                      roleId: props.roles.find((role) => role.name === 'read_only')!.id,
                                      orgId: organizationId,
                                  },
                              ]
                            : [],
                  externalGroupName:
                      props.roleMappingToEdit && props.roleMappingToEdit.externalGroupName ? props.roleMappingToEdit.externalGroupName : '',
              }
            : BlankRoleMapping,
    });

    useEffect(() => {
        if (props.roleMappingToEdit !== undefined) {
            reset(
                {
                    roleAssignments:
                        props.roleMappingToEdit && props.roleMappingToEdit.roleAssignments.length
                            ? props.roleMappingToEdit.roleAssignments
                            : [
                                  {
                                      roleId: props.roles.find((role) => role.name === 'read_only')
                                          ? props.roles.find((role) => role.name === 'read_only')!.id
                                          : '',
                                      orgId: organizationId,
                                  },
                              ],
                    externalGroupName:
                        props.roleMappingToEdit && props.roleMappingToEdit.externalGroupName ? props.roleMappingToEdit.externalGroupName : '',
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [props.roleMappingToEdit, props.roles]);

    const onCloseDialogAndReset = () => {
        props.onCloseDialog();
        setTimeout(() => {
            reset({});
        }, 200);
    };

    const onSaveOrCreateRoleMapping = (roleMappingData: ISSORoleMappingForm) => {
        switch (props.roleMappingToEdit) {
            case undefined:
                props.onCreateRoleMapping(roleMappingData);
                break;
            default:
                props.onSaveRoleMapping({
                    ...props.roleMappingToEdit,
                    externalGroupName: roleMappingData.externalGroupName,
                    roleAssignments: roleMappingData.roleAssignments,
                });
                break;
        }
        props.onCloseDialog();
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({}, { keepValues: true });
        }
    }, [isSubmitSuccessful]);
    return (
        <Dialog maxWidth={'sm'} fullWidth open={props.isDialogOpen} onClose={onCloseDialogAndReset} aria-labelledby='create-edit-role-mapping-dialog'>
            <DialogTitle sx={{ marginLeft: '-0.2rem' }} variant={'h6'} id='create-edit-role-mapping-dialog-title'>
                {props.roleMappingToEdit ? 'Edit' : 'Create'} SSO Role Mapping
            </DialogTitle>
            <DialogContent sx={{ paddingLeft: 2.5 }}>
                <RoleMappingItem
                    handleSubmit={handleSubmit}
                    control={control}
                    roles={props.roles}
                    errors={errors}
                    onSaveOrCreateRoleMapping={onSaveOrCreateRoleMapping}
                />
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Stack direction='row' spacing={1}>
                    <Button
                        variant={'cancel'}
                        onClick={() => {
                            onCloseDialogAndReset();
                        }}
                        disableElevation
                    >
                        Cancel
                    </Button>
                    <Button form={'role-mapping-form'} type='submit' disableElevation>
                        {props.roleMappingToEdit ? 'Save' : 'Create'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<ICreateEditRoleMappingDialogProps>()((theme, props) => ({
    DialogActions: {
        marginRight: '1rem',
        marginBottom: '0.5rem',
    },
    DialogActionsButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 10,
    },
    FormTitle: {
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
}));

export { CreateEditRoleMappingDialog };
