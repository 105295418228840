import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Stack, TextField } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesConditionKeyPairValueProps {
    keyValue: string;
    value: string;
    onChangeValue: (value: string) => void;
    isMissingValues: boolean;
}

const AssignmentRulesConditionKeyPairValue: React.FC<IAssignmentRulesConditionKeyPairValueProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Stack spacing={1} direction={'row'}>
            <TextField
                error={(props.isMissingValues && props.keyValue === '') || props.keyValue === undefined}
                onChange={(e) => props.onChangeValue(JSON.stringify({ key: e.target.value, value: props.value }))}
                value={props.keyValue}
                id='key'
                label={'Key'}
                size='small'
                fullWidth={true}
            />
            <TextField
                error={props.isMissingValues && props.value === ''}
                onChange={(e) => props.onChangeValue(JSON.stringify({ key: props.keyValue, value: e.target.value }))}
                value={props.value}
                id='value'
                label={'Value'}
                size='small'
                fullWidth={true}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<IAssignmentRulesConditionKeyPairValueProps>()((theme, props) => ({}));

export { AssignmentRulesConditionKeyPairValue };
