import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { customMakeStyles } from '@vegaplatformui/styling';

export interface ISearchBudgetsProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

const SearchBudgets: React.FC<ISearchBudgetsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { value, onChange, placeholder = 'Search budgets...' } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <TextField
            fullWidth
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            variant='outlined'
            size='medium'
            className={cx(classes.SearchField)}
            slotProps={{
                input: {
                    className: cx(classes.SearchInput),
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon color='action' className={cx(classes.SearchIcon)} />
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
};

const useStyles = customMakeStyles<ISearchBudgetsProps>()((theme) => ({
    SearchField: {
        width: '100%',
    },
    SearchInput: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    SearchIcon: {
        color: theme.palette.action.active,
    },
}));

export { SearchBudgets };
