import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { IUser } from '@vegaplatformui/models';
import { SubmitHandler } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserDeleteDialogV2Props {
    users: IUser[];
    isOpen: boolean;
    onClose: () => void;
    onDelete: (data: IUser[]) => void;
}

const UserDeleteDialogV2: React.FC<IUserDeleteDialogV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const { users, isOpen, onClose, onDelete } = props;

    const onSubmit: SubmitHandler<IUser[]> = (data: IUser[]) => {
        onDelete(data);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth
            aria-labelledby='delete-config-dialog-title'
            aria-describedby='delete-config-form'
        >
            <DialogTitle className={cx(classes.DialogTitle)} id='delete-config-dialog-title'>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ErrorOutline color={'error'} />
                    <Typography className={cx(classes.TitleTypography)} variant={'h5'}>
                        Delete{users.length > 1 ? ` ${users.length}  Users` : ' User'}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(classes.DialogContent)} id={'delete-config-form'}>
                <Typography variant='body2'>
                    Are you sure you want to delete {users.length > 1 ? 'these users' : 'this user'}? This action cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Button variant='cancel' onClick={onClose}>
                    Cancel
                </Button>
                <Button color={'error'} variant='contained' onClick={() => onSubmit(users)}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IUserDeleteDialogV2Props>()((theme, props) => ({
    DialogActions: {
        paddingRight: '1.5rem',
        marginBottom: '1rem',
    },
    DialogTitle: { paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1.5rem' },
    TitleTypography: { fontWeight: 500 },
    DialogContent: { paddingRight: '1.5rem', paddingLeft: '1.5rem' },
    DialogStartButton: {
        marginLeft: '0.5rem',
    },
}));

export { UserDeleteDialogV2 };
