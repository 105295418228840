import React, { StrictMode } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useSetRecoilState } from 'recoil';
import { SnackbarErrorOutput, SnackBarOptions } from '@vegaplatformui/sharedcomponents';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { maintenancePageHtml } from '../components/maintenance/maintenance-page';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import UserDataLoadingHandler from './user-data-loading-handler';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAppLoadingHandlerProps {}

const AppLoadingHandler: React.FC<IAppLoadingHandlerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const flags = useFlags();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
            },
            mutations: {
                retry: false,
            },
        },
        queryCache: new QueryCache({
            onError: (error, query) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `${query?.meta?.errorMessage ?? 'Something went wrong'}: ${SnackbarErrorOutput(error ?? '')}`,
                });
            },
        }),
    });

    function Application() {
        return (
            <QueryClientProvider client={queryClient}>
                <StrictMode>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <BrowserRouter>
                            <UserDataLoadingHandler />
                        </BrowserRouter>
                    </LocalizationProvider>
                </StrictMode>
                {process.env.NX_REACT_APP_HIDE_DEV_TOOLS ? null : <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        );
    }

    function Maintenance() {
        return <div dangerouslySetInnerHTML={{ __html: maintenancePageHtml }} />;
    }

    return flags?.maintenance ? <Maintenance /> : <Application />;
};

const useStyles = customMakeStyles<IAppLoadingHandlerProps>()((theme, props) => ({}));

export { AppLoadingHandler };
