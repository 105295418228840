import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, FormHelperText, Grid, Stack, TextField, Typography } from '@mui/material';
import { FormField } from '../../../forms';
import { Control, Controller, DeepRequired, FieldErrorsImpl, GlobalError, UseFormSetValue } from 'react-hook-form';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { IUserSettingSSOSamlForm } from '@vegaplatformui/models';
import Box from '@mui/material/Box';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISamlUriUrlCardProps {
    errors: Partial<FieldErrorsImpl<DeepRequired<IUserSettingSSOSamlForm>>> & { root?: Record<string, GlobalError> & GlobalError };
    setValue: UseFormSetValue<IUserSettingSSOSamlForm>;
    control: Control<IUserSettingSSOSamlForm, any>;
}

const SamlUriUrlCard: React.FC<ISamlUriUrlCardProps> = (props) => {
    const { errors, setValue, control } = props;
    const { classes, cx } = useStyles(props);

    function PlaceHolderRender(): EmotionJSX.Element {
        return (
            <Grid xs={12} item container direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                <Grid xs={10} item>
                    <FormField
                        label='Don’t have everything yet?'
                        htmlFor='config.idp_entity_id'
                        children={
                            <Box className={cx(classes.TypographyBox)}>
                                <Typography variant={'caption'} className={cx(classes.SubtitleColor)}>
                                    Use our placeholder values if your Identity Provider requires more info before generating an Issuer URI and an
                                    Identity Provider Single Sign-On URL. After setting up the Identity Provider config initially, you’ll be prompted
                                    to go back and update these fields.
                                </Typography>
                            </Box>
                        }
                    />
                </Grid>
                <Grid xs={10} item>
                    <Button
                        variant={'outlined'}
                        size={'small'}
                        onClick={() => {
                            setValue('config.idp_entity_id', 'urn:idp:default', { shouldDirty: true, shouldValidate: true });
                            setValue('config.single_sign_on_service_url', 'https://localhost', { shouldDirty: true, shouldValidate: true });
                        }}
                    >
                        Fill With Placeholder Values
                    </Button>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container direction={'row'} justifyContent={'space-between'} spacing={10}>
            <Grid xs={7} spacing={4} item container direction={'row'}>
                <Grid item xs={12}>
                    <FormField label='Issuer URI' htmlFor='config.idp_entity_id'>
                        <Stack spacing={0.5}>
                            <FormHelperText className={cx(classes.FormHelperConfigText)}>
                                Identifier for the issuer of the SAML assertion
                            </FormHelperText>
                            <Controller
                                name={'config.idp_entity_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='config.idp_entity_id'
                                        error={!!errors.config?.idp_entity_id}
                                        value={value}
                                        size={'small'}
                                        helperText={errors.config?.idp_entity_id?.message as string}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </FormField>
                </Grid>
                <Grid item xs={12}>
                    <FormField label='Single sign-on URL' htmlFor='config.single_sign_on_service_url'>
                        <Stack spacing={0.5}>
                            <FormHelperText className={cx(classes.FormHelperConfigText)}>URL of the receiver of the SAML AuthNRequest</FormHelperText>
                            <Controller
                                name={'config.single_sign_on_service_url'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='config.single_sign_on_service_url'
                                        error={!!errors.config?.single_sign_on_service_url}
                                        value={value}
                                        size={'small'}
                                        helperText={errors.config?.single_sign_on_service_url?.message as string}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </FormField>
                </Grid>
            </Grid>
            <Grid xs={5} item container>
                <PlaceHolderRender />
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ISamlUriUrlCardProps>()((theme, props) => ({
    CardColor: {
        backgroundColor: theme.palette.grey[50],
        width: '100%',
    },
    TypographyBox: { maxWidth: '19rem' },
    SubtitleColor: {
        color: theme.palette.grey[600],
    },
    PlaceHolderButton: { marginTop: '-3.5em' },
    FormHelperConfigText: { marginTop: '-1rem' },
}));

export { SamlUriUrlCard };
