import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import {
    DataGridPremium,
    GridColDef,
    GridGroupNode,
    GridRenderCellParams,
    GridRowParams,
    useGridApiRef,
    useGridSelector,
} from '@mui/x-data-grid-premium';
import { GridActionsCellItem, gridFilteredDescendantCountLookupSelector, GridRowId, GridSlotsComponent } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { CloudProviderIcons } from '../utilities/logo-selector';
import { ContainerType, IVegaContainer } from '@vegaplatformui/models';
import { ContainerTypeFormatter } from '../utilities/container-type-formatter';
import { FormatNumberUSDHundredth } from '../utilities/value-formatter-methods';
import { DataGridCustomToolbar } from '../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { CustomGridColStringOperatorDef } from '../utilities/custom-grid-col-string-operator-def';
import { GetVegaContainerDescendantCount } from '../utilities/get-vega-container-descendent-count';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';
import { StyledToolTip } from '../utilities/styled-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourcePoolDetailTableProps {
    spaces: IVegaContainer[];
    onClickEdit: (space: IVegaContainer) => void;
    isLoading: boolean;
    onClickTableItem: (container: IVegaContainer[], containerType?: ContainerType) => void;
}

//ToDo pretty sure this can be a common table for all entities with some more logic added
const ResourcePoolDetailTable: React.FC<IResourcePoolDetailTableProps> = (props) => {
    const localStyles = useStyles(props);
    const commonHeaderStyles = useCommonStyles();
    const apiRef = useGridApiRef();
    const resourcePoolsDetailTableUtilities = useTableUtilities(tableIdentifierKeys.resourcePoolsDetailTable);
    const expansionLookup = React.useRef<Record<GridRowId, boolean | undefined>>({});

    React.useEffect(() => {
        resourcePoolsDetailTableUtilities.registerTableControl({ name: false, budget: false });
    }, []);

    const RenderDescendentCount = (props: GridRenderCellParams<IVegaContainer>) => {
        const { id, field, rowNode } = props;

        const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
        const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
        return filteredDescendantCount !== 0 ? filteredDescendantCount : '';
    };

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Name',
            filterable: false,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'container_type',
            headerName: 'Type',
            flex: 1,
            valueGetter: (value: string, row: IVegaContainer) => ContainerTypeFormatter(row?.container_type),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'provider',
            headerName: 'Providers With Resources',
            flex: 1,
            headerClassName: commonHeaderStyles.cx(commonHeaderStyles.classes.AggregatedHeader),
            valueFormatter: (value: string[], row: IVegaContainer) => {
                return value.join(',');
            },
            renderCell: (params: GridRenderCellParams) => {
                if (params.aggregation && params.row?.container_type !== ContainerType.Resource) {
                    return params.formattedValue;
                } else {
                    return <CloudProviderIcons cloudProviders={params.row?.provider} />;
                }
            },
        },
        /*        {
            field: 'cost',
            headerName: 'Cost',
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            valueFormatter: (params: GridValueFormatterParams) => FormatNumberUSDHundredth(params.value),
        },*/
        {
            ...CustomGridColStringOperatorDef,
            field: 'budget',
            headerName: 'Budget',
            flex: 1,
            filterable: false,
            valueFormatter: (value: number, row: IVegaContainer) => FormatNumberUSDHundredth(value),
        },
        /*        {
            field: 'itemsExceededBudget',
            headerName: 'Items Exceeding Budget',
            flex: 1,
        },*/
        // {
        //     field: 'nested',
        //     headerName: 'Nested',
        //     flex: 1,
        //     renderCell: (params: GridRenderCellParams<IVegaContainer>) => {
        //         return RenderDescendentCount(params);
        //     },
        // },
        {
            ...CustomGridColStringOperatorDef,
            disableExport: true,
            field: 'actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 1,
            type: 'actions',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            getActions: (params: GridRowParams<IVegaContainer>) => [
                <StyledToolTip arrow title='Edit'>
                    <GridActionsCellItem
                        icon={<Edit />}
                        label={'Edit'}
                        aria-label={`Edit ${params.row.name}`}
                        onClick={() => props.onClickEdit(params.row)}
                        size='small'
                    />
                </StyledToolTip>,
            ],
        },
    ];

    React.useEffect(() => {
        apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
            expansionLookup.current[node.id] = node.childrenExpanded;
        });
    }, [apiRef]);

    const isGroupExpandedByDefault = React.useCallback(
        (node: GridGroupNode) => {
            return !!expansionLookup.current[node.id];
        },
        [expansionLookup]
    );

    const hiddenFields = ['name', 'budget', 'actions'];

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);
    };

    return (
        <Box>
            <DataGridPremium
                defaultGroupingExpansionDepth={1}
                apiRef={apiRef}
                slotProps={{
                    columnsManagement: { getTogglableColumns },
                    toolbar: {
                        tableIdentifier: tableIdentifierKeys.resourcePoolsDetailTable,
                        defaultHidden: { name: false, budget: false },
                        printOptions: { fields: ['name', 'container_type', 'provider', 'cost'] },
                        csvOptions: { fields: ['name', 'container_type', 'provider', 'cost'] },
                        excelOptions: { fields: ['name', 'container_type', 'provider', 'cost'] },
                    },
                    pagination: {
                        page: resourcePoolsDetailTableUtilities.currentTableControl?.paginationModel?.page,
                        onPageChange: resourcePoolsDetailTableUtilities.onPaginationModelPageChange,
                        count: resourcePoolsDetailTableUtilities.currentTableControl?.totalRows,
                        rowsPerPage: resourcePoolsDetailTableUtilities.currentTableControl?.paginationModel?.pageSize,
                    },
                }}
                groupingColDef={{
                    ...CustomGridColStringOperatorDef,
                    disableColumnMenu: false,
                    filterable: true,
                    headerName: 'Name',
                    disableExport: true,
                    hideDescendantCount: true,
                    sortable: true,
                    flex: 2,
                    valueGetter: (value: string, row: IVegaContainer) => {
                        return row.name;
                    },
                    valueFormatter: (value, row: IVegaContainer) => {
                        return (
                            <GetVegaContainerDescendantCount
                                row={row}
                                apiRef={apiRef}
                                spaces={props.spaces}
                                onClickTableItem={props.onClickTableItem}
                            />
                        );
                    },
                }}
                treeData
                getTreeDataPath={(row) => {
                    const [, , ...excludeRoot] = row.path.split('/');
                    return excludeRoot;
                }}
                autoHeight={true}
                columns={columns}
                rows={props.spaces}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                }}
                columnVisibilityModel={{
                    ...resourcePoolsDetailTableUtilities.currentTableControl?.columnModel,
                    name: false,
                    budget: false,
                }}
                density={resourcePoolsDetailTableUtilities.currentTableControl?.density}
                onDensityChange={resourcePoolsDetailTableUtilities.onDensityChange}
                onColumnVisibilityModelChange={resourcePoolsDetailTableUtilities.onColumnVisibilityModelChange}
                filterModel={resourcePoolsDetailTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={resourcePoolsDetailTableUtilities.onFilterModelChange}
                paginationModel={resourcePoolsDetailTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={resourcePoolsDetailTableUtilities.onPaginationModelChange}
                loading={props.isLoading}
                isGroupExpandedByDefault={isGroupExpandedByDefault}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IResourcePoolDetailTableProps>()((theme, props) => ({
    ToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    ToolBarFilter: {
        color: theme.palette.grey[500],
        marginBottom: '1rem',
    },
    CloudProviderIcon: {
        width: '1.5rem',
    },
    ContainerButton: {
        textTransform: 'none',
    },
}));

export { ResourcePoolDetailTable };
