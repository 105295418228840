import React, { useEffect, useMemo } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Stack, Typography } from '@mui/material';
import { RoleDetailPermissionItem } from './role-detail-permission-item/role-detail-permission-item';
import { PermissionMetadata } from '@vegaplatformui/models';
import { RoleDetailPermissionSummary } from './role-detail-permission-summary';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleDetailPermissionsTabProps {
    permissionSets: Map<string, PermissionMetadata[]>;
    rolePermissions: string[];
}

const RoleDetailPermissionsTab: React.FC<IRoleDetailPermissionsTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { permissionSets, rolePermissions } = props;
    const [expandedPermissionList, setExpandedPermissionList] = React.useState<string[]>([]);
    const permissionSetsArray = useMemo(() => {
        return [...permissionSets.entries()].sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
    }, [permissionSets]);

    const onExpand = (permission: string) => {
        if (expandedPermissionList.includes(permission)) {
            const filtered = expandedPermissionList.filter((perm) => perm !== permission);
            setExpandedPermissionList(filtered);
        } else {
            setExpandedPermissionList((current) => [...current, permission]);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography className={cx(classes.PermissionTitle)} variant={'h6'}>
                    Permissions
                </Typography>
                <Typography className={cx(classes.PermissionSubtitle)} variant={'subtitle1'}>
                    These permissions will be applied to anyone with this role.
                </Typography>
            </Grid>
            <RoleDetailPermissionSummary rolePermissions={rolePermissions} />
            {/*TODO Wont use for now since we don't have a lot of the actions that would make sense for the separated tile view*/}
            {/*<Grid className={cx(classes.GridList)} component={'ol'} item xs={12} container justifyContent='space-between' alignItems='flex-start'>*/}
            {/*    <Grid component={'template'} container item xs={12} md={5.9}>*/}
            {/*        {permissionSetsArray.slice(0, Math.ceil([...permissionSetsArray.entries()].length / 2)).map(([target, metadata]) => (*/}
            {/*            <RoleDetailPermissionItem*/}
            {/*                key={target}*/}
            {/*                target={target}*/}
            {/*                metadata={metadata}*/}
            {/*                expandedPermissionList={expandedPermissionList}*/}
            {/*                onExpand={onExpand}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </Grid>*/}
            {/*    <Grid component={'template'} container item xs={12} md={5.9}>*/}
            {/*        {permissionSetsArray.slice(Math.ceil([...permissionSetsArray.entries()].length / 2)).map(([target, metadata]) => (*/}
            {/*            <RoleDetailPermissionItem*/}
            {/*                key={target}*/}
            {/*                target={target}*/}
            {/*                metadata={metadata}*/}
            {/*                expandedPermissionList={expandedPermissionList}*/}
            {/*                onExpand={onExpand}*/}
            {/*            />*/}
            {/*        ))}*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
        </Grid>
    );
};

const useStyles = customMakeStyles<IRoleDetailPermissionsTabProps>()((theme, props) => ({
    GridList: {
        paddingLeft: 0,
        margin: 0,
    },
    PermissionTitle: { fontWeight: 500 },
    PermissionSubtitle: { color: theme.palette.text.secondary },
}));

export { RoleDetailPermissionsTab };
