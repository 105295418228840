import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { AxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';

export class HttpClient {
    protected readonly instance: AxiosInstance;
    public keycloak!: Keycloak;

    public constructor(baseURL: string) {
        this.instance = axios.create({
            baseURL,
        });
        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(this._handleResponse, this._handleError);
    };

    protected _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this._handleRequest, this._handleError);
    };

    protected _handleRequest = (config: InternalAxiosRequestConfig<AxiosRequestConfig>) => {
        config.headers['Authorization'] = 'Bearer ' + this.keycloak.token;
        // config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';
        config.headers['Access-Control-Allow-Origin'] = 'https://local.vegacloud.io:3001';
        config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
        config.headers['Access-Control-Allow-Headers'] = '*';

        return config;
    };

    private _handleResponse = (response: AxiosResponse) => {
        return response;
    };

    protected _handleError = (error: AxiosError) => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
            return this.keycloak.updateToken(-1).then((_) => {
                error.config!.headers['Authorization'] = 'Bearer ' + this.keycloak.token;
                error.config!.baseURL = undefined;
                return this.instance.request(error.config!);
            });
        }

        return Promise.reject(error);
    };
}
