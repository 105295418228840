import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IUserSettingSSOSaml, IUserSettingSSOSamlForm, PostBinding, SignatureAlgorithm } from '@vegaplatformui/models';
import {
    Button,
    Collapse,
    FormHelperText,
    Grid2,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { ContentCopy, Download, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FormField } from '../../forms';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { regexList, ReplaceUnderscoreWithSpace } from '@vegaplatformui/utils';
import { SSODeleteDialog } from './sso-delete-dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SsoSamlFileUploadEdit } from './sso-saml/sso-saml-file-upload-edit';
import { SnackBarOptions } from '../../jotai/atom';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoSettingsListItemProps {
    ssoItem: IUserSettingSSOSaml;
    idx: number;
    expandedIdxList: number[];
    onExpand: (idx: number) => void;
    onSubmit: (data: IUserSettingSSOSamlForm) => void;
    onDelete: (alias: string) => void;
    onGenerateXMLMetaData: (url: string, filename: string) => void;
}

const validationSchema: yup.ObjectSchema<IUserSettingSSOSamlForm> = yup.object().shape({
    alias: yup.string().required('Configuration name is required'),
    display_name: yup.string().required('Description is required'),
    config: yup.object().shape({
        signing_certificate: yup.string(),
        idp_entity_id: yup.string().required('Issuer URI is required'),
        single_sign_on_service_url: yup
            .string()
            .matches(regexList.url, 'Single sign-on URL must be a valid URL')
            .required('Single sign-on URL is required'),
        post_binding_authn_request: yup
            .mixed<PostBinding>()
            .transform((v) => (!v ? undefined : v))
            .required('Request binding is required'),
        signature_algorithm: yup
            .mixed<SignatureAlgorithm>()
            .transform((v) => (!v ? undefined : v))
            .required('Response signature algorithm is required'),
    }),
});

const SsoSettingsListItem: React.FC<ISsoSettingsListItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields },
    } = useForm<IUserSettingSSOSamlForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            alias: props.ssoItem.alias ?? '',
            display_name: props.ssoItem.display_name ?? '',
            config: {
                signing_certificate: props.ssoItem.config.signing_certificate ?? '',
                idp_entity_id: props.ssoItem.config.idp_entity_id ?? '',
                single_sign_on_service_url: props.ssoItem.config.single_sign_on_service_url ?? '',
                post_binding_authn_request:
                    props.ssoItem.config.post_binding_authn_request === PostBinding.true
                        ? PostBinding.HTTP_POST
                        : props.ssoItem.config.post_binding_authn_request === PostBinding.false
                          ? PostBinding.HTTP_REDIRECT
                          : (props.ssoItem.config.post_binding_authn_request ?? ''),
                signature_algorithm:
                    props.ssoItem.config.signature_algorithm === SignatureAlgorithm.RSASHA1
                        ? SignatureAlgorithm.SHA1
                        : props.ssoItem.config.signature_algorithm === SignatureAlgorithm.RSASHA256
                          ? SignatureAlgorithm.SHA256
                          : (props.ssoItem.config.signature_algorithm ?? ''),
                idp_sso_audience_restriction: props.ssoItem.config.idp_sso_audience_restriction ?? '',
                idp_sso_redirect_url: props.ssoItem.config.idp_sso_redirect_url ?? '',
            },
        },
    });

    const onSubmit: SubmitHandler<IUserSettingSSOSamlForm> = (data: IUserSettingSSOSamlForm) => {
        props.onSubmit(data);
    };

    const handleCopy = (settingCopied: string, stringToCopy: string) => {
        navigator.clipboard
            .writeText(stringToCopy)
            .then(
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'success' },
                        message: `${settingCopied} copied to clipboard`,
                    });
                },
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'error' },
                        message: `Failed to copy ${settingCopied} to clipboard`,
                    });
                }
            )
            .catch(() => {
                alert(`Failed to copy ${settingCopied} to clipboard`);
            });
    };

    return (
        <>
            <Stack className={cx(classes.SSOItem)}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction={'column'}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography variant='subtitle1' className={cx(classes.FontWeightMedium)}>
                                    {props.ssoItem.provider_id.toUpperCase()} SSO Configuration for {props.ssoItem.display_name}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <IconButton onClick={() => props.onExpand(props.idx)}>
                        {props.expandedIdxList.includes(props.idx) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </Stack>
                <Collapse in={props.expandedIdxList.includes(props.idx)} timeout='auto' unmountOnExit>
                    <Grid2
                        id={'list-sso-item-form'}
                        component={'form'}
                        container
                        spacing={0}
                        onSubmit={handleSubmit((data) => {
                            onSubmit(data);
                        })}
                    >
                        <Grid2
                            className={cx(classes.SSOSubItemContainer, classes.SSOParentSubItemContainer, classes.SSOGraySubItemContainer)}
                            container
                        >
                            <Typography variant='subtitle2' className={cx(classes.FontWeightBold, classes.SSOSubItemHeader)}>
                                SETTINGS REQUIRED FOR THE SSO CONFIGURATION WITHIN THE VEGA PLATFORM
                            </Typography>
                            <Grid2 className={cx(classes.SSOSubItemContainer, classes.SSOWhiteSubItemContainer)} container>
                                <Grid2 container spacing={3}>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Configuration Name' htmlFor='alias'>
                                            <Controller
                                                name={'alias'}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        fullWidth={true}
                                                        id='alias'
                                                        error={!!errors.alias}
                                                        value={value}
                                                        disabled={!!props.ssoItem.alias}
                                                        size={'small'}
                                                        helperText={
                                                            (errors.alias?.message as string) ?? 'Configuration name can only be changed on creation'
                                                        }
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormField>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Request Binding' htmlFor='config.post_binding_response'>
                                            <Stack>
                                                <Controller
                                                    name={'config.post_binding_authn_request'}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select
                                                            name={'config.post_binding_authn_request'}
                                                            error={!!errors.config?.post_binding_authn_request}
                                                            size={'small'}
                                                            labelId='config.post_binding_authn_request-label'
                                                            id='config.post_binding_authn_request'
                                                            fullWidth={true}
                                                            value={value}
                                                            onChange={(e: SelectChangeEvent<PostBinding>) => {
                                                                onChange(e.target.value as PostBinding);
                                                            }}
                                                        >
                                                            <MenuItem value={PostBinding.HTTP_POST}>
                                                                {ReplaceUnderscoreWithSpace(PostBinding.HTTP_POST)}
                                                            </MenuItem>
                                                            <MenuItem value={PostBinding.HTTP_REDIRECT}>
                                                                {ReplaceUnderscoreWithSpace(PostBinding.HTTP_REDIRECT)}
                                                            </MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperText className={cx(classes.SelectHelperText)}>
                                                    {errors.config?.post_binding_authn_request?.message as string}
                                                </FormHelperText>
                                            </Stack>
                                        </FormField>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Display Name' htmlFor='display_name'>
                                            <Controller
                                                name={'display_name'}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        fullWidth={true}
                                                        id='display_name'
                                                        error={!!errors.display_name}
                                                        value={value}
                                                        size={'small'}
                                                        helperText={errors.display_name?.message as string}
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormField>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Response signature algorithm' htmlFor='config.signature_algorithm'>
                                            <Stack>
                                                <Controller
                                                    name={'config.signature_algorithm'}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select
                                                            name={'config.signature_algorithm'}
                                                            error={!!errors.config?.signature_algorithm}
                                                            size={'small'}
                                                            labelId='config.signature_algorithm-label'
                                                            id='config.signature_algorithm'
                                                            fullWidth={true}
                                                            value={value}
                                                            onChange={(e: SelectChangeEvent<SignatureAlgorithm>) => {
                                                                onChange(e.target.value as SignatureAlgorithm);
                                                            }}
                                                        >
                                                            <MenuItem value={SignatureAlgorithm.SHA256}>{SignatureAlgorithm.SHA256}</MenuItem>
                                                            <MenuItem value={SignatureAlgorithm.SHA1}>{SignatureAlgorithm.SHA1}</MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                                <FormHelperText className={cx(classes.SelectHelperText)}>
                                                    {errors.config?.signature_algorithm?.message as string}
                                                </FormHelperText>
                                            </Stack>
                                        </FormField>
                                    </Grid2>
                                    <Grid2 container size={{ xs: 12, sm: 6 }} spacing={2}>
                                        <Grid2 size={12}>
                                            <FormField label='Issuer URI' htmlFor='config.idp_entity_id'>
                                                <Controller
                                                    name={'config.idp_entity_id'}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            fullWidth={true}
                                                            id='config.idp_entity_id'
                                                            error={!!errors.config?.idp_entity_id}
                                                            value={value}
                                                            size={'small'}
                                                            helperText={errors.config?.idp_entity_id?.message as string}
                                                            onChange={(e) => {
                                                                onChange(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormField>
                                        </Grid2>
                                        <Grid2 size={12}>
                                            <FormField label='Single sign-on URL' htmlFor='config.single_sign_on_service_url'>
                                                <Controller
                                                    name={'config.single_sign_on_service_url'}
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            fullWidth={true}
                                                            id='config.single_sign_on_service_url'
                                                            error={!!errors.config?.single_sign_on_service_url}
                                                            value={value}
                                                            size={'small'}
                                                            helperText={errors.config?.single_sign_on_service_url?.message as string}
                                                            onChange={(e) => {
                                                                onChange(e.target.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormField>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <SsoSamlFileUploadEdit control={control} errors={errors} setValue={setValue} ssoItem={props.ssoItem} />
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2 className={cx(classes.SSOSubItemContainer, classes.SSOGraySubItemContainer)} container>
                            <Typography variant='subtitle2' className={cx(classes.FontWeightBold, classes.SSOSubItemHeader)}>
                                SETTINGS AND CONFIGURATION DATA YOU CAN PROVIDE YOUR SSO PROVIDER TO COMPLETE SETUP
                            </Typography>
                            <Grid2 className={cx(classes.SSOSubItemContainer, classes.SSOWhiteSubItemContainer)} container>
                                {/*<Grid2 item xs={6}>*/}
                                {/*    <Stack>*/}
                                {/*        <Typography variant='subtitle2' className={cx(classes.FontWeightBold)}>*/}
                                {/*            IDP Metadata*/}
                                {/*        </Typography>*/}
                                {/*        <Button*/}
                                {/*            variant='text'*/}
                                {/*            className={cx(classes.XMLDownload)}*/}
                                {/*            onClick={() => props.onGenerateXMLMetaData(props.ssoItem.xml_metadata, `Vega_IdP_Metadata_XML.xml`)}*/}
                                {/*        >*/}
                                {/*            <Download />*/}
                                {/*            <Typography variant='subtitle2'>Vega IdP Metadata XML</Typography>*/}
                                {/*        </Button>*/}
                                {/*    </Stack>*/}
                                {/*</Grid2>*/}

                                <Typography variant='subtitle2' className={cx(classes.FontWeightBold, classes.SSOSubItemHeader)}>
                                    Additional details to assist with configuring your SSO providers App Registration (if applicable)
                                </Typography>
                                <Grid2 container size={12} spacing={2}>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Audience' htmlFor='config.idp_sso_audience_restriction'>
                                            <Controller
                                                name={'config.idp_sso_audience_restriction'}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        fullWidth
                                                        id='config.idp_entity_id'
                                                        error={!!errors.config?.idp_sso_audience_restriction}
                                                        value={value}
                                                        size={'small'}
                                                        slotProps={{
                                                            input: {
                                                                readOnly: true,
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='copy audience'
                                                                            onClick={() => handleCopy('Audience', value ?? '')}
                                                                        >
                                                                            <ContentCopy />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        }}
                                                        helperText={errors.config?.idp_sso_audience_restriction?.message as string}
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormField>
                                    </Grid2>
                                    <Grid2 size={{ xs: 12, sm: 6 }}>
                                        <FormField label='Single Sign-On ACS URL' htmlFor='config.idp_sso_redirect_url'>
                                            <Controller
                                                name={'config.idp_sso_redirect_url'}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        fullWidth={true}
                                                        id='config.idp_entity_id'
                                                        error={!!errors.config?.idp_sso_redirect_url}
                                                        value={value}
                                                        slotProps={{
                                                            input: {
                                                                readOnly: true,
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='copy redirect url'
                                                                            onClick={() => handleCopy('Redirect URL', value ?? '')}
                                                                        >
                                                                            <ContentCopy />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        }}
                                                        size={'small'}
                                                        helperText={errors.config?.idp_sso_redirect_url?.message as string}
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormField>
                                    </Grid2>
                                </Grid2>
                            </Grid2>
                        </Grid2>
                        <Grid2 className={cx(classes.SSOButtonGrid)} container size={12} direction={'row'} justifyContent={'space-between'}>
                            <Button variant='contained' color='error' onClick={() => setIsDeleteOpen(true)}>
                                Delete
                            </Button>
                            <Button
                                form={'list-sso-item-form'}
                                id={'list-sso-item-form-button'}
                                variant='contained'
                                disabled={!isDirty}
                                type='submit'
                                disableElevation
                            >
                                Save
                            </Button>
                        </Grid2>
                    </Grid2>
                </Collapse>
            </Stack>

            <SSODeleteDialog isOpen={isDeleteOpen} selectedSSO={props.ssoItem} onClose={() => setIsDeleteOpen(false)} onDelete={props.onDelete} />
        </>
    );
};

const useStyles = customMakeStyles<ISsoSettingsListItemProps>()((theme, props) => ({
    SSOItem: {
        borderColor: theme.palette.grey['100'],
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '10px',
        padding: '10px 20px 10px 30px',
        marginTop: theme.spacing(2),
    },
    SSOSubItemHeader: { marginBottom: '5px' },
    SSOParentSubItemContainer: { marginTop: '10px', marginBottom: '10px' },
    SSOSubItemContainer: {
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '8px',
        width: '100%',
    },
    SSOGraySubItemContainer: { backgroundColor: '#fafafa' },
    SSOWhiteSubItemContainer: { backgroundColor: '#ffffff' },
    InfoIcon: {
        fontSize: '17px',
    },
    GreyInput: {
        backgroundColor: theme.palette.grey[100],
    },
    XMLDownload: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1),
        marginTop: theme.spacing(1),
        width: 'fit-content',
        textDecoration: 'underline',
    },
    SSOButtonGrid: { paddingTop: '1rem', paddingBottom: '1rem' },
    FontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    FontWeightBold: {
        fontWeight: theme.typography.fontWeightBold,
    },
    Caption: {
        color: theme.palette.grey[300],
    },
    SelectHelperText: {
        color: theme.palette.error.main,
    },
}));

export { SsoSettingsListItem };
