import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, ButtonProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBlackOnWhiteButtonProps extends ButtonProps {}

const BlackOnWhiteButton: React.FC<IBlackOnWhiteButtonProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <Button {...props} color={'inherit'} className={cx(classes.CustomButton)}></Button>;
};

const useStyles = customMakeStyles<IBlackOnWhiteButtonProps>()((theme, props) => ({
    CustomButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.getContrastText(theme.palette.common.white),
        border: `1px solid #D0D5DD`,
        ':hover': {
            backgroundColor: `${theme.palette.grey[100]}40`,
        },
    },
}));

export { BlackOnWhiteButton };
