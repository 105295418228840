import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { FileDropZone } from '../file-drop-zone/file-drop-zone';
import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BulkProviderAccountsTable } from './bulk-provider-accounts-table';
import { StringLowerCaseAndUnderscore } from '../utilities/string-formatter';
import { v4 as uuidv4 } from 'uuid';
import { AwsLogo, AwsTemplate, AzureLogo, AzureTemplate, GcpLogo, GcpTemplate, OciTemplate } from '@vegaplatformui/sharedassets';
import { Download } from '@mui/icons-material';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { useFetchFileBlobAndDownload } from '../utilities/use-fetch-file-blob-and-download';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SupportEmailRecipient } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBulkProviderAccountCardProps {
    selectedFiles: File[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
    isFilesLoading: boolean;
    setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void;
}

const BulkProviderAccountCard: React.FC<IBulkProviderAccountCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const [fileRows, setFileRows] = React.useState([]);
    const [headerKeys, setHeaderKeys] = React.useState<string[]>([]);
    const fileReader = new FileReader();
    const fetchFileBlobAndDownload = useFetchFileBlobAndDownload();
    const is1800pxOrLarger = useMediaQuery(theme.breakpoints.up(1800));
    const flags = useFlags();
    const allowedMimeTypes: { [key: string]: string[] } = {
        'text/csv': ['.csv'],
    };

    //sourced from https://refine.dev/blog/how-to-import-csv/
    const csvFileToArray = (fileData: any) => {
        const csvHeader = fileData.slice(0, fileData.indexOf('\n')).split(',');
        const csvRows = fileData.slice(fileData.indexOf('\n') + 1).split('\n');
        const tempArray = csvRows.map((row: string) => {
            const values = row.split(',');
            const obj = csvHeader.reduce((object: any[], header: string, index: number) => {
                object[StringLowerCaseAndUnderscore(header.replace(/[\n\r]/g, '')) as any] = values[index].replace(/[\n\r]/g, '');
                return object;
            }, {});
            return obj;
        });

        setFileRows(
            tempArray.map((row: any) => {
                return {
                    ...row,
                    id: uuidv4(),
                };
            })
        );
    };

    const handleOnSubmit = () => {
        //e.preventDefault();
        if (props.selectedFiles.length > 0 && props.selectedFiles[0]) {
            fileReader.onload = function (event) {
                const text = event.target?.result;
                csvFileToArray(text);
            };
            const file: File = props.selectedFiles[0];
            fileReader.readAsText(file);
        }
    };

    const handleHeaderKeys = () => {
        if (props.selectedFiles.length > 0 && fileRows) {
            setHeaderKeys(
                (Object.keys(Object.assign({}, ...fileRows)) as string[])
                    .filter((headerKey) => headerKey !== 'id')
                    .map((headerKey: string) => {
                        return headerKey.replace(/[\n\r]/g, '');
                    })
            );
        } else {
            setHeaderKeys([]);
        }
    };

    useEffect(() => {
        handleOnSubmit();
    }, [props.selectedFiles]);

    useEffect(() => {
        handleHeaderKeys();
    }, [fileRows]);

    return (
        <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
            <Stack direction={is1800pxOrLarger ? 'row' : 'column'} justifyContent='center' alignItems='flex-start' spacing={2}>
                <Button
                    className={commonStyles.classes.LowercaseTextButton}
                    variant={'outlined'}
                    onClick={() => {
                        fetchFileBlobAndDownload.fetchFileToDownload('aws_template.csv', AwsTemplate, props.setSnackbarOptions);
                    }}
                    startIcon={<Download aria-hidden={false} aria-label={'Download AWS'} />}
                    endIcon={<AwsLogo className={cx(classes.CloudProviderIcon)} />}
                >
                    CSV Template
                </Button>
                <Button
                    className={commonStyles.classes.LowercaseTextButton}
                    variant={'outlined'}
                    onClick={() => {
                        fetchFileBlobAndDownload.fetchFileToDownload('azure_template.csv', AzureTemplate, props.setSnackbarOptions);
                    }}
                    startIcon={<Download aria-hidden={false} aria-label={'Download Azure'} />}
                    endIcon={<AzureLogo className={cx(classes.CloudProviderIcon)} />}
                >
                    CSV Template
                </Button>
                <Button
                    className={commonStyles.classes.LowercaseTextButton}
                    variant={'outlined'}
                    onClick={() => {
                        fetchFileBlobAndDownload.fetchFileToDownload('gcp_template.csv', GcpTemplate, props.setSnackbarOptions);
                    }}
                    startIcon={<Download aria-hidden={false} aria-label={'Download GCP'} />}
                    endIcon={<GcpLogo className={cx(classes.CloudProviderIcon)} />}
                >
                    CSV Template
                </Button>
                {flags.ociIntegration && (
                    <Button
                        className={commonStyles.classes.LowercaseTextButton}
                        variant={'outlined'}
                        onClick={() => {
                            fetchFileBlobAndDownload.fetchFileToDownload('oci_template.csv', OciTemplate, props.setSnackbarOptions);
                        }}
                        startIcon={<Download aria-hidden={false} aria-label={'Download OCI'} />}
                        endIcon={
                            <Typography className={cx(classes.TextForIconReplacement)} variant={'subtitle2'}>
                                O.C.I
                            </Typography>
                        }
                    >
                        CSV Template
                    </Button>
                )}
            </Stack>
            <FileDropZone
                allowedMimeTypes={allowedMimeTypes}
                onClickRemoveFile={() => {
                    props.setSelectedFiles([]);
                }}
                selectedFiles={props.selectedFiles}
                setSelectedFiles={props.setSelectedFiles}
                isLoading={props.isFilesLoading}
                inputOptions={{
                    maxFiles: 1,
                    multiple: false,
                    maxSize: 20 * 1024 * 1024,
                    minSize: 1,
                    accept: allowedMimeTypes,
                }}
                acceptedFileList={['CSV']}
            />
            {props.selectedFiles.length > 0 && (
                <BulkProviderAccountsTable
                    selectedFiles={props.selectedFiles}
                    setSelectedFiles={props.setSelectedFiles}
                    fileRows={fileRows}
                    headerKeys={headerKeys}
                />
            )}
        </Stack>
    );
};

const useStyles = customMakeStyles<IBulkProviderAccountCardProps>()((theme, props) => ({
    ClearButton: {
        textTransform: 'none',
    },
    TextForIconReplacement: {
        fontSize: '13px !important',
    },
    BulkProviderSelect: {
        //alignItems: 'flex-end',
        justifyContent: 'flex-end',
        // alignSelf: 'flex-end',
    },
    CloudProviderIcon: {
        width: '1.5rem',
    },
}));

export { BulkProviderAccountCard };
