import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ICloudProviderAccount, LinkOciAccountForm } from '@vegaplatformui/models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import { AccountDiscoveryInProgressAlert } from './account-discovery-in-progress-alert';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { DiscoveryDetails } from '../jotai/atom';
import { FormField } from '../forms/form-field';
import { useAtomValue } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkOciAccountDrawerProps {
    isDialogOpen: boolean;
    onBackDrawer: () => void;
    onCloseDialog: () => void;
    accountToEdit?: ICloudProviderAccount;
    onSubmitLinkOciAccountForm: (data: LinkOciAccountForm) => void;
    setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void;
    onOpenDeleteAccountDialog?: (account: ICloudProviderAccount) => void;
}

const ocidRegex = /^ocid1\.([a-z0-9]+)\.(?:oc1|oc2|oc3)\.([a-z0-9]+)?(\.[a-zA-Z0-9]+)?\.[a-zA-Z0-9]+$/i;
const ocidTenancyRegex = /^ocid1\.tenancy\.(?:oc1|oc2|oc3)\.([a-z0-9]+)?(\.[a-zA-Z0-9]+)?\.[a-zA-Z0-9]+$/i;
const ocidCompartmentRegex = /^ocid1\.compartment\.(?:oc1|oc2|oc3)\.([a-z0-9]+)?(\.[a-zA-Z0-9]+)?\.[a-zA-Z0-9]+$/i;
const ocidUserRegex = /^ocid1\.user\.(?:oc1|oc2|oc3)\.([a-z0-9]+)?(\.[a-zA-Z0-9]+)?\.[a-zA-Z0-9]+$/i;
const ocidMessage = 'must be a valid OCID, and has to match the pattern "ocid1.<RESOURCE TYPE>.<REALM>.[REGION].<UNIQUE ID>"' as string;
const ocidTenancyMessage = 'must be a valid OCID, and has to match the pattern "ocid1.tenancy.<REALM>.[REGION].<UNIQUE ID>"' as string;
const ocidCompartmentMessage = 'must be a valid OCID, and has to match the pattern "ocid1.compartment.<REALM>.[REGION].<UNIQUE ID>"' as string;
const ocidUserMessage = 'must be a valid OCID, and has to match the pattern "ocid1.user.<REALM>.[REGION].<UNIQUE ID>"' as string;

const validationSchema: yup.ObjectSchema<LinkOciAccountForm> = yup.object().shape({
    account_alias: yup.string(),
    enabled: yup.boolean().required(),
    is_root: yup.boolean().required(),
    compartment_ocid: yup
        .string()
        .required('Required')
        .when('is_root', {
            is: true,
            then: (schema) => schema.matches(ocidTenancyRegex, `Tenancy ${ocidTenancyMessage}`),
            otherwise: (schema) => schema.matches(ocidCompartmentRegex, `Compartment ${ocidCompartmentMessage}`),
        }),
    tenancy: yup.string().when('is_root', {
        is: true,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required('Required').matches(ocidTenancyRegex, `Tenancy ${ocidTenancyMessage}`),
    }),
    parent_account_ocid: yup.string().when('is_root', {
        is: true,
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required('Required').matches(ocidRegex, `Parent Compartment ${ocidMessage}`),
    }),
    private_key_content: yup
        .string()
        .required('Required')
        .matches(
            RegExp('-----BEGIN PRIVATE KEY-----[\\s\\S]*-----END PRIVATE KEY-----', 'm'),
            'Private key content not valid, must begin with "-----BEGIN PRIVATE KEY-----" and end with "-----END PRIVATE KEY-----"'
        ),
    user: yup.string().required('Required').matches(ocidUserRegex, ocidUserMessage),
    //   .test('regex-check', 'Invalid format', function (value) {
    //     const { path, createError } = this;
    //     if (value && value.length > 0) {
    //         return ocidUserRegex.test(value) || createError({ path, message: `User ${ocidUserMessage}` });
    //     }
    //     return true; // No validation if the string length is 0 or less
    // }),
    region: yup
        .string()
        .required('Required')
        .matches(/^[a-z]{2,}-[a-z]+-\d+$/i, 'Region Identifier must be in a format like "us-phoenix-1"'),
    fingerprint: yup
        .string()
        .required('Required')
        .matches(
            /^([a-fA-F0-9]{2}:){15}[a-fA-F0-9]{2}$/i,
            'Fingerprint must be 32 hexadecimal characters in the format, "12:34:56:78:90:ab:cd:ef:12:34:56:78:90:ab:cd:ef"'
        ),
});

const LinkOciAccountDrawer: React.FC<ILinkOciAccountDrawerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const discoveryDetails = useAtomValue(DiscoveryDetails);

    const {
        watch,
        handleSubmit,
        control,
        setValue,
        reset,
        formState,
        trigger,
        formState: { errors, isValid, isSubmitSuccessful },
    } = useForm<LinkOciAccountForm>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            account_alias: props.accountToEdit?.account_name ?? '',
            compartment_ocid: props.accountToEdit?.account_id ?? '',
            is_root: (props.accountToEdit && props.accountToEdit.parent_id === null && props.accountToEdit.parent_account_id === null) ?? true,
            parent_account_ocid: props.accountToEdit?.parent_account_id ?? '',
            tenancy: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.tenancy) ?? '',
            region: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.region) ?? '',
            enabled: props.accountToEdit?.enabled ?? true,
            private_key_content: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.private_key_content) ?? '',
            fingerprint: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.fingerprint) ?? '',
            user: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.user) ?? '',
        },
    });

    useEffect(() => {
        if (props.accountToEdit !== undefined) {
            reset(
                {
                    account_alias: props.accountToEdit?.account_name ?? '',
                    compartment_ocid: props.accountToEdit?.account_id ?? '',
                    is_root:
                        (props.accountToEdit && props.accountToEdit.parent_id === null && props.accountToEdit.parent_account_id === null) ?? true,
                    parent_account_ocid: props.accountToEdit?.parent_account_id ?? '',
                    tenancy: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.tenancy) ?? '',
                    enabled: props.accountToEdit?.enabled ?? true,
                    region: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.region) ?? '',
                    private_key_content: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.private_key_content) ?? '',
                    fingerprint: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.fingerprint) ?? '',
                    user: (props.accountToEdit && JSON.parse(props.accountToEdit.secret_json)?.user) ?? '',
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [props.accountToEdit]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    const onCloseDrawerAndResetState = () => {
        props.onCloseDialog();
        reset({});
    };

    const onBackDrawerAndResetState = () => {
        props.onBackDrawer();
        reset({});
    };

    const onSubmitForm: SubmitHandler<LinkOciAccountForm> = (data) => {
        const formToSubmit = { ...data };

        props.onSubmitLinkOciAccountForm(formToSubmit);
        props.onCloseDialog();
    };

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isDialogOpen}
            onClose={onCloseDrawerAndResetState}
            aria-labelledby='link-oci-account-drawer'
            hideBackdrop={!props.accountToEdit}
            id={'link-oci-account-drawer'}
        >
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} variant={'h6'} id={'link-oci-account-drawer-title'}>
                <Stack spacing={0.5} direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Stack
                        direction={'row'}
                        width={'100%'}
                        justifyContent={!props.accountToEdit ? 'space-between' : 'flex-end'}
                        alignItems='center'
                        spacing={1}
                    >
                        {props.accountToEdit === undefined && (
                            <Button onClick={onBackDrawerAndResetState} variant='text' startIcon={<ArrowBack />}>
                                <Typography variant={'caption'}>Back</Typography>
                            </Button>
                        )}
                        <IconButton size={'small'} aria-label={'Close'} className={cx(classes.CloseButton)} onClick={onCloseDrawerAndResetState}>
                            <Close fontSize={'small'} className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                    <Stack direction={'column'}>
                        {(props.accountToEdit ? 'Edit OCI ' : 'Link OCI ') + (control._formValues.is_root ? 'Tenancy' : 'Compartment')}
                        {props.accountToEdit === undefined ? (
                            <Typography variant={'body1'}>
                                Add an OCI data integration at the{control._formValues.is_root ? ' tenancy ' : ' compartment '}level.
                                {/*Click the button to*/}
                                {/*  access the Vega documentation, where you'll find detailed guidance and additional information.*/}
                            </Typography>
                        ) : (
                            <></>
                        )}
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Stack direction={'column'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                    {props.accountToEdit === undefined && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            variant={'outlined'}
                            type={'button'}
                            href={'https://docs.vegacloud.io/docs/providers/oci'}
                            target={'_blank'} //open in a new tab
                            rel={'noreferrer noopener'} //blocks communication between the new tab and the original tab (security measure)
                        >
                            Vega OCI Guide
                        </Button>
                    )}
                    {props.accountToEdit && discoveryDetails.is_discovery && <AccountDiscoveryInProgressAlert />}
                    <Stack
                        id={'link-oci-account-drawer-form'}
                        component={'form'}
                        spacing={2}
                        onSubmit={handleSubmit((data) => {
                            onSubmitForm(data);
                        })}
                    >
                        <Stack className={cx(classes.FormTitle)} direction={'column'} spacing={0}>
                            <Typography fontWeight={600} variant={'body1'}>
                                Account Details
                            </Typography>
                            <Typography variant={'body2'}>
                                The Account or Canonical Name is automatically populated from your cloud service provider.
                            </Typography>
                        </Stack>
                        <Stack direction={'column'} spacing={0}>
                            <FormField label={control._formValues.is_root ? 'Tenancy OCID' : 'Compartment OCID'} htmlFor='compartment_ocid'>
                                <Controller
                                    name={'compartment_ocid'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            onChange={(e) => {
                                                onChange(e.target.value);
                                            }}
                                            fullWidth
                                            value={value}
                                            slotProps={{ input: { readOnly: !!props.accountToEdit } }}
                                            id={'compartment_ocid'}
                                            size='small'
                                            error={!!errors.compartment_ocid}
                                            placeholder={`Enter the ${control._formValues.is_root ? 'tenancy' : 'compartment'} OCID`}
                                            helperText={errors.compartment_ocid?.message as string}
                                        />
                                    )}
                                />
                            </FormField>
                            <FormField id={'is_root'} htmlFor='is_payer_account'>
                                <Controller
                                    name={'is_root'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            className={cx(classes.EnabledSwitch)}
                                            control={
                                                <Checkbox
                                                    id={'is_root'}
                                                    checked={value}
                                                    size={'small'}
                                                    inputProps={{ 'aria-label': 'Checkbox for is root compartment' }}
                                                />
                                            }
                                            disabled={!!props.accountToEdit}
                                            onChange={(event, checked) => {
                                                onChange(checked);
                                                //This will trigger the revalidation of the payer_account field
                                                trigger('compartment_ocid');
                                                if (checked) {
                                                    trigger('parent_account_ocid');
                                                    trigger('tenancy');
                                                }
                                            }}
                                            label={<Typography variant={'caption'}>This is the Tenancy ID (Compartment root).</Typography>}
                                            labelPlacement='end'
                                        />
                                    )}
                                />
                            </FormField>
                        </Stack>
                        {!control._formValues.is_root && [
                            <FormField key={'tenancy'} label='Tenancy OCID' htmlFor='tenancy'>
                                <Controller
                                    name={'tenancy'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            onChange={(e) => {
                                                onChange(e.target.value);
                                            }}
                                            fullWidth
                                            value={value}
                                            id={'tenancy'}
                                            size='small'
                                            error={!!errors.tenancy}
                                            placeholder={`Enter a tenancy OCID`}
                                            helperText={errors.tenancy?.message as string}
                                        />
                                    )}
                                />
                            </FormField>,
                            <FormField key={'parent_account'} label='Parent Compartment OCID' htmlFor='parent_account_ocid'>
                                <Controller
                                    name={'parent_account_ocid'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            onChange={(e) => {
                                                onChange(e.target.value);
                                            }}
                                            fullWidth
                                            value={value}
                                            id={'parent_account_ocid'}
                                            slotProps={{ input: { readOnly: !!props.accountToEdit } }}
                                            size='small'
                                            error={!!errors.parent_account_ocid}
                                            placeholder={`Enter the parent OCID`}
                                            helperText={errors.parent_account_ocid?.message as string}
                                        />
                                    )}
                                />
                            </FormField>,
                        ]}
                        <FormField label='Private Key' htmlFor='private_key_content'>
                            <Controller
                                name={'private_key_content'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        fullWidth
                                        value={value}
                                        multiline
                                        minRows={3}
                                        maxRows={6}
                                        id={'private_key_content'}
                                        size='small'
                                        helperText={errors.private_key_content?.message as string}
                                        error={!!errors.private_key_content}
                                        placeholder={'Paste private key content'}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Fingerprint' htmlFor='fingerprint'>
                            <Controller
                                name={'fingerprint'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'fingerprint'}
                                        size='small'
                                        fullWidth
                                        error={!!errors.fingerprint}
                                        placeholder={'Enter fingerprint'}
                                        helperText={errors.fingerprint?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Home Region Identifier' htmlFor='region'>
                            <Controller
                                name={'region'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        fullWidth
                                        value={value}
                                        id={'region'}
                                        size='small'
                                        error={!!errors.region}
                                        placeholder={'Enter home region identifier'}
                                        helperText={errors.region?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='User OCID' htmlFor='user'>
                            <Controller
                                name={'user'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'user'}
                                        size='small'
                                        fullWidth
                                        error={!!errors.user}
                                        placeholder={'Enter a user OCID'}
                                        helperText={errors.user?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Vega Account Alias (optional)' htmlFor='account_alias'>
                            <Controller
                                name={'account_alias'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'account_alias'}
                                        size='small'
                                        fullWidth
                                        error={!!errors.account_alias}
                                        placeholder={'Create an alias'}
                                        helperText={
                                            (errors.account_alias?.message as string) ??
                                            `${props.accountToEdit ? 'Edit' : 'Create a'} nickname for this account.`
                                        }
                                    />
                                )}
                            />
                        </FormField>
                        <FormField id={'enabled'} label='Account Status' htmlFor='enabled'>
                            <Controller
                                name={'enabled'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        className={cx(classes.EnabledSwitch)}
                                        control={<Switch id={'enabled'} onClick={() => setValue('enabled', !value)} checked={value} />}
                                        label={`This account is ${value ? 'enabled' : 'disabled'}`}
                                        labelPlacement='end'
                                    />
                                )}
                            />
                        </FormField>
                        {/*Just adding false in so it will never display*/}
                        {props.accountToEdit === undefined && false && (
                            <Typography variant={'caption'}>
                                Accounts will automatically be tested after creation and there will be a discovery every 30 minutes to search for
                                errors.
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack
                    width={'100%'}
                    direction={'row'}
                    justifyContent={!props.accountToEdit ? 'flex-end' : 'space-between'}
                    alignItems='center'
                    spacing={0}
                >
                    {props.accountToEdit && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            id={'link-oci-account-drawer-delete'}
                            disableElevation={true}
                            variant={'contained'}
                            onClick={() => {
                                const account = props.accountToEdit!;
                                props.onOpenDeleteAccountDialog && props.onOpenDeleteAccountDialog(account);
                                onCloseDrawerAndResetState();
                            }}
                            color={'error'}
                        >
                            Delete
                        </Button>
                    )}
                    {/*<Button variant={'cancel'} disableElevation={true} variant={'contained'} color={'secondary'} autoFocus onClick={props.onCloseDialog}>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    {props.accountToEdit !== undefined ? (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-oci-account-drawer-form'}
                        >
                            Save
                        </Button>
                    ) : (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            id={'link-oci-account-drawer-form-submit'}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-oci-account-drawer-form'}
                        >
                            Link
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Drawer>
    );
};

const useStyles = customMakeStyles<ILinkOciAccountDrawerProps>()((theme, props) => ({
    EnabledSwitch: { marginLeft: '-0.45rem' },
    CloudFormationTitle: {
        fontWeight: 600,
    },
    FormTitle: {
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DrawerPaper: { width: '35%' },
    CloseButton: {
        padding: '6px',
    },
    TemplateButton: {
        marginTop: '2rem',
    },
}));

export { LinkOciAccountDrawer };
