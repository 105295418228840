import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormControl, Grid2, InputLabel, MenuItem, Select } from '@mui/material';
import { TimePickerWithTimezone } from '../../../utilities/time-picker-with-timezone';
import { Dayjs } from 'dayjs';
import { ITimezone } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWeeklyReoccurrenceFieldProps {
    time: Dayjs | null;
    setTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    day: string;
    setDay: React.Dispatch<React.SetStateAction<string>>;
    timezone: ITimezone | null;
}

const WeeklyReoccurrenceField: React.FC<IWeeklyReoccurrenceFieldProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const daysOfWeekNames = [
        { id: 'sunday', label: 'Sunday' },
        { id: 'monday', label: 'Monday' },
        { id: 'tuesday', label: 'Tuesday' },
        { id: 'wednesday', label: 'Wednesday' },
        { id: 'thursday', label: 'Thursday' },
        { id: 'friday', label: 'Friday' },
        { id: 'saturday', label: 'Saturday' },
    ];

    return (
        <Grid2 container direction={'column'} spacing={1}>
            <Grid2>
                <FormControl fullWidth size={'small'}>
                    <InputLabel id='weekly-reoccurrence-day-select' aria-label={'Weekly reoccurrence day selection'}>
                        Day of the Week
                    </InputLabel>
                    <Select
                        labelId='weekly-reoccurrence-day-select'
                        label={'Day of the week'}
                        size={'small'}
                        value={props.day}
                        onChange={(newValue) => props.setDay(newValue.target.value)}
                    >
                        {daysOfWeekNames.map((day) => (
                            <MenuItem aria-label={day.label} key={day.id} value={day.id}>
                                {day.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid2>
            <Grid2>
                <FormControl fullWidth aria-label={'Weekly reoccurrence time selection'}>
                    <TimePickerWithTimezone
                        timezone={props.timezone}
                        time={props.time}
                        setTime={props.setTime}
                        label={'Select weekly time'}
                        slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                    />
                </FormControl>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IWeeklyReoccurrenceFieldProps>()((theme, props) => ({}));

export { WeeklyReoccurrenceField };
