import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { IParkingScheduleSummary } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeleteExemptionDialogProps {
    schedule: IParkingScheduleSummary | undefined;
    onClickDeleteExemption: (schedule: IParkingScheduleSummary) => void;
    onClose: () => void;
    isOpen: boolean;
}

const DeleteExemptionDialog: React.FC<IDeleteExemptionDialogProps> = (props) => {
    const { classes, cx } = useCommonStyles();

    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={props.isOpen} onClose={props.onClose}>
            <DialogTitle
                className={cx(classes.FormTitlePadding)}
            >{`Delete disable between for ${(props.schedule && props.schedule.name) ?? 'selected schedule'}`}</DialogTitle>
            <DialogContent className={classes.FormContentPadding}>
                <DialogContentText variant={'body2'}>
                    This will delete the disable between schedule and revert the status of the schedule to what it was prior to when the disable
                    between started.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.FormActionsPadding}>
                <Stack direction={'row'} spacing={1.5} justifyContent={'flex-end'} width={'100%'}>
                    <Button variant={'cancel'} onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        color={'error'}
                        onClick={() => {
                            props.schedule && props.onClickDeleteExemption(props.schedule);
                        }}
                    >
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IDeleteExemptionDialogProps>()((theme, props) => ({}));

export { DeleteExemptionDialog };
