import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Card, IconButton, Stack } from '@mui/material';
import { IRouteUrlTab } from '@vegaplatformui/models';
import { SettingsV2Menu } from './settings-v2-menu';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useRouteUrls } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISettingsV2CardProps extends React.PropsWithChildren {
    profileSettingsItems: IRouteUrlTab[];
    organizationSettingsItems: IRouteUrlTab[];
    settingsMenuWidth: number;
    setSettingsMenuWidth: React.Dispatch<React.SetStateAction<number>>;
    setCurrentTab: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CircularIconButton = styled(IconButton)(({ theme }) => ({
    height: '20px',
    width: '20px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    '&:hover': {
        backgroundColor: theme.palette.grey.A200,
    },
}));

const SquareIconButton = styled(IconButton)(({ theme }) => ({
    height: '20px',
    width: '20px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0px',
    '&:hover': {
        backgroundColor: theme.palette.grey.A200,
    },
}));

const SettingsV2Card: React.FC<ISettingsV2CardProps> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const { classes, cx } = useStyles({ props, isOpen });
    const { profileSettingsItems, organizationSettingsItems, settingsMenuWidth, setSettingsMenuWidth, children, setCurrentTab } = props;
    const navigate = useNavigate();
    const { routeUrls } = useRouteUrls({});

    const handleSubMenu = () => {
        setIsOpen(!isOpen);
    };

    const onChangeSettingsLocation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: IRouteUrlTab) => {
        e.preventDefault();
        setCurrentTab(item.id);
        navigate(`/${routeUrls.settings.route.path}/${item.id}`);
    };

    return (
        <Card className={classes.CardHeight} elevation={0}>
            <Stack className={classes.CardStack} spacing={isOpen ? 1 : 0} direction={'row'}>
                <SettingsV2Menu
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    organizationSettingsItems={organizationSettingsItems}
                    profileSettingsItems={profileSettingsItems}
                    settingsMenuWidth={settingsMenuWidth}
                    setSettingsMenuWidth={setSettingsMenuWidth}
                    onChangeSettingsLocation={onChangeSettingsLocation}
                />
                <Stack className={cx(classes.SidebarStack)}>
                    {isOpen ? (
                        <CircularIconButton
                            className={cx(classes.SidebarControl)}
                            size={'small'}
                            onClick={handleSubMenu}
                            aria-label={'minimize settings menu'}
                        >
                            <StyledToolTip key={'minimize'} arrow={true} title={'Minimize settings menu'}>
                                <KeyboardArrowLeftRounded className={cx(classes.SidebarIcon)} fontSize={'small'} />
                            </StyledToolTip>
                        </CircularIconButton>
                    ) : (
                        <CircularIconButton
                            className={cx(classes.SidebarControl)}
                            size={'small'}
                            onClick={handleSubMenu}
                            aria-label={'expand settings menu'}
                        >
                            <StyledToolTip key={'expand'} arrow={true} title={'Expand settings menu'}>
                                <KeyboardArrowRightRounded className={cx(classes.SidebarIcon)} fontSize={'small'} />
                            </StyledToolTip>
                        </CircularIconButton>
                    )}
                </Stack>
                <Box className={cx(classes.ContentBox)}>{children}</Box>
            </Stack>
        </Card>
    );
};

interface ISettingsV2CardStyles {
    props: ISettingsV2CardProps;
    isOpen: boolean;
}

const useStyles = customMakeStyles<ISettingsV2CardStyles>()((theme, { props, isOpen }) => ({
    SidebarStack: {
        paddingLeft: isOpen ? undefined : '0.4rem',
        paddingRight: isOpen ? undefined : '8px',
    },
    SidebarControl: {
        border: '1px solid #CECEDF',
        marginLeft: '-1.1rem',
        marginTop: '1.5rem',
        boxShadow: theme.shadows[2],
        '&.Mui-focusVisible': { border: `1px solid ${theme.palette.primary.main}` },
    },
    SidebarIcon: {
        width: `var(--20, 20px)`,
        height: `var(--20, 20px)`,
    },
    ContentBox: { paddingTop: '1rem', paddingRight: '1rem', paddingBottom: '1rem', minWidth: 0, width: '100%', maxWidth: '1224px' },
    CardHeight: { height: '100%', overflow: 'auto' },
    CardStack: { minHeight: '100%' },
}));

export { SettingsV2Card };
