import { GeminiResponse, removeKeysFromObject } from '@vegaplatformui/utils';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import {
    IGetNotificationChannelsResponse,
    IGetNotificationTypesResponse,
    IGenericUpsertUserNotifications,
    IGenericUpsertUserNotificationResponse,
    INotificationConfigurationResponse,
} from '@vegaplatformui/models';

export class NotificationsAPI extends HttpClient {
    protected static classInstance?: NotificationsAPI;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new NotificationsAPI();
        }

        return this.classInstance;
    }

    public getNotificationConfigurations = (user_id: string, signal: AbortSignal): GeminiResponse<INotificationConfigurationResponse> =>
        this.instance.get(`/notify/v1/users/${user_id}/subscriptions`, { signal });
    public getNotificationChannels = (signal: AbortSignal): GeminiResponse<IGetNotificationChannelsResponse> =>
        this.instance.get(`/notify/v1/channels`, { signal });
    public getNotificationTypes = (signal: AbortSignal): GeminiResponse<IGetNotificationTypesResponse> =>
        this.instance.get('/notify/v1/types', { signal });

    public putNotificationConfiguration = (request: IGenericUpsertUserNotifications): GeminiResponse<IGenericUpsertUserNotificationResponse> =>
        this.instance.put(`/notify/v1/users/${request.user_id}/subscriptions/settings`, { ...removeKeysFromObject(request, ['user_id']) });

    public postNotificationConfiguration = (request: IGenericUpsertUserNotifications): GeminiResponse<IGenericUpsertUserNotificationResponse> =>
        this.instance.post(`/notify/v1/users/${request.user_id}/subscriptions`, { ...removeKeysFromObject(request, ['user_id']) });
}
