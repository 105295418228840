import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import PopupState from 'material-ui-popup-state';
import { CustomChatbotContent } from './custom-chatbot-content';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomChatbotProps {}

const CustomChatbot: React.FC<ICustomChatbotProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <PopupState variant={'popper'} popupId={'chatbot-popup-popper'} parentPopupState={undefined} disableAutoFocus={undefined}>
            {(popupState) => <CustomChatbotContent popupState={popupState} />}
        </PopupState>
    );
};

const useStyles = customMakeStyles<ICustomChatbotProps>()((theme, props) => ({}));

export { CustomChatbot };
