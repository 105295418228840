import { AwsLogo, AzureLogo, GcpLogo } from '@vegaplatformui/sharedassets';
import { Stack, Tooltip, Typography } from '@mui/material';
import { useCommonStyles } from '@vegaplatformui/styling';
import { capitalizeFirstLetter } from '@vegaplatformui/utils';
import { CloudOutlined } from '@mui/icons-material';
import { CloudProvidersTypes } from '@vegaplatformui/models';

export interface ICloudProviderIcon {
    cloudProvider: string;
    isDynamicRowSize?: boolean;
}
const CloudProviderIcon: React.FC<ICloudProviderIcon> = (props) => {
    const { cx, classes } = useCommonStyles();

    return (
        <>
            {props.cloudProvider?.toLowerCase() === CloudProvidersTypes.Aws ? (
                <Tooltip title={CloudProvidersTypes.Aws.toUpperCase()} disableInteractive>
                    <Stack
                        role={'img'}
                        height={'100%'}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                    >
                        <AwsLogo className={cx(classes.CloudProviderTableIcon)} />
                    </Stack>
                </Tooltip>
            ) : props.cloudProvider?.toLowerCase() === CloudProvidersTypes.Gcp ? (
                <Tooltip title={CloudProvidersTypes.Gcp.toUpperCase()} disableInteractive>
                    <Stack
                        role={'img'}
                        height={'100%'}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                    >
                        <GcpLogo className={cx(classes.CloudProviderTableIcon)} />
                    </Stack>
                </Tooltip>
            ) : props.cloudProvider?.toLowerCase() === CloudProvidersTypes.Azure ? (
                <Tooltip title={CloudProvidersTypes.Azure.toUpperCase()} disableInteractive>
                    <Stack
                        role={'img'}
                        height={'100%'}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                    >
                        <AzureLogo className={cx(classes.CloudProviderTableIcon)} />
                    </Stack>
                </Tooltip>
            ) : props.cloudProvider?.toLowerCase() === CloudProvidersTypes.Oci ? (
                <Tooltip title={CloudProvidersTypes.Oci.toUpperCase()} disableInteractive>
                    <Stack
                        role={'img'}
                        height={'100%'}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems={props.isDynamicRowSize ? 'flex-start' : 'center'}
                        spacing={0.3}
                    >
                        <CloudOutlined className={cx(classes.CloudProviderTableIcon)} />
                        <Typography variant={'body2'}>O.C.I.</Typography>
                    </Stack>
                </Tooltip>
            ) : (
                ''
            )}
        </>
    );
};

export interface ICloudProviderIcons {
    cloudProviders: string[] | null;
}

const CloudProviderIcons: React.FC<ICloudProviderIcons> = (props) => {
    const { cx, classes } = useCommonStyles();
    return (
        <Stack height={'100%'} direction={'row'} spacing={1}>
            {props.cloudProviders?.find((cloudProvider) => cloudProvider !== null && cloudProvider?.toLowerCase() === CloudProvidersTypes.Aws) ? (
                <Tooltip title={CloudProvidersTypes.Aws.toUpperCase()}>
                    <Stack role={'img'} height={'100%'} direction='row' justifyContent='flex-start' alignItems='center'>
                        <AwsLogo className={cx(classes.CloudProviderTableIcon)} />
                    </Stack>
                </Tooltip>
            ) : (
                ''
            )}
            {props.cloudProviders?.find((cloudProvider) => cloudProvider?.toLowerCase() === CloudProvidersTypes.Gcp) ? (
                <Tooltip title={CloudProvidersTypes.Gcp.toUpperCase()}>
                    <Stack role={'img'} height={'100%'} direction='row' justifyContent='flex-start' alignItems='center'>
                        <GcpLogo className={cx(classes.CloudProviderTableIcon)} />
                    </Stack>
                </Tooltip>
            ) : (
                ''
            )}
            {props.cloudProviders?.find((cloudProvider) => cloudProvider?.toLowerCase() === CloudProvidersTypes.Azure) ? (
                <Tooltip title={CloudProvidersTypes.Azure.toUpperCase()}>
                    <Stack role={'img'} height={'100%'} direction='row' justifyContent='flex-start' alignItems='center'>
                        <AzureLogo className={cx(classes.CloudProviderTableIcon)} />
                    </Stack>
                </Tooltip>
            ) : (
                ''
            )}
            {props.cloudProviders?.find((cloudProvider) => cloudProvider?.toLowerCase() === CloudProvidersTypes.Oci) ? (
                <Tooltip title={CloudProvidersTypes.Oci.toUpperCase()}>
                    <Stack role={'img'} height={'100%'} direction='row' justifyContent='flex-start' alignItems='center'>
                        <CloudOutlined className={cx(classes.CloudProviderTableIcon)} />
                        <Typography variant={'body2'}>O.C.I.</Typography>
                    </Stack>
                </Tooltip>
            ) : (
                ''
            )}
        </Stack>
    );
};

export { CloudProviderIcon, CloudProviderIcons };
