import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { tableIdentifierKeys } from '../../use-table-utilities/table-identifier-keys';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { StringCapitalizeAndSpace } from '../../utilities/string-formatter';
import { GridPagination } from '@mui/x-data-grid';
import { Close } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChatbotDataDialogProps {
    isOpen: boolean;
    onCloseDialog: () => void;
    title?: string;
    headerKeys: string[];
    data: any[];
}

const ChatbotDataDialog: React.FC<IChatbotDataDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { isOpen, onCloseDialog, headerKeys, data, title } = props;
    const chatbotDataTableUtilities = useTableUtilities(tableIdentifierKeys.chatbotDataTable);

    return (
        <Dialog className={cx(classes.DialogIndex)} fullWidth maxWidth={'xl'} open={isOpen} onClose={onCloseDialog}>
            <Stack alignItems={'flex-start'} justifyContent={'space-between'} direction={'row'}>
                <DialogTitle variant={'h6'}>{`Results for "${title}"` ?? 'Results'}</DialogTitle>
                <IconButton onClick={onCloseDialog} size={'small'}>
                    <Close />
                </IconButton>
            </Stack>
            <DialogContent className={cx(classes.DialogContent)}>
                <DataGridPremium
                    getRowId={(row) => row.datagrid_id}
                    autoHeight={true}
                    rows={data}
                    columns={headerKeys.map((header: string) => {
                        return { field: header, headerName: StringCapitalizeAndSpace(header.toLowerCase()), flex: 1 };
                    })}
                    columnVisibilityModel={chatbotDataTableUtilities.currentTableControl?.columnModel}
                    onColumnVisibilityModelChange={chatbotDataTableUtilities.onColumnVisibilityModelChange}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={true}
                    slots={{
                        pagination: GridPagination,
                    }}
                    slotProps={{
                        toolbar: {
                            slotProps: {},
                            tableIdentifier: tableIdentifierKeys.chatbotDataTable,
                        },
                        pagination: {
                            showLastButton: true,
                            showFirstButton: true,
                            slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                        },
                        basePopper: { className: cx(classes.DialogIndex) },
                        baseSelect: { MenuProps: { className: cx(classes.DialogIndex) } },
                    }}
                    density={chatbotDataTableUtilities.currentTableControl?.density}
                    onDensityChange={chatbotDataTableUtilities.onDensityChange}
                    paginationModel={chatbotDataTableUtilities.currentTableControl?.paginationModel}
                    onPaginationModelChange={chatbotDataTableUtilities.onPaginationModelChange}
                    sortModel={chatbotDataTableUtilities.currentTableControl?.sortModel}
                    onSortModelChange={chatbotDataTableUtilities.onSortModelChange}
                    onFilterModelChange={chatbotDataTableUtilities.onFilterModelChange}
                    filterModel={chatbotDataTableUtilities.currentTableControl?.filterModel}
                    pagination
                />
            </DialogContent>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IChatbotDataDialogProps>()((theme, props) => ({
    CloseIcon: {
        position: 'absolute',
        top: 4,
        right: 4,
    },
    DialogContent: { paddingTop: 0 },
    DialogIndex: { zIndex: '1302 !important' },
}));

export { ChatbotDataDialog };
