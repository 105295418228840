import { HttpClient } from './http-common';
import {
    ICloudAccountsRequest,
    IDataGridRequest,
    IDeleteCloudAccountsBatchRequest,
    IDeleteCloudAccountsRequest,
    IGetCloudAccountRequest,
    IGetCloudAccountsResponse,
    IPostCloudAccountsBatchRequest,
    IPostCloudAccountsBatchResponse,
    IPostCloudAccountsRequest,
    IPutCloudAccountsRequest,
} from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import { ICloudProviderAccount } from '@vegaplatformui/models';
import Keycloak from 'keycloak-js';
import { GenericAbortSignal } from 'axios';

export class CloudProviderAccountApi extends HttpClient {
    protected static classInstance?: CloudProviderAccountApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new CloudProviderAccountApi();
        }

        return this.classInstance;
    }
    public loadCloudAccounts = (request: IDataGridRequest, signal: GenericAbortSignal): GeminiResponse<IGetCloudAccountsResponse> =>
        this.instance.get(`/vegaapi/provider-accounts`, {
            params: {
                filters: JSON.stringify(request.filterModel),
                sorted_by: JSON.stringify(request.sortModel),
                grid_pagination_model: JSON.stringify(request.paginationModel),
            },
            signal,
        });

    public getCloudAccount = (request: IGetCloudAccountRequest): GeminiResponse<ICloudProviderAccount> =>
        this.instance.get(`/vegaapi/provider-accounts/${request.id}`);

    public getAWSParentAccounts = (signal: AbortSignal): GeminiResponse<ICloudProviderAccount[]> =>
        this.instance.get(`/vegaapi/provider-accounts/aws-parents/`, { signal });

    public deleteCloudAccount = (request: IDeleteCloudAccountsRequest): GeminiResponse<any> =>
        this.instance.delete(`vegaapi/provider-accounts/${request.id}`);
    public deleteCloudAccountBatch = (request: IDeleteCloudAccountsBatchRequest): GeminiResponse<any> =>
        this.instance.delete(`vegaapi/provider-accounts/batch/`, { data: request.ids });
    public createCloudAccount = (request: IPostCloudAccountsRequest): GeminiResponse<any> =>
        this.instance.post('/vegaapi/provider-accounts', {
            provider_str: request.provider_str,
            account_id: request.account_id,
            account_name: request.account_name,
            parent_account_id: request.parent_account_id,
            enabled: request.enabled,
            secret_json: request.secret_json ?? null,
            external_id: request.external_id ?? null,
        });

    public createCloudAccounts = (request: IPostCloudAccountsBatchRequest): GeminiResponse<IPostCloudAccountsBatchResponse> =>
        this.instance.post(
            `/vegaapi/provider-accounts/csv`,
            { file: request.file },
            { headers: { ...this._handleRequest, 'Content-Type': 'multipart/form-data' } }
        );

    public updateCloudAccount = (request: IPutCloudAccountsRequest): GeminiResponse<any> =>
        this.instance.put(`/vegaapi/provider-accounts/${request.id}`, {
            provider_str: request.provider_str,
            account_id: request.account_id,
            account_name: request.account_name,
            parent_account_id: request.parent_account_id,
            enabled: request.enabled,
            secret_json: request.secret_json,
            external_id: request.external_id,
        });
}
