import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { GeminiResponse } from '@vegaplatformui/utils';

export class EnumsApi extends HttpClient {
    protected static classInstance?: EnumsApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public getEnums = <T>(url: string, params: object): GeminiResponse<T[]> => this.instance.get(url, { params: params });
}
