import React, { useEffect, useMemo } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { CloudUploadOutlined, FilePresent, Cancel } from '@mui/icons-material';
import { formatBytes, invalidFilenameMessage, isValidFilename, validateFile } from '@vegaplatformui/utils';
import { SnackBarOptions } from '../jotai/atom';
import { useSetAtom } from 'jotai';

export interface IFileDropZoneProps extends DropzoneOptions {
    selectedFiles: File[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
    isLoading: boolean;
    allowedMimeTypes: { [p: string]: string[] };
    inputOptions?: DropzoneOptions;
    acceptedFileList?: string[];
    onClickRemoveFile?: () => void;
    dontShowIcon?: boolean;
    dontShowList?: boolean;
    height?: string;
    width?: string;
    textToDisplay?: string;
}

const FileDropZone: React.FC<IFileDropZoneProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        ...props.inputOptions,
        onDrop: (acceptedFiles: File[], fileRejections) => {
            if (fileRejections.length > 0) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `${fileRejections[0].errors.length > 1 ? 'There were problems: ' : 'There was a problem: '} ${fileRejections[0].errors
                        .map((e) =>
                            e.message.includes('is larger than') ? `file is larger than ${formatBytes(props.inputOptions?.maxSize)}` : e.message
                        )
                        .join(', and ')}`,
                });
            }
            if (acceptedFiles.length > 0) {
                const invalidFiles: File[] = [];

                const validationPromises = acceptedFiles.map(async (file: File) => {
                    try {
                        await validateFile(file, props.allowedMimeTypes);

                        const isValid = isValidFilename(file.name);
                        if (!isValid) {
                            setSnackbarOptions({
                                snackBarProps: { open: true, autoHideDuration: 6000 },
                                alertProps: { severity: 'error' },
                                message: invalidFilenameMessage,
                            });
                            invalidFiles.push(file);
                        }
                    } catch (error) {
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 6000 },
                            alertProps: { severity: 'error' },
                            message: (error as Error).message,
                        });
                        invalidFiles.push(file);
                    }
                });

                Promise.all(validationPromises).then(() => {
                    if (invalidFiles.length === 0) {
                        props.setSelectedFiles(acceptedFiles);
                    }
                });
            }
        },
    });

    const files = props.selectedFiles.map((file) => (
        <Stack key={file.name} direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
            <ListItem key={file.name}>
                <ListItemIcon>
                    <FilePresent />
                </ListItemIcon>
                <ListItemText>
                    {file.name} - {formatBytes(file.size)}
                </ListItemText>
                {props.onClickRemoveFile && (
                    <IconButton onClick={props.onClickRemoveFile} className={cx(classes.RemoveFileButton)}>
                        <Cancel />
                    </IconButton>
                )}
            </ListItem>
            {props.isLoading && <LinearProgress className={cx(classes.LinearProgressBar)} />}
        </Stack>
    ));

    return (
        <section className={cx(classes.Section)}>
            <div {...getRootProps()} className={cx(classes.DropzoneContainer)}>
                <input {...getInputProps()} />
                <Stack className={cx(classes.Stack)} direction='column' justifyContent='center' alignItems='center' spacing={2}>
                    {props.dontShowIcon !== true && <CloudUploadOutlined className={cx(classes.UploadIcon)} />}
                    <Typography variant={'body1'} sx={{ color: 'black' }}>
                        {props.textToDisplay ? props.textToDisplay : 'Drag & drop a file here, or click to Browse'}
                    </Typography>
                    {props.acceptedFileList && (
                        <Typography variant={'subtitle2'} sx={{ color: 'grey' }}>
                            {props.acceptedFileList.length > 1 ? 'Supported Formats: ' : 'Supported Format: '} {props.acceptedFileList.join(',')}
                        </Typography>
                    )}
                </Stack>
            </div>
            {props.dontShowList !== true && (
                <aside>
                    <List>{files}</List>
                </aside>
            )}
        </section>
    );
};

const useStyles = customMakeStyles<IFileDropZoneProps>()((theme, props) => ({
    DropzoneContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '6px',
        borderColor: `${theme.palette.primary.light}60`,
        borderStyle: 'dashed',
        backgroundColor: `${theme.palette.primary.light}20`,
        color: '#BDBDBD',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        height: props.height && `${props.height}`,
        '&:focus': {
            borderStyle: 'solid',
            borderColor: `${theme.palette.primary.light}`,
        },
    },
    UploadIcon: {
        fontSize: '3rem',
        fill: theme.palette.primary.light,
    },
    Stack: {
        marginTop: props.height ? '-0.7rem' : '5rem',
        marginBottom: props.height ? '0rem' : '5rem',
        height: props.height && `${props.height}`,
    },
    Section: {
        width: props.width ? props.width : '100%',
    },
    LinearProgressBar: {
        width: '100%',
    },
    RemoveFileButton: {
        justifyContent: 'flex-end',
    },
}));

export { FileDropZone };
