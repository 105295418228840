import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Menu, MenuItem } from '@mui/material';
import { MenuProps } from '@mui/material/Menu/Menu';
import { IChatbotTopic } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConversationOptionsProps extends MenuProps {
    topic: IChatbotTopic;
    onStartTopicDeletion: (topic: IChatbotTopic) => void;
    startRenameTopic: (conversation: IChatbotTopic) => void;
}

const TopicOptions: React.FC<IConversationOptionsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { topic, onStartTopicDeletion, startRenameTopic, ...otherProps } = props;

    return (
        <Menu {...otherProps}>
            <MenuItem
                onClick={() => {
                    startRenameTopic(topic);
                }}
            >
                Rename
            </MenuItem>
            <MenuItem
                onClick={() => {
                    onStartTopicDeletion(topic);
                }}
            >
                Delete
            </MenuItem>
        </Menu>
    );
};

const useStyles = customMakeStyles<IConversationOptionsProps>()((theme, props) => ({}));

export { TopicOptions };
