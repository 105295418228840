import React, { useEffect, useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { DialogContent, DialogTitle, Drawer, IconButton, List, ListItem, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { ArrowBack } from '@mui/icons-material';
import { IKeycloakUser, IUser } from '@vegaplatformui/models';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useRouteUrls } from '@vegaplatformui/utils';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';

export interface IUsersBusinessGroupingsDrawerProps {
    user: IUser | undefined;
    onBackDrawer: () => void;
    isDrawerOpen: boolean;
    menuItems: GeminiMenuItem[];
    selectMenuItem: (menuItems: GeminiMenuItem[], selectedMenuItem: GeminiMenuItem, setMenuItems: any) => void;
    setSidebarMenuItems: React.Dispatch<React.SetStateAction<GeminiMenuItem[]>>;
}

const UsersBusinessGroupingsDrawer: React.FC<IUsersBusinessGroupingsDrawerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const [messageSubject, setMessageSubject] = useState<string>('');
    const [messageContent, setMessageContent] = useState<{ message: string; key: string }[]>([]);
    const { routeUrls } = useRouteUrls({});

    useEffect(() => {
        if (props.user && props.user.business_groupings !== null && props.user.business_groupings.length > 0) {
            setMessageSubject(`${props.user.given_name} ${props.user.family_name}`);
            setMessageContent(
                props.user.business_groupings.map((group) => {
                    return { message: group.display_name, key: group.id };
                })
            );
        }
    }, [props.user]);

    const setPreviousRoute = () => {
        const usersRoute = routeUrls.settings.tabs.find((route) => route.id === 'users');
        localStorage.setItem('previous_route', `/${routeUrls.settings.route.path}/${usersRoute?.id ?? 'users'}`);
    };

    const onClickHandler = () => {
        setPreviousRoute();
        const selectedParentMenuItem = props.menuItems.find((item) => item.title === 'Organize');
        const selectedMenuItem = selectedParentMenuItem?.subMenu?.find((item) => item.title === 'Business Groupings');
        props.selectMenuItem(props.menuItems, selectedMenuItem!, props.setSidebarMenuItems);
    };

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isDrawerOpen}
            onClose={props.onBackDrawer}
            aria-labelledby='provider-discovery-results-drawer'
        >
            <DialogTitle variant={'h6'} id={'provider-discovery-results-title'}>
                <Grid container>
                    <Grid xs={10}>
                        <Stack className={cx(classes.DrawerTitle)} direction={'row'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                            <IconButton title={'Close drawer'} onClick={props.onBackDrawer}>
                                <ArrowBack className={commonStyles.classes.DarkIconColor} />
                            </IconButton>
                            <Stack direction={'column'}>{messageSubject}'s Business Groupings</Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Stack className={cx(classes.DrawerContainer)} direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                    <Stack spacing={1}>
                        <List className={cx(classes.IntructionList)}>
                            {messageContent.map((message) => (
                                <ListItem key={message.key} className={cx(classes.InstructionStep)} onClick={onClickHandler}>
                                    <Link
                                        className={cx(classes.BusinessGroupLink)}
                                        style={{ color: theme.palette.primary.main }}
                                        to={`/${routeUrls.businessGroupings.path}/${message.key}`}
                                    >
                                        {message.message}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </Stack>
                </Stack>
            </DialogContent>
        </Drawer>
    );
};

const useStyles = customMakeStyles<IUsersBusinessGroupingsDrawerProps>()((theme, props) => ({
    FormTitle: {
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DrawerTitle: {},
    DrawerContainer: {
        marginLeft: '3rem',
    },
    BusinessGroupLink: {
        textDecoration: 'none',
        '&:hover': { textDecoration: 'underline', color: 'inherit' },
    },
    DrawerPaper: { width: '25%' },
    IntructionList: { listStyleType: 'disc', pl: 1 },
    InstructionStep: { display: 'list-item', marginLeft: '1.2rem' },
}));

export { UsersBusinessGroupingsDrawer };
