import React, { ReactElement, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { UserId } from '../recoil/atom';
import { styled, useTheme } from '@mui/material';
import { CustomContentProps, MaterialDesignContent, SnackbarProvider } from 'notistack';
import { StyledComponent } from 'styled-components';
import { Theme } from '@mui/material/styles';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseStyledComponentsHook {
    StyledMaterialDesignContent: typeof MaterialDesignContent;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseStyledComponentsHookProps {}

export function useStyledComponentsHook(props: IUseStyledComponentsHookProps): IUseStyledComponentsHook {
    const userId = useRecoilValue(UserId);
    const theme = useTheme();

    // const StyledNovuProvider = {
    //     bellButton: {
    //         dot: {
    //             rect: {
    //                 fill: theme.palette.primary.main,
    //             },
    //         },
    //     },
    //     header: {
    //         title: { color: theme.palette.text.primary, '::selection': { backgroundColor: 'highlight' } },
    //         markAsRead: {
    //             color: theme.palette.text.secondary,
    //             '::selection': { backgroundColor: 'highlight' },
    //         },
    //     },
    //     unseenBadge: {
    //         root: { background: theme.palette.primary.main },
    //     },
    //     loader: { root: { stroke: theme.palette.primary.main } },
    //     notifications: {
    //         root: {
    //             '.nc-notifications-list-item-title': {
    //                 '::selection': { backgroundColor: 'highlight' },
    //             },
    //             '.nc-notifications-list-item-read': {
    //                 backgroundColor: theme.palette.grey[50],
    //             },
    //             '.nc-notifications-list-item-unread': {
    //                 boxShadow: `5px 5px 8px 3px ${theme.palette.grey[100]}`,
    //                 '&:hover': {
    //                     backgroundImage: `linear-gradient(45deg, ${theme.palette.common.white},${theme.palette.grey[50]}, ${theme.palette.common.white})`,
    //                 },
    //             },
    //         },
    //         listItem: {
    //             layout: { color: theme.palette.text.primary, '::selection': { backgroundColor: 'highlight' } },
    //             unread: {
    //                 '::before': { background: theme.palette.primary.main },
    //             },
    //             timestamp: { color: theme.palette.text.secondary, '::selection': { backgroundColor: 'highlight' } },
    //         },
    //     },
    //     switch: {
    //         input: { '&.mantine-1tngfw:checked~label': { background: theme.palette.primary.main } },
    //     },
    // };

    const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
        '&.notistack-MuiContent-success': {
            backgroundColor: theme.palette.success.main,
        },
        '&.notistack-Snackbar': {
            width: '80% !important',
        },
        '&.notistack-MuiContent-error': {
            backgroundColor: theme.palette.error.main,
        },
        '&.notistack-MuiContent-info': {
            borderRadius: '4px',
            boxShadow: 'none',
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1.43,
            backgroundColor: 'rgb(232, 249, 255)',
            display: 'flex',
            padding: '6px 16px',
            color: 'rgb(12, 81, 102)',
            textAlign: 'center',
            width: '80% !important',
        },
    })) as typeof MaterialDesignContent;

    // const CustomSnackbarProvider = (children: ReactNode) => (
    //     <SnackbarProvider
    //         Components={{
    //             info: StyledMaterialDesignContent,
    //             error: StyledMaterialDesignContent,
    //             success: StyledMaterialDesignContent,
    //             warning: StyledMaterialDesignContent,
    //         }}
    //         maxSnack={5}
    //     >
    //         {Array.isArray(children) ? children.map((child) => child) : children}
    //     </SnackbarProvider>
    // );

    return { StyledMaterialDesignContent };
}
