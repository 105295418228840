import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Stack, Tab, tabClasses, Tabs, tabsClasses } from '@mui/material';
import { ITab, IUser, IUserSettingRealmRole, PermissionDetailTabsType, PermissionMetadata } from '@vegaplatformui/models';
import { useRouteUrls } from '@vegaplatformui/utils';
import { Link } from 'react-router';
import { RoleDetailMembersTab } from './role-detail-members-tab';
import { RoleDetailPermissionsTab } from './role-detail-permissions-tab';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleDetailsTabsProps {
    permissionDetailTabs: ITab[];
    permissionDetailTab: string;
    realmRole?: IUserSettingRealmRole | undefined;
    members: IUser[];
    areUsersLoading: boolean;
    onTabChange: (event: React.SyntheticEvent, newTab: string) => void;
    rolePermissions: string[];
    permissionSets: Map<string, PermissionMetadata[]>;
}

const RoleDetailsTabs: React.FC<IRoleDetailsTabsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { routeUrls } = useRouteUrls({});
    const { permissionDetailTab, permissionDetailTabs, onTabChange, realmRole, members, areUsersLoading, rolePermissions, permissionSets } = props;

    return (
        <Stack width={'100%'} height={'100%'}>
            {permissionDetailTabs.length > 1 && (
                <Tabs
                    className={cx(classes.TabsContainerStyle)}
                    aria-label={'notification settings tabs'}
                    variant='standard'
                    TabIndicatorProps={{ style: { height: '2px' } }}
                    value={permissionDetailTab}
                    onChange={(event, value) => onTabChange(event, value)}
                >
                    {permissionDetailTabs &&
                        permissionDetailTabs.map((currentTab) => (
                            <Tab
                                component={Link}
                                to={`/${routeUrls.settings.route.path}/permissions/${realmRole?.id}/${currentTab.id}`}
                                className={cx(classes.TabStyle)}
                                id={currentTab.id}
                                aria-controls={`${currentTab.id}-panel`}
                                key={currentTab.id}
                                label={currentTab.label}
                                aria-label={currentTab.label}
                                value={currentTab.id}
                            />
                        ))}
                </Tabs>
            )}
            <Box
                component={'div'}
                role={'tabpanel'}
                aria-describedby={permissionDetailTab}
                id={`${permissionDetailTab}-panel`}
                className={cx(classes.Tabpanel)}
            >
                {(() => {
                    switch (permissionDetailTab) {
                        case PermissionDetailTabsType.Members:
                            return <RoleDetailMembersTab realmRole={realmRole} members={members} isLoading={areUsersLoading} />;
                        case PermissionDetailTabsType.Permission:
                            return <RoleDetailPermissionsTab rolePermissions={rolePermissions} permissionSets={permissionSets} />;
                        default:
                            return <></>;
                    }
                })()}
            </Box>
        </Stack>
    );
};

const useStyles = customMakeStyles<IRoleDetailsTabsProps>()((theme, props) => ({
    TabsContainerStyle: {
        boxShadow: `inset 0 -2px 0 0 #D0D5DD`,
        [`& .${tabsClasses.indicator}`]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    //Started this style from https://mui-treasury.com/?path=/story/tabs-chrome--chrome
    TabStyle: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[300],
        opacity: 1,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}20`,
            color: theme.palette.primary.main,
        },
        [`&.${tabClasses.selected}`]: {
            color: theme.palette.primary.main,
        },
    },
    Tabpanel: {
        paddingTop: '1.5rem',
    },
}));

export { RoleDetailsTabs };
