import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid } from '@mui/material';
import { SettingsContentHeader } from '../settings-content-header';
import { SettingsV2Divider } from '../settings-v2-divider';
import { IUser, IUserSettingRealmRole } from '@vegaplatformui/models';
import { PermissionSettingsTableV2 } from './permission-settings-table-v2';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPermissionSettingsV2Props {
    isLoading: boolean;
    roles: IUserSettingRealmRole[];
    users: IUser[];
}

const PermissionSettingsV2: React.FC<IPermissionSettingsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <>
            <Grid container direction={'row'} spacing={2}>
                <SettingsContentHeader title={'Roles & Permissions'} subtitle={'Manage user roles and permissions.'} />
                <SettingsV2Divider />
                <Grid xs={12} item>
                    <PermissionSettingsTableV2 roles={props.roles} isLoading={props.isLoading} users={props.users} />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = customMakeStyles<IPermissionSettingsV2Props>()((theme, props) => ({}));

export { PermissionSettingsV2 };
