import { useKeycloak } from '@react-keycloak-fork/web';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import { useMemo } from 'react';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { ActionsApi } from '@vegaplatformui/apis';
import { IGetOptimizedResourceSchedulesHookRequest } from '@vegaplatformui/models';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IUseOptimizedScheduleApiHook {
    createOptimizedScheduleForPolicy: (hookRequest: IGetOptimizedResourceSchedulesHookRequest) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseOptimizedScheduleApiProps {}

export function useOptimizedScheduleApi(props: IUseOptimizedScheduleApiProps): IUseOptimizedScheduleApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const flags = useFlags();

    const actionsApi = useMemo(() => {
        const actionsApi = new ActionsApi();
        actionsApi.keycloak = keycloak;
        return actionsApi;
    }, [keycloak]);

    const { mutate: createOptimizedScheduleForPolicy } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem getting optimized schedule: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (response, variables, context) => {
            if (flags.optimizedAi && response && response.data.scheduled_on_times) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Successfully applied optimized schedule times`,
                });
                variables.setSchedule(
                    response.data.scheduled_on_times.map((date) => {
                        const dateAsString = date as unknown as string;
                        return new Date(dateAsString);
                    })
                );
            }
        },
        mutationFn: async (hookRequest: IGetOptimizedResourceSchedulesHookRequest) => {
            if (flags.optimizedAi) {
                return actionsApi.getOptimizedResourceSchedules(hookRequest.resource_ids);
            }
        },
    });

    return { createOptimizedScheduleForPolicy };
}
