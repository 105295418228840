import React, { useEffect, useState } from 'react';
import {
    BusinessGroupingWizardCard,
    ExpandedBusinessGroupTreeNodes,
    ParkingScheduleBusinessGroupId,
    ParkingScheduleTableBusinessGroupId,
    tableIdentifierKeys,
    useBusinessGroupingsApi,
    useParkingApi,
    useUsersApi,
} from '@vegaplatformui/sharedcomponents';
import { IBusinessGrouping, IBusinessGroupingDetailsForm, IBusinessGroupingRequest, IUser, IUserBusinessGroups } from '@vegaplatformui/models';
import { useRouteUrls } from '@vegaplatformui/utils';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingDetailsControllerProps {}

const BusinessGroupingDetailsController: React.FC<IBusinessGroupingDetailsControllerProps> = (props) => {
    const parkingScheduleTableIdentifier = tableIdentifierKeys.businessGroupDetailParkingScheduleTable;

    const businessGroupingApi = useBusinessGroupingsApi({});
    const usersApi = useUsersApi({});

    const [groupingToEdit, setGroupingToEdit] = useState<IBusinessGrouping | undefined>(undefined);
    const setTableBusinessGroupingId = useSetAtom(ParkingScheduleTableBusinessGroupId);
    const setBusinessGroupingIDState = useSetAtom(ParkingScheduleBusinessGroupId);
    const setExpandedNodes = useSetAtom(ExpandedBusinessGroupTreeNodes);
    const { routeUrls } = useRouteUrls({});

    const {
        setSelectedSchedules,
        selectedSchedules,
        parkingSchedules,
        resources,
        setIsDrawerOpen,
        isDrawerOpen,
        loadSelectedParkingSchedule,
        scheduleToEdit,
        onClickCreateSchedule,
        onClickEditSchedule,
        onClickDeleteSchedule,
        onClickDeleteSchedules,
        onOpenScheduleChangeDialog,
        isChangeScheduleDialogOpen,
        setIsChangeScheduleDialogOpen,
        onOpenRemoveAdvancedParkingDialog,
        onRemoveAdvancedParking,
        isRemoveAdvancedParkingDialogOpen,
        setIsRemoveAdvancedParkingDialogOpen,
        userHasGroupings,
        loadParkableResources,
        isParkableResourcesLoading,
        isParkingSchedulesLoading: isParkingLoading,
        setResourcesSelectedForParking,
        scheduleToDelete,
        setScheduleToDelete,
        isConfirmDeleteDialogOpen,
        setIsConfirmDeleteDialogOpen,
        isScheduledChangeStatus,
        onChangeScheduleStatuses,
        onClickOpenDeleteDialog,
        schedulesToChangeStatusOn,
        onOpenEnableDisableSchedulesDialog,
        isDeleteExemptionDialogOpen,
        setIsDeleteExemptionDialogOpen,
        scheduleWithExemption,
        setScheduleWithExemption,
        onClickOpenDeleteExemptionDialog,
        onClickDeleteExemption,
    } = useParkingApi({ parkingScheduleTableIdentifier: parkingScheduleTableIdentifier });

    useEffect(() => {
        return () => {
            const path = window.location.pathname.split('/')[1];

            !path.includes(routeUrls.businessGroupings.path) && setTableBusinessGroupingId && setTableBusinessGroupingId('');
            !path.includes(routeUrls.businessGroupings.path) && setExpandedNodes && setExpandedNodes([]);
        };
    }, [window.location]);

    useEffect(() => {
        const currentBusinessGroupId = window.location.pathname.split('/')[2];
        if (currentBusinessGroupId !== 'create') {
            businessGroupingApi.setBusinessGroupingId(currentBusinessGroupId);
            const bgToEdit = businessGroupingApi.businessGrouping;
            setGroupingToEdit(bgToEdit);
            businessGroupingApi.setBusinessGroupToEdit(bgToEdit);
            setBusinessGroupingIDState(currentBusinessGroupId);
            setTableBusinessGroupingId(currentBusinessGroupId);
        }
    }, [window.location.pathname, businessGroupingApi.businessGroupings, businessGroupingApi.businessGrouping]);

    const onSubmitCreateBusinessGrouping = (data: IBusinessGroupingRequest) => {
        businessGroupingApi.onSubmitCreateBusinessGrouping({
            request: {
                name: data.name,
                type: data.type,
                users: data.users,
                owned_by: data.owned_by,
                description: data.description,
                parent_id: data.parent_id,
            },
        });
    };

    const onSubmitEditBusinessGrouping = (data: IBusinessGroupingRequest) => {
        businessGroupingApi.onSubmitEditBusinessGrouping({
            request: {
                name: data.name,
                type: data.type,
                users: data.users,
                owned_by: data.owned_by,
                description: data.description,
                parent_id: data.parent_id ?? null,
            },
            businessUnitID: groupingToEdit!.id,
        });
    };

    const onSubmitBusinessGroupingForm = (data: IBusinessGroupingDetailsForm) => {
        const requestToSubmit: IBusinessGroupingRequest = {
            name: data.business_grouping_name,
            type: data.business_grouping_type,
            users: data.business_grouping_users,
            owned_by: data.business_grouping_owner!.id,
            parent_id: (data.business_grouping_parent as IUserBusinessGroups)?.id,
            description: data.business_grouping_description ?? '',
        };

        if (groupingToEdit !== undefined) {
            onSubmitEditBusinessGrouping(requestToSubmit);
        } else {
            onSubmitCreateBusinessGrouping(requestToSubmit);
        }
    };

    const unassignUsers = (users: IUser[]) => {
        console.log(users);
    };

    return (
        <BusinessGroupingWizardCard
            availableUsers={usersApi.usersV2}
            businessGroupingToEdit={groupingToEdit}
            businessGroupingTypes={businessGroupingApi.businessGroupingTypes ?? []}
            initalBusinessroupTreeNodes={businessGroupingApi.initialBusinessGroupings ?? []}
            isChangeScheduleDialogOpen={isChangeScheduleDialogOpen}
            isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
            isDeleteExemptionDialogOpen={isDeleteExemptionDialogOpen}
            isDrawerOpen={isDrawerOpen}
            isLoading={
                businessGroupingApi.isBusinessGroupingsLoading ||
                businessGroupingApi.isInitialBusinessGroupingsLoading ||
                businessGroupingApi.isBusinessGroupingLoading
            }
            isParkableResourcesLoading={isParkableResourcesLoading}
            isParkingLoading={isParkingLoading}
            isRemoveAdvancedParkingDialogOpen={isRemoveAdvancedParkingDialogOpen}
            isScheduled={isScheduledChangeStatus}
            isSubmitLoading={businessGroupingApi.isBusinessGroupingEditLoading || businessGroupingApi.isBusinessGroupingCreateLoading}
            loadParkableResources={loadParkableResources}
            onChangeScheduleStatuses={onChangeScheduleStatuses}
            onClickCreateSchedule={onClickCreateSchedule}
            onClickDeleteExemption={onClickDeleteExemption}
            onClickDeleteSchedule={onClickDeleteSchedule}
            onClickDeleteSelectedSchedules={onClickDeleteSchedules}
            onClickEditSchedule={onClickEditSchedule}
            onClickOpenDeleteDialog={onClickOpenDeleteDialog}
            onClickOpenDeleteExemptionDialog={onClickOpenDeleteExemptionDialog}
            onOpenEnableDisableSchedulesDialog={onOpenEnableDisableSchedulesDialog}
            onOpenRemoveAdvancedParkingDialog={onOpenRemoveAdvancedParkingDialog}
            onOpenScheduleChangeDialog={onOpenScheduleChangeDialog}
            onRemoveAdvancedParking={onRemoveAdvancedParking}
            onSubmitForm={onSubmitBusinessGroupingForm}
            onToggleDrawer={setIsDrawerOpen}
            parkableResources={resources}
            parkingSchedules={parkingSchedules}
            parkingScheduleTableIdentifier={parkingScheduleTableIdentifier}
            schedulesToChangeStatusOn={schedulesToChangeStatusOn}
            scheduleToDelete={scheduleToDelete}
            scheduleToEdit={scheduleToEdit}
            scheduleWithExemption={scheduleWithExemption}
            selectedSchedules={selectedSchedules}
            setIsChangeScheduleDialogOpen={setIsChangeScheduleDialogOpen}
            setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
            setIsDeleteExemptionDialogOpen={setIsDeleteExemptionDialogOpen}
            setIsRemoveAdvancedParkingDialogOpen={setIsRemoveAdvancedParkingDialogOpen}
            setResourcesSelectedForParking={setResourcesSelectedForParking}
            setScheduleToDelete={setScheduleToDelete}
            setScheduleToEdit={loadSelectedParkingSchedule}
            setScheduleWithExemption={setScheduleWithExemption}
            setSelectedSchedules={setSelectedSchedules}
            unassignUsers={unassignUsers}
            userHasGroupings={userHasGroupings}
            usersBusinessGroupings={businessGroupingApi.myBusinessUnitTree ?? []}
            validParentBusinessGroupings={businessGroupingApi.validBusinessGroupingParentsTree ?? []}
            usersBusinessGroupingsFlat={businessGroupingApi.validParentBusinessGroupings || []}
            width={'60%'}
        />
    );
};

export { BusinessGroupingDetailsController };
