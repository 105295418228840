import React, { useState } from 'react';
import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { ISSONameIDPolicyFormat, IUserSettingSSOSaml, IUserSettingSSOSamlForm } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SsoSettingsListItem } from './sso-settings-list-item';

interface SSOSettingsDetailCardProps {
    idpList: IUserSettingSSOSaml[];
    nameIDPolicyFormatOptions: ISSONameIDPolicyFormat[];
    onUpdate: (data: IUserSettingSSOSamlForm) => void;
    onDelete: (data: string) => void;
    onGenerateXMLMetaData: (url: string, filename: string) => void;
}

const SSOSettingsListCard: React.FC<SSOSettingsDetailCardProps> = (props) => {
    const theme = useTheme();
    const { cx, classes } = useStyles();
    const [expandedIdxList, setExpandedIdxList] = useState<number[]>([]);

    const onExpand = (idx: number) => {
        if (expandedIdxList.includes(idx)) {
            const filtered = expandedIdxList.filter((x) => x !== idx);
            setExpandedIdxList(filtered);
        } else {
            setExpandedIdxList([...expandedIdxList, idx]);
        }
    };
    return (
        <Stack>
            <Card elevation={0}>
                <CardContent>
                    <Stack spacing={0.5}>
                        <Typography variant='h3'>Configured SSO</Typography>
                        <Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>
                            Manage previously configured SSO; limit of 3 configurations.
                        </Typography>
                    </Stack>
                    <Stack marginTop={2}>
                        {props.idpList.length > 0 &&
                            props.idpList.map((x, idx) => (
                                <React.Fragment key={`${x.alias}_${idx}`}>
                                    <SsoSettingsListItem
                                        idx={idx}
                                        expandedIdxList={expandedIdxList}
                                        ssoItem={x}
                                        onExpand={onExpand}
                                        onSubmit={props.onUpdate}
                                        onDelete={props.onDelete}
                                        onGenerateXMLMetaData={props.onGenerateXMLMetaData}
                                    />
                                </React.Fragment>
                            ))}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
};

const useStyles = customMakeStyles()((theme) => ({
    EmptySSODescription: {
        color: theme.palette.grey[600],
        textAlign: 'center',
        marginTop: theme.spacing(2),
        paddingBlock: theme.spacing(5),
    },
}));

export { SSOSettingsListCard };
