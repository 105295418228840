import React from 'react';
import { Box, Typography, RadioGroup, FormControlLabel, Radio, Stack } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { BudgetPeriod } from '@vegaplatformui/models';

export interface IBudgetPeriodSelectorProps {
    selectedPeriod: BudgetPeriod['value'];
    onPeriodSelect: (period: BudgetPeriod['value']) => void;
}

const periods: BudgetPeriod[] = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'quarterly', label: 'Quarterly' },
    { value: 'annually', label: 'Annually' },
];

const BudgetPeriodSelector: React.FC<IBudgetPeriodSelectorProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { selectedPeriod, onPeriodSelect } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onPeriodSelect(event.target.value as BudgetPeriod['value']);
    };

    return (
        <Box className={cx(classes.Container)}>
            <Typography variant='body2' color='text.secondary' className={cx(classes.Label)}>
                Budget period:
            </Typography>

            <RadioGroup value={selectedPeriod} onChange={handleChange} className={cx(classes.PeriodOptions)}>
                <Stack direction='row' flexWrap='wrap' gap={2}>
                    {periods.map((period) => (
                        <FormControlLabel
                            key={period.value}
                            value={period.value}
                            control={<Radio className={cx(classes.Radio)} disableRipple />}
                            label={period.label}
                            className={cx(classes.PeriodOption, selectedPeriod === period.value && classes.SelectedOption)}
                        />
                    ))}
                </Stack>
            </RadioGroup>
        </Box>
    );
};

const useStyles = customMakeStyles<IBudgetPeriodSelectorProps>()((theme) => ({
    Container: {
        margin: `${theme.spacing(2)} 0`,
    },
    Label: {
        marginBottom: theme.spacing(1),
        fontSize: '0.9rem',
    },
    PeriodOptions: {
        marginTop: theme.spacing(1),
    },
    PeriodOption: {
        margin: 0,
        padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.action.hover,
        },
        '& .MuiFormControlLabel-label': {
            fontSize: '0.9rem',
        },
    },
    SelectedOption: {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light || theme.palette.action.selected,
    },
    Radio: {
        padding: theme.spacing(0.5),
        '&.Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
}));

export { BudgetPeriodSelector };
