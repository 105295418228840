import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Close, ContentCopy } from '@mui/icons-material';
import { SnackBarOptions } from '../jotai/atom';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICopyReportUrlDialogProps {
    isOpen: boolean;
    onClose: () => void;
    reportUrl: string;
}

const CopyReportUrlDialog: React.FC<ICopyReportUrlDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);

    const handleCopy = (stringToCopy: string) => {
        navigator.clipboard
            .writeText(stringToCopy)
            .then(
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'success' },
                        message: `Shared URL copied to clipboard`,
                    });
                },
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'error' },
                        message: `Failed to copy Shared URL to clipboard`,
                    });
                }
            )
            .catch(() => {
                alert(`Failed to copy Shared URL to clipboard`);
            });
    };

    return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={props.isOpen} onClose={props.onClose}>
            <DialogTitle className={cx(classes.DialogTitle, classes.DialogPadding)} variant={'h5'} id='sso-config-drawer-title'>
                <Stack direction={'column'} spacing={0}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction={'row'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                            <Typography fontWeight={500} variant={'h6'}>
                                Share Dashboard
                            </Typography>
                        </Stack>
                        <IconButton
                            aria-label={'Close SSO Configuration Drawer'}
                            id={'sso-config-drawer-close'}
                            className={cx(classes.CloseButton)}
                            onClick={props.onClose}
                        >
                            <Close className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(classes.CopyUrlContent, classes.DialogPadding)}>
                <TextField
                    fullWidth={true}
                    value={props.reportUrl}
                    slotProps={{
                        input: {
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton aria-label='copy shared url' onClick={() => handleCopy(props.reportUrl)}>
                                        <ContentCopy />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                    size={'small'}
                />
            </DialogContent>
        </Dialog>
    );
};

const useStyles = customMakeStyles<ICopyReportUrlDialogProps>()((theme, props) => ({
    CloseButton: { paddingRight: 0, paddingBottom: 0, paddingTop: 0 },
    DialogPadding: { paddingLeft: '1.5rem', paddingRight: '1.5rem' },
    DialogTitle: { paddingBottom: '1rem', paddingTop: '1.5rem' },
    CopyUrlContent: { paddingBottom: '1.5rem' },
}));

export { CopyReportUrlDialog };
