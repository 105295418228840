import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { IUser } from '@vegaplatformui/models';
import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardUnassignUsersDialogProps {
    usersToUnassign: IUser[];
    unassignUsers: (users: IUser[]) => void;
    isUnassignUserDialogOpen: boolean;
    onCloseDialog: () => void;
}

const BusinessGroupingWizardUnassignUsersDialog: React.FC<IBusinessGroupingWizardUnassignUsersDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    return (
        <Dialog open={props.isUnassignUserDialogOpen} onClose={props.onCloseDialog} fullWidth maxWidth={'sm'}>
            <DialogTitle variant={'h6'} id='unassign-users-dialog'>
                Are you sure you want to unassign users?
            </DialogTitle>
            <DialogContent>
                <Typography>You must click the 'Save Business Grouping' button to apply changes.</Typography>
            </DialogContent>
            <Stack direction={'row'} justifyContent={'flex-end'} columnGap={2} padding={2}>
                <Button variant='contained' className={commonStyles.classes.GreyButton} onClick={props.onCloseDialog}>
                    Cancel
                </Button>
                <Button color='error' variant='contained' onClick={() => props.unassignUsers(props.usersToUnassign)}>
                    Unassign Users
                </Button>
            </Stack>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardUnassignUsersDialogProps>()((theme, props) => ({}));

export { BusinessGroupingWizardUnassignUsersDialog };
