import React, { SyntheticEvent } from 'react';
import { Autocomplete, Checkbox, ListItemIcon, ListItemText, Skeleton, TextField, Tooltip } from '@mui/material';
import { FieldErrors } from 'react-hook-form';
import { customMakeStyles } from '@vegaplatformui/styling';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesConditionAutoCompleteValueProps {
    value: string;
    onChange: (value: any | null) => void;
    errors?: FieldErrors;
    disabled?: boolean;
    label: string;
    enumValues: any[];
    isLoading: boolean;
    isMissingValues: boolean;
}

const AssignmentRulesConditionAutoCompleteValue: React.FC<IAssignmentRulesConditionAutoCompleteValueProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { errors } = props;

    const getEnumValueDisplayName = (value: any): any => {
        try {
            return value.displayName ?? value.canonical_name ?? value.account_name ?? value;
        } catch (e) {
            return value;
        }
    };

    const getEnumValueValue = (value: any): any => {
        try {
            return value.name ?? value.account_id ?? value;
        } catch (e) {
            return value;
        }
    };

    const currentEnumValue = props.enumValues.find((enumValue) => getEnumValueValue(enumValue) === props.value) ?? null;

    return props.isLoading ? (
        <Skeleton variant='rounded' width={'100%'} height={40} />
    ) : (
        <Autocomplete
            isOptionEqualToValue={(option, value) => {
                return getEnumValueValue(option) === getEnumValueValue(value);
            }}
            value={currentEnumValue}
            disableCloseOnSelect
            size={'small'}
            disablePortal
            disabled={props.disabled}
            options={props.enumValues.filter((value: any) => value !== null)}
            fullWidth={true}
            onChange={(event: SyntheticEvent<Element, Event>, value: any | null) => {
                props.onChange(getEnumValueValue(value));
            }}
            getOptionLabel={(option) => getEnumValueDisplayName(option)}
            renderOption={(props, enumValue: any, { selected }) => (
                <li {...props} key={uuidv4()}>
                    <ListItemIcon>
                        <Checkbox checked={selected} />
                    </ListItemIcon>
                    <Tooltip title={getEnumValueDisplayName(enumValue)}>
                        <ListItemText
                            primary={getEnumValueDisplayName(enumValue)}
                            primaryTypographyProps={{ noWrap: true }}
                            secondaryTypographyProps={{ noWrap: true }}
                        />
                    </Tooltip>
                </li>
            )}
            renderInput={(params) => <TextField label={props.label} {...params} error={props.isMissingValues} />}
        />
    );
};

const useStyles = customMakeStyles<IAssignmentRulesConditionAutoCompleteValueProps>()((theme, props) => ({}));

export { AssignmentRulesConditionAutoCompleteValue };
