import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useFetchFileBlobAndDownload } from '../utilities/use-fetch-file-blob-and-download';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { CheckCircle, CheckCircleOutline, ContentCopy, Visibility, VisibilityOff } from '@mui/icons-material';
import { INewClientRegistrationInformation } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IClientRegistrationSuccessDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    newClientInformation: INewClientRegistrationInformation;
}

const ClientRegistrationSuccessDialog: React.FC<IClientRegistrationSuccessDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { isDialogOpen, onClose, newClientInformation } = props;
    const fetchFileBlobAndDownload = useFetchFileBlobAndDownload();
    const [haveCopied, setHaveCopied] = useState<boolean>(false);

    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const handleCopy = (stringToCopy: string) => {
        navigator.clipboard.writeText(stringToCopy).then(
            () => {
                navigator.clipboard.readText().then(
                    () =>
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 3000 },
                            alertProps: { severity: 'success' },
                            message: `Client registration information copied to clipboard`,
                        }),
                    () =>
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 3000 },
                            alertProps: { severity: 'error' },
                            message: `Failed to copy client registration information to clipboard`,
                        })
                );
            },
            () => alert('Failed to copy client registration information to clipboard')
        );
    };

    const handleOnClose = () => {
        setHaveCopied(false);
        onClose();
    };

    const handleJSONDownload = () => {
        fetchFileBlobAndDownload.downloadJsonAsFile(newClientInformation, `VegaApiKey_${Date.parse(new Date().toString())}`);
    };

    return (
        <Dialog
            open={props.isDialogOpen}
            fullWidth
            maxWidth={'sm'}
            aria-labelledby='client-registration-success-dialog-title'
            aria-describedby='client-registration-success-dialog-content'
        >
            <DialogTitle id='client-registration-success-dialog-title'>
                <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                    <CheckCircleOutline className={cx(classes.SuccessIcon)} fontSize={'large'} color={'success'} />
                    <Typography variant={'h6'} fontWeight={'bold'}>
                        API Client Registration successfully created!
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent id='client-registration-success-dialog-content'>
                <Stack direction={'column'} spacing={2}>
                    <Alert severity={'warning'}>Copy, download, and store the client secret below as it won’t be shown again.</Alert>
                    <Stack spacing={1}>
                        <TextField
                            size='medium'
                            label='Client Information'
                            multiline
                            value={JSON.stringify(newClientInformation, null, 4)}
                            fullWidth
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='copy client information'
                                            onClick={() => handleCopy(JSON.stringify(newClientInformation, null, 4))}
                                        >
                                            <ContentCopy />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Stack justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                            <Button variant='text' onClick={handleJSONDownload}>
                                Download as JSON
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <FormControlLabel
                    className={cx(classes.DialogStartButton)}
                    control={<Checkbox value={haveCopied} onChange={(event, checked) => setHaveCopied(checked)} />}
                    label={<Typography variant={'body2'}>I have copied the client information</Typography>}
                />
                <Button className={cx(classes.DialogEndButton)} disabled={!haveCopied} onClick={handleOnClose}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IClientRegistrationSuccessDialogProps>()((theme, props) => ({
    SuccessIcon: { height: 80, width: 80 },
    DialogActions: {
        marginBottom: '1rem',
        justifyContent: 'space-between',
    },
    DialogStartButton: {
        marginLeft: '0.5rem',
    },
    DialogEndButton: {
        marginRight: '1rem',
    },
}));

export { ClientRegistrationSuccessDialog };
