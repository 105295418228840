import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ApiKeyState } from '../recoil/atom';
import { useCommonStyles } from '@vegaplatformui/styling';
import { Card, CardContent, FormHelperText, Icon, Link, Stack, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiKeyManagementDialog } from './api-key-management-dialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApiKeyManagementCardProps {
    generateApiKey: () => void;
    isGenerateApiKeyLoading: boolean;
}

const ApiKeyManagementCard: React.FC<IApiKeyManagementCardProps> = (props) => {
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const [apiKey, setApiKey] = useRecoilState(ApiKeyState);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    useEffect(() => {
        setIsDialogOpen(!!apiKey);
    }, [apiKey]);

    const handleOnCloseApiKeyDialog = () => {
        setIsDialogOpen(false);
        setApiKey(null);
    };

    const DocumentationLink = () => {
        return (
            <Link
                target='_blank'
                rel='noreferrer noopener'
                style={{ color: 'blue', textDecorationColor: 'blue' }}
                href={'https://docs.vegacloud.io/docs/platformguide/data_api'}
            >
                here
            </Link>
        );
    };

    return (
        <Card elevation={0}>
            <CardContent>
                <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                    <Stack direction={'column'}>
                        <Typography variant='h3'>API Key Management</Typography>
                        <Stack direction={'column'} spacing={0} className={commonStyles.classes.PageCardSubtitle}>
                            <Typography variant='body2'>To connect securely to the Data API, a user requires an API key.</Typography>
                            <Typography variant='body2'>To learn more about API Key Management click {DocumentationLink()}.</Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={0} direction={'column'} width={'auto'} justifyContent='center' alignItems='flex-start'>
                        <LoadingButton
                            variant={'contained'}
                            endIcon={props.isGenerateApiKeyLoading ? <Icon></Icon> : <></>}
                            onClick={() => props.generateApiKey()}
                            loading={props.isGenerateApiKeyLoading}
                            loadingPosition={'end'}
                        >
                            {props.isGenerateApiKeyLoading ? 'Generating...' : 'Generate API Key'}
                        </LoadingButton>
                        <FormHelperText sx={{ fontStyle: 'italic' }}>
                            Generating a new API key will invalidate any existing key for this user.
                        </FormHelperText>
                    </Stack>
                </Stack>
                {apiKey && <ApiKeyManagementDialog isDialogOpen={isDialogOpen} apiKey={apiKey} onClose={handleOnCloseApiKeyDialog} />}
            </CardContent>
        </Card>
    );
};

export { ApiKeyManagementCard };
