import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { LinearIndeterminateLoading } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinearIndeterminateLoadingPageControllerProps {}

const LinearIndeterminateLoadingPageController: React.FC<ILinearIndeterminateLoadingPageControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <LinearIndeterminateLoading />;
};

const useStyles = customMakeStyles<ILinearIndeterminateLoadingPageControllerProps>()((theme, props) => ({}));

export { LinearIndeterminateLoadingPageController };
