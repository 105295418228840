import React, { useState, useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormHelperText, Grid2, LinearProgress, TextField, Typography } from '@mui/material';
import { IParkingSchedule, IBusinessGroupingNode, ITimezone } from '@vegaplatformui/models';
import { useRouteUrls } from '@vegaplatformui/utils';
import Box from '@mui/material/Box';
import { FormField } from '../../forms';
import { BusinessGroupingPicker } from '../../business-grouping-picker/business-grouping-picker';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { areParkingScheduleTabsDirty, ParkingScheduleBusinessGroupId } from '../../jotai/atom';
import { setSelectedNodes } from '../../utilities/selecting-assigned-business-grouping';
import { useLocation } from 'react-router';
import { useAtom, useSetAtom } from 'jotai';
import { TimezoneFormField } from '../../utilities/timezone-form-field';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDetailsTabProps {
    scheduleDescription: string | undefined;
    setScheduleDescription: (description: string) => void;
    scheduleName: string | undefined;
    setScheduleName: (name: string) => void;
    timezone: ITimezone | null;
    setTimezone: (timezone: ITimezone | null) => void;
    scheduleToEdit?: IParkingSchedule;
    businessGroupingId: string;
    loadParkableResources?: (bg_id?: string) => void;
    businessGroupHasResources: boolean;
    isLoading: boolean;
    businessGroupings: TreeNodeProps[];
    areDetailsValid: boolean | undefined;
}

const DetailsTab: React.FC<IDetailsTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedBusinessGroupingName, setSelectedBusinessGroupingName] = useState<string>('');
    const setBusinessGroupingIDState = useSetAtom(ParkingScheduleBusinessGroupId);
    const [areTabsDirty, setAreTabsAreDirty] = useAtom(areParkingScheduleTabsDirty);
    const [BGs, setBGs] = useState(setSelectedNodes(props.businessGroupings, [props.businessGroupingId]));
    const location = useLocation();
    const { routeUrls } = useRouteUrls({});

    useEffect(() => {
        setBGs(setSelectedNodes(props.businessGroupings, [props.businessGroupingId]));
    }, [props.businessGroupings]);

    const changeHandlerOnDropDownTreeSelect = (selectedNodes: IBusinessGroupingNode[]) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        setBusinessGroupingIDState(selectedNodes[0]?.id || '');
        setSelectedBusinessGroupingName(selectedNodes[0]?.name || '');
    };

    const onChangeTimeZone = (timezone: ITimezone | null) => {
        if (!timezone) return;
        !areTabsDirty && setAreTabsAreDirty(true);
        props.setTimezone(timezone);
    };

    return (
        <Grid2 spacing={1} container direction={'row'}>
            <Grid2 size={12}>
                <Typography variant={'h6'}>{props.scheduleToEdit ? 'Edit schedule details' : 'Create a recurring schedule'}</Typography>
                <Typography variant={'subtitle1'}>Fill in the details for the schedule.</Typography>
            </Grid2>
            <Grid2 size={6}>
                <FormField label='Schedule Name' htmlFor='schedule_name'>
                    <TextField
                        onChange={(e) => {
                            !areTabsDirty && setAreTabsAreDirty(true);
                            props.setScheduleName(e.target.value);
                        }}
                        id={'schedule_name'}
                        fullWidth={true}
                        size='small'
                        defaultValue={props.scheduleName}
                        placeholder={'Create a name for your schedule'}
                        error={
                            props.areDetailsValid !== undefined &&
                            !props.areDetailsValid &&
                            (props.scheduleName === '' || !props.scheduleName?.match(/\S{1}/i) || props.scheduleName === undefined)
                        }
                        helperText={
                            props.areDetailsValid !== undefined &&
                            !props.areDetailsValid &&
                            (props.scheduleName === '' || !props.scheduleName?.match(/\S{1}/i) || props.scheduleName === undefined) &&
                            'Schedule Name is required'
                        }
                    />
                </FormField>
            </Grid2>
            <Grid2 size={6}>
                <TimezoneFormField timezone={props.timezone} setTimezone={onChangeTimeZone} disableClearable={true} />
            </Grid2>
            <Grid2 size={12} sx={{ position: 'relative', bottom: 0 }}>
                <FormField
                    label={
                        <Typography variant={'inherit'}>
                            Description{' '}
                            <Box className={cx(classes.OptionalBox)} component={'span'} display='inline'>
                                (optional)
                            </Box>
                        </Typography>
                    }
                    htmlFor='description'
                >
                    <TextField
                        onChange={(e) => {
                            !areTabsDirty && setAreTabsAreDirty(true);
                            props.setScheduleDescription(e.target.value);
                        }}
                        id={'description'}
                        fullWidth={true}
                        size='small'
                        multiline={true}
                        rows={3}
                        defaultValue={props.scheduleDescription}
                        placeholder={'Add a description'}
                    />
                </FormField>
            </Grid2>
            <Grid2 size={12}>
                <FormField
                    label='Which business group, of which you are a member, has permission to access this schedule?'
                    htmlFor='business_grouping_select'
                >
                    <>
                        <BusinessGroupingPicker
                            readonly={location.pathname.includes(routeUrls.businessGroupings.path)}
                            changeHandler={changeHandlerOnDropDownTreeSelect}
                            mode='radioSelect'
                            data={BGs}
                            setData={setBGs}
                            hasError={props.areDetailsValid !== undefined && !props.areDetailsValid && props.businessGroupingId === ''}
                        />
                        {props.isLoading && (
                            <Box sx={{ width: '100%', marginTop: '-0.2rem', borderRadius: 3 }}>
                                <LinearProgress />
                            </Box>
                        )}
                        {!props.businessGroupHasResources && !props.isLoading && selectedBusinessGroupingName && (
                            <FormHelperText>
                                <Typography variant={'caption'} color={'error'}>
                                    {`No resources found for ${selectedBusinessGroupingName}`}
                                </Typography>
                            </FormHelperText>
                        )}
                        {props.areDetailsValid !== undefined && !props.areDetailsValid && props.businessGroupingId === '' && (
                            <FormHelperText>
                                <Typography variant={'caption'} color={'error'}>
                                    {`Business Grouping is required`}
                                </Typography>
                            </FormHelperText>
                        )}
                    </>
                </FormField>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IDetailsTabProps>()((theme, props) => ({
    Dropdowns: {
        minWidth: '100%',
    },
    OptionalBox: { color: theme.palette.grey[400] },
    TimeZoneInputLabel: {
        marginTop: '-.5rem',
        color: '#9C9CA7',
    },
}));

export { DetailsTab };
