import React, { SyntheticEvent, useEffect } from 'react';
import { useCommonStyles, customMakeStyles } from '@vegaplatformui/styling';
import {
    Autocomplete,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControlLabel,
    IconButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LinkAwsAccountForm, ICloudProviderAccount } from '@vegaplatformui/models';
import { ArrowBack, Close } from '@mui/icons-material';
import { DiscoveryDetails, OrganizationId } from '../jotai/atom';
import { FormField } from '../forms';
import { useFetchFileBlobAndDownload } from '../utilities/use-fetch-file-blob-and-download';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountDiscoveryInProgressAlert } from './account-discovery-in-progress-alert';
import { useAtomValue } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkAwsAccountDialogProps {
    isDialogOpen: boolean;
    onBackDrawer: () => void;
    onCloseDialog: () => void;
    accountToEdit?: ICloudProviderAccount;
    awsParentAccounts: ICloudProviderAccount[];
    onSubmitLinkAwsAccountForm: (data: LinkAwsAccountForm) => void;
    setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void;
    onOpenDeleteAccountDialog?: (account: ICloudProviderAccount) => void;
}

const validationSchema: yup.ObjectSchema<LinkAwsAccountForm> = yup.object().shape({
    account_alias: yup.string(),
    is_payer_account: yup.boolean().required(),
    payer_account: yup.mixed<ICloudProviderAccount>().when('is_payer_account', {
        is: false,
        then: (schema) => schema.required('Select a payer account'),
        otherwise: (schema) => schema.nullable(),
    }),
    account_id: yup
        .string()
        .required('Required')
        .matches(/^(\d*)$/i, 'Account ID should only contain digits')
        .length(12, 'Value must be 12 digits'),
    external_id: yup.string().required('Required'),
    enabled: yup.boolean().required(),
});

const LinkAwsAccountDrawer: React.FC<ILinkAwsAccountDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const organizationId = useAtomValue(OrganizationId);
    const fetchFileBlobAndDownload = useFetchFileBlobAndDownload();
    const discoveryDetails = useAtomValue(DiscoveryDetails);

    const {
        watch,
        handleSubmit,
        control,
        setValue,
        reset,
        formState,
        trigger,
        formState: { errors, isValid, isSubmitSuccessful },
    } = useForm<LinkAwsAccountForm>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            account_alias: props.accountToEdit?.account_name ?? '',
            is_payer_account:
                (props.accountToEdit && !(props.accountToEdit.parent_account_id !== null || props.accountToEdit.parent_id !== null)) ?? false,
            payer_account:
                props.accountToEdit && props.accountToEdit.parent_account_id
                    ? props.awsParentAccounts.find((account) => account.account_id === props.accountToEdit?.parent_account_id) ?? null
                    : props.accountToEdit && props.accountToEdit.parent_id
                    ? props.awsParentAccounts.find((account) => account.id === props.accountToEdit?.parent_id) ?? null
                    : props.accountToEdit === undefined && props.awsParentAccounts && props.awsParentAccounts.length > 0
                    ? props.awsParentAccounts[0]
                    : null,
            account_id: props.accountToEdit?.account_id ?? '',
            external_id: props.accountToEdit?.external_id ?? `vega:${organizationId}`,
            enabled: props.accountToEdit?.enabled ?? true,
        },
    });

    useEffect(() => {
        if (props.accountToEdit !== undefined) {
            reset(
                {
                    account_alias: props.accountToEdit?.account_name ?? '',
                    is_payer_account: !(props.accountToEdit.parent_account_id !== null || props.accountToEdit.parent_id !== null),
                    payer_account:
                        props.accountToEdit && props.accountToEdit.parent_account_id
                            ? props.awsParentAccounts.find((account) => account.account_id === props.accountToEdit?.parent_account_id) ?? null
                            : props.accountToEdit && props.accountToEdit.parent_id
                            ? props.awsParentAccounts.find((account) => account.id === props.accountToEdit?.parent_id) ?? null
                            : null,
                    account_id: props.accountToEdit?.account_id ?? '',
                    external_id: props.accountToEdit?.external_id ?? `vega:${organizationId}`,
                    enabled: props.accountToEdit?.enabled ?? true,
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
        if (props.accountToEdit === undefined && props.awsParentAccounts) {
            setValue('payer_account', props.awsParentAccounts.length > 0 ? props.awsParentAccounts[0] : null, { shouldValidate: true });
        }
        if (props.accountToEdit === undefined && organizationId) {
            setValue('external_id', `vega:${organizationId}`, { shouldValidate: true });
        }
    }, [props.accountToEdit, props.awsParentAccounts, organizationId]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    const onCloseDrawerAndResetState = () => {
        props.onCloseDialog();
        reset({});
    };

    const onBackDrawerAndResetState = () => {
        props.onBackDrawer();
        reset({});
    };

    const onSubmitForm: SubmitHandler<LinkAwsAccountForm> = (data) => {
        const formToSubmit = {
            ...data,
        };
        props.onSubmitLinkAwsAccountForm(formToSubmit);
        props.onCloseDialog();
    };

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isDialogOpen}
            onClose={onCloseDrawerAndResetState}
            aria-labelledby='link-aws-account-drawer'
            hideBackdrop={!props.accountToEdit}
        >
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} variant={'h6'} id={'link-aws-account-drawer-title'}>
                <Stack spacing={0.5} direction={'column'} justifyContent={'space-between'} alignItems={'flex-start'}>
                    <Stack
                        direction={'row'}
                        width={'100%'}
                        justifyContent={!props.accountToEdit ? 'space-between' : 'flex-end'}
                        alignItems='center'
                        spacing={1}
                    >
                        {props.accountToEdit === undefined && (
                            <Button onClick={onBackDrawerAndResetState} variant='text' startIcon={<ArrowBack />}>
                                <Typography variant={'caption'}>Back</Typography>
                            </Button>
                        )}
                        <IconButton size={'small'} aria-label={'Close'} className={cx(classes.CloseButton)} onClick={onCloseDrawerAndResetState}>
                            <Close fontSize={'small'} className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                    <Stack direction={'column'}>
                        {(props.accountToEdit && 'Edit AWS Account') ?? 'Link AWS Cloud Account'}
                        {props.accountToEdit === undefined ? (
                            <Typography variant={'body1'}>
                                Click the button to access the Vega documentation, where you'll find detailed guidance and additional information.
                            </Typography>
                        ) : (
                            <></>
                        )}
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={commonStyles.classes.FormContentPadding}>
                <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                    {props.accountToEdit === undefined && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            variant={'outlined'}
                            type={'button'}
                            href={'https://docs.vegacloud.io/docs/providers/aws'}
                            target={'_blank'} //open in a new tab
                            rel={'noreferrer noopener'} //blocks communication between the new tab and the original tab (security measure)
                        >
                            Vega AWS Guide
                        </Button>
                    )}
                    {props.accountToEdit && discoveryDetails.is_discovery && <AccountDiscoveryInProgressAlert />}
                    <Stack
                        id={'link-aws-account-drawer-form'}
                        component={'form'}
                        spacing={2}
                        onSubmit={handleSubmit((data) => {
                            onSubmitForm(data);
                        })}
                        width={'100%'}
                        className={cx(classes.FormTitle)}
                    >
                        <Stack className={cx(classes.FormTitle)} direction={'column'} spacing={0}>
                            <Typography fontWeight={600} variant={'body1'}>
                                Account Details
                            </Typography>
                            <Typography variant={'body2'}>
                                The Account or Canonical Name is automatically populated from your cloud service provider.
                            </Typography>
                        </Stack>
                        <FormField id={'payer_account'} label='Payer Account ID' htmlFor='payer_account'>
                            <Controller
                                name={'payer_account'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        id={'payer_account'}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        value={value}
                                        size={'small'}
                                        disablePortal
                                        disabled={!!props.accountToEdit || watch('is_payer_account')}
                                        options={props.awsParentAccounts}
                                        fullWidth={true}
                                        onChange={(event: SyntheticEvent<Element, Event>, value: ICloudProviderAccount | null) => {
                                            onChange(value);
                                        }}
                                        getOptionLabel={(option) => `${option.account_id}`}
                                        renderOption={(props, parentAccount: ICloudProviderAccount, { selected }) => (
                                            <li {...props} key={`${parentAccount.id}`}>
                                                <ListItemIcon>
                                                    <Checkbox checked={selected} />
                                                </ListItemIcon>
                                                <Stack spacing={0.1}>
                                                    <Tooltip title={`Account ID: ${parentAccount.account_id}`}>
                                                        <ListItemText
                                                            primary={`Account ID: ${parentAccount.account_id}`}
                                                            slotProps={{ primary: { noWrap: true }, secondary: { noWrap: true } }}
                                                        />
                                                    </Tooltip>
                                                    {parentAccount.canonical_name && (
                                                        <Tooltip title={`Account: ${parentAccount.canonical_name}`}>
                                                            <ListItemText
                                                                primary={`Account: ${parentAccount.canonical_name}`}
                                                                slotProps={{ primary: { noWrap: true }, secondary: { noWrap: true } }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                    {parentAccount.account_name && (
                                                        <Tooltip title={`Account Alias: ${parentAccount.account_name}`}>
                                                            <ListItemText
                                                                primary={`Account Alias: ${parentAccount.account_name}`}
                                                                slotProps={{ primary: { noWrap: true }, secondary: { noWrap: true } }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <Tooltip
                                                title={
                                                    value?.account_id ? (
                                                        <>
                                                            <p>{`Account ID: ${value?.account_id}`}</p>
                                                            {value?.canonical_name ? <p>{`Account: ${value?.canonical_name}`}</p> : ''}
                                                            {value?.account_name ? <p>{`Account Alias: ${value?.account_name}`}</p> : ''}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                placement='bottom-start'
                                            >
                                                <TextField
                                                    {...params}
                                                    label={value ? '' : 'Enter Payer Account ID'}
                                                    error={!!errors.payer_account}
                                                    helperText={
                                                        (errors.payer_account?.message as string) ??
                                                        (!props.accountToEdit && watch('is_payer_account') && 'Input will be ignored')
                                                    }
                                                />
                                            </Tooltip>
                                        )}
                                    />
                                )}
                            />
                        </FormField>
                        <Stack direction={'column'} spacing={0}>
                            <FormField label='Account ID' htmlFor='account_id'>
                                <Controller
                                    name={'account_id'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            onChange={(e) => {
                                                onChange(e.target.value);
                                            }}
                                            fullWidth
                                            value={value}
                                            id={'account_id'}
                                            size='small'
                                            slotProps={{ input: { readOnly: !!props.accountToEdit } }}
                                            error={!!errors.account_id}
                                            placeholder={'Enter Account ID'}
                                            helperText={errors.account_id?.message as string}
                                        />
                                    )}
                                />
                            </FormField>
                            <FormField id={'is_payer_account'} htmlFor='is_payer_account'>
                                <Controller
                                    name={'is_payer_account'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                            className={cx(classes.EnabledSwitch)}
                                            control={
                                                <Checkbox
                                                    id={'is_payer_account'}
                                                    checked={value}
                                                    size={'small'}
                                                    inputProps={{ 'aria-label': 'Checkbox is_payer_account' }}
                                                />
                                            }
                                            disabled={!!props.accountToEdit}
                                            onChange={(event, checked) => {
                                                onChange(checked);
                                                //This will trigger the revalidation of the payer_account field
                                                trigger('payer_account');
                                            }}
                                            label={<Typography variant={'caption'}>This is a payer account (organization root).</Typography>}
                                            labelPlacement='end'
                                        />
                                    )}
                                />
                            </FormField>
                        </Stack>
                        <FormField label='External ID' htmlFor='external_id'>
                            <Controller
                                name={'external_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        fullWidth
                                        id={'external_id'}
                                        size='small'
                                        error={!!errors.external_id}
                                        placeholder={'Enter External ID'}
                                        helperText={errors.external_id?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Vega Account Alias (optional)' htmlFor={'account_alias'}>
                            <Controller
                                name={'account_alias'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'account_alias'}
                                        size='small'
                                        fullWidth
                                        error={!!errors.account_alias}
                                        placeholder={'Create an alias'}
                                        helperText={
                                            (errors.account_alias?.message as string) ??
                                            `${props.accountToEdit ? 'Edit' : 'Create a'} nickname for this account.`
                                        }
                                    />
                                )}
                            />
                        </FormField>
                        <FormField id={'enabled'} label='Account Status' htmlFor='enabled'>
                            <Controller
                                name={'enabled'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        className={cx(classes.EnabledSwitch)}
                                        control={
                                            <Switch
                                                id={'enabled'}
                                                onClick={() => setValue('enabled', !value, { shouldDirty: true, shouldValidate: true })}
                                                checked={value}
                                            />
                                        }
                                        label={`This account is ${value ? 'enabled' : 'disabled'}`}
                                        labelPlacement='end'
                                    />
                                )}
                            />
                        </FormField>
                        {/*Just adding false in so it will never display*/}
                        {props.accountToEdit === undefined && false && (
                            <Typography variant={'caption'}>
                                Accounts will automatically be tested after creation and there will be a discovery every 30 minutes to search for
                                errors.
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack
                    width={'100%'}
                    direction={'row'}
                    justifyContent={!props.accountToEdit ? 'flex-end' : 'space-between'}
                    alignItems='center'
                    spacing={0}
                >
                    {props.accountToEdit && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            id={'link-aws-account-drawer-delete'}
                            disableElevation={true}
                            variant={'contained'}
                            onClick={() => {
                                const account = props.accountToEdit!;
                                props.onOpenDeleteAccountDialog && props.onOpenDeleteAccountDialog(account);
                                onCloseDrawerAndResetState();
                            }}
                            color={'error'}
                        >
                            Delete
                        </Button>
                    )}

                    {props.accountToEdit !== undefined ? (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-aws-account-drawer-form'}
                        >
                            Save
                        </Button>
                    ) : (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-aws-account-drawer-form'}
                            onClick={() => {
                                if (!isValid) {
                                    trigger();
                                }
                            }}
                        >
                            Link
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Drawer>
    );
};

const useStyles = customMakeStyles<ILinkAwsAccountDialogProps>()((theme, props) => ({
    EnabledSwitch: { marginLeft: '-0.45rem' },
    CloseButton: {
        padding: '6px',
    },
    CloudFormationTitle: {
        fontWeight: 600,
    },
    FormTitle: {
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DeleteButton: { marginLeft: '4rem' },
    DrawerPaper: { width: '35%' },
    IntructionList: { listStyleType: 'disc', pl: 1, marginLeft: '1rem' },
    InstructionStep: { display: 'list-item', marginLeft: '1rem' },
    TemplateButton: {
        marginTop: '2rem',
    },
}));

export { LinkAwsAccountDrawer };
