import React from 'react';
import { Dialog, DialogContent, DialogTitle, Stack, Typography, Button, IconButton, Checkbox, FormControlLabel, DialogActions } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { IClientRegistrationSuccessDialogProps } from './client-registration-success-dialog';

export interface IClientDeletionDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const ClientDeletionDialog: React.FC<IClientDeletionDialogProps> = ({ isDialogOpen, onClose, onDelete }) => {
    const { classes, cx } = useStyles({ isDialogOpen, onClose, onDelete });
    const commonStyles = useCommonStyles();
    const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

    const onDeleteConfirmation = () => {
        setIsCheckboxChecked(false);
        onDelete();
        onClose();
    };

    const onClickCancelButton = () => {
        setIsCheckboxChecked(false);
        onClose();
    };

    return (
        <Dialog open={isDialogOpen} aria-labelledby='delete-client-dialog-title' aria-describedby='delete-client-form'>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} id='delete-client-dialog-title'>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ErrorOutline color={'error'} />
                    <Typography variant={'h6'}> Delete API client registration</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)} id={'delete-client-form'}>
                <Typography variant='body2'>Are you sure you want to delete this API client registration?</Typography>
                <Typography variant='body2'>This action cannot be undone.</Typography>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                    <FormControlLabel
                        control={<Checkbox value={isCheckboxChecked} onChange={(event, checked) => setIsCheckboxChecked(checked)} />}
                        label={<Typography variant={'body2'}>I understand</Typography>}
                    />
                    <Stack width={'50%'} direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1.5}>
                        <Button variant='cancel' onClick={onClickCancelButton} disabled={false}>
                            Cancel
                        </Button>
                        <Button color={'error'} variant='contained' onClick={onDeleteConfirmation} disabled={!isCheckboxChecked}>
                            Delete
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IClientDeletionDialogProps>()((theme, props) => ({}));

export { ClientDeletionDialog };
