import React from 'react';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { SendRounded } from '@mui/icons-material';
import { IChatbotPostQuestionHookRequest } from '@vegaplatformui/models';
import { v4 as uuidv4 } from 'uuid';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChatbotInputProps {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    handleSendMessage: (hookRequest: IChatbotPostQuestionHookRequest) => void;
}

const ChatbotInput: React.FC<IChatbotInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { message, setMessage, handleSendMessage } = props;

    return (
        <Stack className={cx(classes.Input)} direction={'row'} justifyContent='center' alignItems='flex-end' spacing={1}>
            <TextField
                type={'text'}
                size={'small'}
                sx={{ flexGrow: 1, maxWidth: '90%' }}
                placeholder='Ask a question...'
                fullWidth
                value={message}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && message.length > 0) {
                        handleSendMessage({ message, newId: uuidv4() });
                    }
                }}
                onChange={(e) => setMessage(e.target.value)}
                slotProps={{
                    input: {
                        className: cx(classes.TextField),
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    size={'small'}
                                    className={cx(classes.InputAdornment)}
                                    color={'primary'}
                                    aria-label='send question'
                                    disabled={message.length < 1}
                                    onClick={(e) => {
                                        handleSendMessage({ message, newId: uuidv4() });
                                    }}
                                >
                                    <SendRounded fontSize={'small'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<IChatbotInputProps>()((theme, props) => ({
    Input: { paddingTop: '4px', width: '100%' },
    IconButtonSize: { height: '56px', width: '56px' },
    TextField: { borderRadius: '8px', backgroundColor: theme.palette.common.white },
    InputAdornment: {},
}));

export { ChatbotInput };
