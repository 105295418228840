import React from 'react';
import { QuicksightReportsController } from '../quicksight-reports-controller/quicksight-reports-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomDashboardsProps {}

const CustomDashboards: React.FC<ICustomDashboardsProps> = (props) => {
    return <QuicksightReportsController folderNames={['custom']} />;
};

export { CustomDashboards };
