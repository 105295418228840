import { HttpClient } from './http-common';
import { IUser, IListUsersRequest, IBusinessGrouping } from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';

export class UsersApi extends HttpClient {
    protected static classInstance?: UsersApi;
    public baseURL!: string;
    public keycloak!: Keycloak;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new UsersApi();
        }

        return this.classInstance;
    }

    public loadUser = (userId: string): GeminiResponse<IUser> => this.instance.get(`/vegaapi/users/${userId}`);

    public loadUsers = (request?: IListUsersRequest): GeminiResponse<IUser[]> => this.instance.get('vegaapi/users');

    public loadUsersBusinessGroups = (auth_user_id: string): GeminiResponse<IBusinessGrouping[]> =>
        this.instance.get(`vegaapi/users/${auth_user_id}/business_units`);
}
