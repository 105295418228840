import { TreeNodeProps } from 'react-dropdown-tree-select';

export const setSelectedNodes = (tree: TreeNodeProps[], selectedNodeIDs: string[]): TreeNodeProps[] => {
    return tree.map((item) => {
        if (!!item.children) {
            return {
                ...item,
                checked: selectedNodeIDs.includes(item.value),
                children: setSelectedNodes(item.children, selectedNodeIDs),
            };
        }
        return { ...item, checked: selectedNodeIDs.includes(item.value) };
    });
};
