import React from 'react';
import { NotificationSettingsTab } from '../../utilities/notification-settings-tab';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Divider, Grid2, Typography } from '@mui/material';
import { CommunicationSettingItem } from './communication-setting-item';
import { ICommunicationSettingItem } from '@vegaplatformui/models';
import { CommunicationSettingItemLoadingSkeleton } from './communication-setting-item-loading-skeleton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationCommunicationSettingsProps {
    defaultCommunicationSettings: ICommunicationSettingItem[];
    communicationSettings: ICommunicationSettingItem[];
    isDefaultCommunicationItemsLoading: boolean;
}

const NotificationCommunicationSettings: React.FC<INotificationCommunicationSettingsProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <NotificationSettingsTab title={'Communication Settings'} subtitle={'View email and phone number set for communication.'} isLoading={false}>
            <Grid2 className={cx(classes.ColumnMinWidth)} size={3}>
                <Typography>Type</Typography>
            </Grid2>
            <Grid2 className={cx(classes.ColumnMinWidth)} size={5}>
                <Typography>Value</Typography>
            </Grid2>
            <Grid2 className={cx(classes.Divider)} size={12}>
                <Divider variant={'fullWidth'} />
            </Grid2>
            <Grid2 size={12}>
                {props.isDefaultCommunicationItemsLoading
                    ? [1, 2].map((value) => <CommunicationSettingItemLoadingSkeleton key={value} />)
                    : props.defaultCommunicationSettings.map((setting) => (
                          <CommunicationSettingItem key={`${setting.type}-${setting.value}`} communicationSetting={setting} />
                      ))}
                {props.communicationSettings.map((setting) => (
                    <CommunicationSettingItem key={`${setting.type}-${setting.value}`} communicationSetting={setting} />
                ))}
            </Grid2>
        </NotificationSettingsTab>
    );
};

const useStyles = customMakeStyles<INotificationCommunicationSettingsProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '50px',
        },
    },
    Divider: { marginBottom: '1rem' },
}));

export { NotificationCommunicationSettings };
