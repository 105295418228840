import { IOrganizationMetadata } from '@vegaplatformui/models';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useRecoilValue } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { OrganizationsApi } from '@vegaplatformui/apis';
import { OrganizationId } from '../recoil/atom';
import { queryKeys } from './query-keys';

export interface IUseOrganizationsChildrenApiHook {
    organizationChildrenMetadata: IOrganizationMetadata | undefined;
    currentChildSelected: IOrganizationMetadata | null;
    setCurrentChildSelected: React.Dispatch<React.SetStateAction<IOrganizationMetadata | null>>;
    orgId: string | undefined;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseOrganizationsChildrenApiProps {}

export function useOrganizationsChildrenApi(props: IUseOrganizationsChildrenApiProps): IUseOrganizationsChildrenApiHook {
    const { keycloak } = useKeycloak();
    const orgId = useRecoilValue<string | undefined>(OrganizationId);
    const queryClient = useQueryClient();
    const [currentChildSelected, setCurrentChildSelected] = useState<IOrganizationMetadata | null>(null);
    const flags = useFlags();

    const organizationsApi = useMemo(() => {
        const organizationApi = new OrganizationsApi();
        organizationApi.keycloak = keycloak;
        return organizationApi;
    }, [keycloak]);

    // get organization data
    const {
        data: organizationChildrenMetadata,
        isLoading: isOrganizationChildrenMetadataLoading,
        isSuccess: isOrganizationChildrenMetadataSuccess,
    } = useQuery({
        queryKey: [queryKeys.organizationsApi.getOrganizationChildren, orgId],
        queryFn: async ({ signal }) => {
            if (orgId) {
                await queryClient.cancelQueries({
                    queryKey: [queryKeys.organizationsApi.getOrganizationChildren],
                });
                const response = await organizationsApi.getOrganizationChildren(orgId, signal);
                return response.data;
            }
        },
        enabled: flags.portalNavigationDropdown && !!orgId,
        meta: {
            errorMessage: 'There was a problem loading the client list.',
        },
    });

    return {
        currentChildSelected,
        setCurrentChildSelected,
        organizationChildrenMetadata: organizationChildrenMetadata,
        orgId,
    };
}
