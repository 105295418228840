import React, { useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Select,
    MenuItem,
    Typography,
    Button,
    FormHelperText,
    DialogActions,
} from '@mui/material';
import { FormField } from '../forms';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { INewClient } from '@vegaplatformui/models';
import { useTheme } from '@mui/material/styles';
import { useCommonStyles } from '@vegaplatformui/styling';

export interface IClientRegistrationDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    clientName: string | undefined;
    expiration: string | undefined;
    expirationOptions: {};
    onDataSubmit: (data: INewClient) => void;
}

const validationSchema: yup.ObjectSchema<INewClient> = yup.object().shape({
    client_name: yup.string().required('Client name is required'),
    expiration_duration: yup.string().required('Expiration duration is required'),
});

const ClientRegistrationDialog: React.FC<IClientRegistrationDialogProps> = ({
    isDialogOpen,
    onClose,
    clientName,
    expiration,
    onDataSubmit,
    expirationOptions,
}) => {
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const {
        handleSubmit,
        control,
        reset,
        formState,
        formState: { isDirty, errors, isSubmitSuccessful },
    } = useForm<INewClient>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            client_name: clientName ?? '',
            expiration_duration: expiration ?? '',
        },
    });

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [formState, isSubmitSuccessful, reset]);

    const onCloseDialogAndForm = () => {
        onClose();
        reset();
    };

    return (
        <Dialog
            open={isDialogOpen}
            onClose={onCloseDialogAndForm}
            aria-labelledby='client-registration-dialog-title'
            aria-describedby='client-registration-form'
        >
            <DialogTitle className={commonStyles.cx(commonStyles.classes.FormTitlePadding)} id='client-registration-dialog-title' variant={'h6'}>
                Create API Client Registration
            </DialogTitle>
            <DialogContent className={commonStyles.cx(commonStyles.classes.FormContentPadding)}>
                <Stack
                    component={'form'}
                    onSubmit={handleSubmit((data: INewClient) => {
                        onDataSubmit(data);
                    })}
                    spacing={1}
                    id={'client-registration-form'}
                >
                    <FormField label='Client Name' htmlFor='client_name'>
                        <Controller
                            name='client_name'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id='client_name'
                                    type='text'
                                    size='small'
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    placeholder='Create a name'
                                    helperText={errors.client_name?.message as string}
                                    error={!!errors.client_name}
                                />
                            )}
                        ></Controller>
                    </FormField>
                    <Stack>
                        <FormField label='Expiration' aria-label={'Expiration input for client registration'} htmlFor='expiration_duration'>
                            <Controller
                                name='expiration_duration'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        id='expiration_duration'
                                        size='small'
                                        fullWidth
                                        value={value}
                                        error={!!errors.expiration_duration}
                                        onChange={onChange}
                                    >
                                        {Object.entries(expirationOptions)?.map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {value as string}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            ></Controller>
                        </FormField>
                        <FormHelperText>
                            <Typography variant={'caption'} sx={{ color: theme.palette.error.main }}>
                                {errors.expiration_duration?.message as string}
                            </Typography>
                        </FormHelperText>
                        <Typography variant='caption'>API keys will expire after this time, expired keys can not be used.</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={commonStyles.cx(commonStyles.classes.FormActionsPadding)}>
                <Stack direction='row' width={'100%'} justifyContent={'flex-end'} spacing={1.5}>
                    <Button
                        variant='cancel'
                        onClick={() => {
                            onCloseDialogAndForm();
                        }}
                        disabled={false}
                    >
                        Cancel
                    </Button>
                    <Button variant='contained' type='submit' form={'client-registration-form'} disabled={false}>
                        Create
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export { ClientRegistrationDialog };
