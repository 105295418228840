import { useKeycloak } from '@react-keycloak-fork/web';
import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { RecommendationsApi } from '@vegaplatformui/apis';
import { IPostTakeImmediateActionRequest, RecommendationActionType } from '@vegaplatformui/models';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';

export interface IUseRecommendationsApiHook {
    takeImmediateAction: (hookRequest: IPostTakeImmediateActionRequest) => void;
}
export interface IUseRecommendationsApiProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRunning: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useRecommendationsApi(props: IUseRecommendationsApiProps): IUseRecommendationsApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const recommendationsApi = useMemo(() => {
        const recommendationsApiInstance = new RecommendationsApi();
        recommendationsApiInstance.keycloak = keycloak;
        return recommendationsApiInstance;
    }, [keycloak]);

    const { mutate: takeImmediateAction } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 5000 },
                alertProps: { severity: 'error' },
                message: `${variables.action === RecommendationActionType.unpark ? 'There was a problem starting your resource' : 'There was a problem stopping your resource'}: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 5000 },
                alertProps: { severity: 'success' },
                message: `Your resource is ${variables.action === RecommendationActionType.unpark ? 'starting' : 'stopping'}.`,
            });
            props.setIsRunning(variables.action === RecommendationActionType.unpark);
        },
        onSettled: (data, error, variables, context) => {
            props.setIsLoading(false);
        },
        mutationFn: async (hookRequest: IPostTakeImmediateActionRequest) => {
            return recommendationsApi.postTakeImmediateAction(hookRequest);
        },
    });

    return { takeImmediateAction };
}
