import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormField } from '../forms';
import { Autocomplete, TextField, useTheme, useMediaQuery, Typography, styled, Popper, autocompleteClasses } from '@mui/material';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { ITimezone } from '@vegaplatformui/models';
import { timezonesAtom } from '../jotai/atom';
import { useAtomValue } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITimezoneFormfieldProps {
    timezone: ITimezone | null;
    setTimezone: (timezone: ITimezone | null) => void;
    disableClearable?: boolean;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };

    return (
        <Typography key={dataSet['key']} component='li' {...dataSet['props']} noWrap style={inlineStyle}>
            {`${dataSet['key']}`}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement<unknown>[] = [];
    (children as React.ReactElement<unknown>[]).forEach(
        (
            item: React.ReactElement<unknown> & {
                children?: React.ReactElement<unknown>[];
            }
        ) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactElement<unknown>) => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width='100%'
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType='ul'
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={4}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const VirtualizedPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const TimezoneFormField: React.FC<ITimezoneFormfieldProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { timezone, setTimezone } = props;
    const timezones = useAtomValue(timezonesAtom);
    return (
        <FormField label={'Timezone'} htmlFor={'timezone_input'}>
            <Autocomplete
                id={'timezone_input'}
                size={'small'}
                value={timezone ?? null}
                disableClearable={props.disableClearable ?? false}
                options={timezones}
                fullWidth={true}
                onChange={(e, timezone: ITimezone | null, reason) => {
                    setTimezone(timezone);
                }}
                disableListWrap
                slots={{
                    popper: VirtualizedPopper,
                }}
                slotProps={{
                    listbox: {
                        component: ListboxComponent,
                    },
                }}
                renderInput={(params) => <TextField {...params} placeholder={'Select a timezone'} />}
            />
        </FormField>
    );
};

const useStyles = customMakeStyles<ITimezoneFormfieldProps>()((theme, props) => ({}));

export { TimezoneFormField };
