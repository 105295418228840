import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SettingsContentHeader } from '../settings-content-header';
import { SettingsV2Divider } from '../settings-v2-divider';
import { SettingsDetailTile } from '../settings-detail-tile';
import { Grid2 } from '@mui/material';
import { MfaInput } from './mfa-input';
import { IUserSettingMFAStatusEnum } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMfaSettingsV2Props {
    status: IUserSettingMFAStatusEnum;
    onSubmit: (data: string) => void;
}

const MfaSettingsV2: React.FC<IMfaSettingsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid2 container spacing={2}>
            <SettingsContentHeader title={'Multi-Factor Authentication'} />
            <SettingsV2Divider />
            <SettingsDetailTile
                title={"Require additional types of credentials to verify a user's identity."}
                titleClassName={classes.MfaDetailTitle}
                children={<MfaInput status={props.status} onSubmit={props.onSubmit} />}
            />
        </Grid2>
    );
};

const useStyles = customMakeStyles<IMfaSettingsV2Props>()((theme, props) => ({
    MfaDetailTitle: { fontWeight: 400 },
}));

export { MfaSettingsV2 };
