import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Alert, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAccountDiscoveryInProgressProps {}

const AccountDiscoveryInProgressAlert: React.FC<IAccountDiscoveryInProgressProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Alert className={cx(classes.AlertStyle)} severity={'info'} color={'error'}>
            <Typography variant={'caption'}> Changes will not be included in the current active discovery.</Typography>
        </Alert>
    );
};

const useStyles = customMakeStyles<IAccountDiscoveryInProgressProps>()((theme, props) => ({
    AlertStyle: { width: '90%' },
}));

export { AccountDiscoveryInProgressAlert };
