import React, { useRef } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import IconButton from '@mui/material/IconButton';
import { MoreHoriz } from '@mui/icons-material';
import { Divider, Menu, MenuItem } from '@mui/material';
import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-premium';
import { IAssignmentRule } from '@vegaplatformui/models';
import { DeleteAssignmentRuleDialog } from '../delete-assignment-rule-dialog';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../utilities/custom-data-grid-render-cell-button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAsssignmentRulesTableMenuProps {
    assignmentRules: IAssignmentRule[];
    params: GridRenderCellParams<IAssignmentRule>;
    currentAssignmentRule: IAssignmentRule;
    updateRowPosition: (initialIndex: number, newIndex: number, rows: Array<GridRowModel<IAssignmentRule>>) => void;
    onClickViewAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    onDeleteAssignmentRule: (assignmentRule: IAssignmentRule) => void;
}

const AssignmentRulesTableMenu: React.FC<IAsssignmentRulesTableMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOptionMenuOpen = Boolean(anchorEl);
    const currentIndex = props.assignmentRules.findIndex((item) => item.id === props.currentAssignmentRule.id);
    const [isDeleteAssignmentRuleDialogOpen, setIsDeleteAssignmentRuleDialogOpen] = React.useState<boolean>(false);
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);

    const handleToggleCreateDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <DeleteAssignmentRuleDialog
                onDeleteAssignmentRule={props.onDeleteAssignmentRule}
                isOpen={isDeleteAssignmentRuleDialogOpen}
                onClose={() => setIsDeleteAssignmentRuleDialogOpen(false)}
                assignmentRule={props.currentAssignmentRule}
            />
            <StyledToolTip arrow title={'Options'}>
                <CustomDataGridRenderCellButton
                    ref={ref}
                    iconButtonProps={{
                        tabIndex: props.params.tabIndex,
                        onClick: handleToggleCreateDropDown,
                        children: <MoreHoriz />,
                        'aria-label': `${props.currentAssignmentRule.name}'s Options`,
                        'aria-expanded': !!anchorEl,
                        size: 'small',
                    }}
                    params={props.params}
                />
            </StyledToolTip>
            <Menu
                id='assignment-rules-table-menu'
                anchorEl={anchorEl}
                open={isOptionMenuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    onClick={() => {
                        props.onClickViewAssignmentRule(props.currentAssignmentRule);
                        handleClose();
                    }}
                >
                    Details
                </MenuItem>
                <MenuItem
                    disabled={currentIndex === 0}
                    onClick={() => {
                        props.updateRowPosition(currentIndex, currentIndex - 1, props.assignmentRules);
                        handleClose();
                    }}
                >
                    Move up
                </MenuItem>
                <MenuItem
                    disabled={currentIndex === 0}
                    onClick={(event) => {
                        props.updateRowPosition(currentIndex, 0, props.assignmentRules);
                        handleClose();
                    }}
                >
                    Move to the top
                </MenuItem>
                <MenuItem
                    disabled={currentIndex === props.assignmentRules.length - 1}
                    onClick={(event) => {
                        props.updateRowPosition(currentIndex, currentIndex + 1, props.assignmentRules);
                        handleClose();
                    }}
                >
                    Move down
                </MenuItem>
                <MenuItem
                    disabled={currentIndex === props.assignmentRules.length - 1}
                    onClick={(event) => {
                        props.updateRowPosition(currentIndex, props.assignmentRules.length - 1, props.assignmentRules);
                        handleClose();
                    }}
                >
                    Move to the bottom
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={(event) => {
                        setIsDeleteAssignmentRuleDialogOpen(true);
                        handleClose();
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        </>
    );
};

const useStyles = customMakeStyles<IAsssignmentRulesTableMenuProps>()((theme, props) => ({}));

export { AssignmentRulesTableMenu };
