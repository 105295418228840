import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Switch, SwitchProps } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGreenTrackSwitchProps extends SwitchProps {}

const GreenTrackSwitch: React.FC<IGreenTrackSwitchProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <Switch {...props} color={'default'} focusVisibleClassName={cx(classes.FocusedSwitch)} className={cx(classes.CustomSwitch)} />;
};

const useStyles = customMakeStyles<IGreenTrackSwitchProps>()((theme, props) => ({
    FocusedSwitch: {
        '& .MuiTouchRipple-root': {
            color: theme.palette.grey[400],
        },
    },
    CustomSwitch: {
        '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
                '&.Mui-disabled': {
                    '& + .MuiSwitch-track': {
                        opacity: 0.5,
                        backgroundColor: theme.palette.success.light,
                    },
                },
                '& + .MuiSwitch-track': {
                    opacity: 0.7,
                    backgroundColor: theme.palette.success.light,
                },
            },
            '&:not(.Mui-checked)': {
                '&.Mui-disabled': {
                    '& + .MuiSwitch-track': {
                        opacity: 0.5,
                        backgroundColor: theme.palette.grey[300],
                    },
                },
            },
        },
    },
}));

export { GreenTrackSwitch };
