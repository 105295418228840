import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';

export interface ICloudAccountsRequest {
    page: number;
    size: number;
    filter: string;
    ordering: string;
    fields: string;
}

export enum CloudProvidersTypes {
    Aws = 'aws',
    Gcp = 'gcp',
    Azure = 'azure',
    Oci = 'oci',
}

export interface IGetCloudAccountsResponse {
    total_rows: number;
    accounts: ICloudProviderAccount[];
}

export interface IGetCloudAccountRequest {
    id: string;
}

export interface IDeleteCloudAccountsRequest {
    id: string;
}

export interface IDeleteCloudAccountsBatchRequest {
    ids: string[];
}

export interface IPostCloudAccountsRequest {
    id?: string;
    provider_str: string;
    account_id: string;
    account_name: string;
    //parent_account_id is the cloud provider's id for the parent account
    parent_account_id: string | null;
    enabled: boolean;
    secret_json?: string;
    external_id?: string;
}

export interface IPostCloudAccountsBatchRequest {
    file: Blob;
}

export interface IPostCloudAccountsBatchResponse {
    created_provider_accounts: ICloudProviderAccount[];
    number_of_successful_rows: number;
    number_of_failed_rows: number;
    successful_rows: string[];
    failed_rows: string[];
}

export interface IPutCloudAccountsRequest {
    id?: string;
    provider_str: string;
    account_id: string;
    account_name: string;
    //parent_account_id is the cloud provider's id for the parent account
    parent_account_id: string | null;
    enabled: boolean;
    secret_json?: string;
    external_id?: string;
    created_at: string;
    created_by: string;
}

export type IDiscoveryDetails = {
    in_cooldown: boolean;
    is_discovery: boolean;
    request_id: string;
    client_id: string;
    datetime_in_30min: number;
    shouldConnect: boolean;
};

export type DiscoveryResponseData = {
    client_id?: string;
    request_id?: string;
    org_id?: string;
    cooldown_time_remaining?: string;
};

export type DiscoveryAccounts = {
    accounts: string[];
};

export interface IDiscoverRequest extends SendJsonMessage {
    jsonMessage: {
        event: DiscoveryEvents;
        data: DiscoveryAccounts;
    };
}

export interface IDiscoveryResponse {
    event: DiscoveryEvents;
    data: DiscoveryResponseData;
}

export type ICloudProviderAccount = {
    id: string;
    account_id: string;
    account_name: string;
    canonical_name: string;
    provider_str: string;
    //parent_account_id is the cloud provider's id for the parent account
    parent_account_id: string | null;
    parent_id: string | null;
    business_unit_id: string;
    resources: number;
    expensesMtd: number;
    currentMonthForecast: number;
    roleId?: string;
    subscriptionId?: string;
    clientId?: string;
    secretKey?: string;
    tenantId?: string;
    type?: string;
    projectId?: string;
    privateKeyId?: string;
    privateKey?: string;
    deleted_by?: string;
    deleted_at?: string;
    created_by: string;
    created_at: string;
    updated_by?: string | null;
    updated_at: string | null;
    organization_id: string;
    enabled: boolean;
    secret_json: string;
    external_id?: string;
    discovered_at?: string;
    discovered_status: number;
    discovered_message?: string;
};

export interface IEditOciAccountHookRequest {
    request: LinkOciAccountForm;
    accountToEdit: ICloudProviderAccount;
}

export type LinkOciAccountForm = {
    account_alias?: string;
    enabled: boolean;
    compartment_ocid: string;
    fingerprint: string;
    parent_account_ocid?: string | null;
    is_root: boolean;
    //Will live in the secret json
    region: string;
    //Will live in the secret json
    tenancy?: string | null;
    //Will live in the secret json
    private_key_content: string;
    //Will live in the secret json
    user: string;
};

export interface IEditAwsAccountHookRequest {
    request: LinkAwsAccountForm;
    accountToEdit: ICloudProviderAccount;
}

export type LinkAwsAccountForm = {
    payer_account?: ICloudProviderAccount | null;
    account_alias?: string;
    account_id: string;
    external_id: string;
    is_payer_account: boolean;
    enabled: boolean;
};

export interface IEditAzureAccountHookRequest {
    request: LinkAzureAccountForm;
    accountToEdit: ICloudProviderAccount;
}

export type LinkAzureAccountForm = {
    subscription_id: string;
    account_alias?: string;
    client_id: string;
    client_secret: string;
    tenant_id: string;
    enabled: boolean;
};

export interface IEditGcpAccountHookRequest {
    request: LinkGcpAccountForm;
    accountToEdit: ICloudProviderAccount;
}

export type LinkGcpAccountForm = {
    service_account: string;
    account_alias?: string;
    enabled: boolean;
};

export enum DiscoveryEvents {
    NewConnection = 'new_connection',
    RequestDiscovery = 'request_discovery',
    RequestDiscoveryAll = 'request_discovery_all',
    ClientConnected = 'client_connected',
    ClientDisconnected = 'client_disconnected',
    AuthenticationFailed = 'authentication_failed',
    DiscoveryStarted = 'discovery_started',
    DiscoveryInProgress = 'discovery_inprogress',
    DiscoveryRequestFailed = 'discovery_request_failed',
    DiscoveryComplete = 'discovery_complete',
    DiscoveryCompleteWithFailures = 'discovery_complete_with_failures',
    DiscoveryCooldown = 'discovery_cooldown',
    AccessCheckAndDiscover = 'accesscheck_and_discover',
}

export enum FilterTableByProvider {
    Aws = 'aws',
    Gcp = 'gcp',
    Azure = 'azure',
    All = 'All',
}
