export function samePageLinkNavigation(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    return !(
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    );
}
