import React from 'react';
import { Button, Dialog } from '@mui/material';
import { ChangeScheduleStatusNonScheduledContent } from './change-schedule-status-non-scheduled-content';
import { ChangeScheduleStatusScheduledContent } from './change-schedule-status-scheduled-content';
import { IParkingScheduleSummary, ITimezone } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangeScheduleStatusProps {
    show: boolean;
    onClose: () => void;
    onChangeScheduleStatuses: (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimezone
    ) => void;
    schedulesToChangeStatusOn: IParkingScheduleSummary[];
    isScheduled: boolean;
}

const ChangeScheduleStatusDialog: React.FC<IChangeScheduleStatusProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog open={props.show} onClose={props.onClose} fullWidth>
            {!props.isScheduled ? (
                <ChangeScheduleStatusNonScheduledContent
                    onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                    schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                    onClose={props.onClose}
                />
            ) : (
                <ChangeScheduleStatusScheduledContent
                    onClose={props.onClose}
                    onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                    schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                />
            )}
        </Dialog>
    );
};

const useStyles = customMakeStyles<IChangeScheduleStatusProps>()((theme, props) => ({}));

export { ChangeScheduleStatusDialog };
