import React, { ReactNode } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Route } from 'react-router';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomRouteProps {
    isEnabled: boolean;
    path: string;
    element: ReactNode;
    index?: boolean;
}

const CustomRoute: React.FC<ICustomRouteProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { isEnabled, index, element, path } = props;

    return isEnabled ? <Route index={index} path={path} element={element} /> : <></>;
};

const useStyles = customMakeStyles<ICustomRouteProps>()((theme, props) => ({}));

export { CustomRoute };
