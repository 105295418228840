import React from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { GreenTrackSwitch } from '../../utilities/green-track-switch';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommunicationItemProps {
    report: any;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedReport: React.Dispatch<any>;
    isPhoneNumberSet: boolean;
}

const ScheduleItem: React.FC<ICommunicationItemProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid className={cx(classes.PreferenceItem)} container spacing={2} direction={'row'} justifyContent='flex-start' alignItems='center'>
            <Grid className={cx(classes.ColumnMinWidth)} item xs={2}>
                <FormControlLabel
                    control={<GreenTrackSwitch checked={props.report.enabled} onClick={() => {}} aria-label={`${props.report.name} status switch`} />}
                    title={'Report Status Switch'}
                    label={props.report.enabled ? 'On' : 'Off'}
                />
            </Grid>
            <Grid className={cx(classes.ColumnMinWidth)} container direction='column' justifyContent='center' alignItems='flex-start' item xs={5.3}>
                <Grid item>
                    <Typography variant={'body1'}>{props.report.name}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'caption'}>{props.report.id}</Typography>
                </Grid>
            </Grid>
            <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={1.3}>
                <FormControlLabel
                    control={<Checkbox aria-label={`${props.report.name} Email option`} checked={false} onClick={() => {}} />}
                    title={'Email Checkbox'}
                    label={undefined}
                />
            </Grid>
            <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={1.3}>
                <FormControlLabel
                    control={
                        <Checkbox
                            aria-label={`${props.report.name} SMS option`}
                            checked={false}
                            onClick={() => {}}
                            disabled={!props.isPhoneNumberSet}
                        />
                    }
                    title={'SMS Checkbox'}
                    label={undefined}
                />
            </Grid>
            {/*<Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={1.3}>*/}
            {/*    <FormControlLabel*/}
            {/*        control={<Checkbox aria-label={`${props.report.name} Slack option`} checked={false} onClick={() => {}} />}*/}
            {/*        title={'Slack Checkbox'}*/}
            {/*        label={undefined}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid className={cx(classes.ColumnCheckboxMinWidth)} item xs={2.1}>
                <Button
                    onClick={() => {
                        props.setSelectedReport(props.report);
                        props.setIsOpen(true);
                    }}
                    variant={'text'}
                >
                    Schedule
                </Button>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ICommunicationItemProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ColumnCheckboxMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '50px',
        },
    },
    ToggleButton: {
        '&.MuiToggleButtonGroup-grouped:not(:last-of-type).MuiToggleButton-root.Mui-selected': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
        },
        '&.MuiToggleButtonGroup-grouped:last-of-type.MuiToggleButton-root.Mui-selected ': {
            backgroundColor: theme.palette.success.main,
        },
        '&.MuiToggleButton-root:not(.Mui-selected)': {
            backgroundColor: theme.palette.grey[50],
            ':hover': { backgroundColor: theme.palette.grey[200] },
        },
        textTransform: 'capitalize',
    },
    PreferenceItem: { marginBottom: '1rem' },
    StatusSubtext: { color: theme.palette.grey[600] },
}));

export { ScheduleItem };
