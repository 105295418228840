import React, { useEffect, useRef, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    defaultVegaTableControl,
    FileDownloadsCard,
    SnackbarErrorOutput,
    SnackBarOptions,
    tableIdentifierKeys,
    useCommonPageHeader,
    useFilesApi,
    useTableUtilities,
    vegaTableControls,
} from '@vegaplatformui/sharedcomponents';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Card, CardContent } from '@mui/material';
import { useKeycloak } from '@react-keycloak-fork/web';
import { FileApi } from '@vegaplatformui/apis';
import { IFile, IGetFileDownloadUrlsResponse } from '@vegaplatformui/models';
import { downloadZip } from 'client-zip';
import streamSaver from 'streamsaver';
import { GenerateRandomString } from '@vegaplatformui/utils';
import * as url from 'url';

export type IFileDownloadsContainerProps = React.PropsWithChildren;

const FileDownloadsController: React.FC<IFileDownloadsContainerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const { keycloak } = useKeycloak();
    const fileDownloadsTableUtilities = useTableUtilities(tableIdentifierKeys.fileDownloadsTable);
    const filesApi = useFilesApi({ isFileUpload: false });

    const onClickDownloadSelectedFiles = () => {
        filesApi.setSelectedFiles(selectedFiles);
    };

    const onClickDownloadFile = (data: IFile) => {
        filesApi.setSelectedFiles([data]);
    };

    return (
        <FileDownloadsCard
            selectedFiles={selectedFiles}
            availableFilesToDownload={filesApi.availableFiles}
            onClickDownloadSelectedFiles={onClickDownloadSelectedFiles}
            onClickDownloadFile={onClickDownloadFile}
            setSelectedFiles={setSelectedFiles}
            isLoading={filesApi.isLoading}
        />
    );
};

const useStyles = customMakeStyles<IFileDownloadsContainerProps>()((theme, props) => ({}));

export { FileDownloadsController };
