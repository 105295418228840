import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Box } from '@mui/material';
import { DataGridPremium, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItem, GridPagination, GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { ISSORoleMapping, IUserSettingRealmRole, roleAssignment } from '@vegaplatformui/models';
import { Delete, Edit } from '@mui/icons-material';
import { useTableUtilities } from '../../../use-table-utilities/use-table-utilities';
import { StyledToolTip } from '../../../utilities/styled-tooltip';
import { DataGridCustomToolbar } from '../../../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { tableIdentifierKeys } from '../../../use-table-utilities/table-identifier-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleMappingTableProps {
    roleMappings: ISSORoleMapping[];
    roles: IUserSettingRealmRole[];
    isRoleMappingsLoading: boolean;
    onOpenDeleteRoleMappingDialog: (roleMapping: ISSORoleMapping) => void;
    onClickEditRoleMapping: (roleMapping: ISSORoleMapping) => void;
}

const RoleMappingTable: React.FC<IRoleMappingTableProps> = (props) => {
    const { classes, cx } = useStyles(props);

    const commonStyles = useCommonStyles();
    const ssoRoleMappingsTableUtilities = useTableUtilities(tableIdentifierKeys.ssoRoleMappingsTable);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

    React.useEffect(() => {
        ssoRoleMappingsTableUtilities.registerTableControl();
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'externalGroupName',
            headerName: 'SSO IdP Group Name',
            flex: 1,
        },
        {
            field: 'roleAssignments',
            headerName: 'Platform Role',
            flex: 3,
            valueGetter: (value: roleAssignment[], row: ISSORoleMapping) => {
                return props.roles
                    .map((role) => role)
                    .filter((item) => row.roleAssignments.map((roleAssignment) => roleAssignment.roleId).includes(item.id))
                    .map((role) => role.name)
                    .join(', ');
            },
        },
        {
            field: 'actions',
            headerName: 'Actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 0.5,
            type: 'actions',
            headerAlign: 'center',
            align: 'center',
            getActions: (params: GridRowParams<ISSORoleMapping>) => [
                <StyledToolTip arrow title={'Edit'}>
                    <GridActionsCellItem
                        icon={<Edit />}
                        label={'Edit'}
                        aria-label={`Edit ${params.row.externalGroupName}`}
                        onClick={() => props.onClickEditRoleMapping(params.row)}
                        size='small'
                    />
                </StyledToolTip>,
                <StyledToolTip arrow title={'Delete'}>
                    {props.roleMappings.length <= 1 ? (
                        <span>
                            <GridActionsCellItem
                                icon={<Delete />}
                                label={'Delete'}
                                aria-label={`Unable to delete at least 1 role mapping is required`}
                                disabled={props.roleMappings.length <= 1}
                                onClick={() => props.onOpenDeleteRoleMappingDialog(params.row)}
                                size='small'
                            />
                        </span>
                    ) : (
                        <GridActionsCellItem
                            icon={<Delete />}
                            label={'Delete'}
                            aria-label={`Delete ${params.row.externalGroupName}`}
                            onClick={() => props.onOpenDeleteRoleMappingDialog(params.row)}
                            size='small'
                        />
                    )}
                </StyledToolTip>,
            ],
        },
    ];

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    };

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
    };

    return (
        <Box>
            <DataGridPremium
                autoHeight={true}
                pagination={true}
                columns={columns}
                paginationMode={'client'}
                rows={props.roleMappings}
                checkboxSelectionVisibleOnly
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    pagination: {
                        showLastButton: true,
                        showFirstButton: true,
                        slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                    },
                    toolbar: { tableIdentifier: tableIdentifierKeys.ssoRoleMappingsTable },
                    columnsManagement: {
                        getTogglableColumns,
                    },
                    noRowsOverlay: {
                        action: 'adding a role mapping',
                    },
                }}
                density={ssoRoleMappingsTableUtilities.currentTableControl?.density}
                onDensityChange={ssoRoleMappingsTableUtilities.onDensityChange}
                paginationModel={ssoRoleMappingsTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={ssoRoleMappingsTableUtilities.onPaginationModelChange}
                onSortModelChange={ssoRoleMappingsTableUtilities.onSortModelChange}
                onFilterModelChange={ssoRoleMappingsTableUtilities.onFilterModelChange}
                columnVisibilityModel={ssoRoleMappingsTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={ssoRoleMappingsTableUtilities.onColumnVisibilityModelChange}
                loading={props.isRoleMappingsLoading}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IRoleMappingTableProps>()((theme, props) => ({}));

export { RoleMappingTable };
