import React, { useState, useEffect, useMemo } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    ParkingScheduleResourceId,
    ResourceDetailCard,
    useParkingApi,
    useBusinessGroupingsApi,
    tableIdentifierKeys,
} from '@vegaplatformui/sharedcomponents';
import { useKeycloak } from '@react-keycloak-fork/web';
import { ContainerApi } from '@vegaplatformui/apis';
import { useErrorHandlingV2 } from '@vegaplatformui/sharedcomponents';
import { IResource } from '@vegaplatformui/models';
import { useRecoilState } from 'recoil';
import { useRouteUrls } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IResourceDetailControllerProps {}

const ResourceDetailController: React.FC<IResourceDetailControllerProps> = (props) => {
    const { keycloak } = useKeycloak();
    const { classes, cx } = useStyles(props);
    const [withErrorHandlingV2] = useErrorHandlingV2();
    const [isNotParkingLoadingLoading, setIsLoading] = useState<boolean>(true);
    const [resource, setResource] = useState<IResource | undefined>(undefined);
    const [resourceId, setResourceId] = useRecoilState(ParkingScheduleResourceId);
    const parkingScheduleTableIdentifier = tableIdentifierKeys.resourceDetailParkingScheduleTable;
    const businessGroupingApi = useBusinessGroupingsApi({});
    const { routeUrls } = useRouteUrls({});

    const {
        setSelectedSchedules,
        selectedSchedules,
        parkingSchedules,
        resources,
        setIsDrawerOpen,
        isDrawerOpen,
        loadSelectedParkingSchedule,
        scheduleToEdit,
        onClickCreateSchedule,
        onClickEditSchedule,
        onClickDeleteSchedule,
        onClickDeleteSchedules,
        onOpenScheduleChangeDialog,
        isChangeScheduleDialogOpen,
        setIsChangeScheduleDialogOpen,
        onOpenRemoveAdvancedParkingDialog,
        onRemoveAdvancedParking,
        isRemoveAdvancedParkingDialogOpen,
        setIsRemoveAdvancedParkingDialogOpen,
        userHasGroupings,
        loadParkableResources,
        isParkableResourcesLoading,
        isParkingSchedulesLoading: isParkingLoading,
        setResourcesSelectedForParking,
        scheduleToDelete,
        setScheduleToDelete,
        isScheduledChangeStatus,
        onClickOpenDeleteDialog,
        onChangeScheduleStatuses,
        isConfirmDeleteDialogOpen,
        setIsConfirmDeleteDialogOpen,
        schedulesToChangeStatusOn,
        onOpenEnableDisableSchedulesDialog,
        isDeleteExemptionDialogOpen,
        setIsDeleteExemptionDialogOpen,
        scheduleWithExemption,
        setScheduleWithExemption,
        onClickOpenDeleteExemptionDialog,
        onClickDeleteExemption,
    } = useParkingApi({ parkingScheduleTableIdentifier: parkingScheduleTableIdentifier });

    useEffect(() => {
        const currentResourceId = window.location.pathname.split('/')[2];
        if (resourceId !== currentResourceId) {
            setResourceId(currentResourceId);
        }
        return () => {
            window.location.pathname.split('/')[1] !== routeUrls.resources.path && setResourceId('');
        };
    }, [window.location.pathname]);

    useEffect(() => {
        const currentResourceId = window.location.pathname.split('/')[2];
        if (resourceId !== '' && currentResourceId === resourceId) {
            getResourceDetail();
        }
    }, [resourceId]);

    const containerApi = useMemo(() => {
        const containerApiInstance = new ContainerApi();
        containerApiInstance.keycloak = keycloak;
        return containerApiInstance;
    }, [keycloak]);

    const getResourceDetail = () => {
        resourceId !== '' &&
            resourceId !== undefined &&
            withErrorHandlingV2(async () => {
                setIsLoading(true);
                const res = await containerApi.getResourceById(resourceId);
                setResource(res.data);
                setIsLoading(false);
            }, 'Getting Resource by ID failed.');
    };

    return (
        <ResourceDetailCard
            isDeleteExemptionDialogOpen={isDeleteExemptionDialogOpen}
            setIsDeleteExemptionDialogOpen={setIsDeleteExemptionDialogOpen}
            scheduleWithExemption={scheduleWithExemption}
            setScheduleWithExemption={setScheduleWithExemption}
            onClickOpenDeleteExemptionDialog={onClickOpenDeleteExemptionDialog}
            onClickDeleteExemption={onClickDeleteExemption}
            parkingScheduleTableIdentifier={parkingScheduleTableIdentifier}
            setResourcesSelectedForParking={setResourcesSelectedForParking}
            setResourceId={setResourceId}
            isParkingLoading={isParkingLoading}
            isParkingDrawerOpen={isDrawerOpen}
            isLoading={isNotParkingLoadingLoading}
            resource={resource!}
            onToggleParkingDrawer={setIsDrawerOpen}
            setScheduleToEdit={loadSelectedParkingSchedule}
            scheduleToEdit={scheduleToEdit}
            parkableResources={resources}
            parkingSchedules={parkingSchedules}
            selectedSchedules={selectedSchedules}
            setSelectedSchedules={setSelectedSchedules}
            usersBusinessGroupings={businessGroupingApi.myBusinessUnitTree || []}
            onClickCreateSchedule={onClickCreateSchedule}
            onClickEditSchedule={onClickEditSchedule}
            onClickDeleteSchedule={onClickDeleteSchedule}
            onClickDeleteSelectedSchedules={onClickDeleteSchedules}
            onOpenScheduleChangeDialog={onOpenScheduleChangeDialog}
            isChangeScheduleDialogOpen={isChangeScheduleDialogOpen}
            setIsChangeScheduleDialogOpen={setIsChangeScheduleDialogOpen}
            onOpenRemoveAdvancedParkingDialog={onOpenRemoveAdvancedParkingDialog}
            onRemoveAdvancedParking={onRemoveAdvancedParking}
            isRemoveAdvancedParkingDialogOpen={isRemoveAdvancedParkingDialogOpen}
            setIsRemoveAdvancedParkingDialogOpen={setIsRemoveAdvancedParkingDialogOpen}
            userHasGroupings={userHasGroupings}
            loadParkableResources={loadParkableResources}
            isParkableResourcesLoading={isParkableResourcesLoading}
            scheduleToDelete={scheduleToDelete}
            setScheduleToDelete={setScheduleToDelete}
            isScheduled={isScheduledChangeStatus}
            onClickOpenDeleteDialog={onClickOpenDeleteDialog}
            onChangeScheduleStatuses={onChangeScheduleStatuses}
            isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
            setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
            schedulesToChangeStatusOn={schedulesToChangeStatusOn}
            onOpenEnableDisableSchedulesDialog={onOpenEnableDisableSchedulesDialog}
        />
    );
};

const useStyles = customMakeStyles<IResourceDetailControllerProps>()((theme, props) => ({
    Tabpanel: {
        paddingBlock: theme.spacing(2),
    },
}));

export { ResourceDetailController };
