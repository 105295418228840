import React, { useEffect } from 'react';

import { TextField } from '@mui/material';
import { AssignmentRulesConditionAutoCompleteValue } from './assignment-rules-condition-auto-complete-value/assignment-rules-condition-auto-complete-value';
import { AssignmentRulesConditionKeyPairValue } from './assignment-rules-condition-key-pair-value';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IAssignmentRuleCondition, IAssignmentRuleFormValue } from '@vegaplatformui/models';
import { useRulesEnumsApi } from '../../../../api-hooks/use-rules/use-rules-enums-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesConditionValueProps {
    assignmentRuleCondition: IAssignmentRuleCondition;
    onChangeAssignmentRuleCondition: (assignmentRuleCondition: IAssignmentRuleCondition) => void;
    assignmentRuleFormValue: IAssignmentRuleFormValue | null;
    onChangeValue: (value: string) => void;
    currentValue: string;
    isMissingValues: boolean;
}

export interface IRegionsEnum {
    displayName: string;
    name: string;
}
const AssignmentRulesConditionValue: React.FC<IAssignmentRulesConditionValueProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { enumValues, isLoading } = useRulesEnumsApi<any>({
        url: props.assignmentRuleFormValue?.data_url ?? '',
        meta_data: props.assignmentRuleFormValue?.meta_data ?? '',
    });

    const getValueFromJson = (value: string, jsonKey: string): string => {
        try {
            return JSON.parse(value)[jsonKey];
        } catch (e) {
            return value;
        }
    };

    const getInputComponent = () => {
        switch (props.assignmentRuleFormValue?.frm_field_type) {
            case 'acd':
                return (
                    <AssignmentRulesConditionAutoCompleteValue
                        isMissingValues={props.isMissingValues}
                        enumValues={enumValues ?? []}
                        isLoading={isLoading}
                        label={'Value'}
                        value={props.currentValue}
                        onChange={props.onChangeValue}
                    />
                );
            case 'kv':
                return (
                    <AssignmentRulesConditionKeyPairValue
                        isMissingValues={props.isMissingValues}
                        onChangeValue={props.onChangeValue}
                        keyValue={getValueFromJson(props.currentValue, 'key')}
                        value={getValueFromJson(props.currentValue, 'value')}
                    />
                );
            default:
                return (
                    <TextField
                        onChange={(event) => props.onChangeValue(event.target.value)}
                        value={props.currentValue}
                        id='value'
                        label={'Value'}
                        size='small'
                        fullWidth={true}
                        error={props.isMissingValues && props.currentValue === ''}
                    />
                );
        }
    };

    return getInputComponent();
};

const useStyles = customMakeStyles<IAssignmentRulesConditionValueProps>()((theme, props) => ({}));

export { AssignmentRulesConditionValue };
