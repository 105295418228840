import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { IParkingSchedule } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScheduleInactiveDialogProps {
    show: boolean;
    onClose: () => void;
    onToggleYes: () => void;
    onToggleNo: () => void;
}

const ScheduleInactiveWarningDialog: React.FC<IScheduleInactiveDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>
                <Stack direction={'column'} spacing={0}>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
                        <Stack spacing={0} direction={'column'}>
                            <Typography fontWeight={500}>You are about to create a schedule but it is set as “Off”,</Typography>
                            <Typography fontWeight={500}>do you want to turn it “On”?</Typography>
                        </Stack>
                        <IconButton aria-label={'Close and return to editing the schedule.'} size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "Yes" to turn the schedule on.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "No" to keep the schedule off.
                </DialogContentText>
                <DialogContentText className={cx(classes.ContentTextDivider)} variant={'body2'} id='confirm-close-dialog-description'>
                    Both of the above actions will still create the schedule.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Or return to editing by selecting the close button.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Button variant={'cancel'} onClick={props.onToggleNo} autoFocus>
                    No
                </Button>
                <Button onClick={props.onToggleYes}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IScheduleInactiveDialogProps>()((theme, props) => ({
    ContentTextDivider: { marginBottom: '1rem' },
}));

export { ScheduleInactiveWarningDialog };
