import { useMemo, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IDashboard } from '@vegaplatformui/models';
import { IsDashboardShareReady, IsDashboardView, SnackBarOptions } from '../jotai/atom';
import { ReportsApi } from '@vegaplatformui/apis';
import { queryKeys } from './query-keys';
import { DashboardExperience } from 'amazon-quicksight-embedding-sdk';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseReportsApiHook {
    reports: IDashboard[];
    embeddedUrl: string;
    isDashboardLoading: boolean;
    isUrlLoading: boolean;
    setDashboardExperience: React.Dispatch<React.SetStateAction<DashboardExperience | undefined>>;
    shareLink: string | undefined;
    setShareLink: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface IUseReportsApiProps {
    folderNames: string[];
}

export function useReportsApi(props: IUseReportsApiProps): IUseReportsApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const [dashboardExperience, setDashboardExperience] = useState<DashboardExperience | undefined>(undefined);
    const setIsDashboardView = useSetAtom(IsDashboardView);
    const setIsDashboardShareReady = useSetAtom(IsDashboardShareReady);
    const shareButton = document.getElementById('share-button');
    const [shareLink, setShareLink] = useState<string | undefined>(undefined);

    const reportsApi = useMemo(() => {
        const reportsApi = new ReportsApi();
        reportsApi.keycloak = keycloak;
        return reportsApi;
    }, [keycloak]);

    const {
        data: reports,
        isLoading: isDashboardLoading,
        isError: isDashboardError,
        isSuccess: isDashboardSuccess,
        error: dashboardError,
    } = useQuery({
        queryKey: [queryKeys.reportsApi.dashboards, props.folderNames],

        queryFn: async () => {
            const response = await reportsApi.listDashboards({ folderNames: props.folderNames });
            return response.data;
        },

        meta: {
            errorMessage: 'There was a problem getting dashboard Ids',
        },
    });

    const {
        data: embeddedUrl,
        isError: isEmbeddedUrlError,
        error: embeddedUrlError,
        isLoading: isLoadingisEmbeddedUrlLoading,
    } = useQuery({
        queryKey: [queryKeys.reportsApi.dashboardEmbeddedUrl, props.folderNames, reports],

        queryFn: async () => {
            const response = await reportsApi.postEmbeddedDashboardURL({
                dashboardId: reports?.map((dashboard: IDashboard) => dashboard.dashboardId)[0] ?? '',
                isPersistent: true,
            });
            return response.data;
        },

        // The query will not execute until the reports exists
        enabled: isDashboardSuccess && reports && reports.length > 0,
        gcTime: 0,
        meta: {
            errorMessage: 'There was a problem getting embedded Url',
        },
    });

    useEffect(() => {
        setIsDashboardView(!isDashboardLoading && !isLoadingisEmbeddedUrlLoading);
        return () => {
            setIsDashboardView(false);
            setIsDashboardShareReady(false);
        };
    }, [isDashboardLoading, isLoadingisEmbeddedUrlLoading]);

    useEffect(() => {
        // Function to handle the event
        async function handleClick() {
            if (dashboardExperience) {
                dashboardExperience
                    .createSharedView()
                    .then((response) => {
                        const sharedDashboardId = response?.message.dashboardId;
                        const sharedViewId = response?.message.sharedViewId;
                        setShareLink(
                            `https://${
                                process.env.NX_PUBLIC_IS_LOCAL ? window.location.hostname + ':3001' : window.location.hostname
                            }/sharedreport/${sharedDashboardId}/views/${sharedViewId}`
                        );
                    })
                    .catch((reason) => {
                        console.log(reason);
                    });
            }
        }

        // Add the event listener
        if (shareButton) {
            shareButton.addEventListener('click', handleClick);

            // Cleanup function to remove the listener on unmount
            return () => {
                shareButton.removeEventListener('click', handleClick);
            };
        }
    }, [shareButton]);

    return {
        reports: reports ?? [],
        embeddedUrl: embeddedUrl ?? '',
        isDashboardLoading: isDashboardLoading,
        isUrlLoading: isLoadingisEmbeddedUrlLoading,
        setDashboardExperience,
        shareLink,
        setShareLink,
    };
}

export default useReportsApi;
