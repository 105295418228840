import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { DialogContent, Fade, Paper, Skeleton } from '@mui/material';
import { PopperTransitionProps } from '@mui/base';
import { ChatbotNavigationTabTypes } from '@vegaplatformui/models';
import { ChatbotInput } from './chat-navigation-content/chatbot-input';
import { ChatbotWindow } from './chat-navigation-content/chatbot-window';
import { ChatbotHeader } from './chatbot-header';
import { ChatbotFooter } from './chatbot-footer';
import { HelpWindow } from './help-navigation-content/help-window';
import { HistoryWindow } from './history-navigation-content/history-window';
import { IUseChatbotApiHook } from '../api-hooks/use-chatbot-api';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomChatbotPopupProps {
    TransitionProps?: PopperTransitionProps | undefined;
    close: () => void;
    chatbotApi: IUseChatbotApiHook;
}

const CustomChatbotPopupContentControl: React.FC<ICustomChatbotPopupProps> = (props) => {
    const [currentLocation, setCurrentLocation] = useState<ChatbotNavigationTabTypes>(ChatbotNavigationTabTypes.Chat);
    const { classes, cx } = useStyles({ props, currentLocation });
    const {
        chatMessages,
        message,
        setMessage,
        handleSendMessage,
        topicHistory,
        initStartTopic,
        loadTopic,
        renameTopic,
        deleteTopic,
        renamingStates,
        setRenaming,
        clearTopics,
        currentTopic,
        isTopicHistoryLoading,
        handleEditQuestionPair,
        isChatbotLoading,
    } = props.chatbotApi;

    useEffect(() => {
        const textarea = document.getElementById('content-window');
        if (currentLocation === ChatbotNavigationTabTypes.Chat) {
            textarea && (textarea.scrollTop = textarea.scrollHeight);
        } else {
            textarea && (textarea.scrollTop = 0);
        }
    }, [chatMessages.length, currentLocation]);

    const loadTopicAndChat = (topicId: string) => {
        loadTopic(topicId);
        setCurrentLocation(ChatbotNavigationTabTypes.Chat);
    };

    const reopenSelectedTopic = () => {
        setCurrentLocation(ChatbotNavigationTabTypes.Chat);
    };

    const startTopicAndLoadChat = () => {
        initStartTopic();
        setCurrentLocation(ChatbotNavigationTabTypes.Chat);
    };

    return (
        <Fade {...props.TransitionProps} timeout={200}>
            <Paper elevation={6} className={cx(classes.ChatBotPaper)}>
                <ChatbotHeader
                    currentLocation={currentLocation}
                    close={props.close}
                    currentTopic={currentTopic}
                    initStartTopic={startTopicAndLoadChat}
                />
                <DialogContent id={'content-window'} className={classes.ChatbotDialogContent}>
                    {(() => {
                        switch (currentLocation) {
                            case ChatbotNavigationTabTypes.Chat:
                                return (
                                    <ChatbotWindow
                                        chatMessages={chatMessages}
                                        handleSendMessage={handleSendMessage}
                                        handleEditQuestionPair={handleEditQuestionPair}
                                        isChatbotLoading={isChatbotLoading}
                                    />
                                );
                            case ChatbotNavigationTabTypes.History:
                                return !isTopicHistoryLoading ? (
                                    <HistoryWindow
                                        history={topicHistory}
                                        deleteTopic={deleteTopic}
                                        loadTopic={loadTopicAndChat}
                                        renameTopic={renameTopic}
                                        initStartTopic={initStartTopic}
                                        renamingStates={renamingStates}
                                        setRenaming={setRenaming}
                                        clearTopics={clearTopics}
                                        currentTopic={currentTopic}
                                        reopenSelectedTopic={reopenSelectedTopic}
                                    />
                                ) : (
                                    [
                                        <Skeleton key={'header'} width={'100%'} height={'68px'} />,
                                        [1, 2, 3, 4, 5, 6].map((value) => <Skeleton key={value} className={cx(classes.ChatbotHistorySkeleton)} />),
                                    ]
                                );
                            case ChatbotNavigationTabTypes.Help:
                                return <HelpWindow />;
                            default:
                                return <></>;
                        }
                    })()}
                </DialogContent>
                {currentLocation === ChatbotNavigationTabTypes.Chat && (
                    <ChatbotInput setMessage={setMessage} message={message} handleSendMessage={handleSendMessage} />
                )}
                <ChatbotFooter currentLocation={currentLocation} setCurrentLocation={setCurrentLocation} />
            </Paper>
        </Fade>
    );
};

interface IChatbotMakeStylesProps {
    props: ICustomChatbotPopupProps;
    currentLocation: ChatbotNavigationTabTypes;
}

const useStyles = customMakeStyles<IChatbotMakeStylesProps>()((theme, params) => ({
    ChatBotPaper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '24px',
        [theme.breakpoints.up('md')]: {
            width: '630px',
            height: '750px',
        },
        [theme.breakpoints.between(700, 1200)]: {
            width: '520px',
            height: '683px',
        },
        [theme.breakpoints.down(700)]: {
            width: '450px',
            height: '500px',
        },
    },
    ChatbotDialogContent: {
        [theme.breakpoints.up('md')]: {
            height: params.currentLocation === ChatbotNavigationTabTypes.Chat ? '72%' : '83%',
        },
        [theme.breakpoints.between(700, 1200)]: {
            height: params.currentLocation === ChatbotNavigationTabTypes.Chat ? '69%' : '81%',
        },
        [theme.breakpoints.down(700)]: {
            height: params.currentLocation === ChatbotNavigationTabTypes.Chat ? '58%' : '74%',
        },
        width: '100%',
        overflowY: 'auto',
        overflowWrap: 'break-word',
    },
    ChatbotHistorySkeleton: { padding: '10px', borderRadius: '4px', width: '100%', minHeight: '100px' },
}));

export { CustomChatbotPopupContentControl };
