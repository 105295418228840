import { useKeycloak } from '@react-keycloak-fork/web';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    IsEditingExemption,
    ParkingScheduleBusinessGroupId,
    ParkingScheduleResourceId,
    ParkingScheduleTableBusinessGroupId,
    SnackBarOptions,
} from '../recoil/atom';
import React, { useEffect, useMemo, useState } from 'react';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { ActionsApi, BusinessGroupingsApi, ResourcesApi } from '@vegaplatformui/apis';
import {
    IMutateScheduleStatusesHookObject,
    IParkingSchedule,
    IParkingScheduleSummary,
    IResource,
    IUpdateScheduleExemptionsHookObject,
    IUserBusinessGroups,
    ParkingDelayUnit,
    ParkingScheduleType,
} from '@vegaplatformui/models';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { ITimeZone } from '@vegaplatformui/utils';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';

export interface IUseParkingApiHook {
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDrawerOpen: boolean;
    loadSelectedParkingSchedule: (schedule: IParkingScheduleSummary | undefined) => void;
    scheduleToEdit: IParkingSchedule | undefined;
    resources: IResource[];
    parkingSchedules: IParkingScheduleSummary[];
    selectedSchedules: IParkingScheduleSummary[];
    setSelectedSchedules: React.Dispatch<React.SetStateAction<IParkingScheduleSummary[]>>;
    usersBusinessGroupings: IUserBusinessGroups[];
    isParkingSchedulesLoading: boolean;
    isParkableResourcesLoading: boolean;
    onClickCreateSchedule: (schedule: IParkingSchedule) => void;
    onClickEditSchedule: (schedule: IParkingSchedule) => void;
    onClickDeleteSchedule: (schedule: any) => void;
    onClickDeleteSchedules(): Promise<void>;
    onClickCopySchedule(): void;
    onOpenRemoveAdvancedParkingDialog: (schedule: IParkingScheduleSummary | undefined) => void;
    onRemoveAdvancedParking: (schedule: IParkingSchedule) => void;
    onOpenScheduleChangeDialog: (schedule: IParkingScheduleSummary | undefined) => void;
    isChangeScheduleDialogOpen: boolean;
    setIsChangeScheduleDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isRemoveAdvancedParkingDialogOpen: boolean;
    setIsRemoveAdvancedParkingDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    userHasGroupings: boolean;
    businessGroupingId: string;
    setBusinessGroupingId: React.Dispatch<React.SetStateAction<string>>;
    loadParkableResources: (bg_id?: string) => void;
    setResourcesSelectedForParking: (resources: IResource[]) => void;
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    onChangeScheduleStatuses: (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimeZone
    ) => void;
    isScheduledChangeStatus: boolean;
    setIsScheduledChangeStatus: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmDeleteDialogOpen: boolean;
    onClickOpenDeleteDialog: (schedule?: IParkingScheduleSummary) => void;
    schedulesToChangeStatusOn: IParkingScheduleSummary[];
    onOpenEnableDisableSchedulesDialog: (schedules: IParkingScheduleSummary[] | undefined, isScheduledChangeStatus: boolean) => void;
    isDeleteExemptionDialogOpen: boolean;
    setIsDeleteExemptionDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scheduleWithExemption: IParkingScheduleSummary | undefined;
    setScheduleWithExemption: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    onClickOpenDeleteExemptionDialog: (schedule: IParkingScheduleSummary) => void;
    onClickDeleteExemption: (schedule: IParkingScheduleSummary) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseParkingApiProps {
    parkingScheduleTableIdentifier: string;
}

export function useParkingApi(props: IUseParkingApiProps): IUseParkingApiHook {
    const { keycloak } = useKeycloak();
    const [isLoading, setIsLoading] = useState(false);
    const [isParkingScheduleLoading, setIsParkingScheduleLoading] = useState(false);
    const [selectedSchedules, setSelectedSchedules] = useState<IParkingScheduleSummary[]>([]);
    const [scheduleToEdit, setScheduleToEdit] = React.useState<IParkingSchedule | undefined>(undefined);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const parkingScheduleTableUtilities = useTableUtilities(props.parkingScheduleTableIdentifier);
    const attachResourcesDialogTableUtilities = useTableUtilities(tableIdentifierKeys.attachResourcesDialogTable);
    const [isParkableResourcesLoading, setIsParkableResourcesLoading] = useState(false);
    const [isChangeScheduleDialogOpen, setIsChangeScheduleDialogOpen] = React.useState<boolean>(false);
    const [isRemoveAdvancedParkingDialogOpen, setIsRemoveAdvancedParkingDialogOpen] = React.useState<boolean>(false);
    const [usersBusinessGroupings, setUsersBusinessGroupings] = React.useState<IUserBusinessGroups[]>([]);
    const [userHasGroupings, setUserHasGroupings] = React.useState<boolean>(false);
    const [businessGroupingId, setBusinessGroupingId] = useRecoilState(ParkingScheduleBusinessGroupId);
    const tableBusinessGroupingId = useRecoilValue(ParkingScheduleTableBusinessGroupId);
    const [resourceId, setResourceId] = useRecoilState(ParkingScheduleResourceId);
    const [resourcesSelectedForParking, setResourcesSelectedForParking] = useState<IResource[]>([]);
    const [scheduleToDelete, setScheduleToDelete] = React.useState<IParkingScheduleSummary | undefined>(undefined);
    const [isScheduledChangeStatus, setIsScheduledChangeStatus] = React.useState<boolean>(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState<boolean>(false);
    const [schedulesToChangeStatusOn, setSchedulesToChangeStatusOn] = React.useState<IParkingScheduleSummary[]>([]);
    const [isDeleteExemptionDialogOpen, setIsDeleteExemptionDialogOpen] = React.useState<boolean>(false);
    const [scheduleWithExemption, setScheduleWithExemption] = React.useState<IParkingScheduleSummary | undefined>(undefined);
    const [isEditingExemption, setIsEditingExemption] = useRecoilState(IsEditingExemption);

    const queryClient = useQueryClient();

    const onClickOpenDeleteDialog = (schedule?: IParkingScheduleSummary) => {
        schedule && setScheduleToDelete(schedule);
        setIsConfirmDeleteDialogOpen(true);
    };

    const resourcesApi = useMemo(() => {
        const resourcesApi = new ResourcesApi();
        resourcesApi.keycloak = keycloak;
        return resourcesApi;
    }, [keycloak]);

    const actionsApi = useMemo(() => {
        const actionsApi = new ActionsApi();
        actionsApi.keycloak = keycloak;
        return actionsApi;
    }, [keycloak]);

    const businessGroupingApi = useMemo(() => {
        const businessGroupingApi = new BusinessGroupingsApi();
        businessGroupingApi.keycloak = keycloak;
        return businessGroupingApi;
    }, [keycloak]);

    useEffect(() => {
        loadUsersBusinessGroups();
    }, [keycloak.token]);

    const loadSelectedParkingSchedule = (schedule: IParkingScheduleSummary | undefined) => {
        if (schedule !== undefined) {
            setIsParkingScheduleLoading(true);
            actionsApi
                .getParkingPolicy(schedule.id)
                .then((response) => {
                    setScheduleToEdit({
                        ...response.data,
                        scheduled_on_times: response.data.scheduled_on_times?.map((date) => {
                            const dareAsString = date as unknown as string;
                            return new Date(dareAsString);
                        }),
                    });
                    setBusinessGroupingId(response.data.business_group_id ?? '');
                    setIsDrawerOpen(true);
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `Error loading schedule: ${error}.`,
                    });
                })
                .finally(() => {
                    setIsParkingScheduleLoading(false);
                });
        } else {
            setScheduleToEdit(undefined);
        }
    };

    const loadUsersBusinessGroups = () => {
        businessGroupingApi
            .getValidBusinessGroupingParents({})
            .then((response) => {
                if (response.data.length > 0) {
                    const businessGroupingsSorted = response.data.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                    });
                    setUsersBusinessGroupings(businessGroupingsSorted);
                    setUserHasGroupings(true);
                } else {
                    setUserHasGroupings(false);
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `User cannot create a schedule since they have no assigned business groupings.`,
                    });
                }
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Unable to get the user's business groupings: ${SnackbarErrorOutput(error)}`,
                });
            });
    };

    const {
        data: parkingSchedules,
        isLoading: isSchedulesLoading,
        isError: isParkingSchedulesError,
        error: parkingSchedulesError,
        isSuccess: isParkingSchedulesSuccess,
        isPlaceholderData: isParkingSchedulesPreviousData,
    } = useQuery({
        queryKey: [
            queryKeys.parking.parkingTable,
            parkingScheduleTableUtilities.currentTableControl?.paginationModel,
            parkingScheduleTableUtilities.currentTableControl?.sortModel,
            parkingScheduleTableUtilities.currentTableControl?.filterModel,
            tableBusinessGroupingId,
            resourceId,
            isLoading,
        ],

        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.parking.parkingTable],
            });
            const response = await loadParkingSchedules(signal);
            return response;
        },

        meta: {
            errorMessage: 'Unable to load schedules',
        },

        placeholderData: keepPreviousData,
        enabled: parkingScheduleTableUtilities.currentTableControl !== undefined && !isLoading && !isDrawerOpen && !isParkingScheduleLoading,
    });
    const loadParkingSchedules = async (signal: AbortSignal): Promise<IParkingScheduleSummary[]> => {
        if (parkingScheduleTableUtilities.currentTableControl !== undefined && !isDrawerOpen) {
            let filterModel = parkingScheduleTableUtilities.currentTableControl.filterModel;

            if (resourceId !== undefined && resourceId !== '') {
                const response = await actionsApi.getParkingPolicySummariesForResource(
                    {
                        paginationModel: parkingScheduleTableUtilities.currentTableControl.paginationModel,
                        sortModel: parkingScheduleTableUtilities.currentTableControl.sortModel,
                        filterModel: filterModel,
                    },
                    resourceId,
                    signal
                );
                parkingScheduleTableUtilities.updateTotalRows(response.data.total_rows);

                return response.data.policies;
            } else {
                if (tableBusinessGroupingId !== undefined && tableBusinessGroupingId !== '') {
                    filterModel =
                        parkingScheduleTableUtilities.addFilterItemToFilterModel({
                            field: 'business_group_id',
                            operator: 'isAnyOf',
                            value: [tableBusinessGroupingId],
                        }) ?? parkingScheduleTableUtilities.currentTableControl.filterModel;
                }
                const response = await actionsApi.getParkingPolicySummaries(
                    {
                        paginationModel: parkingScheduleTableUtilities.currentTableControl.paginationModel,
                        sortModel: parkingScheduleTableUtilities.currentTableControl.sortModel,
                        filterModel: filterModel,
                    },
                    signal
                );
                parkingScheduleTableUtilities.updateTotalRows(response.data.total_rows);
                return response.data.policies;
            }
        }
        return [];
    };

    const { data: parkableResources } = useQuery({
        queryKey: [
            queryKeys.resources.parkableResources,
            attachResourcesDialogTableUtilities.currentTableControl?.paginationModel,
            attachResourcesDialogTableUtilities.currentTableControl?.sortModel,
            attachResourcesDialogTableUtilities.currentTableControl?.filterModel,
            scheduleToEdit,
            resourcesSelectedForParking,
            businessGroupingId,
            isSchedulesLoading,
        ],
        queryFn: async ({ signal }) => {
            if (
                !!attachResourcesDialogTableUtilities.currentTableControl?.paginationModel &&
                !!attachResourcesDialogTableUtilities.currentTableControl?.sortModel &&
                !!attachResourcesDialogTableUtilities.currentTableControl?.filterModel
            ) {
                await queryClient.cancelQueries({
                    queryKey: [queryKeys.resources.parkableResources],
                });
                setIsParkableResourcesLoading(true);
                const resourceResponse = await loadParkableResources(businessGroupingId ?? undefined, signal);
                setIsParkableResourcesLoading(false);
                return resourceResponse;
            }
        },
        meta: {
            errorMessage: 'Unable to load parkable resources',
        },
        placeholderData: keepPreviousData,
        enabled: attachResourcesDialogTableUtilities.currentTableControl !== undefined && !isSchedulesLoading && businessGroupingId !== '',
    });

    const loadParkableResources = async (bg_id?: string, signal?: AbortSignal) => {
        if (attachResourcesDialogTableUtilities.currentTableControl !== undefined) {
            let filterModel = attachResourcesDialogTableUtilities.currentTableControl.filterModel;

            if (resourcesSelectedForParking !== undefined && resourcesSelectedForParking.length > 0) {
                filterModel =
                    attachResourcesDialogTableUtilities.addFilterItemToFilterModel({
                        field: 'id',
                        operator: 'isNotAnyOf',
                        value: resourcesSelectedForParking.map((resource) => resource.id),
                    }) ?? attachResourcesDialogTableUtilities.currentTableControl.filterModel;
            }

            if (resourcesSelectedForParking.length > 100) {
                const response = await resourcesApi.loadParkableResourcesWorkaround(
                    {
                        paginationModel: attachResourcesDialogTableUtilities.currentTableControl.paginationModel,
                        sortModel: attachResourcesDialogTableUtilities.currentTableControl.sortModel,
                        filterModel: filterModel,
                    },
                    bg_id ? bg_id : businessGroupingId && scheduleToEdit && businessGroupingId !== '' ? businessGroupingId : undefined,
                    signal
                );
                attachResourcesDialogTableUtilities.updateTotalRows(response.data.total_rows);
                return response.data.resources;
            } else {
                const response = await resourcesApi.loadParkableResources(
                    scheduleToEdit?.id ?? '',
                    {
                        paginationModel: attachResourcesDialogTableUtilities.currentTableControl.paginationModel,
                        sortModel: attachResourcesDialogTableUtilities.currentTableControl.sortModel,
                        filterModel: filterModel,
                    },
                    bg_id ? bg_id : businessGroupingId && scheduleToEdit && businessGroupingId !== '' ? businessGroupingId : undefined,
                    signal
                );
                attachResourcesDialogTableUtilities.updateTotalRows(response.data.total_rows);
                return response.data.resources;
            }
        }
    };

    const onClickDeleteSchedule = (schedule: any) => {
        setIsLoading(true);
        actionsApi
            .deleteParkingSchedule(schedule.id)
            .then((response) => {
                setSelectedSchedules([]);
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Schedule deleted.`,
                });
                setIsDrawerOpen(false);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Error deleting schedule: ${SnackbarErrorOutput(error)}.`,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onClickDeleteSchedules = async () => {
        setIsLoading(true);
        actionsApi
            .deleteParkingSchedules(selectedSchedules.map((schedule) => schedule.id))
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .then(() => {
                setSelectedSchedules([]);
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Schedules deleted.`,
                });
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was an error deleting some of your schedules: ${SnackbarErrorOutput(error)}`,
                });
            })
            .finally(() => setIsLoading(false));
    };

    const onClickCreateSchedule = (schedule: IParkingSchedule) => {
        setIsLoading(true);
        actionsApi
            .postParking(schedule)
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Schedule created.`,
                });
                setIsDrawerOpen(false);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Error creating schedule: ${SnackbarErrorOutput(error)}.`,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onClickEditSchedule = (schedule: IParkingSchedule) => {
        setIsLoading(true);
        actionsApi
            .putParking(schedule.id!, schedule)
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Schedule updated.`,
                });
                setIsDrawerOpen(false);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Error updating schedule: ${SnackbarErrorOutput(error)}.`,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onClickCopySchedule = () => {
        setSnackbarOptions({
            snackBarProps: { open: true, autoHideDuration: 6000 },
            alertProps: { severity: 'warning' },
            message: `Copy schedule button pressed`,
        });
    };

    const onOpenRemoveAdvancedParkingDialog = (schedule: IParkingScheduleSummary | undefined) => {
        if (schedule !== undefined) {
            setIsLoading(true);
            actionsApi
                .getParkingPolicy(schedule.id)
                .then((response) => {
                    setScheduleToEdit({
                        ...response.data,
                        scheduled_on_times: response.data.scheduled_on_times?.map((date) => {
                            const dareAsString = date as unknown as string;
                            return new Date(dareAsString);
                        }),
                    });
                    setIsRemoveAdvancedParkingDialogOpen(true);
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `Error loading schedule: ${error}.`,
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setScheduleToEdit(undefined);
        }
    };

    const onRemoveAdvancedParking = (schedule: IParkingSchedule) => {
        actionsApi
            .putParking(schedule.id!, {
                ...schedule,
                type_str: ParkingScheduleType.Basic,
                priority_groups: [
                    {
                        ...schedule.priority_groups[0],
                        parking_delay: 0,
                        parking_delay_unit: ParkingDelayUnit.Seconds,
                        unparking_delay: 0,
                        unparking_delay_unit: ParkingDelayUnit.Seconds,
                        resources: [
                            ...schedule.priority_groups[0].resources,
                            ...schedule.priority_groups.filter((pg) => pg.id !== schedule.priority_groups[0].id).flatMap((pg) => pg.resources),
                        ],
                    },
                ],
            })
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `${schedule.name} has been changed to a basic schedule.`,
                });
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Error changing ${schedule.name} to a basic schedule: ${SnackbarErrorOutput(error)}.`,
                });
            })
            .finally(() => {
                setIsRemoveAdvancedParkingDialogOpen(false);
                setIsLoading(false);
                queryClient.invalidateQueries({ queryKey: [queryKeys.parking.parkingTable] });
            });
    };

    const onOpenScheduleChangeDialog = (schedule: IParkingScheduleSummary | undefined) => {
        if (schedule !== undefined) {
            setIsLoading(true);
            actionsApi
                .getParkingPolicy(schedule.id)
                .then((response) => {
                    setScheduleToEdit({
                        ...response.data,
                        scheduled_on_times: response.data.scheduled_on_times?.map((date) => {
                            const dareAsString = date as unknown as string;
                            return new Date(dareAsString);
                        }),
                    });
                    setTimeout(() => {
                        setIsChangeScheduleDialogOpen(true);
                    }, 100);
                })
                .catch((error) => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `Error loading schedule: ${error}.`,
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setScheduleToEdit(undefined);
        }
    };

    const onOpenEnableDisableSchedulesDialog = (schedules: IParkingScheduleSummary[] | undefined, isScheduledChangeStatus: boolean) => {
        if (schedules !== undefined) {
            setSchedulesToChangeStatusOn(schedules);
            setIsChangeScheduleDialogOpen(true);
            setIsScheduledChangeStatus(isScheduledChangeStatus);
        } else {
            setSchedulesToChangeStatusOn([]);
        }
    };

    const onChangeScheduleStatus_deprecated = (schedule: IParkingSchedule, status: boolean) => {
        setIsLoading(true);
        actionsApi
            .putParking(schedule.id!, { ...schedule, is_enabled: status })
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Schedule has changed status.`,
                });
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Error changing status on schedule: ${SnackbarErrorOutput(error)}.`,
                });
            })
            .finally(() => {
                setIsChangeScheduleDialogOpen(false);
                setIsLoading(false);
            });
    };

    const {
        mutate: onMutateScheduleStatuses,
        isSuccess: isMutateScheduleStatusesSuccessful,
        isPending: isMutateScheduleStatusesLoading,
    } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem changing schedule statuses: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.parking.parkingTable] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `${
                    variables.starts_at === undefined && variables.ends_at === undefined
                        ? `${variables.schedules.length > 1 ? 'Successfully updated schedule statuses' : 'Successfully updated schedule status'}`
                        : `Successfully applied exemption period on ${variables.schedules.length > 1 ? 'schedules' : 'schedule'}`
                }`,
            });
        },
        onSettled: (data, error, variables, context) => {
            setIsChangeScheduleDialogOpen(false);
            setIsScheduledChangeStatus(false);
            setIsEditingExemption(false);
        },
        mutationFn: async (hookRequest: IMutateScheduleStatusesHookObject) => {
            if (hookRequest.starts_at !== undefined && hookRequest.ends_at !== undefined && hookRequest.timeZone !== undefined) {
                return actionsApi.postBatchPolicyExemptions({
                    policies: hookRequest.schedules,
                    is_enabled: hookRequest.isEnabled,
                    starts_at: hookRequest.starts_at.toISOString(),
                    ends_at: hookRequest.ends_at.toISOString(),
                    time_zone_str: hookRequest.timeZone,
                });
            } else {
                return actionsApi.putBatchPolicyStatusUpdate({ policies: hookRequest.schedules, is_enabled: hookRequest.isEnabled });
            }
        },
    });

    const onChangeScheduleStatuses = (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimeZone
    ) => {
        if (isEditingExemption && enableStartDateTime && disableStartDateTime && timeZone) {
            switch (isEnabled) {
                case true:
                    return onEditScheduleExemption({
                        schedule: schedules[0],
                        isEnabled: isEnabled,
                        starts_at: enableStartDateTime,
                        ends_at: disableStartDateTime,
                        timeZone: timeZone?.tzCode,
                    });
                default:
                    return onEditScheduleExemption({
                        schedule: schedules[0],
                        isEnabled: isEnabled,
                        starts_at: disableStartDateTime,
                        ends_at: enableStartDateTime,
                        timeZone: timeZone?.tzCode,
                    });
            }
        } else {
            switch (isEnabled) {
                case true:
                    return onMutateScheduleStatuses({
                        schedules: schedules,
                        isEnabled: isEnabled,
                        starts_at: enableStartDateTime,
                        ends_at: disableStartDateTime,
                        timeZone: timeZone?.tzCode,
                    });
                default:
                    return onMutateScheduleStatuses({
                        schedules: schedules,
                        isEnabled: isEnabled,
                        starts_at: disableStartDateTime,
                        ends_at: enableStartDateTime,
                        timeZone: timeZone?.tzCode,
                    });
            }
        }
    };

    const onClickOpenDeleteExemptionDialog = (schedule: IParkingScheduleSummary) => {
        setIsDeleteExemptionDialogOpen(true);
        setScheduleWithExemption(schedule);
    };

    const {
        mutate: onClickDeleteExemption,
        isSuccess: isOnClickDeleteExemptionSuccess,
        isPending: isOnClickDeleteExemptionLoading,
    } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting exemption on ${variables.name}: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.parking.parkingTable] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Successfully deleted exemption on ${variables.name}`,
            });
        },
        onSettled: (data, error, variables, context) => {
            setScheduleWithExemption(undefined);
        },
        mutationFn: async (schedule: IParkingScheduleSummary) => {
            return await actionsApi.deletePolicyExemptionsByScheduleId(schedule.id);
        },
    });

    const { mutate: onEditScheduleExemption } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem editing exemption on ${variables.schedule.name}: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.parking.parkingTable] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Successfully edited exemption on ${variables.schedule.name}`,
            });
        },
        onSettled: (data, error, variables, context) => {
            setIsChangeScheduleDialogOpen(false);
            setIsScheduledChangeStatus(false);
            setIsEditingExemption(false);
        },
        mutationFn: async (hookRequest: IUpdateScheduleExemptionsHookObject) => {
            return await actionsApi.putPolicyExemptionsByScheduleId(
                {
                    starts_at: hookRequest.starts_at.toISOString(),
                    ends_at: hookRequest.ends_at.toISOString(),
                    is_enabled: hookRequest.isEnabled,
                    time_zone_str: hookRequest.timeZone,
                },
                hookRequest.schedule.id
            );
        },
    });

    return {
        setIsDrawerOpen: setIsDrawerOpen,
        isDrawerOpen: isDrawerOpen,
        loadSelectedParkingSchedule: loadSelectedParkingSchedule,
        scheduleToEdit: scheduleToEdit,
        resources: parkableResources ?? [],
        parkingSchedules: parkingSchedules ?? [],
        selectedSchedules: selectedSchedules,
        setSelectedSchedules: setSelectedSchedules,
        usersBusinessGroupings: usersBusinessGroupings,
        isParkingSchedulesLoading:
            isSchedulesLoading || isParkingSchedulesPreviousData || isParkingScheduleLoading || isMutateScheduleStatusesLoading,
        isParkableResourcesLoading: isParkableResourcesLoading,
        onClickCreateSchedule: onClickCreateSchedule,
        onClickEditSchedule: onClickEditSchedule,
        onClickDeleteSchedule: onClickDeleteSchedule,
        onClickDeleteSchedules: onClickDeleteSchedules,
        onClickCopySchedule: onClickCopySchedule,
        onOpenRemoveAdvancedParkingDialog: onOpenRemoveAdvancedParkingDialog,
        onRemoveAdvancedParking: onRemoveAdvancedParking,
        onOpenScheduleChangeDialog: onOpenScheduleChangeDialog,
        isChangeScheduleDialogOpen: isChangeScheduleDialogOpen,
        setIsChangeScheduleDialogOpen: setIsChangeScheduleDialogOpen,
        isRemoveAdvancedParkingDialogOpen: isRemoveAdvancedParkingDialogOpen,
        setIsRemoveAdvancedParkingDialogOpen: setIsRemoveAdvancedParkingDialogOpen,
        userHasGroupings: userHasGroupings,
        businessGroupingId: businessGroupingId,
        setBusinessGroupingId: setBusinessGroupingId,
        loadParkableResources: loadParkableResources,
        setResourcesSelectedForParking: setResourcesSelectedForParking,
        scheduleToDelete: scheduleToDelete,
        setScheduleToDelete: setScheduleToDelete,
        onChangeScheduleStatuses: onChangeScheduleStatuses,
        isScheduledChangeStatus: isScheduledChangeStatus,
        setIsScheduledChangeStatus: setIsScheduledChangeStatus,
        onClickOpenDeleteDialog: onClickOpenDeleteDialog,
        setIsConfirmDeleteDialogOpen: setIsConfirmDeleteDialogOpen,
        isConfirmDeleteDialogOpen: isConfirmDeleteDialogOpen,
        schedulesToChangeStatusOn: schedulesToChangeStatusOn,
        onOpenEnableDisableSchedulesDialog: onOpenEnableDisableSchedulesDialog,
        isDeleteExemptionDialogOpen,
        setIsDeleteExemptionDialogOpen,
        scheduleWithExemption,
        setScheduleWithExemption,
        onClickOpenDeleteExemptionDialog,
        onClickDeleteExemption,
    };
}
