import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Card, Grid2, Popper, Stack, TextField, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak-fork/web';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IUserSettingPassword } from '@vegaplatformui/models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormField } from '../../../forms';
import { PasswordStrengthIndicator } from '../../password';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPasswordCardProps {
    onUpdatePassword: (data: IUserSettingPassword) => void;
}

const validationPasswordSchema: yup.ObjectSchema<IUserSettingPassword> = yup.object().shape({
    new_password: yup.string().required('Required'),
    confirm_password: yup
        .string()
        .required('Required')
        .oneOf([yup.ref('new_password')], 'Password mismatched'),
    current_password: yup.string().required('Required'),
});

const PasswordSettingsInput: React.FC<IPasswordCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [passwordToCheck, setPasswordToCheck] = useState('');
    const [isPasswordStrong, setIsPasswordStrong] = useState<boolean>(false);
    const { keycloak } = useKeycloak();

    const {
        handleSubmit,
        control,
        formState,
        register,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<IUserSettingPassword>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationPasswordSchema),
        defaultValues: {
            confirm_password: '',
            new_password: '',
            current_password: '',
        },
    });

    const onSubmit: SubmitHandler<IUserSettingPassword> = (data) => {
        props.onUpdatePassword(data);
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    return (
        <div>
            <Grid2
                id={'new-password-form'}
                size={12}
                component={'form'}
                container
                spacing={2}
                onSubmit={handleSubmit((data) => {
                    onSubmit(data);
                })}
            >
                <Grid2 size={12}>
                    <FormField label='Current Password' htmlFor='current_password'>
                        <Controller
                            name={'current_password'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id='current_password'
                                    type='password'
                                    autoComplete='current-password'
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    size='small'
                                    fullWidth={true}
                                    error={!!errors.current_password}
                                    helperText={errors.current_password?.message}
                                />
                            )}
                        />
                    </FormField>
                </Grid2>
                <Grid2 size={12}>
                    <FormField label='New Password' htmlFor='new_password'>
                        <Controller
                            name={'new_password'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id='new_password'
                                    type='password'
                                    autoComplete='new-password'
                                    size='small'
                                    fullWidth={true}
                                    value={value}
                                    onFocus={(e) => {
                                        setAnchorEl(e.currentTarget);
                                    }}
                                    {...register('new_password', {
                                        required: {
                                            value: true,
                                            message: 'Required',
                                        },
                                        validate: (password: string) => {
                                            if (password.includes(keycloak.tokenParsed?.email)) {
                                                return 'Password can not contain your email';
                                            }
                                        },
                                        onChange: (e) => setPasswordToCheck(e.currentTarget.value),
                                        onBlur: () => {
                                            setAnchorEl(null);
                                        },
                                    })}
                                    error={!!errors.new_password || (!isPasswordStrong && passwordToCheck !== '')}
                                    helperText={
                                        errors.new_password?.message ??
                                        (!isPasswordStrong && passwordToCheck !== '' && 'Password does not meet requirements')
                                    }
                                />
                            )}
                        />
                    </FormField>
                </Grid2>
                <Grid2 size={12}>
                    <FormField label='Confirm New Password' htmlFor='confirm_password'>
                        <Controller
                            name={'confirm_password'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id='confirm_password'
                                    type='password'
                                    autoComplete='new-password'
                                    size='small'
                                    fullWidth={true}
                                    value={value}
                                    onChange={(e) => onChange(e.target.value)}
                                    error={!!errors.confirm_password}
                                    helperText={errors.confirm_password?.message}
                                />
                            )}
                        />
                    </FormField>
                </Grid2>
                <Grid2 size={12}>
                    <Stack spacing={2} direction={'row'} justifyContent={'flex-start'}>
                        <Button form={'new-password-form'} variant='contained' disabled={!formState.isDirty} type='submit' disableElevation>
                            Save
                        </Button>
                    </Stack>
                </Grid2>
            </Grid2>
            <Popper open={Boolean(anchorEl)} placement={'bottom-start'} anchorEl={anchorEl}>
                <Card elevation={3}>
                    <PasswordStrengthIndicator password={passwordToCheck} setIsPasswordStrong={setIsPasswordStrong} />
                </Card>
            </Popper>
        </div>
    );
};

const useStyles = customMakeStyles<IPasswordCardProps>()((theme, props) => ({}));

export { PasswordSettingsInput };
