import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ConfirmDeleteScheduleDialog } from '../../../parking/confirm-delete-schedule-dialog';
import { RemoveAdvancedParkingDialog } from '../../../parking/remove-advanced-parking-dialog';
import { ParkingSchedulesTable } from '../../../parking/parking-schedule-table/parking-schedules-table';
import { IParkingScheduleSummary, ParkingScheduleType } from '@vegaplatformui/models';
import { IParkingScheduleTableProps } from '../../../parking/parking-types';
import { ChangeScheduleStatusDialog } from '../../../parking/change-schedule-status-dialog/change-schedule-status-dialog';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardParkingSchedulesTabProps extends IParkingScheduleTableProps {
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    setParkingScheduleType: React.Dispatch<React.SetStateAction<ParkingScheduleType>>;
}

const BusinessGroupingWizardParkingSchedulesTab: React.FC<IBusinessGroupingWizardParkingSchedulesTabProps> = (props) => {
    const { classes, cx } = useStyles(props);

    const onClickAction = (schedule: IParkingScheduleSummary, scheduleType?: ParkingScheduleType) => {
        props.setScheduleToEdit(props.parkingSchedules.find((scheduleToFind) => scheduleToFind.id === schedule.id));
        scheduleType ? props.setParkingScheduleType(scheduleType) : props.setParkingScheduleType(schedule.type_str);
    };

    const onClickDelete = (schedule: IParkingScheduleSummary) => {
        props.setIsConfirmDeleteDialogOpen(false);
        props.onClickDeleteSchedule(schedule);
    };
    return (
        <>
            <ConfirmDeleteScheduleDialog
                isConfirmDeleteDialogOpen={props.isConfirmDeleteDialogOpen}
                onCloseConfirmDeleteDialog={() => {
                    props.setIsConfirmDeleteDialogOpen(false);
                    setTimeout(() => {
                        props.setScheduleToDelete(undefined);
                        props.setSelectedSchedules([]);
                    }, 200);
                }}
                confirmDeleteSchedule={onClickDelete}
                scheduleToDelete={props.scheduleToDelete}
                schedulesToDelete={props.selectedSchedules}
                onClickDeleteSelectedSchedules={() => {
                    props.setIsConfirmDeleteDialogOpen(false);
                    props.onClickDeleteSelectedSchedules();
                }}
            />
            <ChangeScheduleStatusDialog
                show={props.isChangeScheduleDialogOpen}
                onClose={() => {
                    props.setIsChangeScheduleDialogOpen(false);
                    props.setScheduleToEdit(undefined);
                }}
                onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                isScheduled={props.isScheduled}
                schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
            />
            <RemoveAdvancedParkingDialog
                show={props.isRemoveAdvancedParkingDialogOpen}
                onClose={() => {
                    props.setIsRemoveAdvancedParkingDialogOpen(false);
                    props.setScheduleToEdit(undefined);
                }}
                onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                scheduleToEdit={props.scheduleToEdit}
            />
            <ParkingSchedulesTable
                parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                parkingSchedules={props.parkingSchedules}
                selectedSchedules={props.selectedSchedules}
                setSelectedSchedules={props.setSelectedSchedules}
                isLoading={props.isParkingLoading}
                onClickAction={onClickAction}
                onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                onOpenScheduleChangeDialog={props.onOpenScheduleChangeDialog}
                onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
            />
        </>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardParkingSchedulesTabProps>()((theme, props) => ({}));

export { BusinessGroupingWizardParkingSchedulesTab };
