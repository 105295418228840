import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useKeycloak } from '@react-keycloak-fork/web';
import { ParkingScheduleBusinessGroupId, ParkingScheduleTableBusinessGroupId, ResourceIdToRefresh, SnackBarOptions } from '../jotai/atom';
import { useErrorHandlingV2 } from '../utilities/use-error-handling';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { CreateContainerForm } from '../spaces/spaces-landing/spaces-landing';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import {
    ContainerType,
    IParkingSchedule,
    IResource,
    IResourcesSummary,
    IResourceSummaryActiveResources,
    IResourceSummaryOnSchedule,
    IResourceSummaryTotalResources,
    IUserBusinessGroups,
    IVegaContainer,
    ParkingScheduleType,
} from '@vegaplatformui/models';
import { ActionsApi, BusinessGroupingsApi, ContainerApi, RecommendationsApi, UsersApi } from '@vegaplatformui/apis';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { useAtom, useAtomValue } from 'jotai';

const resourcesTabs = [
    { label: 'All', id: 'all' },
    // { label: 'Aws', id: 'aws' },
    // { label: 'Azure', id: 'azure' },
    // { label: 'Gcp', id: 'gcp' },
];
const defaultContainerToEditForm = {
    containerType: ContainerType.ResourcePool,
    name: '',
    budget: 0,
    space_id: '',
    description: '',
};
const defaultResourcesSummary = {
    projected_monthly_savings: 0,
    actual_savings_mtd: 0,
    num_resources: 0,
    active_resources: 0,
    resources_on_a_schedule: 0,
};

export interface IUseResourcesApiHook {
    anchorEl: HTMLElement | null;
    containerToEditForm: CreateContainerForm;
    isCreateMenuOpen: boolean;
    isCreateResourcePoolDialog: boolean;
    isDrawerScheduleOpen: boolean;
    isLoading: boolean;
    onClickCreateResourcePoolItem: () => void;
    onClickCreateSchedule: (schedule: IParkingSchedule) => void;
    onClickParkingScheduleItem: (scheduleType: ParkingScheduleType) => void;
    onClickToggleCreateDropDown: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onCloseCreateContainerDialog: () => void;
    onCloseDrawerScheduler: () => void;
    onCloseToggleCreateDropDown: () => void;
    onSubmitCreateContainerForm: (data: CreateContainerForm, children: IResource[]) => void;
    parkingScheduleType: ParkingScheduleType;
    recommendationsApi: RecommendationsApi;
    resources: IResource[];
    selectedResources: IResource[];
    setContainerToEditForm: React.Dispatch<React.SetStateAction<CreateContainerForm>>;
    setResourcesSelectedForParking: (resources: IResource[]) => void;
    setSelectedResources: React.Dispatch<React.SetStateAction<IResource[]>>;
    usersBusinessGroupings: IUserBusinessGroups[];
    workloads: IVegaContainer[];
    summaryActiveResources: IResourceSummaryActiveResources;
    summaryTotalResources: IResourceSummaryTotalResources;
    summaryScheduledResources: IResourceSummaryOnSchedule;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseResourcesApiProps {
    tableIdentifier: string;
}

export function useResourcesApi(props: IUseResourcesApiProps): IUseResourcesApiHook {
    const { keycloak } = useKeycloak();
    const [snackbarOptions, setSnackbarOptions] = useAtom(SnackBarOptions);
    const [withErrorHandlingV2] = useErrorHandlingV2();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isCreateMenuOpen = Boolean(anchorEl);
    const [isCreateResourcePoolDialog, setIsCreateResourcePoolDialog] = useState<boolean>(false);
    const [isDrawerScheduleOpen, setIsDrawerScheduleOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(resourcesTabs[0].id);
    const [workloads, setWorkloads] = useState<IVegaContainer[]>([]);
    const [selectedResources, setSelectedResources] = useState<IResource[]>([]);
    const resourcesTableUtilities = useTableUtilities(props.tableIdentifier);
    const businessGroupingId = useAtomValue(ParkingScheduleTableBusinessGroupId);

    const [summary, setSummary] = useState<IResourcesSummary>(defaultResourcesSummary);
    const [containerToEditForm, setContainerToEditForm] = React.useState<CreateContainerForm>(defaultContainerToEditForm);
    const [parkingScheduleType, setParkingScheduleType] = React.useState<ParkingScheduleType>(ParkingScheduleType.Basic);
    const [usersBusinessGroupings, setUsersBusinessGroupings] = React.useState<IUserBusinessGroups[]>([]);
    const [resourcesSelectedForParking, setResourcesSelectedForParking] = useState<IResource[]>([]);
    const [resourceId, setResourceId] = useAtom(ResourceIdToRefresh);
    const queryClient = useQueryClient();

    const containerApi = useMemo(() => {
        const containerApiInstance = new ContainerApi();
        containerApiInstance.keycloak = keycloak;
        return containerApiInstance;
    }, [keycloak]);

    const recommendationsApi = useMemo(() => {
        const recommendationsApiInstance = new RecommendationsApi();
        recommendationsApiInstance.keycloak = keycloak;
        return recommendationsApiInstance;
    }, [keycloak]);

    const usersApi = useMemo(() => {
        const usersApi = new UsersApi();
        usersApi.keycloak = keycloak;
        return usersApi;
    }, [keycloak]);

    const businessGroupingApi = useMemo(() => {
        const businessGroupingApi = new BusinessGroupingsApi();
        businessGroupingApi.keycloak = keycloak;
        return businessGroupingApi;
    }, [keycloak]);

    const onTabChange = (event: React.ChangeEvent<object>, newValue: string) => {
        setCurrentTab(newValue);
    };

    const onClickToggleCreateDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onCloseToggleCreateDropDown = () => {
        setAnchorEl(null);
    };

    const onClickCreateResourcePoolItem = () => {
        onCloseToggleCreateDropDown();
        setIsCreateResourcePoolDialog(true);
    };
    const onClickParkingScheduleItem = (scheduleType: ParkingScheduleType) => {
        onCloseToggleCreateDropDown();
        setParkingScheduleType(scheduleType);
        setIsDrawerScheduleOpen(true);
    };

    const getSummary = async () => {
        withErrorHandlingV2(async () => {
            const res = await containerApi.getResourcesSummary();
            if (res.status !== 200) return;
            setSummary(res.data);
        }, 'There was an error getting summary informations.');
    };

    const { data: summaryActiveResourcesCount, isLoading: isSummaryActiveResourcesCountLoading } = useQuery({
        queryKey: [queryKeys.resources.resourceSummaryActiveCount],
        queryFn: async ({ signal }) => {
            const response = await containerApi.getResourcesSummaryActiveResourcesCount(signal);
            return response.data.active_resources;
        },
        meta: {
            errorMessage: 'Unable to get count of active resources',
        },
        placeholderData: keepPreviousData,
    });

    const { data: summaryResourcesCount, isLoading: isSummaryResourcesCountLoading } = useQuery({
        queryKey: [queryKeys.resources.resourceSummaryCount],
        queryFn: async ({ signal }) => {
            const response = await containerApi.getResourcesSummaryTotalCount(signal);
            return response.data.num_resources;
        },
        meta: {
            errorMessage: 'Unable to get count of total resources',
        },
        placeholderData: keepPreviousData,
    });

    const { data: summaryScheduledResourcesCount, isLoading: isSummaryScheduledResourcesCountLoading } = useQuery({
        queryKey: [queryKeys.resources.resourceSummaryScheduledResourcesCount],
        queryFn: async ({ signal }) => {
            const response = await containerApi.getResourcesSummaryOnScheduleCount(signal);
            return response.data.resources_on_a_schedule;
        },
        meta: {
            errorMessage: 'Unable to get count of total scheduled resources',
        },
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        loadUsersBusinessGroups();
    }, [keycloak.token]);

    const loadUsersBusinessGroups = () => {
        businessGroupingApi
            .getValidBusinessGroupingParents({})
            .then((response) => {
                if (response.data.length > 0) {
                    const businessGroupingsSorted = response.data.sort(function (a, b) {
                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                        return 0;
                    });
                    setUsersBusinessGroupings(businessGroupingsSorted);
                } else {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `User cannot create a schedule since they have no assigned business groupings.`,
                    });
                }
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Unable to get the user's business groupings: ${SnackbarErrorOutput(error)}`,
                });
            });
    };

    const {
        data: resources,
        isLoading: isResourcesLoading,
        isPlaceholderData: isResourcesPreviousData,
    } = useQuery({
        queryKey: [
            queryKeys.resources.resourcesTable,
            resourcesTableUtilities.currentTableControl?.filterModel,
            resourcesTableUtilities.currentTableControl?.paginationModel,
            resourcesTableUtilities.currentTableControl?.sortModel,
            businessGroupingId,
        ],
        queryFn: async ({ signal }) => {
            const response = await getResources(signal);
            return response;
        },
        meta: {
            errorMessage: 'Unable to get resources',
        },
        placeholderData: keepPreviousData,
        enabled: resourcesTableUtilities.currentTableControl !== undefined,
    });
    const getResources = async (signal: AbortSignal): Promise<IResource[] | undefined> => {
        if (resourcesTableUtilities.currentTableControl !== undefined) {
            let filterModel = resourcesTableUtilities.currentTableControl.filterModel;

            if (resourcesSelectedForParking !== undefined && resourcesSelectedForParking.length > 0) {
                filterModel =
                    resourcesTableUtilities.addFilterItemToFilterModel({
                        field: 'id',
                        operator: 'isNotAnyOf',
                        value: resourcesSelectedForParking.map((res) => res.id),
                    }) ?? resourcesTableUtilities.currentTableControl.filterModel;
            }

            if (businessGroupingId !== undefined && businessGroupingId !== '') {
                filterModel =
                    resourcesTableUtilities.addFilterItemToFilterModel({
                        field: 'business_group_id',
                        operator: 'isAnyOf',
                        value: [businessGroupingId],
                    }) ?? resourcesTableUtilities.currentTableControl.filterModel;
            }

            if (resourcesTableUtilities.currentTableControl !== undefined) {
                const response = await containerApi.loadResources(
                    {
                        sortModel: resourcesTableUtilities.currentTableControl.sortModel,
                        filterModel: filterModel,
                        paginationModel: resourcesTableUtilities.currentTableControl.paginationModel,
                    },
                    signal
                );

                if (response.status !== 200) return [];
                resourcesTableUtilities.updateTotalRows(response.data.total_rows);
                return response.data.resources;
            }
        }
    };

    const {} = useQuery({
        queryKey: [queryKeys.resources.refreshResource, resourceId],
        queryFn: async () => {
            if (resourceId !== undefined) {
                const response = await containerApi.getResourceById(resourceId);
                const resourceToUpdate = response.data;
                queryClient.setQueryData(
                    [
                        queryKeys.resources.resourcesTable,
                        resourcesTableUtilities.currentTableControl?.filterModel,
                        resourcesTableUtilities.currentTableControl?.paginationModel,
                        resourcesTableUtilities.currentTableControl?.sortModel,
                    ],
                    resourceToUpdate && resources
                        ? [...resources.map((resource) => (resource.id === resourceToUpdate.id ? resourceToUpdate : resource))]
                        : resources
                );
                setResourceId(undefined);
                return response.data;
            }
        },

        meta: { errorMessage: 'Unable to refresh resource' },
        placeholderData: keepPreviousData,
        enabled: resourceId !== undefined,
    });

    const getWorkloads = () => {
        withErrorHandlingV2(async () => {
            const res = await containerApi.loadWorkloads();
            if (res.status !== 200) return;
            setWorkloads(
                res.data.map((x: any) => {
                    return {
                        id: x.id,
                        container_type: ContainerType.Workload,
                        name: x.name,
                        description: x.description,
                        parent_id: x.space_id,
                    };
                })
            );
        }, 'There was an error loading workloads.');
    };

    const onCloseCreateContainerDialog = useCallback(() => {
        setIsCreateResourcePoolDialog(false);
        setContainerToEditForm(defaultContainerToEditForm);
    }, []);

    const onSubmitCreateContainerForm = useCallback((data: CreateContainerForm, children: IResource[]) => {
        withErrorHandlingV2(
            async () => {
                const res = await containerApi.createResourcePool({
                    name: data.name,
                    budget: 0, //data.budget
                    description: data.description,
                    workload_id: data.space_id ? data.space_id : null,
                    resources: children,
                });
                setSelectedResources([]);
            },
            'There was a problem creating your resource pool',
            'Your resource pool was created successfully'
        );
    }, []);

    const onCloseDrawerScheduler = useCallback(() => {
        setIsDrawerScheduleOpen(false);
    }, []);

    useEffect(() => {
        getWorkloads();
    }, []);

    // useEffect(() => {
    //     getSummary();
    // }, [
    //     resourcesTableUtilities.currentTableControl?.paginationModel,
    //     resourcesTableUtilities.currentTableControl?.sortModel,
    //     resourcesTableUtilities.currentTableControl?.filterModel,
    //     resourcesSelectedForParking,
    // ]);

    const actionsApi = useMemo(() => {
        const actionsApi = new ActionsApi();
        actionsApi.keycloak = keycloak;
        return actionsApi;
    }, [keycloak]);

    const onClickCreateSchedule = (schedule: IParkingSchedule) => {
        setIsLoading(true);
        actionsApi
            .postParking(schedule)
            .then((response) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `Schedule created.`,
                });
                setSelectedResources([]);
                onCloseDrawerScheduler();
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Error creating schedule: ${SnackbarErrorOutput(error)}.`,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        anchorEl: anchorEl,
        containerToEditForm: containerToEditForm,
        isCreateMenuOpen: isCreateMenuOpen,
        isCreateResourcePoolDialog: isCreateResourcePoolDialog,
        isDrawerScheduleOpen: isDrawerScheduleOpen,
        isLoading: isLoading || isResourcesLoading || isResourcesPreviousData,
        onClickCreateResourcePoolItem: onClickCreateResourcePoolItem,
        onClickCreateSchedule: onClickCreateSchedule,
        onClickParkingScheduleItem: onClickParkingScheduleItem,
        onClickToggleCreateDropDown: onClickToggleCreateDropDown,
        onCloseCreateContainerDialog: onCloseCreateContainerDialog,
        onCloseDrawerScheduler: onCloseDrawerScheduler,
        onCloseToggleCreateDropDown: onCloseToggleCreateDropDown,
        onSubmitCreateContainerForm: onSubmitCreateContainerForm,
        parkingScheduleType: parkingScheduleType,
        recommendationsApi: recommendationsApi,
        resources: resources ?? [],
        selectedResources: selectedResources,
        setContainerToEditForm: setContainerToEditForm,
        setResourcesSelectedForParking,
        setSelectedResources: setSelectedResources,
        usersBusinessGroupings: usersBusinessGroupings,
        workloads: workloads,
        summaryActiveResources: { active_resources: summaryActiveResourcesCount ?? 0, isLoading: isSummaryActiveResourcesCountLoading },
        summaryTotalResources: { num_resources: summaryResourcesCount ?? 0, isLoading: isSummaryResourcesCountLoading },
        summaryScheduledResources: {
            resources_on_a_schedule: summaryScheduledResourcesCount ?? 0,
            isLoading: isSummaryScheduledResourcesCountLoading,
        },
    };
}
