import React, { useMemo } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, PaperProps, Stack, Typography } from '@mui/material';
import { PaperComponent } from '../utilities/paper-component';

import { IFile } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmDeleteFileDialogProps {
    selectedFiles: IFile[];
    onClickDeleteSelectedFiles: () => void;
    isConfirmDeleteDialogOpen: boolean;
    onCloseConfirmDeleteDialog(): void;
    fileToDelete?: IFile;
    confirmDeleteFile(file: IFile): void;
}

const ConfirmDeleteFileDialog: React.FC<IConfirmDeleteFileDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const fileName = useMemo(() => {
        if (props.fileToDelete) {
            const splitFileName = props.fileToDelete?.filename.split('/');
            return splitFileName.length > 1 ? splitFileName[1] : props.fileToDelete.filename;
        }
        return undefined;
    }, [props.fileToDelete]);

    return (
        <Dialog
            fullWidth
            open={props.isConfirmDeleteDialogOpen}
            onClose={props.onCloseConfirmDeleteDialog}
            PaperComponent={(paperProps: PaperProps) => PaperComponent(paperProps, '#confirm-delete-file-dialog')}
            aria-labelledby='confirm-create-file-dialog'
        >
            <DialogTitle
                className={cx(commonStyles.classes.FormTitlePadding, commonStyles.classes.FormTitle)}
                style={{ cursor: 'move' }}
                variant={'h5'}
                id='confirm-delete-file-dialog'
            >
                {props.selectedFiles.length > 1 ? (
                    <Typography fontWeight={'600'} variant={'inherit'}>
                        Are you sure you want to delete {props.selectedFiles.length} files?
                    </Typography>
                ) : fileName ? (
                    <Typography fontWeight={'600'} variant={'inherit'}>
                        Are you sure you want to delete {fileName}?
                    </Typography>
                ) : (
                    <Typography fontWeight={'600'} variant={'inherit'}>
                        Are you sure you want to delete this file?
                    </Typography>
                )}
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Typography variant={'subtitle1'}> You cannot undo this action.</Typography>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack spacing={1.5} direction='row' width={'100%'} justifyContent={'flex-end'}>
                    <Button
                        variant={'cancel'}
                        className={cx(commonStyles.classes.LowercaseTextButton)}
                        disableElevation={true}
                        color={'secondary'}
                        autoFocus
                        onClick={props.onCloseConfirmDeleteDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={cx(commonStyles.classes.LowercaseTextButton)}
                        color={'error'}
                        variant={'contained'}
                        onClick={() => {
                            if (props.selectedFiles.length > 1) {
                                return props.onClickDeleteSelectedFiles();
                            } else {
                                return props.confirmDeleteFile(props.fileToDelete!);
                            }
                        }}
                        disableElevation={true}
                    >
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmDeleteFileDialogProps>()((theme, props) => ({}));

export { ConfirmDeleteFileDialog };
