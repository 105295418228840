export enum ScheduleReportReoccurrenceType {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export enum CommunicationSettingType {
    Phone = 'Phone',
    Email = 'Email',
}

export interface ICommunicationSettingItem {
    value: string | undefined;
    type: CommunicationSettingType;
    isDefault: boolean;
}

export enum NotificationTabsType {
    Notifications = 'notifications',
    ScheduleReports = 'schedule-reports',
    CommunicationSettings = 'communication-settings',
    History = 'history',
}

export enum NotificationPriorityType {
    Low = 'low',
    Medium = 'medium',
    Critical = 'critical',
}

export enum NotificationChannelName {
    InApp = 'in_app',
    Email = 'email',
    Sms = 'sms',
    Teams = 'teams',
    Slack = 'slack',
    Chat = 'chat',
    Push = 'push',
}

export interface IVegaNotification {
    id: string;
    name: string;
    is_enabled: boolean;
    channels: IVegaNotificationChannel[];
    description?: string;
    settings: IUserNotificationSettings;
}

export interface IVegaNotificationChannel {
    id: string;
    channel_id: string;
    channel_name: NotificationChannelName;
    is_enabled: boolean;
}

export interface INotificationConfiguration {
    id: string;
    user_id: string;
    notification_type_id: string;
    notification_channel_id: string;
    is_enabled: boolean;
    settings: IUserNotificationSettings;
}

export interface IGetNotificationChannelsResponse {
    data: INotificationChannel[];
}

export interface INotificationChannel {
    is_active: boolean;
    name: NotificationChannelName;
    id: string;
}

export interface INotificationType {
    id: string;
    name: string;
    is_active: boolean;
    description?: string;
}
export interface IGetNotificationTypesResponse {
    data: INotificationType[];
}

export interface INotificationConfigurationResponse {
    data: INotificationConfiguration[];
}

export interface IGetNotificationTypesResponse {
    data: INotificationType[];
}

export interface IPutUserConfiguration {
    id: string;
    notification_type_id: string;
    notification_channel_id: string;
    is_enabled: boolean;
}

export interface IPostUserConfiguration {
    notification_type_id: string;
    notification_channel_id: string;
    is_enabled: boolean;
}

export enum NotificationFrequency {
    OnDemand = 'on-demand',
    Digest = 'digest',
    Scheduled = 'scheduled',
}

export interface IUserNotificationSettings {
    frequency: NotificationFrequency;
}

export interface IGenericUpsertUserNotifications {
    id?: string;
    user_id: string;
    notification_type: string;
    notification_channel: NotificationChannelName;
    is_enabled: boolean;
    settings: IUserNotificationSettings;
}

export interface IGenericUpsertUserNotificationResponse {
    message: string;
    result: INotificationConfiguration;
}

export interface IPutNotificationStatus {
    is_enabled: boolean;
    notification_type_id: string;
}

export interface IUpdateChannelPreferenceHook {
    id?: string;
    notification_type_id: string;
    notification_channel_id: string;
    is_enabled: boolean;
}

export interface IUpsertNotificationPreferenceHook {
    id?: string;
    notification_type_name: string;
    notification_channel_name: NotificationChannelName;
    settings: IUserNotificationSettings;
    is_enabled: boolean;
}

export interface IUpdateNotificationStatusHook {
    is_enabled: boolean;
    notification_type_id: string;
}

export interface IUpdateNotificationSettingsHook {
    isUpdate: boolean;
    request: IUpdateNotificationSettingsRequest;
}

export interface IUpdateNotificationSettingsRequest {
    organization_id: string;
    email: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
}

export enum NotificationIntegrationTypeType {
    AnomalyDetected = 'anomaly_detected',
}

export enum OrganizationNotificationLoadingType {
    Submit = 'submit',
    Delete = 'delete',
}

export interface IOrganizationNotificationConfiguration {
    id: string;
    notification_type_id: string;
    notification_channel_id: string;
    notification_channel: NotificationChannelName;
    webhook_url: string;
    is_enabled: boolean;
}

export interface IGetOrganizationConfigurationsResponse {
    configurations: IOrganizationNotificationConfiguration[];
}

export interface IPostOrganizationIntegrationRequest {
    notification_channel: NotificationChannelName;
    notification_type: NotificationIntegrationTypeType;
    org_slug: string;
    webhook_url: string;
}

export interface IPutOrganizationIntegrationRequest {
    id: string;
    notification_channel: NotificationChannelName;
    webhook_url: string;
    is_enabled: boolean;
}

export interface IDeleteOrganizationIntegrationRequest {
    id: string;
    notification_channel: NotificationChannelName;
}

export interface IUpdateTeamsNewConfiguration {
    id: string;
    webhook_url: string;
}

export interface IOrganizationConfigurationForm {
    webhook_url: string;
}

export interface IPostIntegrationResponse {
    message: string;
    new_config: IUpdateTeamsNewConfiguration;
}

export interface IPutIntegrationResponse {
    message: string;
    updated_config: IUpdateTeamsNewConfiguration;
}
