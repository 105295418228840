import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { IParkingSchedule } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScheduleInactiveDialogProps {
    show: boolean;
    onClose: () => void;
    onToggleYes: () => void;
    onToggleNo: () => void;
}

const ScheduleInactiveWarningDialog: React.FC<IScheduleInactiveDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <Grid
                    container
                    className={cx(classes.GridContainer)}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='flex-start'
                    spacing={2}
                >
                    <Grid xs={11} item>
                        <Typography fontWeight={500}>You are about to create a schedule but it is set as “Off”,</Typography>
                        <Typography fontWeight={500}>do you want to turn it “On”?</Typography>
                    </Grid>
                    <Grid xs={1} item className={cx(classes.CancelButton)}>
                        <IconButton aria-label={'Close'} size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "Yes" to turn the schedule on.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Select "No" to keep the schedule off.
                </DialogContentText>
                <DialogContentText className={cx(classes.ContentTextDivider)} variant={'body2'} id='confirm-close-dialog-description'>
                    Both of the above actions will still create the schedule.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Or return to editing by selecting the close button.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onToggleNo} autoFocus>
                    No
                </Button>
                <Button onClick={props.onToggleYes}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IScheduleInactiveDialogProps>()((theme, props) => ({
    GridContainer: { marginTop: '-1.5rem' },
    CancelButton: { marginRight: '-1rem' },
    ContentTextDivider: { marginBottom: '1rem' },
}));

export { ScheduleInactiveWarningDialog };
