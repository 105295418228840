import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid2, Skeleton } from '@mui/material';
import { FormField } from '../forms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFormFieldLoadingSkeletonProps {
    formField: React.ReactElement | string;
    htmlFor: string;
}

const FormFieldLoadingSkeleton: React.FC<IFormFieldLoadingSkeletonProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { formField, htmlFor } = props;

    return (
        <Grid2 size={12}>
            <FormField label={formField} htmlFor={htmlFor}>
                <Skeleton height={37} variant={'rounded'} />
            </FormField>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IFormFieldLoadingSkeletonProps>()((theme, props) => ({}));

export { FormFieldLoadingSkeleton };
