import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Badge, Box, Chip, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { ShowUpdatedUi } from '../recoil/atom';
import mixpanel from 'mixpanel-browser';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { useKeycloak } from '@react-keycloak-fork/web';
import process from 'process';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IToggleUpdatedUiProps {}

const ToggleUpdatedUi: React.FC<IToggleUpdatedUiProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { keycloak } = useKeycloak();
    const [showUpdatedUi, setShowUpdatedUi] = useRecoilState(ShowUpdatedUi);

    const onChangeUpdateToggle = (value: boolean) => {
        localStorage.setItem('showUpdatedUi', value.toString());
        (process.env?.NX_ENVIRONMENT === 'prod' || process.env.NX_USE_MIXPANEL) &&
            process.env.NX_MIXPANEL_PROJECT_TOKEN &&
            mixpanel.track('UI Beta Toggle', { value: value ? 'on' : 'off', domain: keycloak?.realm, user: keycloak.tokenParsed?.email });
        setShowUpdatedUi(value);
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    className={classes.PreviewSwitch}
                    aria-label={`turn ${showUpdatedUi ? 'on' : 'off'} the new UI preview`}
                    onChange={(event, checked) => onChangeUpdateToggle(checked)}
                    checked={showUpdatedUi}
                />
            }
            label={
                <Stack direction={'row'} spacing={3.5}>
                    <Typography className={cx(classes.Label)} variant={'inherit'}>
                        Try the new UI
                    </Typography>
                    <StyledToolTip
                        title={
                            "We've moved the bottom left menu items from the sidebar to the header bar in the upper right, and the settings pages now feature an updated design! For questions or feedback, please contact us at support@vegacloud.io."
                        }
                    >
                        <Badge
                            className={cx(classes.BadgeStyle)}
                            aria-label={'currently in beta'}
                            badgeContent={
                                <Typography className={cx(classes.BadgeTypography)} variant={'body1'}>
                                    Beta
                                </Typography>
                            }
                            color={'secondary'}
                        />
                    </StyledToolTip>
                </Stack>
            }
            labelPlacement='end'
        />
    );
};

const useStyles = customMakeStyles<IToggleUpdatedUiProps>()((theme, props) => ({
    PreviewSwitch: {
        '& .MuiSwitch-thumb': {
            color: theme.palette.common.white,
        },
    },
    Label: { color: theme.palette.text.primary, fontWeight: theme.typography.fontWeightMedium },
    BadgeTypography: { fontWeight: theme.typography.fontWeightMedium, color: theme.palette.getContrastText(theme.palette.warning.main) },
    BadgeStyle: {
        '& .MuiBadge-badge': {
            marginTop: '4px',
            borderRadius: '3px',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            height: '1.25rem',
            backgroundColor: theme.palette.warning.main,
        },
    },
}));

export { ToggleUpdatedUi };
