import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid2, Skeleton, Stack } from '@mui/material';
import { SettingsContentHeader } from '../settings-content-header';
import { SettingsV2Divider } from '../settings-v2-divider';
import { SettingsDetailTile } from '../settings-detail-tile';
import { Add } from '@mui/icons-material';
import { SsoConfigurationList } from './sso-configuration-list';
import { ISSONameIDPolicyFormat, ISSORoleMapping, IUserSettingRealmRole, IUserSettingSSOSaml, IUserSettingSSOSamlForm } from '@vegaplatformui/models';
import { RoleMappingCard } from '../../sso/sso-role-mapping/role-mapping-card';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoSettingsV2Props {
    isLoading: boolean;
    idpList: IUserSettingSSOSaml[];
    nameIDPolicyFormatOptions: ISSONameIDPolicyFormat[];
    onUpdate: (data: IUserSettingSSOSamlForm) => void;
    onCreate: (data: IUserSettingSSOSamlForm) => void;
    onDelete: (data: string) => void;
    roleMappings: ISSORoleMapping[];
    roles: IUserSettingRealmRole[];
    onSaveRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onCreateRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onDeleteRoleMapping: (roleMapping: ISSORoleMapping) => void;
    isRoleMappingsLoading: boolean;
}

const SsoSettingsV2: React.FC<ISsoSettingsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const flags = useFlags();
    const [isConfigurationDrawerOpen, setIsConfigurationDrawerOpen] = useState(false);

    const onClickOpenConfigurationDrawer = () => {
        setIsConfigurationDrawerOpen(true);
    };

    const onClickCloseConfigurationDrawer = () => {
        setIsConfigurationDrawerOpen(false);
    };

    return (
        <>
            {props.isLoading ? (
                <Grid2 container spacing={2}>
                    <SettingsContentHeader title={'Single Sign-On'} />
                    <SettingsV2Divider />
                    <SettingsDetailTile
                        title={'Configured SSO'}
                        subtitle={'Manage SSO configurations, limit 3.'}
                        children={<Skeleton width={'100%'} height={'200px'} />}
                    />
                    {flags.roleMappings && [
                        <SettingsV2Divider key={'loading-divider'} />,
                        <SettingsDetailTile
                            key={'loading-role-mapping'}
                            title={'Role Mappings'}
                            subtitle={'Map idP groups with one or more Vega roles to streamline authorization.'}
                            children={<Skeleton width={'100%'} height={'109px'} />}
                        />,
                    ]}
                </Grid2>
            ) : (
                <Grid2 container spacing={2}>
                    <SettingsContentHeader
                        title={'Single Sign-On'}
                        children={
                            <Stack>
                                <Button
                                    variant={'contained'}
                                    startIcon={<Add />}
                                    size={'small'}
                                    disabled={props.idpList.length >= 3 && !flags.showSsoCreation}
                                    onClick={() => onClickOpenConfigurationDrawer()}
                                >
                                    SSO Configuration
                                </Button>
                            </Stack>
                        }
                    />
                    <SettingsV2Divider />
                    <SettingsDetailTile
                        title={'Configured SSO'}
                        subtitle={'Manage SSO configurations, limit 3.'}
                        children={
                            <SsoConfigurationList
                                idpList={props.idpList}
                                nameIDPolicyFormatOptions={props.nameIDPolicyFormatOptions}
                                onDelete={props.onDelete}
                                onUpdate={props.onUpdate}
                                onCreate={props.onCreate}
                                onClickCloseConfigurationDrawer={onClickCloseConfigurationDrawer}
                                onClickOpenConfigurationDrawer={onClickOpenConfigurationDrawer}
                                isConfigurationDrawerOpen={isConfigurationDrawerOpen}
                            />
                        }
                    />
                    {flags.roleMappings &&
                        props.idpList.length > 0 && [
                            <SettingsV2Divider key={'role-mappings-divider'} />,
                            <SettingsDetailTile
                                title={'Role Mappings'}
                                key={'role-mappings-tile'}
                                subtitle={'Map idP groups with one or more Vega roles to streamline authorization.'}
                                children={
                                    <Stack key={'role-mappings'} width={'100%'} height={'100%'} direction={'column'} spacing={2}>
                                        <RoleMappingCard
                                            onSaveRoleMapping={props.onSaveRoleMapping}
                                            roleMappings={props.roleMappings}
                                            roles={props.roles}
                                            isRoleMappingsLoading={props.isRoleMappingsLoading}
                                            onCreateRoleMapping={props.onCreateRoleMapping}
                                            onDeleteRoleMapping={props.onDeleteRoleMapping}
                                        />
                                    </Stack>
                                }
                            />,
                        ]}
                </Grid2>
            )}
        </>
    );
};

const useStyles = customMakeStyles<ISsoSettingsV2Props>()((theme, props) => ({}));

export { SsoSettingsV2 };
