import React, { useMemo } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Card, CardContent, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Savings, FormatListNumbered, CloudCircle, CalendarToday } from '@mui/icons-material';
import {
    IResource,
    IResourcesSummary,
    IResourceSummaryActiveResources,
    IResourceSummaryOnSchedule,
    IResourceSummaryTotalResources,
} from '@vegaplatformui/models';
import { FormatNumberUSDHundredth } from '../utilities/value-formatter-methods';

export interface IResourcePoolsSummaryCardProps {
    summaryActiveResources: IResourceSummaryActiveResources;
    summaryTotalResources: IResourceSummaryTotalResources;
    summaryScheduledResources: IResourceSummaryOnSchedule;
}

const ResourcesSummaryCard: React.FC<IResourcePoolsSummaryCardProps> = (props) => {
    const { classes, cx } = useStyles(props);

    const SummaryGroup = (icon: JSX.Element, title: string, subtitle: string, isLoading: boolean) => {
        return isLoading ? (
            <Grid spacing={1} container direction={'row'} alignItems={'center'}>
                <Grid item>
                    <Skeleton height={'3rem'} width={'3rem'} variant={'rounded'} />
                </Grid>
                <Grid item xs={6} container>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            <Skeleton />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle2'}>
                            <Skeleton />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <Grid spacing={1} container direction={'row'} alignItems={'center'}>
                <Grid item>
                    <Box className={cx(classes.IconBox)}>{icon}</Box>
                </Grid>
                <Grid item xs={12} md={6} container>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle2'}>{subtitle}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Card elevation={0}>
            <CardContent>
                <Stack direction={'row'} spacing={3} justifyContent={'space-around'} alignItems={'center'}>
                    {/*                    {SummaryGroup(<Savings />, FormatNumberUSDHundredth(props.summary.projected_monthly_savings), 'Projected Monthly Savings')}
                    {SummaryGroup(<FormatListNumbered />, FormatNumberUSDHundredth(props.summary.actual_savings_mtd), 'Actual Savings (MTD)')}*/}
                    {SummaryGroup(
                        <FormatListNumbered />,
                        props.summaryTotalResources.num_resources.toLocaleString('en-US'),
                        'Discovered Resources',
                        props.summaryTotalResources.isLoading
                    )}
                    {SummaryGroup(
                        <CloudCircle />,
                        props.summaryActiveResources.active_resources.toLocaleString('en-US'),
                        'Running',
                        props.summaryActiveResources.isLoading
                    )}
                    {SummaryGroup(
                        <CalendarToday />,
                        props.summaryScheduledResources.resources_on_a_schedule.toLocaleString('en-US'),
                        'Scheduled',
                        props.summaryScheduledResources.isLoading
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

const useStyles = customMakeStyles<IResourcePoolsSummaryCardProps>()((theme, props) => ({
    IconBox: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[500],
        color: theme.palette.grey[900],
        borderRadius: '8px',
        width: '3rem',
        height: '3rem',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
    },
}));

export { ResourcesSummaryCard };
