export const queryKeys = {
    spaces: { resources: 'resources' },
    filesApi: {
        availableFilesToDownload: 'availableFilesToDownload',
        downloadFile: 'downloadFile',
        uploadFileToDb: 'uploadFileToDb',
        uploadFileToS3: 'uploadFileToS3',
        getFileUploadUrl: 'getFileUploadUrl',
        deleteFiles: 'deleteFiles',
    },
    ApiKeyManagementApi: {},
    cloudProviderAccounts: { loadCloudAccounts: 'loadCloudAccounts', getAwsParentProviderAccounts: 'getAwsParentProviderAccounts' },
    reportsApi: {
        dashboards: 'dashboards',
        dashboardEmbeddedUrl: '$dashboard-embedded-url',
        sharedDashboardEmbeddedUrl: 'shared-dashboard-embedded-url',
    },
    vegaApi: { supportEmail: 'supportEmail' },
    usersApi: {
        loadSingleUser: 'loadSingleUser',
        loadUsers: 'loadUsers',
        loadKeycloakUsers: 'loadKeycloakUsers',
    },
    usersV2Api: { getUsersV2: 'getUsersV2', getUserV2: 'getUserV2', getCurrentUserV2: 'getCurrentUserV2' },
    businessGroupingsApi: {
        getBusinessGroupingTreeNodes: 'getBusinessGroupingTreeNodes',
        getBusinessGroupingTypes: 'getBusinessGroupingTypes',
        getMyBusinessGroupingTree: 'getMyBusinessGroupingTree',
        getValidBusinessGroupingParentsTree: 'getValidBusinessGroupingParentsTree',
        getOwnedBusinessGroupingsByUserId: 'getOwnedBusinessGroupingsByUserId',
        loadBusinessGroupings: 'loadBusinessGroupings',
        loadInitialBusinessGroupings: 'loadInitialBusinessGroupings',
        loadUserBusinessGroupings: 'loadUserBusinessGroupings',
        loadValidParents: 'loadValidParents',
        onSubmitCreateBusinessGrouping: 'onSubmitCreateBusinessGrouping',
        onSubmitEditBusinessGrouping: 'onSubmitEditBusinessGrouping',
    },
    resources: {
        resourcesTable: 'resourcesTable',
        refreshResource: 'refreshResource',
        parkableResources: 'parkableResources',
        resourceMetadata: 'resourceMetadata',
        resourceTypes: 'resourceTypes',
        resourceSummaryActiveCount: 'resourceActiveCount',
        resourceSummaryCount: 'resourceSummaryCount',
        resourceSummaryScheduledResourcesCount: 'resourceSummaryScheduledResourcesCount',
    },
    parking: {
        parkingTable: 'parkingTable',
    },
    rulesApi: {
        getAssignmentRules: 'getAssignmentRules',
        getApplyAssignmentRulesStatus: 'getApplyAssignmentRulesStatus',
    },
    organizationsApi: {
        getOrganization: 'getOrganization',
        getOrganizationChildren: 'getOrganizationChildren',
        getOrganizationLogo: 'getOrganizationLogo',
        getOrganizationThemeData: 'getOrganizationThemeData',
        createOrganizationTheme: 'createOrganizationTheme',
        updateOrganizationTheme: 'updateOrganizationTheme',
    },
    notificationTabs: {
        getDefaultCommunicationItems: 'getDefaultCommunicationItems',
        getNotifications: 'getNotifications',
        getConfigurations: 'getConfigurations',
        getNotificationTypes: 'getNotificationTypes',
        getNotificationChannels: 'getNotificationChannels',
    },
    federationManagement: {
        getRoleMappings: 'getRoleMappings',
        onCreateRoleMapping: 'createRoleMapping',
        onSaveRoleMapping: 'saveRoleMapping',
        onDeleteRoleMapping: 'deleteRoleMapping',
    },
    clientRegistration: {
        getExpirationOptions: 'getExpirationOptions',
        getRegisteredClients: 'getRegisteredClients',
    },
    permissionAndRoles: { getRealmRoles: 'getRealmRoles', getRealmRole: 'getRealmRole' },
    chatbot: { getTopics: 'getTopics' },
};
