import { Navigate, Outlet } from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { sessionTexts, useRouteUrls } from '@vegaplatformui/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ProtectedRouterType {
    redirectPath?: string;
    keycloak: Keycloak;
    layoutComponent: React.ElementType;
}

export const ProtectedRoute = ({ redirectPath = '/', keycloak, layoutComponent }: ProtectedRouterType) => {
    if (!keycloak.authenticated) {
        return <Navigate to={redirectPath} replace />;
    }

    const LayoutComponent = layoutComponent;

    return (
        <LayoutComponent>
            <Outlet />
        </LayoutComponent>
    );
};

export const RedirectOnNonExistingRoute = () => {
    const flags = useFlags();
    const { routeUrls } = useRouteUrls({});

    const redirectPath = routeUrls.navigator;
    localStorage?.setItem(sessionTexts.route, redirectPath.path);
    return <Navigate to={redirectPath.path} replace />;
};
