import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Button, Grid2, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { Edit, People, Visibility } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingTreeViewColumnHeaderProps {
    isSmaller922PxWidth: boolean;
}

const BusinessGroupingTreeViewColumnHeader: React.FC<IBusinessGroupingTreeViewColumnHeaderProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { isSmaller922PxWidth } = props;
    const { vOperate } = useFlags();

    return (
        <Grid2 container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid2 size={{ xs: 2, sm: !isSmaller922PxWidth ? 4 : null }}>
                <Typography noWrap className={cx(classes.NameTypography)} fontWeight={500} variant='body2'>
                    Name
                </Typography>
            </Grid2>
            <Grid2 size={2}>
                <Typography noWrap className={cx(classes.TypeTypography)} fontWeight={500} variant='body2'>
                    Type
                </Typography>
            </Grid2>
            <Grid2 size={2}>
                <Typography noWrap className={cx(classes.OwnerTypography)} fontWeight={500} variant='body2'>
                    Owner
                </Typography>
            </Grid2>
            <Grid2 size={1.5}>
                <Typography noWrap className={cx(classes.UsersTypography)} fontWeight={500} variant='body2'>
                    Users
                </Typography>
            </Grid2>
            {vOperate && (
                <Grid2 size={1.5}>
                    <Typography noWrap className={cx(classes.SchedulesTypography)} fontWeight={500} variant='body2'>
                        Schedules
                    </Typography>
                </Grid2>
            )}
            <Grid2 size={'auto'}>
                <Typography noWrap fontWeight={500} variant='body2'>
                    Actions
                </Typography>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingTreeViewColumnHeaderProps>()((theme, props) => ({
    NameTypography: { marginLeft: '1.9rem' },
    TypeTypography: { marginLeft: '1.3rem' },
    OwnerTypography: { marginLeft: '1.2rem' },
    UsersTypography: { marginLeft: '1.2rem' },
    SchedulesTypography: { marginLeft: '1.2rem' },
}));

export { BusinessGroupingTreeViewColumnHeader };
