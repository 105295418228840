import { Card, CardContent, Divider, Stack, Tab, Tabs } from '@mui/material';
import { useKeycloak } from '@react-keycloak-fork/web';
import { UserSettingApi } from '@vegaplatformui/apis';
import {
    ILogoVariation,
    IOrganizationThemeColors,
    IOrgData,
    IOrgDataUpdate,
    IRouteUrlTab,
    ISSONameIDPolicyFormat,
    ITab,
    IUser,
    IUserSettingAddress,
    IUserSettingBaseRequest,
    IUserSettingMFAStatusEnum,
    IUserSettingPassword,
    IUserSettingProfile,
    IUserSettingRealmRole,
    IUserSettingRoleBaseRequest,
    IUserSettingRolePostRequest,
    IUserSettingSSO,
    IUserSettingSSOBaseRequest,
    IUserSettingSSOSaml,
    IUserSettingSSOSamlForm,
    NotificationTabsType,
    SettingTypes,
    SSOTypeEnum,
} from '@vegaplatformui/models';
import {
    AuthUserRoles,
    FrontendFileDownloadBlob,
    MFASettings,
    MfaSettingsV2,
    OrganizationId,
    OrganizationSettings,
    OrganizationSettingsV2,
    PasswordSettings,
    PasswordSettingsV2,
    PermissionSettingsV2,
    PermissionsSettings,
    ProfileDetailsCard,
    ProfilePhotoUrl,
    ProfileSettings,
    queryKeys,
    QuicksightReportLoadingSkeleton,
    RoleMappingCard,
    SettingsV2Card,
    ShowUpdatedUi,
    SnackbarErrorOutput,
    SnackBarOptions,
    SSOSettings,
    SSOSettingsListCard,
    SsoSettingsV2,
    useBusinessGroupingsApi,
    useErrorHandlingV2,
    useFederationManagementApi,
    useNotificationCommunicationSettingsApi,
    useOrganizationsApi,
    UsersSettings,
    UsersSettingsV2,
    useUsersApi,
} from '@vegaplatformui/sharedcomponents';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useRouteUrls } from '@vegaplatformui/utils';
import { CloudProviderAccountsController } from '../cloud-provider-accounts/cloud-provider-accounts-controller';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiKeyManagementController } from '../api-key-management/api-key-management-controller';
import { ClientRegistrationController } from '../client-registration/client-registration-controller';
import { NotificationSettingsController } from '../notifications/notification-settings-controller';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISettingsControllerProps {}

const defaultProfile = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    image: '',
    username: '',
};
const defaultAddress = {
    street_address: '',
    country: '',
    city: '',
    state: '',
    zip_code: '',
};
const nameIDPolicyFormatList = [
    { label: 'Persistent', value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:persistent' },
    { label: 'Transient', value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:transient' },
    { label: 'Email', value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress' },
    { label: 'Kerberos', value: 'urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos' },
    { label: 'X.509 Subject Name', value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName' },
    { label: 'Windows Domain Qualified Name', value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:WindowsDomainQualifiedName' },
    { label: 'Unspecified', value: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified' },
];
const notificationTabs: ITab[] = [
    { id: NotificationTabsType.Notifications, label: 'Notifications' },
    // { id: NotificationTabsType.ScheduleReports, label: 'Schedule Reports' },
    // { id: NotificationTabsType.CommunicationSettings, label: 'Communication Settings' },
    // { id: NotificationTabs.History, label: 'History' },
];
const SettingsController: React.FC<ISettingsControllerProps> = (props) => {
    const { keycloak } = useKeycloak();
    const { classes, cx } = useStyles();

    const navigate = useNavigate();
    const { tab, notificationtab } = useParams();

    // api calls
    const queryClient = useQueryClient();
    const organizationsApi = useOrganizationsApi({});

    //Routes hook
    const { routeUrls } = useRouteUrls({});

    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const [withErrorHandlingV2] = useErrorHandlingV2();
    const userSettingApi = useMemo(() => {
        const apiInstance = new UserSettingApi();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const authUsername = keycloak.tokenParsed?.preferred_username;
    const [availableTabs, setAvailableTabs] = useState<ITab[] | undefined>();
    const [profileSettingsItems, setProfileSettingsItems] = useState<IRouteUrlTab[]>([]);
    const [organizationSettingsItems, setOrganizationSettingsItems] = useState<IRouteUrlTab[]>([]);
    const defaultSettingsMenuWidth = 243;
    const [settingsMenuWidth, setSettingsMenuWidth] = useState(defaultSettingsMenuWidth);
    const [currentTab, setCurrentTab] = useState<string | undefined>();
    const [notificationTab, setNotificationTab] = useState<string>('notifications');
    const [authUserRoles, setAuthUserRoles] = useRecoilState(AuthUserRoles);
    const [userId, setUserId] = useState<string | undefined>();
    const [realmRoles, setRealmRoles] = useState<IUserSettingRealmRole[]>([]);
    const [idpList, setIdpList] = useState<IUserSettingSSOSaml[]>([]);
    const [profile, setProfile] = useState<IUserSettingProfile>({ ...defaultProfile, username: authUsername });
    const [, setProfilePhotoUrl] = useRecoilState(ProfilePhotoUrl);
    const [address, setAddress] = useState<IUserSettingAddress>(defaultAddress);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const [isRolesLoading, setIsRolesLoading] = useState(true);
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const [nameIDPolicyFormatOptions] = useState<ISSONameIDPolicyFormat[]>(nameIDPolicyFormatList);
    const [mfaStatus, setMFAStatus] = useState<IUserSettingMFAStatusEnum>(IUserSettingMFAStatusEnum.DISABLED);
    const [isIDPLoading, setIsIDPLoading] = useState<boolean>(false);
    const { businessGroupingTypes } = useBusinessGroupingsApi({});
    const [organizationOwner, setOrganizationOwner] = useState<IUser | undefined>();
    const organizationId = useRecoilValue(OrganizationId);
    const [isMemberUpdating, setIsMemberUpdating] = useState<boolean>(false);
    const notificationCommunicationSettingsApi = useNotificationCommunicationSettingsApi({});
    const { isRoleMappingsLoading, onCreateRoleMapping, onSaveRoleMapping, onDeleteRoleMapping, roleMappings } = useFederationManagementApi({});
    const { roleMappings: isRoleMappingsFlag, showSsoCreation } = useFlags();
    const showUpdatedUi = useRecoilValue(ShowUpdatedUi);

    const getPhoto = async () => {
        const reqBody: IUserSettingBaseRequest = {
            username: authUsername,
        };
        const res = await userSettingApi.getPhoto(reqBody);
        if (res.status !== 200) return;
        setProfile((prev) => ({ ...prev, image: res.data.details.key }));
        setProfilePhotoUrl(res.data.details.key);
    };
    const usersApi = useUsersApi({ setUserId, getPhoto, setAddress, setProfile, setIsProfileLoading });

    const onTabChange = (event: React.ChangeEvent<object>, newValue: string) => {
        setCurrentTab(newValue);
        navigate(`/${routeUrls.settings.route.path}/${newValue}`);
    };

    const updateOrgData = (data: IOrgData) => {
        if (!userId) return;
        const payload: IOrgDataUpdate = {
            updated_by: userId,
            updated_at: new Date().toISOString(),
        };
        if (data.organizationName) {
            payload.name = data.organizationName;
        }
        if (data.organizationOwner) {
            payload.organization_owner_id = data.organizationOwner;
        }
        organizationsApi.updateOrganizationData({ orgId: organizationId, payload });
    };

    const saveOrgLogo = (orgId: string, variation: ILogoVariation, orgLogo: any) => {
        const formData = new FormData();
        formData.append('file', orgLogo);
        organizationsApi.saveOrganizationLogo({ orgId, variation, file: formData });
    };

    const deleteOrgLogo = (orgId: string, variation: ILogoVariation) => {
        organizationsApi.deleteOrganizationLogo({ orgId, variation });
    };

    const createOrgTheme = (orgId: string, theme: IOrganizationThemeColors) => {
        if (!userId) return;
        organizationsApi.createOrganizationTheme({
            orgId: orgId,
            created_at: new Date().toISOString(),
            created_by: userId!,
            primary_color: theme.primary_color,
            secondary_color: theme.secondary_color,
        });
    };

    const updateOrgTheme = (orgId: string, theme: IOrganizationThemeColors) => {
        if (!userId) return;
        organizationsApi.updateOrganizationTheme({
            orgId: orgId,
            updated_at: new Date().toISOString(),
            updated_by: userId!,
            primary_color: theme.primary_color,
            secondary_color: theme.secondary_color,
        });
    };

    const uploadImg = (file: File) => {
        withErrorHandlingV2(
            async () => {
                const formData = new FormData();
                formData.append('file', file);
                const reqBody: IUserSettingBaseRequest = {
                    username: authUsername,
                };
                const res = await userSettingApi.uploadImg(reqBody, formData);
                if (res.status !== 200) return;
                setProfile((prev) => ({ ...prev, image: res.data.details.key }));
                setProfilePhotoUrl(res.data.details.key);
            },
            'Failed to upload the photo.',
            'Photo upload was successful.'
        );
    };

    const removeProfileImage = () => {
        withErrorHandlingV2(
            async () => {
                await userSettingApi.deleteImg(authUsername);
                setProfile((prev) => ({ ...prev, image: '' }));
                setProfilePhotoUrl('');
            },
            'Failed to delete photo',
            'Photo was successfully deleted'
        );
    };

    const updatePassword = (data: IUserSettingPassword) => {
        withErrorHandlingV2(
            async () => {
                const formData = new FormData();
                const reqBody: IUserSettingBaseRequest = {
                    username: authUsername,
                };
                formData.append('new_password', data.new_password);
                formData.append('password', data.current_password);
                const res = await userSettingApi.updatePassword(reqBody, formData);
                if (res.status !== 200) return;
            },
            'Failed to update the password.',
            'Password has been updated.'
        );
    };

    const getRealmRoles = () => {
        withErrorHandlingV2(async () => {
            setIsRolesLoading(true);
            const realm = keycloak.realm || '';
            const res = await userSettingApi.getRealmRoles(realm);
            if (res.status !== 200) {
                setIsRolesLoading(false);
                return;
            }
            const sorted = res.data.sort((a: IUserSettingRealmRole, b: IUserSettingRealmRole) => a.name.localeCompare(b.name));
            setRealmRoles(sorted);
            setIsRolesLoading(false);
        }, 'Failed to retrieve realm roles.');
    };
    const createAndAssignRoleToUsers = async (role_name: string, members: string[]) => {
        const reqBody: IUserSettingRolePostRequest = {
            role_name: role_name,
        };
        withErrorHandlingV2(
            async () => {
                const addResponse = await userSettingApi.createRole(reqBody, authUsername);
                if (addResponse.status !== 200) return;
            },
            'Failed to create user role.',
            'Role has been successfully created.'
        );

        withErrorHandlingV2(async () => {
            const assignResponse = await userSettingApi.assignRoleToUsers(role_name, members);
            if (assignResponse.status !== 200) return;
        }, 'Failed to assign new role.');

        getRealmRoles();
        queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
    };
    const updateRoleAndAssignToUsers = (role_name: string, new_role_name: string, previous_members: string[], members: string[]) => {
        withErrorHandlingV2(async () => {
            const updateRoleResponse = await userSettingApi.updateRoleName(role_name, new_role_name);
            if (updateRoleResponse.status !== 200) return;
        }, 'Role update failed.');

        withErrorHandlingV2(async () => {
            const unassignResponse = await userSettingApi.removeRoleFromUsers(role_name, previous_members);
            if (unassignResponse.status !== 200) return;
        }, 'Failed to unassign role from previous users.');
        withErrorHandlingV2(async () => {
            const assignResponse = await userSettingApi.assignRoleToUsers(new_role_name, members);
            if (assignResponse.status !== 200) return;
        }, 'Failed to assign role to users.');

        getRealmRoles();
        queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
    };
    const deleteRoles = (data: IUserSettingRealmRole[]) => {
        const requests = data.map((role) => {
            const reqBody: IUserSettingRoleBaseRequest = {
                username: '',
                role_name: role.name,
            };
            return userSettingApi.removeRolesFromUser(reqBody);
        });
        Promise.all(requests).then(() => {
            getRealmRoles();
        });
    };
    const getIDPList = () => {
        setIsIDPLoading(true);
        withErrorHandlingV2(async () => {
            const res = await userSettingApi.getIDPList();
            if (res.status !== 200) return setIsIDPLoading(false);
            const processedRes = res.data.map((x: any) => {
                return {
                    alias: x.alias,
                    display_name: x.display_name,
                    redirect_uri: x.redirect_uri,
                    provider_id: x.provider_id,
                    xml_metadata: x.xml_metadata,
                    config: x.config,
                };
            });
            setIdpList(processedRes);
            setIsIDPLoading(false);
        }, 'Failed to retrieve the idp list');
    };
    const createIDP = (data: IUserSettingSSO, type: SSOTypeEnum, byXml: boolean) => {
        withErrorHandlingV2(
            async () => {
                const reqBody: IUserSettingSSOBaseRequest = {
                    display_name: data.display_name,
                    config: data.config,
                };

                if (byXml) {
                    await userSettingApi.importConfigIDP(type, data.xml_metadata);
                } else {
                    if (type === SSOTypeEnum.OPENID) {
                        await userSettingApi.createOpenIdIDP(reqBody);
                    }
                }
                getIDPList();
            },
            'Failed to create new SSO configuration',
            'The new SSO configuration has created successfully'
        );
    };

    const createSamlIDP = (data: IUserSettingSSOSamlForm) => {
        setIsIDPLoading(true);
        userSettingApi
            .createSamlIDP({ ...data, config: { ...data.config, post_binding_response: data.config.post_binding_authn_request } })
            .then(() => {
                setSnackbarOptions({
                    message: `The new SSO configuration has created successfully.`,
                    snackBarProps: { open: true, autoHideDuration: 3000 },
                    alertProps: { severity: 'info' },
                });
                getIDPList();
            })
            .catch((error) => {
                setSnackbarOptions({
                    message: 'Failed to create new SSO configuration: ' + SnackbarErrorOutput(error),
                    snackBarProps: { open: true, autoHideDuration: 3000 },
                    alertProps: { severity: 'error' },
                });
                setIsIDPLoading(false);
            });
    };

    const updateSamlIDP = (data: IUserSettingSSOSamlForm) => {
        setIsIDPLoading(true);
        userSettingApi
            .updateSamlIDP(data.alias, { ...data, config: { ...data.config, post_binding_response: data.config.post_binding_authn_request } })
            .then(() => {
                setSnackbarOptions({
                    message: `The SSO has successfully updated.`,
                    snackBarProps: { open: true, autoHideDuration: 3000 },
                    alertProps: { severity: 'info' },
                });
                getIDPList();
            })
            .catch((error) => {
                setSnackbarOptions({
                    message: 'Failed to update the SSO Configuration: ' + SnackbarErrorOutput(error),
                    snackBarProps: { open: true, autoHideDuration: 3000 },
                    alertProps: { severity: 'error' },
                });
                setIsIDPLoading(false);
            });
    };

    const updateIDP = (data: IUserSettingSSO) => {
        withErrorHandlingV2(
            async () => {
                const reqBody: IUserSettingSSOBaseRequest = {
                    display_name: data.display_name,
                    config: data.config,
                };

                const res = await userSettingApi.updateOpenIdIDP(data.alias, reqBody);

                if (res.status !== 200) return;
                getIDPList();
            },
            'Failed to update the SSO Configuration',
            'The SSO has successfully updated'
        );
    };
    const deleteIDP = (alias: string) => {
        setIsIDPLoading(true);
        withErrorHandlingV2(
            async () => {
                const res = await userSettingApi.deleteIDP(alias);
                if (res.status !== 200) {
                    setIsIDPLoading(false);
                    return;
                }
                getIDPList();
            },
            'Failed to delete the SSO Configuration',
            'Deleted successfully'
        );
    };
    const generateXMLMetaData = useCallback((url: string, filename: string) => {
        withErrorHandlingV2(async () => {
            const res = await userSettingApi.generateSSOXML(url);
            const blob = new Blob([res.data], { type: 'text/xml' });
            FrontendFileDownloadBlob(blob, filename);
        }, 'Failed to download the file ' + filename);
    }, []);
    const getMFAStatus = () => {
        withErrorHandlingV2(async () => {
            const res = await userSettingApi.getMFAStatus();
            if (res.status !== 200) return;
            setMFAStatus(res.data.requirement);
        }, 'Failed to get the MFA status');
    };
    const updateMFAStatus = (status: string) => {
        withErrorHandlingV2(
            async () => {
                const res = await userSettingApi.updateMFAStatus(status);
                if (res.status !== 200) return;
                getMFAStatus();
            },
            'Failed to update the MFA status',
            'The MFA has updated successfully'
        );
    };

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getCurrentUserV2] });
        if (keycloak?.tokenParsed?.realm_access) {
            const mappedRoleArray = (keycloak.tokenParsed.mapped_roles && JSON.parse(keycloak.tokenParsed.mapped_roles)) ?? [];
            setAuthUserRoles([...new Set([...keycloak.tokenParsed.realm_access.roles, ...mappedRoleArray])]);
        } else {
            setAuthUserRoles([]);
        }
    }, [keycloak]);

    useEffect(() => {
        if (!organizationsApi.organizationData) return;
        const orgOwner = usersApi.usersV2.find((user) => user.id === organizationsApi.organizationData!.organization_owner_id);
        setOrganizationOwner(orgOwner);
    }, [usersApi.usersV2, organizationsApi.organizationData]);

    useEffect(() => {
        if (!authUserRoles.length) return;
        const allowedTabs = routeUrls.settings.tabs
            .filter((tab) => tab.enabled)
            .filter((tab) => tab.allowedRoles.some((role) => authUserRoles.includes(role)));
        setAvailableTabs(allowedTabs);
        setProfileSettingsItems(allowedTabs.filter((tab) => tab.settingsLocation === SettingTypes.Personal));
        setOrganizationSettingsItems(allowedTabs.filter((tab) => tab.settingsLocation === SettingTypes.Organizational));
    }, [authUserRoles, routeUrls]);

    useEffect(() => {
        if (!availableTabs) return;
        if (tab && availableTabs.some((t) => tab === t.id)) {
            setCurrentTab(tab);
        } else {
            setCurrentTab(availableTabs![0].id);
            navigate(`/${routeUrls.settings.route.path}/${availableTabs![0].id}`);
        }

        const notificationsId = routeUrls.settings.tabs.find((tab) => tab.id === 'notifications')?.id;
        if (tab && notificationsId && tab === notificationsId) {
            if (!notificationTabs) return;
            if (notificationtab && notificationTabs.some((t) => notificationtab === t.id)) {
                setNotificationTab(notificationtab);
            } else {
                setNotificationTab(notificationTabs![0].id);
                navigate(`/${routeUrls.settings.route.path}/${notificationsId}/${notificationTabs![0].id}`);
            }
        }
    }, [availableTabs, tab, navigate, notificationtab, notificationTabs]);

    // fetch data based on the current tab
    useEffect(() => {
        if (['profile'].some((item) => item === currentTab)) {
            queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getCurrentUserV2, userId] });
        } else if (['users', 'permissions'].some((item) => item === currentTab)) {
            queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
            getRealmRoles();
        } else if (currentTab === 'organization') {
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationThemeData, organizationId] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganization, organizationId] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.organizationsApi.getOrganizationLogo, organizationId] });
        } else if (currentTab === 'sso') {
            getIDPList();
            isRoleMappingsFlag && queryClient.invalidateQueries({ queryKey: [queryKeys.federationManagement.getRoleMappings] });
            isRoleMappingsFlag && getRealmRoles();
        } else if (currentTab === 'mfa') {
            getMFAStatus();
        }
    }, [currentTab]);

    return showUpdatedUi ? (
        <SettingsV2Card
            organizationSettingsItems={organizationSettingsItems}
            profileSettingsItems={profileSettingsItems}
            settingsMenuWidth={settingsMenuWidth}
            setSettingsMenuWidth={setSettingsMenuWidth}
            setCurrentTab={setCurrentTab}
            children={
                <>
                    {(currentTab === 'organization' && (
                        <OrganizationSettingsV2
                            isLoading={
                                organizationsApi.isOrganizationDataLoading ||
                                organizationsApi.isOrganizationLogoDataLoading ||
                                organizationsApi.isThemeDataLoading
                            }
                            isOrganizationLogoDataLoading={organizationsApi.isOrganizationLogoDataLoading}
                            orgName={organizationsApi.organizationData?.name ?? ''}
                            orgOwner={organizationOwner}
                            orgSlug={organizationsApi.organizationData?.realm ?? ''}
                            orgUpdateStatus={organizationsApi.orgUpdateStatus || ''}
                            themeUpdateStatus={organizationsApi.themeUpdateStatus || ''}
                            themeCreateStatus={organizationsApi.themeCreateStatus || ''}
                            allUsers={usersApi.usersV2}
                            onPhotoUpload={saveOrgLogo}
                            onPhotoRemove={deleteOrgLogo}
                            onOrgDataUpdate={updateOrgData}
                            updateOrganizationTheme={updateOrgTheme}
                            createOrganizationTheme={createOrgTheme}
                        />
                    )) ?? <QuicksightReportLoadingSkeleton />}
                    {currentTab === 'profile' && (
                        <ProfileDetailsCard
                            profile={profile}
                            onProfileUpdate={usersApi.updateUserDetailsV2}
                            isLoading={usersApi.isCurrentUserV2Loading || isProfileLoading}
                            address={address}
                            onAddressUpdate={usersApi.updateUserAddressV2}
                            onPhotoUpload={(data: File) => uploadImg(data)}
                            onPhotoRemove={removeProfileImage}
                        />
                    )}
                    {currentTab === 'password' && <PasswordSettingsV2 onUpdatePassword={updatePassword} />}
                    {currentTab === 'keymanagement' && <ApiKeyManagementController />}
                    {currentTab === 'clientregistration' && <ClientRegistrationController />}
                    {currentTab === 'users' && (
                        <UsersSettingsV2
                            businessGroupingTypes={businessGroupingTypes ?? []}
                            isLoading={
                                usersApi.isUsersV2Loading ||
                                usersApi.isCreateUserV2Loading ||
                                usersApi.isDeleteUserV2Pending ||
                                usersApi.isUpdateUserDetailsV2Loading
                            }
                            users={usersApi.usersV2}
                            roles={realmRoles}
                            onInviteUser={usersApi.createUserV2}
                            onUpdateUser={usersApi.updateUserDetailsV2}
                            onDeleteUsers={usersApi.deleteUserV2}
                            isCreatingUser={usersApi.isCreateUserV2Loading}
                        />
                    )}
                    {currentTab === 'permissions' && (
                        <PermissionSettingsV2
                            isLoading={[usersApi.isUsersV2Loading, isRolesLoading].some((item) => item === true)}
                            roles={realmRoles}
                            users={usersApi.usersV2}
                        />
                    )}
                    {currentTab === 'accounts' && <CloudProviderAccountsController />}
                    {currentTab === 'sso' && (
                        <SsoSettingsV2
                            onUpdate={updateSamlIDP}
                            onCreate={createSamlIDP}
                            onDelete={deleteIDP}
                            nameIDPolicyFormatOptions={nameIDPolicyFormatOptions}
                            idpList={idpList}
                            isLoading={isIDPLoading}
                            onSaveRoleMapping={onSaveRoleMapping}
                            roleMappings={roleMappings}
                            roles={realmRoles}
                            isRoleMappingsLoading={isRoleMappingsLoading}
                            onCreateRoleMapping={onCreateRoleMapping}
                            onDeleteRoleMapping={onDeleteRoleMapping}
                        />
                    )}
                    {currentTab === 'notifications' && (
                        <NotificationSettingsController
                            parentTab={currentTab}
                            notificationTab={notificationTab}
                            setNotificationTab={setNotificationTab}
                            notificationTabs={notificationTabs}
                        />
                    )}
                    {currentTab === 'mfa' && <MfaSettingsV2 status={mfaStatus} onSubmit={updateMFAStatus} />}
                </>
            }
        />
    ) : (
        <>
            <Card className={cx(classes.OldSettingCard)} elevation={0}>
                <CardContent>
                    <Stack direction='column'>
                        <div>
                            <Tabs aria-label={'settings tabs'} variant='scrollable' value={currentTab} onChange={onTabChange}>
                                {authUserRoles.length &&
                                    availableTabs &&
                                    currentTab &&
                                    availableTabs.map((currentTab) => (
                                        <Tab
                                            id={currentTab.id}
                                            aria-controls={`${currentTab.id}-panel`}
                                            key={currentTab.id}
                                            label={currentTab.label}
                                            aria-label={currentTab.label}
                                            value={currentTab.id}
                                        />
                                    ))}
                            </Tabs>
                        </div>
                        <div role={'tabpanel'} aria-describedby={currentTab} id={`${currentTab}-panel`} className={cx(classes.Tabpanel)}>
                            {(currentTab === 'organization' && (
                                <OrganizationSettings
                                    isLoading={
                                        organizationsApi.isOrganizationDataLoading ||
                                        organizationsApi.isOrganizationLogoDataLoading ||
                                        organizationsApi.isThemeDataLoading
                                    }
                                    orgName={organizationsApi.organizationData?.name ?? ''}
                                    orgOwner={organizationOwner}
                                    orgSlug={organizationsApi.organizationData?.realm ?? ''}
                                    orgUpdateStatus={organizationsApi.orgUpdateStatus || ''}
                                    themeUpdateStatus={organizationsApi.themeUpdateStatus || ''}
                                    themeCreateStatus={organizationsApi.themeCreateStatus || ''}
                                    allUsers={usersApi.usersV2}
                                    onPhotoUpload={saveOrgLogo}
                                    onPhotoRemove={deleteOrgLogo}
                                    onOrgDataUpdate={updateOrgData}
                                    updateOrganizationTheme={updateOrgTheme}
                                    createOrganizationTheme={createOrgTheme}
                                />
                            )) ?? <QuicksightReportLoadingSkeleton />}
                            {currentTab === 'profile' && (
                                <ProfileSettings
                                    isLoading={usersApi.isCurrentUserV2Loading || isProfileLoading}
                                    profile={profile}
                                    address={address}
                                    onProfileUpdate={usersApi.updateUserDetailsV2}
                                    onAddressUpdate={usersApi.updateUserAddressV2}
                                    onPhotoUpload={(data: File) => uploadImg(data)}
                                    onPhotoRemove={removeProfileImage}
                                />
                            )}
                            {currentTab === 'password' && <PasswordSettings onUpdatePassword={updatePassword} />}
                            {currentTab === 'keymanagement' && <ApiKeyManagementController />}
                            {currentTab === 'clientregistration' && <ClientRegistrationController />}
                            {currentTab === 'users' && (
                                <UsersSettings
                                    businessGroupingTypes={businessGroupingTypes ?? []}
                                    isLoading={
                                        usersApi.isUsersV2Loading ||
                                        usersApi.isCreateUserV2Loading ||
                                        usersApi.isDeleteUserV2Pending ||
                                        usersApi.isUpdateUserDetailsV2Loading
                                    }
                                    users={usersApi.usersV2}
                                    roles={realmRoles}
                                    onInviteUser={usersApi.createUserV2}
                                    onUpdateUser={usersApi.updateUserDetailsV2}
                                    onDeleteUsers={usersApi.deleteUserV2}
                                    isCreatingUser={usersApi.isCreateUserV2Loading}
                                />
                            )}
                            {currentTab === 'permissions' && (
                                <PermissionsSettings
                                    isLoading={[usersApi.isUsersV2Loading, isRolesLoading].some((item) => item === true)}
                                    roles={realmRoles}
                                    users={usersApi.usersV2}
                                    onAdd={(role_name: string, members: string[]) => createAndAssignRoleToUsers(role_name, members)}
                                    onUpdate={(role_name: string, new_role_name: string, previous_members: string[], members: string[]) =>
                                        updateRoleAndAssignToUsers(role_name, new_role_name, previous_members, members)
                                    }
                                    onDeleteRoles={deleteRoles}
                                />
                            )}
                            {currentTab === 'accounts' && <CloudProviderAccountsController />}
                            {currentTab === 'sso' && (
                                <>
                                    {isIDPLoading ? (
                                        <QuicksightReportLoadingSkeleton />
                                    ) : (
                                        [
                                            (idpList.length < 3 || showSsoCreation) && [
                                                <SSOSettings
                                                    key={'sso-creation'}
                                                    nameIDPolicyFormatOptions={nameIDPolicyFormatOptions}
                                                    onCreate={createIDP}
                                                    onCreateSaml={createSamlIDP}
                                                />,
                                                <CardContent key={'sso-divider'}>
                                                    <Divider />
                                                </CardContent>,
                                            ],
                                            <SSOSettingsListCard
                                                key={'sso-settings-list'}
                                                nameIDPolicyFormatOptions={nameIDPolicyFormatOptions}
                                                idpList={idpList}
                                                onUpdate={updateSamlIDP}
                                                onDelete={deleteIDP}
                                                onGenerateXMLMetaData={generateXMLMetaData}
                                            />,
                                        ]
                                    )}
                                </>
                            )}
                            {currentTab === 'notifications' && (
                                <NotificationSettingsController
                                    parentTab={currentTab}
                                    notificationTab={notificationTab}
                                    setNotificationTab={setNotificationTab}
                                    notificationTabs={notificationTabs}
                                />
                            )}
                            {currentTab === 'mfa' && <MFASettings status={mfaStatus} onSubmit={updateMFAStatus} />}
                        </div>
                    </Stack>
                </CardContent>
            </Card>
            {currentTab === 'sso' && showSsoCreation && !isIDPLoading && idpList.length > 0 && (
                <Stack className={cx(classes.RoleMappingStack)} direction={'column'} spacing={2}>
                    <RoleMappingCard
                        onSaveRoleMapping={onSaveRoleMapping}
                        roleMappings={roleMappings}
                        roles={realmRoles}
                        isRoleMappingsLoading={isRoleMappingsLoading}
                        onCreateRoleMapping={onCreateRoleMapping}
                        onDeleteRoleMapping={onDeleteRoleMapping}
                    />
                </Stack>
            )}
        </>
    );
};

const useStyles = customMakeStyles()((theme) => ({
    Tabpanel: {
        paddingBlock: theme.spacing(2),
    },
    Skeleton: {
        backgroundColor: `#F5F5F5`,
    },
    RoleMappingStack: { marginTop: '1rem' },
    OldSettingCard: { minHeight: '100%' },
}));

export { SettingsController };
