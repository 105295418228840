import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormField } from '../../../forms';
import { Button, FormHelperText, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { Control, Controller, DeepRequired, FieldErrorsImpl, GlobalError, UseFormSetValue } from 'react-hook-form';
import { Cancel, Description, FilePresent, UploadRounded } from '@mui/icons-material';
import { IUserSettingSSOSaml, IUserSettingSSOSamlForm } from '@vegaplatformui/models';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../../../recoil/atom';
import { BlackOnWhiteButton } from '../../../utilities/black-on-white-button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoSamlFileUploadEditProps {
    ssoItem: IUserSettingSSOSaml;
    errors: Partial<FieldErrorsImpl<DeepRequired<IUserSettingSSOSamlForm>>> & { root?: Record<string, GlobalError> & GlobalError };
    setValue: UseFormSetValue<IUserSettingSSOSamlForm>;
    control: Control<IUserSettingSSOSamlForm, any>;
}

const SsoSamlFileUploadEdit: React.FC<ISsoSamlFileUploadEditProps> = (props) => {
    const { ssoItem, errors, setValue, control } = props;
    const { classes, cx } = useStyles(props);
    const [certificate, setCertificate] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileReader = new FileReader();
    const certFileInput = React.useRef<HTMLInputElement | null>(null);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const readFileData = (file: File) => {
        setCertificate([file]);
        fileReader.onload = function (event) {
            const text = event.target?.result;
            if (text) {
                const strippedCert = text.toString().replace(new RegExp('-+.[A-Z ]+-+', 'gm'), '');
                setValue('config.signing_certificate', strippedCert, { shouldDirty: true, shouldValidate: true });
            }
        };
        fileReader.readAsText(file);
    };

    return (
        <>
            <FormField label={'Identity provider signature certificate'} htmlFor={'config.signing_certificate'}>
                <Stack spacing={0.5}>
                    <FormHelperText className={cx(classes.FormHelperConfigText)}>
                        The optional PEM encoded public key certificate of the Identity Provider
                    </FormHelperText>
                    <Controller
                        name={'config.signing_certificate'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                {props.ssoItem.config.signing_certificate === '' || props.ssoItem.config.signing_certificate === undefined ? (
                                    <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={0.5}>
                                        <BlackOnWhiteButton
                                            disableElevation
                                            variant='contained'
                                            startIcon={<UploadRounded />}
                                            color='primary'
                                            onClick={() => certFileInput.current?.click()}
                                        >
                                            Upload Signing Certificate
                                        </BlackOnWhiteButton>
                                    </Stack>
                                ) : control._formValues.config.signing_certificate === '' ||
                                  control._formValues.config.signing_certificate === undefined ? (
                                    <Stack direction='column' justifyContent='flex-start' alignItems='flex-start' spacing={0.5}>
                                        <BlackOnWhiteButton
                                            disableElevation
                                            variant='contained'
                                            color='primary'
                                            startIcon={<UploadRounded />}
                                            onClick={() => certFileInput.current?.click()}
                                        >
                                            Upload Signing Certificate
                                        </BlackOnWhiteButton>
                                        <Typography variant='caption' className={cx(classes.FontWeightMedium, classes.Caption)}>
                                            OR
                                        </Typography>
                                        <Button
                                            variant={'text'}
                                            size={'small'}
                                            onClick={() => {
                                                setCertificate([]);
                                                setValue('config.signing_certificate', ssoItem.config.signing_certificate, {
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                });
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Stack>
                                ) : certificate.length > 0 ? (
                                    certificate.map((file) => (
                                        <Stack
                                            id={'config.signing_certificate'}
                                            key={file.name}
                                            direction={'row'}
                                            alignItems={'center'}
                                            justifyContent={'flex-start'}
                                        >
                                            <ListItem key={file.name}>
                                                <ListItemIcon>
                                                    <FilePresent />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {file.name} - {file.size} bytes
                                                </ListItemText>
                                                <IconButton
                                                    onClick={() => {
                                                        setCertificate([]);
                                                        setValue('config.signing_certificate', '', {
                                                            shouldDirty: true,
                                                            shouldValidate: true,
                                                        });
                                                    }}
                                                >
                                                    <Cancel />
                                                </IconButton>
                                            </ListItem>
                                        </Stack>
                                    ))
                                ) : (
                                    <Stack id={'config.signing_certificate'} direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Description />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Stack direction={'row'} spacing={5} justifyContent={'flex-start'} alignItems={'center'}>
                                                    <Typography variant={'inherit'}>Attached PEM</Typography>
                                                    <BlackOnWhiteButton
                                                        disableElevation
                                                        variant={'outlined'}
                                                        onClick={() => {
                                                            setCertificate([]);
                                                            setValue('config.signing_certificate', '', {
                                                                shouldDirty: true,
                                                                shouldValidate: true,
                                                            });
                                                        }}
                                                    >
                                                        Change
                                                    </BlackOnWhiteButton>
                                                </Stack>
                                            </ListItemText>
                                        </ListItem>
                                    </Stack>
                                )}
                                <FormHelperText error={!!errors.config?.signing_certificate} color={'red'}>
                                    {errors.config?.signing_certificate?.message as string}
                                </FormHelperText>
                            </>
                        )}
                    />
                </Stack>
            </FormField>
            <input
                ref={certFileInput}
                type='file'
                style={{ display: 'none' }}
                accept={'.pem'}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    if (evt.target.files?.length) {
                        const file = evt.target.files[0];
                        const ext = file.type.split('/')[1];
                        if (ext) {
                            switch (ext) {
                                case 'x-x509-ca-cert':
                                case 'x-pem-file':
                                    if (file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase() === 'pem') {
                                        readFileData(file);
                                    } else {
                                        setSnackbarOptions({
                                            snackBarProps: { open: true, autoHideDuration: 6000 },
                                            alertProps: { severity: 'warning' },
                                            message: `Please use a file with the extension, .pem`,
                                        });
                                    }
                                    evt.currentTarget.value = '';
                                    break;
                                default:
                                    setSnackbarOptions({
                                        snackBarProps: { open: true, autoHideDuration: 6000 },
                                        alertProps: { severity: 'error' },
                                        message: `This file type is not allowed please use a .pem`,
                                    });
                                    evt.currentTarget.value = '';
                                    break;
                            }
                        } else {
                            if (file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase() === 'pem') {
                                readFileData(file);
                            } else {
                                setSnackbarOptions({
                                    snackBarProps: { open: true, autoHideDuration: 6000 },
                                    alertProps: { severity: 'warning' },
                                    message: `Please use a file with the extension, .pem`,
                                });
                            }
                            evt.currentTarget.value = '';
                        }
                    }
                }}
            />
        </>
    );
};

const useStyles = customMakeStyles<ISsoSamlFileUploadEditProps>()((theme, props) => ({
    RemoveFileButton: {
        justifyContent: 'flex-end',
    },
    FormHelperConfigText: { marginTop: '-1rem' },
    ErrorFormHelper: {
        marginTop: '-1rem',
    },
    FontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    Caption: {
        color: theme.palette.grey[300],
    },
}));

export { SsoSamlFileUploadEdit };
