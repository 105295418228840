import { useKeycloak } from '@react-keycloak-fork/web';
import { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { ContainerApi } from '@vegaplatformui/apis';
import { IResource } from '@vegaplatformui/models';

export interface IUseResourceMetadataApiHook {
    resourceMetadata: IResource | undefined;
    setResourceId: React.Dispatch<React.SetStateAction<string | undefined>>;
    isResourceMetadataLoading: boolean;
}
export interface IUseResourceMetadataApiProps {}

export function useResourceMetadataApi(props: IUseResourceMetadataApiProps): IUseResourceMetadataApiHook {
    const { keycloak } = useKeycloak();
    const queryClient = useQueryClient();
    const [resourceId, setResourceId] = useState<string | undefined>();

    const containerApi = useMemo(() => {
        const containerApi = new ContainerApi();
        containerApi.keycloak = keycloak;
        return containerApi;
    }, [keycloak]);

    const { data: resourceMetadata, isLoading: isResourceMetadataLoading } = useQuery({
        queryKey: [queryKeys.resources.resourceMetadata, resourceId],
        queryFn: async ({ signal }) => {
            if (resourceId) {
                const response = await containerApi.getResourceById(resourceId, signal, true);

                return response.data;
            }
        },
        meta: {
            errorMessage: 'Unable to get resource metadata',
        },
        enabled: !!resourceId,
    });

    return {
        resourceMetadata,
        setResourceId,
        isResourceMetadataLoading,
    };
}
