import { IResource, RecommendationActionType } from '@vegaplatformui/models';
import { alpha, styled } from '@mui/material/styles';
import { FormControlLabel, Switch } from '@mui/material';
import { green, pink } from '@mui/material/colors';
import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useRecommendationsApi } from '../api-hooks/use-recommendations-api';

interface IParkingToggleProps {
    resource: IResource;
    isLoading: boolean;
}

const ParkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: pink[600],
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: pink[600],
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: green[600],
        '&:hover': {
            backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: green[600],
    },
}));

const ParkingToggle: React.FC<IParkingToggleProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isRunning, setIsRunning] = React.useState<boolean>(props.resource?.is_running || false);
    const [isLoading, setIsLoading] = React.useState<boolean>(props.isLoading);
    const { takeImmediateAction } = useRecommendationsApi({ setIsRunning: setIsRunning, setIsLoading: setIsLoading });

    React.useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    const onClickParkingToggle = () => {
        setIsLoading(true);
        if (!isRunning) {
            takeImmediateAction({ action: RecommendationActionType.unpark, resourceId: props.resource.id });
        } else {
            takeImmediateAction({ action: RecommendationActionType.park, resourceId: props.resource.id });
        }
    };

    return props.resource?.is_parking_capable ? (
        <FormControlLabel
            control={<ParkSwitch onClick={onClickParkingToggle} checked={isRunning} />}
            label={!isRunning ? 'Stopped' : 'Running'}
            labelPlacement='end'
            disabled={isLoading}
        />
    ) : (
        <></>
    );
};

const useStyles = customMakeStyles<IParkingToggleProps>()((theme, props) => ({
    label: {
        width: '200px',
        color: theme.palette.secondary.main,
    },
    provider: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
    },
    parkingScheduleButton: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[900],
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
}));

export { ParkingToggle };
