import React, { ReactNode } from 'react';
import { Route } from 'react-router';

export interface IVegaRoute {
    isEnabled: boolean;
    path?: string;
}

export interface IVegaGetRoute {
    route: IVegaRoute;
    key?: string;
    element?: ReactNode;
    index?: boolean;
    children?: IVegaGetRoute[];
}

export const getVegaRoute = (props: IVegaGetRoute) => {
    return props.route.isEnabled ? (
        props.children ? (
            <Route key={props.key} path={props.route.path} element={props.element}>
                {props.children.map((child, index) =>
                    getVegaRoute({
                        route: { isEnabled: child.route.isEnabled, path: child.route.path },
                        key: child.key,
                        element: child.element,
                        index: child.index,
                        children: child.children,
                    })
                )}
            </Route>
        ) : (
            <Route key={props.key} index={props.index} path={props.route.path} element={props.element}></Route>
        )
    ) : (
        <React.Fragment key={props.key}></React.Fragment>
    );
};
