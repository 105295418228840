import { atom, PrimitiveAtom } from 'jotai';
import { atomWithStorage, atomWithDefault } from 'jotai/utils';
import React from 'react';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { IDiscoveryDetails, IOrganizationLogoState, SupportEmailRecipient, IOrganizationThemeColorState, ITimezone } from '@vegaplatformui/models';
import { GridColumnVisibilityModel, GridDensity, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GridFilterModel } from '@mui/x-data-grid';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { localStorageTexts, sessionTexts } from '@vegaplatformui/utils';
import { DashboardExperience } from 'amazon-quicksight-embedding-sdk';
import { PaletteMode } from '@mui/material';
import { atomWithQuery } from 'jotai-tanstack-query'
import { queryKeys } from '../api-hooks/query-keys';
export const themeState = atom<PaletteMode>('light');

export const ShowUpdatedUi = atom<boolean>(true);

export const IsDashboardView = atom<boolean>(false);
export const IsDashboardShareReady = atom<boolean>(false);

export const IsThemeFirstLoad = atom<boolean>(true);

export const CustomColorsState = atomWithStorage<IOrganizationThemeColorState>('theme_colors', {
    isCustomColors: false,
    primary_color: '',
    secondary_color: '',
});

export const IsLogoFirstLoad = atom<boolean>(true);

export const CustomPrimaryLogoState = atom<IOrganizationLogoState>({
    isCustom: false,
    url: '',
});

export const CustomSecondaryLogoState = atom<IOrganizationLogoState>({
    isCustom: false,
    url: '',
});

export const CustomFaviconState = atom<IOrganizationLogoState>({
    isCustom: false,
    url: '',
});

export const CommonPageHeader = atom<React.ReactNode>(undefined);

export const OrganizationId = atom<string>('');

export const AuthUserRoles = atom<string[]>([]);

export const OrganizationLoading = atom<boolean>(false);
export const UserId = atom<string | undefined>(undefined);

export const ViewPortHeightAndWidth = atom<{ height: number; width: number }>({
    height: 0,
    width: 0,
});

export const QuicksightReportLoading = atom<boolean>(false);

export const IsEditingExemption = atom<boolean>(false);

export const QuicksightConsoleLoading = atom<boolean>(false);

export const OrgKeycloakRealm = atom<string>('');

export const ShowSupportForm = atom<{ showSupportForm: boolean; contactType: SupportEmailRecipient }>({
    showSupportForm: false,
    contactType: SupportEmailRecipient.Support,
});

export const ApplicationError = atom<Error | undefined>(undefined);

export const SnackBarOptions = atom<CustomSnackBarOptions>({
    snackBarProps: {},
    alertProps: {},
    message: '',
});

export const ApiKeyState = atom<string | null>(null);

export const ProfilePhotoUrl = atom<string>('');

export const DiscoveryDetails = atom<IDiscoveryDetails>({
    in_cooldown: false,
    is_discovery: false,
    request_id: '',
    client_id: '',
    datetime_in_30min: 0,
    shouldConnect: false,
});

export const areParkingScheduleTabsDirty = atom<boolean>(false);

export const vegaTableControls: PrimitiveAtom<IVegaTableControls[]> = atomWithDefault((get) => {
    const key = localStorage.getItem(`${localStorageTexts.tableControls}_${sessionStorage.getItem(sessionTexts.keycloakUserId)}`);
    return JSON.parse(key !== null ? key : '[]');
});

export const addVegaTableControl = atom(null, (get, set, newControl: IVegaTableControls) => {
    const newControls = [...get(vegaTableControls), newControl];
    set(vegaTableControls, newControls);
});

export const removeVegaTableControl = atom(null, (get, set, controlKeyToRemove: string) => {
    const newControls = get(vegaTableControls).filter((control) => control.key !== controlKeyToRemove);
    set(vegaTableControls, newControls);
});

export interface IVegaTableControls {
    key: string;
    value: IVegaTableControl;
}

export interface IVegaTableControl {
    totalRows?: number;
    paginationModel: GridPaginationModel;
    filterModel: GridFilterModel;
    sortModel: GridSortModel;
    density: GridDensity | undefined;
    columnModel: GridColumnVisibilityModel | undefined;
}

export const defaultVegaTableControl: IVegaTableControl = {
    paginationModel: { page: 0, pageSize: 15 },
    filterModel: { items: [], quickFilterValues: [] },
    columnModel: {},
    density: undefined,
    sortModel: [],
    totalRows: 0,
};

export const ParkingScheduleBusinessGroupId = atom<string>('');

export const ParkingScheduleTableBusinessGroupId = atom<string>('');

export const ParkingScheduleResourceId = atom<string>('');

export const ResourceIdToRefresh = atom<string | undefined>(undefined);

export const NotificationInboxAnchorElAtom = atom<HTMLElement | null>(null);

export const MenuItems = atom<GeminiMenuItem[]>([]);

export const menuItemState = atom<GeminiMenuItem | undefined>(undefined);

export const ExpandedBusinessGroupTreeNodes = atom<string[]>([]);

// Local storage atom for timezones
const localStorageTimezonesAtom = atom<ITimezone[]>(() => {
    const stored = localStorage.getItem("timezones");
    return stored ? JSON.parse(stored) : [];
});

// Reference the existing query by its queryKey only, with proper typing
const timezonesQueryAtom = atomWithQuery<ITimezone[], Error>(() => ({
    queryKey: [queryKeys.timezonesApi.getTimezones],
    // No queryFn needed - it will use the one defined in useTimezonesApi
}));

// Derived atom that extracts just the timezone data array
export const timezonesAtom = atom<ITimezone[]>(
    (get) => get(timezonesQueryAtom)?.data ?? get(localStorageTimezonesAtom)
);