import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MuiColorInput } from 'mui-color-input';
import { IOrganizationThemeColors, IOrganizationThemeForm } from '@vegaplatformui/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { CustomColorsState, OrganizationId } from '../../../recoil/atom';
import { FormField } from '../../../forms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationThemeInputProps {
    themeCreateStatus: string;
    themeUpdateStatus: string;
    updateOrganizationTheme: (orgId: string, theme: IOrganizationThemeColors) => void;
    createOrganizationTheme: (orgId: string, theme: IOrganizationThemeColors) => void;
}

const validationSchema: yup.ObjectSchema<IOrganizationThemeForm> = yup.object().shape({
    primary_color: yup.string().required('Primary Color is required.'),
    secondary_color: yup.string().required('Secondary Color is required.'),
});

const OrganizationThemeInput: React.FC<IOrganizationThemeInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { themeCreateStatus, themeUpdateStatus, updateOrganizationTheme, createOrganizationTheme } = props;
    const theme = useTheme();
    const organizationId = useRecoilValue(OrganizationId);
    const customColors = useRecoilValue(CustomColorsState);
    const defaultPrimaryColor = '#7F56D9';
    const defaultSecondaryColor = '#101828';

    const {
        handleSubmit: themeHandleSubmit,
        control: themeControl,
        reset: themeReset,
        setValue: themeSetValue,
        formState: { isDirty: themeIsDirty, isValid: themeIsValid, defaultValues: themeDefaultValues },
    } = useForm<IOrganizationThemeForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            primary_color: customColors.primary_color,
            secondary_color: customColors.secondary_color,
        },
    });

    useEffect(() => {
        themeReset({
            primary_color: customColors.primary_color,
            secondary_color: customColors.secondary_color,
        });
    }, [customColors]);

    const onSubmitThemeForm: SubmitHandler<IOrganizationThemeForm> = (data) => {
        return customColors.isCustomColors ? updateOrganizationTheme(organizationId, data) : createOrganizationTheme(organizationId, data);
    };

    return (
        <Grid
            onSubmit={themeHandleSubmit((data) => {
                onSubmitThemeForm(data);
            })}
            id={'organization-theme-form'}
            component={'form'}
            container
            spacing={2}
        >
            <Grid item xs={12}>
                <Stack spacing={0.5}>
                    <Typography variant='h3'>Theme</Typography>
                    <Typography variant='body2' color={theme.palette.grey[600]}>
                        Customize your own palette in the platform.
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormField label={'Primary Color'} htmlFor='orgThemePrimaryColor'>
                    <Controller
                        name={'primary_color'}
                        control={themeControl}
                        render={({ field: { onChange, value } }) => (
                            <Stack direction={'column'} alignItems={'flex-start'}>
                                <MuiColorInput
                                    id={'orgThemePrimaryColor'}
                                    onChange={onChange}
                                    value={value}
                                    format={'hex'}
                                    isAlphaHidden={true}
                                    fallbackValue={defaultPrimaryColor}
                                    className={cx(classes.colorPickerInput)}
                                />
                                <Button
                                    disabled={value === defaultPrimaryColor}
                                    onClick={() => themeSetValue('primary_color', defaultPrimaryColor, { shouldDirty: true, shouldValidate: true })}
                                    variant={'text'}
                                >
                                    Reset
                                </Button>
                            </Stack>
                        )}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormField label={'Sidebar Color'} htmlFor='orgThemeSecondaryColor'>
                    <Controller
                        name={'secondary_color'}
                        control={themeControl}
                        render={({ field: { onChange, value } }) => (
                            <Stack direction={'column'} alignItems={'flex-start'}>
                                <MuiColorInput
                                    id={'orgThemeSecondaryColor'}
                                    value={value}
                                    onChange={onChange}
                                    format={'hex'}
                                    isAlphaHidden={true}
                                    fallbackValue={defaultSecondaryColor}
                                    className={cx(classes.colorPickerInput)}
                                />
                                <Button
                                    disabled={value === defaultSecondaryColor}
                                    onClick={() =>
                                        themeSetValue('secondary_color', defaultSecondaryColor, { shouldDirty: true, shouldValidate: true })
                                    }
                                    variant={'text'}
                                >
                                    Reset
                                </Button>
                            </Stack>
                        )}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12}>
                <Stack className={cx(classes.FormButtonStack)} direction='row' spacing={2} justifyContent={'flex-start'}>
                    <Button
                        form={'organization-theme-form'}
                        variant='contained'
                        type='submit'
                        disabled={!themeIsDirty || !themeIsValid || themeUpdateStatus === 'pending' || themeCreateStatus === 'pending'}
                    >
                        Save
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IOrganizationThemeInputProps>()((theme, props) => ({
    colorPickerInput: {
        width: '100%',
        input: {
            padding: '10px',
        },
    },
    FormButtonStack: { marginRight: '1rem' },
}));

export { OrganizationThemeInput };
