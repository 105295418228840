import React, { ReactNode } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Alert, Button, Grid, MenuItem, Select, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import { Circle, Info, RestartAlt } from '@mui/icons-material';
import { ConfirmChangeIntervalDialog } from '../confirm-change-interval-dialog';
import { IParkingSchedule } from '@vegaplatformui/models';
import { ResetButton } from '../recurring-schedule';
import { FormField } from '../../forms';
import { WeeklyScheduler } from '../../weekly-scheduler/weekly-scheduler';
import { useRecoilState } from 'recoil';
import { areParkingScheduleTabsDirty } from '../../recoil/atom';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISchedulerTabProps {
    selectionStyle: string | undefined;
    setSelectionStyle: React.Dispatch<React.SetStateAction<string>>;
    intervals: number | undefined;
    setIntervals: React.Dispatch<React.SetStateAction<number | undefined>>;
    intervalToChangeTo: number | undefined;
    setIntervalToChangeTo: React.Dispatch<React.SetStateAction<number | undefined>>;
    showConfirmChangeIntervalDialog: boolean;
    setShowConfirmChangeIntervalDialog: React.Dispatch<React.SetStateAction<boolean>>;
    schedule: Date[];
    setSchedule: React.Dispatch<React.SetStateAction<Date[]>>;
    scheduleToEdit?: IParkingSchedule;
    onClickGetOptimizedSchedules: () => void;
    showNoTimesInvalidWarning: boolean;
    showAllTimesInvalidWarning: boolean;
}

const SchedulerTab: React.FC<ISchedulerTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [areTabsDirty, setAreTabsAreDirty] = useRecoilState(areParkingScheduleTabsDirty);
    const flags = useFlags();

    const onChangeIntervals = (event: SelectChangeEvent<number>, child: ReactNode) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        props.setIntervalToChangeTo(Number(event.target.value));
        props.setShowConfirmChangeIntervalDialog(true);
    };

    const onChangeSchedule = (scheduleToUpdate: Date[]) => {
        //Using a set on the date object does not work in the way it does for simpler arrays. Each new instance of the same date is still a different reference.
        //Bringing it to a number getting the unique timestamps then converting them back to dates
        const uniqueTimestamps = [...new Set(scheduleToUpdate.map((schedule) => schedule.getTime()))];
        uniqueTimestamps[uniqueTimestamps.length - 1] !== props.schedule.map((schedule) => schedule.getTime())[props.schedule.length - 1] &&
            !areTabsDirty &&
            setAreTabsAreDirty(true);
        const filteredSchedules = uniqueTimestamps.map((timestamp) => new Date(timestamp));
        props.setSchedule(filteredSchedules);
    };

    return (
        <>
            <ConfirmChangeIntervalDialog
                show={props.showConfirmChangeIntervalDialog}
                onClose={() => props.setShowConfirmChangeIntervalDialog(false)}
                onConfirm={() => {
                    props.setIntervals(props.intervalToChangeTo);
                    props.setSchedule([]);
                    props.setShowConfirmChangeIntervalDialog(false);
                }}
            />
            <Grid spacing={1} container direction={'row'}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>{props.scheduleToEdit ? 'Edit Schedule' : 'Create Schedule'}</Typography>
                    <Typography variant={'subtitle1'}>Select the time blocks on the scheduler to define when resources will be running.</Typography>
                </Grid>
                {(props.showNoTimesInvalidWarning || props.showAllTimesInvalidWarning) && (
                    <Grid xs={12} item>
                        <Alert severity={'warning'} color={'warning'}>
                            <Typography variant={'caption'}>
                                Selecting either all or none of the time slots means Vega won't take any action. To ensure the schedule is valid and
                                allow Vega to take action, please include both running and stopped time slot selections.
                            </Typography>
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={6} className={cx(classes.SelectionStyleContainer)}>
                    <FormField
                        label={
                            <Stack direction={'row'}>
                                <Typography id={'selection_style_label'} className={cx(classes.SelectionStyleFormFieldTypography)}>
                                    Selection Style
                                </Typography>
                                <Tooltip
                                    title='When dragging, Linear selects the entire column while Square selects individual square slots'
                                    placement={'right'}
                                    arrow
                                >
                                    <Info
                                        tabIndex={0}
                                        aria-hidden={false}
                                        className={cx(commonStyles.classes.TooltipIcons, classes.TooltipIconMargin)}
                                    />
                                </Tooltip>
                            </Stack>
                        }
                        htmlFor='selection_style'
                    >
                        <Select
                            id={'selection_style_label'}
                            className={cx(classes.Dropdowns)}
                            size={'small'}
                            value={props.selectionStyle}
                            onChange={(e) => props.setSelectionStyle(e.target.value)}
                        >
                            <MenuItem value={'linear'}>Linear</MenuItem>
                            <MenuItem value={'square'}>Square</MenuItem>
                        </Select>
                    </FormField>
                </Grid>
                <Grid item xs={6}>
                    <FormField id={'intervals'} label='Time Interval' htmlFor='intervals'>
                        <Select
                            id={'intervals'}
                            className={cx(classes.Dropdowns)}
                            size={'small'}
                            value={props.intervals}
                            onChange={onChangeIntervals}
                        >
                            <MenuItem value={1}>60 Minutes</MenuItem>
                            <MenuItem value={2}>30 Minutes</MenuItem>
                            <MenuItem value={4}>15 Minutes</MenuItem>
                            <MenuItem value={12}>5 Minutes</MenuItem>
                        </Select>
                    </FormField>
                </Grid>
                <Grid sx={{ marginTop: '0.5rem' }} alignItems={'center'} justifyContent={'space-between'} item xs={12}>
                    <Stack spacing={2} direction='row' justifyContent='space-between'>
                        <Stack spacing={1} direction={'row'}>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Circle className={cx(classes.RunningCircle)} />
                                <Typography>Running</Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <Circle className={cx(classes.StoppedCircle)} />
                                <Typography>Stopped</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                            {flags.optimizedAi && props.intervals === 1 && (
                                <Stack direction={'row'}>
                                    <Button onClick={() => props.onClickGetOptimizedSchedules()} variant={'text'}>
                                        Apply Optimized Schedule
                                    </Button>
                                    <Tooltip
                                        title={`Use the power of Vega AI to generate an optimized schedule based on your resource selection. Currently only applicable on the 60 minute interval.`}
                                        placement={'top'}
                                        arrow
                                    >
                                        <Info
                                            aria-label={
                                                'Use the power of AI to generate an optimized schedule based on your resource selection. Currently only applicable on the 60 minute interval.'
                                            }
                                            tabIndex={0}
                                            aria-hidden={'false'}
                                            className={commonStyles.classes.TooltipIcons}
                                        />
                                    </Tooltip>
                                </Stack>
                            )}
                            <ResetButton
                                onClick={() => {
                                    !areTabsDirty && setAreTabsAreDirty(true);
                                    props.setSchedule([]);
                                }}
                                startIcon={<RestartAlt />}
                                variant={'contained'}
                            >
                                Reset Selection
                            </ResetButton>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <WeeklyScheduler
                        selectedDates={props.schedule}
                        onChangeSelectedDates={onChangeSchedule}
                        hourlyChunks={props.intervals}
                        selectionScheme={props.selectionStyle}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = customMakeStyles<ISchedulerTabProps>()((theme, props) => ({
    SelectionStyleFormFieldTypography: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey['700'],
    },
    TooltipIconMargin: {
        marginTop: '-.25rem',
    },
    Dropdowns: {
        minWidth: '100%',
    },
    RunningCircle: { fontSize: '1rem', fill: theme.palette.primary.main },
    StoppedCircle: {
        fontSize: '1rem',
        fill: '#F1EDFE',
    },
    SelectionStyleContainer: {},
}));

export { SchedulerTab };
