import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { QuicksightReportsController } from '../quicksight-reports-controller/quicksight-reports-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommittedUse1AzureProps {}

const CommittedUseDiscountsAzure: React.FC<ICommittedUse1AzureProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <QuicksightReportsController folderNames={['committeduse_azure']} />;
};

const useStyles = customMakeStyles<ICommittedUse1AzureProps>()((theme, props) => ({}));

export { CommittedUseDiscountsAzure };
