import {
    INotificationChannel,
    INotificationConfiguration,
    INotificationType,
    IVegaNotification,
    IVegaNotificationChannel,
} from '@vegaplatformui/models';

export function getMappedVegaNotificationData(
    notificationTypesData: INotificationType[],
    channelConfigurationsData: INotificationConfiguration[],
    notificationChannelsData: INotificationChannel[]
): IVegaNotification[] {
    return notificationTypesData.map((type) => {
        const notificationChannels: IVegaNotificationChannel[] = [];
        const typeConfigurations = channelConfigurationsData.filter((config) => config.notification_type_id === type.id);
        typeConfigurations.length > 0 &&
            typeConfigurations.forEach((config) => {
                notificationChannels.push({
                    channel_id: config.notification_channel_id,
                    id: config.id,
                    is_enabled: config.is_enabled,
                    channel_name: notificationChannelsData.find((channel) => channel.id === config.notification_channel_id)!.name,
                });
            });
        return {
            name: type.name,
            id: type.id,
            description:
                type.description !== undefined
                    ? type.description
                    : type.name === 'anomaly_detected'
                      ? `Notify users when there's an anomaly, which could indicate a misconfiguration or an unintended usage pattern. Any detected anomalies will be dispatched to your provided contact email as a daily digest at 9:00 AM PST.`
                      : type.name === 'parking_failure'
                        ? `Notify users of any parking failures. Each detected failure will be dispatched to your provided contact email as an on-demand notification.`
                        : undefined,
            is_enabled: notificationChannels.filter((channel) => channel.is_enabled).length > 0,
            channels: notificationChannels,
        };
    });
}
