import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IUser, IKeycloakUser, IPostUserV2Request, IPutUserV2HookRequest, IUserSettingAddress, IUserSettingProfile } from '@vegaplatformui/models';
import { useKeycloak } from '@react-keycloak-fork/web';
import React, { useState, useMemo } from 'react';
import { UsersApi, UsersApiV2 } from '@vegaplatformui/apis';
import { queryKeys } from './query-keys';
import { UserSettingApi } from '@vegaplatformui/apis';
import { SnackBarOptions, UserId } from '../jotai/atom';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { useAtomValue, useSetAtom } from 'jotai';

export interface IUseUsersApiHook {
    isUsersListLoading: boolean;
    isKeycloakUsersLoading: boolean;
    availableUsers: IUser[] | undefined;
    keycloakUsers: IKeycloakUser[] | undefined;
    refetchUsers: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters) | undefined) => Promise<QueryObserverResult<IUser[], unknown>>;
    setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
    usersV2: IUser[];
    isUsersV2Loading: boolean;
    userV2Data: IUser | undefined;
    isUserV2DataLoading: boolean;
    isCurrentUserV2Loading: boolean;
    createUserV2: (hookRequest: IPostUserV2Request) => void;
    isCreateUserV2Loading: boolean;
    updateUserDetailsV2: (hookRequest: IPutUserV2HookRequest) => void;
    updateUserAddressV2: (hookRequest: IPutUserV2HookRequest) => void;
    isUpdateUserDetailsV2Loading: boolean;
    deleteUserV2: (data: IUser[]) => void;
    isDeleteUserV2Pending: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseUsersApiProps {
    setAddress?: (value: React.SetStateAction<IUserSettingAddress>) => void;
    setProfile?: (value: React.SetStateAction<IUserSettingProfile>) => void;
    setUserId?: (value: React.SetStateAction<string | undefined>) => void;
    getPhoto?: () => Promise<void>;
    setIsProfileLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useUsersApi(props: IUseUsersApiProps): IUseUsersApiHook {
    const { keycloak } = useKeycloak();
    const { setAddress, setProfile, getPhoto, setUserId: setPropUserId } = props;
    const queryClient = useQueryClient();
    const currentUserId = useAtomValue(UserId);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);

    // const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const [userId, setUserId] = useState<string | undefined>(undefined);

    const usersApi = useMemo(() => {
        const usersApi = new UsersApi();
        usersApi.keycloak = keycloak;
        return usersApi;
    }, [keycloak]);

    const userSettingApi = useMemo(() => {
        const apiInstance = new UserSettingApi();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const usersV2Api = useMemo(() => {
        const usersV2Api = new UsersApiV2();
        usersV2Api.keycloak = keycloak;
        return usersV2Api;
    }, [keycloak]);

    const {
        data: availableUsers,
        isLoading: isUsersListLoading,
        isError: isLoadUsersError,
        error: loadUsersError,
        isSuccess: isLoadUsersSuccess,
        refetch: refetchUsers,
    } = useQuery({
        queryKey: [queryKeys.usersApi.loadUsers],

        queryFn: async () => {
            const response = await usersApi.loadUsers();

            const usersSorted = response.data.sort(function (a, b) {
                if (a.given_name.toLowerCase() < b.given_name.toLowerCase()) return -1;
                if (a.given_name.toLowerCase() > b.given_name.toLowerCase()) return 1;
                return 0;
            });

            return usersSorted;
        },
        enabled: false,
        meta: {
            errorMessage: 'Unable to get users',
        },
    });

    const {
        data: keycloakUsers,
        isLoading: isKeycloakUsersLoading,
        isError: isKeycloakUsersError,
        error: loadKeycloakUsersError,
        isSuccess: isLoadKeycloakUsersSuccess,
    } = useQuery({
        queryKey: [queryKeys.usersApi.loadKeycloakUsers],

        queryFn: async () => {
            const response = await getKeycloakUsers();

            const usersSorted = response.sort(function (a, b) {
                if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) return -1;
                if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) return 1;
                return 0;
            });

            return usersSorted;
        },
        //Turn Off Method for now
        enabled: false,
        meta: {
            errorMessage: 'Unable to get users',
        },
    });

    const getKeycloakUsers = async (): Promise<IKeycloakUser[]> => {
        const realm = keycloak.realm ?? '';
        const usersResponse = await userSettingApi.getUsers(realm);

        if (usersResponse.status !== 200) {
            return [];
        }
        const userData: IKeycloakUser[] = usersResponse.data.map((resItem: any) => {
            return {
                id: resItem.id,
                username: resItem.username,
                first_name: resItem.firstName || '',
                last_name: resItem.lastName || '',
                email: resItem.email,
                realm: realm,
                dateAdded: new Date(resItem.createdTimestamp).toLocaleDateString('en-CA'),
                realm_role: resItem.realmRoles,
                status: resItem.emailVerified,
                business_groupings: resItem.business_groupings.map((grouping: any) => {
                    return { ...grouping, name: grouping.display_name };
                }),
            };
        });
        return userData ?? [];
    };

    const { data: usersV2, isLoading: isUsersV2Loading } = useQuery({
        queryKey: [queryKeys.usersV2Api.getUsersV2],
        queryFn: async ({ signal }) => {
            const response = await usersV2Api.getUsersV2(signal);
            return response.data.sort(function (a, b) {
                if (a.given_name.toLowerCase() < b.given_name.toLowerCase()) return -1;
                if (a.given_name.toLowerCase() > b.given_name.toLowerCase()) return 1;
                return 0;
            });
        },
        meta: { errorMessage: 'Unable to get users' },
    });

    const { mutate: createUserV2, isPending: isCreateUserV2Loading } = useMutation({
        onError: (error: any, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `Failed to create the user: ${
                    error.response.data.detail && error.response.data.detail.includes('User exists')
                        ? `The user, ${variables.email}, already exists`
                        : SnackbarErrorOutput(error)
                }`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'The user has been successfully created.',
            });
            if (response.data && usersV2 && !usersV2.map((user) => user.id).includes(response.data.id)) {
                queryClient.setQueryData([queryKeys.usersV2Api.getUsersV2], [...usersV2, response.data]);
            } else {
                queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
            }
        },
        mutationFn: async (hookRequest: IPostUserV2Request) => {
            return await usersV2Api.postUserV2(hookRequest);
        },
    });

    const { mutate: updateUserDetailsV2, isPending: isUpdateUserDetailsV2Loading } = useMutation({
        onError: (error: any, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
            variables.user_id === currentUserId && queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getCurrentUserV2] });
            if (
                error.message.includes('503') &&
                'business_groupings' in variables.request &&
                variables.request.business_groupings !== undefined &&
                variables.request.business_groupings.length > 50
            ) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'info' },
                    message: `User update is taking longer then expected, check back later for updated data.`,
                });
            } else {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `Failed to update the user: ${SnackbarErrorOutput(error)}`,
                });
            }
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'The user has been successfully edited.',
            });
            if (usersV2 && response.data) {
                setUserId(response.data.id);
            } else {
                queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
            }

            if (variables.user_id === currentUserId && setProfile) {
                queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getCurrentUserV2] });
            }
        },
        mutationFn: async (hookRequest: IPutUserV2HookRequest) => {
            return await usersV2Api.putUserV2(hookRequest.request, hookRequest.user_id);
        },
    });

    const { mutate: updateUserAddressV2, isPending: isUpdateUserAddressV2Loading } = useMutation({
        onError: (error: any, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getCurrentUserV2] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `Failed to update the address: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Address has been updated.',
            });
            if (variables.user_id === currentUserId) {
                const data = response.data;
                queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getCurrentUserV2, currentUserId] });
            }
        },
        mutationFn: async (hookRequest: IPutUserV2HookRequest) => {
            return await usersV2Api.putUserV2(hookRequest.request, hookRequest.user_id);
        },
    });

    const { mutate: deleteUserV2, isPending: isDeleteUserV2Pending } = useMutation({
        mutationFn: async (data: IUser[]) => {
            return await deleteUsers(data);
        },
        onError: (error: any, variables, context) => {
            setSnackbarOptions({
                message: variables.length > 1 ? 'Some or all users have failed to delete.' : 'Failed to delete user. ' + SnackbarErrorOutput(error),
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'error' },
            });
            queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                message: 'The users have been successfully deleted.',
                snackBarProps: { open: true, autoHideDuration: 3000 },
                alertProps: { severity: 'success' },
            });
            if (usersV2 && variables) {
                queryClient.setQueryData(
                    [queryKeys.usersV2Api.getUsersV2],
                    usersV2.filter((user) => !variables.map((deletedUser) => deletedUser.id).includes(user.id))
                );
            } else {
                queryClient.invalidateQueries({ queryKey: [queryKeys.usersV2Api.getUsersV2] });
            }
        },
    });

    const deleteUsers = async (data: IUser[]) => {
        const requests = data.map((user) => {
            return usersV2Api.deleteUserV2(user.id);
        });
        await Promise.all(requests);
    };

    const { data: currentUserV2, isPending: isCurrentUserV2Loading } = useQuery({
        queryKey: [queryKeys.usersV2Api.getCurrentUserV2, currentUserId],
        queryFn: async ({ signal }) => {
            if (currentUserId) {
                const response = await usersV2Api.getUserV2(currentUserId, signal);
                setPropUserId && setPropUserId(response.data.id);
                setProfile &&
                    setProfile((prev) => ({
                        ...prev,
                        first_name: response.data.given_name,
                        last_name: response.data.family_name,
                        email: response.data.email,
                        phone: response.data.mobile_phone ? response.data.mobile_phone.toString() : '',
                    }));
                setAddress &&
                    setAddress((prev) => ({
                        ...prev,
                        street_address: response.data.street_address,
                        country: response.data.country,
                        city: response.data.city,
                        state: response.data.state,
                        zip_code: response.data.zip_code,
                    }));
                props.setIsProfileLoading && props.setIsProfileLoading(false);
                getPhoto && getPhoto();
                return response.data;
            }
        },
        enabled: !!currentUserId && window.location.pathname.includes('settings'),
        meta: {
            errorMessage: 'Unable to get current user details',
        },
    });

    const { data: userV2Data, isLoading: isUserV2DataLoading } = useQuery({
        queryKey: [queryKeys.usersV2Api.getUserV2, userId],
        queryFn: async ({ signal }) => {
            if (userId) {
                const response = await usersV2Api.getUserV2(userId, signal);
                if (usersV2) {
                    const updatedList = usersV2.map((user) => (user.id === response.data.id ? response.data : user));
                    queryClient.setQueryData([queryKeys.usersV2Api.getUsersV2], updatedList);
                }
                setUserId(undefined);
                return response.data;
            }
        },
        enabled: !!userId,
        meta: {
            errorMessage: 'Unable to get user',
        },
    });

    return {
        availableUsers,
        isUsersListLoading,
        keycloakUsers,
        isKeycloakUsersLoading,
        refetchUsers,
        setUserId,
        userV2Data,
        isUserV2DataLoading,
        isCurrentUserV2Loading,
        createUserV2,
        isCreateUserV2Loading,
        updateUserDetailsV2,
        updateUserAddressV2,
        isUpdateUserDetailsV2Loading,
        isUsersV2Loading,
        usersV2: usersV2 ?? [],
        deleteUserV2,
        isDeleteUserV2Pending,
    };
}

export default useUsersApi;
