import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Grid2, LinearProgress, Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { ResourceDetailTab } from './resource-detail-tab';
import { sessionTexts, useRouteUrls } from '@vegaplatformui/utils';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IParkingSchedule, IParkingScheduleSummary, IResource, IBusinessUnitTreeItem, ParkingScheduleType } from '@vegaplatformui/models';
import { ResourceDetailParkingSchedulesTab } from './resource-detail-parking-schedules-tab';
import { BulkParkingSchedulesActions } from '../../../parking/bulk-parking-schedules-actions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ITimezone } from '@vegaplatformui/models';
import { useTheme } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourceDetailCardProps {
    isLoading: boolean;
    resource: IResource;
    parkingSchedules: IParkingScheduleSummary[];
    selectedSchedules: IParkingScheduleSummary[];
    setSelectedSchedules: React.Dispatch<React.SetStateAction<IParkingScheduleSummary[]>>;
    setResourceId: (update: string | ((prev: string) => string)) => void;
    onClickDeleteSchedule: (schedule: any) => void;
    onClickDeleteSelectedSchedules: () => void;
    isChangeScheduleDialogOpen: boolean;
    setIsChangeScheduleDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onOpenScheduleChangeDialog?: (schedule: any) => void;
    onOpenRemoveAdvancedParkingDialog?: (schedule: any) => void;
    setScheduleToEdit: (schedule: IParkingScheduleSummary | undefined) => void;
    scheduleToEdit: IParkingSchedule | undefined;
    parkableResources: IResource[];
    isParkableResourcesLoading: boolean;
    isParkingDrawerOpen: boolean;
    onToggleParkingDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    onClickEditSchedule: (schedule: IParkingSchedule) => void;
    onClickCreateSchedule: (schedule: IParkingSchedule) => void;
    usersBusinessGroupings: IBusinessUnitTreeItem[];
    userHasGroupings: boolean;
    loadParkableResources?: (bg_id?: string) => void;
    isRemoveAdvancedParkingDialogOpen: boolean;
    setIsRemoveAdvancedParkingDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onRemoveAdvancedParking: (schedule: IParkingSchedule) => void;
    isParkingLoading: boolean;
    setResourcesSelectedForParking: (resources: IResource[]) => void;
    parkingScheduleTableIdentifier: string;
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    onClickOpenDeleteDialog: (schedule?: IParkingScheduleSummary) => void;
    onChangeScheduleStatuses: (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimezone
    ) => void;
    isScheduled: boolean;
    setIsConfirmDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmDeleteDialogOpen: boolean;
    schedulesToChangeStatusOn: IParkingScheduleSummary[];
    onOpenEnableDisableSchedulesDialog: (schedules: IParkingScheduleSummary[] | undefined, isScheduledChangeStatus: boolean) => void;
    isDeleteExemptionDialogOpen: boolean;
    setIsDeleteExemptionDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    scheduleWithExemption: IParkingScheduleSummary | undefined;
    setScheduleWithExemption: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    onClickOpenDeleteExemptionDialog: (schedule: IParkingScheduleSummary) => void;
    onClickDeleteExemption: (schedule: IParkingScheduleSummary) => void;
}

const ResourceDetailCard: React.FC<IResourceDetailCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { tab } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState(tab ?? 'details');
    const is700pxOrSmaller = useMediaQuery(theme.breakpoints.down(700));
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState<boolean>(false);
    const { vOperate } = useFlags();
    const [parkingScheduleType, setParkingScheduleType] = React.useState<ParkingScheduleType>(ParkingScheduleType.Basic);
    const { routeUrls } = useRouteUrls({});

    const onTabChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
        setCurrentTab(newValue);
        navigate(`/${routeUrls.resources.path}/${props.resource?.id}/${newValue}`, { replace: true });
    };

    return (
        <Card className={cx(classes.Card)} elevation={0}>
            {props.isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    <CardContent className={classes.CardContent}>
                        <Grid2 className={cx(classes.ActionGrid)} container direction='row' alignItems='center'>
                            <Grid2 size={6}>
                                <Button
                                    onClick={() => {
                                        localStorage.setItem(sessionTexts.route, routeUrls.resources.path);
                                        props.setResourceId('');
                                        if (localStorage.getItem('previous_route')) {
                                            navigate(localStorage.getItem('previous_route') as string);
                                        } else {
                                            navigate(`/${routeUrls.resources.path}`);
                                        }
                                        localStorage.removeItem('previous_route');
                                    }}
                                    variant='text'
                                    startIcon={<ArrowBack className={cx(classes.BackArrow)} />}
                                >
                                    <Typography variant={'subtitle2'}>
                                        {is700pxOrSmaller || !!localStorage.getItem('previous_route') ? 'Back' : 'Back to Resources'}
                                    </Typography>
                                </Button>
                            </Grid2>
                            {vOperate && currentTab === 'scheduler' && (
                                <Grid2 direction={'row'} container size={{ xs: 'grow', sm: 6 }} justifyContent={'flex-end'}>
                                    <BulkParkingSchedulesActions
                                        isDeleteExemptionDialogOpen={props.isDeleteExemptionDialogOpen}
                                        setIsDeleteExemptionDialogOpen={props.setIsDeleteExemptionDialogOpen}
                                        scheduleWithExemption={props.scheduleWithExemption}
                                        setScheduleWithExemption={props.setScheduleWithExemption}
                                        onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                                        onClickDeleteExemption={props.onClickDeleteExemption}
                                        parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                                        setResourcesSelectedForParking={props.setResourcesSelectedForParking}
                                        isParkableResourcesLoading={props.isParkableResourcesLoading}
                                        selectedSchedules={props.selectedSchedules}
                                        setSelectedSchedules={props.setSelectedSchedules}
                                        onClickEditSchedule={props.onClickEditSchedule}
                                        onClickCreateSchedule={props.onClickCreateSchedule}
                                        onClickDeleteSelectedSchedules={props.onClickDeleteSelectedSchedules}
                                        onClickDeleteSchedule={props.onClickDeleteSchedule}
                                        parkableResources={props.parkableResources}
                                        isDrawerOpen={props.isParkingDrawerOpen}
                                        usersBusinessGroupings={props.usersBusinessGroupings}
                                        userHasGroupings={props.userHasGroupings}
                                        loadParkableResources={props.loadParkableResources}
                                        setScheduleToEdit={props.setScheduleToEdit}
                                        isChangeScheduleDialogOpen={props.isChangeScheduleDialogOpen}
                                        setIsChangeScheduleDialogOpen={props.setIsChangeScheduleDialogOpen}
                                        isRemoveAdvancedParkingDialogOpen={props.isRemoveAdvancedParkingDialogOpen}
                                        setIsRemoveAdvancedParkingDialogOpen={props.setIsRemoveAdvancedParkingDialogOpen}
                                        onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                                        onToggleDrawer={props.onToggleParkingDrawer}
                                        scheduleToEdit={props.scheduleToEdit}
                                        selectedResources={[props.resource]}
                                        parkingSchedules={props.parkingSchedules}
                                        isParkingLoading={props.isParkingLoading}
                                        isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
                                        setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
                                        setScheduleToDelete={props.setScheduleToDelete}
                                        scheduleToDelete={props.scheduleToDelete}
                                        isScheduled={props.isScheduled}
                                        onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                                        onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                                        schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                                        onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                                        parkingScheduleType={parkingScheduleType}
                                        isCreationEnabled={false}
                                    />
                                </Grid2>
                            )}
                        </Grid2>
                        <Stack direction={'column'} width={'100%'}>
                            <Typography className={cx(classes.ResourceTitle)} variant={'subtitle1'} fontSize={'24px'}>
                                {props.resource.name !== undefined ? props.resource.name : props.resource.resource_id}
                            </Typography>
                            {props.resource?.is_parking_capable ? (
                                <>
                                    {vOperate && (
                                        <Tabs className={cx(classes.Tabs)} variant={'fullWidth'} value={currentTab} onChange={onTabChange}>
                                            <Tab value='details' label='Details' />
                                            {vOperate && <Tab value='scheduler' label='Enterprise Scheduler' />}
                                        </Tabs>
                                    )}
                                    <Box className={cx(classes.ContentContainer)}>
                                        {currentTab === 'details' && <ResourceDetailTab isLoading={props.isLoading} resource={props.resource} />}
                                        {vOperate && currentTab === 'scheduler' && (
                                            <ResourceDetailParkingSchedulesTab
                                                isDeleteExemptionDialogOpen={props.isDeleteExemptionDialogOpen}
                                                setIsDeleteExemptionDialogOpen={props.setIsDeleteExemptionDialogOpen}
                                                scheduleWithExemption={props.scheduleWithExemption}
                                                setScheduleWithExemption={props.setScheduleWithExemption}
                                                onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                                                onClickDeleteExemption={props.onClickDeleteExemption}
                                                parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                                                setScheduleToEdit={props.setScheduleToEdit}
                                                scheduleToEdit={props.scheduleToEdit}
                                                parkingSchedules={props.parkingSchedules}
                                                selectedSchedules={props.selectedSchedules}
                                                setSelectedSchedules={props.setSelectedSchedules}
                                                isParkingLoading={props.isParkingLoading}
                                                onClickDeleteSchedule={props.onClickDeleteSchedule}
                                                onClickDeleteSelectedSchedules={props.onClickDeleteSelectedSchedules}
                                                onOpenScheduleChangeDialog={props.onOpenScheduleChangeDialog}
                                                isChangeScheduleDialogOpen={props.isChangeScheduleDialogOpen}
                                                setIsChangeScheduleDialogOpen={props.setIsChangeScheduleDialogOpen}
                                                onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                                                onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                                                isRemoveAdvancedParkingDialogOpen={props.isRemoveAdvancedParkingDialogOpen}
                                                setIsRemoveAdvancedParkingDialogOpen={props.setIsRemoveAdvancedParkingDialogOpen}
                                                scheduleToDelete={props.scheduleToDelete}
                                                setScheduleToDelete={props.setScheduleToDelete}
                                                onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                                                isScheduled={props.isScheduled}
                                                isConfirmDeleteDialogOpen={props.isConfirmDeleteDialogOpen}
                                                setIsConfirmDeleteDialogOpen={props.setIsConfirmDeleteDialogOpen}
                                                onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                                                schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                                                onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                                                setParkingScheduleType={setParkingScheduleType}
                                            />
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <ResourceDetailTab isLoading={props.isLoading} resource={props.resource} />
                            )}
                        </Stack>
                    </CardContent>
                </>
            )}
        </Card>
    );
};

const useStyles = customMakeStyles<IResourceDetailCardProps>()((theme, props) => ({
    BackArrow: {
        fill: theme.palette.primary.main,
    },
    ActionGrid: { marginBottom: '1.5rem' },
    Card: {
        minHeight: '100%',
    },
    ResourceTitle: { marginBottom: '1rem' },
    CardContent: { padding: '1.5rem' },
    ContentContainer: {
        marginTop: '1rem',
    },
    Tabs: {},
}));

export { ResourceDetailCard };
