import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { DialogTitle, Drawer, IconButton, Paper, PaperProps, Stack, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import { LinkAwsAccountDrawer } from './link-aws-account-drawer';
import {
    CloudProvidersTypes,
    ICloudProviderAccount,
    LinkAwsAccountForm,
    LinkAzureAccountForm,
    LinkGcpAccountForm,
    LinkOciAccountForm,
} from '@vegaplatformui/models';
import { LinkAzureAccountDrawer } from './link-azure-account-drawer';
import { LinkGcpAccountDrawer } from './link-gcp-account-drawer';
import { SelectProviderAccountCard } from './select-provider-account-card';
import { Close } from '@mui/icons-material';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { LinkOciAccountDrawer } from './link-oci-account-drawer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICloudProviderSelecterDialogProps {
    isDialogOpen: boolean;
    awsParentAccounts: ICloudProviderAccount[];
    onCloseDialog: () => void;
    onCloseLinkAwsDialog: () => void;
    onCloseLinkAzureDialog: () => void;
    onCloseLinkGcpDialog: () => void;
    onCloseLinkOciAccountDialog: () => void;
    onOpenBulkImportProviderDialog: () => void;
    onSubmitLinkAwsAccountForm: (data: LinkAwsAccountForm) => void;
    onSubmitLinkAzureAccountForm: (data: LinkAzureAccountForm) => void;
    onSubmitLinkGcpAccountForm: (data: LinkGcpAccountForm) => void;
    onSubmitLinkOciAccountForm: (data: LinkOciAccountForm) => void;
    setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void;
    serviceAccountJson: string;
    setServiceAccountJson: React.Dispatch<React.SetStateAction<string>>;
}

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle='#choose-cloud-provider-dialog' cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    );
}

const CloudProviderSelecterDrawer: React.FC<ICloudProviderSelecterDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [cloudProvider, setCloudProvider] = React.useState<CloudProvidersTypes | undefined>(undefined);
    const [isCloudProviderSelected, setIsCloudProviderSelected] = React.useState(false);
    const [onSubmitClicked, setOnSubmitClicked] = React.useState(false);
    const [isRoot, setIsRoot] = React.useState(false);

    const onChangeCloudProvider = (event: React.MouseEvent<HTMLElement>, cloudProvider: CloudProvidersTypes | undefined) => {
        if (cloudProvider !== null) {
            setIsCloudProviderSelected(true);
        } else {
            setIsCloudProviderSelected(false);
        }
        setCloudProvider(cloudProvider);
    };

    const onBackArrow = () => {
        setOnSubmitClicked(false);
        setCloudProvider(undefined);
        setIsRoot(false);
    };

    const onCloseDialog = () => {
        props.onCloseDialog();
        setIsCloudProviderSelected(false);
        setIsRoot(false);
        setCloudProvider(undefined);
    };

    return (
        <>
            <LinkAwsAccountDrawer
                onBackDrawer={onBackArrow}
                onCloseDialog={() => {
                    setOnSubmitClicked(false);
                    setCloudProvider(undefined);
                    onCloseDialog();
                }}
                awsParentAccounts={props.awsParentAccounts}
                isDialogOpen={onSubmitClicked && cloudProvider === CloudProvidersTypes.Aws}
                onSubmitLinkAwsAccountForm={props.onSubmitLinkAwsAccountForm}
                setSnackbarOptions={props.setSnackbarOptions}
            />
            <LinkAzureAccountDrawer
                isDialogOpen={onSubmitClicked && cloudProvider === CloudProvidersTypes.Azure}
                onBackDrawer={onBackArrow}
                onCloseDialog={() => {
                    setOnSubmitClicked(false);
                    setCloudProvider(undefined);
                    onCloseDialog();
                }}
                onSubmitLinkAzureAccountForm={props.onSubmitLinkAzureAccountForm}
                setSnackbarOptions={props.setSnackbarOptions}
            />
            <LinkGcpAccountDrawer
                isDialogOpen={onSubmitClicked && cloudProvider === CloudProvidersTypes.Gcp}
                onBackDrawer={() => {
                    onBackArrow();
                    props.setServiceAccountJson(``);
                }}
                onCloseDialog={() => {
                    setOnSubmitClicked(false);
                    setCloudProvider(undefined);
                    onCloseDialog();
                    props.setServiceAccountJson(``);
                }}
                onSubmitLinkGcpAccountForm={props.onSubmitLinkGcpAccountForm}
                setSnackbarOptions={props.setSnackbarOptions}
                serviceAccountJson={props.serviceAccountJson}
                setServiceAccountJson={props.setServiceAccountJson}
            />
            <LinkOciAccountDrawer
                isDialogOpen={onSubmitClicked && cloudProvider === CloudProvidersTypes.Oci}
                onBackDrawer={onBackArrow}
                onCloseDialog={() => {
                    setOnSubmitClicked(false);
                    setCloudProvider(undefined);
                    onCloseDialog();
                }}
                onSubmitLinkOciAccountForm={props.onSubmitLinkOciAccountForm}
                setSnackbarOptions={props.setSnackbarOptions}
            />
            <Drawer
                PaperProps={{
                    className: cx(classes.DrawerPaper),
                }}
                className={cx(classes.DrawerRoot)}
                anchor={'right'}
                open={props.isDialogOpen}
                onClose={onCloseDialog}
                aria-labelledby='choose-cloud-provider-drawer'
            >
                <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} variant={'h6'} id='choose-cloud-provider-dialog'>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        Link Provider Account
                        <IconButton className={cx(classes.CloseButton)} aria-label={'Close'} onClick={onCloseDialog}>
                            <Close fontSize={'small'} className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                    <Typography variant={'body2'}>Select a cloud service provider.</Typography>
                </DialogTitle>
                <SelectProviderAccountCard
                    onCloseDialog={onCloseDialog}
                    onChangeCloudProvider={onChangeCloudProvider}
                    cloudProvider={cloudProvider}
                    isCloudProviderSelected={isCloudProviderSelected}
                    setOnSubmitClicked={setOnSubmitClicked}
                    onOpenBulkImportProviderDialog={props.onOpenBulkImportProviderDialog}
                />
            </Drawer>
        </>
    );
};

const useStyles = customMakeStyles<ICloudProviderSelecterDialogProps>()((theme, props) => ({
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DialogActions: {
        marginRight: '1rem',
    },
    CloseButton: { float: 'right', padding: '6px' },
    DrawerPaper: { width: '35%' },
}));

export { CloudProviderSelecterDrawer };
