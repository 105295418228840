import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { GeminiResponse } from '@vegaplatformui/utils';
import {
    IGetOrganizationResponse,
    ISaveOrgLogoVariables,
    IGetLogoResponse,
    IOrganizationBrandingResponse,
    IPostOrganizationThemeRequest,
    IPutOrganizationThemeRequest,
    IOrganizationMetadata,
} from '@vegaplatformui/models';

export class OrganizationsApi extends HttpClient {
    protected static classInstance?: OrganizationsApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new OrganizationsApi();
        }
        return this.classInstance;
    }

    // organization data
    public getOrganization = (orgId: string): GeminiResponse<IGetOrganizationResponse> => this.instance.get(`/vegaapi/organization/${orgId}`);

    public putOrganization = (orgId: string, payload: any): GeminiResponse<any> => this.instance.put(`/vegaapi/organization/${orgId}`, payload);

    // logo data
    public getOrganizationLogo = (orgId: string): GeminiResponse<IGetLogoResponse> =>
        this.instance.get(`/vegaapi/organization/${orgId}/branding/logo`);

    public postOrganizationLogo = (variables: ISaveOrgLogoVariables): GeminiResponse<string> =>
        this.instance.post(`/vegaapi/organization/${variables.orgId}/branding/logo/${variables.variation}`, variables.file);

    public deleteOrganizationLogo = (orgId: string, variation: string) => {
        return this.instance
            .delete(`/vegaapi/organization/${orgId}/branding/logo?variation=${variation}`)
            .catch((error) => console.error('error deleting org logo: ', error.response?.data?.detail));
    };

    // branding data
    public getOrganizationBranding = (orgId: string, signal: AbortSignal): GeminiResponse<IOrganizationBrandingResponse> =>
        this.instance.get(`/vegaapi/organization/${orgId}/branding/style`, { signal });

    public putOrganizationBranding = (orgId: string, payload: IPutOrganizationThemeRequest): GeminiResponse<IOrganizationBrandingResponse> =>
        this.instance.put(`/vegaapi/organization/${orgId}/branding/style`, payload);

    public postOrganizationBranding = (orgId: string, payload: IPostOrganizationThemeRequest): GeminiResponse<IOrganizationBrandingResponse> =>
        this.instance.post(`/vegaapi/organization/${orgId}/branding/style`, payload);

    // organization children lookup
    public getOrganizationChildren = (orgId: string, signal: AbortSignal): GeminiResponse<IOrganizationMetadata> =>
        this.instance.get(`/vegaapi/organization/${orgId}/children`, { signal });
}
