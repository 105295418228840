import { useKeycloak } from '@react-keycloak-fork/web';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { IResourceType } from '@vegaplatformui/models';
import { ContainerApi } from '@vegaplatformui/apis';

export interface IUseResourceFilterOptionsApiHook {
    resourceTypes: IResourceType[];
    // resourceRegions: IResourceRegion[];
}
export interface IUseResourceFilterOptionsApiProps {
    tableIdentifier: string;
}

export function useResourceFilterOptionsApi(props: IUseResourceFilterOptionsApiProps): IUseResourceFilterOptionsApiHook {
    const { keycloak } = useKeycloak();
    const resourcesTableUtilities = useTableUtilities(props.tableIdentifier);

    const containerApi = useMemo(() => {
        const containerApi = new ContainerApi();
        containerApi.keycloak = keycloak;
        return containerApi;
    }, [keycloak]);

    const { data: resourceTypes } = useQuery({
        queryKey: [queryKeys.resources.resourceTypes],
        queryFn: async () => {
            const response = await containerApi.getResourceTypes();
            return response.data;
        },
        meta: {
            errorMessage: 'Unable to get resource types',
        },
        enabled: resourcesTableUtilities.currentTableControl !== undefined,
    });

    return { resourceTypes: resourceTypes ?? [] };
}
