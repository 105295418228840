import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Dialog, DialogTitle, DialogActions, DialogContent, Stack, Typography, Button } from '@mui/material';
import { IChatbotTopic } from '@vegaplatformui/models';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeleteConversationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    topicToDelete: IChatbotTopic | undefined;
    deleteTopic: (topicId: string) => void;
    clearTopics: () => void;
}

const DeleteTopicDialog: React.FC<IDeleteConversationDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { isOpen, onClose, topicToDelete, deleteTopic, clearTopics } = props;

    return (
        <Dialog
            open={isOpen}
            className={cx(classes.Dialog)}
            maxWidth={'xs'}
            fullWidth
            onClose={onClose}
            aria-labelledby='delete-topic-dialog-title'
            aria-describedby='delete-topic-item'
        >
            <DialogTitle className={cx(classes.DialogTitle)} id='delete-client-dialog-title'>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ErrorOutline color={'error'} />
                    <Typography variant={'h6'}>{topicToDelete ? 'Delete topic' : 'Clear all topics'}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(classes.DialogContent)} id={'delete-topic-item'}>
                <Typography variant='body2'>
                    {topicToDelete ? 'Are you sure you want to delete this topic?' : 'Are you sure you want to clear all topics?'} This action can not
                    be undone.
                </Typography>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Stack direction={'row'} alignItems={'center'} sx={{ mr: '1rem' }} justifyContent={'flex-end'} spacing={1.5}>
                    <Button variant='cancel' onClick={onClose} disabled={false}>
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color={'error'}
                        onClick={() => {
                            topicToDelete ? deleteTopic(topicToDelete.conversation_id) : clearTopics();
                            return onClose();
                        }}
                    >
                        {topicToDelete ? 'Delete' : 'Clear All'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IDeleteConversationDialogProps>()((theme, props) => ({
    Dialog: { zIndex: '1303 !important' },
    DialogTitle: { paddingBottom: '12px' },
    DialogContent: { paddingBottom: '24px' },
    DialogActions: {
        paddingBottom: '24px',
        paddingTop: 0,
    },
}));

export { DeleteTopicDialog };
