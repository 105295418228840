import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Card, CardContent, Grid2, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
    IBusinessGrouping,
    IBusinessGroupingDetailsForm,
    IBusinessGroupingType,
    IBusinessGroupTreeItem,
    IParkingScheduleSummary,
    IResource,
    IUser,
    IUserBusinessGroups,
    ParkingScheduleType,
    IBusinessUnitTreeItem,
} from '@vegaplatformui/models';
import { BusinessGroupingWizardTabs } from './business-grouping-wizard-tabs/business-grouping-wizard-tabs';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { sessionTexts, useRouteUrls } from '@vegaplatformui/utils';
import { IBulkParkingSchedulesActionsProps } from '../../parking/parking-types';
import { BulkParkingSchedulesActions } from '../../parking/bulk-parking-schedules-actions';
import { ParkingScheduleBusinessGroupId, ParkingScheduleTableBusinessGroupId } from '../../jotai/atom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardCardProps extends IBulkParkingSchedulesActionsProps {
    width: string;
    businessGroupingToEdit: IBusinessGrouping | undefined;
    businessGroupingTypes: IBusinessGroupingType[];
    availableUsers: IUser[];
    usersBusinessGroupings: IBusinessUnitTreeItem[];
    validParentBusinessGroupings: IBusinessUnitTreeItem[];
    onSubmitForm: (data: IBusinessGroupingDetailsForm) => void;
    isLoading: boolean;
    isSubmitLoading: boolean;
    initalBusinessroupTreeNodes: IBusinessGroupTreeItem[];
    unassignUsers: (users: IUser[]) => void;
    setResourcesSelectedForParking: (resources: IResource[]) => void;
    scheduleToDelete: IParkingScheduleSummary | undefined;
    setScheduleToDelete: React.Dispatch<React.SetStateAction<IParkingScheduleSummary | undefined>>;
    usersBusinessGroupingsFlat: IUserBusinessGroups[];
}

const BusinessGroupingWizardCard: React.FC<IBusinessGroupingWizardCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const theme = useTheme();
    const navigate = useNavigate();
    const { tab } = useParams();
    const [currentTab, setCurrentTab] = useState(tab ?? '');
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState<boolean>(false);
    const [isPageReset, setIsPageReset] = React.useState<boolean>(false);
    const setTableBusinessGroupingId = useSetAtom(ParkingScheduleTableBusinessGroupId);
    const setBusinessGroupingIDState = useSetAtom(ParkingScheduleBusinessGroupId);
    const { routeUrls } = useRouteUrls({});
    const [parkingScheduleType, setParkingScheduleType] = React.useState<ParkingScheduleType>(ParkingScheduleType.Basic);
    const { vOperate } = useFlags();
    const is1000pxOrSmaller = useMediaQuery(theme.breakpoints.down(1000));

    useEffect(() => {
        setCurrentTab(tab ?? '');
    }, [tab]);

    return (
        <Card className={cx(classes.Card)}>
            {props.isLoading ? (
                <LinearProgress />
            ) : (
                <CardContent className={classes.CardContent}>
                    <Grid2 container spacing={0}>
                        <Grid2 className={cx(classes.ActionGrid)} container direction='row' alignItems='center' size={12}>
                            <Grid2 size={6}>
                                <Button
                                    onClick={() => {
                                        setIsPageReset(true);
                                        localStorage.setItem(sessionTexts.route, routeUrls.businessGroupings.path);
                                        if (localStorage.getItem('previous_route')) {
                                            navigate(localStorage.getItem('previous_route') as string);
                                        } else {
                                            navigate('/businessgroupings');
                                        }
                                        localStorage.removeItem('previous_route');
                                        setBusinessGroupingIDState('');
                                        setTableBusinessGroupingId('');
                                    }}
                                    variant='text'
                                    startIcon={<ArrowBack className={cx(classes.BackArrow)} />}
                                >
                                    <Typography variant={'subtitle2'}>
                                        {is1000pxOrSmaller || !!localStorage.getItem('previous_route') ? 'Back' : 'Back to Business Groupings'}
                                    </Typography>
                                </Button>
                            </Grid2>
                            {vOperate && currentTab === 'scheduler' && (
                                <Grid2 direction={'row'} container size={{ xs: 'auto', sm: 6 }} justifyContent={'flex-end'}>
                                    <BulkParkingSchedulesActions
                                        isDeleteExemptionDialogOpen={props.isDeleteExemptionDialogOpen}
                                        setIsDeleteExemptionDialogOpen={props.setIsDeleteExemptionDialogOpen}
                                        scheduleWithExemption={props.scheduleWithExemption}
                                        setScheduleWithExemption={props.setScheduleWithExemption}
                                        onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                                        onClickDeleteExemption={props.onClickDeleteExemption}
                                        parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                                        setResourcesSelectedForParking={props.setResourcesSelectedForParking}
                                        isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
                                        isParkableResourcesLoading={props.isParkableResourcesLoading}
                                        selectedSchedules={props.selectedSchedules}
                                        setSelectedSchedules={props.setSelectedSchedules}
                                        onClickEditSchedule={props.onClickEditSchedule}
                                        onClickCreateSchedule={props.onClickCreateSchedule}
                                        onClickDeleteSelectedSchedules={props.onClickDeleteSelectedSchedules}
                                        onClickDeleteSchedule={props.onClickDeleteSchedule}
                                        parkableResources={props.parkableResources}
                                        isDrawerOpen={props.isDrawerOpen}
                                        usersBusinessGroupings={props.usersBusinessGroupings}
                                        userHasGroupings={props.userHasGroupings}
                                        loadParkableResources={props.loadParkableResources}
                                        setScheduleToEdit={props.setScheduleToEdit}
                                        isChangeScheduleDialogOpen={props.isChangeScheduleDialogOpen}
                                        setIsChangeScheduleDialogOpen={props.setIsChangeScheduleDialogOpen}
                                        isRemoveAdvancedParkingDialogOpen={props.isRemoveAdvancedParkingDialogOpen}
                                        setIsRemoveAdvancedParkingDialogOpen={props.setIsRemoveAdvancedParkingDialogOpen}
                                        onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                                        onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                                        setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
                                        onToggleDrawer={props.onToggleDrawer}
                                        scheduleToEdit={props.scheduleToEdit}
                                        isParkingLoading={props.isParkingLoading}
                                        parkingSchedules={props.parkingSchedules}
                                        scheduleToDelete={props.scheduleToDelete}
                                        setScheduleToDelete={props.setScheduleToDelete}
                                        onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                                        onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                                        isScheduled={props.isScheduled}
                                        schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                                        onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                                        parkingScheduleType={parkingScheduleType}
                                        isCreationEnabled={true}
                                    />
                                </Grid2>
                            )}
                        </Grid2>
                        <Grid2 sx={{ marginTop: '1.5rem' }} size={12} container>
                            <BusinessGroupingWizardTabs
                                isDeleteExemptionDialogOpen={props.isDeleteExemptionDialogOpen}
                                setIsDeleteExemptionDialogOpen={props.setIsDeleteExemptionDialogOpen}
                                scheduleWithExemption={props.scheduleWithExemption}
                                setScheduleWithExemption={props.setScheduleWithExemption}
                                onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                                onClickDeleteExemption={props.onClickDeleteExemption}
                                parkingScheduleTableIdentifier={props.parkingScheduleTableIdentifier}
                                validParentBusinessGroupings={props.validParentBusinessGroupings}
                                userBusinessGroupings={props.usersBusinessGroupings}
                                onSubmitForm={props.onSubmitForm}
                                businessGroupingTypes={props.businessGroupingTypes}
                                availableUsers={props.availableUsers}
                                businessGroupingToEdit={props.businessGroupingToEdit}
                                isLoading={props.isSubmitLoading}
                                unassignUsers={props.unassignUsers}
                                parkingSchedules={props.parkingSchedules}
                                onClickDeleteSchedule={props.onClickDeleteSchedule}
                                onClickDeleteSelectedSchedules={props.onClickDeleteSelectedSchedules}
                                selectedSchedules={props.selectedSchedules}
                                setSelectedSchedules={props.setSelectedSchedules}
                                isChangeScheduleDialogOpen={props.isChangeScheduleDialogOpen}
                                setIsChangeScheduleDialogOpen={props.setIsChangeScheduleDialogOpen}
                                scheduleToEdit={props.scheduleToEdit}
                                setScheduleToEdit={props.setScheduleToEdit}
                                isRemoveAdvancedParkingDialogOpen={props.isRemoveAdvancedParkingDialogOpen}
                                setIsRemoveAdvancedParkingDialogOpen={props.setIsRemoveAdvancedParkingDialogOpen}
                                onRemoveAdvancedParking={props.onRemoveAdvancedParking}
                                onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                                isParkingLoading={props.isParkingLoading}
                                isBackPage={isPageReset}
                                scheduleToDelete={props.scheduleToDelete}
                                setScheduleToDelete={props.setScheduleToDelete}
                                onChangeScheduleStatuses={props.onChangeScheduleStatuses}
                                onClickOpenDeleteDialog={props.onClickOpenDeleteDialog}
                                isScheduled={props.isScheduled}
                                isConfirmDeleteDialogOpen={props.isConfirmDeleteDialogOpen}
                                setIsConfirmDeleteDialogOpen={props.setIsConfirmDeleteDialogOpen}
                                schedulesToChangeStatusOn={props.schedulesToChangeStatusOn}
                                onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                                setParkingScheduleType={setParkingScheduleType}
                                usersBusinessGroupingsFlat={props.usersBusinessGroupingsFlat}
                            />
                        </Grid2>
                    </Grid2>
                </CardContent>
            )}
        </Card>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardCardProps>()((theme, props) => ({
    BackArrow: {
        fill: theme.palette.primary.main,
    },
    ActionGrid: { marginBottom: '1.5rem' },
    CardContent: { padding: '1.5rem', paddingBottom: 0 },
    Card: {
        minHeight: '100%',
        overflow: 'hidden',
    },
}));

export { BusinessGroupingWizardCard };
