import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { FileDownload } from '@mui/icons-material';
import { DataGrid, GridPagination, GridRowId, GridRowSelectionModel, GridSlotsComponent, GridToolbarContainer } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { IFile } from '@vegaplatformui/models';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { CustomGridColStringOperatorDef } from '../utilities/custom-grid-col-string-operator-def';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { DataGridCustomToolbar } from '../utilities/datagrid-custom-toolbar';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';

export interface IFileDownloadsTableProps {
    isLoading: boolean;
    setSelectedFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
    selectedFiles: IFile[];
    availableFilesToDownload: IFile[];
    onClickDownloadFile: (data: IFile) => void;
}

const FileDownloadsTable: React.FC<IFileDownloadsTableProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const fileDownloadsTableUtilities = useTableUtilities(tableIdentifierKeys.fileDownloadsTable);

    useEffect(() => {
        fileDownloadsTableUtilities.registerTableControl();
    }, []);

    useEffect(() => {
        if (props.selectedFiles.length === 0 && selectionModel.length > 0) {
            setSelectionModel([]);
        }
    }, [props.selectedFiles, selectionModel.length]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter className={commonStyles.classes.DataGridToolBarFilter} />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        { ...CustomGridColStringOperatorDef, field: 'filename', headerName: 'File Name', flex: 1 },
        {
            ...CustomGridColStringOperatorDef,
            field: 'lastmodified',
            headerName: 'Date Added',
            flex: 1,
            valueFormatter: (value: number, row: IFile) =>
                Intl.DateTimeFormat('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }).format(value * 1000),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'size',
            headerName: 'File Size',
            flex: 1,
            valueFormatter: (value: number, row: IFile) => formatBytes(Number(value)),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            headerName: 'Actions',
            disableColumnMenu: true,
            type: 'actions',
            sortable: false,
            flex: 1,
            getActions: (params) => [
                <Button
                    startIcon={<FileDownload />}
                    color='primary'
                    variant='contained'
                    disabled={props.selectedFiles.length > 1}
                    onClick={() => {
                        props.onClickDownloadFile(params.row);
                    }}
                >
                    Download
                </Button>,
            ],
        },
    ];

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        props.setSelectedFiles(
            gridSelectionModel.map((id: GridRowId) => props.availableFilesToDownload.find((row: IFile) => row.id === id)) as IFile[]
        );
        setSelectionModel(gridSelectionModel);
    };

    return (
        <Box>
            <DataGrid // TODO: switch to DataGridPremium
                autoHeight={true}
                // pagination={true}
                columns={columns}
                rows={props.availableFilesToDownload ?? []}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    columnsManagement: { getTogglableColumns },
                    pagination: {
                        showLastButton: true,
                        showFirstButton: true,
                        slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                    },
                    toolbar: { tableIdentifier: tableIdentifierKeys.fileDownloadsTable },
                    noRowsOverlay: {
                        action: 'uploading a file',
                    },
                }}
                density={fileDownloadsTableUtilities.currentTableControl?.density}
                onDensityChange={fileDownloadsTableUtilities.onDensityChange}
                paginationModel={fileDownloadsTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={fileDownloadsTableUtilities.onPaginationModelChange}
                filterModel={fileDownloadsTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={fileDownloadsTableUtilities.onFilterModelChange}
                columnVisibilityModel={fileDownloadsTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={fileDownloadsTableUtilities.onColumnVisibilityModelChange}
                loading={props.isLoading}
            />
        </Box>
    );
};

//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function formatBytes(x: any) {
    let l = 0,
        n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

const useStyles = customMakeStyles<IFileDownloadsTableProps>()((theme, props) => ({
    ToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    ToolBarFilter: {
        color: theme.palette.grey[500],
        marginBottom: '1rem',
    },
    FileIcon: {
        height: '0.875rem',
        marginBottom: '-.05rem',
        marginLeft: '-.5rem',
        color: theme.palette.grey[900],
    },
}));

export { FileDownloadsTable };
