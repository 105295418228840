import React, { useEffect, useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { Circle, Schedule } from '@mui/icons-material';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { DataGridCellTooltip } from '../../utilities/datagrid-cell-tooltip';
import { CustomGridColSingleSelectOperatorDef_OnlyIs, CustomGridColStringOperatorDef } from '../../utilities/custom-grid-col-string-operator-def';
import { ParkingScheduleTableMenu } from './parking-schedule-table-menu';
import { IParkingScheduleSummary, ParkingScheduleStatus, ParkingScheduleType } from '@vegaplatformui/models';
import { capitalizeFirstLetter } from '@vegaplatformui/utils';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useSetRecoilState } from 'recoil';
import dayjs from 'dayjs';
import { IsEditingExemption } from '../../recoil/atom';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../utilities/custom-data-grid-render-cell-button';
import { CustomNoResultsOverlay, CustomNoRowsOverlay } from '../../utilities/datagrid-custom-norowsoverlay';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IParkingSchedulesTableProps {
    parkingSchedules: IParkingScheduleSummary[];
    selectedSchedules: IParkingScheduleSummary[];
    setSelectedSchedules: React.Dispatch<React.SetStateAction<IParkingScheduleSummary[]>>;
    isLoading: boolean;
    onClickAction: (schedule: IParkingScheduleSummary, scheduleType?: ParkingScheduleType) => void;
    // disableFilters?: boolean;
    onClickDelete?: () => void;
    onClickOpenDeleteDialog?: (schedule?: IParkingScheduleSummary) => void;
    onOpenScheduleChangeDialog?: (schedule: any) => void;
    onOpenRemoveAdvancedParkingDialog?: (schedule: any) => void;
    parkingScheduleTableIdentifier: string;
    onOpenEnableDisableSchedulesDialog?: (schedules: IParkingScheduleSummary[] | undefined, isScheduledChangeStatus: boolean) => void;
    onClickOpenDeleteExemptionDialog?: (schedule: IParkingScheduleSummary) => void;
}

const ParkingSchedulesTable: React.FC<IParkingSchedulesTableProps> = (props) => {
    const { cx, classes } = useStyles(props);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const parkingScheduleTableUtilities = useTableUtilities(props.parkingScheduleTableIdentifier);
    const setIsEditingExemption = useSetRecoilState(IsEditingExemption);
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);

    React.useEffect(() => {
        parkingScheduleTableUtilities.registerTableControl();
    }, []);

    useEffect(() => {
        const selectedIds = props.selectedSchedules?.map((x) => x?.id);
        setSelectionModel(selectedIds);
    }, [props.parkingSchedules, props.selectedSchedules]);

    const columns = React.useMemo<GridColDef<IParkingScheduleSummary>[]>(
        () => [
            {
                ...CustomGridColStringOperatorDef,
                field: 'name',
                headerName: 'Schedule Name',
                flex: 1,
                renderCell: (params: GridRenderCellParams<IParkingScheduleSummary>) => (
                    <CustomDataGridRenderCellButton
                        ref={ref}
                        buttonProps={{
                            tabIndex: params.tabIndex,
                            className: cx(classes.ParkingScheduleNameButton),
                            variant: 'text',
                            onClick: (e) => {
                                props.onClickAction!(params.row);
                            },
                            children: params.row.name,
                        }}
                        params={params}
                    />
                ),
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'type_str',
                headerName: 'Type',
                flex: 0.5,
                renderCell: (params: GridRenderCellParams) => (params.value ? capitalizeFirstLetter(params.value) : params.value),
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'description',
                headerName: 'Description',
                flex: 1,
            },
            {
                ...CustomGridColSingleSelectOperatorDef_OnlyIs,
                field: 'status',
                headerName: 'Status',
                flex: 0.7,
                sortable: false,
                type: 'singleSelect',
                valueOptions: [
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                    { value: 'disabled', label: 'Disabled' },
                ],
                renderCell: (params: GridRenderCellParams<IParkingScheduleSummary>) => {
                    switch (params.row.status) {
                        case ParkingScheduleStatus.Inactive:
                            return (
                                <Stack height={'100%'} direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                    <Circle color={'error'} sx={{ width: 10, height: 10 }} />
                                    <Typography variant={'body2'}>Inactive</Typography>
                                </Stack>
                            );
                        case ParkingScheduleStatus.Active:
                            return (
                                <Stack height={'100%'} direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                    <Circle color={'success'} sx={{ width: 10, height: 10 }} />
                                    <Typography variant={'body2'}>Active</Typography>
                                </Stack>
                            );
                        case ParkingScheduleStatus.Disabled:
                            return (
                                <Stack height={'100%'} direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                    <Circle color={'disabled'} sx={{ width: 10, height: 10 }} />
                                    <Typography variant={'body2'}>Disabled</Typography>
                                </Stack>
                            );
                    }
                },
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'exemption',
                headerName: 'Disable Between',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                flex: 1,
                renderCell: (params: GridRenderCellParams<IParkingScheduleSummary>) => {
                    return (
                        <StyledToolTip
                            tabIndex={params.tabIndex}
                            arrow
                            title={
                                params.row.exemption && params.row.exemption.starts_at !== null && params.row.exemption.ends_at !== null ? (
                                    <Stack direction={'column'} justifyContent='center' alignItems='center' spacing={1}>
                                        {params.row.exemption && dayjs(params.row.exemption.ends_at) < dayjs() && (
                                            <Typography variant={'caption'}>Disable Between has expired</Typography>
                                        )}
                                        <Stack direction={'column'} spacing={0.5}>
                                            <Typography variant={'caption'}>
                                                Disable start:{' '}
                                                {dayjs(params.row.exemption?.starts_at)
                                                    .tz(params.row.exemption.time_zone_str)
                                                    .format('MM/DD/YYYY hh:mm A (Z)')}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                Disable end:{' '}
                                                {dayjs(params.row.exemption?.ends_at)
                                                    .tz(params.row.exemption.time_zone_str)
                                                    .format('MM/DD/YYYY hh:mm A (Z)')}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                ) : (
                                    ''
                                )
                            }
                        >
                            <CustomDataGridRenderCellButton
                                ref={ref}
                                iconButtonProps={{
                                    tabIndex: params.tabIndex,
                                    size: 'small',
                                    'aria-label': `${params.row.name}'s Disable Between`,
                                    className:
                                        params.row.exemption && dayjs(params.row.exemption.ends_at) < dayjs()
                                            ? cx(classes.DisableBetweenIconError)
                                            : cx(classes.DisableBetweenIcon),
                                    onClick: () => {
                                        setIsEditingExemption(!!params.row.exemption);
                                        return (
                                            props.onOpenEnableDisableSchedulesDialog && props.onOpenEnableDisableSchedulesDialog([params.row], true)
                                        );
                                    },
                                    color:
                                        params.row.exemption && dayjs(params.row.exemption.ends_at) < dayjs()
                                            ? 'error'
                                            : params.row.exemption
                                              ? 'success'
                                              : 'default',
                                    children: <Schedule />,
                                }}
                                params={params}
                            />
                        </StyledToolTip>
                    );
                },
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'business_group_name',
                headerName: 'Business Group',
                flex: 1,
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'timezone',
                headerName: 'Time Zone',
                flex: 1,
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'number_of_resources',
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                headerName: 'Number of Resources',
                flex: 1,
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'updated_at',
                headerName: 'Last Updated ',
                flex: 1,
                valueFormatter: (value: string, row: IParkingScheduleSummary) => {
                    return Intl.DateTimeFormat('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    }).format(Date.parse(value));
                },
            },
            {
                ...CustomGridColStringOperatorDef,
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                disableColumnMenu: true,
                filterable: false,
                sortable: false,
                flex: 0.5,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams<IParkingScheduleSummary>) => (
                    <strong>
                        <ParkingScheduleTableMenu
                            selectedSchedules={props.selectedSchedules}
                            scheduleSummary={params.row}
                            onClickShowDetails={props.onClickAction}
                            onClickDeleteDialog={props.onClickOpenDeleteDialog}
                            onOpenEnableDisableSchedulesDialog={props.onOpenEnableDisableSchedulesDialog}
                            onOpenRemoveAdvancedParkingDialog={props.onOpenRemoveAdvancedParkingDialog}
                            onClickOpenDeleteExemptionDialog={props.onClickOpenDeleteExemptionDialog}
                            params={params}
                        />
                    </strong>
                ),
            },
        ],
        [classes.DisableBetweenIcon, classes.DisableBetweenIconError, classes.ParkingScheduleNameButton, cx, props, setIsEditingExemption]
    );

    columns.map((column) => {
        if (!column.renderCell && column.type !== 'actions') column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        const t = gridSelectionModel.map((id) => props.parkingSchedules.find((schedule) => schedule.id === id));
        let x = t.filter((x) => x !== undefined);
        const y = x.filter((x) => props.selectedSchedules.find((schedule) => schedule.id === x?.id) === undefined);
        x = y.filter((x) => x !== undefined);
        const z = props.selectedSchedules.filter((x) => gridSelectionModel.find((id) => id === x.id));

        props.setSelectedSchedules(gridSelectionModel.length === 0 ? [] : [...new Set([...(x as IParkingScheduleSummary[]), ...z])]);
    };

    return (
        <Box>
            <DataGridPremium
                aria-label={'Schedule Table'}
                autoHeight={true}
                pagination={true}
                columns={columns}
                paginationMode={'server'}
                filterMode={'server'}
                sortingMode={'server'}
                keepNonExistentRowsSelected={true}
                rowCount={parkingScheduleTableUtilities.currentTableControl?.totalRows ?? 0}
                rows={props.parkingSchedules}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                slotProps={{
                    toolbar: {
                        tableIdentifier: props.parkingScheduleTableIdentifier,
                    },
                    columnsManagement: {
                        getTogglableColumns,
                    },
                    pagination: {
                        page: parkingScheduleTableUtilities.currentTableControl?.paginationModel?.page,
                        onPageChange: parkingScheduleTableUtilities.onPaginationModelPageChange,
                        count: parkingScheduleTableUtilities.currentTableControl?.totalRows,
                        rowsPerPage: parkingScheduleTableUtilities.currentTableControl?.paginationModel?.pageSize,
                    },
                    noRowsOverlay: {
                        action: 'creating a schedule',
                    },
                }}
                density={parkingScheduleTableUtilities.currentTableControl?.density}
                onDensityChange={parkingScheduleTableUtilities.onDensityChange}
                paginationModel={parkingScheduleTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={parkingScheduleTableUtilities.onPaginationModelChange}
                sortModel={parkingScheduleTableUtilities.currentTableControl?.sortModel}
                onSortModelChange={parkingScheduleTableUtilities.onSortModelChange}
                filterModel={parkingScheduleTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={parkingScheduleTableUtilities.onFilterModelChange}
                columnVisibilityModel={parkingScheduleTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={parkingScheduleTableUtilities.onColumnVisibilityModelChange}
                loading={props.isLoading}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IParkingSchedulesTableProps>()((theme, props) => ({
    ParkingScheduleNameButton: {
        textTransform: 'none',
        justifyContent: 'unset',
    },
    DisableBetweenIcon: { ':hover': { color: theme.palette.success.main } },
    DisableBetweenIconError: { ':hover': { color: theme.palette.error.main } },
}));

export { ParkingSchedulesTable };
