import React, { useEffect, useRef, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ConsoleContentOptions, ConsoleExperience, createEmbeddingContext, FrameOptions } from 'amazon-quicksight-embedding-sdk';
import { CommonPageHeader, QuicksightConsoleLoading, ViewPortHeightAndWidth } from '../jotai/atom';
import { useAtom, useAtomValue } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuicksightConsoleProps {
    consoleUrl: string;
}

const QuicksightConsoleFrame: React.FC<IQuicksightConsoleProps> = (props) => {
    const [consoleLoading, setConsoleLoading] = useAtom(QuicksightConsoleLoading);
    const viewPortHeightAndWidth = useAtomValue(ViewPortHeightAndWidth);
    const commonPageHeader = useAtomValue(CommonPageHeader);
    const { cx, classes } = useStyles({ viewPortHeightAndWidth, commonPageHeader, isTabbed: false });
    const ref = useRef(null);
    const [container, setContainer] = useState<HTMLElement | null>(null);
    const [consoleFrame, setConsoleFrame] = useState<ConsoleExperience | undefined>(undefined);

    useEffect(() => {
        if (ref.current) {
            setContainer(ref.current);
        }
    }, []);

    const frameOptions: FrameOptions = {
        url: decodeURIComponent(props.consoleUrl),
        height: '100%',
        width: '100%',
        withIframePlaceholder: false,
        resizeHeightOnSizeChangedEvent: false,
        container: document.getElementById('qsContainer') ?? '#qsContainer',
    };

    const contentOptions: ConsoleContentOptions = {
        locale: navigator.language,
    };

    useEffect(() => {
        if (!consoleFrame) {
            const embedConsole = async (container: HTMLElement) => {
                const { embedConsole } = await createEmbeddingContext();

                const cFrame = await embedConsole({ ...frameOptions, container: container }, contentOptions);

                setConsoleFrame(cFrame);
            };

            if (container) {
                embedConsole(container);
            }
        } else {
            setConsoleLoading(false);
        }
    }, [container, props.consoleUrl]);

    return <div className={cx(classes.ReportContainer)} ref={ref}></div>;
};

interface IQuicksightStyles {
    commonPageHeader: React.ReactNode;
    isTabbed: boolean;
    viewPortHeightAndWidth: {
        height: number;
        width: number;
    };
}

const useStyles = customMakeStyles<IQuicksightStyles>()((theme, input) => ({
    ReportContainer: {
        overflowY: 'hidden',
        /**
         * Height set here because Quicksight doesn't fill to the container its in when set to 100%, numbers are the total height of the viewport minus
         * the static sizes of the Appbar and Page header if it is there.
         */
        height: input.commonPageHeader ? `calc(100vh - ${input.isTabbed ? '13rem' : '9rem'})` : `calc(100vh - ${input.isTabbed ? '10rem' : '4rem'})`,
        quicksightEmbeddingIframe: {
            quicksightEmbeddingIframe_clickableQuicksightAttribution: {
                display: 'none',
            },
        },
    },
}));

export { QuicksightConsoleFrame };
