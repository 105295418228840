import React from 'react';
import { Grid2, Card, Typography, Checkbox, CardContent } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CloudProvider } from '@vegaplatformui/models';

export interface ICloudProviderSelectorProps {
    providers: CloudProvider[];
    onProviderSelect: (providerId: string) => void;
}

const CloudProviderSelector: React.FC<ICloudProviderSelectorProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { providers, onProviderSelect } = props;

    const handleProviderSelect = (providerId: string) => {
        onProviderSelect(providerId);
    };

    return (
        <Grid2 container spacing={2} className={cx(classes.Container)}>
            {providers.map((provider) => (
                <Grid2 size={{ xs: 6, sm: 4, md: 3, lg: 2 }} key={provider.id}>
                    <Card
                        className={cx(classes.ProviderCard, provider.selected && classes.SelectedCard)}
                        onClick={() => handleProviderSelect(provider.id)}
                    >
                        <CardContent className={cx(classes.CardContent)}>
                            <Typography variant='body2' className={cx(classes.ProviderName)}>
                                {provider.name}
                            </Typography>
                            <Checkbox
                                checked={provider.selected}
                                onChange={() => handleProviderSelect(provider.id)}
                                className={cx(classes.Checkbox)}
                                size='small'
                            />
                        </CardContent>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
    );
};

const useStyles = customMakeStyles<ICloudProviderSelectorProps>()((theme) => ({
    Container: {
        margin: `${theme.spacing(2)} 0`,
    },
    ProviderCard: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.action.hover,
        },
    },
    SelectedCard: {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light || theme.palette.action.selected,
    },
    CardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1.5)} !important`,
        '&:last-child': {
            paddingBottom: `${theme.spacing(1.5)} !important`,
        },
    },
    ProviderName: {
        fontWeight: 500,
    },
    Checkbox: {
        padding: theme.spacing(0.5),
        '&.Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
}));

export { CloudProviderSelector };
