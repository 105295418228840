import { useKeycloak } from '@react-keycloak-fork/web';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import React, { useEffect, useMemo, useState } from 'react';
import {
    ChatbotSourceTypes,
    IChatbotConversationQAndAPair,
    IChatbotDataError,
    IChatbotDataItems,
    IChatbotDataJson,
    IChatbotMessage,
    IChatbotPostQuestionHookRequest,
    IChatbotPutQuestionAnswerRequest,
    IChatbotPutTopicRequest,
    IChatbotTopic,
} from '@vegaplatformui/models';
import { ChatbotApi } from '@vegaplatformui/apis';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { queryKeys } from './query-keys';
import { v4 as uuidv4 } from 'uuid';

export interface IUseChatbotApiHook {
    chatMessages: IChatbotMessage[];
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    handleSendMessage: (hookRequest: IChatbotPostQuestionHookRequest) => void;
    topicHistory: IChatbotTopic[];
    initStartTopic: () => void;
    loadTopic: (topicId: string) => void;
    renameTopic: (hookRequest: IChatbotPutTopicRequest) => void;
    deleteTopic: (topicId: string) => void;
    clearTopics: () => void;
    renamingStates: Record<string, boolean>;
    setRenaming: (id: string, isBeingRenamed: boolean) => void;
    currentTopic: IChatbotTopic | undefined;
    isTopicHistoryLoading: boolean;
    handleEditQuestionPair: (hookRequest: IChatbotPutQuestionAnswerRequest) => void;
    isDataTableOpen: boolean;
    chatbotDataObject: IChatbotDataItems | undefined;
    onCloseDataTableDialog: () => void;
    isChatbotLoading: boolean;
}

export interface IUseChatbotApiProps {
    isOpen: boolean;
}

export function useChatbotApi(props: IUseChatbotApiProps): IUseChatbotApiHook {
    const { keycloak } = useKeycloak();
    const { isOpen: isChatbotOpen } = props;
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const queryClient = useQueryClient();
    const [message, setMessage] = useState('');
    const [renamingStates, setRenamingStates] = useState<Record<string, boolean>>({});
    const [currentTopic, setCurrentTopic] = useState<IChatbotTopic>();
    const [chatMessages, setChatMessages] = useState<IChatbotMessage[]>([]);
    const [isDataTableOpen, setIsDataTableOpen] = useState(false);
    const [chatbotDataObject, setChatbotDataObject] = useState<IChatbotDataItems | undefined>(undefined);
    const [isChatbotLoading, setIsChatbotLoading] = useState(false);

    const setRenaming = (id: string, isBeingRenamed: boolean) => {
        setRenamingStates((prevState) => ({
            ...prevState,
            [id]: isBeingRenamed,
        }));
    };

    useEffect(() => {
        if (currentTopic === undefined) {
            setChatMessages([]);
        }
    }, [currentTopic]);

    const chatbotApi = useMemo(() => {
        const chatbotApi = new ChatbotApi();
        chatbotApi.keycloak = keycloak;
        return chatbotApi;
    }, [keycloak]);

    const onOpenDataTableDialog = (data: any[], question?: string) => {
        if (data.length > 0) {
            // Extract headers from the first object keys
            const headers = Object.keys(data[0]);
            setChatbotDataObject({
                headers: headers,
                data: data.map((data) => {
                    return { ...data, datagrid_id: uuidv4() };
                }),
                question: question,
            });
            setIsDataTableOpen(true);
        }
    };

    const onGetErrorMessage = (error: IChatbotDataError) => {
        if (error.message) {
            return error.message;
        }
    };

    const onCloseDataTableDialog = () => {
        setIsDataTableOpen(false);
        setChatbotDataObject(undefined);
    };

    const streamNewMessageResponse = async (response: Response) => {
        if (response.body) {
            const reader = response.body.getReader();
            const stream = new ReadableStream({
                async start(controller) {
                    let newMessage = '';
                    let question = '';
                    let metadata: IChatbotConversationQAndAPair;
                    let isLoading = false;
                    let jsonData: IChatbotDataJson;
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            controller.close();
                            break;
                        }
                        const decoder = new TextDecoder();
                        const chunk = decoder.decode(value, { stream: true });
                        newMessage += chunk;
                        if (newMessage.includes('JSON_meta_START') || newMessage.includes('JSON_result_START')) {
                            isLoading = true;
                            setIsChatbotLoading(true);
                        }
                        if (newMessage.includes('JSON_meta_START') && newMessage.includes('JSON_meta_END')) {
                            const jsonresult = newMessage.substring(
                                newMessage.indexOf('JSON_meta_START') + 'JSON_meta_START'.length,
                                newMessage.indexOf('JSON_meta_END')
                            );
                            metadata = JSON.parse(jsonresult);
                            question = metadata.question;
                            if (newMessage.includes('JSON_meta_ENDJSON_result_START')) {
                                newMessage = 'JSON_result_START';
                            }
                        }
                        if (newMessage.includes('JSON_result_START') && newMessage.includes('JSON_result_END')) {
                            const jsonResult = newMessage.substring(
                                newMessage.indexOf('JSON_result_START') + 'JSON_result_START'.length,
                                newMessage.indexOf('JSON_result_END')
                            );
                            jsonData = JSON.parse(jsonResult);
                            if ('result' in jsonData && jsonData.result) {
                                onOpenDataTableDialog(jsonData.result, question);
                                newMessage =
                                    'The results from your question are shown in a private table view and are not saved in the chat for security reasons. To display the results, please ask your question again.';
                            } else if ('error' in jsonData && jsonData.error) {
                                const errorMessage = onGetErrorMessage(jsonData.error);
                                if (errorMessage) {
                                    newMessage = errorMessage;
                                } else {
                                    newMessage = 'Looks like something went wrong.';
                                }
                            }
                            setIsChatbotLoading(false);
                            isLoading = false;
                        }
                        if (!isLoading) {
                            setChatMessages((msgs) => {
                                const newMsgs = [...msgs];
                                if (newMsgs.length > 0 && newMsgs[newMsgs.length - 1].from === ChatbotSourceTypes.Bot) {
                                    newMsgs[newMsgs.length - 1].message = newMessage;
                                    newMsgs[newMsgs.length - 1].metadata = { ...metadata, answer: newMsgs[newMsgs.length - 1].message };
                                } else {
                                    newMsgs.push({
                                        message: newMessage,
                                        from: ChatbotSourceTypes.Bot,
                                        metadata: { ...metadata, answer: newMessage },
                                    });
                                }
                                return newMsgs;
                            });
                        }
                        if (newMessage.includes('JSON_meta_END') && !newMessage.includes('JSON_meta_ENDJSON_result_START')) {
                            newMessage = '';
                            isLoading = false;
                            setIsChatbotLoading(false);
                        }
                    }
                },
            });
            new Response(stream).text();
            await queryClient.invalidateQueries({ queryKey: [queryKeys.chatbot.getTopics] });
        }
    };

    const { data: topicHistory, isLoading: isTopicHistoryLoading } = useQuery({
        queryKey: [queryKeys.chatbot.getTopics],
        queryFn: async () => {
            const response = await chatbotApi.getHistory();
            const current = response.data.conversations.find((topic) => topic.conversation_id === currentTopic?.conversation_id);
            if (current && currentTopic && current.conversation_name !== currentTopic.conversation_name) {
                setCurrentTopic({ ...currentTopic, conversation_name: current.conversation_name });
            }
            return response.data.conversations;
        },
        enabled: isChatbotOpen,
        meta: { errorMessage: 'There was a problem getting topic history' },
    });

    const loadTopicChatMessages = (topic: IChatbotTopic) => {
        setChatMessages(
            topic.question_and_answer_pairs.flatMap((questionAndAnswer, index) => {
                if (index > 0) {
                    const userMessage: IChatbotMessage = {
                        message: questionAndAnswer.question,
                        from: ChatbotSourceTypes.User,
                        metadata: questionAndAnswer,
                    };
                    const chatbotMessage: IChatbotMessage = {
                        message: questionAndAnswer.answer,
                        from: ChatbotSourceTypes.Bot,
                        metadata: questionAndAnswer,
                    };
                    return [userMessage, chatbotMessage];
                } else {
                    const chatbotMessage: IChatbotMessage = {
                        message: questionAndAnswer.answer,
                        from: ChatbotSourceTypes.Bot,
                        metadata: questionAndAnswer,
                    };
                    return [chatbotMessage];
                }
            })
        );
    };

    const handleLoadConversation = (topicId: string) => {
        const selectedTopic = topicHistory && topicHistory.find((topic) => topic.conversation_id === topicId);
        if (selectedTopic) {
            setCurrentTopic(selectedTopic);
            loadTopicChatMessages(selectedTopic);
        }
    };

    const {
        isPending: isHandleSendMessageLoading,
        mutate: handleSendMessage,
        isSuccess: isHandleSendMessageSuccess,
    } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem sending the message: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: async (response) => {
            if (response) {
                await streamNewMessageResponse(response);
            }
        },
        onSettled: async (data, error, variables, context) => {
            if (currentTopic === undefined) {
                setCurrentTopic(topicHistory?.find((topic) => topic.conversation_id === variables.newId) ?? undefined);
            }
        },
        mutationFn: async (hookRequest: IChatbotPostQuestionHookRequest) => {
            if (keycloak.tokenParsed && keycloak.tokenParsed.keycloak_userid) {
                const newMessage: IChatbotMessage = { message: hookRequest.message, from: ChatbotSourceTypes.User };
                setChatMessages((prevState) => [...prevState, newMessage]);
                setMessage('');
                return await chatbotApi.postQuestion({
                    question: hookRequest.message,
                    user_id: keycloak.tokenParsed.keycloak_userid,
                    conversation_id: currentTopic?.conversation_id ?? hookRequest.newId,
                });
            }
        },
    });

    const { mutate: handleDeleteTopic } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting the topic: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Topic successfully deleted.`,
            });
        },
        onSettled: async (data, error, topicId, context) => {
            if (topicId === currentTopic?.conversation_id) {
                setCurrentTopic(undefined);
            }
            await queryClient.invalidateQueries({ queryKey: [queryKeys.chatbot.getTopics] });
        },
        mutationFn: async (topicId: string) => {
            return await chatbotApi.deleteTopic(topicId);
        },
    });

    const { mutate: handleClearTopics } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem clearing topic history: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Topic history has cleared successfully.`,
            });
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.chatbot.getTopics] });
            setCurrentTopic(undefined);
        },
        mutationFn: async () => {
            return await chatbotApi.clearHistory();
        },
    });

    const { mutate: handleRenameTopic } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem clearing topic history: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables) => {
            queryClient.setQueryData([queryKeys.chatbot.getTopics], (prevState: IChatbotTopic[]) => {
                return prevState.map((topic: IChatbotTopic) => {
                    return topic.conversation_id === variables.topicId ? { ...topic, conversation_name: variables.new_name } : topic;
                });
            });
            if (currentTopic !== undefined && variables.topicId === currentTopic.conversation_id) {
                setCurrentTopic((prevState) => {
                    return (
                        prevState && {
                            conversation_id: prevState.conversation_id,
                            conversation_name: variables.new_name,
                            question_and_answer_pairs: prevState.question_and_answer_pairs,
                        }
                    );
                });
            }
        },
        onSettled: (data, error, variables, context) => {
            setRenaming(variables.topicId, false);
        },
        mutationFn: async (hookRequest: IChatbotPutTopicRequest) => {
            return await chatbotApi.renameTopic({ topicId: hookRequest.topicId, new_name: hookRequest.new_name });
        },
    });

    const { mutate: handleStartTopic } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem starting the new topic: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: async (response, newTopic) => {
            queryClient.setQueryData([queryKeys.chatbot.getTopics], [...(topicHistory ?? []), newTopic]);
            setCurrentTopic(newTopic);
            if (response) {
                await streamNewMessageResponse(response);
            }
        },
        mutationFn: async (newTopic: IChatbotTopic) => {
            return await chatbotApi.postTopic(newTopic);
        },
    });

    const initStartTopic = () => {
        const newId = uuidv4();
        const newTopic = {
            conversation_id: newId,
            conversation_name: '',
            question_and_answer_pairs: [],
        };
        setChatMessages(newTopic.question_and_answer_pairs);
        return handleStartTopic(newTopic);
    };

    const { mutate: handleEditQuestionPair } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem ${variables.useful ? 'liking' : 'disliking'} the response: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: async (response, hookRequest) => {
            setChatMessages(
                chatMessages.map((chatMessage) =>
                    chatMessage.metadata?.id === hookRequest.id
                        ? { ...chatMessage, metadata: { ...chatMessage.metadata, useful: hookRequest.useful ?? undefined } }
                        : chatMessage
                )
            );
        },
        mutationFn: async (hookRequest: IChatbotPutQuestionAnswerRequest) => {
            return await (currentTopic && chatbotApi.putQuestionAnswerPair(currentTopic.conversation_id, hookRequest));
        },
    });

    return {
        chatMessages,
        message,
        setMessage,
        handleSendMessage,
        topicHistory: topicHistory ?? [],
        deleteTopic: handleDeleteTopic,
        clearTopics: handleClearTopics,
        loadTopic: handleLoadConversation,
        renameTopic: handleRenameTopic,
        initStartTopic,
        setRenaming,
        renamingStates,
        currentTopic: currentTopic,
        isTopicHistoryLoading,
        handleEditQuestionPair,
        isDataTableOpen,
        onCloseDataTableDialog,
        chatbotDataObject,
        isChatbotLoading,
    };
}
