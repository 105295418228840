const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function formatBytes(x: any) {
    if (isNaN(x) || x < 0) return 'Invalid input'; // Handle non-numeric or negative inputs
    if (x === 0) return '0 bytes'; // Handle edge case for 0 bytes

    let l = 0,
        n = parseFloat(x); // Using parseFloat to handle decimal input properly

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    // Adding precision formatting
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}
