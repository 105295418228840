import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Stack, Typography } from '@mui/material';
import { StyledToolTip } from '../../../../utilities/styled-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleDetailPermissionSummaryProps {
    rolePermissions: string[];
}

const RoleDetailPermissionSummary: React.FC<IRoleDetailPermissionSummaryProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { rolePermissions } = props;
    return (
        <Grid direction='row' component={'ol'} item container xs={12}>
            <Grid
                role={'presentation'}
                component={'template'}
                className={cx(classes.GridList)}
                item
                container
                xs={12}
                justifyContent='flex-start'
                alignItems='center'
            >
                {rolePermissions.map((permission, index) => (
                    <Grid sm={7} md={6} className={cx(classes.ListItem)} component={'li'} key={permission} item>
                        <StyledToolTip arrow placement={'right'} title={permission}>
                            <Stack width={'fit-content'}>
                                <Typography className={cx(classes.Typography)}>{permission}</Typography>
                            </Stack>
                        </StyledToolTip>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IRoleDetailPermissionSummaryProps>()((theme, props) => ({
    GridList: {
        padding: '2px',
        marginLeft: '1rem',
        // border: '1px solid #D0D5DD',
        borderRadius: '0.5rem',
    },
    Typography: { fontWeight: 500 },
    ListItem: {
        listStyleType: 'disc',
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingRight: '2rem',
        paddingLeft: '2px',
        // border: '1px solid #D0D5DD',
    },
}));

export { RoleDetailPermissionSummary };
