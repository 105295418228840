import { useFlags } from 'launchdarkly-react-client-sdk';
import { ValueOptions } from '@mui/x-data-grid-premium';
import { CloudProvidersTypes } from '@vegaplatformui/models';

export interface IProviderFilterOptionsHook {
    providerOptions: ValueOptions[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProviderFilterOptionsProps {}

export function useProviderFilterOptions(props: IProviderFilterOptionsProps): IProviderFilterOptionsHook {
    const flags = useFlags();

    function getProviderFilterOptions(): ValueOptions[] {
        const providerOptions: ValueOptions[] = [
            { value: CloudProvidersTypes.Aws.toUpperCase(), label: CloudProvidersTypes.Aws.toUpperCase() },
            { value: CloudProvidersTypes.Azure.toUpperCase(), label: CloudProvidersTypes.Azure.toUpperCase() },
            { value: CloudProvidersTypes.Gcp.toUpperCase(), label: CloudProvidersTypes.Gcp.toUpperCase() },
        ];
        if (flags.ociIntegration) {
            providerOptions.push({ value: CloudProvidersTypes.Oci.toUpperCase(), label: CloudProvidersTypes.Oci.toUpperCase() });
        }
        return providerOptions;
    }
    return { providerOptions: getProviderFilterOptions() };
}

export default useProviderFilterOptions;
