import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ITagManagerControllerProps } from './tag-manager-controller';
import { QuicksightReportsController } from '../quicksight-reports-controller/quicksight-reports-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITagComplianceDashboardProps {}

const TagComplianceController: React.FC<ITagManagerControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <QuicksightReportsController folderNames={['tagcompliance']} />;
};

const useStyles = customMakeStyles<ITagComplianceDashboardProps>()((theme, props) => ({}));

export { TagComplianceController };
