import React, { useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, FormHelperText, Grid2, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormField } from '../../../forms';
import { SamlUriUrlCard } from './saml-uri-url-card';
import { regexList, ReplaceUnderscoreWithSpace } from '@vegaplatformui/utils';
import { ISSONameIDPolicyFormat, IUserSettingSSOSamlForm, PostBinding, SignatureAlgorithm, SSOButtonEnum } from '@vegaplatformui/models';
import { SsoSamlFileUpload } from './sso-saml-file-upload';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISSOSamlCreateFormProps {
    nameIDPolicyFormatOptions: ISSONameIDPolicyFormat[];
    onCreate: (data: IUserSettingSSOSamlForm) => void;
    onClose: () => void;
    selectedButton: SSOButtonEnum | null;
}

const validationSchema: yup.ObjectSchema<IUserSettingSSOSamlForm> = yup.object().shape({
    alias: yup
        .string()
        .required('Configuration name is required')
        .matches(/^(\S+$)/g, 'Configuration name cannot have spaces'),
    display_name: yup.string().required('Description is required'),
    config: yup.object().shape({
        signing_certificate: yup.string(),
        idp_entity_id: yup.string().required('Issuer URI is required'),
        single_sign_on_service_url: yup
            .string()
            .matches(regexList.url, 'Single sign-on URL must be a valid URL')
            .required('Single sign-on URL is required'),
        post_binding_authn_request: yup
            .mixed<PostBinding>()
            .transform((v) => (!v ? undefined : v))
            .required('Request binding is required'),
        signature_algorithm: yup
            .mixed<SignatureAlgorithm>()
            .transform((v) => (!v ? undefined : v))
            .required('Response signature algorithm is required'),
    }),
});

const SSOSamlCreateForm: React.FC<ISSOSamlCreateFormProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [t, setT] = useState<SignatureAlgorithm>(SignatureAlgorithm.SHA256);
    const commonStyles = useCommonStyles();

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields },
    } = useForm<IUserSettingSSOSamlForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            alias: '',
            display_name: '',
            config: {
                signing_certificate: '',
                idp_entity_id: '',
                single_sign_on_service_url: '',
                post_binding_authn_request: PostBinding.HTTP_POST,
                signature_algorithm: SignatureAlgorithm.SHA256,
            },
        },
    });

    const onConfigurationSubmit: SubmitHandler<IUserSettingSSOSamlForm> = (data) => {
        props.onCreate(data);
    };

    const onClose = () => {
        props.onClose();
        reset({});
    };

    return (
        <Grid2 container spacing={2}>
            <Grid2 container size={12} direction={'row'} spacing={0.5}>
                <Grid2 size={12}>
                    <Typography variant={'h3'}>{`Create ${
                        props.selectedButton === SSOButtonEnum.SAML ? 'a SAML SSO' : 'an Okta SSO'
                    } configuration`}</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography className={commonStyles.classes.PageCardSubtitle} variant={'body1'}>
                        Fill out the values with the information provided by your identity provider.
                    </Typography>
                </Grid2>
            </Grid2>
            <Grid2
                container
                size={12}
                id={'sso-saml-create-form'}
                component={'form'}
                spacing={2}
                direction={'row'}
                onSubmit={handleSubmit((data) => {
                    onConfigurationSubmit(data);
                })}
            >
                <Grid2 size={12} spacing={2} container>
                    <Grid2 size={9}>
                        <FormField label='Configuration Name' htmlFor='alias'>
                            <Controller
                                name={'alias'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='alias'
                                        label='Enter a name to identify your configuration'
                                        error={!!errors.alias}
                                        value={value}
                                        size={'small'}
                                        helperText={(errors.alias?.message as string) ?? 'Can not be edited after creation'}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                    </Grid2>
                    <Grid2 size={9}>
                        <FormField label='Display Name' htmlFor='display_name'>
                            <Controller
                                name={'display_name'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='display_name'
                                        label='Enter a friendly name for your configuration'
                                        error={!!errors.display_name}
                                        value={value}
                                        size={'small'}
                                        helperText={errors.display_name?.message as string}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                    </Grid2>
                </Grid2>
                <Grid2 size={12} container>
                    <SamlUriUrlCard control={control} errors={errors} setValue={setValue} />
                </Grid2>
                <Grid2 size={12} container spacing={2}>
                    <Grid2 size={9}>
                        <FormField label='Request Binding' htmlFor='config.post_binding_response'>
                            <Grid2 container spacing={0.5}>
                                <Grid2 size={12}>
                                    <FormHelperText>The SAML Authentication Request Protocol binding used to send the AuthNRequest</FormHelperText>
                                </Grid2>
                                <Grid2 size={12}>
                                    <Controller
                                        name={'config.post_binding_authn_request'}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                name={'config.post_binding_authn_request'}
                                                error={!!errors.config?.post_binding_authn_request}
                                                size={'small'}
                                                labelId='config.post_binding_authn_request-label'
                                                id='config.post_binding_authn_request'
                                                fullWidth={true}
                                                value={value}
                                                onChange={(e: SelectChangeEvent<PostBinding>) => {
                                                    onChange(e.target.value as PostBinding);
                                                }}
                                            >
                                                <MenuItem value={PostBinding.HTTP_POST}>{ReplaceUnderscoreWithSpace(PostBinding.HTTP_POST)}</MenuItem>
                                                <MenuItem value={PostBinding.HTTP_REDIRECT}>
                                                    {ReplaceUnderscoreWithSpace(PostBinding.HTTP_REDIRECT)}
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </Grid2>
                            </Grid2>
                        </FormField>
                    </Grid2>
                    <Grid2 size={9}>
                        <FormField label='Response signature algorithm' htmlFor='config.signature_algorithm'>
                            <Grid2 container spacing={0.5}>
                                <Grid2 size={12}>
                                    <FormHelperText>The signature algorithm used to sign the SAML AuthNRequest</FormHelperText>
                                </Grid2>
                                <Grid2 size={12}>
                                    <Controller
                                        name={'config.signature_algorithm'}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                name={'config.signature_algorithm'}
                                                error={!!errors.config?.signature_algorithm}
                                                size={'small'}
                                                labelId='config.signature_algorithm-label'
                                                id='config.signature_algorithm'
                                                fullWidth={true}
                                                value={value}
                                                onChange={(e: SelectChangeEvent<SignatureAlgorithm>) => {
                                                    onChange(e.target.value as SignatureAlgorithm);
                                                }}
                                            >
                                                <MenuItem value={SignatureAlgorithm.SHA256}>{SignatureAlgorithm.SHA256}</MenuItem>
                                                <MenuItem value={SignatureAlgorithm.SHA1}>{SignatureAlgorithm.SHA1}</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </Grid2>
                            </Grid2>
                        </FormField>
                    </Grid2>
                </Grid2>
                <Grid2 size={12} container>
                    <Grid2 size={12}>
                        <SsoSamlFileUpload control={control} errors={errors} setValue={setValue} />
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 container size={12}>
                <Stack direction={'row'} columnGap={2} justifyContent={'end'}>
                    <Button variant='contained' className={commonStyles.classes.GreyButton} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button form={'sso-saml-create-form'} id={'sso-saml-create-form-button'} disabled={!isDirty} variant='contained' type='submit'>
                        Create
                    </Button>
                </Stack>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<ISSOSamlCreateFormProps>()((theme, props) => ({
    CardColor: {
        backgroundColor: theme.palette.grey[50],
        width: '100%',
    },
    PlaceHolderButton: { marginTop: '-5rem' },
    FormHelperConfigText: { marginTop: '-1rem' },
}));

export { SSOSamlCreateForm };
