import React, { ErrorInfo, useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router';
import { ErrorScreen } from './error-screen';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { ApplicationError } from '../jotai/atom';
import { useSetAtom } from 'jotai';

export interface ICustomErrorBoundaryProps extends React.PropsWithChildren {
    menuItems: GeminiMenuItem[];
    setSidebarMenuItems: React.Dispatch<React.SetStateAction<GeminiMenuItem[]>>;
}

// Create a component that will suppress the ownerState warnings
const WarningSupressor: React.FC<React.PropsWithChildren> = ({ children }) => {
    useEffect(() => {
        // Save the original console.error
        const originalConsoleError = console.error;

        // Override console.error to filter out specific warnings
        console.error = function (...args) {
            // Check if this is the ownerState warning
            if (
                args[2] &&
                typeof args[2] === 'string' &&
                args[2].includes('The following props are not supported: `ownerState`') &&
                args[3] &&
                typeof args[3] === 'string' &&
                args[3].includes('ClickAwayListener') &&
                args[3].includes('Snackbar')
            ) {
                // Suppress this specific warning
                return;
            }

            // For all other errors, use the original function
            return originalConsoleError.apply(console, args);
        };

        // Cleanup function to restore the original console.error
        return () => {
            console.error = originalConsoleError;
        };
    }, []);

    return <>{children}</>;
};

const CustomErrorBoundary: React.FC<ICustomErrorBoundaryProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [resetError, setResetError] = React.useState(false);
    const location = useLocation();
    const setApplicationError = useSetAtom(ApplicationError);

    const onError = (error: Error, info: ErrorInfo) => {
        //ToDo log to external service
        setApplicationError(error);
    };

    return (
        <WarningSupressor>
            <ErrorBoundary
                FallbackComponent={(fallbackProps) => (
                    <ErrorScreen menuItems={props.menuItems} setSidebarMenuItems={props.setSidebarMenuItems} {...fallbackProps} />
                )}
                onReset={(props) => {
                    setResetError(false);
                }}
                onError={onError}
                resetKeys={[resetError, location.pathname]}
            >
                {props.children}
            </ErrorBoundary>
        </WarningSupressor>
    );
};

const useStyles = customMakeStyles<ICustomErrorBoundaryProps>()((theme, props) => ({}));

export { CustomErrorBoundary };
