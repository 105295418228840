import { Box, getContrastRatio, ListItemButton, ListItemIcon, MenuItem, Stack, Theme, Tooltip, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from 'react';
import { useCommonDrawerStyles } from './drawer-menu-item-style';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { CollapsibleDrawerItem } from './collapsible-drawer-item';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { ShowUpdatedUi } from '../../recoil/atom';

interface IMainMenuItemItemProps {
    menuItem: GeminiMenuItem;
    title?: string;
    theme: Theme;
    isDrawerOpen: boolean;
    onSelectMenuItem: (menuItem: GeminiMenuItem) => void;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MainMenuItem = (props: IMainMenuItemItemProps) => {
    const hasSubMenuItems = props.menuItem.subMenu && props.menuItem.subMenu.length > 0;
    const [showSubMenu, setShowSubMenu] = React.useState(false);
    const theme = useTheme();
    const [hasSelectedMenu, setHasSelectedMenu] = useState(false);
    const showUpdatedUi = useRecoilValue(ShowUpdatedUi);
    const { cx, classes: commonClasses } = useCommonDrawerStyles({
        isDrawerOpen: props.isDrawerOpen,
        isCollapsible: false,
        theme: theme,
        showUpdatedUi: showUpdatedUi,
    });
    const { classes } = useStyles({ props: props, showUpdatedUi: showUpdatedUi });

    const onClickMenuItem = (event: React.MouseEvent<any, MouseEvent>, menuItem: GeminiMenuItem) => {
        if (menuItem.subMenu && menuItem.subMenu.length > 0) {
            //do nothing clicked on parent
        } else {
            props.onSelectMenuItem(menuItem);
        }
        event.stopPropagation();
    };

    useEffect(() => {
        setHasSelectedMenu(props.menuItem.isSelected);
        props.menuItem &&
            props.menuItem.subMenu &&
            props.menuItem.subMenu.length > 0 &&
            props.menuItem.subMenu.forEach(function iter(item: GeminiMenuItem) {
                if (item.isSelected) {
                    setHasSelectedMenu(true);
                }
                Array.isArray(item.subMenu) && item.subMenu.forEach(iter);
            });
    }, [props.menuItem]);

    return (
        // TODO: remove this outer MenuItem => styling of MenuItem will need to be updated (simplified)
        <MenuItem
            aria-busy={true}
            role={'menu'}
            tabIndex={0}
            key={props.menuItem.title}
            className={cx(
                commonClasses.MenuItem,
                props.menuItem.isSelected && commonClasses.SelectedMenuItem,
                commonClasses.CommonMenuStyling,
                commonClasses.MainMenuItem
            )}
            onClick={(e) => {
                if (!props.isDrawerOpen && hasSubMenuItems) {
                    props.setIsDrawerOpen(!props.isDrawerOpen);
                    setShowSubMenu(!showSubMenu);
                }
                setShowSubMenu(!showSubMenu);
                onClickMenuItem(e, props.menuItem);
            }}
        >
            <>
                <MenuItem
                    className={cx(
                        props.isDrawerOpen ? commonClasses.SuperMenuItemOpen : commonClasses.SuperMenuItemClosed,
                        props.menuItem.isSelected || (hasSelectedMenu && (!props.isDrawerOpen || !showSubMenu))
                            ? commonClasses.SelectedMenuItem
                            : commonClasses.HideSelectedMenuItem,
                        commonClasses.CommonMenuStyling
                    )}
                    aria-label={props.menuItem.title}
                    component={'a'}
                    href={hasSubMenuItems ? undefined : props.menuItem.route}
                    selected={(!hasSubMenuItems && props.menuItem.isSelected) || hasSelectedMenu}
                    onClick={(evt: any) => {
                        evt.preventDefault();
                        hasSubMenuItems ? setShowSubMenu(!showSubMenu) : undefined;
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Tooltip title={props.isDrawerOpen ? '' : props.menuItem.title}>
                            <props.menuItem.icon className={cx(classes.MainMenuIcon)} />
                        </Tooltip>

                        {props.isDrawerOpen && (
                            <Typography
                                color={
                                    props.menuItem.isSelected
                                        ? theme.palette.primary.main
                                        : showUpdatedUi &&
                                            getContrastRatio(theme.palette.grey[200], theme.palette.secondary.main) >= theme.palette.contrastThreshold
                                          ? theme.palette.grey[200]
                                          : theme.palette.grey[700]
                                }
                                variant='body1'
                            >
                                {props.menuItem.title}
                            </Typography>
                        )}
                    </Stack>
                    {props.isDrawerOpen && (
                        <div className={classes.SubMenuButton}>
                            {props.menuItem.subMenu &&
                                props.menuItem.subMenu.length > 0 &&
                                (showSubMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
                        </div>
                    )}
                </MenuItem>
                {hasSubMenuItems && props.isDrawerOpen && showSubMenu && (
                    <Box className={cx(classes.SubMenuBox)}>
                        {props.menuItem?.subMenu?.map((child: any, i: number) =>
                            child.subMenu && child.subMenu.length > 0 ? (
                                <CollapsibleDrawerItem
                                    onClickMenuItem={onClickMenuItem}
                                    key={`${child.title}-${i}`}
                                    isDrawerOpen={props.isDrawerOpen}
                                    theme={theme}
                                    menuItem={child}
                                />
                            ) : (
                                <ListItemButton
                                    onClick={(event) => {
                                        event.preventDefault();
                                        onClickMenuItem(event, child);
                                    }}
                                    key={child.title}
                                    sx={{
                                        width: '100%',
                                        padding: 0,
                                        paddingLeft: '0.3rem',
                                        '&:hover': {
                                            backgroundColor: 'inherit',
                                        },
                                    }}
                                >
                                    <MenuItem
                                        aria-busy={true}
                                        role={'menu'}
                                        component={'a'}
                                        href={child.route}
                                        selected={child.isSelected}
                                        className={cx(commonClasses.SubMenuItem, commonClasses.CommonMenuStyling)}
                                        sx={{
                                            '&.Mui-selected': child.isSelected ? commonClasses.SelectedMenuItem : {},
                                        }}
                                    >
                                        <ListItemIcon>
                                            <child.icon className={cx(commonClasses.SubMenuIcon)} />
                                        </ListItemIcon>
                                        <Stack
                                            className={cx(classes.MainMenuStack)}
                                            justifyContent={'space-between'}
                                            direction='row'
                                            alignItems='center'
                                            spacing={1}
                                        >
                                            <Typography
                                                color={
                                                    child.isSelected
                                                        ? theme.palette.primary.main
                                                        : showUpdatedUi &&
                                                            getContrastRatio(theme.palette.grey[200], theme.palette.secondary.main) >=
                                                                theme.palette.contrastThreshold
                                                          ? theme.palette.grey[200]
                                                          : theme.palette.grey[700]
                                                }
                                                variant='body1'
                                            >
                                                {child.title}
                                            </Typography>
                                            {child.trailingIcon && <child.trailingIcon />}
                                        </Stack>
                                    </MenuItem>
                                </ListItemButton>
                            )
                        )}
                    </Box>
                )}
            </>
        </MenuItem>
    );
};

interface IMainMenuItemStyleProps {
    props: IMainMenuItemItemProps;
    showUpdatedUi: boolean;
}

const useStyles = customMakeStyles<IMainMenuItemStyleProps>()((theme, input) => ({
    SubMenuButton: {
        paddingTop: '0.4rem',
        marginLeft: 'auto',
    },
    SubMenuBox: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'start',
    },

    MainMenuIcon: {
        marginRight: input.props.isDrawerOpen ? '1rem' : undefined,
        // '& .MuiSvgIcon-root': {
        //     color: input.showUpdatedUi &&
        //                                                 getContrastRatio(themeVariant.theme.palette.grey[200], themeVariant.theme.palette.secondary.main) >=
        //                                                     themeVariant.theme.palette.contrastThreshold
        //                                                     ? themeVariant.theme.palette.grey[200] : theme.palette.grey[700],
        // },
    },
    MainMenuStack: { width: input.props.isDrawerOpen ? '16rem' : 'auto' },
}));
