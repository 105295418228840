import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { IUser } from '@vegaplatformui/models';
import { SubmitHandler } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserDeleteDialogV2Props {
    users: IUser[];
    isOpen: boolean;
    onClose: () => void;
    onDelete: (data: IUser[]) => void;
}

const UserDeleteDialogV2: React.FC<IUserDeleteDialogV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const { users, isOpen, onClose, onDelete } = props;

    const onSubmit: SubmitHandler<IUser[]> = (data: IUser[]) => {
        onDelete(data);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth
            aria-labelledby='delete-config-dialog-title'
            aria-describedby='delete-config-form'
        >
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} id='delete-config-dialog-title'>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ErrorOutline color={'error'} />
                    <Typography className={cx(classes.TitleTypography)} variant={'h5'}>
                        Delete{users.length > 1 ? ` ${users.length}  Users` : ' User'}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)} id={'delete-config-form'}>
                <Typography variant='body2'>
                    Are you sure you want to delete {users.length > 1 ? 'these users' : 'this user'}? This action cannot be undone.
                </Typography>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack height={'100%'} direction={'row'} spacing={1.5}>
                    <Button variant='cancel' onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color={'error'} variant='contained' onClick={() => onSubmit(users)}>
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IUserDeleteDialogV2Props>()((theme, props) => ({
    TitleTypography: { fontWeight: 500 },
    DialogStartButton: {
        marginLeft: '0.5rem',
    },
}));

export { UserDeleteDialogV2 };
