import { HttpClient } from './http-common';
import {
    ISSORoleMappingGetRequest,
    ISSORoleMappingGetResponse,
    ISSORoleMappingPostRequest,
    IUserSettingAddressPutRequest,
    IUserSettingBaseRequest,
    IUserSettingProfilePutRequest,
    IUserSettingRoleBaseRequest,
    IUserSettingRolePostRequest,
    IUserSettingSSOBaseRequest,
    IUserSettingUserPostRequest,
    IUserSettingUserRolesGetRequest,
} from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';

export class FederationManagementApi extends HttpClient {
    protected static classInstance?: FederationManagementApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new FederationManagementApi();
        }

        return this.classInstance;
    }

    // SSO
    public getRoleMappings = (): GeminiResponse<ISSORoleMappingGetResponse> => this.instance.get(`/vegaapi/federation/settings/roleMappings`);
    public postRoleMappings = (data: ISSORoleMappingPostRequest): GeminiResponse<any> =>
        this.instance.post(`/vegaapi/federation/settings/roleMappings`, data);
    public putRoleMappings = (data: ISSORoleMappingPostRequest, id: string): GeminiResponse<any> =>
        this.instance.put(`/vegaapi/federation/settings/roleMappings/${id}`, data);

    public deleteRoleMapping = (id: string): GeminiResponse<any> => this.instance.delete(`/vegaapi/federation/settings/roleMappings/${id}`);
}
