import { Box, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useEffect, useState } from 'react';
import { Check, Close } from '@mui/icons-material';

export interface IPasswordStrengthIndicatorProps {
    password: string;
    setIsPasswordStrong: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface strengthCheckList {
    isMinimumLength: boolean;
    isOneUppercaseLetter: boolean;
    isOneLowercaseLetter: boolean;
    isOneNumber: boolean;
    isOneSpecialChar: boolean;
}

const PasswordStrengthIndicator: React.FC<IPasswordStrengthIndicatorProps> = (props) => {
    const { cx, classes } = useStyles();
    const theme = useTheme();

    const checkPasswordStrength = (password: string): strengthCheckList => {
        const atLeastMinimumLength = (password: string) => new RegExp(/(?=.{12,})/).test(password);
        const atLeastOneUppercaseLetter = (password: string) => new RegExp(/(?=.*?[A-Z])/).test(password);
        const atLeastOneLowercaseLetter = (password: string) => new RegExp(/(?=.*?[a-z])/).test(password);
        const atLeastOneNumber = (password: string) => new RegExp(/(?=.*?[0-9])/).test(password);
        const atLeastOneSpecialChar = (password: string) => new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(password);

        let isOneNumber = false;
        let isOneUppercaseLetter = false;
        let isOneLowercaseLetter = false;
        let isMinimumLength = false;
        let isOneSpecialChar = false;

        if (atLeastMinimumLength(password)) {
            isMinimumLength = true;
        }
        if (atLeastOneUppercaseLetter(password)) {
            isOneUppercaseLetter = true;
        }
        if (atLeastOneNumber(password)) {
            isOneNumber = true;
        }
        if (atLeastOneLowercaseLetter(password)) {
            isOneLowercaseLetter = true;
        }
        if (atLeastOneSpecialChar(password)) {
            isOneSpecialChar = true;
        }
        return {
            isMinimumLength: isMinimumLength,
            isOneLowercaseLetter: isOneLowercaseLetter,
            isOneUppercaseLetter: isOneUppercaseLetter,
            isOneNumber: isOneNumber,
            isOneSpecialChar: isOneSpecialChar,
        };
    };

    const getIndicatorColor = (strength: boolean[], theme: Theme): string => {
        if (strength.filter((check) => check).length === 5) {
            return theme.palette.success.main;
        } else if (strength.filter((check) => check).length > 2 && strength.filter((check) => check).length < 5) {
            return theme.palette.warning.main;
        } else {
            return theme.palette.error.main;
        }
    };

    const strength = checkPasswordStrength(props.password);
    useEffect(() => {
        props.setIsPasswordStrong(
            strength.isMinimumLength &&
                strength.isOneLowercaseLetter &&
                strength.isOneUppercaseLetter &&
                strength.isOneNumber &&
                strength.isOneSpecialChar
        );
    }, [strength]);
    const booleanArray = [
        strength.isOneNumber,
        strength.isOneUppercaseLetter,
        strength.isOneLowercaseLetter,
        strength.isMinimumLength,
        strength.isOneSpecialChar,
    ];
    const color = getIndicatorColor(booleanArray, theme);

    return (
        <Stack sx={{ padding: '16px 18px' }} spacing={2}>
            <Typography variant='body1' fontWeight={theme.typography.fontWeightBold}>
                Password Requirements
            </Typography>
            <Stack direction={'column'} spacing={1}>
                <Typography variant={'caption'}>Strength</Typography>
                <Stack direction={'row'} spacing={1}>
                    {booleanArray.map((bool, index) => (
                        <Box
                            key={index}
                            sx={{
                                height: 4,
                                width: '100%',
                                backgroundColor: booleanArray.filter((check) => check).length >= index + 1 ? color : theme.palette.grey.A400,
                            }}
                        />
                    ))}
                </Stack>
            </Stack>
            <Box>
                <Typography>Password should include:</Typography>
                <Grid direction={'column'} container component={'ul'} className={cx(classes.RequirementsList)}>
                    <Grid xs={12} item container direction={'row'} spacing={1} justifyContent='flex-start' alignItems='center'>
                        <Grid item component={'li'}>
                            12 or more characters
                        </Grid>
                        <Grid item>{strength.isMinimumLength ? <Check color={'success'} /> : <Close color={'error'} />}</Grid>
                    </Grid>
                    <Grid xs={12} item container direction={'row'} spacing={1} justifyContent='flex-start' alignItems='center'>
                        <Grid item component={'li'}>
                            Upper & lower case letters
                        </Grid>
                        <Grid item>
                            {strength.isOneLowercaseLetter && strength.isOneUppercaseLetter ? <Check color={'success'} /> : <Close color={'error'} />}
                        </Grid>
                    </Grid>
                    <Grid xs={12} item container direction={'row'} spacing={1} justifyContent='flex-start' alignItems='center'>
                        <Grid item component={'li'}>
                            One digit
                        </Grid>
                        <Grid item>
                            <Grid item>{strength.isOneNumber ? <Check color={'success'} /> : <Close color={'error'} />}</Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} item container direction={'row'} spacing={1} justifyContent='flex-start' alignItems='center'>
                        <Grid item component={'li'}>
                            One special character
                        </Grid>
                        <Grid item>{strength.isOneSpecialChar ? <Check color={'success'} /> : <Close color={'error'} />}</Grid>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
};

const useStyles = customMakeStyles()((theme) => ({
    RequirementsList: {
        width: '18rem',
        margin: 0,
    },
}));

export { PasswordStrengthIndicator };
