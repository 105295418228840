import React, { useEffect, useRef } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import { IBusinessGrouping, IUser } from '@vegaplatformui/models';
import { GridActionsCellItem, GridPagination, GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { CustomDataGridRenderCellButtonRef } from '../../../utilities/custom-data-grid-render-cell-button';
import { useKeycloak } from '@react-keycloak-fork/web';
import { CustomGridColSingleSelectOperatorDef, CustomGridColStringOperatorDef } from '../../../utilities/custom-grid-col-string-operator-def';
import dayjs from 'dayjs';
import { IUseBusinessGroupingsApiHook } from '../../../api-hooks/business-groupings-hooks/use-business-groupings-api';
import { useTableUtilities } from '../../../use-table-utilities/use-table-utilities';
import { UserAvatarNameCell } from '../../../utilities/user-avatar-name-cell';
import { ExpandableRolesCell } from '../../../utilities/expandable-roles-cell';
import { ExpandableBusinessGroupingCell } from '../../../utilities/expandable-business-grouping-cell';
import { DataGridCellTooltip } from '../../../utilities/datagrid-cell-tooltip';
import { DataGridCustomToolbar, DataGridCustomToolbarProps } from '../../../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { tableIdentifierKeys } from '../../../use-table-utilities/table-identifier-keys';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUsersSettingsTableProps {
    usersToDelete: IUser[];
    users: IUser[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
    isLoading: boolean;
    setIsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setUserToEdit: React.Dispatch<React.SetStateAction<IUser | undefined>>;
    setUsersToDelete: React.Dispatch<React.SetStateAction<IUser[]>>;
    businessGroupingApi: IUseBusinessGroupingsApiHook;
}

const UsersSettingsTableV2: React.FC<IUsersSettingsTableProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const {
        usersToDelete,
        users,
        setSelectedUsers,
        isLoading,
        setIsFormOpen,
        setIsDeleteOpen,
        setUserToEdit,
        setUsersToDelete,
        businessGroupingApi,
    } = props;
    const userSettingsTableUtilities = useTableUtilities(tableIdentifierKeys.userSettingsTableV2);
    const { keycloak } = useKeycloak();
    const theme = useTheme();
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);

    React.useEffect(() => {
        userSettingsTableUtilities.registerTableControl({ email: false });
    }, []);

    useEffect(() => {
        setSelectionModel([...new Set([...selectionModel, ...(usersToDelete.map((user) => user.id) ?? [])])]);
    }, [usersToDelete]);

    const onClickEditUser = (user: IUser) => {
        setIsFormOpen(true);
        setUserToEdit(user);
        businessGroupingApi.setUserId(user.id);
    };

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'User',
            flex: 2.5,
            display: 'flex',
            valueGetter: (value: string, row: IUser) => {
                return `${row.given_name} ${row.family_name}`;
            },
            renderCell: (params: GridRenderCellParams<IUser>) => <UserAvatarNameCell params={params} onClickEditUser={onClickEditUser} />,
        },
        {
            ...CustomGridColStringOperatorDef,
            disableExport: true,
            field: 'email',
            flex: 1,
            headerName: 'Email',
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'platform_roles',
            headerName: 'Role',
            display: 'flex',
            flex: 3.2,
            valueGetter: (value: string[], row: IUser) => (row.platform_roles !== null ? row.platform_roles?.join(', ') : row.platform_roles),
            renderCell: (params: GridRenderCellParams<IUser>) => {
                return <ExpandableRolesCell roles={params.row.platform_roles} tabIndex={params.tabIndex} />;
            },
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'emailVerified',
            headerName: 'Status',
            display: 'flex',
            flex: 1,
            renderCell: (params: GridRenderCellParams<IUser>) => (
                <span>
                    <Chip
                        variant={'outlined'}
                        sx={{
                            color: `${params.value ? theme.palette.success.dark : theme.palette.warning.dark}`,
                            borderColor: `${params.value ? theme.palette.success.main : theme.palette.warning.main}`,
                        }}
                        size={'small'}
                        label={<Typography variant={'caption'}>{`${params.value ? 'Active' : 'Invited'}`}</Typography>}
                    />
                </span>
            ),
            type: 'singleSelect',
            valueOptions: [
                { label: 'Active', value: true },
                { label: 'Invited', value: false },
            ],
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'business_groupings',
            headerName: 'Business Groupings',
            display: 'flex',
            flex: 2,
            valueGetter: (value: IBusinessGrouping, row: IUser) =>
                row.business_groupings !== null
                    ? row.business_groupings.map((bg: IBusinessGrouping) => bg.display_name).join(', ')
                    : row.business_groupings,
            renderCell: (params: GridRenderCellParams<IUser>) => {
                const bgs = params.row.business_groupings.map((bg: IBusinessGrouping) => {
                    return { id: bg.id, name: bg.display_name };
                });
                return <ExpandableBusinessGroupingCell businessGroupings={bgs} tabIndex={params.tabIndex} limit={1} />;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'created_at',
            headerName: 'Date Added',
            flex: 1.5,
            valueFormatter: (value: string, row: IUser) => {
                if (!value) {
                    return '';
                }
                return dayjs(value).format('L');
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            type: 'actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            disableExport: true,
            flex: 1,
            align: 'center',
            headerName: 'Actions',
            getActions: (params: GridRowParams<IUser>) => [
                <GridActionsCellItem
                    label={'Edit'}
                    showInMenu
                    aria-label='edit user'
                    onClick={() => {
                        onClickEditUser(params.row);
                    }}
                />,
                <GridActionsCellItem
                    showInMenu
                    aria-label='delete user'
                    label={'Delete'}
                    disabled={params.row.email === (keycloak.tokenParsed && keycloak.tokenParsed.email)}
                    onClick={() => {
                        setIsDeleteOpen(true);
                        setUsersToDelete([params.row]);
                    }}
                />,
            ],
        },
    ];

    columns.map((column) => {
        if (!column.renderCell && column.field !== 'actions') column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        setSelectedUsers(gridSelectionModel.map((id) => users?.find((user) => user.id === id) as IUser));
    };

    return (
        <Box height={'auto'} style={{ display: 'flex', flexDirection: 'column' }}>
            <DataGridPremium
                className={
                    users.length > 0
                        ? cx(commonStyles.classes.MuiDataGridAutoRowSize)
                        : cx(commonStyles.classes.MuiDataGridAutoRowSize, commonStyles.classes.MuiDataGridMinHeightLoader)
                }
                isRowSelectable={(params: GridRowParams<IUser>) => params.row.email !== (keycloak.tokenParsed && keycloak.tokenParsed.email)}
                getEstimatedRowHeight={() => 80}
                getRowHeight={() => 'auto'}
                initialState={{
                    columns: {
                        columnVisibilityModel: { email: false },
                    },
                }}
                loading={isLoading}
                columns={columns}
                rows={users}
                filterMode={'client'}
                paginationMode={'client'}
                sortingMode={'client'}
                rowSelectionModel={selectionModel}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                checkboxSelectionVisibleOnly
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    pagination: {
                        showLastButton: true,
                        showFirstButton: true,
                        slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                    },
                    toolbar: {
                        tableIdentifier: tableIdentifierKeys.userSettingsTableV2,
                        defaultHidden: { email: false },
                    } as DataGridCustomToolbarProps,
                    columnsManagement: {
                        getTogglableColumns,
                    },
                    noRowsOverlay: {
                        action: 'inviting a user',
                    },
                }}
                pagination
                density={userSettingsTableUtilities.currentTableControl?.density ?? 'standard'}
                onDensityChange={userSettingsTableUtilities.onDensityChange}
                sortModel={userSettingsTableUtilities.currentTableControl?.sortModel}
                onSortModelChange={userSettingsTableUtilities.onSortModelChange}
                filterModel={userSettingsTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={userSettingsTableUtilities.onFilterModelChange}
                paginationModel={userSettingsTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={userSettingsTableUtilities.onPaginationModelChange}
                columnVisibilityModel={userSettingsTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={userSettingsTableUtilities.onColumnVisibilityModelChange}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IUsersSettingsTableProps>()((theme, props) => ({}));

export { UsersSettingsTableV2 };
