import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Card, CardContent, Grid2, Typography } from '@mui/material';
import { samePageLinkNavigation, useRouteUrls } from '@vegaplatformui/utils';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';
import { IUser, IUserSettingRealmRole, permissionDetailTabs, PermissionMetadata } from '@vegaplatformui/models';
import { RoleDetailsTabs } from './role-details-tabs';
import { QuicksightReportLoadingSkeleton } from '../../../../quicksight-reports/quicksight-report-loading-skeleton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleDetailsCardProps {
    isLoading: boolean;
    setRoleId: React.Dispatch<React.SetStateAction<string | undefined>>;
    realmRole?: IUserSettingRealmRole;
    areUsersLoading: boolean;
    members: IUser[];
    rolePermissions: string[];
    permissionSets: Map<string, PermissionMetadata[]>;
}

const RoleDetailsCard: React.FC<IRoleDetailsCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const navigate = useNavigate();
    const { routeUrls } = useRouteUrls({});
    const { permissiontab, roleid } = useParams();
    const [permissionDetailTab, setPermissionDetailTab] = useState<string>('members');

    const { isLoading, setRoleId, realmRole, areUsersLoading, members, rolePermissions, permissionSets } = props;

    const onTabChange = (event: React.SyntheticEvent, newTab: string) => {
        // make sure to only change tabs if selection was only left click
        if (event.type !== 'click' || (event.type === 'click' && samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))) {
            setPermissionDetailTab(newTab);
        }
    };

    //Make sure to set tab if the detail page is opened in new window or browser refreshed
    useEffect(() => {
        const permissionsId = routeUrls.settings.tabs.find((tab) => tab.id === 'permissions')?.id;
        if (roleid && permissionsId && permissiontab) {
            if (!permissionDetailTabs) return;
            if (permissiontab && permissionDetailTabs.some((t) => permissiontab === t.id)) {
                setPermissionDetailTab(permissiontab);
            } else {
                setPermissionDetailTab(permissionDetailTabs![0].id);
                navigate(`/${routeUrls.settings.route.path}/permissions/${roleid}/${permissionDetailTabs![0].id}`);
            }
        }
    }, []);

    return (
        <Card className={cx(classes.Card)} elevation={0}>
            {isLoading || !realmRole ? (
                <QuicksightReportLoadingSkeleton />
            ) : (
                <CardContent className={cx(classes.CardContent)}>
                    <Grid2
                        className={cx(classes.ContentGrid)}
                        spacing={'1.5rem'}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid2 size={4}>
                            <Button
                                onClick={() => {
                                    setRoleId('');
                                    navigate(`/${routeUrls.settings.route.path}/permissions`);
                                }}
                                variant='text'
                                startIcon={<ArrowBack className={cx(classes.BackArrowColor)} />}
                            >
                                <Typography className={cx(classes.BackArrowColor)} variant={'body1'}>
                                    Back to roles and permissions
                                </Typography>
                            </Button>
                        </Grid2>
                        <Grid2 container size={12} spacing={0}>
                            <Grid2 size={12}>
                                <Typography variant={'h5'}>{realmRole.name}</Typography>
                            </Grid2>
                            <Grid2 size={12}>
                                <Typography className={cx(classes.SubtitleTypography)} variant={'subtitle1'}>
                                    {realmRole.description}
                                </Typography>
                            </Grid2>
                        </Grid2>
                        <Grid2 container size={12}>
                            <RoleDetailsTabs
                                permissionDetailTabs={permissionDetailTabs}
                                permissionDetailTab={permissionDetailTab}
                                onTabChange={onTabChange}
                                areUsersLoading={areUsersLoading}
                                members={members}
                                realmRole={realmRole}
                                rolePermissions={rolePermissions}
                                permissionSets={permissionSets}
                            />
                        </Grid2>
                    </Grid2>
                </CardContent>
            )}
        </Card>
    );
};

const useStyles = customMakeStyles<IRoleDetailsCardProps>()((theme, props) => ({
    Card: {
        minHeight: '100%',
    },
    SubtitleTypography: { color: theme.palette.text.secondary },
    BackArrowColor: {
        fill: theme.palette.primary.main,
    },
    CardContent: { padding: '1.5rem' },
    ContentGrid: { maxWidth: '1224px' },
}));

export { RoleDetailsCard };
