// eslint-disable-next-line @typescript-eslint/no-empty-interface
import { ISendSupportHookObject, SupportEmailRecipient } from '@vegaplatformui/models';
import { SetStateAction, useMemo } from 'react';
import { VegaApi } from '@vegaplatformui/apis';
import { useKeycloak } from '@react-keycloak-fork/web';
import { SnackBarOptions } from '../jotai/atom';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export interface IUseVegaApiHook {
    isEmailSending: boolean;
    sendSupportEmail: UseMutateFunction<any, unknown, ISendSupportHookObject, unknown>;
}

export interface IUseVegaApiProps {
    setShowContactSupport: (
        update:
            | { showSupportForm: boolean; contactType: SupportEmailRecipient }
            | ((prev: { showSupportForm: boolean; contactType: SupportEmailRecipient }) => {
                  showSupportForm: boolean;
                  contactType: SupportEmailRecipient;
              })
    ) => void;
    setApplicationError: (update: Error | undefined | ((prev: Error | undefined) => Error | undefined)) => void;
    setSelectedTopic: React.Dispatch<React.SetStateAction<string>>;
}

export function useVegaApi(props: IUseVegaApiProps): IUseVegaApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);

    const vegaApi = useMemo(() => {
        const vegaApi = new VegaApi();
        vegaApi.keycloak = keycloak ?? undefined;
        return vegaApi;
    }, [keycloak]);

    const { isPending: isEmailSending, mutate: sendSupportEmail } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message:
                    variables.recipient === SupportEmailRecipient.CloudHero
                        ? `There was a problem contacting a cloud hero specialist: ${SnackbarErrorOutput(error ?? '')}`
                        : `There was a problem contacting support:  ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message:
                    variables.recipient === SupportEmailRecipient.CloudHero
                        ? 'Thank you for contacting a cloud hero specialist!'
                        : 'Thank you for contacting support!',
            });
        },
        onSettled: (data, error, variables, context) => {
            props.setShowContactSupport({ showSupportForm: false, contactType: SupportEmailRecipient.Support });
            props.setApplicationError(undefined);
            variables.recipient === SupportEmailRecipient.Support && props.setSelectedTopic('default');
        },
        mutationFn: async (hookRequest: ISendSupportHookObject) => {
            const response = await vegaApi.sendSupportEmail(hookRequest.request, hookRequest.recipient);
            return response.data;
        },
    });

    return {
        isEmailSending: isEmailSending,
        sendSupportEmail: sendSupportEmail,
    };
}

export default useVegaApi;
