import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { GridActionsCellItem, GridPagination, GridRowSelectionModel, GridSlotsComponent, GridToolbarContainer } from '@mui/x-data-grid';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { IFile } from '@vegaplatformui/models';
import { Delete } from '@mui/icons-material';
import { Box } from '@mui/material';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { CustomGridColStringOperatorDef } from '../utilities/custom-grid-col-string-operator-def';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { DataGridCustomToolbar, DataGridCustomToolbarProps } from '../utilities/datagrid-custom-toolbar';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';
import { formatBytes } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFileTransferHistoryTableProps {
    selectedFiles: IFile[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<IFile[]>>;
    isLoading: boolean;
    onOpenDeleteFileDialog(file: IFile): void;
    historyFiles: IFile[];
}

const FileTransferHistoryTable: React.FC<IFileTransferHistoryTableProps> = (props) => {
    const localStyles = useStyles(props);
    const commonStyles = useCommonStyles();
    const fileTransferTableUtilities = useTableUtilities(tableIdentifierKeys.fileTransferHistoryTable);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

    useEffect(() => {
        fileTransferTableUtilities.registerTableControl();
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter className={commonStyles.classes.DataGridToolBarFilter} />
            </GridToolbarContainer>
        );
    }

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'filename',
            headerName: 'File Name',
            flex: 1,
            renderCell: (params: GridRenderCellParams<IFile>) => params.row.filename.split('/')[1],
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'lastmodified',
            headerName: 'Date Added',
            flex: 1,
            valueFormatter: (value: number, row: IFile) =>
                Intl.DateTimeFormat('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }).format(value * 1000),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'size',
            headerName: 'File Size',
            flex: 1,
            valueFormatter: (value: number, row: IFile) => formatBytes(Number(value)),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 0.5,
            getActions: (params: GridRowParams<IFile>) => [
                <StyledToolTip arrow title='Delete'>
                    <GridActionsCellItem
                        icon={<Delete />}
                        label={'Delete'}
                        aria-label={props.selectedFiles.length > 1 ? 'Unselect rows to continue' : `Delete ${params.row.filename}`}
                        disabled={props.selectedFiles.length > 1}
                        onClick={() => props.onOpenDeleteFileDialog(params.row)}
                        size='small'
                    />
                </StyledToolTip>,
            ],
        },
    ];

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        props.setSelectedFiles(gridSelectionModel.map((id) => props.historyFiles.find((file) => file.id === id) as IFile));
    };

    return (
        <Box>
            <DataGridPremium
                autoHeight={true}
                pagination={true}
                columns={columns}
                rows={props.historyFiles ?? []}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                    pagination: GridPagination,
                }}
                slotProps={{
                    columnsManagement: { getTogglableColumns },
                    pagination: {
                        showLastButton: true,
                        showFirstButton: true,
                        slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                    },
                    toolbar: { tableIdentifier: tableIdentifierKeys.fileTransferHistoryTable } as DataGridCustomToolbarProps,
                }}
                density={fileTransferTableUtilities.currentTableControl?.density}
                onDensityChange={fileTransferTableUtilities.onDensityChange}
                paginationModel={fileTransferTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={fileTransferTableUtilities.onPaginationModelChange}
                filterModel={fileTransferTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={fileTransferTableUtilities.onFilterModelChange}
                columnVisibilityModel={fileTransferTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={fileTransferTableUtilities.onColumnVisibilityModelChange}
                loading={props.isLoading}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IFileTransferHistoryTableProps>()((theme, props) => ({}));

export { FileTransferHistoryTable };
