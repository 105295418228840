import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SettingsContentHeader } from '../settings-content-header';
import { Grid, Stack, Typography } from '@mui/material';
import { SettingsV2Divider } from '../settings-v2-divider';
import { SettingsDetailTile } from '../settings-detail-tile';
import { ILogoVariation, IOrganizationThemeColors, IOrgData, IUser } from '@vegaplatformui/models';
import { OrganizationDetailsInput } from './organization-details-input';
import { OrganizationLogoInput } from './organization-logo-input';
import { OrganizationThemeInput } from './organization-theme-input';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationSettingsV2Props {
    allUsers: IUser[];
    createOrganizationTheme: (orgId: string, theme: IOrganizationThemeColors) => void;
    isLoading: boolean;
    onOrgDataUpdate: (data: IOrgData) => void;
    onPhotoRemove: (orgId: string, variation: ILogoVariation) => void;
    onPhotoUpload: (orgId: string, variation: ILogoVariation, file: File) => void;
    orgName: string;
    orgOwner?: IUser;
    orgSlug?: string;
    orgUpdateStatus: string;
    themeCreateStatus: string;
    themeUpdateStatus: string;
    updateOrganizationTheme: (orgId: string, theme: IOrganizationThemeColors) => void;
}

const OrganizationSettingsV2: React.FC<IOrganizationSettingsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid container spacing={2}>
            <SettingsContentHeader title={'Organization Details'} subtitle={'Manage organization information.'} />
            <SettingsV2Divider />
            <SettingsDetailTile
                title={'General'}
                children={
                    <OrganizationDetailsInput
                        onOrgDataUpdate={props.onOrgDataUpdate}
                        orgName={props.orgName}
                        orgOwner={props.orgOwner}
                        orgSlug={props.orgSlug}
                        allUsers={props.allUsers}
                        orgUpdateStatus={props.orgUpdateStatus}
                    />
                }
            />
            <SettingsV2Divider />
            <SettingsContentHeader title={'Branding'} />
            <SettingsDetailTile
                title={'Logo'}
                subtitle={
                    <Stack>
                        <Typography variant={'inherit'}>Customize your own logos in the platform.</Typography>
                        <Typography variant={'inherit'}>File types: .png, .jpg, .jpeg, .ico.</Typography>
                    </Stack>
                }
                children={<OrganizationLogoInput onPhotoUpload={props.onPhotoUpload} onPhotoRemove={props.onPhotoRemove} />}
            />
            <SettingsDetailTile
                title={'Theme'}
                subtitle={'Customize your colors in the platform.'}
                children={
                    <OrganizationThemeInput
                        updateOrganizationTheme={props.updateOrganizationTheme}
                        themeCreateStatus={props.themeCreateStatus}
                        themeUpdateStatus={props.themeUpdateStatus}
                        createOrganizationTheme={props.createOrganizationTheme}
                    />
                }
            />
        </Grid>
    );
};

const useStyles = customMakeStyles<IOrganizationSettingsV2Props>()((theme, props) => ({}));

export { OrganizationSettingsV2 };
