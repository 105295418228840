import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { regexList } from '@vegaplatformui/utils';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IBusinessGrouping, IPutUserV2HookRequest, IUserSettingProfile } from '@vegaplatformui/models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRecoilValue } from 'recoil';
import { UserId } from '../../../recoil/atom';
import { FormField } from '../../../forms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileGeneralInputsProps {
    isLoading: boolean;
    profile: IUserSettingProfile;
    onProfileUpdate: (data: IPutUserV2HookRequest) => void;
}

const validationProfileDetailsSchema: yup.ObjectSchema<IUserSettingProfile> = yup.object().shape({
    first_name: yup
        .string()
        .required('Required')
        .matches(regexList.alphanumeric_contains_space_and_hyphen, 'Must start and end with a letter or number, and can contain hyphens or spaces.'),
    last_name: yup
        .string()
        .required('Required')
        .matches(regexList.alphanumeric_contains_space_and_hyphen, 'Must start and end with a letter or number, and can contain hyphens or spaces.'),
    email: yup.string().required('Required').matches(regexList.email, 'Invalid email address.'),
    phone: yup.string().matches(regexList.phone, 'Invalid phone number format. Please enter a 10-digit phone number, such as 2342813454'),
    username: yup.string().required(),
    image: yup.string(),
    business_groupings: yup.array<IBusinessGrouping[]>(),
});

const ProfileGeneralInputs: React.FC<IProfileGeneralInputsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isProfileFieldsChanged, setisProfileFieldsChanged] = useState(false);
    const currentUserId = useRecoilValue(UserId);

    const {
        handleSubmit: handleDetailsSubmit,
        control: detailsControl,
        reset: detailsReset,
        formState: detailsFormState,
        formState: { isDirty: isDetailsDirty, errors: detailsErrors, isSubmitSuccessful: isDetailsSubmitSuccessful },
    } = useForm<IUserSettingProfile>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationProfileDetailsSchema),
        defaultValues: {
            first_name: props.profile.first_name ?? '',
            last_name: props.profile.last_name ?? '',
            email: props.profile.email ?? '',
            phone: props.profile.phone ?? '',
            username: props.profile.email ?? '',
        },
    });

    const onProfileSubmit: SubmitHandler<IUserSettingProfile> = (data) => {
        setisProfileFieldsChanged(false);
        if (currentUserId) {
            props.onProfileUpdate({
                request: { family_name: data.last_name, given_name: data.first_name, mobile_phone: data.phone },
                user_id: currentUserId,
            });
        }
    };

    const onProfileChange = () => {
        setisProfileFieldsChanged(true);
    };

    useEffect(() => {
        if (props.profile !== undefined) {
            detailsReset(
                {
                    first_name: props.profile.first_name ?? '',
                    last_name: props.profile.last_name ?? '',
                    email: props.profile.email ?? '',
                    phone: props.profile.phone ?? '',
                    username: props.profile.email ?? '',
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [props.profile]);

    return (
        <Grid
            id={'profile-details-v2-form'}
            component={'form'}
            item
            container
            spacing={2}
            onSubmit={handleDetailsSubmit((data) => {
                onProfileSubmit(data);
            })}
            onChange={onProfileChange}
        >
            <Grid item xs={12} sm={6}>
                <FormField label={'First Name'} htmlFor={'first_name'}>
                    <Controller
                        name={'first_name'}
                        control={detailsControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'first_name'}
                                size='small'
                                fullWidth={true}
                                value={value}
                                placeholder={'First Name'}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!detailsErrors['first_name']}
                                helperText={detailsErrors['first_name']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormField label={'Last Name'} htmlFor={'last_name'}>
                    <Controller
                        name={'last_name'}
                        control={detailsControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'last_name'}
                                size='small'
                                fullWidth={true}
                                value={value}
                                placeholder={'Last Name'}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!detailsErrors['last_name']}
                                helperText={detailsErrors['last_name']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12}>
                <FormField label={'Email Address'} htmlFor={'email'}>
                    <Controller
                        name={'email'}
                        control={detailsControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'email'}
                                size='small'
                                fullWidth={true}
                                inputProps={{ readOnly: true }}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!detailsErrors['email']}
                                helperText={detailsErrors['email']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12}>
                <FormField label={'Phone Number'} htmlFor={'phone'}>
                    <Controller
                        name={'phone'}
                        control={detailsControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'phone'}
                                size='small'
                                fullWidth={true}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!detailsErrors['phone']}
                                helperText={detailsErrors['phone']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12}>
                <Stack spacing={2} direction={'row'} justifyContent={'flex-start'}>
                    <Button
                        variant='contained'
                        disabled={!isDetailsDirty || !isProfileFieldsChanged}
                        type='submit'
                        form={'profile-details-v2-form'}
                        disableElevation
                    >
                        Save
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IProfileGeneralInputsProps>()((theme, props) => ({}));

export { ProfileGeneralInputs };
