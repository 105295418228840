import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { GeminiResponse } from '@vegaplatformui/utils';
import { IClient, IGetClientRegistrationsResponse, INewClient, INewClientRegistrationResponse } from '@vegaplatformui/models';

export class ClientRegistrationApi extends HttpClient {
    protected static classInstance?: ClientRegistrationApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ClientRegistrationApi();
        }
        return this.classInstance;
    }

    public createApiClientRegistration = (payload: INewClient): GeminiResponse<INewClientRegistrationResponse> =>
        this.instance.post(`identity/clientregistration/clients`, payload);

    public getRegisteredClients = (userId: string): GeminiResponse<IGetClientRegistrationsResponse> =>
        this.instance.get(`identity/clientregistration/clients/${userId}`);

    public getExpirationOptions = (): GeminiResponse<any> => this.instance.get(`identity/clientregistration/expiration-duration-mappings`);

    public deleteClientRegistration = (clientId: string): GeminiResponse<any> =>
        this.instance.delete(`identity/clientregistration/clients/${clientId}`);
}
