import { PaletteMode } from '@mui/material';
import { atom } from 'recoil';
import { IVegaPageTitle } from '@vegaplatformui/sharedassets';
import { IVegaContainer } from '@vegaplatformui/models';

export const themeState = atom<PaletteMode>({
    key: 'themeState',
    default: 'light',
});

export const menuExpandState = atom({
    key: 'menuExpandState',
    default: true,
});

export const isLoading = atom({
    key: 'loading',
    default: false,
});

export const pageWrapperMargin = atom({
    key: 'pageWrapperMargin',
    default: '',
});

export const authenticationState = atom({
    key: 'authenticationState',
    default: false,
});

export const spacesState = atom<IVegaContainer[]>({
    key: 'spacesState',
    default: [],
});

export const vegaPageTitleState = atom<IVegaPageTitle>({
    key: 'vegaPageTitleState',
    default: {
        title: 'Efficient Cloud Management Services | Vegacloud | United States',
        subTitle: '',
    },
});
