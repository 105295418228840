import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { IUserSettingSSOSaml, IUserSettingSSOSamlForm, PostBinding, SignatureAlgorithm, SSOTypeEnum } from '@vegaplatformui/models';
import * as yup from 'yup';
import { regexList, ReplaceUnderscoreWithSpace } from '@vegaplatformui/utils';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormField } from '../../../forms/form-field';
import { SnackBarOptions } from '../../../jotai/atom';
import { SsoSamlFileUpload } from '../../sso/sso-saml/sso-saml-file-upload';
import { SsoSamlFileUploadEdit } from '../../sso/sso-saml/sso-saml-file-upload-edit';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Close, ContentCopy, ExpandMore } from '@mui/icons-material';
import { SsoPlaceHolderInfoAlert } from './sso-place-holder-info-alert';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoConfigurationDialogProps {
    isOpen: boolean;
    configToEdit: IUserSettingSSOSaml | undefined;
    onClose: () => void;
    onSubmit: (data: IUserSettingSSOSamlForm) => void;
}

const validationSchema: yup.ObjectSchema<IUserSettingSSOSamlForm> = yup.object().shape({
    alias: yup
        .string()
        .required('Configuration name is required')
        .matches(/^(\S+$)/g, 'Configuration name cannot have spaces'),
    display_name: yup.string().required('Description is required'),
    config: yup.object().shape({
        signing_certificate: yup.string(),
        idp_entity_id: yup.string().required('Issuer URI is required'),
        single_sign_on_service_url: yup
            .string()
            .matches(regexList.url, 'Single sign-on URL must be a valid URL')
            .required('Single sign-on URL is required'),
        post_binding_authn_request: yup
            .mixed<PostBinding>()
            .transform((v) => (!v ? undefined : v))
            .required('Request binding is required'),
        signature_algorithm: yup
            .mixed<SignatureAlgorithm>()
            .transform((v) => (!v ? undefined : v))
            .required('Response signature algorithm is required'),
    }),
});

const SsoConfigurationDrawer: React.FC<ISsoConfigurationDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const { configToEdit, onSubmit, isOpen, onClose } = props;
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState,
        formState: { errors, isSubmitSuccessful },
    } = useForm<IUserSettingSSOSamlForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            alias: (configToEdit && configToEdit.alias) ?? '',
            display_name: configToEdit?.display_name ?? '',
            config: {
                signing_certificate: configToEdit?.config.signing_certificate ?? '',
                idp_entity_id: configToEdit?.config.idp_entity_id ?? '',
                single_sign_on_service_url: configToEdit?.config.single_sign_on_service_url ?? '',
                post_binding_authn_request:
                    configToEdit?.config.post_binding_authn_request === PostBinding.true
                        ? PostBinding.HTTP_POST
                        : configToEdit?.config.post_binding_authn_request === PostBinding.false
                        ? PostBinding.HTTP_REDIRECT
                        : configToEdit?.config.post_binding_authn_request ?? PostBinding.HTTP_POST,
                signature_algorithm:
                    configToEdit?.config.signature_algorithm === SignatureAlgorithm.RSASHA1
                        ? SignatureAlgorithm.SHA1
                        : configToEdit?.config.signature_algorithm === SignatureAlgorithm.RSASHA256
                        ? SignatureAlgorithm.SHA256
                        : configToEdit?.config.signature_algorithm ?? SignatureAlgorithm.SHA256,
                idp_sso_audience_restriction: configToEdit?.config.idp_sso_audience_restriction ?? '',
                idp_sso_redirect_url: configToEdit?.config.idp_sso_redirect_url ?? '',
            },
        },
    });

    useEffect(() => {
        if (configToEdit !== undefined) {
            reset(
                {
                    alias: (configToEdit && configToEdit.alias) ?? '',
                    display_name: configToEdit?.display_name ?? '',
                    config: {
                        signing_certificate: configToEdit?.config.signing_certificate ?? '',
                        idp_entity_id: configToEdit?.config.idp_entity_id ?? '',
                        single_sign_on_service_url: configToEdit?.config.single_sign_on_service_url ?? '',
                        post_binding_authn_request:
                            configToEdit?.config.post_binding_authn_request === PostBinding.true
                                ? PostBinding.HTTP_POST
                                : configToEdit?.config.post_binding_authn_request === PostBinding.false
                                ? PostBinding.HTTP_REDIRECT
                                : configToEdit?.config.post_binding_authn_request ?? PostBinding.HTTP_POST,
                        signature_algorithm:
                            configToEdit?.config.signature_algorithm === SignatureAlgorithm.RSASHA1
                                ? SignatureAlgorithm.SHA1
                                : configToEdit?.config.signature_algorithm === SignatureAlgorithm.RSASHA256
                                ? SignatureAlgorithm.SHA256
                                : configToEdit?.config.signature_algorithm ?? SignatureAlgorithm.SHA256,
                        idp_sso_audience_restriction: configToEdit?.config.idp_sso_audience_restriction ?? '',
                        idp_sso_redirect_url: configToEdit?.config.idp_sso_redirect_url ?? '',
                    },
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [configToEdit]);

    const onCloseDrawerAndResetState = () => {
        reset({});
        onClose();
    };

    const onSubmitForm: SubmitHandler<IUserSettingSSOSamlForm> = (data: IUserSettingSSOSamlForm) => {
        onSubmit(data);
    };

    const handleCopy = (settingCopied: string, stringToCopy: string) => {
        navigator.clipboard
            .writeText(stringToCopy)
            .then(
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'success' },
                        message: `${settingCopied} copied to clipboard`,
                    });
                },
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'error' },
                        message: `Failed to copy ${settingCopied} to clipboard`,
                    });
                }
            )
            .catch(() => {
                alert(`Failed to copy ${settingCopied} to clipboard`);
            });
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={isOpen}
            onClose={onCloseDrawerAndResetState}
            aria-labelledby='sso-config-drawer'
            id={'sso-config-drawer'}
        >
            <DialogTitle variant={'h5'} id='sso-config-drawer-title'>
                <Stack direction={'column'} spacing={0}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction={'row'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                            {configToEdit ? `${configToEdit.display_name}` : 'Create a new SSO Configuration'}
                        </Stack>
                        <IconButton
                            aria-label={'Close SSO Configuration Drawer'}
                            id={'sso-config-drawer-close'}
                            className={cx(classes.CloseButton)}
                            onClick={onCloseDrawerAndResetState}
                        >
                            <Close className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction={'column'} className={cx(classes.DrawerContainer)} justifyContent='flex-start' alignItems='flex-start' spacing={2}>
                    {!configToEdit && (
                        <Stack>
                            <Typography>Select a method for your new configuration</Typography>
                            <RadioGroup aria-label='sso config method type' defaultValue={SSOTypeEnum.SAML} name='sso-config-drawer-method-group'>
                                <FormControlLabel value={SSOTypeEnum.SAML} control={<Radio />} label='SAML' />
                                <FormControlLabel value={'Okta'} control={<Radio />} label='Okta' />
                            </RadioGroup>
                        </Stack>
                    )}
                    <Stack
                        id={'sso-config-drawer-form'}
                        component={'form'}
                        width={'100%'}
                        spacing={2}
                        onSubmit={handleSubmit((data) => {
                            onSubmitForm(data);
                        })}
                    >
                        {!configToEdit ? (
                            <Stack direction={'column'} spacing={1.5}>
                                <Typography className={cx(classes.FormTitle)} variant={'h6'}>
                                    Fill out the values with the information provided by your identity provider
                                </Typography>
                                <SsoPlaceHolderInfoAlert setValue={setValue} />
                            </Stack>
                        ) : (
                            <Typography className={cx(classes.FormTitle)} variant={'h6'}>
                                {configToEdit.provider_id.toUpperCase()} SSO Configuration for {configToEdit.display_name}
                            </Typography>
                        )}
                        <FormField label='Configuration Name' htmlFor='alias'>
                            <Controller
                                name={'alias'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='alias'
                                        placeholder='Enter a name to identify your configuration'
                                        error={!!errors.alias}
                                        value={value}
                                        disabled={!!configToEdit}
                                        size={'small'}
                                        helperText={(errors.alias?.message as string) ?? 'Cannot be edited after creation'}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Display Name' htmlFor='display_name'>
                            <Controller
                                name={'display_name'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='display_name'
                                        error={!!errors.display_name}
                                        value={value}
                                        size={'small'}
                                        helperText={errors.display_name?.message as string}
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Issuer URI' htmlFor='config.idp_entity_id'>
                            <Controller
                                name={'config.idp_entity_id'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='config.idp_entity_id'
                                        error={!!errors.config?.idp_entity_id}
                                        value={value}
                                        size={'small'}
                                        helperText={
                                            (errors.config?.idp_entity_id?.message as string) ?? 'Identifier for the issuer of the SAML assertion'
                                        }
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Single sign-on URL' htmlFor='config.single_sign_on_service_url'>
                            <Controller
                                name={'config.single_sign_on_service_url'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        fullWidth={true}
                                        id='config.single_sign_on_service_url'
                                        error={!!errors.config?.single_sign_on_service_url}
                                        value={value}
                                        size={'small'}
                                        helperText={
                                            (errors.config?.single_sign_on_service_url?.message as string) ??
                                            'URL of the receiver of the SAML AuthNRequest'
                                        }
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField id={'config.post_binding_authn_request-label'} label='Request Binding' htmlFor='config.post_binding_response'>
                            <Stack>
                                <Controller
                                    name={'config.post_binding_authn_request'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            name={'config.post_binding_authn_request'}
                                            error={!!errors.config?.post_binding_authn_request}
                                            size={'small'}
                                            labelId='config.post_binding_authn_request-label'
                                            id='config.post_binding_authn_request'
                                            fullWidth={true}
                                            value={value}
                                            onChange={(e: SelectChangeEvent<PostBinding>) => {
                                                onChange(e.target.value as PostBinding);
                                            }}
                                        >
                                            <MenuItem value={PostBinding.HTTP_POST}>{ReplaceUnderscoreWithSpace(PostBinding.HTTP_POST)}</MenuItem>
                                            <MenuItem value={PostBinding.HTTP_REDIRECT}>
                                                {ReplaceUnderscoreWithSpace(PostBinding.HTTP_REDIRECT)}
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                                <FormHelperText className={!!errors.config?.post_binding_authn_request ? cx(classes.SelectHelperText) : undefined}>
                                    {(errors.config?.post_binding_authn_request?.message as string) ??
                                        'The SAML Authentication Request Protocol binding used to send the AuthNRequest'}
                                </FormHelperText>
                            </Stack>
                        </FormField>
                        <FormField id={'config.signature_algorithm-label'} label='Response signature algorithm' htmlFor='config.signature_algorithm'>
                            <Stack>
                                <Controller
                                    name={'config.signature_algorithm'}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            name={'config.signature_algorithm'}
                                            error={!!errors.config?.signature_algorithm}
                                            size={'small'}
                                            labelId='config.signature_algorithm-label'
                                            id='config.signature_algorithm'
                                            fullWidth={true}
                                            value={value}
                                            onChange={(e: SelectChangeEvent<SignatureAlgorithm>) => {
                                                onChange(e.target.value as SignatureAlgorithm);
                                            }}
                                        >
                                            <MenuItem value={SignatureAlgorithm.SHA256}>{SignatureAlgorithm.SHA256}</MenuItem>
                                            <MenuItem value={SignatureAlgorithm.SHA1}>{SignatureAlgorithm.SHA1}</MenuItem>
                                        </Select>
                                    )}
                                />
                                <FormHelperText className={!!errors.config?.signature_algorithm ? cx(classes.SelectHelperText) : undefined}>
                                    {(errors.config?.signature_algorithm?.message as string) ??
                                        'The signature algorithm used to sign the SAML AuthRequest'}
                                </FormHelperText>
                            </Stack>
                        </FormField>
                        {configToEdit ? (
                            <SsoSamlFileUploadEdit control={control} errors={errors} setValue={setValue} ssoItem={configToEdit} />
                        ) : (
                            <SsoSamlFileUpload control={control} errors={errors} setValue={setValue} />
                        )}
                        {configToEdit && (
                            <Accordion
                                aria-label={'Open to show configuration data fields required for setup'}
                                className={cx(classes.SSOSubItemContainer, classes.SSOGraySubItemContainer, classes.Accordian)}
                                elevation={0}
                            >
                                <AccordionSummary expandIcon={<ExpandMore />} aria-controls='sso-metadata-content' id='sso-metadata-header'>
                                    <Typography className={cx(classes.FormTitle)} fontWeight={600} variant={'body1'}>
                                        Settings and configuration data required for your SSO provider to complete setup
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Stack
                                        direction={'column'}
                                        spacing={1.5}
                                        className={cx(classes.SSOSubItemContainer, classes.SSOWhiteSubItemContainer)}
                                    >
                                        <Typography variant={'subtitle1'}>
                                            Additional details to assist with configuring your SSO providers App Registration
                                        </Typography>
                                        <FormField label='Audience' htmlFor='config.idp_sso_audience_restriction'>
                                            <Controller
                                                name={'config.idp_sso_audience_restriction'}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        fullWidth
                                                        id='config.idp_entity_id'
                                                        error={!!errors.config?.idp_sso_audience_restriction}
                                                        value={value}
                                                        size={'small'}
                                                        slotProps={{
                                                            input: {
                                                                readOnly: true,
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='copy audience'
                                                                            onClick={() => handleCopy('Audience', value ?? '')}
                                                                        >
                                                                            <ContentCopy />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        }}
                                                        helperText={errors.config?.idp_sso_audience_restriction?.message as string}
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormField>
                                        <FormField label='Single Sign-On ACS URL' htmlFor='config.idp_sso_redirect_url'>
                                            <Controller
                                                name={'config.idp_sso_redirect_url'}
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        fullWidth={true}
                                                        id='config.idp_entity_id'
                                                        error={!!errors.config?.idp_sso_redirect_url}
                                                        value={value}
                                                        slotProps={{
                                                            input: {
                                                                readOnly: true,
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='copy redirect url'
                                                                            onClick={() => handleCopy('Redirect URL', value ?? '')}
                                                                        >
                                                                            <ContentCopy />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        }}
                                                        size={'small'}
                                                        helperText={errors.config?.idp_sso_redirect_url?.message as string}
                                                        onChange={(e) => {
                                                            onChange(e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormField>
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Button
                    className={commonStyles.classes.LowercaseTextButton}
                    disableElevation={true}
                    type={'submit'}
                    variant={'contained'}
                    form={'sso-config-drawer-form'}
                >
                    {configToEdit ? 'Save' : 'Create'}
                </Button>
            </DialogActions>
        </Drawer>
    );
};

const useStyles = customMakeStyles<ISsoConfigurationDialogProps>()((theme, props) => ({
    DialogActions: {
        paddingRight: '1.5rem',
        paddingTop: '1px',
        paddingBottom: '1.5rem',
    },
    DialogTitle: { paddingLeft: '1.5rem', paddingTop: '1.5rem', paddingRight: '1.5rem' },
    FormTitle: {
        color: theme.palette.grey[700],
        fontWeight: 500,
    },
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DrawerContainer: {},
    DrawerPaper: { width: '45%' },
    CloseButton: { paddingRight: 0, paddingBottom: 0, paddingTop: 0 },
    SelectHelperText: {
        color: theme.palette.error.main,
    },
    SSOSubItemContainer: {
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '8px',
        width: '100%',
    },
    SSOWhiteSubItemContainer: { backgroundColor: '#ffffff' },
    SSOGraySubItemContainer: { backgroundColor: '#fafafa' },
    Accordian: { '&.MuiAccordion-root': { padding: '1rem' } },
}));

export { SsoConfigurationDrawer };
