import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { QuicksightReportsController } from '../quicksight-reports-controller/quicksight-reports-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDatametryProps {}

const Datametry: React.FC<IDatametryProps> = (props) => {
    return <QuicksightReportsController folderNames={['datametry']} />;
};

export { Datametry };
