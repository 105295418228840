import { useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useKeycloak } from '@react-keycloak-fork/web';
import { SnackBarOptions, ApiKeyState } from '../recoil/atom';
import { useMutation } from '@tanstack/react-query';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';
import { ApiKeyManagementApi } from '@vegaplatformui/apis';

export interface IUseApiKeyManagementApiHook {
    generateApiKey: () => void;
    isGenerateApiKeyLoading: boolean;
}

export interface IUseApiKeyManagementApiProps {}

export function useApiKeyManagementApi(props: IUseApiKeyManagementApiProps): IUseApiKeyManagementApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const setApiKey = useSetRecoilState(ApiKeyState);

    const apiKeyManagementApi = useMemo(() => {
        const apiKeyManagementApi = new ApiKeyManagementApi();
        apiKeyManagementApi.keycloak = keycloak;
        return apiKeyManagementApi;
    }, [keycloak]);

    const { isPending: isGenerateApiKeyLoading, mutate: generateApiKey } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem generating API Key: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setApiKey(response.data.api_key);
        },
        mutationFn: async () => {
            return await apiKeyManagementApi.createApiToken();
        },
    });

    return {
        generateApiKey,
        isGenerateApiKeyLoading,
    };
}
