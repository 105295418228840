import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { QuicksightReportsController } from '../quicksight-reports-controller/quicksight-reports-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommitedUseDiscountsControllerProps {}

const CommittedUseDiscountsAws: React.FC<ICommitedUseDiscountsControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <QuicksightReportsController folderNames={['committeduse_aws']} />;
};

const useStyles = customMakeStyles<ICommitedUseDiscountsControllerProps>()((theme, props) => ({}));

export { CommittedUseDiscountsAws };
