import { HttpClient } from './http-common';
import {
    IGetBusinessGroupingRequest,
    IDeleteBusinessGroupingRequest,
    IDeleteBusinessGroupingsRequest,
    IBusinessGroupingRequest,
    IGetBusinessGroupingTypesRequest,
    IListBusinessGroupingsRequest,
    IGetBusinessGroupTreeItemsRequest,
    IBusinessGroupTreeItem,
    IBusinessUnitTreeItem,
    IBusinessGrouping,
    IGetValidBusinessGroupingParentsRequest,
    IBusinessGroupingType,
} from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';

export class BusinessGroupingsApi extends HttpClient {
    protected static classInstance?: BusinessGroupingsApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new BusinessGroupingsApi();
        }

        return this.classInstance;
    }

    // single business grouping
    public getBusinessGrouping = (request: IGetBusinessGroupingRequest): GeminiResponse<IBusinessGrouping> =>
        this.instance.get(`/vegaapi/business-units/${request.id}`);

    public createBusinessGrouping = (request: IBusinessGroupingRequest): GeminiResponse<any> =>
        this.instance.post(`/vegaapi/business-units`, request);

    public updateBusinessGrouping = (request: IBusinessGroupingRequest, businessUnitID: string): GeminiResponse<any> =>
        this.instance.put(`/vegaapi/business-units/${businessUnitID}`, request);

    public deleteBusinessGrouping = (request: IDeleteBusinessGroupingRequest): GeminiResponse<any> =>
        this.instance.delete(`/vegaapi/business-units/${request.id}`);

    // multiple business groupings
    public loadBusinessGroupings = (request?: IListBusinessGroupingsRequest): GeminiResponse<IBusinessGrouping[]> =>
        this.instance.get(`/vegaapi/business-units`);

    public deleteBusinessGroupings = (request: IDeleteBusinessGroupingsRequest): GeminiResponse<any> =>
        this.instance.delete(`/vegaapi/business-units`, { data: { ids: request.ids } });

    // business grouping types, treenodes and parents
    public getBusinessGroupingTypes = (request?: IGetBusinessGroupingTypesRequest): GeminiResponse<IBusinessGroupingType[]> =>
        this.instance.get(`/vegaapi/business-unit/types`);

    public getBusinessGroupingTreeNodes = (request?: IGetBusinessGroupTreeItemsRequest): GeminiResponse<IBusinessGroupTreeItem[]> =>
        request?.root_uuid !== undefined
            ? this.instance.get(`/vegaapi/business-units/treenode/`, { params: { root_uuid: request.root_uuid } })
            : this.instance.get(`/vegaapi/business-units/treenode/`);

    public getValidBusinessGroupingParents = (request?: IGetValidBusinessGroupingParentsRequest): GeminiResponse<IBusinessGrouping[]> =>
        request?.business_group_id !== undefined
            ? this.instance.get(`/vegaapi/business-units/get_valid_parents/${request.business_group_id}`)
            : this.instance.get(`/vegaapi/business-units/get_valid_parents/None`);

    public getMyBusinessGroupingTree = (request?: any): GeminiResponse<IBusinessUnitTreeItem[]> => {
        return request?.id !== undefined
            ? this.instance.get(`vegaapi/business-units/get_valid_parents_tree/`, { params: { id: request.id } })
            : this.instance.get(`vegaapi/business-units/get_valid_parents_tree/`);
    };

    public getOwnedBusinessGroupingsByUserId = (userId: string): GeminiResponse<string[]> =>
        this.instance.get(`vegaapi/business-units/users/${userId}/owned_business_groups`);
}
