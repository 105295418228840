import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid2, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { IPutUserV2HookRequest, IUserSettingAddress } from '@vegaplatformui/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { countries } from 'countries-list';
import { regexList, usStateList } from '@vegaplatformui/utils';
import { UserId } from '../../../jotai/atom';
import { FormField } from '../../../forms';
import { useAtomValue } from 'jotai/index';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileAddressInputProps {
    address: IUserSettingAddress;
    onAddressUpdate: (data: IPutUserV2HookRequest) => void;
}

const validationProfileAddressSchema: yup.ObjectSchema<IUserSettingAddress> = yup.object().shape({
    street_address: yup.string().required('Required').matches(regexList.alphabetic_with_white_space, 'Required to be alphabetic.'),
    zip_code: yup.string().required('Required').matches(regexList.number, 'Required to be numeric.'),
    city: yup.string().required('Required').matches(regexList.alphabetic_with_white_space, 'Required to be alphabetic.'),
    country: yup.string().required('Required'),
    state: yup.string().when('country', {
        is: (value: string) => {
            return value !== 'US';
        },
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required('Required'),
    }),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const ProfileAddressInput: React.FC<IProfileAddressInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const countryOptions = Object.entries(countries).map(([code, country]) => ({
        code,
        country,
    }));
    const currentUserId = useAtomValue(UserId);

    const [addressFieldsBySelect, setAddressFieldsBySelect] = useState({
        state: props.address.state,
        country: props.address.country,
    });

    const [isAddressFieldsChanged, setIsAddressFieldsChanged] = useState(false);
    const [isAddressFieldsBySelectChanged, setIsAddressFieldsBySelectChanged] = useState(false);
    const [disableStateSelect, setDisableStateSelect] = useState(props.address.country !== 'US');

    const {
        handleSubmit: handleAddressSubmit,
        control: addressControl,
        reset: addressReset,
        formState: addressFormState,
        setValue: setAddressValue,
        formState: { isDirty: isAddressDirty, errors: addressErrors, isSubmitSuccessful: isAddressSubmitSuccessful },
    } = useForm<IUserSettingAddress>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationProfileAddressSchema),
        defaultValues: {
            street_address: props.address.street_address ?? '',
            zip_code: props.address.zip_code ?? '',
            city: props.address.city ?? '',
            country: props.address.country ?? null,
            state: props.address.state ?? null,
        },
    });

    const handleCountrySelect = (event: SelectChangeEvent<string>) => {
        const fieldValue = event.target.value;
        setAddressFieldsBySelect((prev) => ({
            ...prev,
            ['country']: fieldValue,
        }));
        //Setting the form state value
        setAddressValue('country', fieldValue);
        setIsAddressFieldsBySelectChanged(true);
        setDisableStateSelect(fieldValue !== 'US');
    };

    const handleStateSelect = (event: SelectChangeEvent<string>) => {
        const fieldValue = event.target.value;
        setAddressFieldsBySelect((prev) => ({
            ...prev,
            ['state']: fieldValue,
        }));
        //Setting the form state value
        setAddressValue('state', fieldValue);
        setIsAddressFieldsBySelectChanged(true);
    };
    const onAddressChange = () => {
        setIsAddressFieldsChanged(true);
    };

    const onAddressSubmit: SubmitHandler<IUserSettingAddress> = (data) => {
        data.country = addressFieldsBySelect.country;
        data.state = addressFieldsBySelect.state;
        if (data.country !== 'US') data.state = '';
        setIsAddressFieldsBySelectChanged(false);
        setIsAddressFieldsChanged(false);
        if (currentUserId) {
            props.onAddressUpdate({
                request: {
                    street_address: data.street_address,
                    city: data.city,
                    country: data.country,
                    state: data.state ?? '',
                    zip_code: data.zip_code,
                },
                user_id: currentUserId,
            });
        }
    };

    useEffect(() => {
        if (props.address !== undefined) {
            addressReset(
                {
                    street_address: props.address.street_address ?? '',
                    zip_code: props.address.zip_code ?? '',
                    city: props.address.city ?? '',
                    country: props.address.country ?? null,
                    state: props.address.state ?? null,
                },
                { keepDirty: true, keepIsValid: true }
            );
            setAddressFieldsBySelect((prev) => ({
                ...prev,
                country: props.address.country,
                state: props.address.state,
            }));
            setDisableStateSelect(props.address.country !== 'US');
        }
    }, [props.address]);

    return (
        <Grid2
            id={'profile-address-form'}
            component={'form'}
            container
            spacing={2}
            onSubmit={handleAddressSubmit((data) => {
                onAddressSubmit(data);
            })}
            onChange={onAddressChange}
        >
            <Grid2 size={12}>
                <FormField label={'Street Address'} htmlFor={'street_address'}>
                    <Controller
                        name={'street_address'}
                        control={addressControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'street_address'}
                                size='small'
                                fullWidth={true}
                                value={value}
                                placeholder={'Street Address'}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!addressErrors['street_address']}
                                helperText={addressErrors['street_address']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormField label={'City'} htmlFor={'city'}>
                    <Controller
                        name={'city'}
                        control={addressControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'city'}
                                size='small'
                                fullWidth={true}
                                value={value}
                                placeholder={'City'}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!addressErrors['city']}
                                helperText={addressErrors['city']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormField id={'country'} label='Country' htmlFor='country'>
                    <Controller
                        control={addressControl}
                        name={'country'}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                id={'country'}
                                labelId={'country'}
                                size='small'
                                fullWidth={true}
                                error={!!addressErrors.country}
                                MenuProps={MenuProps}
                                value={addressFieldsBySelect.country !== null ? addressFieldsBySelect.country : ''}
                                onChange={handleCountrySelect}
                            >
                                <MenuItem key={'us'} value={'US'}>
                                    United States
                                </MenuItem>
                                {countryOptions.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {option.country.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </FormField>
            </Grid2>
            {!disableStateSelect && (
                <Grid2 size={{ xs: 12, sm: 6 }}>
                    <FormField label='State' htmlFor='state'>
                        <Controller
                            control={addressControl}
                            name={'state'}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id={'state'}
                                    labelId={'state'}
                                    size='small'
                                    fullWidth={true}
                                    error={!!addressErrors.state}
                                    MenuProps={MenuProps}
                                    // defaultValue={props.address.state}
                                    value={addressFieldsBySelect.state !== null ? addressFieldsBySelect.state : ''}
                                    onChange={handleStateSelect}
                                    disabled={disableStateSelect}
                                >
                                    {usStateList.map((option) => (
                                        <MenuItem key={option.name} value={option.abbreviation}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormField>
                </Grid2>
            )}
            <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormField label={'Zip Code'} htmlFor={'zip_code'}>
                    <Controller
                        name={'zip_code'}
                        control={addressControl}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id={'zip_code'}
                                size='small'
                                fullWidth={true}
                                value={value}
                                placeholder={'Zip Code'}
                                onChange={(e) => onChange(e.target.value)}
                                error={!!addressErrors['zip_code']}
                                helperText={addressErrors['zip_code']?.message}
                            />
                        )}
                    />
                </FormField>
            </Grid2>
            <Grid2 size={12}>
                <Stack spacing={2} direction={'row'} justifyContent={'flex-start'}>
                    <Button
                        variant='contained'
                        disabled={(!isAddressDirty || !isAddressFieldsChanged) && !isAddressFieldsBySelectChanged}
                        type='submit'
                        form={'profile-address-form'}
                        disableElevation
                    >
                        Save
                    </Button>
                </Stack>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IProfileAddressInputProps>()((theme, props) => ({}));

export { ProfileAddressInput };
