import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { IParkingSchedule } from '@vegaplatformui/models';
import { scheduleTarget } from 'nx/src/adapter/ngcli-adapter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmCloseDrawerProps {
    show: boolean;
    onClose: () => void;
    onSave: () => void;
    onDontSave: () => void;
    scheduleToEdit?: IParkingSchedule;
    saveIsDisabled: boolean;
}

const ConfirmCloseDrawer: React.FC<IConfirmCloseDrawerProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <Grid container className={cx(classes.GridContainer)} direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <Grid xs={11} item>
                        <Typography fontWeight={500}>
                            Do you want to save the changes made{props.scheduleToEdit ? ` to ${props.scheduleToEdit.name}?` : '?'}
                        </Typography>
                    </Grid>
                    <Grid xs={1} item className={cx(classes.CancelButton)}>
                        <IconButton aria-label={'Close'} size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Your changes will be lost if you do not save them.
                </DialogContentText>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    Saving will {props.scheduleToEdit ? 'submit the changes.' : 'create the schedule.'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onDontSave} autoFocus>
                    Don't Save
                </Button>
                <Button disabled={props.saveIsDisabled} onClick={() => props.onSave()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmCloseDrawerProps>()((theme, props) => ({
    GridContainer: { marginTop: '-1.5rem' },
    CancelButton: { marginRight: '-1rem' },
}));

export { ConfirmCloseDrawer };
