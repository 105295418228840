import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ApplicationError, ShowSupportForm, SnackbarErrorOutput, SnackBarOptions, SupportForm, useVegaApi } from '@vegaplatformui/sharedcomponents';
import { useKeycloak } from '@react-keycloak-fork/web';
import { ContactSupportForm, ContactSupportTopic, ISendSupportEmailRequest, SupportEmailRecipient } from '@vegaplatformui/models';
import { VegaApi } from '@vegaplatformui/apis';
import { useAtom, useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactSupportControllerProps {
    show: boolean;
}

const ContactSupportController: React.FC<IContactSupportControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { keycloak } = useKeycloak();
    const [showContactSupport, setShowContactSupport] = useAtom(ShowSupportForm);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const [applicationError, setApplicationError] = useAtom(ApplicationError);
    const [isSending, setIsSending] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState<string | ContactSupportTopic>('default');
    // const vegaApi = new VegaApi();
    const vegaApi = useVegaApi({
        setApplicationError: setApplicationError,
        setShowContactSupport: setShowContactSupport,
        setSelectedTopic: setSelectedTopic,
    });

    const ContactCloudHeroSpecialist = (request: ISendSupportEmailRequest) => {
        vegaApi.sendSupportEmail({
            request: {
                first_name: request.first_name,
                last_name: request.last_name,
                email: request.email,
                topic: request.topic,
                message: request.message,
                subject: request.subject,
            },
            recipient: SupportEmailRecipient.CloudHero,
        });
    };

    const ContactSupport = (request: ISendSupportEmailRequest) => {
        vegaApi.sendSupportEmail({
            request: {
                first_name: request.first_name,
                last_name: request.last_name,
                email: request.email,
                topic: request.topic,
                message: request.message,
                subject: request.subject,
            },
            recipient: SupportEmailRecipient.Support,
        });
    };

    const onSubmitFeedback = (contactSupportForm: ContactSupportForm) => {
        const contactType = showContactSupport.contactType;
        setIsSending(true);
        switch (contactType) {
            case SupportEmailRecipient.Support:
                switch (contactSupportForm.topic) {
                    case ContactSupportTopic.CloudHero:
                        ContactCloudHeroSpecialist({
                            first_name: contactSupportForm.firstName,
                            last_name: contactSupportForm.lastName,
                            email: contactSupportForm.email,
                            topic: contactSupportForm.topic,
                            subject: contactSupportForm.subject,
                            message: contactSupportForm.feedback,
                        });
                        break;
                    default:
                        ContactSupport({
                            first_name: contactSupportForm.firstName,
                            last_name: contactSupportForm.lastName,
                            email: contactSupportForm.email,
                            topic: contactSupportForm.topic,
                            subject: contactSupportForm.subject,
                            message: contactSupportForm.feedback,
                        });
                        break;
                }
                break;
            case SupportEmailRecipient.CloudHero:
                ContactCloudHeroSpecialist({
                    first_name: contactSupportForm.firstName,
                    last_name: contactSupportForm.lastName,
                    email: contactSupportForm.email,
                    topic: contactSupportForm.topic,
                    subject: contactSupportForm.subject,
                    message: contactSupportForm.feedback,
                });
                break;
        }
    };

    return (
        <SupportForm
            show={showContactSupport.showSupportForm}
            onClose={() => {
                setShowContactSupport({ showSupportForm: false, contactType: SupportEmailRecipient.Support });
                setSelectedTopic('default');
            }}
            isSending={vegaApi.isEmailSending}
            onSubmitFeedback={onSubmitFeedback}
            keycloak={keycloak}
            contactType={showContactSupport.contactType}
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
        />
    );
};

const useStyles = customMakeStyles<IContactSupportControllerProps>()((theme, props) => ({}));

export { ContactSupportController };
