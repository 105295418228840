import { useKeycloak } from '@react-keycloak-fork/web';
import { EnumsApi } from 'libs/apis/src';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../query-keys';
import { useRecoilValue } from 'recoil';
import { OrganizationId } from '../../recoil/atom';

export interface IUseRulesEnumApiProps {
    url: string;
    meta_data?: string;
}

export interface IUseRulesEnumApiHook<T> {
    enumValues: T[] | undefined;
    isLoading: boolean;
    isError: boolean;
    error: any;
    isSuccess: boolean;
}

export function useRulesEnumsApi<T>(props: IUseRulesEnumApiProps): IUseRulesEnumApiHook<T> {
    const { keycloak } = useKeycloak();
    const organizationId = useRecoilValue(OrganizationId);

    const enumsApi = useMemo(() => {
        const enumsApi = new EnumsApi();
        enumsApi.keycloak = keycloak;
        return enumsApi;
    }, [keycloak]);

    const {
        data: enumValues,
        isLoading: isEnumValuesLoading,
        isError: isEnumValuesLoadingError,
        error: enumValuesLoadingError,
        isSuccess: isEnumValuesLoadingSuccess,
    } = useQuery({
        queryKey: [queryKeys.usersApi.loadUsers, props.url],
        queryFn: async () => {
            const metaData = props.meta_data?.includes('query_param') && getParamsFromMetaData(props.meta_data);

            const url = props.meta_data?.includes('path_param') ? getPathFromMetaData(props.url, props.meta_data) : props.url;

            const response = await enumsApi.getEnums<T>(url, metaData);

            return response.data;
        },
        enabled: !!props.url && organizationId !== '' && organizationId !== undefined,
        meta: {
            errorMessage: '`Unable to get values for auto complete.',
        },
    });

    const getPathFromMetaData = (urlToAppendTo: string, metaData?: string) => {
        let ret = urlToAppendTo;

        if (metaData) {
            const metaDataObj = JSON.parse(metaData);

            Object.keys(metaDataObj).forEach((key) => {
                if (metaDataObj[key] === 'org_id') {
                    ret = ret + (ret.endsWith('/') ? '' : '/') + organizationId;
                }
            });

            return ret;
        }
        return ret;
    };

    const getParamsFromMetaData = (metaData?: string) => {
        if (metaData) {
            const metaDataObj = JSON.parse(metaData);
            const params: any = {};

            Object.keys(metaDataObj).forEach((key) => {
                if (metaDataObj[key] === 'org_id') {
                    //add org_id to params object
                    params[metaDataObj[key]] = organizationId;
                }
            });

            return params;
        }
        return {};
    };

    return {
        enumValues,
        isLoading: isEnumValuesLoading,
        isError: isEnumValuesLoadingError,
        error: enumValuesLoadingError,
        isSuccess: isEnumValuesLoadingSuccess,
    };
}
