import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { TimePicker, TimePickerSlotProps } from '@mui/x-date-pickers';
import { ITimeZone } from '@vegaplatformui/utils';
import { Dayjs } from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITimePickerWithTimezoneProps {
    time: Dayjs | null;
    setTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    timezone: ITimeZone | null;
    label?: string;
    slotProps: TimePickerSlotProps<Dayjs, any> | undefined;
}

const TimePickerWithTimezone: React.FC<ITimePickerWithTimezoneProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { time, setTime, timezone, label, slotProps } = props;

    return (
        <TimePicker
            slotProps={slotProps ? slotProps : undefined}
            timezone={timezone && timezone.tzCode === 'Europe/London' ? 'UTC' : timezone?.tzCode ?? undefined}
            label={label ? label : 'Select time'}
            value={time}
            onChange={(newValue) => setTime(newValue)}
        />
    );
};

const useStyles = customMakeStyles<ITimePickerWithTimezoneProps>()((theme, props) => ({}));

export { TimePickerWithTimezone };
