import { useMemo } from 'react';
import { useShepherd } from 'react-shepherd';
import { Tour, StepOptions, TourOptions } from 'shepherd.js';

export interface IUseShepherdTourHook {
    tourObject: Tour;
}

export interface IUseShephedTourProps {
    tourOptions: TourOptions;
    steps: StepOptions[];
}

export function useShepherdTour(props: IUseShephedTourProps): IUseShepherdTourHook {
    const { tourOptions, steps } = props;
    const shepherd = useShepherd();

    const tourObject = useMemo(() => {
        const stepsWithIndicator = steps.map((step) => {
            return {
                ...step,
                when: {
                    show() {
                        const currentStep = shepherd.activeTour?.getCurrentStep();
                        const currentStepElement = currentStep?.getElement();
                        const footer = currentStepElement?.querySelector('.shepherd-footer');
                        const progress = document.createElement('span');
                        progress.className = 'shepherd-progress';
                        progress.innerText =
                            (currentStep &&
                                shepherd.activeTour &&
                                `${shepherd.activeTour?.steps.indexOf(currentStep) + 1}/${shepherd.activeTour?.steps.length}`) ??
                            '';
                        currentStepElement && footer?.insertBefore(progress, currentStepElement.querySelector('.shepherd-button'));
                    },
                    cancel() {
                        localStorage.setItem('menu_walkthrough', 'completed');
                    },
                },
            };
        });

        return new shepherd.Tour({ ...tourOptions, steps: stepsWithIndicator });
    }, [tourOptions, steps]);

    return { tourObject };
}

export default useShepherdTour;
