import React, { useMemo, useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Grid, Icon, Stack } from '@mui/material';
import { SettingsContentHeader } from '../settings-content-header';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { SettingsV2Divider } from '../settings-v2-divider';
import {
    IBusinessGroupingType,
    IPostUserV2Request,
    IPutUserV2HookRequest,
    ISubmitUserV2,
    IUser,
    IUserSettingRealmRole,
} from '@vegaplatformui/models';
import { UsersSettingsTableV2 } from './users-settings-table-v2';
import useBusinessGroupingsApi from '../../../api-hooks/business-groupings-hooks/use-business-groupings-api';
import { UserFormDrawerV2 } from './user-form-v2/user-form-drawer-v2';
import { UserDeleteDialogV2 } from './user-delete-dialog-v2';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUsersSettingsCardV2Props {
    users: IUser[];
    roles: IUserSettingRealmRole[];
    isLoading: boolean;
    onUpdateUser: (data: IPutUserV2HookRequest) => void;
    onDeleteUsers: (data: IUser[]) => void;
    onInviteUser: (data: IPostUserV2Request) => void;
    isCreatingUser: boolean;
    businessGroupingTypes: IBusinessGroupingType[];
}

const UsersSettingsV2: React.FC<IUsersSettingsCardV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [userToEdit, setUserToEdit] = useState<IUser>();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [usersToDelete, setUsersToDelete] = useState<IUser[]>([]);
    const businessGroupingApi = useBusinessGroupingsApi({});
    const sortedRoles = useMemo(() => {
        const readOnlyRole = props.roles.find((role) => role.name === 'read_only');
        if (readOnlyRole) {
            const readOnlyIndex = props.roles.indexOf(readOnlyRole);
            if (readOnlyIndex > -1) {
                return [props.roles[readOnlyIndex], ...props.roles.slice(0, readOnlyIndex), ...props.roles.slice(readOnlyIndex + 1)];
            }
        }
        return props.roles;
    }, [props.roles]);
    const commonStyles = useCommonStyles();

    const closeUserFormDialog = () => {
        setUserToEdit(undefined);
        businessGroupingApi.setUserId(undefined);
        setIsFormOpen(false);
    };

    const closeDeleteDialog = () => {
        setIsDeleteOpen(false);
        setUsersToDelete([]);
    };

    const inviteUser = (data: ISubmitUserV2) => {
        closeUserFormDialog();
        props.onInviteUser({
            email: data.email,
            business_group_ids: data.business_groupings?.map((group) => group.id),
            family_name: data.family_name,
            given_name: data.given_name,
            platform_roles: data.platform_roles,
        });
    };
    const updateUser = (data: ISubmitUserV2, user_id: string) => {
        closeUserFormDialog();
        props.onUpdateUser({
            request: {
                given_name: data.given_name,
                family_name: data.family_name,
                platform_roles: data.platform_roles,
                business_groupings: data.business_groupings,
            },
            user_id: user_id,
        });
    };
    const deleteUsers = (data: IUser[]) => {
        closeDeleteDialog();
        props.onDeleteUsers(data);
    };

    return (
        <>
            <Stack direction={'column'}>
                <Grid container direction='column'>
                    <Grid item xs={12} container direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <SettingsContentHeader
                            title={'Users'}
                            subtitle={'Manage platform users.'}
                            children={
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    {selectedUsers.length > 1 && (
                                        <Button
                                            startIcon={<Delete />}
                                            className={commonStyles.classes.MultipleDeleteButton}
                                            variant={'contained'}
                                            onClick={() => {
                                                setUsersToDelete(selectedUsers);
                                                setIsDeleteOpen(true);
                                            }}
                                            aria-label={'Delete Selected Users'}
                                        >
                                            Delete Selected Users
                                        </Button>
                                    )}
                                    <LoadingButton
                                        loading={props.isCreatingUser}
                                        loadingPosition={'start'}
                                        variant={'contained'}
                                        startIcon={props.isCreatingUser ? <Icon></Icon> : <></>}
                                        onClick={() => setIsFormOpen(true)}
                                        aria-label={'Invite User'}
                                    >
                                        Invite User
                                    </LoadingButton>
                                </Stack>
                            }
                        />
                        <SettingsV2Divider />
                    </Grid>
                </Grid>
                <UsersSettingsTableV2
                    users={props.users}
                    setSelectedUsers={setSelectedUsers}
                    setUsersToDelete={setUsersToDelete}
                    setUserToEdit={setUserToEdit}
                    setIsDeleteOpen={setIsDeleteOpen}
                    usersToDelete={usersToDelete}
                    setIsFormOpen={setIsFormOpen}
                    isLoading={props.isLoading}
                    businessGroupingApi={businessGroupingApi}
                />
            </Stack>
            <UserFormDrawerV2
                key={userToEdit ? userToEdit + '-update-form-drawer' : 'create-form-drawer'}
                usersBusinessGroupings={businessGroupingApi.myBusinessUnitTree ?? []}
                ownedBusinessGroupingsByUser={businessGroupingApi.ownedBusinessGroupingsByUser}
                isOpen={isFormOpen}
                user={userToEdit}
                users={props.users}
                roles={sortedRoles}
                onClose={closeUserFormDialog}
                onUpdate={updateUser}
                onInvite={inviteUser}
            />
            <UserDeleteDialogV2
                key={usersToDelete.length + '-delete-dialog'}
                isOpen={isDeleteOpen}
                users={usersToDelete}
                onClose={closeDeleteDialog}
                onDelete={deleteUsers}
            />
        </>
    );
};

const useStyles = customMakeStyles<IUsersSettingsCardV2Props>()((theme, props) => ({}));

export { UsersSettingsV2 };
