import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { MoreHorizRounded } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDatagridCustomMoreactionsbuttonProps {}

const DatagridCustomMoreActionsButton: React.FC<IDatagridCustomMoreactionsbuttonProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return <MoreHorizRounded />;
};

const useStyles = customMakeStyles<IDatagridCustomMoreactionsbuttonProps>()((theme, props) => ({}));

export { DatagridCustomMoreActionsButton };
