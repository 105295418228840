import { useKeycloak } from '@react-keycloak-fork/web';
import React, { useMemo, useState } from 'react';
import { SnackBarOptions } from '../../jotai/atom';
import {
    QueryObserverResult,
    RefetchOptions,
    RefetchQueryFilters,
    UseMutateFunction,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { SnackbarErrorOutput } from '../../utilities/snackbar-error-output';
import {
    IBusinessGrouping,
    IBusinessGroupingType,
    IBusinessGroupTreeItem,
    IPostBusinessGroupHookObject,
    IPutBusinessGroupHookObject,
    IBusinessUnitTreeItem,
} from '@vegaplatformui/models';
import { BusinessGroupingsApi } from '@vegaplatformui/apis';
import { queryKeys } from '../query-keys';
import { useNavigate } from 'react-router';
import { useRouteUrls } from '@vegaplatformui/utils';
import { useSetAtom } from 'jotai';

export interface IUseBusinessGroupingsApiHook {
    businessGroupingTypes: IBusinessGroupingType[] | undefined;
    refetchBusinessGroupingTypes: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters) | undefined
    ) => Promise<QueryObserverResult<IBusinessGroupingType[], unknown>>;
    validParentBusinessGroupings: IBusinessGrouping[] | undefined;
    refetchValidParentBusinessGroupings: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters) | undefined
    ) => Promise<QueryObserverResult<IBusinessGrouping[], unknown>>;
    isValidParentBusinessGroupingsSuccess: boolean;
    setBusinessGroupToEdit: React.Dispatch<React.SetStateAction<IBusinessGrouping | undefined>>;
    businessGroupings: IBusinessGrouping[] | undefined;
    refetchBusinessGroupings: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters) | undefined
    ) => Promise<QueryObserverResult<IBusinessGrouping[], unknown>>;
    initialBusinessGroupings: IBusinessGroupTreeItem[] | undefined;
    refetchInitialBusinessGroupings: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters) | undefined
    ) => Promise<QueryObserverResult<IBusinessGroupTreeItem[], unknown>>;
    isBusinessGroupingCreateSuccessful: boolean;
    businessGrouping: IBusinessGrouping | undefined;
    isBusinessGroupingCreateLoading: boolean;
    isBusinessGroupingEditLoading: boolean;
    isBusinessGroupingEditSuccessful: boolean;
    isBusinessGroupingLoading: boolean;
    isBusinessGroupingsLoading: boolean;
    isInitialBusinessGroupingsLoading: boolean;
    isValidParentBusinessGroupingsLoading: boolean;
    myBusinessUnitTree: IBusinessUnitTreeItem[] | undefined;
    onSubmitCreateBusinessGrouping: UseMutateFunction<any, any, IPostBusinessGroupHookObject, unknown>;
    onSubmitEditBusinessGrouping: UseMutateFunction<any, unknown, IPutBusinessGroupHookObject, unknown>;
    setBusinessGroupingId: React.Dispatch<React.SetStateAction<string | undefined>>;
    validBusinessGroupingParentsTree: IBusinessUnitTreeItem[] | undefined;
    ownedBusinessGroupingsByUser: string[];
    setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseBusinessGroupingsApiProps {}

export function useBusinessGroupingsApi(props: IUseBusinessGroupingsApiProps): IUseBusinessGroupingsApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const navigate = useNavigate();
    const [businessGroupToEdit, setBusinessGroupToEdit] = useState<IBusinessGrouping | undefined>(undefined);
    const [businessGroupingId, setBusinessGroupingId] = useState<string | undefined>(undefined);
    const [userId, setUserId] = useState<string | undefined>(undefined);
    const queryClient = useQueryClient();
    const { routeUrls } = useRouteUrls({});

    const businessGroupingsApi = useMemo(() => {
        const businessGroupingsApi = new BusinessGroupingsApi();
        businessGroupingsApi.keycloak = keycloak;
        return businessGroupingsApi;
    }, [keycloak]);

    const {
        data: businessGroupingTypes,
        isLoading: isBusinessGroupingTypesLoading,
        isError: isBusinessGroupingTypesError,
        error: businessGroupingTypesError,
        isSuccess: isBusinessGroupingTypesSuccess,
        refetch: refetchBusinessGroupingTypes,
    } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.getBusinessGroupingTypes],

        queryFn: async () => {
            const response = await businessGroupingsApi.getBusinessGroupingTypes();
            return response.data;
        },

        meta: {
            errorMessage: 'Unable to get users',
        },
    });

    const {
        data: validParentBusinessGroupings,
        isLoading: isValidParentBusinessGroupingsLoading,
        isError: isValidParentBusinessGroupingsError,
        error: validParentBusinessGroupingsError,
        isSuccess: isValidParentBusinessGroupingsSuccess,
        refetch: refetchValidParentBusinessGroupings,
    } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.loadValidParents, businessGroupToEdit],

        queryFn: async () => {
            const response = await businessGroupingsApi.getValidBusinessGroupingParents({ business_group_id: businessGroupToEdit?.id });
            return response.data;
        },

        meta: {
            errorMessage: 'There was a problem loading valid parent business groupings',
        },
    });

    const {
        data: businessGroupings,
        isLoading: isBusinessGroupingsLoading,
        isError: isBusinessGroupingsError,
        error: BusinessGroupingsError,
        isSuccess: isBusinessGroupingsSuccess,
        refetch: refetchBusinessGroupings,
    } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.loadBusinessGroupings],

        queryFn: async () => {
            const response = await businessGroupingsApi.loadBusinessGroupings();
            const sortedBusinessGroupingUsers: IBusinessGrouping[] = response.data.map((group: IBusinessGrouping) => {
                return {
                    ...group,
                    users: group.users.sort(function (a, b) {
                        if (a.given_name.toLowerCase() < b.given_name.toLowerCase()) return -1;
                        if (a.given_name.toLowerCase() > b.given_name.toLowerCase()) return 1;
                        return 0;
                    }),
                } as IBusinessGrouping;
            });

            return sortedBusinessGroupingUsers;
        },

        meta: {
            errorMessage: 'There was a problem loading the data',
        },
    });

    const {
        data: businessGrouping,
        isLoading: isBusinessGroupingLoading,
        isError: isBusinessGroupingError,
        error: BusinessGroupingError,
        isSuccess: isBusinessGroupingSuccess,
        refetch: refetchBusinessGrouping,
    } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.loadBusinessGroupings, businessGroupingId],

        queryFn: async () => {
            if (businessGroupingId !== undefined) {
                const response = await businessGroupingsApi.getBusinessGrouping({ id: businessGroupingId });

                return response.data;
            }
        },

        enabled: businessGroupingId !== undefined,

        meta: {
            errorMessage: 'There was a problem loading the business grouping',
        },
    });

    const {
        data: initialBusinessGroupings,
        isLoading: isInitialBusinessGroupingsLoading,
        isError: isInitialBusinessGroupingsError,
        error: initialBusinessGroupingsError,
        isSuccess: isInitialBusinessGroupingsSuccess,
        refetch: refetchInitialBusinessGroupings,
    } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.loadInitialBusinessGroupings],

        queryFn: async () => {
            const response = await businessGroupingsApi.getBusinessGroupingTreeNodes();

            return response.data;
        },

        meta: {
            errorMessage: 'There was a problem loading the data',
        },
    });

    const { data: myBusinessUnitTree } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.getMyBusinessGroupingTree],

        queryFn: async () => {
            const response = await businessGroupingsApi.getMyBusinessGroupingTree();
            return response.data;
        },
        meta: {
            errorMessage: 'There was a problem loading myBusinessUnitTree',
        },
    });

    const { data: validBusinessGroupingParentsTree } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.getValidBusinessGroupingParentsTree, businessGroupingId],
        queryFn: async () => {
            const response = await businessGroupingsApi.getMyBusinessGroupingTree({ id: businessGroupingId });
            return response.data;
        },
        enabled: businessGroupingId !== undefined,
        meta: {
            errorMessage: 'There was a problem loading the valid business grouping parents tree',
        },
    });

    const { data: ownedBusinessGroupingsByUser } = useQuery({
        queryKey: [queryKeys.businessGroupingsApi.getOwnedBusinessGroupingsByUserId, userId],
        queryFn: async () => {
            if (userId !== undefined) {
                const response = await businessGroupingsApi.getOwnedBusinessGroupingsByUserId(userId);
                return response.data;
            }
        },
        enabled: userId !== undefined,
        meta: {
            errorMessage: 'There was a problem loading the business groupings owned by user',
        },
    });

    const {
        mutate: onSubmitCreateBusinessGrouping,
        isSuccess: isBusinessGroupingCreateSuccessful,
        isPending: isBusinessGroupingCreateLoading,
    } = useMutation({
        onError: (error: any, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadBusinessGroupings] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the business grouping: ${
                    error.response && error.response.data && error.response.data.detail && error.response.data.detail.includes('already exists')
                        ? `The business group, ${variables.request.name}, already exists`
                        : SnackbarErrorOutput(error ?? '')
                }`,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadInitialBusinessGroupings] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.getBusinessGroupingTreeNodes, variables.request.parent_id] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadBusinessGroupings] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadUserBusinessGroupings] });
            navigate(`/${routeUrls.businessGroupings.path}`);
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Successfully created business grouping`,
            });
        },
        mutationFn: async (hookRequest: IPostBusinessGroupHookObject) => {
            return businessGroupingsApi.createBusinessGrouping(hookRequest.request);
        },
    });

    const {
        mutate: onSubmitEditBusinessGrouping,
        isSuccess: isBusinessGroupingEditSuccessful,
        isPending: isBusinessGroupingEditLoading,
    } = useMutation({
        onError: (error, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadBusinessGroupings] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem updating the business grouping: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadInitialBusinessGroupings] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.getBusinessGroupingTreeNodes] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadBusinessGroupings] });
            queryClient.invalidateQueries({ queryKey: [queryKeys.businessGroupingsApi.loadUserBusinessGroupings] });
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Successfully updated business grouping`,
            });
        },
        mutationFn: async (hookRequest: IPutBusinessGroupHookObject) => {
            return businessGroupingsApi.updateBusinessGrouping(hookRequest.request, hookRequest.businessUnitID);
        },
    });

    return {
        businessGrouping: businessGrouping,
        businessGroupings: businessGroupings,
        businessGroupingTypes: businessGroupingTypes,
        initialBusinessGroupings: initialBusinessGroupings,
        isBusinessGroupingCreateLoading: isBusinessGroupingCreateLoading,
        isBusinessGroupingCreateSuccessful: isBusinessGroupingCreateSuccessful,
        isBusinessGroupingEditLoading: isBusinessGroupingEditLoading,
        isBusinessGroupingEditSuccessful: isBusinessGroupingEditSuccessful,
        isBusinessGroupingLoading: isBusinessGroupingLoading,
        isBusinessGroupingsLoading: isBusinessGroupingsLoading,
        isInitialBusinessGroupingsLoading: isInitialBusinessGroupingsLoading,
        isValidParentBusinessGroupingsLoading: isValidParentBusinessGroupingsLoading,
        isValidParentBusinessGroupingsSuccess: isValidParentBusinessGroupingsSuccess,
        myBusinessUnitTree,
        onSubmitCreateBusinessGrouping: onSubmitCreateBusinessGrouping,
        onSubmitEditBusinessGrouping: onSubmitEditBusinessGrouping,
        refetchBusinessGroupings: refetchBusinessGroupings,
        refetchBusinessGroupingTypes: refetchBusinessGroupingTypes,
        refetchInitialBusinessGroupings: refetchInitialBusinessGroupings,
        refetchValidParentBusinessGroupings: refetchValidParentBusinessGroupings,
        setBusinessGroupingId: setBusinessGroupingId,
        setBusinessGroupToEdit: setBusinessGroupToEdit,
        validParentBusinessGroupings: validParentBusinessGroupings,
        validBusinessGroupingParentsTree,
        ownedBusinessGroupingsByUser: ownedBusinessGroupingsByUser ?? [],
        setUserId: setUserId,
    };
}

export default useBusinessGroupingsApi;
