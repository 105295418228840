import React, { useState, useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { IParkingSchedule, IBusinessGroupingNode } from '@vegaplatformui/models';
import { ITimeZone, timezoneSet, useRouteUrls } from '@vegaplatformui/utils';
import Box from '@mui/material/Box';
import { FormField } from '../../forms';
import { BusinessGroupingPicker } from '../../business-grouping-picker/business-grouping-picker';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { areParkingScheduleTabsDirty, ParkingScheduleBusinessGroupId } from '../../recoil/atom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { setSelectedNodes } from '../../utilities/selecting-assigned-business-grouping';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDetailsTabProps {
    scheduleDescription: string | undefined;
    setScheduleDescription: (description: string) => void;
    scheduleName: string | undefined;
    setScheduleName: (name: string) => void;
    timezone: ITimeZone | undefined;
    setTimezone: (timezone: ITimeZone | undefined) => void;
    scheduleToEdit?: IParkingSchedule;
    businessGroupingId: string;
    loadParkableResources?: (bg_id?: string) => void;
    businessGroupHasResources: boolean;
    isLoading: boolean;
    businessGroupings: TreeNodeProps[];
}

const DetailsTab: React.FC<IDetailsTabProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedBusinessGroupingName, setSelectedBusinessGroupingName] = useState<string>('');
    const setBusinessGroupingIDState = useSetRecoilState(ParkingScheduleBusinessGroupId);
    const [areTabsDirty, setAreTabsAreDirty] = useRecoilState(areParkingScheduleTabsDirty);
    const [BGs, setBGs] = useState(setSelectedNodes(props.businessGroupings, [props.businessGroupingId]));
    const location = useLocation();
    const { routeUrls } = useRouteUrls({});

    useEffect(() => {
        setBGs(setSelectedNodes(props.businessGroupings, [props.businessGroupingId]));
    }, [props.businessGroupings]);

    const changeHandlerOnDropDownTreeSelect = (selectedNodes: IBusinessGroupingNode[]) => {
        !areTabsDirty && setAreTabsAreDirty(true);
        setBusinessGroupingIDState(selectedNodes[0]?.id || '');
        setSelectedBusinessGroupingName(selectedNodes[0]?.name || '');
    };

    return (
        <Grid spacing={1} container direction={'row'}>
            <Grid item xs={12}>
                <Typography variant={'h6'}>{props.scheduleToEdit ? 'Edit schedule details' : 'Create a recurring schedule'}</Typography>
                <Typography variant={'subtitle1'}>Fill in the details for the schedule.</Typography>
            </Grid>
            <Grid item xs={6}>
                <FormField label='Schedule Name' htmlFor='schedule_name'>
                    <TextField
                        onChange={(e) => {
                            !areTabsDirty && setAreTabsAreDirty(true);
                            props.setScheduleName(e.target.value);
                        }}
                        id={'schedule_name'}
                        fullWidth={true}
                        size='small'
                        defaultValue={props.scheduleName}
                        placeholder={'Create a name for your schedule'}
                    />
                </FormField>
            </Grid>
            <Grid item xs={6}>
                <FormField label='Time Zone' htmlFor='time_zone'>
                    <FormControl id={'time_zone'} fullWidth>
                        {props.timezone === undefined ? (
                            <InputLabel
                                className={cx(classes.TimeZoneInputLabel)}
                                disableAnimation
                                shrink={false}
                                focused={false}
                                id='item_type_label'
                            >
                                Select a time zone
                            </InputLabel>
                        ) : null}
                        <Select
                            id={'time_zone'}
                            className={cx(classes.Dropdowns)}
                            size={'small'}
                            value={props.timezone?.tzCode ?? ''}
                            displayEmpty={true}
                            aria-label={'Select a time zone'}
                            onChange={(e) => {
                                !areTabsDirty && setAreTabsAreDirty(true);
                                props.setTimezone(timezoneSet.find((s: ITimeZone) => s.tzCode === e.target.value));
                            }}
                        >
                            {timezoneSet.map((timezone: ITimeZone) => (
                                <MenuItem key={timezone.tzCode} value={timezone.tzCode}>
                                    {timezone.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormField>
            </Grid>
            <Grid item xs={12} sx={{ position: 'relative', bottom: 0 }}>
                <FormField
                    label={
                        <Typography variant={'inherit'}>
                            Description{' '}
                            <Box className={cx(classes.OptionalBox)} component={'span'} display='inline'>
                                (optional)
                            </Box>
                        </Typography>
                    }
                    htmlFor='description'
                >
                    <TextField
                        onChange={(e) => {
                            !areTabsDirty && setAreTabsAreDirty(true);
                            props.setScheduleDescription(e.target.value);
                        }}
                        id={'description'}
                        fullWidth={true}
                        size='small'
                        multiline={true}
                        rows={3}
                        defaultValue={props.scheduleDescription}
                        placeholder={'Add a description'}
                    />
                </FormField>
            </Grid>
            <Grid item xs={12}>
                <FormField
                    label='Which business group, of which you are a member, has permission to access this schedule?'
                    htmlFor='business_grouping_select'
                >
                    <>
                        <BusinessGroupingPicker
                            readonly={location.pathname.includes(routeUrls.businessGroupings.path)}
                            changeHandler={changeHandlerOnDropDownTreeSelect}
                            mode='radioSelect'
                            data={BGs}
                            setData={setBGs}
                        />
                        {props.isLoading && (
                            <Box sx={{ width: '100%', marginTop: '-0.2rem', borderRadius: 3 }}>
                                <LinearProgress />
                            </Box>
                        )}
                        {!props.businessGroupHasResources && !props.isLoading && selectedBusinessGroupingName && (
                            <FormHelperText>
                                <Typography variant={'caption'} color={'error'}>
                                    {`No resources found for ${selectedBusinessGroupingName}`}
                                </Typography>
                            </FormHelperText>
                        )}
                    </>
                </FormField>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IDetailsTabProps>()((theme, props) => ({
    Dropdowns: {
        minWidth: '100%',
    },
    OptionalBox: { color: theme.palette.grey[400] },
    TimeZoneInputLabel: {
        marginTop: '-.5rem',
        color: '#9C9CA7',
    },
}));

export { DetailsTab };
