import { Avatar, Button, Grid, Stack, TextField, Typography, Select, MenuItem, SelectChangeEvent, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, FormField } from '../forms';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IBusinessGrouping, IPutUserV2HookRequest, IUserSettingAddress, IUserSettingProfile } from '@vegaplatformui/models';
import { fieldToStr, regexList, usStateList } from '@vegaplatformui/utils';
import { countries } from 'countries-list';
import { QuicksightReportLoadingSkeleton } from '../quicksight-reports/quicksight-report-loading-skeleton';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SnackBarOptions, UserId } from '../recoil/atom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface IProfileSettingsProps {
    isLoading: boolean;
    profile: IUserSettingProfile;
    address: IUserSettingAddress;
    onProfileUpdate: (data: IPutUserV2HookRequest) => void;
    onAddressUpdate: (data: IPutUserV2HookRequest) => void;
    onPhotoUpload: (data: File) => void;
    onPhotoRemove: () => void;
}

const profileFields = ['first_name', 'last_name', 'email', 'phone'] as const;
type ProfileFieldUnion = (typeof profileFields)[number];
const addressFields = ['street_address', 'city', 'zip_code'] as const;
type AddressFieldUnion = (typeof addressFields)[number];

const regex = {
    ...regexList,
    first_name: regexList.alphanumeric_contains_space_and_hyphen,
    last_name: regexList.alphanumeric_contains_space_and_hyphen,
    country: regexList.alphabetic_with_white_space,
    city: regexList.alphabetic_with_white_space,
    zip_code: regexList.number,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const validationProfileDetailsSchema: yup.ObjectSchema<IUserSettingProfile> = yup.object().shape({
    first_name: yup
        .string()
        .required('Required')
        .matches(regex.first_name, 'Must start and end with a letter or number, and can contain hyphens or spaces.'),
    last_name: yup
        .string()
        .required('Required')
        .matches(regex.last_name, 'Must start and end with a letter or number, and can contain hyphens or spaces.'),
    email: yup.string().required('Required').matches(regex.email, 'Invalid email address.'),
    phone: yup
        .string()
        .notRequired()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .matches(regexList.phone, 'Invalid phone number format. Please enter a 10-digit phone number, such as 2342813454'),
    username: yup.string().required(),
    image: yup.string(),
    business_groupings: yup.array<IBusinessGrouping[]>(),
});
const validationProfileAddressSchema: yup.ObjectSchema<IUserSettingAddress> = yup.object().shape({
    street_address: yup.string().required('Required').matches(regex.street_address, 'Required to be alphabetic.'),
    zip_code: yup.string().required('Required').matches(regex.zip_code, 'Required to be numeric.'),
    city: yup.string().required('Required').matches(regex.city, 'Required to be alphabetic.'),
    country: yup.string().required('Required'),
    state: yup.string().when('country', {
        is: (value: string) => {
            return value !== 'US';
        },
        then: (schema) => schema.nullable(),
        otherwise: (schema) => schema.required('Required'),
    }),
});

const ProfileSettings: React.FC<IProfileSettingsProps> = (props) => {
    const theme = useTheme();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const currentUserId = useRecoilValue(UserId);
    const countryOptions = Object.entries(countries).map(([code, country]) => ({
        code,
        country,
    }));

    const [addressFieldsBySelect, setAddressFieldsBySelect] = useState({
        state: props.address.state,
        country: props.address.country,
    });
    const flags = useFlags();
    const [isProfileFieldsChanged, setisProfileFieldsChanged] = useState(false);
    const [isAddressFieldsChanged, setIsAddressFieldsChanged] = useState(false);
    const [isAddressFieldsBySelectChanged, setIsAddressFieldsBySelectChanged] = useState(false);
    const [disableStateSelect, setDisableStateSelect] = useState(props.address.country !== 'US');

    const {
        handleSubmit: handleDetailsSubmit,
        control: detailsControl,
        reset: detailsReset,
        formState: detailsFormState,
        formState: { isDirty: isDetailsDirty, errors: detailsErrors, isSubmitSuccessful: isDetailsSubmitSuccessful },
    } = useForm<IUserSettingProfile>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationProfileDetailsSchema),
        defaultValues: {
            first_name: props.profile.first_name ?? '',
            last_name: props.profile.last_name ?? '',
            email: props.profile.email ?? '',
            phone: props.profile.phone ?? '',
            username: props.profile.email ?? '',
        },
    });

    const {
        handleSubmit: handleAddressSubmit,
        control: addressControl,
        reset: addressReset,
        formState: addressFormState,
        setValue: setAddressValue,
        formState: { isDirty: isAddressDirty, errors: addressErrors, isSubmitSuccessful: isAddressSubmitSuccessful },
    } = useForm<IUserSettingAddress>({
        reValidateMode: 'onChange',
        mode: 'onSubmit',
        resolver: yupResolver(validationProfileAddressSchema),
        defaultValues: {
            street_address: props.address.street_address ?? '',
            zip_code: props.address.zip_code ?? '',
            city: props.address.city ?? '',
            country: props.address.country ?? null,
            state: props.address.state ?? null,
        },
    });

    useEffect(() => {
        if (props.profile !== undefined) {
            detailsReset(
                {
                    first_name: props.profile.first_name ?? '',
                    last_name: props.profile.last_name ?? '',
                    email: props.profile.email ?? '',
                    phone: props.profile.phone ?? '',
                    username: props.profile.email ?? '',
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [props.profile]);

    useEffect(() => {
        if (props.address !== undefined) {
            addressReset(
                {
                    street_address: props.address.street_address ?? '',
                    zip_code: props.address.zip_code ?? '',
                    city: props.address.city ?? '',
                    country: props.address.country ?? null,
                    state: props.address.state ?? null,
                },
                { keepDirty: true, keepIsValid: true }
            );
            setAddressFieldsBySelect((prev) => ({
                ...prev,
                country: props.address.country,
                state: props.address.state,
            }));
            setDisableStateSelect(props.address.country !== 'US');
        }
    }, [props.address]);

    const updatePhoto = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.files?.length) {
            const file = evt.target.files[0];
            const ext = file.type.split('/')[1];

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                    props.onPhotoUpload(file);
                    evt.currentTarget.value = '';
                    break;
                default:
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `This file type is not allowed please use a.jpeg, png, .jpg or .gif`,
                    });
                    evt.currentTarget.value = '';
                    break;
            }
        }
    };

    const handleCountrySelect = (event: SelectChangeEvent<string>) => {
        const fieldValue = event.target.value;
        setAddressFieldsBySelect((prev) => ({
            ...prev,
            ['country']: fieldValue,
        }));
        //Setting the form state value
        setAddressValue('country', fieldValue);
        setIsAddressFieldsBySelectChanged(true);
        setDisableStateSelect(fieldValue !== 'US');
    };

    const handleStateSelect = (event: SelectChangeEvent<string>) => {
        const fieldValue = event.target.value;
        setAddressFieldsBySelect((prev) => ({
            ...prev,
            ['state']: fieldValue,
        }));
        //Setting the form state value
        setAddressValue('state', fieldValue);
        setIsAddressFieldsBySelectChanged(true);
    };

    const onProfileChange = () => {
        setisProfileFieldsChanged(true);
    };

    const onAddressChange = () => {
        setIsAddressFieldsChanged(true);
    };

    const onProfileSubmit: SubmitHandler<IUserSettingProfile> = (data) => {
        setisProfileFieldsChanged(false);
        if (currentUserId) {
            props.onProfileUpdate({
                request: { family_name: data.last_name, given_name: data.first_name, mobile_phone: !!data.phone ? data.phone : undefined },
                user_id: currentUserId,
            });
        }
    };

    const onAddressSubmit: SubmitHandler<IUserSettingAddress> = (data) => {
        data.country = addressFieldsBySelect.country;
        data.state = addressFieldsBySelect.state;
        if (data.country !== 'US') data.state = '';
        setIsAddressFieldsBySelectChanged(false);
        setIsAddressFieldsChanged(false);
        if (currentUserId) {
            props.onAddressUpdate({
                request: {
                    street_address: data.street_address,
                    city: data.city,
                    country: data.country,
                    state: data.state ?? '',
                    zip_code: data.zip_code,
                },
                user_id: currentUserId,
            });
        }
    };

    return (
        <div>
            {props.isLoading ? (
                <QuicksightReportLoadingSkeleton />
            ) : (
                <>
                    {/* Profile Info */}
                    <Grid
                        id={'profile-details-form'}
                        component={'form'}
                        container
                        spacing={2}
                        onSubmit={handleDetailsSubmit((data) => {
                            onProfileSubmit(data);
                        })}
                        onChange={onProfileChange}
                    >
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography variant='h3'>My Profile</Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    Manage your personal information.
                                </Typography>
                            </Stack>
                        </Grid>
                        {profileFields.map((profileField: ProfileFieldUnion, idx) => (
                            <Grid item xs={12} sm={6} key={`${profileField}_${idx}`}>
                                <FormField label={fieldToStr(profileField)} htmlFor={profileField}>
                                    <Controller
                                        name={profileField}
                                        control={detailsControl}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id={profileField}
                                                size='small'
                                                fullWidth={true}
                                                inputProps={{ readOnly: profileField === 'email' }}
                                                value={value}
                                                onChange={(e) => onChange(e.target.value)}
                                                error={!!detailsErrors[profileField]}
                                                helperText={detailsErrors[profileField]?.message}
                                            />
                                        )}
                                    />
                                </FormField>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <FormField label='Photo' htmlFor='image'>
                                <Grid container columnGap={1} direction='row' alignItems='flex-end'>
                                    {props.profile.image && (
                                        <Grid item>
                                            <Avatar
                                                sx={{ width: 80, height: 80 }}
                                                src={props.profile.image}
                                                alt={`profile avatar ${props.profile.image}`}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button
                                            disabled={props.profile.image ? true : false}
                                            color='primary'
                                            variant='contained'
                                            component='label'
                                            size='small'
                                        >
                                            Upload
                                            <input
                                                hidden
                                                id='image'
                                                type='file'
                                                accept='.jpeg,.png,.jpg,.gif'
                                                disabled={props.profile.image ? true : false}
                                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                                    updatePhoto(evt);
                                                }}
                                            />
                                        </Button>
                                    </Grid>
                                    {props.profile.image && (
                                        <Grid item>
                                            <Button
                                                size='small'
                                                variant='text'
                                                onClick={() => {
                                                    props.onPhotoRemove();
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </FormField>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2} direction={'row'} justifyContent={'end'}>
                                <Button
                                    variant={'cancel'}
                                    onClick={() => {
                                        detailsReset({
                                            first_name: props.profile.first_name,
                                            last_name: props.profile.last_name,
                                            email: props.profile.email,
                                            phone: props.profile.phone,
                                        });
                                        setisProfileFieldsChanged(false);
                                    }}
                                    disabled={!isDetailsDirty || !isProfileFieldsChanged}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    disabled={!isDetailsDirty || !isProfileFieldsChanged}
                                    type='submit'
                                    form={'profile-details-form'}
                                    disableElevation
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    {/* Address Info */}
                    <Grid
                        id={'profile-address-form'}
                        component={'form'}
                        container
                        spacing={2}
                        onSubmit={handleAddressSubmit((data) => {
                            onAddressSubmit(data);
                        })}
                        onChange={onAddressChange}
                    >
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography variant='h3'>Shipping Address</Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    This is where we'll send Cloud Heroes rewards.
                                </Typography>
                            </Stack>
                        </Grid>
                        {addressFields.map((addressField: AddressFieldUnion, idx) => (
                            <Grid item xs={12} sm={6} key={`${addressField}_${idx}`}>
                                <FormField label={fieldToStr(addressField)} htmlFor={addressField}>
                                    <Controller
                                        control={addressControl}
                                        name={addressField}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id={addressField}
                                                size='small'
                                                fullWidth={true}
                                                error={!!addressErrors[addressField]}
                                                onChange={(e) => onChange(e.target.value)}
                                                helperText={addressErrors[addressField]?.message}
                                                value={value}
                                            />
                                        )}
                                    />
                                </FormField>
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={6}>
                            <FormField label='Country' htmlFor='country'>
                                <Controller
                                    control={addressControl}
                                    name={'country'}
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            id={'country'}
                                            labelId={'country'}
                                            size='small'
                                            fullWidth={true}
                                            error={!!addressErrors.country}
                                            MenuProps={MenuProps}
                                            value={addressFieldsBySelect.country !== null ? addressFieldsBySelect.country : ''}
                                            onChange={handleCountrySelect}
                                        >
                                            <MenuItem key={'us'} value={'US'}>
                                                United States
                                            </MenuItem>
                                            {countryOptions.map((option) => (
                                                <MenuItem key={option.code} value={option.code}>
                                                    {option.country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormField>
                        </Grid>

                        {!disableStateSelect && (
                            <Grid item xs={12} sm={6}>
                                <FormField label='State' htmlFor='state'>
                                    <Controller
                                        control={addressControl}
                                        name={'state'}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                id={'state'}
                                                labelId={'state'}
                                                size='small'
                                                fullWidth={true}
                                                error={!!addressErrors.state}
                                                MenuProps={MenuProps}
                                                // defaultValue={props.address.state}
                                                value={addressFieldsBySelect.state !== null ? addressFieldsBySelect.state : ''}
                                                onChange={handleStateSelect}
                                                disabled={disableStateSelect}
                                            >
                                                {usStateList.map((option) => (
                                                    <MenuItem key={option.name} value={option.abbreviation}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormField>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Stack spacing={2} direction={'row'} justifyContent={'end'}>
                                <Button
                                    variant={'cancel'}
                                    onClick={() => {
                                        addressReset({
                                            street_address: props.address?.street_address,
                                            country: props.address?.country,
                                            city: props.address?.city,
                                            state: props.address?.state,
                                            zip_code: props.address?.zip_code,
                                        });
                                        setAddressFieldsBySelect({
                                            country: props.address.country,
                                            state: props.address.state,
                                        });
                                        setIsAddressFieldsBySelectChanged(false);
                                        setIsAddressFieldsChanged(false);
                                    }}
                                    disabled={(!isAddressDirty || !isAddressFieldsChanged) && !isAddressFieldsBySelectChanged}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    disabled={(!isAddressDirty || !isAddressFieldsChanged) && !isAddressFieldsBySelectChanged}
                                    type='submit'
                                    form={'profile-address-form'}
                                    disableElevation
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export { ProfileSettings };
