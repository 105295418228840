import {
    IChatbotPostQuestionRequest,
    IChatbotPutQuestionAnswerRequest,
    IChatbotPutTopicRequest,
    IChatbotTopic,
    IChatbotTopicHistory,
} from '@vegaplatformui/models';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { GeminiResponse } from '@vegaplatformui/utils';
import { v4 as uuidv4 } from 'uuid';

export class ChatbotApi extends HttpClient {
    protected static classInstance?: ChatbotApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_CHATBOT_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ChatbotApi();
        }
        return this.classInstance;
    }

    public postQuestion = (requestBody: IChatbotPostQuestionRequest) =>
        fetch(`https://${process.env.NX_PUBLIC_CHATBOT_URL!}/ask`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.keycloak.token}`,
                'Access-Control-Allow-Origin': 'https://local.vegacloud.io:3001',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers': '*',
            },
            body: JSON.stringify(requestBody),
        });

    public getHistory = (): GeminiResponse<IChatbotTopicHistory> => this.instance.get('/history');
    public clearHistory = (): GeminiResponse<string> => this.instance.delete(`/history`);
    public postTopic = (requestBody: IChatbotTopic) =>
        fetch(`https://${process.env.NX_PUBLIC_CHATBOT_URL!}/history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.keycloak.token}`,
                'Access-Control-Allow-Origin': 'https://local.vegacloud.io:3001',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Headers': '*',
            },
            body: JSON.stringify(requestBody),
        });
    public deleteTopic = (topicId: string): GeminiResponse<string> => this.instance.delete(`/history/${topicId}`);
    public putQuestionAnswerPair = (topicId: string, requestBody: IChatbotPutQuestionAnswerRequest) =>
        this.instance.put(`/history/${topicId}/question_and_answer_pair`, requestBody);
    public renameTopic = (requestBody: IChatbotPutTopicRequest) =>
        this.instance.put(`history/${requestBody.topicId}/rename`, { new_name: requestBody.new_name });
}
