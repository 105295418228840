import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Button,
    Grid,
    IconButton,
    Checkbox,
    FormControlLabel,
    DialogActions,
} from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IClientRegistrationSuccessDialogProps } from './client-registration-success-dialog';

export interface IClientDeletionDialogProps {
    isDialogOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const ClientDeletionDialog: React.FC<IClientDeletionDialogProps> = ({ isDialogOpen, onClose, onDelete }) => {
    const { classes, cx } = useStyles({ isDialogOpen, onClose, onDelete });
    const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

    const onDeleteConfirmation = () => {
        setIsCheckboxChecked(false);
        onDelete();
        onClose();
    };

    const onClickCancelButton = () => {
        setIsCheckboxChecked(false);
        onClose();
    };

    return (
        <Dialog open={isDialogOpen} aria-labelledby='delete-client-dialog-title' aria-describedby='delete-client-form'>
            <DialogTitle id='delete-client-dialog-title'>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ErrorOutline color={'error'} />
                    <Typography variant={'h6'}> Delete API client registration</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent id={'delete-client-form'}>
                <Typography variant='body2'>Are you sure you want to delete this API client registration?</Typography>
                <Typography variant='body2'>This action cannot be undone.</Typography>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <FormControlLabel
                    className={cx(classes.DialogStartButton)}
                    control={<Checkbox value={isCheckboxChecked} onChange={(event, checked) => setIsCheckboxChecked(checked)} />}
                    label={<Typography variant={'body2'}>I understand</Typography>}
                />
                <Stack direction={'row'} alignItems={'center'} sx={{ mr: '1rem' }} justifyContent={'flex-end'} spacing={1.5}>
                    <Button variant='cancel' onClick={onClickCancelButton} disabled={false}>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={onDeleteConfirmation} disabled={!isCheckboxChecked}>
                        Delete
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IClientDeletionDialogProps>()((theme, props) => ({
    DialogActions: {
        marginBottom: '1rem',
        justifyContent: 'space-between',
    },
    DialogStartButton: {
        marginLeft: '0.5rem',
    },
}));

export { ClientDeletionDialog };
