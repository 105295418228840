import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IConfirmChangeIntervalDialogProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmChangeIntervalDialog: React.FC<IConfirmChangeIntervalDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose}>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>{'Change schedule time interval?'}</DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <DialogContentText id='alert-dialog-description'>
                    Changing the schedule interval will clear existing schedule entries. Are you sure you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack width={'100%'} direction={'row'} spacing={1.5} justifyContent={'flex-end'}>
                    <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={props.onConfirm}>Confirm</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IConfirmChangeIntervalDialogProps>()((theme, props) => ({}));

export { ConfirmChangeIntervalDialog };
