import React, { useState, useMemo, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Alert,
    Divider,
    Chip,
    Typography,
    Paper,
    Stack,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Badge,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import FilterListIcon from '@mui/icons-material/FilterList';
import SaveIcon from '@mui/icons-material/Save';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import { QueryBuilder, RuleGroupType, Field, formatQuery, RuleType, FormatQueryOptions, ValueProcessorLegacy } from 'react-querybuilder';
import { QueryBuilderMaterial } from '@react-querybuilder/material';
import 'react-querybuilder/dist/query-builder.css';
import { customMakeStyles } from '@vegaplatformui/styling';
import { StyledToolTip } from '../../../utilities/styled-tooltip';
import { FilterInfo, SavedFilter } from '@vegaplatformui/models';
import { BlackOnWhiteButton } from '../../../utilities/black-on-white-button';

export interface IDataFiltersProps {
    open: boolean;
    onClose: () => void;
    onApply: (filters: RuleGroupType) => void;
    initialFilters?: RuleGroupType;
    onSave?: (filter: SavedFilter) => Promise<void>;
    onLoad?: () => Promise<SavedFilter[]>;
    onDelete?: (filterId: string) => Promise<void>;
}

// Format operator for display
const formatOperator = (operator: string): string => {
    switch (operator) {
        case '=':
            return 'is';
        case '!=':
            return 'is not';
        case '>':
            return 'greater than';
        case '<':
            return 'less than';
        case 'contains':
            return 'contains';
        case '!contains':
            return 'does not contain';
        case 'beginsWith':
            return 'begins with';
        case 'endsWith':
            return 'ends with';
        case 'between':
            return 'between';
        default:
            return operator;
    }
};

// Format value for display
const formatValue = (value: string | number | string[], field: Field): string => {
    if (Array.isArray(value)) {
        return value.join(', ');
    }
    if (field.name === 'cost') {
        return `$${value}`;
    }
    return String(value);
};

const fields: Field[] = [
    {
        name: 'cloudProvider',
        label: 'Cloud Provider',
        operators: [
            { name: '=', label: 'equals' },
            { name: '!=', label: 'does not equal' },
        ],
        valueEditorType: 'multiselect',
        values: [
            { name: 'AWS', label: 'AWS' },
            { name: 'GCP', label: 'GCP' },
            { name: 'Azure', label: 'Azure' },
            { name: 'OCI', label: 'OCI' },
            { name: 'Kubernetes', label: 'Kubernetes' },
        ],
        defaultOperator: '=',
    },
    {
        name: 'service',
        label: 'Service',
        operators: [
            { name: '=', label: 'equals' },
            { name: '!=', label: 'does not equal' },
        ],
        valueEditorType: 'multiselect',
        values: [
            { name: 'Compute', label: 'Compute' },
            { name: 'Storage', label: 'Storage' },
            { name: 'Database', label: 'Database' },
            { name: 'Networking', label: 'Networking' },
            { name: 'AI/ML', label: 'AI/ML' },
        ],
        defaultOperator: '=',
    },
    {
        name: 'region',
        label: 'Region',
        operators: [
            { name: '=', label: 'equals' },
            { name: '!=', label: 'does not equal' },
        ],
        valueEditorType: 'multiselect',
        values: [
            { name: 'us-east-1', label: 'US East (N. Virginia)' },
            { name: 'us-west-1', label: 'US West (N. California)' },
            { name: 'europe-west1', label: 'Europe West' },
            { name: 'asia-southeast1', label: 'Asia Southeast' },
        ],
        defaultOperator: '=',
    },
    {
        name: 'cost',
        label: 'Cost ($)',
        operators: [
            { name: '>', label: 'greater than' },
            { name: '<', label: 'less than' },
            { name: '=', label: 'equals' },
            { name: '!=', label: 'does not equal' },
            { name: 'between', label: 'between' },
        ],
        inputType: 'number',
        valueEditorType: 'text',
        defaultOperator: '>',
    },
    {
        name: 'usageType',
        label: 'Usage Type',
        operators: [
            { name: '=', label: 'equals' },
            { name: '!=', label: 'does not equal' },
        ],
        valueEditorType: 'multiselect',
        values: [
            { name: 'On-Demand', label: 'On-Demand' },
            { name: 'Reserved', label: 'Reserved' },
            { name: 'Spot', label: 'Spot' },
        ],
        defaultOperator: '=',
    },
    {
        name: 'tag',
        label: 'Resource Tag',
        operators: [
            { name: 'contains', label: 'contains' },
            { name: '!contains', label: 'does not contain' },
            { name: 'beginsWith', label: 'begins with' },
            { name: 'endsWith', label: 'ends with' },
        ],
        valueEditorType: 'text',
        defaultOperator: 'contains',
        placeholder: 'Enter tag key or value',
    },
];

const defaultQuery: RuleGroupType = {
    combinator: 'and',
    rules: [],
};

const formatQueryOptions: FormatQueryOptions = {
    format: 'sql',
    fields,
    fallbackExpression: '',
    paramPrefix: '',
    valueProcessor: ((field, operator, value) => {
        // Handle array values (for multiselect fields)
        if (Array.isArray(value)) {
            return `(${value.map((v) => `'${v}'`).join(', ')})`;
        }

        // Handle numeric fields
        if (field === 'cost' || fields.find((f) => f.name === field)?.inputType === 'number') {
            return String(value);
        }

        // Handle special operators that don't need quotes
        if (operator === 'between') {
            return value;
        }

        // Default to quoted string for text values
        return `'${value}'`;
    }) as ValueProcessorLegacy,
};

type CxFunction = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]): string;
};

interface ComponentProps {
    query: RuleGroupType;
    onRemoveRule: (ruleId: string) => void;
    classes: Record<string, string>;
    cx: CxFunction;
}

const ActiveFilters: React.FC<ComponentProps> = ({ query, onRemoveRule, classes, cx }) => {
    const activeFilters = useMemo(() => {
        const filters: FilterInfo[] = [];
        const processRules = (rules: Array<RuleType | RuleGroupType>) => {
            rules.forEach((rule) => {
                if ('rules' in rule) {
                    processRules(rule.rules);
                } else if ('field' in rule && rule.id) {
                    const field = fields.find((f) => f.name === rule.field);
                    if (field) {
                        filters.push({
                            id: rule.id,
                            field,
                            operator: rule.operator,
                            value: rule.value,
                        });
                    }
                }
            });
        };
        processRules(query.rules);
        return filters;
    }, [query]);

    if (activeFilters.length === 0) return null;

    return (
        <Box className={cx(classes.activeFiltersContainer)}>
            <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                Active Filters:
            </Typography>
            <Stack direction='row' spacing={1} flexWrap='wrap' useFlexGap>
                {activeFilters.map(({ id, field, operator, value }) => (
                    <Chip
                        key={id}
                        label={`${field.label} ${formatOperator(operator)} ${formatValue(value, field)}`}
                        onDelete={() => onRemoveRule(id)}
                        size='small'
                        className={cx(classes.filterChip)}
                    />
                ))}
            </Stack>
        </Box>
    );
};

// Then update both interfaces
interface FilterPreviewProps {
    query: RuleGroupType;
    classes: Record<string, string>;
    cx: CxFunction;
}

const FilterPreview: React.FC<FilterPreviewProps> = ({ query, classes, cx }) => {
    const previewText = useMemo(() => {
        if (query.rules.length === 0) return null;
        return formatQuery(query, formatQueryOptions);
    }, [query]);

    if (!previewText) return null;

    return (
        <Paper variant='outlined' className={cx(classes.previewContainer)}>
            <Typography variant='subtitle2' color='text.secondary' gutterBottom>
                Filter Preview:
            </Typography>
            <Typography variant='body2' component='pre' className={cx(classes.previewText)}>
                {previewText}
            </Typography>
        </Paper>
    );
};

const DataFilters: React.FC<IDataFiltersProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { open, onClose, onApply, initialFilters, onSave, onLoad, onDelete } = props;

    const [query, setQuery] = useState<RuleGroupType>(initialFilters || defaultQuery);
    const [showHelp, setShowHelp] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
    const [loadingFilters, setLoadingFilters] = useState(false);
    const [filterHistory, setFilterHistory] = useState<RuleGroupType[]>([]);
    const [showHistory, setShowHistory] = useState(false);

    // Load saved filters on initial mount
    useEffect(() => {
        if (onLoad) {
            setLoadingFilters(true);
            onLoad()
                .then((filters) => setSavedFilters(filters))
                .catch((error) => console.error('Error loading filters:', error))
                .finally(() => setLoadingFilters(false));
        }
    }, [onLoad]);

    // Track filter history
    useEffect(() => {
        if (query.rules.length > 0) {
            setFilterHistory((prev) => [...prev, query].slice(-10)); // Keep last 10 changes
        }
    }, [query]);

    const handleApply = () => {
        if (query.rules.length === 0) {
            setShowHelp(true);
            return;
        }
        onApply(query);
        onClose();
    };

    const handleReset = () => {
        setQuery(defaultQuery);
        setShowHelp(false);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleSaveFilter = async () => {
        if (!onSave) return;

        const name = prompt('Enter a name for this filter:');
        if (!name) return;

        const newFilter: SavedFilter = {
            id: Date.now().toString(),
            name,
            filters: query,
            createdAt: new Date().toISOString(),
            lastModified: new Date().toISOString(),
        };

        try {
            await onSave(newFilter);
            setSavedFilters((prev) => [...prev, newFilter]);
        } catch (error) {
            console.error('Error saving filter:', error);
            alert('Failed to save filter');
        }
        handleMenuClose();
    };

    const handleLoadFilter = (filter: SavedFilter) => {
        setQuery(filter.filters);
        handleMenuClose();
    };

    const handleDeleteFilter = async (filter: SavedFilter) => {
        if (!onDelete) return;

        try {
            await onDelete(filter.id);
            setSavedFilters((prev) => prev.filter((f) => f.id !== filter.id));
        } catch (error) {
            console.error('Error deleting filter:', error);
            alert('Failed to delete filter');
        }
        handleMenuClose();
    };

    const handleDuplicateFilter = () => {
        setQuery((prev) => ({
            ...prev,
            rules: [...prev.rules],
        }));
        handleMenuClose();
    };

    const handleHistoryRestore = (historicFilter: RuleGroupType) => {
        setQuery(historicFilter);
        setShowHistory(false);
    };

    const handleRemoveRule = (ruleId: string) => {
        const removeRuleById = (rules: Array<RuleType | RuleGroupType>): Array<RuleType | RuleGroupType> => {
            return rules.filter((rule) => {
                if ('id' in rule && rule.id === ruleId) return false;
                if ('rules' in rule) {
                    rule.rules = removeRuleById(rule.rules);
                    return rule.rules.length > 0;
                }
                return true;
            });
        };

        setQuery((prev) => ({
            ...prev,
            rules: removeRuleById(prev.rules),
        }));
    };

    const renderFilterStats = useMemo(() => {
        const stats = {
            total: query.rules.length,
            conditions: 0,
            groups: 0,
        };

        const countRules = (rules: Array<RuleType | RuleGroupType>) => {
            rules.forEach((rule) => {
                if ('rules' in rule) {
                    stats.groups++;
                    countRules(rule.rules);
                } else {
                    stats.conditions++;
                }
            });
        };

        countRules(query.rules);
        return stats;
    }, [query]);

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth='md'
                fullWidth
                slotProps={{
                    paper: {
                        className: cx(classes.DialogPaper),
                    },
                }}
            >
                <DialogTitle className={cx(classes.DialogTitle)}>
                    <Box className={cx(classes.TitleContainer)}>
                        <FilterListIcon />
                        <Typography variant='h6' className={cx(classes.TitleText)}>
                            Configure Data Filters
                        </Typography>
                        <StyledToolTip title='Add filters to narrow down the cost information tracked in this budget'>
                            <IconButton size='small' className={cx(classes.InfoButton)}>
                                <InfoIcon fontSize='small' />
                            </IconButton>
                        </StyledToolTip>
                    </Box>
                    <Box className={cx(classes.TitleActions)}>
                        <StyledToolTip title='Filter options'>
                            <IconButton onClick={handleMenuOpen} className={cx(classes.ActionButton)}>
                                <MoreVertIcon />
                            </IconButton>
                        </StyledToolTip>
                        <StyledToolTip title='Filter history'>
                            <IconButton
                                onClick={() => setShowHistory(true)}
                                disabled={filterHistory.length === 0}
                                className={cx(classes.ActionButton)}
                            >
                                <Badge badgeContent={filterHistory.length} color='primary'>
                                    <HistoryIcon />
                                </Badge>
                            </IconButton>
                        </StyledToolTip>
                        <StyledToolTip title='Close'>
                            <IconButton onClick={onClose} className={cx(classes.CloseButton)}>
                                <CloseIcon />
                            </IconButton>
                        </StyledToolTip>
                    </Box>
                </DialogTitle>

                <Divider />

                <DialogContent className={cx(classes.DialogContent)}>
                    {showHelp && (
                        <Alert severity='info' className={cx(classes.HelpAlert)} onClose={() => setShowHelp(false)}>
                            Add at least one filter condition to apply filters to your budget
                        </Alert>
                    )}

                    {/* Filter Stats */}
                    <Paper variant='outlined' className={cx(classes.StatsContainer)}>
                        <Stack direction='row' spacing={3}>
                            <Box className={cx(classes.StatItem)}>
                                <Typography variant='caption' color='text.secondary'>
                                    Total Rules
                                </Typography>
                                <Typography variant='h6'>{renderFilterStats.total}</Typography>
                            </Box>
                            <Box className={cx(classes.StatItem)}>
                                <Typography variant='caption' color='text.secondary'>
                                    Conditions
                                </Typography>
                                <Typography variant='h6'>{renderFilterStats.conditions}</Typography>
                            </Box>
                            <Box className={cx(classes.StatItem)}>
                                <Typography variant='caption' color='text.secondary'>
                                    Groups
                                </Typography>
                                <Typography variant='h6'>{renderFilterStats.groups}</Typography>
                            </Box>
                        </Stack>
                    </Paper>

                    <QueryBuilderMaterial>
                        <QueryBuilder
                            fields={fields}
                            query={query}
                            onQueryChange={setQuery}
                            controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
                            controlElements={{
                                addRuleAction: ({ handleOnClick }) => (
                                    <Button
                                        onClick={handleOnClick}
                                        variant='outlined'
                                        size='small'
                                        className={cx(classes.AddRuleButton)}
                                        startIcon={<FilterListIcon />}
                                    >
                                        Add Filter
                                    </Button>
                                ),
                                addGroupAction: ({ handleOnClick }) => (
                                    <Button onClick={handleOnClick} variant='outlined' size='small' className={cx(classes.AddGroupButton)}>
                                        Add Filter Group
                                    </Button>
                                ),
                            }}
                        />
                    </QueryBuilderMaterial>

                    <ActiveFilters query={query} onRemoveRule={handleRemoveRule} classes={classes} cx={cx} />
                    <FilterPreview query={query} classes={classes} cx={cx} />
                </DialogContent>

                <Divider />

                <DialogActions className={cx(classes.DialogActions)}>
                    <Button onClick={handleReset} variant='outlined' color='inherit'>
                        Reset
                    </Button>
                    <Box sx={{ flex: 1 }} />
                    <BlackOnWhiteButton onClick={onClose}>Cancel</BlackOnWhiteButton>
                    <Button onClick={handleApply} variant='contained' color='primary' disabled={query.rules.length === 0}>
                        Apply Filters
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Options Menu */}
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
                <MenuItem onClick={handleSaveFilter} disabled={query.rules.length === 0}>
                    <ListItemIcon>
                        <SaveIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Save Filter</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDuplicateFilter} disabled={query.rules.length === 0}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Duplicate Filter</ListItemText>
                </MenuItem>
                <Divider />
                {loadingFilters ? (
                    <MenuItem disabled>
                        <ListItemIcon>
                            <CircularProgress size={20} />
                        </ListItemIcon>
                        <ListItemText>Loading saved filters...</ListItemText>
                    </MenuItem>
                ) : savedFilters.length > 0 ? (
                    savedFilters.map((filter) => (
                        <MenuItem key={filter.id} onClick={() => handleLoadFilter(filter)}>
                            <ListItemIcon>
                                <FolderOpenIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary={filter.name} secondary={new Date(filter.lastModified).toLocaleDateString()} />
                            <StyledToolTip title='Delete filter'>
                                <IconButton
                                    size='small'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteFilter(filter);
                                    }}
                                    className={cx(classes.DeleteFilterButton)}
                                >
                                    <DeleteIcon fontSize='small' />
                                </IconButton>
                            </StyledToolTip>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>
                        <ListItemText>No saved filters</ListItemText>
                    </MenuItem>
                )}
            </Menu>

            {/* History Dialog */}
            <Dialog
                open={showHistory}
                onClose={() => setShowHistory(false)}
                maxWidth='sm'
                fullWidth
                slotProps={{
                    paper: {
                        className: cx(classes.HistoryDialogPaper),
                    },
                }}
            >
                <DialogTitle>Filter History</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {filterHistory.map((filter, index) => (
                            <Paper key={index} variant='outlined' className={cx(classes.HistoryItem)} onClick={() => handleHistoryRestore(filter)}>
                                <Typography variant='caption' color='text.secondary'>
                                    Version {filterHistory.length - index}
                                </Typography>
                                <Typography variant='body2' component='pre' className={cx(classes.HistoryItemText)}>
                                    {formatQuery(filter, formatQueryOptions)}
                                </Typography>
                            </Paper>
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowHistory(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const useStyles = customMakeStyles<IDataFiltersProps>()((theme, props) => ({
    DialogPaper: {
        minHeight: '60vh',
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[24],
    },
    DialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    TitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    TitleText: {
        marginLeft: theme.spacing(1),
    },
    TitleActions: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    InfoButton: {
        color: theme.palette.info.main,
    },
    ActionButton: {
        color: theme.palette.text.secondary,
    },
    CloseButton: {
        color: theme.palette.grey[500],
    },
    DialogContent: {
        padding: theme.spacing(2),
    },
    HelpAlert: {
        marginBottom: theme.spacing(2),
    },
    StatsContainer: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    StatItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    AddRuleButton: {
        marginTop: theme.spacing(1),
    },
    AddGroupButton: {
        marginTop: theme.spacing(1),
    },
    ActiveFiltersContainer: {
        marginTop: theme.spacing(2),
    },
    FilterChip: {
        margin: theme.spacing(0.5),
    },
    PreviewContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.grey[50],
    },
    PreviewText: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        margin: 0,
        fontFamily: 'monospace',
    },
    DialogActions: {
        padding: theme.spacing(2),
        gap: theme.spacing(1),
    },
    DeleteFilterButton: {
        color: theme.palette.error.main,
    },
    HistoryDialogPaper: {
        borderRadius: theme.shape.borderRadius * 2,
    },
    HistoryItem: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    HistoryItemText: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        margin: 0,
        fontFamily: 'monospace',
        fontSize: '0.8rem',
    },
}));

export { DataFilters, FilterPreview, ActiveFilters };
