import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Checkbox, FormControlLabel, ListItem, ListItemIcon, ListItemText, Stack, Switch } from '@mui/material';
import { StringCapitalizeAndSpace, StringCapitalizeAndSpaceAll } from '../../../../utilities/string-formatter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPlatformRoleListItemProps {
    role: string;
    description: string;
    isSelected: boolean;
    handleRoleSelect: (checked: boolean, selectedRole: string) => void;
}

const PlatformRoleListItem: React.FC<IPlatformRoleListItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { role, description, isSelected, handleRoleSelect } = props;

    return (
        <ListItem disablePadding>
            <Stack role={'presentation'} direction={'row'} justifyContent='flex-start' alignItems='flex-start' spacing={0}>
                <FormControlLabel
                    className={cx(classes.FormControl)}
                    control={
                        <Checkbox
                            disabled={role === 'read_only'}
                            onChange={(event, checked) => handleRoleSelect(checked, role)}
                            checked={isSelected}
                        />
                    }
                    label={
                        <ListItemText
                            sx={{ margin: 0, paddingTop: '9px' }}
                            primaryTypographyProps={{ className: cx(classes.RoleTypography, classes.PrimaryLabel) }}
                            secondaryTypographyProps={{ className: cx(classes.RoleTypography) }}
                            primary={StringCapitalizeAndSpaceAll(role)}
                            secondary={description}
                        />
                    }
                    labelPlacement='end'
                />
            </Stack>
        </ListItem>
    );
};

const useStyles = customMakeStyles<IPlatformRoleListItemProps>()((theme, props) => ({
    RoleTypography: { fontSize: '12px' },
    PrimaryLabel: { color: theme.palette.text.primary },
    FormControl: { alignItems: 'flex-start', justifyContent: 'flex-start' },
}));

export { PlatformRoleListItem };
