import { IRouteUrls, SettingTypes, VegaRoleHelperTypes } from '@vegaplatformui/models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getVegaRoleTypeValues } from './role-type-values';
import { useEffect, useMemo, useState } from 'react';

export interface IUseRouteUrlsHook {
    routeUrls: IRouteUrls;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUseRouteUrlsProps {}

export function useRouteUrls(props: IUseRouteUrlsProps): IUseRouteUrlsHook {
    const flags = useFlags();
    const organizationalAdminRoles = getVegaRoleTypeValues(VegaRoleHelperTypes.HigherLevel);
    const widerAdminRoles = getVegaRoleTypeValues(VegaRoleHelperTypes.LowerLevel);
    const allRoles = getVegaRoleTypeValues(VegaRoleHelperTypes.All);

    const routeUrls: IRouteUrls = useMemo(() => {
        return {
            linearLoadingPage: { path: 'loading', isEnabled: true },
            committedUse: { path: 'committeduse', isEnabled: flags.committedUseDiscounts },
            committedUseAws: {
                path: 'committeduseaws',
                isEnabled: flags.committedUseDiscounts && flags.cudAws,
            },
            committedUseAzure: {
                isEnabled: flags.committedUseDiscounts && flags.cudAzure,
                path: 'committeduseazure',
            },
            committedUseGcp: {
                isEnabled: flags.committedUseDiscounts && flags.cudGcp,
                path: 'committedusegcp',
            },
            executivekpis: { isEnabled: true, path: 'executivekpis' },
            dashboard: { isEnabled: true, path: 'dashboard' },
            navigator: { isEnabled: true, path: 'navigator' },
            customDashboards: { isEnabled: flags.customDashboards, path: 'customdashboard' },
            sharedReport: { isEnabled: flags.sharedReports, path: 'sharedreport' },
            sharedReportId: { isEnabled: flags.sharedReports, path: ':reportid' },
            sharedReportView: { isEnabled: flags.sharedReports, path: 'views' },
            sharedReportViewId: { isEnabled: flags.sharedReports, path: ':viewid' },
            datametry: { isEnabled: flags.datametry, path: 'datametry' },
            finance: { isEnabled: flags.finance, path: 'finance' },
            anomalies: { isEnabled: true, path: 'anomalies' },
            optimize: { isEnabled: true, path: 'optimize' },
            operate: { isEnabled: true, path: 'operate' },
            fileDownloads: { isEnabled: true, path: 'filedownloads' },
            fileTransfer: { isEnabled: true, path: 'filetransfer' },
            tagManager: { isEnabled: true, path: 'tagmanager' },
            organize: { isEnabled: true, path: 'organize' },
            businessGroupings: { isEnabled: true, path: 'businessgroupings' },
            businessGroupingDetails: { isEnabled: true, path: ':id' },
            businessGroupingDetailsTabs: { isEnabled: true, path: ':id/:tab' },
            businessGroupingAssignmentRules: { isEnabled: flags.businessGroupingAssignmentRules, path: 'assignmentrules' },
            cloudAccounts: { isEnabled: true, path: 'cloudaccounts' },
            spaces: { isEnabled: flags.spaces, path: 'spaces' },
            workloads: { isEnabled: true, path: 'workloads' },
            resourcePools: { isEnabled: true, path: 'resourcepools' },
            vLabels: { isEnabled: true, path: 'vlabels' },
            vScore: { isEnabled: true, path: 'vscore' },
            recommendations: { isEnabled: true, path: 'recommendations' },
            takeAction: { isEnabled: true, path: 'takeaction' },
            vPolicies: { isEnabled: true, path: 'vpolicies' },
            quotasBudgets: { isEnabled: true, path: 'quotasbudgets' },
            resources: { isEnabled: true, path: 'resource' },
            resourcesDetail: { isEnabled: true, path: ':id' },
            resourcesDetailTabs: { isEnabled: true, path: ':id/:tab' },
            tagCompliance: { isEnabled: true, path: 'tagcompliance' },
            forecasting: { isEnabled: true, path: 'forecasting' },
            parking: { isEnabled: flags.vOperate, path: 'scheduler' },
            alerting: { isEnabled: true, path: 'alerting' },
            contacts: { isEnabled: true, path: 'contacts' },
            hushes: { isEnabled: true, path: 'hushes' },
            myFiles: { isEnabled: true, path: 'myfiles' },
            helpSupport: { isEnabled: true, path: 'helpsupport' },
            settings: {
                route: { isEnabled: true, path: 'settings' },
                tabs: [
                    {
                        label: 'Organization',
                        id: 'organization',
                        enabled: flags.organizationSettingsTab,
                        allowedRoles: organizationalAdminRoles,
                        settingsLocation: SettingTypes.Organizational,
                    },
                    { label: 'Profile Details', id: 'profile', enabled: true, allowedRoles: allRoles, settingsLocation: SettingTypes.Personal },
                    { label: 'Password', id: 'password', enabled: true, allowedRoles: allRoles, settingsLocation: SettingTypes.Personal },
                    // keymanagement is available only for roles: data_reader, org_global_admin, org_owner - this is controlled by the flag
                    {
                        label: 'API Key Management',
                        id: 'keymanagement',
                        enabled: flags.keyManagement,
                        allowedRoles: allRoles,
                        settingsLocation: SettingTypes.Personal,
                    },
                    {
                        label: 'API Client Registration',
                        id: 'clientregistration',
                        enabled: flags.clientRegistration,
                        allowedRoles: allRoles,
                        settingsLocation: SettingTypes.Personal,
                    },
                    { label: 'Users', id: 'users', enabled: true, allowedRoles: widerAdminRoles, settingsLocation: SettingTypes.Organizational },
                    {
                        label: 'Roles & Permissions',
                        id: 'permissions',
                        enabled: true,
                        allowedRoles: widerAdminRoles,
                        settingsLocation: SettingTypes.Organizational,
                    },
                    {
                        label: 'Provider Accounts',
                        id: 'accounts',
                        enabled: true,
                        allowedRoles: widerAdminRoles,
                        settingsLocation: SettingTypes.Organizational,
                    },
                    {
                        label: 'Multi-Factor Authentication',
                        id: 'mfa',
                        enabled: true,
                        allowedRoles: widerAdminRoles,
                        settingsLocation: SettingTypes.Organizational,
                    },
                    {
                        label: 'Single Sign-On',
                        id: 'sso',
                        enabled: true,
                        allowedRoles: widerAdminRoles,
                        settingsLocation: SettingTypes.Organizational,
                    },
                    {
                        label: 'Notifications & Alerts',
                        id: 'notifications',
                        enabled: flags.notificationService,
                        allowedRoles: allRoles,
                        settingsLocation: SettingTypes.Personal,
                    },
                ],
            },
            settingsTabs: { isEnabled: true, path: ':tab' },
            notificationsTabs: { isEnabled: flags.notificationService, path: ':notificationtab' },
            permissionDetails: { isEnabled: true, path: 'permissions/:roleid' },
            permissionsDetailTabs: { isEnabled: true, path: ':permissiontab' },
            dashboardAuthor: { isEnabled: flags.dashboardManagement, path: 'dashboardauthor' },
            cloudHeroes: { isEnabled: true, path: 'cloudheroes' },
            cloudHeroBadges: { isEnabled: true, path: 'cloudherobadges' },
            cloudHeroSummaries: { isEnabled: true, path: 'cloudherosummaries' },
        };
    }, [flags]);
    return { routeUrls };
}
