import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Add, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { IResource, ParkingScheduleType } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateScheduleMenuProps {
    onClickCreate: (parkingScheduleType: ParkingScheduleType) => void;
    parkableResources: IResource[];
    anchorEl: null | HTMLElement;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    userHasGroupings: boolean;
}

const CreateScheduleMenu: React.FC<ICreateScheduleMenuProps> = (props) => {
    const isCreateMenuOpen = Boolean(props.anchorEl);

    const handleToggleCreateDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        props.setAnchorEl(null);
    };

    return (
        <>
            <Button
                startIcon={<Add />}
                variant={'contained'}
                aria-expanded={isCreateMenuOpen}
                onClick={handleToggleCreateDropDown}
                disabled={!props.userHasGroupings}
                endIcon={isCreateMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            >
                Create
            </Button>
            <Menu
                id='parking-schedule-create-menu'
                anchorEl={props.anchorEl}
                open={isCreateMenuOpen}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={(event) => props.onClickCreate(ParkingScheduleType.Advanced)}>Advanced Schedule</MenuItem>
                <MenuItem onClick={(event) => props.onClickCreate(ParkingScheduleType.Basic)}>Schedule</MenuItem>
            </Menu>
        </>
    );
};

const useStyles = customMakeStyles<ICreateScheduleMenuProps>()((theme, props) => ({}));

export { CreateScheduleMenu };
