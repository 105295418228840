import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { RoleDetailsCard, usePermissionAndRolesApi, useUsersApi } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPermissionDetailsControllerProps {}

const PermissionDetailsController: React.FC<IPermissionDetailsControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { usersV2, isUsersV2Loading } = useUsersApi({});
    const { realmRoles, realmRole, isRealmRoleLoading, areRealmRolesLoading, setRoleId, members, rolePermissions, permissionSets } =
        usePermissionAndRolesApi({ users: usersV2 });

    useEffect(() => {
        const currentRoleId = window.location.pathname.split('/')[3];
        if (currentRoleId !== 'create') {
            setRoleId(currentRoleId);
        }
    }, [window.location.pathname]);

    return (
        <RoleDetailsCard
            realmRole={realmRole}
            setRoleId={setRoleId}
            isLoading={areRealmRolesLoading || isRealmRoleLoading}
            areUsersLoading={isUsersV2Loading || isRealmRoleLoading}
            members={members}
            permissionSets={permissionSets}
            rolePermissions={rolePermissions}
        />
    );
};

const useStyles = customMakeStyles<IPermissionDetailsControllerProps>()((theme, props) => ({}));

export { PermissionDetailsController };
