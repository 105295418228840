import { GeminiResponse } from '@vegaplatformui/utils';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { CreateApiTokenResponse } from '@vegaplatformui/models';

export class ApiKeyManagementApi extends HttpClient {
    protected static classInstance?: ApiKeyManagementApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ApiKeyManagementApi();
        }

        return this.classInstance;
    }

    public createApiToken = (): GeminiResponse<CreateApiTokenResponse> => this.instance.post('vegaapi/api-token/tokens');
}
