import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { IUserSettingSSOSamlForm } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoDeleteConfigurationDialogProps {
    selectedSSO: IUserSettingSSOSamlForm;
    isOpen: boolean;
    onClose: () => void;
    onDelete: (alias: string) => void;
}

const SsoDeleteConfigurationDialog: React.FC<ISsoDeleteConfigurationDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { selectedSSO, isOpen, onClose, onDelete } = props;

    const onDeleteConfirmation = (alias: string) => {
        onDelete(alias);
        onClose();
    };

    const onClickCancelButton = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth
            aria-labelledby='delete-config-dialog-title'
            aria-describedby='delete-config-form'
        >
            <DialogTitle className={cx(classes.DialogTitle)} id='delete-config-dialog-title'>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <ErrorOutline color={'error'} />
                    <Typography className={cx(classes.TitleTypography)} variant={'h5'}>
                        Delete SSO
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(classes.DialogContent)} id={'delete-config-form'}>
                <Typography variant='body2'>Are you sure you want to delete this configured SSO? This action cannot be undone.</Typography>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Button variant='cancel' onClick={onClickCancelButton}>
                    Cancel
                </Button>
                <Button color={'error'} variant='contained' onClick={() => onDeleteConfirmation(selectedSSO.alias)}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<ISsoDeleteConfigurationDialogProps>()((theme, props) => ({
    DialogActions: {
        paddingRight: '1.5rem',
        marginBottom: '1rem',
    },
    DialogTitle: { paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1.5rem' },
    TitleTypography: { fontWeight: 500 },
    DialogContent: { paddingRight: '1.5rem', paddingLeft: '1.5rem' },
    DialogStartButton: {
        marginLeft: '0.5rem',
    },
}));

export { SsoDeleteConfigurationDialog };
