import React, { useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    FormControlLabel,
    IconButton,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { LinkGcpAccountForm, ICloudProviderAccount } from '@vegaplatformui/models';
import { ArrowBack, Close } from '@mui/icons-material';
import { useFetchFileBlobAndDownload } from '../utilities/use-fetch-file-blob-and-download';
import { FormField } from '../forms';
import { JsonInput } from '../json-input/json-input';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DiscoveryDetails } from '../jotai/atom';
import { AccountDiscoveryInProgressAlert } from './account-discovery-in-progress-alert';
import { useAtomValue } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkGcpAccountDialogProps {
    isDialogOpen: boolean;
    onBackDrawer: () => void;
    onCloseDialog: () => void;
    accountToEdit?: ICloudProviderAccount;
    onSubmitLinkGcpAccountForm: (data: LinkGcpAccountForm) => void;
    setSnackbarOptions: (update: CustomSnackBarOptions | ((prev: CustomSnackBarOptions) => CustomSnackBarOptions)) => void;
    serviceAccountJson: string;
    setServiceAccountJson: React.Dispatch<React.SetStateAction<string>>;
    onOpenDeleteAccountDialog?: (account: ICloudProviderAccount) => void;
}

const validationSchema: yup.ObjectSchema<LinkGcpAccountForm> = yup.object().shape({
    service_account: yup.string().matches(RegExp('.*"project_id":.*'), 'Missing project_id in json').required('Required'),
    account_alias: yup.string(),
    enabled: yup.boolean().required(),
});

const LinkGcpAccountDrawer: React.FC<ILinkGcpAccountDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [cloudProvider, setCloudProvider] = React.useState<string | undefined>(undefined);
    const [isCloudProviderSelected, setIsCloudProviderSelected] = React.useState(false);
    const fetchFileBlobAndDownload = useFetchFileBlobAndDownload();
    const discoveryDetails = useAtomValue(DiscoveryDetails);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        formState,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields, isSubmitSuccessful },
    } = useForm<LinkGcpAccountForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            account_alias: props.accountToEdit?.account_name ?? '',
            service_account: props.serviceAccountJson ?? '',
            enabled: props.accountToEdit?.enabled ?? true,
        },
    });

    useEffect(() => {
        if (props.accountToEdit !== undefined) {
            reset(
                {
                    account_alias: props.accountToEdit?.account_name ?? '',
                    service_account: props.serviceAccountJson ?? '',
                    enabled: props.accountToEdit?.enabled ?? true,
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [props.accountToEdit]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    const onCloseDrawerAndResetState = () => {
        props.onCloseDialog();
        reset({});
    };

    const onBackDrawerAndResetState = () => {
        props.onBackDrawer();
        reset({});
    };

    const onSubmitForm: SubmitHandler<LinkGcpAccountForm> = (data) => {
        const formToSubmit = { ...data };
        props.onSubmitLinkGcpAccountForm(formToSubmit);
        props.onCloseDialog();
    };

    const onChangeJson = (value: string) => {
        props.setServiceAccountJson!(value);
    };

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isDialogOpen}
            onClose={onCloseDrawerAndResetState}
            aria-labelledby='link-gcp-account-drawer'
            hideBackdrop={!props.accountToEdit}
        >
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} variant={'h6'} id={'link-gcp-account-drawer-title'}>
                <Stack spacing={0.5} direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Stack
                        direction={'row'}
                        width={'100%'}
                        justifyContent={!props.accountToEdit ? 'space-between' : 'flex-end'}
                        alignItems='center'
                        spacing={1}
                    >
                        {props.accountToEdit === undefined && (
                            <Button onClick={onBackDrawerAndResetState} variant='text' startIcon={<ArrowBack />}>
                                <Typography variant={'caption'}>Back</Typography>
                            </Button>
                        )}
                        <IconButton size={'small'} aria-label={'Close'} className={cx(classes.CloseButton)} onClick={onCloseDrawerAndResetState}>
                            <Close fontSize={'small'} className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                    <Stack direction={'column'}>
                        {(props.accountToEdit && 'Edit GCP Project') ?? 'Link GCP Project'}
                        {props.accountToEdit === undefined ? (
                            <Typography variant={'body1'}>
                                Click the button to access the Vega documentation, where you'll find detailed guidance and additional information.
                            </Typography>
                        ) : (
                            <></>
                        )}
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <Stack direction={'column'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                    {props.accountToEdit === undefined && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            variant={'outlined'}
                            type={'button'}
                            href={'https://docs.vegacloud.io/docs/providers/gcp'}
                            target={'_blank'} //open in a new tab
                            rel={'noreferrer noopener'} //blocks communication between the new tab and the original tab (security measure)
                        >
                            Vega GCP Guide
                        </Button>
                    )}
                    {props.accountToEdit && discoveryDetails.is_discovery && <AccountDiscoveryInProgressAlert />}
                    <Stack
                        id={'link-gcp-account-drawer-form'}
                        component={'form'}
                        spacing={2}
                        onSubmit={handleSubmit((data) => {
                            onSubmitForm(data);
                        })}
                        className={cx(classes.FormWindow)}
                    >
                        <Stack className={cx(classes.FormTitle)} direction={'column'} spacing={0}>
                            <Typography fontWeight={600} variant={'body1'}>
                                Account Details
                            </Typography>
                            <Typography variant={'body2'}>
                                The Account or Canonical Name is automatically populated from your cloud service provider.
                            </Typography>
                        </Stack>
                        <FormField label='Service Account' htmlFor='service_account'>
                            <Controller
                                name={'service_account'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <JsonInput
                                        onChangeValue={onChangeJson}
                                        id={'service_account'}
                                        fullWidth
                                        size='small'
                                        multiline
                                        rows={12}
                                        setFormValue={setValue}
                                        error={!!errors.service_account}
                                        value={props.serviceAccountJson ?? ''}
                                        placeholder={'Paste JSON from Instructions'}
                                        helperText={errors.service_account?.message as string}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label='Vega Account Alias (optional)' htmlFor='account_alias'>
                            <Controller
                                name={'account_alias'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        onChange={(e) => {
                                            onChange(e.target.value);
                                        }}
                                        value={value}
                                        id={'account_alias'}
                                        fullWidth
                                        size='small'
                                        placeholder={'Create an alias'}
                                        error={!!errors.account_alias}
                                        helperText={
                                            (errors.account_alias?.message as string) ??
                                            `${props.accountToEdit ? 'Edit' : 'Create a'} nickname for this account.`
                                        }
                                    />
                                )}
                            />
                        </FormField>
                        <FormField id={'enabled'} label='Account Status' htmlFor='enabled'>
                            <Controller
                                name={'enabled'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        className={cx(classes.EnabledSwitch)}
                                        control={<Switch id={'enabled'} onClick={() => setValue('enabled', !value)} checked={value} />}
                                        label={`This account is ${value ? 'enabled' : 'disabled'}`}
                                        labelPlacement='end'
                                    />
                                )}
                            />
                        </FormField>
                    </Stack>
                    {/*Just adding false in so it will never display*/}
                    {props.accountToEdit === undefined && false && (
                        <Typography variant={'caption'}>
                            Accounts will automatically be tested after creation and there will be a discovery every 30 minutes to search for errors.
                        </Typography>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack
                    direction={'row'}
                    width={'100%'}
                    justifyContent={!props.accountToEdit ? 'flex-end' : 'space-between'}
                    alignItems='center'
                    spacing={0}
                >
                    {props.accountToEdit && (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            id={'link-gcp-account-drawer-delete'}
                            disableElevation={true}
                            variant={'contained'}
                            onClick={() => {
                                const account = props.accountToEdit!;
                                props.onOpenDeleteAccountDialog && props.onOpenDeleteAccountDialog(account);
                                onCloseDrawerAndResetState();
                            }}
                            color={'error'}
                        >
                            Delete
                        </Button>
                    )}
                    {/*<Button variant={'cancel'} disableElevation={true} variant={'contained'} color={'secondary'} autoFocus onClick={props.onCloseDialog}>*/}
                    {/*    Cancel*/}
                    {/*</Button>*/}
                    {props.accountToEdit !== undefined ? (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-gcp-account-drawer-form'}
                        >
                            Save
                        </Button>
                    ) : (
                        <Button
                            className={commonStyles.classes.LowercaseTextButton}
                            disableElevation={true}
                            type={'submit'}
                            variant={'contained'}
                            form={'link-gcp-account-drawer-form'}
                        >
                            Link
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Drawer>
    );
};

const useStyles = customMakeStyles<ILinkGcpAccountDialogProps>()((theme, props) => ({
    EnabledSwitch: { marginLeft: '-0.45rem' },
    FormWindow: { width: '100%' },
    FormTitle: {
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    DeleteButton: { marginLeft: '4rem' },
    DrawerPaper: { width: '35%' },
    CloseButton: {
        padding: '6px',
    },
    TemplateButton: {
        marginTop: '2rem',
    },
}));

export { LinkGcpAccountDrawer };
