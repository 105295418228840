import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IParkingPriorityGroup } from '@vegaplatformui/models';
import { Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPriorityGroupListItemProps {
    priorityGroup: IParkingPriorityGroup;
}

const PriorityGroupListItem: React.FC<IPriorityGroupListItemProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Typography variant={'subtitle2'}>
            Priority Group {props.priorityGroup.priority + 1}
            {props.priorityGroup.description ? ` - ${props.priorityGroup.description}` : ''}
        </Typography>
    );
};

const useStyles = customMakeStyles<IPriorityGroupListItemProps>()((theme, props) => ({}));

export { PriorityGroupListItem };
