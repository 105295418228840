import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid, Skeleton, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommunicationSettingItemLoadingSkeletonProps {}

const CommunicationSettingItemLoadingSkeleton: React.FC<ICommunicationSettingItemLoadingSkeletonProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid className={cx(classes.ListItem)} container spacing={2} direction={'row'} justifyContent='flex-start' alignItems='center'>
            <Grid className={cx(classes.ColumnMinWidth)} item xs={3}>
                <Typography>
                    <Skeleton height={'3rem'} />
                </Typography>
            </Grid>
            <Grid className={cx(classes.ColumnMinWidth)} container direction='column' justifyContent='center' alignItems='flex-start' item xs={5}>
                <Grid item xs={12}>
                    <Typography>
                        <Skeleton height={'3rem'} width={'15rem'} />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ICommunicationSettingItemLoadingSkeletonProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ListItem: { marginBottom: '1rem' },
}));

export { CommunicationSettingItemLoadingSkeleton };
