import { Dialog, DialogContent, DialogTitle, DialogActions, styled, Button, ButtonProps, Stack, Typography } from '@mui/material';
import { IKeycloakUser, IUser } from '@vegaplatformui/models';
import { SubmitHandler } from 'react-hook-form';
import { GridColDef, GridPagination, GridSlotsComponent } from '@mui/x-data-grid';
import React from 'react';
import { CustomGridColStringOperatorDef } from '../../utilities/custom-grid-col-string-operator-def';
import { CustomDatagridColumnMenu } from '../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { DataGridCustomToolbar, DataGridCustomToolbarProps } from '../../utilities/datagrid-custom-toolbar';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { tableIdentifierKeys } from '../../use-table-utilities/table-identifier-keys';

export interface IUserDeleteDialogProps {
    users: IUser[];
    isOpen: boolean;
    onClose: () => void;
    onDelete: (data: IUser[]) => void;
}

const UserDeleteDialog: React.FC<IUserDeleteDialogProps> = (props) => {
    const onSubmit: SubmitHandler<IUser[]> = (data: IUser[]) => {
        props.onDelete(data);
    };
    const usersDeleteTableUtilities = useTableUtilities(tableIdentifierKeys.usersDeleteTableUtilities);

    React.useEffect(() => {
        usersDeleteTableUtilities.registerTableControl();

        return () => {
            usersDeleteTableUtilities.unregisterTableControl();
        };
    }, []);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'User',
            flex: 1,
            valueGetter: (value: string, row: IUser) => `${row.given_name} ${row.family_name}`,
        },
        { ...CustomGridColStringOperatorDef, field: 'email', headerName: 'Email', flex: 1 },
        {
            ...CustomGridColStringOperatorDef,
            field: 'platform_roles',
            headerName: 'Role',
            flex: 1,
            valueGetter: (value: string[], row: IUser) => (row.platform_roles !== null ? row.platform_roles?.join(', ') : row.platform_roles),
        },
    ];

    return (
        <Dialog open={props.isOpen} onClose={props.onClose} fullWidth>
            <DialogTitle variant={'h6'} style={{ cursor: 'move' }} id='choose-cloud-provider-dialog'>
                Remove User{props.users.length > 1 ? 's' : ''}
            </DialogTitle>
            <DialogContent>
                <Stack direction={'column'} justifyContent='space-between' spacing={2}>
                    {props.users.length > 1 && (
                        <Typography variant={'subtitle1'} fontWeight={600}>
                            {props.users.length} user{props.users.length > 1 ? 's' : ''} will be deleted.
                        </Typography>
                    )}
                    <DataGridPremium
                        slots={{
                            toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                            columnMenu: CustomDatagridColumnMenu,
                            pagination: GridPagination,
                        }}
                        slotProps={{ toolbar: { tableIdentifier: tableIdentifierKeys.usersDeleteTableUtilities } as DataGridCustomToolbarProps }}
                        autoHeight={true}
                        initialState={{ density: 'compact' }}
                        columns={columns}
                        rows={props.users}
                        pagination
                        density={usersDeleteTableUtilities.currentTableControl?.density}
                        onDensityChange={usersDeleteTableUtilities.onDensityChange}
                        filterModel={usersDeleteTableUtilities.currentTableControl?.filterModel}
                        onFilterModelChange={usersDeleteTableUtilities.onFilterModelChange}
                        paginationModel={usersDeleteTableUtilities.currentTableControl?.paginationModel}
                        onPaginationModelChange={usersDeleteTableUtilities.onPaginationModelChange}
                        columnVisibilityModel={usersDeleteTableUtilities.currentTableControl?.columnModel}
                        onColumnVisibilityModelChange={usersDeleteTableUtilities.onColumnVisibilityModelChange}
                        loading={false}
                        disableRowSelectionOnClick={true}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} color={'secondary'} autoFocus onClick={props.onClose}>
                    Cancel
                </Button>
                <Button variant={'contained'} color='error' onClick={() => onSubmit(props.users)} disabled={false}>
                    Remove User{props.users.length > 1 ? 's' : ''}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export { UserDeleteDialog };
