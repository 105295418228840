import { GeminiResponse } from '@vegaplatformui/utils';
import { IUserSettingBaseRequest, IUserSettingSSOBaseRequest, IUserSettingSSOSamlRequest, IUserSettingRealmRole } from '@vegaplatformui/models';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';

export class UserSettingApi extends HttpClient {
    protected static classInstance?: UserSettingApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new UserSettingApi();
        }

        return this.classInstance;
    }

    // User Profile Section
    public getUser = (data: IUserSettingBaseRequest, signal: AbortSignal): GeminiResponse<any> =>
        this.instance.get(`/keycloak/keycloak/${data.username}/user`, { signal });

    public getPhoto = (data: IUserSettingBaseRequest): GeminiResponse<any> => this.instance.get(`/keycloak/keycloak/${data.username}/photo`);

    public uploadImg = (data: IUserSettingBaseRequest, formData: FormData): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak/photo`, formData, { params: { ...data } });

    public deleteImg = (username: string): GeminiResponse<any> =>
        this.instance.delete(`/keycloak/keycloak/photo`, { params: { username: username } });

    public updatePassword = (data: IUserSettingBaseRequest, formData: FormData): GeminiResponse<any> =>
        this.instance.put<IUserSettingBaseRequest>(`/keycloak/keycloak/${data.username}/password`, formData);

    // Users Table Section
    public getUsers = (data: string): GeminiResponse<any> => this.instance.get<string>(`/keycloak/keycloak_admin/users?size=50`);

    public getRealmRoles = (data: string, signal?: AbortSignal): GeminiResponse<IUserSettingRealmRole[]> =>
        this.instance.get<IUserSettingRealmRole[]>(`/keycloak/keycloak_admin/realmRoles`, { signal: signal ?? undefined });

    // SSO
    public getIDPList = () => this.instance.get(`/keycloak/keycloak_admin/idp`);

    public createOpenIdIDP = (data: IUserSettingSSOBaseRequest): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak_admin/idp/openID`, data);

    public createSamlIDP = (data: IUserSettingSSOSamlRequest): GeminiResponse<any> => this.instance.post(`/keycloak/keycloak_admin/idp/saml`, data);

    public updateOpenIdIDP = (alias: string, data: IUserSettingSSOBaseRequest): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/idp/openID/${alias}`, data);

    public updateSamlIDP = (alias: string, data: IUserSettingSSOSamlRequest): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/idp/saml/${alias}`, data);

    public deleteIDP = (alias: string): GeminiResponse<any> => this.instance.delete(`/keycloak/keycloak_admin/idp/${alias}`);

    public generateSSOXML = (url: string): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak_admin/generate-xml`, null, { params: { url: url } });

    public importConfigIDP = (provider_id: string, config_file_path: string): GeminiResponse<any> =>
        this.instance.post(`/keycloak/keycloak_admin/idp/import-config`, null, {
            params: { provider_id: provider_id, config_file_path: config_file_path },
        });

    // MFA
    public getMFAStatus = (): GeminiResponse<any> => this.instance.get('/keycloak/keycloak_admin/browser_flow/conditional_otp');

    public updateMFAStatus = (type: string): GeminiResponse<any> =>
        this.instance.put(`/keycloak/keycloak_admin/browser_flow/conditional_otp?parameter_type=${type}`);
}
