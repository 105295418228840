import React, { useEffect, useRef } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { DataGridPremium, GridColDef, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridPagination, GridRowSelectionModel } from '@mui/x-data-grid';
import { CustomDatagridColumnMenu } from '../../../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { IBusinessGrouping, IBusinessGroupingDetailsForm, IUser } from '@vegaplatformui/models';
import { CustomGridColStringOperatorDef } from '../../../../utilities/custom-grid-col-string-operator-def';
import { Avatar, Grid2, Stack, Typography } from '@mui/material';
import { StyledToolTip } from '../../../../utilities/styled-tooltip';
import { useTableUtilities } from '../../../../use-table-utilities/use-table-utilities';
import { Control, Controller, FieldErrors, UseFormRegister, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { tableIdentifierKeys } from '../../../../use-table-utilities/table-identifier-keys';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../../../utilities/custom-data-grid-render-cell-button';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardUsersTableProps {
    businessGroupingToEdit: IBusinessGrouping | undefined;
    owner: IUser | undefined;
    setUserToUnassign: React.Dispatch<React.SetStateAction<IUser | undefined>>;
    assignedUsers: IUser[];
    selectedAssignedUsers: IUser[];
    setSelectedAssignedUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
    isLoading: boolean;
    onClickUnassignUsers: () => void;
    onClickSwitchOwner: () => void;
    control: Control<IBusinessGroupingDetailsForm>;
    register: UseFormRegister<IBusinessGroupingDetailsForm>;
    reset: UseFormReset<IBusinessGroupingDetailsForm>;
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
    errors: FieldErrors<IBusinessGroupingDetailsForm>;
}

const BusinessGroupingWizardUsersTable: React.FC<IBusinessGroupingWizardUsersTableProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const apiRef = useGridApiRef();
    const businessGroupingWizardUsersTableUtilities = useTableUtilities(tableIdentifierKeys.businessGroupingWizardUsersTable);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);

    useEffect(() => {
        businessGroupingWizardUsersTableUtilities.registerTableControl();
    }, []);

    const { assignedUsers, ownerRows } = React.useMemo(() => {
        let rowsData: IUser[] = [];
        let pinnedRowsData: { top: IUser[]; bottom: IUser[] } = {
            top: [],
            bottom: [],
        };

        rowsData = props.assignedUsers.filter((user) => user.id !== props.owner?.id);
        pinnedRowsData = {
            top: props.assignedUsers.filter((user) => user.id === props.owner?.id),
            bottom: [],
        };

        return {
            assignedUsers: rowsData,
            ownerRows: pinnedRowsData,
        };
    }, [props.owner, props.assignedUsers]);

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Name',
            flex: 3,
            valueGetter: (value: string, row: IUser) => {
                return `${row.given_name} ${row.family_name}`;
            },
            renderCell: (params: GridRenderCellParams<IUser>) => {
                return (
                    <Stack direction='row' height={'100%'} justifyContent='flex-start' alignItems='center' spacing={1} tabIndex={0}>
                        <Avatar alt={'avatar'} className={cx(classes.LetterAvatar)}>
                            {params.row.given_name.charAt(0) + params.row.family_name.charAt(0)}
                        </Avatar>
                        <Stack direction='column' justifyContent='center' alignItems='flex-start' spacing={0}>
                            <Typography fontWeight={500} variant={'body2'}>
                                {params.row.given_name} {params.row.family_name}
                            </Typography>
                            <Typography className={cx(classes.SubtitleColor)} variant={'caption'}>
                                {params.row.email}
                            </Typography>
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'role',
            headerName: 'Role',
            type: 'string',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1,
            valueGetter: (value: string, row: IUser) => {
                if (props.owner !== undefined) {
                    switch (row.id) {
                        case props.owner.id: {
                            return 'Owner';
                        }
                        default: {
                            return 'User';
                        }
                    }
                } else {
                    return 'User';
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            headerName: 'Actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 1.5,
            // type: 'actions',
            headerAlign: 'left',
            align: 'left',
            renderCell: (params: GridRenderCellParams<IUser>) => (
                <Stack direction='row' alignItems='center' spacing={1}>
                    <StyledToolTip
                        arrow
                        title={props.owner && params.row.id === props.owner.id ? 'This user can not be unassigned while still the owner.' : ''}
                        tabIndex={(props.owner && params.row.id === props.owner.id) || props.selectedAssignedUsers.length > 0 ? 0 : -1}
                    >
                        <span>
                            <CustomDataGridRenderCellButton
                                ref={ref}
                                buttonProps={{
                                    className: cx(classes.ActionButtons),
                                    disabled: (props.owner && params.row.id === props.owner.id) || props.selectedAssignedUsers.length > 0,
                                    color: 'secondary',
                                    variant: 'text',
                                    size: 'small',
                                    onClick: () => {
                                        props.setUserToUnassign(params.row);
                                        props.onClickUnassignUsers();
                                    },
                                    children: 'Remove',
                                }}
                                params={params}
                            />
                        </span>
                    </StyledToolTip>
                    {props.owner && props.owner.id === params.row.id && (
                        <StyledToolTip
                            arrow
                            title={
                                assignedUsers.length < 1
                                    ? 'There must be other assigned users to switch to'
                                    : 'Select another assigned user to become the new owner.'
                            }
                            tabIndex={assignedUsers.length < 1 ? 0 : -1}
                        >
                            <span>
                                <CustomDataGridRenderCellButton
                                    ref={ref}
                                    buttonProps={{
                                        className: cx(classes.ActionButtons),
                                        disabled: assignedUsers.length < 1,
                                        variant: 'text',
                                        onClick: () => props.onClickSwitchOwner(),
                                        children: 'Switch Owner',
                                    }}
                                    params={params}
                                />
                            </span>
                        </StyledToolTip>
                    )}
                </Stack>
            ),
        },
    ];

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);

        props.setSelectedAssignedUsers(
            gridSelectionModel.map(
                (id) => [...new Set([...props.assignedUsers, ...props.selectedAssignedUsers].flat())].find((user) => user.id === id) as IUser
            )
        );
    };

    function NoRowsOverlay() {
        //This can be changed to <></> if we want nothing to display when there are no additional users
        return (
            <Grid2 aria-live='assertive' height={'100%'} container direction='row' justifyContent='center' alignItems='center'>
                <Grid2 size={12}>
                    <Typography fontWeight={400} fontSize={'0.875rem'} variant={'body2'} align={'center'} sx={{ marginTop: '4rem' }}>
                        No Additional Users Assigned
                    </Typography>
                </Grid2>
            </Grid2>
        );
    }

    return (
        <Controller
            name={'business_grouping_users'}
            control={props.control}
            render={({ field: { onChange, value } }) => {
                return (
                    <DataGridPremium
                        className={cx(classes.DataGridRowHeight)}
                        apiRef={apiRef as React.MutableRefObject<GridApiPremium>}
                        pinnedRows={ownerRows}
                        sortingMode={'client'}
                        filterMode={'client'}
                        paginationMode={'client'}
                        checkboxSelectionVisibleOnly
                        autoHeight={false}
                        pagination={true}
                        columns={columns}
                        rows={assignedUsers}
                        onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                        rowSelectionModel={selectionModel}
                        checkboxSelection={true}
                        disableRowSelectionOnClick={true}
                        slots={{
                            columnMenu: CustomDatagridColumnMenu,
                            pagination: GridPagination,
                        }}
                        slotProps={{
                            pagination: {
                                showLastButton: true,
                                showFirstButton: true,
                                slotProps: {
                                    actions: {
                                        firstButton: { color: 'inherit' },
                                        lastButton: { color: 'inherit' },
                                    },
                                },
                            },
                            columnsManagement: {
                                getTogglableColumns,
                            },
                            noRowsOverlay: {
                                action: 'adding a user',
                            },
                        }}
                        density={businessGroupingWizardUsersTableUtilities.currentTableControl?.density ?? 'standard'}
                        onDensityChange={businessGroupingWizardUsersTableUtilities.onDensityChange}
                        filterModel={businessGroupingWizardUsersTableUtilities.currentTableControl?.filterModel}
                        onFilterModelChange={businessGroupingWizardUsersTableUtilities.onFilterModelChange}
                        paginationModel={businessGroupingWizardUsersTableUtilities.currentTableControl?.paginationModel}
                        onPaginationModelChange={businessGroupingWizardUsersTableUtilities.onPaginationModelChange}
                        sortModel={businessGroupingWizardUsersTableUtilities.currentTableControl?.sortModel}
                        onSortModelChange={businessGroupingWizardUsersTableUtilities.onSortModelChange}
                        columnVisibilityModel={businessGroupingWizardUsersTableUtilities.currentTableControl?.columnModel}
                        onColumnVisibilityModelChange={businessGroupingWizardUsersTableUtilities.onColumnVisibilityModelChange}
                        loading={props.isLoading}
                    />
                );
            }}
        />
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardUsersTableProps>()((theme, props) => ({
    DataGridRowHeight: {
        height: 'calc(100vh - 30rem)',
    },
    LetterAvatar: { backgroundColor: theme.palette.primary.light },
    SubtitleColor: {
        color: theme.palette.grey[600],
    },
    ActionButtons: {
        color: 'black',
    },
}));

export { BusinessGroupingWizardUsersTable };
