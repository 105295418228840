import { useKeycloak } from '@react-keycloak-fork/web';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../recoil/atom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from './query-keys';
import { CloudProviderAccountApi } from '@vegaplatformui/apis';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';
import {
    CloudProvidersTypes,
    ICloudProviderAccount,
    IEditAwsAccountHookRequest,
    IEditAzureAccountHookRequest,
    IEditGcpAccountHookRequest,
    IEditOciAccountHookRequest,
    LinkAwsAccountForm,
    LinkAzureAccountForm,
    LinkGcpAccountForm,
    LinkOciAccountForm,
} from '@vegaplatformui/models';
import { SnackbarErrorOutput } from '../utilities/snackbar-error-output';

export interface IUseCloudProviderAccountApiHook {
    accounts: ICloudProviderAccount[];
    isAccountsLoading: boolean;
    submitLinkAwsAccountForm: (data: LinkAwsAccountForm) => void;
    submitLinkAzureAccountForm: (data: LinkAzureAccountForm) => void;
    submitLinkGcpAccountForm: (data: LinkGcpAccountForm) => void;
    submitLinkOciAccountForm: (data: LinkOciAccountForm) => void;
    submitEditAwsAccountForm: (hookRequest: IEditAwsAccountHookRequest) => void;
    submitEditAzureAccountForm: (hookRequest: IEditAzureAccountHookRequest) => void;
    submitEditGcpAccountForm: (hookRequest: IEditGcpAccountHookRequest) => void;
    submitEditOciAccountForm: (hookRequest: IEditOciAccountHookRequest) => void;
    deleteAccount: (account: ICloudProviderAccount) => void;
    deleteSelectedAccounts: (selectedAccountIds: string[]) => void;
    awsParentAccounts: ICloudProviderAccount[];
}
export interface IUseCloudProviderAccountApiProps {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAccounts: React.Dispatch<React.SetStateAction<ICloudProviderAccount[]>>;
}

export function useCloudProviderAccountApi(props: IUseCloudProviderAccountApiProps): IUseCloudProviderAccountApiHook {
    const { keycloak } = useKeycloak();
    const queryClient = useQueryClient();
    const cloudProviderAccountsTableUtilities = useTableUtilities(tableIdentifierKeys.cloudProviderAccountsTable);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const escapeNewlines = (text: string) => {
        return text.replace(/[(?<!\\)\n]/gm, '\\n');
    };

    const cloudAccountApi = useMemo(() => {
        const cloudAccountApi = new CloudProviderAccountApi();
        cloudAccountApi.keycloak = keycloak;
        return cloudAccountApi;
    }, [keycloak]);

    const { data: accounts, isLoading: isAccountsLoading } = useQuery({
        queryKey: [
            queryKeys.cloudProviderAccounts.loadCloudAccounts,
            cloudProviderAccountsTableUtilities.currentTableControl?.filterModel,
            cloudProviderAccountsTableUtilities.currentTableControl?.sortModel,
            cloudProviderAccountsTableUtilities.currentTableControl?.paginationModel,
        ],
        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts],
            });
            props.setIsLoading(true);
            const data = await loadData(signal);
            props.setIsLoading(false);
            return data ?? [];
        },
        placeholderData: keepPreviousData,
        enabled: cloudProviderAccountsTableUtilities.currentTableControl !== undefined,
        meta: {
            errorMessage: 'There was a problem loading the cloud provider accounts',
        },
    });

    const loadData = async (signal: AbortSignal) => {
        if (cloudProviderAccountsTableUtilities.currentTableControl !== undefined) {
            const response = await cloudAccountApi.loadCloudAccounts(
                {
                    filterModel: cloudProviderAccountsTableUtilities.currentTableControl.filterModel,
                    sortModel: cloudProviderAccountsTableUtilities.currentTableControl.sortModel,
                    paginationModel: cloudProviderAccountsTableUtilities.currentTableControl.paginationModel,
                },
                signal
            );
            cloudProviderAccountsTableUtilities.updateTotalRows(response.data.total_rows);
            return response?.data.accounts;
        }
    };

    const { data: awsParentAccounts } = useQuery({
        queryKey: [queryKeys.cloudProviderAccounts.getAwsParentProviderAccounts],
        queryFn: async ({ signal }) => {
            await queryClient.cancelQueries({
                queryKey: [queryKeys.cloudProviderAccounts.getAwsParentProviderAccounts],
            });
            const response = await cloudAccountApi.getAWSParentAccounts(signal);
            return response.data;
        },
        placeholderData: keepPreviousData,
        enabled: true,
        meta: {
            errorMessage: 'There was a problem getting AWS Parent Accounts ',
        },
    });

    const { mutate: submitLinkAwsAccountForm, isPending: isLinkAwsAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully created cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (data: LinkAwsAccountForm) => {
            return await cloudAccountApi.createCloudAccount({
                account_id: data.account_id,
                account_name: data.account_alias ?? '',
                provider_str: CloudProvidersTypes.Aws.toUpperCase(),
                enabled: data.enabled,
                parent_account_id: !data.is_payer_account && data.payer_account ? data.payer_account.account_id : null,
                external_id: data.external_id,
            });
        },
    });

    const { mutate: submitLinkAzureAccountForm, isPending: isLinkAzureAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully created cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (data: LinkAzureAccountForm) => {
            return await cloudAccountApi.createCloudAccount({
                account_id: data.subscription_id,
                account_name: data.account_alias ?? '',
                provider_str: CloudProvidersTypes.Azure.toUpperCase(),
                enabled: data.enabled,
                parent_account_id: null,
                secret_json: `{"activeDirectoryEndpointUrl":"https://login.microsoftonline.com","resourceManagerEndpointUrl":"https://management.azure.com/","activeDirectoryGraphResourceId":"https://graph.windows.net/","sqlManagementEndpointUrl":"https://management.core.windows.net:8443/","galleryEndpointUrl":"https://gallery.azure.com/","managementEndpointUrl":"https://management.core.windows.net/","vegacustomername":"${keycloak.realm}","subscriptionId":"${data.subscription_id}","clientId":"${data.client_id}","clientSecret":"${data.client_secret}","tenantId":"${data.tenant_id}"}`,
            });
        },
    });

    const { mutate: submitLinkGcpAccountForm, isPending: isLinkGcpAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully created cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (data: LinkGcpAccountForm) => {
            return await cloudAccountApi.createCloudAccount({
                account_id: data && JSON.parse(data.service_account).project_id,
                account_name: data.account_alias ?? '',
                provider_str: CloudProvidersTypes.Gcp.toUpperCase(),
                enabled: data.enabled,
                parent_account_id: null,
                secret_json: data.service_account,
            });
        },
    });

    const { mutate: submitLinkOciAccountForm, isPending: isLinkOciAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully created cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (data: LinkOciAccountForm) => {
            return await cloudAccountApi.createCloudAccount({
                account_name: data.account_alias ?? '',
                account_id: data.compartment_ocid,
                secret_json: `{"private_key_content":"${escapeNewlines(data.private_key_content)}","tenancy":"${data.is_root ? data.compartment_ocid : data.tenancy}","fingerprint":"${data.fingerprint}","user":"${data.user}","region":"${data.region}"}`,
                parent_account_id: data.parent_account_ocid ?? null,
                provider_str: CloudProvidersTypes.Oci.toUpperCase(),
                enabled: data.enabled,
            });
        },
    });

    const { mutate: submitEditAwsAccountForm, isPending: isEditAwsAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem editing the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully edited cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (hookRequest: IEditAwsAccountHookRequest) => {
            return await cloudAccountApi.updateCloudAccount({
                id: hookRequest.accountToEdit.id,
                account_id: hookRequest.request.account_id,
                account_name: hookRequest.request.account_alias ?? '',
                provider_str: CloudProvidersTypes.Aws.toUpperCase(),
                enabled: hookRequest.request.enabled,
                created_at: hookRequest.accountToEdit.created_at,
                created_by: hookRequest.accountToEdit.created_by,
                parent_account_id:
                    !hookRequest.request.is_payer_account && hookRequest.request.payer_account ? hookRequest.request.payer_account.account_id : null,
                external_id: hookRequest.request.external_id,
            });
        },
    });

    const { mutate: submitEditAzureAccountForm, isPending: isEditAzureAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem editing the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully edited cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (hookRequest: IEditAzureAccountHookRequest) => {
            return await cloudAccountApi.updateCloudAccount({
                id: hookRequest.accountToEdit?.id,
                account_id: hookRequest.request.subscription_id,
                account_name: hookRequest.request.account_alias ?? '',
                provider_str: CloudProvidersTypes.Azure.toUpperCase(),
                enabled: hookRequest.request.enabled,
                created_at: hookRequest.accountToEdit.created_at,
                created_by: hookRequest.accountToEdit.created_by,
                parent_account_id: null,
                secret_json: `{"activeDirectoryEndpointUrl":"https://login.microsoftonline.com","resourceManagerEndpointUrl":"https://management.azure.com/","activeDirectoryGraphResourceId":"https://graph.windows.net/","sqlManagementEndpointUrl":"https://management.core.windows.net:8443/","galleryEndpointUrl":"https://gallery.azure.com/","managementEndpointUrl":"https://management.core.windows.net/","vegacustomername":"${keycloak.realm}","subscriptionId":"${hookRequest.request.subscription_id}","clientId":"${hookRequest.request.client_id}","clientSecret":"${hookRequest.request.client_secret}","tenantId":"${hookRequest.request.tenant_id}"}`,
            });
        },
    });

    const { mutate: submitEditGcpAccountForm, isPending: isEditGcpAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem editing the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully edited cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (hookRequest: IEditGcpAccountHookRequest) => {
            return await cloudAccountApi.updateCloudAccount({
                id: hookRequest.accountToEdit?.id,
                account_id: hookRequest.request && JSON.parse(hookRequest.request.service_account)?.project_id,
                account_name: hookRequest.request.account_alias ?? '',
                provider_str: CloudProvidersTypes.Gcp.toUpperCase(),
                enabled: hookRequest.request.enabled,
                created_at: hookRequest.accountToEdit.created_at,
                created_by: hookRequest.accountToEdit.created_by,
                parent_account_id: hookRequest.accountToEdit?.parent_account_id ? hookRequest.accountToEdit?.parent_account_id : null,
                secret_json: hookRequest.request.service_account,
            });
        },
    });

    const { mutate: submitEditOciAccountForm, isPending: isEditOciAccountLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem editing the cloud account: ${SnackbarErrorOutput(error)}`,
            });
        },
        onSuccess: (response, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Successfully edited cloud account',
            });
        },
        onSettled: () => {
            props.setIsLoading(false);
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (hookRequest: IEditOciAccountHookRequest) => {
            return await cloudAccountApi.updateCloudAccount({
                id: hookRequest.accountToEdit?.id,
                account_id: hookRequest.request.compartment_ocid,
                parent_account_id: hookRequest.request.compartment_ocid ?? null,
                secret_json: `{"private_key_content":"${escapeNewlines(hookRequest.request.private_key_content)}","tenancy":"${hookRequest.request.is_root ? hookRequest.request.compartment_ocid : hookRequest.request.tenancy}","fingerprint":"${hookRequest.request.fingerprint}","user":"${hookRequest.request.user}","region":"${hookRequest.request.region}"}`,
                account_name: hookRequest.request.account_alias ?? '',
                provider_str: CloudProvidersTypes.Oci.toUpperCase(),
                enabled: hookRequest.request.enabled,
                created_at: hookRequest.accountToEdit.created_at,
                created_by: hookRequest.accountToEdit.created_by,
            });
        },
    });

    const { mutate: deleteAccount, isPending: isDeleteAccountLoading } = useMutation({
        onError: (error, account, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting the cloud account (${account.account_id}): ` + SnackbarErrorOutput(error),
            });
            props.setIsLoading(false);
        },
        onSuccess: (response, account, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Successfully deleted ${account.account_id}`,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (account: ICloudProviderAccount) => {
            return await cloudAccountApi.deleteCloudAccount({ id: account.id });
        },
    });

    const { mutate: deleteSelectedAccounts, isPending: isDeleteSelectedAccountsLoading } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting the accounts: ${SnackbarErrorOutput(error)}`,
            });
            props.setIsLoading(false);
        },
        onSuccess: (response, accounts, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Successfully deleted ${accounts.length} accounts`,
            });
            props.setSelectedAccounts([]);
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.cloudProviderAccounts.loadCloudAccounts] });
        },
        mutationFn: async (selectedAccountIds: string[]) => {
            return await cloudAccountApi.deleteCloudAccountBatch({ ids: selectedAccountIds });
        },
    });

    return {
        accounts: accounts ?? [],
        awsParentAccounts: awsParentAccounts ?? [],
        isAccountsLoading:
            isAccountsLoading ||
            isLinkAwsAccountLoading ||
            isLinkAzureAccountLoading ||
            isLinkGcpAccountLoading ||
            isLinkOciAccountLoading ||
            isEditAwsAccountLoading ||
            isEditAzureAccountLoading ||
            isEditGcpAccountLoading ||
            isEditOciAccountLoading ||
            isDeleteAccountLoading ||
            isDeleteSelectedAccountsLoading,
        submitLinkAwsAccountForm,
        submitLinkAzureAccountForm,
        submitLinkGcpAccountForm,
        submitLinkOciAccountForm,
        submitEditAwsAccountForm,
        submitEditAzureAccountForm,
        submitEditGcpAccountForm,
        submitEditOciAccountForm,
        deleteSelectedAccounts,
        deleteAccount,
    };
}
