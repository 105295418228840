import React, { useEffect } from 'react';
import { useCommonStyles, customMakeStyles } from '@vegaplatformui/styling';
import {
    GridColDef,
    GridRenderCellParams,
    DataGridPremium,
    useGridApiRef,
    GridColumnHeaderParams,
    GridRowParams,
    GridRowSelectionModel,
    GridPagination,
    GridActionsCellItem,
    GridValueOptionsParams,
    ValueOptions,
} from '@mui/x-data-grid-premium';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Edit, Refresh, Error, PriorityHigh, Announcement, Check, Info } from '@mui/icons-material';
import { CloudProvidersTypes, FilterTableByProvider, ICloudProviderAccount, IResource } from '@vegaplatformui/models';
import { CloudProviderIcon } from '../utilities/logo-selector';
import { useRecoilState } from 'recoil';
import { DiscoveryDetails } from '../recoil/atom';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { DataGridCellTooltip } from '../utilities/datagrid-cell-tooltip';
import { DataGridCustomToolbar } from '../utilities/datagrid-custom-toolbar';
import {
    CustomGridColSingleSelectOperatorDef,
    CustomGridColSingleSelectOperatorDef_OnlyIs,
    CustomGridColStringOperatorDef,
} from '../utilities/custom-grid-col-string-operator-def';
import { tableIdentifierKeys } from '../use-table-utilities/table-identifier-keys';
import { useProviderFilterOptions } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICloudProviderAccountsTableProps {
    cloudProviderAccounts: ICloudProviderAccount[];
    setSelectedAccounts: React.Dispatch<React.SetStateAction<ICloudProviderAccount[]>>;
    selectedAccounts: ICloudProviderAccount[];
    isLoading: boolean;
    onClickEditAccount: (account: ICloudProviderAccount) => void;
    onOpenDeleteAccountDialog(account: ICloudProviderAccount): void;
    tableFilterByProvider: FilterTableByProvider;
    setTableFilterByProvider: React.Dispatch<React.SetStateAction<FilterTableByProvider>>;
    handleClickSendDiscoveryRequest: (accounts: string[]) => void;
    onClickShowDiscoveryErrorDetails: (account: ICloudProviderAccount) => void;
}

const CloudProviderAccountsTable: React.FC<ICloudProviderAccountsTableProps> = (props) => {
    const localStyles = useStyles(props);
    const commonStyles = useCommonStyles();
    const apiRef = useGridApiRef();
    const [discoveryDetails] = useRecoilState(DiscoveryDetails);
    // const [paginationModel, setPaginationModel] = useRecoilState(defaultPaginationModel);
    const cloudProviderAccountsTableUtilities = useTableUtilities(tableIdentifierKeys.cloudProviderAccountsTable);
    const { providerOptions } = useProviderFilterOptions({});

    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

    React.useEffect(() => {
        cloudProviderAccountsTableUtilities.registerTableControl();
    }, []);

    // const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    //     items: [],
    // });
    useEffect(() => {
        switch (props.tableFilterByProvider) {
            case FilterTableByProvider.All:
                apiRef.current.deleteFilterItem({ field: 'provider_str', operator: 'is', id: 'provider' });
                break;
            case FilterTableByProvider.Aws:
                apiRef.current.upsertFilterItem({
                    field: 'provider_str',
                    operator: 'is',
                    value: CloudProvidersTypes.Aws.toUpperCase(),
                    id: 'provider',
                });
                break;
            case FilterTableByProvider.Azure:
                apiRef.current.upsertFilterItem({
                    field: 'provider_str',
                    operator: 'is',
                    value: CloudProvidersTypes.Azure.toUpperCase(),
                    id: 'provider',
                });
                break;
            case FilterTableByProvider.Gcp:
                apiRef.current.upsertFilterItem({
                    field: 'provider_str',
                    operator: 'is',
                    value: CloudProvidersTypes.Gcp.toUpperCase(),
                    id: 'provider',
                });
                break;
        }
    }, [props.tableFilterByProvider]);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'canonical_name',
            headerName: 'Account',
            renderHeader: (params: GridColumnHeaderParams<ICloudProviderAccount>) => {
                return (
                    <Stack className={localStyles.cx(localStyles.classes.CanonicalHeader)} direction={'row'} spacing={0.5}>
                        <Tooltip title={params.colDef.headerName}>
                            <Typography className={localStyles.cx(localStyles.classes.CanonicalHeader)} variant={'body2'}>
                                {params.colDef.headerName}
                            </Typography>
                        </Tooltip>
                        <Tooltip title='This is automatically populated from your cloud service provider.' placement={'top'} arrow>
                            <Info className={commonStyles.classes.TooltipIcons} />
                        </Tooltip>
                    </Stack>
                );
            },
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'account_id',
            headerName: 'Account ID',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'account_name',
            headerName: 'Vega Account Alias',
            flex: 1,
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'provider_str',
            headerName: 'Provider',
            flex: 0.7,
            renderCell: (params: GridRenderCellParams<IResource>) => (params.value ? <CloudProviderIcon cloudProvider={params.value} /> : ''),
            valueFormatter: (value: string, row: IResource) => (value ? value : ''),
            type: 'singleSelect',
            valueOptions: (params: GridValueOptionsParams<ICloudProviderAccount>): ValueOptions[] => {
                return providerOptions;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'parent_account_id',
            headerName: 'Parent Account',
            flex: 1,
        },
        {
            ...CustomGridColSingleSelectOperatorDef_OnlyIs,
            field: 'enabled',
            headerName: 'Status',
            flex: 1,
            type: 'singleSelect',
            valueOptions: [
                { value: true, label: 'Enabled' },
                { value: false, label: 'Disabled' },
            ],
            valueGetter: (value: boolean, row: ICloudProviderAccount) => {
                switch (value) {
                    case true:
                        return 'Enabled';
                    case false:
                        return 'Disabled';
                    default:
                        return 'Unknown';
                }
            },
            valueFormatter: (value: boolean) => {
                return value ?? '';
            },
            renderCell: (params: GridRenderCellParams<ICloudProviderAccount>) => {
                switch (params.value) {
                    case 'Enabled':
                        return 'Enabled';
                    case 'Disabled':
                        return (
                            <Stack height={'100%'} direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                <Typography color={'error'} variant={'body2'}>
                                    Disabled
                                </Typography>
                                <Error color={'error'} />
                            </Stack>
                        );
                    default:
                        return 'Unknown';
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'discovered_at',
            headerName: 'Last Discovered',
            flex: 1,
            filterable: false,
            valueFormatter: (value: string, row: ICloudProviderAccount) => {
                const account = props.cloudProviderAccounts.find((account) => account.id === row.id);
                switch (account?.discovered_status) {
                    case undefined:
                        return '';
                    case null:
                        return '';
                    case 0:
                        return 'In Progress';
                    default:
                        switch (row.discovered_at) {
                            case undefined:
                                return '';
                            case null:
                                return '';
                            default:
                                return Intl.DateTimeFormat('en-US', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }).format(Date.parse(row.discovered_at));
                        }
                }
            },
            renderCell: (params: GridRenderCellParams<ICloudProviderAccount>) => {
                switch (params.row.discovered_status) {
                    case 0:
                        return params.row.discovered_at;
                    case 1:
                        return (
                            <StyledToolTip title={params.formattedValue}>
                                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                    {params.formattedValue}
                                    <Check color={'success'} />
                                </Stack>
                            </StyledToolTip>
                        );
                    case 2:
                        return (
                            <StyledToolTip title={params.formattedValue}>
                                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                    {params.formattedValue}
                                    <PriorityHigh color={'error'} />
                                </Stack>
                            </StyledToolTip>
                        );
                    case 3:
                        return (
                            <StyledToolTip title={params.formattedValue}>
                                <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1}>
                                    {params.formattedValue}
                                    <PriorityHigh color={'error'} />
                                </Stack>
                            </StyledToolTip>
                        );
                    default:
                        return '';
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'discovery_message',
            headerName: 'Messages',
            flex: 1,
            valueGetter: (value: string, row: ICloudProviderAccount) => {
                switch (value) {
                    case undefined:
                        return '';
                    case null:
                        return '';
                    default:
                        return value;
                }
            },
            renderCell: (params: GridRenderCellParams<ICloudProviderAccount>) => {
                if (params.row.discovered_message) {
                    return (
                        <StyledToolTip title={'Click to view messages'}>
                            <IconButton
                                disabled={props.selectedAccounts.length > 0}
                                onClick={() => {
                                    props.onClickShowDiscoveryErrorDetails(params.row);
                                }}
                            >
                                <Announcement />
                            </IconButton>
                        </StyledToolTip>
                    );
                } else {
                    return '';
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            flex: 0.8,
            align: 'center',
            disableExport: true,
            getActions: (params: GridRowParams<ICloudProviderAccount>) => [
                props.selectedAccounts.length > 0 || !params.row.enabled || discoveryDetails.is_discovery || discoveryDetails.in_cooldown ? (
                    <StyledToolTip
                        arrow
                        title={
                            !params.row.enabled
                                ? 'Account is disabled'
                                : discoveryDetails.is_discovery
                                  ? 'Running Discovery'
                                  : discoveryDetails.in_cooldown
                                    ? 'Discovery on Cooldown'
                                    : 'Discover'
                        }
                    >
                        <span>
                            <GridActionsCellItem
                                icon={<Refresh />}
                                label={'Discover'}
                                aria-label={
                                    !params.row.enabled
                                        ? 'Account is disabled'
                                        : discoveryDetails.is_discovery
                                          ? 'Running Discovery'
                                          : discoveryDetails.in_cooldown
                                            ? 'Discovery on Cooldown'
                                            : 'Discover'
                                }
                                disabled={
                                    props.selectedAccounts.length > 0 ||
                                    !params.row.enabled ||
                                    discoveryDetails.is_discovery ||
                                    discoveryDetails.in_cooldown
                                }
                                onClick={() => {
                                    return props.handleClickSendDiscoveryRequest([params.row.id]);
                                }}
                            />
                        </span>
                    </StyledToolTip>
                ) : (
                    <StyledToolTip arrow title={'Discover'}>
                        <GridActionsCellItem
                            icon={<Refresh />}
                            label={'Discover'}
                            aria-label={'Discover'}
                            onClick={() => {
                                return props.handleClickSendDiscoveryRequest([params.row.id]);
                            }}
                        />
                    </StyledToolTip>
                ),
                <StyledToolTip arrow title={'Edit'}>
                    <GridActionsCellItem
                        icon={<Edit />}
                        label={'Edit'}
                        aria-label={`edit ${params.row.canonical_name ?? params.row.account_id}`}
                        onClick={() => props.onClickEditAccount(params.row)}
                    />
                </StyledToolTip>,
            ],
        },
    ];

    //This was something that Mohammed added, and I am not sure we even want this?
    // I think it is supposed to create a render cell for the cell if there isn't one present that will add a styled tooltip that we use in other places.
    // I don't even think is desired functionality is working as it is setup now though
    columns.map((column) => {
        if (!column.renderCell && column.type !== 'actions') column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);

        props.setSelectedAccounts(
            gridSelectionModel.map(
                (id) =>
                    [...new Set([...props.cloudProviderAccounts, ...props.selectedAccounts].flat())].find(
                        (account) => account.id === id
                    ) as ICloudProviderAccount
            )
        );
    };

    useEffect(() => {
        setSelectionModel(props.selectedAccounts.map((account) => account.id) ?? []);
    }, [props.selectedAccounts]);

    return (
        <Box>
            <DataGridPremium
                apiRef={apiRef}
                filterMode={'server'}
                sortingMode={'server'}
                paginationMode={'server'}
                filterModel={cloudProviderAccountsTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={cloudProviderAccountsTableUtilities.onFilterModelChange}
                columnVisibilityModel={cloudProviderAccountsTableUtilities.currentTableControl?.columnModel}
                onColumnVisibilityModelChange={cloudProviderAccountsTableUtilities.onColumnVisibilityModelChange}
                rowCount={
                    (cloudProviderAccountsTableUtilities.currentTableControl && cloudProviderAccountsTableUtilities.currentTableControl.totalRows) ??
                    0
                }
                autoHeight={true}
                pagination={true}
                columns={columns}
                rows={props.cloudProviderAccounts}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                keepNonExistentRowsSelected={true}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                slots={{
                    pagination: GridPagination,
                }}
                slotProps={{
                    pagination: {
                        showLastButton: true,
                        showFirstButton: true,
                        slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                    },
                    toolbar: { tableIdentifier: tableIdentifierKeys.cloudProviderAccountsTable },
                    columnsManagement: {
                        getTogglableColumns,
                    },
                    noRowsOverlay: {
                        action: 'linking a provider account',
                    },
                }}
                density={cloudProviderAccountsTableUtilities.currentTableControl?.density}
                onDensityChange={cloudProviderAccountsTableUtilities.onDensityChange}
                paginationModel={cloudProviderAccountsTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={cloudProviderAccountsTableUtilities.onPaginationModelChange}
                sortModel={cloudProviderAccountsTableUtilities.currentTableControl?.sortModel}
                onSortModelChange={cloudProviderAccountsTableUtilities.onSortModelChange}
                loading={props.isLoading}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<ICloudProviderAccountsTableProps>()((theme, props) => ({
    CanonicalHeader: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: 500 },
}));

export { CloudProviderAccountsTable };
