import React, { useCallback } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, Button, Grid2 } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { formatBytes, invalidFilenameMessage, isValidFilename, validateFile } from '@vegaplatformui/utils';
import { SnackBarOptions } from '../../../jotai/atom';
import { useSetAtom } from 'jotai';
import { fileTypeFromBuffer } from 'file-type';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileImageInputProps {
    image?: string;
    onPhotoUpload: (data: File) => void;
    onPhotoRemove: () => void;
}

const ProfileImageInput: React.FC<IProfileImageInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const maxFileSize = 10 * 1024 * 1024;
    const allowedMimeTypes: { [key: string]: string[] } = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'], 'image/gif': ['.gif'] };

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
            const file = acceptedFiles[0];
            const ext = file.type.split('/')[1];

            const isValidName = isValidFilename(file.name);

            if (!isValidName) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: invalidFilenameMessage,
                });
                return;
            }

            validateFile(file, allowedMimeTypes)
                .then(() => {
                    switch (ext) {
                        case 'jpg':
                        case 'jpeg':
                        case 'png':
                        case 'gif':
                            props.onPhotoUpload(file);
                            break;
                        default:
                            setSnackbarOptions({
                                snackBarProps: { open: true, autoHideDuration: 6000 },
                                alertProps: { severity: 'error' },
                                message: `This file type is not allowed please use a .jpeg, png, .jpg or .gif`,
                            });
                            break;
                    }
                })
                .catch((error: Error) =>
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: error.message,
                    })
                );
        }
    };
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: allowedMimeTypes,
        maxSize: maxFileSize, // 1MB limit keeping coefficient
        minSize: 1,
        noClick: true, // Prevents opening on container click
        multiple: false,
        noDrag: true, // Disables drag-and-drop
        onDrop,
        onDropRejected: (fileRejections, event) => {
            const rejectionMessage = fileRejections[0].errors
                .map(
                    (e) =>
                        `There was a problem: ${e.message.includes('is larger than') ? `file is larger than ${formatBytes(maxFileSize)}` : e.message}`
                )
                .join(', and ');
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 4000 },
                alertProps: { severity: 'error' },
                message: rejectionMessage,
            });
        },
        onError: (err) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 4000 },
                alertProps: { severity: 'error' },
                message: err.message,
            });
        },
    });

    return (
        <Grid2 className={cx(classes.GridArea)} size={12} container columnGap={1} direction='row' alignItems='center'>
            {props.image && (
                <Grid2>
                    <Avatar className={cx(classes.Avatar)} src={props.image} alt={`profile avatar ${props.image}`} />
                </Grid2>
            )}
            {!props.image && (
                <Grid2 {...getRootProps()}>
                    <Button onClick={open} color='primary' variant='contained' component='label' size='small'>
                        Upload
                    </Button>
                    <input {...getInputProps()} hidden id='image' />
                </Grid2>
            )}
            {props.image && (
                <Grid2>
                    <Button
                        size='small'
                        variant='cancel'
                        onClick={() => {
                            props.onPhotoRemove();
                        }}
                    >
                        Remove
                    </Button>
                </Grid2>
            )}
        </Grid2>
    );
};

const useStyles = customMakeStyles<IProfileImageInputProps>()((theme, props) => ({
    GridArea: { paddingTop: '1rem', paddingBottom: '1rem' },
    Avatar: { width: 77, height: 77 },
}));

export { ProfileImageInput };
