import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ApiKeyManagementCard, useApiKeyManagementApi } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApiKeyManagementControllerProps {}

const ApiKeyManagementController: React.FC<IApiKeyManagementControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { generateApiKey, isGenerateApiKeyLoading } = useApiKeyManagementApi({});

    return <ApiKeyManagementCard generateApiKey={generateApiKey} isGenerateApiKeyLoading={isGenerateApiKeyLoading} />;
};

const useStyles = customMakeStyles<IApiKeyManagementControllerProps>()((theme, props) => ({}));

export { ApiKeyManagementController };
