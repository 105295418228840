import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    Autocomplete,
    autocompleteClasses,
    IconButton,
    ListSubheader,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { GridPagination, GridSlotProps } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const LISTBOX_PADDING = 8; // px

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = 15;

    const getChildSize = (child: React.ReactElement) => {
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 9 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    function renderRow(props: ListChildComponentProps) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
            ...style,
            top: (style.top as number) + LISTBOX_PADDING,
        };

        return (
            <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
                {(dataSet[1] + 1).toLocaleString('en-US')}
            </Typography>
        );
    }

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width='100%'
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType='ul'
                    itemSize={(index: number) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

function CustomDatagridPagination(props: GridSlotProps['pagination']) {
    const { classes, cx } = useStyles(props);
    const theme = useTheme();

    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            "& .MuiAutocomplete-option[aria-selected='true']": {
                backgroundColor: `${theme.palette.grey[100]}`,
            },
            '& .Mui-focused': { backgroundColor: theme.palette.primary?.main },
            boxSizing: 'border-box',
            '& ul': {
                padding: 0,
                margin: 0,
            },
        },
    });

    return (
        <GridPagination
            component={'div'}
            rowsPerPageOptions={props.rowsPerPageOptions!}
            count={props.count!}
            rowsPerPage={props.rowsPerPage!}
            page={props.page!}
            labelDisplayedRows={({ from, to, count }) => {
                return `${from.toLocaleString('en-US')}–${to.toLocaleString('en-US')} of ${count !== -1 ? count.toLocaleString('en-US') : `more than ${to.toLocaleString('en-US')}`}`;
            }}
            onPageChange={props.onPageChange!}
            showFirstButton={props.showFirstButton ?? true}
            showLastButton={props.showLastButton ?? true}
            ActionsComponent={(subProps) => {
                const { page, count, rowsPerPage, slotProps, showLastButton, getItemAriaLabel, showFirstButton, onPageChange, ...restSubProps } =
                    subProps;

                //create array of pages
                const pages = [];
                for (let i = 0; i < Math.ceil(count / rowsPerPage); i++) {
                    pages.push(i);
                }

                return (
                    <Stack direction={'row'}>
                        {showFirstButton && (
                            <IconButton
                                color={'inherit'}
                                title={'Go to first page'}
                                aria-label={'go to first page'}
                                disabled={page === 0}
                                onClick={(event) => onPageChange(event, 0)}
                            >
                                <FirstPage />
                            </IconButton>
                        )}
                        <IconButton
                            color={'inherit'}
                            title={'Go to previous page'}
                            aria-label={'go to previous page'}
                            disabled={page === 0}
                            onClick={(event) => onPageChange(event, page - 1)}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton
                            color={'inherit'}
                            title={'Go to next page'}
                            aria-label={'go to next page'}
                            disabled={page >= pages.length - 1}
                            onClick={(event) => onPageChange(event, page + 1)}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                        {showLastButton && (
                            <IconButton
                                color={'inherit'}
                                title={'Go to last page'}
                                aria-label={'go to last page'}
                                disabled={page >= pages.length - 1}
                                onClick={(event) => onPageChange(event, pages.length - 1)}
                            >
                                <LastPage />
                            </IconButton>
                        )}
                    </Stack>
                );
            }}
        />
    );
}

const useStyles = customMakeStyles<GridSlotProps['pagination']>()((theme, props) => ({}));

export { CustomDatagridPagination };
