import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Card, CardContent, FormHelperText, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { SendRounded } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISharedReportUrlInputProps {}

const SharedReportInfo: React.FC<ISharedReportUrlInputProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Card className={cx(classes.SharedReportsCard)}>
            <CardContent>
                <Typography fontWeight={500} variant={'body1'}>
                    Paste a shared report url into browser navigation in order to generate the shared report.
                </Typography>
            </CardContent>
        </Card>
    );
};

const useStyles = customMakeStyles<ISharedReportUrlInputProps>()((theme, props) => ({
    IconButtonSize: { height: '56px', width: '56px' },
    TextField: { borderRadius: '8px', backgroundColor: theme.palette.common.white },
    SharedReportsCard: {
        minHeight: '100%',
    },
}));

export { SharedReportInfo };
