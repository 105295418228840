import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import {
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { ISSORoleMapping, IUserSettingRealmRole } from '@vegaplatformui/models';
import { OrganizationId } from '../../../recoil/atom';
import { FormField } from '../../../forms';
import { StyledToolTip } from '../../../utilities/styled-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleMappingItemProps {
    roleMapping: ISSORoleMapping;
    roles: IUserSettingRealmRole[];
    onChangeRoleMapping: (roleMapping: ISSORoleMapping) => void;
    isGroupNameUnique: boolean;
    isGroupNameEmpty: boolean;
    isGroupNameSpaceless: boolean;
}

const RoleMappingItem: React.FC<IRoleMappingItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const organizationId = useRecoilValue(OrganizationId);

    const handleRoleChange = (event: any) => {
        const value = event.target.value;
        if (value[value.length - 1] === 'all') {
            props.onChangeRoleMapping({
                ...props.roleMapping,
                roleAssignments:
                    props.roleMapping.roleAssignments.length === props.roles.filter((role) => !role.name.includes('vega_')).length
                        ? []
                        : props.roles
                              .filter((role) => !role.name.includes('vega_'))
                              .map((role) => {
                                  return { orgId: organizationId, roleId: role.id };
                              }),
            });
            return;
        }
        props.onChangeRoleMapping({
            ...props.roleMapping,
            roleAssignments: (value as IUserSettingRealmRole[]).map((val) => {
                return { orgId: organizationId, roleId: val.id };
            }),
        });
    };

    const onChangeIDPGroup = (group: string) => {
        props.onChangeRoleMapping({ ...props.roleMapping, externalGroupName: group });
    };

    return (
        <Grid direction={'column'} item container spacing={2} justifyContent='flex-start' alignItems='flex-start'>
            <Grid item container direction={'column'} spacing={0.5} xs={12}>
                <Grid item xs={12}>
                    <Stack direction={'row'}>
                        <Typography>SSO IDP Group Name</Typography>
                        <Tooltip title={'Type the name of the group as it is displayed in your IDP in this field.'} placement={'right'} arrow>
                            <Info className={commonStyles.classes.TooltipIcons} />
                        </Tooltip>
                    </Stack>
                </Grid>
                <Grid item xs={12} className={cx(classes.FullWidthInput)}>
                    <FormField htmlFor={'externalGroupName'}>
                        <FormControl size={'small'} className={cx(classes.FullWidthInput)}>
                            <TextField
                                error={props.isGroupNameUnique || props.isGroupNameEmpty || !props.isGroupNameSpaceless}
                                onChange={(e) => onChangeIDPGroup(e.target.value)}
                                id={'externalGroupName'}
                                size='small'
                                placeholder={'Enter IDP Group name'}
                                fullWidth
                                helperText={
                                    props.isGroupNameEmpty
                                        ? 'Group name must not be empty'
                                        : props.isGroupNameUnique
                                          ? 'Group name must be unique'
                                          : !props.isGroupNameSpaceless
                                            ? 'Group name must not contain spaces'
                                            : ''
                                }
                                value={props.roleMapping.externalGroupName}
                            />
                        </FormControl>
                    </FormField>
                </Grid>
            </Grid>
            <Grid item container direction={'column'} spacing={0.5} xs={12}>
                <Grid item xs={12}>
                    <Stack direction={'row'}>
                        <Typography>Platform Role</Typography>
                        <Tooltip title={'Select each role that you want to assign to the IDP group.'} placement={'right'} arrow>
                            <Info className={commonStyles.classes.TooltipIcons} />
                        </Tooltip>
                    </Stack>
                </Grid>
                <Grid item xs={12} className={cx(classes.FullWidthInput)}>
                    <FormField htmlFor='platform_role'>
                        <FormControl size={'small'} className={cx(classes.FullWidthInput)}>
                            <Select
                                className={cx(classes.FullWidthInput)}
                                id={'platform_role-label'}
                                size={'small'}
                                multiple
                                fullWidth
                                placeholder={'Select roles'}
                                value={props.roles
                                    .map((role) => role)
                                    .filter((item) =>
                                        props.roleMapping.roleAssignments.map((roleAssignment) => roleAssignment.roleId).includes(item.id)
                                    )}
                                onChange={(event) => {
                                    return handleRoleChange(event);
                                }}
                                renderValue={(selected: IUserSettingRealmRole[]) => selected.map((role) => role.name).join(', ')}
                            >
                                <MenuItem value='all'>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={props.roles.length > 0 && props.roleMapping.roleAssignments.length === props.roles.length}
                                            indeterminate={
                                                props.roleMapping.roleAssignments.length > 0 &&
                                                props.roleMapping.roleAssignments.length < props.roles.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary='Select All' />
                                </MenuItem>
                                {props.roles.map((role) => (
                                    <MenuItem key={role.id} value={role as any}>
                                        <StyledToolTip key={role.id} placement={'left'} arrow title={role.description}>
                                            <Stack
                                                className={cx(classes.FullWidthInput)}
                                                direction='row'
                                                justifyContent='flex-start'
                                                alignItems='center'
                                                spacing={0}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        checked={
                                                            props.roleMapping.roleAssignments
                                                                .map((roleAssignment) => roleAssignment.roleId)
                                                                .indexOf(role.id) !== -1
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary={role.name} />
                                            </Stack>
                                        </StyledToolTip>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormField>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IRoleMappingItemProps>()((theme, props) => ({
    FullWidthInput: { width: '100%' },
}));

export { RoleMappingItem };
