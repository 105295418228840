import { useKeycloak } from '@react-keycloak-fork/web';
import React, { useMemo } from 'react';
import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { queryKeys } from '../query-keys';
import { IAssignmentRule } from '@vegaplatformui/models';
import { RulesApi } from '@vegaplatformui/apis';
import { SnackbarErrorOutput } from '../../utilities/snackbar-error-output';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OrganizationId, SnackBarOptions } from '../../recoil/atom';
import { sessionTexts } from '@vegaplatformui/utils';
import { usePollingApi } from '../use-polling-api';

export interface IUseRulesApiProps {}
export interface IUseRulesApiHook {
    assignmentRules: IAssignmentRule[] | undefined;
    isAssignmentRulesLoading: boolean;
    onPostAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    isAssignmentRulePosting: boolean;
    onPutAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    isAssignmentRulePutting: boolean;
    onDeleteAssignmentRule: (assignmentRule: IAssignmentRule) => void;
    isAssignmentRuleDeleting: boolean;
    isAssignmentRulesLoadingPlaceholderData: boolean;
    isAssignmentRulesDrawerOpen: boolean;
    setIsAssignmentRulesDrawerOpen: (isOpen: boolean) => void;
    assignmentRuleToEdit: IAssignmentRule | undefined;
    setAssignmentRuleToEdit: (assignmentRule: IAssignmentRule | undefined) => void;
    applyAssignmentRules: (rule: string) => void;
    isAssignmentRulesApplying: boolean;
    getApplyAssignmentRulesData: string | undefined;
}

export function useRulesApi(props: IUseRulesApiProps): IUseRulesApiHook {
    const { keycloak } = useKeycloak();
    const [isAssignmentRulesDrawerOpen, setIsAssignmentRulesDrawerOpen] = React.useState<boolean>(false);
    const [assignmentRuleToEdit, setAssignmentRuleToEdit] = React.useState<IAssignmentRule | undefined>(undefined);
    const organizationId = useRecoilValue(OrganizationId);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const { getApplyAssignmentRulesData } = usePollingApi({});

    const rulesApi = useMemo(() => {
        const rulesApi = new RulesApi();
        rulesApi.keycloak = keycloak;
        return rulesApi;
    }, [keycloak]);

    const { isPending: isAssignmentRulePosting, mutate: postAssignmentRule } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem creating the assignment rule: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: 'Assignment rule created successfully!',
            });
            setIsAssignmentRulesDrawerOpen(false);
        },
        mutationFn: async (assignmentRule: IAssignmentRule) => {
            const response = await rulesApi.postAssignmentRule(assignmentRule);
            return response.data;
        },
    });

    const { isPending: isAssignmentRulePutting, mutate: putAssignmentRule } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem updating ${variables.name}: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `${variables.name} updated successfully!`,
            });
            setIsAssignmentRulesDrawerOpen(false);
            setAssignmentRuleToEdit(undefined);
        },
        mutationFn: async (assignmentRule: IAssignmentRule) => {
            const response = await rulesApi.putAssignmentRule(assignmentRule);
            return response.data;
        },
    });

    const { isPending: isAssignmentRuleDeleting, mutate: deleteAssignmentRule } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem deleting ${variables.name}: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `${variables.name} deleted successfully!`,
            });
        },
        mutationFn: async (assignmentRule: IAssignmentRule) => {
            const response = await rulesApi.deleteAssignmentRule(assignmentRule);
            return response.data;
        },
    });

    const { isPending: isAssignmentRulesApplying, mutate: applyAssignmentRules } = useMutation({
        onError: (error, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'error' },
                message: `There was a problem applying the assignment rules: ${SnackbarErrorOutput(error ?? '')}`,
            });
        },
        onSuccess: (data, variables, context) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 6000 },
                alertProps: { severity: 'info' },
                message: `Assignment rules are being applied`,
            });
        },
        mutationFn: async (rule: string) => {
            const response = await rulesApi.applyAssignmentRules(organizationId, rule);
            sessionStorage.setItem(sessionTexts.businessGroupRulesApplyJobId, response.data.job_id);
            return response.data;
        },
    });

    const {
        data: assignmentRules,
        isLoading: isAssignmentRulesLoading,
        isError: isAssignmentRulesLoadingError,
        error: assignmentRulesLoadingError,
        isSuccess: isAssignmentRulesLoadingSuccess,
        isPlaceholderData: isAssignmentRulesLoadingPlaceholderData,
    } = useQuery({
        queryKey: [queryKeys.rulesApi.getAssignmentRules, isAssignmentRulePosting, isAssignmentRulePutting, isAssignmentRuleDeleting, organizationId],
        enabled:
            !isAssignmentRulePosting &&
            !isAssignmentRulePutting &&
            !isAssignmentRuleDeleting &&
            organizationId !== '' &&
            organizationId !== undefined,
        queryFn: async () => {
            const response = await rulesApi.getAssignmentRules(organizationId);

            return response.data;
        },
        placeholderData: keepPreviousData,
        meta: {
            errorMessage: '`Unable to load assignment rules',
        },
    });

    const onChangeIsAssignmentRulesDrawerOpen = (isOpen: boolean) => {
        isOpen === false && setAssignmentRuleToEdit(undefined);
        setIsAssignmentRulesDrawerOpen(isOpen);
    };

    return {
        assignmentRules,
        isAssignmentRulesLoading: isAssignmentRulesLoading || organizationId === '',
        onPostAssignmentRule: postAssignmentRule,
        isAssignmentRulePosting,
        onPutAssignmentRule: putAssignmentRule,
        isAssignmentRulePutting,
        onDeleteAssignmentRule: deleteAssignmentRule,
        isAssignmentRuleDeleting: isAssignmentRuleDeleting,
        isAssignmentRulesLoadingPlaceholderData,
        isAssignmentRulesDrawerOpen,
        setIsAssignmentRulesDrawerOpen: onChangeIsAssignmentRulesDrawerOpen,
        assignmentRuleToEdit,
        setAssignmentRuleToEdit,
        applyAssignmentRules: applyAssignmentRules,
        isAssignmentRulesApplying: isAssignmentRulesApplying,
        getApplyAssignmentRulesData: getApplyAssignmentRulesData,
    };
}
