import React from 'react';
import { Grid, Stack, Button, Avatar, Typography } from '@mui/material';
import { ILogoVariation } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IOrganizationLogoInputProps } from './settingsV2/organization-v2/organization-logo-input';

export interface IImageUploadProps {
    acceptedFormats?: string[];
    alt: string;
    direction?: 'row' | 'column';
    handleRemove: (variation: ILogoVariation) => void;
    handleUpload: (event: React.ChangeEvent<HTMLInputElement>, variation: ILogoVariation) => void;
    imageSrc: string;
    sizes: { maxWidth: number; maxHeight: number };
    spacing?: number | string;
    title: string;
    subtitle?: React.ReactNode;
    variation: ILogoVariation;
}

const ImageUpload: React.FC<IImageUploadProps> = (props) => {
    const {
        acceptedFormats = ['.jpeg', '.png', '.jpg', '.ico'],
        alt,
        direction = 'row',
        handleRemove,
        handleUpload,
        imageSrc,
        sizes,
        spacing = 2,
        title,
        variation,
        subtitle,
    } = props;
    const { classes, cx } = useStyles(props);

    const onLogoUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        handleUpload(evt, variation);
    };
    const onLogoRemoval = () => {
        handleRemove(variation);
    };

    return (
        <Stack direction={'column'} spacing={1}>
            <Stack direction={'column'}>
                <Typography className={cx(classes.Header)} variant={'subtitle1'}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography className={cx(classes.Subtitle)} variant={'subtitle2'}>
                        {subtitle}
                    </Typography>
                )}
            </Stack>
            <Stack direction={direction} spacing={spacing} alignItems={'center'}>
                <Grid item>
                    <Avatar aria-label={variation + ' logo'} className={cx(classes.PreviewAvatar)} src={imageSrc} alt={alt} />
                </Grid>
                {!imageSrc && (
                    <Grid item>
                        <Button color='primary' variant='contained' component='label' size='small'>
                            Upload
                            {/* Using the imageSrc as key, so the input field gets re-rendered. Otherwise the logo removal
                        didn't reflect in the DOM state and it caused problem on file upload, not triggering onChange event */}
                            <input key={imageSrc} hidden id='logo' type='file' accept={acceptedFormats.join(',')} onChange={onLogoUpload} />
                        </Button>
                    </Grid>
                )}
                {!!imageSrc && (
                    <Grid item>
                        <Button color='primary' variant='outlined' component='label' size='small' onClick={onLogoRemoval}>
                            Remove
                        </Button>
                    </Grid>
                )}
            </Stack>
        </Stack>
    );
};

const useStyles = customMakeStyles<IImageUploadProps>()((theme, props) => ({
    Header: { fontWeight: theme.typography.fontWeightMedium, color: theme.palette.grey[700] },
    Subtitle: { fontWeight: theme.typography.fontWeightMedium, color: theme.palette.grey[300] },
    PreviewAvatar: { width: '77px', height: '77px' },
}));

export { ImageUpload };
