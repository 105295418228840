import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IParkingPriorityGroup, ParkingDelayUnit } from '@vegaplatformui/models';
import { Button, FormControl, Grid2, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPriorityGroupProps {
    priorityGroup: IParkingPriorityGroup;
    onChangePriorityGroup: (priorityGroup: IParkingPriorityGroup) => void;
    onDeletePriorityGroup: (priorityGroup: IParkingPriorityGroup) => void;
    isUnparkingDisabled: boolean;
    isParkingDisabled: boolean;
}

const PriorityGroup: React.FC<IPriorityGroupProps> = (props) => {
    const { classes, cx } = useStyles(props);

    const onChangeDescription = (description: string) => {
        props.onChangePriorityGroup({ ...props.priorityGroup, description: description });
    };

    const onChangeParkingDelay = (parkingDelay: number) => {
        props.onChangePriorityGroup({ ...props.priorityGroup, parking_delay: parkingDelay });
    };

    const onChangeParkingDelayUnits = (parkingDelayUnits: ParkingDelayUnit) => {
        props.onChangePriorityGroup({ ...props.priorityGroup, parking_delay_unit: parkingDelayUnits });
    };

    const onChangeUnparkingDelay = (unparkingDelay: number) => {
        props.onChangePriorityGroup({ ...props.priorityGroup, unparking_delay: unparkingDelay });
    };

    const onChangeUnparkingDelayUnits = (unparkingDelayUnits: ParkingDelayUnit) => {
        props.onChangePriorityGroup({ ...props.priorityGroup, unparking_delay_unit: unparkingDelayUnits });
    };

    const getUnitsMenu = (id: string, value: ParkingDelayUnit, onChange: (value: ParkingDelayUnit) => void) => {
        return (
            <FormControl>
                <InputLabel id='delay_unit'>Units</InputLabel>
                <Select
                    aria-hidden={false}
                    labelId={'delay_unit'}
                    label={'units'}
                    id={id}
                    size={'small'}
                    value={value}
                    displayEmpty={true}
                    onChange={(e) => onChange(e.target.value as ParkingDelayUnit)}
                >
                    <MenuItem key={ParkingDelayUnit.Seconds} value={ParkingDelayUnit.Seconds}>
                        Seconds
                    </MenuItem>
                    <MenuItem key={ParkingDelayUnit.Minutes} value={ParkingDelayUnit.Minutes}>
                        Minutes
                    </MenuItem>
                </Select>
            </FormControl>
        );
    };

    const onClickClear = () => {
        props.onChangePriorityGroup({
            ...props.priorityGroup,
            description: '',
            parking_delay: 0,
            parking_delay_unit: ParkingDelayUnit.Seconds,
            unparking_delay: 0,
            unparking_delay_unit: ParkingDelayUnit.Seconds,
        });
    };

    return (
        <Grid2 className={cx(classes.Container)} spacing={1} justifyContent='flex-start' alignItems='center' container direction={'row'}>
            <Grid2 size={4}>
                <Typography variant={'body2'} className={cx(classes.PriorityGroupName)}>{`Priority Group ${
                    props.priorityGroup.priority + 1
                }`}</Typography>
                <TextField
                    aria-label={'Description'}
                    onChange={(e) => onChangeDescription(e.target.value)}
                    id={'description'}
                    size='small'
                    label={'Description'}
                    multiline
                    fullWidth
                    rows={2}
                    value={props.priorityGroup.description}
                />
            </Grid2>
            <Grid2 size={3}>
                <Tooltip
                    placement={'bottom'}
                    arrow
                    title={
                        props.isUnparkingDisabled
                            ? 'Setting a starting delay is disabled for the first priority group. This group will start first.'
                            : ''
                    }
                >
                    <Stack spacing={1} direction='row'>
                        <TextField
                            label={'Starting Delay'}
                            onChange={(e) => onChangeUnparkingDelay(Number(e.target.value))}
                            id={'starting_delay'}
                            size='small'
                            slotProps={{ input: { inputProps: { min: 0 } } }}
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            disabled={props.isUnparkingDisabled}
                            type='number'
                            value={props.priorityGroup.unparking_delay}
                        />
                        {getUnitsMenu('unparking_delay_unit', props.priorityGroup.unparking_delay_unit, onChangeUnparkingDelayUnits)}
                    </Stack>
                </Tooltip>
            </Grid2>
            <Grid2 size={3}>
                <Tooltip
                    placement={'bottom'}
                    arrow
                    title={
                        props.isParkingDisabled ? 'Setting a stopping delay is disabled for the last priority group. This group will stop first.' : ''
                    }
                >
                    <Stack spacing={1} direction='row'>
                        <TextField
                            label={'Stopping Delay'}
                            onChange={(e) => onChangeParkingDelay(Number(e.target.value))}
                            id={'stopping_delay'}
                            size='small'
                            slotProps={{ input: { inputProps: { min: 0 } } }}
                            type={'number'}
                            disabled={props.isParkingDisabled}
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            value={props.priorityGroup.parking_delay}
                        />
                        {getUnitsMenu('parking_delay_unit', props.priorityGroup.parking_delay_unit, onChangeParkingDelayUnits)}
                    </Stack>
                </Tooltip>
            </Grid2>
            <Grid2 container justifyContent={'flex-end'} size={2}>
                <Stack spacing={1} direction='row'>
                    <Button variant={'cancel'} onClick={onClickClear}>
                        Clear
                    </Button>
                    <IconButton
                        aria-label={'Delete priority group'}
                        disabled={props.priorityGroup.priority === 0}
                        onClick={() => props.onDeletePriorityGroup(props.priorityGroup)}
                    >
                        <Close />
                    </IconButton>
                </Stack>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IPriorityGroupProps>()((theme, props) => ({
    PriorityGroupName: {
        fontWeight: 'bold',
        marginBottom: '.25rem',
    },
    Container: {
        marginLeft: '.5rem',
        paddingTop: '8px',
    },
}));

export { PriorityGroup };
