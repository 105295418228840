import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { FileDownloadsCard, SnackBarOptions, tableIdentifierKeys, useFilesApi, useTableUtilities } from '@vegaplatformui/sharedcomponents';
import { useKeycloak } from '@react-keycloak-fork/web';
import { IFile } from '@vegaplatformui/models';
import { useSetAtom } from 'jotai';

export type IFileDownloadsContainerProps = React.PropsWithChildren;

const FileDownloadsController: React.FC<IFileDownloadsContainerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedFiles, setSelectedFiles] = useState<IFile[]>([]);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const { keycloak } = useKeycloak();
    const fileDownloadsTableUtilities = useTableUtilities(tableIdentifierKeys.fileDownloadsTable);
    const filesApi = useFilesApi({ isFileUpload: false });

    const onClickDownloadSelectedFiles = () => {
        filesApi.setSelectedFiles(selectedFiles);
    };

    const onClickDownloadFile = (data: IFile) => {
        filesApi.setSelectedFiles([data]);
    };

    return (
        <FileDownloadsCard
            selectedFiles={selectedFiles}
            availableFilesToDownload={filesApi.availableFiles}
            onClickDownloadSelectedFiles={onClickDownloadSelectedFiles}
            onClickDownloadFile={onClickDownloadFile}
            setSelectedFiles={setSelectedFiles}
            isLoading={filesApi.isLoading}
        />
    );
};

const useStyles = customMakeStyles<IFileDownloadsContainerProps>()((theme, props) => ({}));

export { FileDownloadsController };
