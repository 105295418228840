import { useKeycloak } from '@react-keycloak-fork/web';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys } from '../query-keys';
import { NotificationsAPI, UserSettingApi } from '@vegaplatformui/apis';
import {
    CommunicationSettingType,
    ICommunicationSettingItem,
    IUpdateNotificationSettingsHook,
    IUpdateNotificationSettingsRequest,
    IUpdateNotificationStatusHook,
} from '@vegaplatformui/models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SnackbarErrorOutput } from '../../utilities/snackbar-error-output';

export interface IUseNotificationCommunicationSettingsApiHook {
    defaultCommunicationItems: ICommunicationSettingItem[];
    isDefaultCommunicationItemsLoading: boolean;
    isPhoneNumberSet: boolean;
}
export interface IUseNotificationCommunicationSettingsApiProps {}

export function useNotificationCommunicationSettingsApi(
    props: IUseNotificationCommunicationSettingsApiProps
): IUseNotificationCommunicationSettingsApiHook {
    const { keycloak } = useKeycloak();
    const [isPhoneNumberSet, setIsPhoneNumberSet] = useState<boolean>(false);
    const flags = useFlags();

    const userSettingApi = useMemo(() => {
        const apiInstance = new UserSettingApi();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const notificationsApi = useMemo(() => {
        const apiInstance = new NotificationsAPI();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const { data: defaultCommunicationItems, isLoading: isDefaultCommunicationItemsLoading } = useQuery({
        queryKey: [queryKeys.notificationTabs.getDefaultCommunicationItems],

        queryFn: async () => {
            if (keycloak.tokenParsed) {
                const response = await userSettingApi.getUser({ username: keycloak.tokenParsed.email });
                setIsPhoneNumberSet(response.data.mobile_phone !== null);
                const defaultCommunicationItems: ICommunicationSettingItem[] = [
                    { isDefault: true, type: CommunicationSettingType.Email, value: response.data.email },
                    {
                        isDefault: true,
                        type: CommunicationSettingType.Phone,
                        value: response.data.mobile_phone !== null ? response.data.mobile_phone : undefined,
                    },
                ];

                return defaultCommunicationItems;
            }
        },
        enabled: flags.notificationService && window.location.pathname.includes('notification') && keycloak.tokenParsed !== undefined,
        meta: {
            errorMessage: 'Failed to get default communication items',
        },
    });

    return {
        defaultCommunicationItems: defaultCommunicationItems ?? [],
        isDefaultCommunicationItemsLoading,
        isPhoneNumberSet,
    };
}
