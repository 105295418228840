import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Avatar, IconButton, Link, ListItem, ListItemText, Skeleton, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { Autorenew, ThumbDownRounded, ThumbUpRounded } from '@mui/icons-material';
import {
    ChatbotMessageReactionTypes,
    ChatbotSourceTypes,
    IChatbotMessage,
    IChatbotMessageLink,
    IChatbotPostQuestionHookRequest,
    IChatbotPutQuestionAnswerRequest,
} from '@vegaplatformui/models';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';
import { CosmooNew, CosmooV2 } from '@vegaplatformui/sharedassets';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChatbotWindowProps {
    chatMessages: IChatbotMessage[];
    handleSendMessage: (hookRequest: IChatbotPostQuestionHookRequest) => void;
    handleEditQuestionPair: (hookRequest: IChatbotPutQuestionAnswerRequest) => void;
    isChatbotLoading: boolean;
}

const ChatbotWindow: React.FC<IChatbotWindowProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const { chatMessages, handleSendMessage, handleEditQuestionPair, isChatbotLoading } = props;

    const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: ChatbotMessageReactionTypes, msg: IChatbotMessage) => {
        switch (newAlignment) {
            case ChatbotMessageReactionTypes.Like:
                return msg.metadata && handleEditQuestionPair({ ...msg.metadata, useful: true });
            case ChatbotMessageReactionTypes.Dislike:
                return msg.metadata && handleEditQuestionPair({ ...msg.metadata, useful: false });
            default:
                return (
                    msg.metadata &&
                    handleEditQuestionPair({
                        id: msg.metadata.id,
                        question: msg.metadata.question,
                        answer: msg.metadata.answer,
                        classification: msg.metadata.classification,
                        timestamp: msg.metadata.timestamp,
                    })
                );
        }
    };

    const extractLinks = (message: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = DOMPurify.sanitize(message);
        const links = tempDiv.getElementsByTagName('a');
        const linkDataArray: IChatbotMessageLink[] = [];

        for (let i = 0; i < links.length; i++) {
            const link = links[i];
            linkDataArray.push({
                href: link.href,
                text: link.textContent || '',
            });
        }

        return linkDataArray;
    };

    const getMessageComponent = (message: string) => {
        const links = extractLinks(message);
        let textMessage = '';
        if (links.length > 0) {
            textMessage = message.split('<a')[0];
        } else {
            textMessage = message;
        }

        return (
            <Stack direction={'column'}>
                {textMessage}
                {links.map((link, index) => (
                    <Link
                        width={'fit-content'}
                        className={cx(classes.MessageLinks)}
                        key={index}
                        style={{ color: 'blue' }}
                        href={link.href}
                        target='_blank'
                        rel='noreferrer noopener'
                    >
                        {link.text}
                    </Link>
                ))}
            </Stack>
        );
    };

    return (
        <Stack direction={'column'} component={'ol'} spacing={2} className={cx(classes.ChatBoxStack)}>
            {chatMessages.map((msg, index) => (
                <Stack
                    component={'li'}
                    key={index}
                    direction='row'
                    justifyContent={msg.from === ChatbotSourceTypes.User ? 'flex-end' : 'flex-start'}
                    alignItems='flex-start'
                    spacing={1}
                >
                    {msg.from === ChatbotSourceTypes.Bot && (
                        <Avatar sx={{ width: 40, height: 40, backgroundColor: 'black' }}>
                            <CosmooV2 aria-label={'Cosmoo message avatar'} />
                        </Avatar>
                    )}
                    <Stack key={index} spacing={1} className={cx(classes.MessageLength)} direction={'column'}>
                        <ListItem
                            key={index}
                            component={'span'}
                            className={cx(msg.from === ChatbotSourceTypes.User ? classes.UserMessageBox : classes.BotMessageBox)}
                        >
                            <ListItemText primary={getMessageComponent(msg.message)} />
                        </ListItem>
                        <Stack key={msg.from + '-' + index + '-options'} direction={'row'} spacing={1}>
                            {msg.from === ChatbotSourceTypes.Bot && index > 1 && index === chatMessages.length - 1 && (
                                <Tooltip key={msg.from + '-' + index + '-askagain'} title={'Ask Again'}>
                                    <IconButton
                                        size={'small'}
                                        className={cx(classes.BotContextColor)}
                                        onClick={() => {
                                            return handleSendMessage({ message: chatMessages[index - 1].message, newId: uuidv4() });
                                        }}
                                        aria-label={`ask ${
                                            msg.metadata && msg.metadata?.question ? `${msg.metadata?.question},` : 'the question for this answer'
                                        } again`}
                                    >
                                        <Autorenew fontSize='inherit' />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {msg.from === ChatbotSourceTypes.Bot &&
                                index > 1 && [
                                    <ToggleButtonGroup
                                        key={msg.from + '-' + index + '-toggle-button-group'}
                                        value={
                                            msg && msg.metadata && msg.metadata.useful === true
                                                ? ChatbotMessageReactionTypes.Like
                                                : msg && msg.metadata && msg.metadata.useful === false
                                                ? ChatbotMessageReactionTypes.Dislike
                                                : null
                                        }
                                        exclusive
                                        onChange={(event, value) => handleAlignment(event, value, msg)}
                                    >
                                        <Stack spacing={1} direction={'row'}>
                                            <Tooltip key={msg.from + '-' + index + '-like'} title={'Like Response'}>
                                                <ToggleButton
                                                    size={'small'}
                                                    value={ChatbotMessageReactionTypes.Like}
                                                    className={cx(classes.BotContextColor, classes.LikeToggleButton)}
                                                    aria-label={`mark "${msg.message}" as a good response.`}
                                                >
                                                    <ThumbUpRounded fontSize='inherit' />
                                                </ToggleButton>
                                            </Tooltip>
                                            <Tooltip key={msg.from + '-' + index + '-dislike'} title={'Dislike Response'}>
                                                <ToggleButton
                                                    size={'small'}
                                                    value={ChatbotMessageReactionTypes.Dislike}
                                                    className={cx(classes.BotContextColor, classes.DislikeToggleButton)}
                                                    aria-label={`mark "${msg.message}" as a poor response.`}
                                                >
                                                    <ThumbDownRounded fontSize='inherit' />
                                                </ToggleButton>
                                            </Tooltip>
                                        </Stack>
                                    </ToggleButtonGroup>,
                                ]}
                        </Stack>
                    </Stack>
                </Stack>
            ))}
            {isChatbotLoading && (
                <Stack direction='row' justifyContent={'flex-start'} alignItems='flex-start' spacing={1}>
                    <Avatar sx={{ width: 40, height: 40, backgroundColor: 'black' }}>
                        <CosmooV2 aria-label={'Cosmoo message pending avatar'} />
                    </Avatar>
                    <Stack direction={'column'} spacing={1} className={cx(classes.MessageLength)}>
                        <ListItem component={'span'} className={cx(classes.BotMessageBox)}>
                            <Stack sx={{ padding: '4px' }} direction={'row'} spacing={0.5}>
                                <Skeleton className={cx(classes.LoadingBubbles)} variant={'circular'} />
                                <Skeleton sx={{ animationDelay: '0.7s' }} className={cx(classes.LoadingBubbles)} variant={'circular'} />
                                <Skeleton sx={{ animationDelay: '0.8s' }} className={cx(classes.LoadingBubbles)} variant={'circular'} />
                            </Stack>
                        </ListItem>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

const useStyles = customMakeStyles<IChatbotWindowProps>()((theme, props) => ({
    BotAvatar: { backgroundColor: theme.palette.primary.main, color: theme.palette.getContrastText(theme.palette.primary.main) },
    LoadingBubbles: { width: '8px', height: '8px', backgroundColor: `${theme.palette.text.primary}80` },
    ChatBoxStack: { paddingInlineStart: '0px' },
    PopoverPaper: { backgroundColor: theme.palette.background.paper },
    MessageLength: { maxWidth: '85%', width: 'auto', display: 'flex' },
    MessageLinks: { textDecoration: 'none', '&:hover': { textDecoration: 'underline', color: 'inherit' } },
    UserMessageBox: {
        backgroundColor: theme.palette.primary.main,
        padding: '10px',
        borderRadius: '10px',
        // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        color: theme.palette.getContrastText(theme.palette.primary.main),
        whiteSpace: 'pre-wrap',
    },
    BotContextColor: {
        minWidth: 0,
        width: '30px',
        height: '30px',
        borderRadius: '8px',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.common.white,
        border: `1px solid #D0D5DD`,
    },

    LikeToggleButton: {
        '&.Mui-selected': {
            color: theme.palette.success.main,
            border: `1px solid ${theme.palette.success.main}`,
            backgroundColor: theme.palette.common.white,
        },
    },
    DislikeToggleButton: {
        '&.Mui-selected': {
            color: theme.palette.error.main,
            border: `1px solid ${theme.palette.error.main}`,
            backgroundColor: theme.palette.common.white,
        },
    },
    BotMessageBox: {
        backgroundColor: theme.palette.grey[50],
        // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        borderRadius: '10px',
        color: theme.palette.getContrastText(theme.palette.grey[50]),
        whiteSpace: 'pre-wrap',
    },
}));

export { ChatbotWindow };
