import React from 'react';
import { Grid2, Stack, Button, Avatar, Typography, Skeleton } from '@mui/material';
import { ILogoVariation } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { useDropzone } from 'react-dropzone';
import { formatBytes } from '@vegaplatformui/utils';
import { SnackBarOptions } from '../jotai/atom';
import { useSetAtom } from 'jotai';

export interface IImageUploadProps {
    allowedMimeTypes: { [key: string]: string[] };
    alt: string;
    direction?: 'row' | 'column';
    handleRemove: (variation: ILogoVariation) => void;
    handleUpload: (files: File[], variation: ILogoVariation) => void;
    imageSrc: string;
    sizes: { maxWidth: number; maxHeight: number };
    spacing?: number | string;
    title: string;
    subtitle?: React.ReactNode;
    isLoading?: boolean;
    variation: ILogoVariation;
}

const ImageUpload: React.FC<IImageUploadProps> = (props) => {
    const {
        alt,
        allowedMimeTypes,
        direction = 'row',
        handleRemove,
        handleUpload,
        imageSrc,
        sizes,
        spacing = 2,
        title,
        variation,
        subtitle,
        isLoading,
    } = props;
    const { classes, cx } = useStyles(props);
    const maxFileSize = 1024 * 1024;

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length) {
            handleUpload(acceptedFiles, variation);
        }
    };
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const onLogoRemoval = () => {
        handleRemove(variation);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: allowedMimeTypes,
        maxSize: maxFileSize, // 1MB limit keeping coefficient
        minSize: 1,
        noClick: true, // Prevents opening on container click
        multiple: false,
        noDrag: true, // Disables drag-and-drop
        onDrop,
        onDropRejected: (fileRejections, event) => {
            const rejectionMessage = fileRejections[0].errors
                .map(
                    (e) =>
                        `There was a problem: ${e.message.includes('is larger than') ? `file is larger than ${formatBytes(maxFileSize)}` : e.message}`
                )
                .join(', and ');
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 4000 },
                alertProps: { severity: 'error' },
                message: rejectionMessage,
            });
        },
        onError: (err) => {
            setSnackbarOptions({
                snackBarProps: { open: true, autoHideDuration: 4000 },
                alertProps: { severity: 'error' },
                message: err.message,
            });
        },
    });

    return (
        <Stack direction={'column'} spacing={1}>
            <Stack direction={'column'}>
                <Typography className={cx(classes.Header)} variant={'subtitle1'}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography className={cx(classes.Subtitle)} variant={'subtitle2'}>
                        {subtitle}
                    </Typography>
                )}
            </Stack>
            <Stack direction={direction} spacing={spacing} alignItems={'center'}>
                <Grid2>
                    {isLoading ? (
                        <Skeleton animation={'wave'} className={cx(classes.AvatarSkeleton)} variant='circular'>
                            <Avatar className={cx(classes.PreviewAvatar)} />
                        </Skeleton>
                    ) : (
                        <Avatar className={cx(classes.PreviewAvatar)} src={imageSrc} alt={alt} />
                    )}
                </Grid2>
                {!imageSrc && (
                    <Grid2 {...getRootProps()}>
                        <Button onClick={open} color='primary' variant='contained' component='label' size='small'>
                            Upload
                            {/* Using the imageSrc as key, so the input field gets re-rendered. Otherwise the logo removal
                        didn't reflect in the DOM state and it caused problem on file upload, not triggering onChange event */}
                        </Button>
                        <input {...getInputProps()} key={imageSrc} hidden id='logo' />
                    </Grid2>
                )}
                {!!imageSrc && (
                    <Grid2>
                        <Button color='primary' variant='outlined' component='label' size='small' onClick={onLogoRemoval}>
                            Remove
                        </Button>
                    </Grid2>
                )}
            </Stack>
        </Stack>
    );
};

const useStyles = customMakeStyles<IImageUploadProps>()((theme, props) => ({
    AvatarSkeleton: { color: `${theme.palette.grey[500]}70` },
    Header: { fontWeight: theme.typography.fontWeightMedium, color: theme.palette.grey[700] },
    Subtitle: { fontWeight: theme.typography.fontWeightMedium, color: theme.palette.grey[400] },
    PreviewAvatar: { width: '77px', height: '77px' },
}));

export { ImageUpload };
