import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, Button, Grid } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { SnackBarOptions } from '../../../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProfileImageInputProps {
    image?: string;
    onPhotoUpload: (data: File) => void;
    onPhotoRemove: () => void;
}

const ProfileImageInput: React.FC<IProfileImageInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    const updatePhoto = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target.files?.length) {
            const file = evt.target.files[0];
            const ext = file.type.split('/')[1];

            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                    props.onPhotoUpload(file);
                    evt.currentTarget.value = '';
                    break;
                default:
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'error' },
                        message: `This file type is not allowed please use a.jpeg, png, .jpg or .gif`,
                    });
                    evt.currentTarget.value = '';
                    break;
            }
        }
    };

    return (
        <Grid className={cx(classes.GridArea)} item xs={12} container columnGap={1} direction='row' alignItems='center'>
            {props.image && (
                <Grid item>
                    <Avatar className={cx(classes.Avatar)} src={props.image} alt={`profile avatar ${props.image}`} />
                </Grid>
            )}
            {!props.image && (
                <Grid item>
                    <Button color='primary' variant='contained' component='label' size='small'>
                        Upload
                        <input
                            hidden
                            id='image'
                            disabled={!!props.image}
                            type='file'
                            accept='.jpeg,.png,.jpg,.gif'
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                                updatePhoto(evt);
                            }}
                        />
                    </Button>
                </Grid>
            )}
            {props.image && (
                <Grid item>
                    <Button
                        size='small'
                        variant='cancel'
                        onClick={() => {
                            props.onPhotoRemove();
                        }}
                    >
                        Remove
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

const useStyles = customMakeStyles<IProfileImageInputProps>()((theme, props) => ({
    GridArea: { paddingTop: '1rem', paddingBottom: '1rem' },
    Avatar: { width: 77, height: 77 },
}));

export { ProfileImageInput };
