import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Chat, History } from '@mui/icons-material';
import { ChatbotNavigationTabTypes } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChatbotFooterProps {
    currentLocation: ChatbotNavigationTabTypes;
    setCurrentLocation: React.Dispatch<React.SetStateAction<ChatbotNavigationTabTypes>>;
}

const ChatbotFooter: React.FC<IChatbotFooterProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { currentLocation, setCurrentLocation } = props;

    return (
        <Paper className={cx(classes.BottomNavigationPaper)} elevation={3}>
            <BottomNavigation
                className={cx(classes.Footer)}
                showLabels
                value={currentLocation}
                onChange={(event, newValue) => {
                    setCurrentLocation(newValue);
                }}
            >
                <BottomNavigationAction
                    className={cx(classes.BottomNavigationLeftAction)}
                    value={ChatbotNavigationTabTypes.Chat}
                    label='Chat'
                    icon={<Chat />}
                />
                <BottomNavigationAction
                    className={cx(classes.BottomNavigationRightAction)}
                    value={ChatbotNavigationTabTypes.History}
                    label='Past Topics'
                    icon={<History />}
                />
                {/*<BottomNavigationAction value={ChatbotNavigationTabTypes.Help} label='Help' icon={<Help />} />*/}
            </BottomNavigation>
        </Paper>
    );
};

const useStyles = customMakeStyles<IChatbotFooterProps>()((theme, props) => ({
    BottomNavigationPaper: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '0 0 24px 24px',
        borderTop: '1px solid #D0D5DD',
    },
    //Could do middle button as there gets more then two as well
    BottomNavigationLeftAction: { paddingBottom: '0.75rem', paddingTop: '0.75rem', minWidth: '50%', borderRadius: '0 0 0 24px' },
    BottomNavigationRightAction: { paddingBottom: '0.75rem', paddingTop: '0.75rem', minWidth: '50%', borderRadius: '0 0 24px 0' },
    Footer: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        borderRadius: '0 0 24px 24px',
        '& .Mui-selected': { color: `${theme.palette.primary.main} !important` },
        // '& :not(.Mui-selected)': { color: `${theme.palette.action.disabled}` },
    },
}));

export { ChatbotFooter };
