export enum ChatbotNavigationTabTypes {
    Chat = 'chat',
    History = 'history',
    Help = 'help',
}

export enum ChatbotMessageReactionTypes {
    Like = 'like',
    Dislike = 'dislike',
}

export enum ChatbotSourceTypes {
    User = 'user',
    Bot = 'bot',
}

export interface IChatbotMessage {
    from: ChatbotSourceTypes;
    message: string;
    metadata?: IChatbotConversationQAndAPair;
}

export interface IChatbotMessageLink {
    href: string;
    text: string;
}

export interface IChatbotTopicHistory {
    user_id: string;
    conversations: IChatbotTopic[];
}

export interface IChatbotTopic {
    conversation_id: string;
    conversation_name: string;
    question_and_answer_pairs: IChatbotConversationQAndAPair[];
}

export interface IChatbotConversationQAndAPair {
    id: string;
    question: string;
    answer: string;
    classification: number;
    timestamp: number;
    useful?: boolean;
}

export interface IChatbotDataJson {
    result?: any[];
    error?: IChatbotDataError;
}

export interface IChatbotDataError {
    message: string;
}

export interface IChatbotDataItems {
    question?: string;
    headers: string[];
    data: any[];
}

export interface IChatbotPostQuestionHookRequest {
    message: string;
    newId: string;
}

export interface IChatbotPutQuestionAnswerHookRequest {
    topicId: string;
    request: IChatbotPutQuestionAnswerRequest;
}

export interface IChatbotPutQuestionAnswerRequest {
    id: string;
    question: string;
    answer: string;
    classification: number;
    timestamp: number;
    useful?: boolean;
}

export interface IChatbotPostQuestionRequest {
    question: string;
    user_id: string;
    conversation_id: string;
}

export interface IChatbotPutTopicRequest {
    topicId: string;
    new_name: string;
}
