import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Chip, Grid, Link, Stack, Tooltip, Typography } from '@mui/material';
import { CommunicationSettingType, ICommunicationSettingItem } from '@vegaplatformui/models';
import { Info } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useRouteUrls } from '@vegaplatformui/utils';
import { LinkToProfile } from '../../utilities/link-to-profile';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICommunicationSettingItemProps {
    communicationSetting: ICommunicationSettingItem;
}

const CommunicationSettingItem: React.FC<ICommunicationSettingItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    const NoPhoneToolTipTitle = () => {
        return <>Please add phone number in {<LinkToProfile color={'white'} />}</>;
    };

    return (
        <Grid className={cx(classes.ListItem)} container spacing={2} direction={'row'} justifyContent='flex-start' alignItems='center'>
            <Grid className={cx(classes.ColumnMinWidth)} item xs={3}>
                <Typography>{props.communicationSetting.type}</Typography>
            </Grid>
            <Grid
                className={cx(classes.ColumnMinWidth)}
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                item
                xs={5}
                spacing={1}
            >
                <Grid item>
                    {props.communicationSetting.value ? (
                        <Typography>{props.communicationSetting.value}</Typography>
                    ) : (
                        <Stack direction={'row'}>
                            <Typography color={'grey'}>No value</Typography>
                            {props.communicationSetting.type === CommunicationSettingType.Phone && (
                                <Tooltip tabIndex={0} title={NoPhoneToolTipTitle()} placement={'bottom'}>
                                    <Info className={commonStyles.classes.TooltipIcons} />
                                </Tooltip>
                            )}
                        </Stack>
                    )}
                </Grid>
                {props.communicationSetting.isDefault && (
                    <Grid item>
                        <Chip label='Default' />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ICommunicationSettingItemProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '50px',
        },
    },
    ListItem: { marginBottom: '1rem' },
}));

export { CommunicationSettingItem };
