import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { IParkingSchedule } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRemoveAdvancedParkingDialogProps {
    show: boolean;
    onClose: () => void;
    onRemoveAdvancedParking: (schedule: IParkingSchedule) => void;
    scheduleToEdit?: IParkingSchedule;
}

const RemoveAdvancedParkingDialog: React.FC<IRemoveAdvancedParkingDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <Grid container className={cx(classes.GridContainer)} direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <Grid xs={11} item>
                        <Typography fontWeight={500}>
                            Are you sure you want to remove advanced scheduling{props.scheduleToEdit ? ` on ${props.scheduleToEdit.name}?` : '?'}
                        </Typography>
                    </Grid>
                    <Grid xs={1} item className={cx(classes.CancelButton)}>
                        <IconButton size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    This will convert the advanced schedule configuration to a basic schedule with no priority groups.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                    Cancel
                </Button>
                <Button color={'error'} onClick={() => props.onRemoveAdvancedParking(props.scheduleToEdit!)}>
                    Yes, Remove
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IRemoveAdvancedParkingDialogProps>()((theme, props) => ({
    GridContainer: { marginTop: '-1.5rem' },
    CancelButton: { marginRight: '-1rem' },
}));

export { RemoveAdvancedParkingDialog };
