import React, { useState } from 'react';
import { Box, Button, Typography, Paper, Link, Chip, Container, Grid2 } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { RuleGroupType } from 'react-querybuilder';
import { customMakeStyles } from '@vegaplatformui/styling';

// Import refactored components
import { CloudProviderSelector } from './cloud-provider-selector';
import { BudgetPeriodSelector } from './budget-period-selector';
import { BudgetDetailsForm } from './budget-details-form';
import { CostTrendChart } from './cost-trend-chart';
import { DataFilters } from './data-filters/data-filters';
import { BudgetDetails, BudgetPeriod, CloudProvider, CostTrendData, ICreateBudgetForm } from '@vegaplatformui/models';
import { BlackOnWhiteButton } from '../../utilities/black-on-white-button';

export interface ICreateBudgetProps {
    onSubmit?: (budget: ICreateBudgetForm) => void;
    onCancel?: () => void;
    onViewInCostExplorer?: () => void;
    initialBudget?: {
        cloudProviders: CloudProvider[];
        period: BudgetPeriod['value'];
        details: BudgetDetails;
    };
    costTrendData?: CostTrendData[];
}

const CreateBudget: React.FC<ICreateBudgetProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const {
        onSubmit,
        onCancel,
        onViewInCostExplorer,
        initialBudget,
        costTrendData = [
            { date: '2022-12-28', actualCost: 9000 },
            { date: '2023-01-10', actualCost: 10000 },
            { date: '2023-01-23', actualCost: 12500 },
            { date: '2023-02-05', actualCost: 8500 },
            { date: '2023-02-18', actualCost: 7800 },
            { date: '2023-03-02', actualCost: 11000 },
            { date: '2023-03-16', actualCost: 4800, forecastCost: 12000 },
            { date: '2023-03-27', actualCost: 0, forecastCost: 12000 },
        ],
    } = props;

    const [cloudProviders, setCloudProviders] = useState<CloudProvider[]>(
        initialBudget?.cloudProviders ?? [
            { id: 'aws', name: 'AWS', selected: true },
            { id: 'gcp', name: 'Google Cloud', selected: false },
            { id: 'azure', name: 'Azure', selected: true },
            { id: 'snowflake', name: 'Snowflake', selected: false },
            { id: 'kubernetes', name: 'Kubernetes', selected: false },
        ]
    );

    const [selectedPeriod, setSelectedPeriod] = useState<BudgetPeriod['value']>(initialBudget?.period ?? 'weekly');

    const [budgetDetails, setBudgetDetails] = useState<BudgetDetails>(
        initialBudget?.details ?? {
            amount: 12000,
            name: 'Data-pipeline budget',
            startDate: '2023-03-28',
            addGrowthRate: false,
        }
    );

    // Add state for filters dialog and applied filters
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState<RuleGroupType>();

    const handleProviderSelect = (providerId: string) => {
        setCloudProviders((providers) =>
            providers.map((provider) => (provider.id === providerId ? { ...provider, selected: !provider.selected } : provider))
        );
    };

    const handlePeriodSelect = (period: BudgetPeriod['value']) => {
        setSelectedPeriod(period);
    };

    const handleDetailsChange = (details: BudgetDetails) => {
        setBudgetDetails(details);
    };

    const handleFiltersApply = (filters: RuleGroupType) => {
        setAppliedFilters(filters);
        setIsFiltersOpen(false);
    };

    const handleSubmit = () => {
        onSubmit?.({
            cloudProviders: cloudProviders.filter((p) => p.selected),
            period: selectedPeriod,
            details: budgetDetails,
        });
    };

    const handleCancel = () => {
        onCancel?.();
    };

    const handleViewInCostExplorer = (event: React.MouseEvent) => {
        event.preventDefault();
        onViewInCostExplorer?.();
    };

    return (
        <Container maxWidth={false} className={cx(classes.Container)}>
            <Grid2 container spacing={4}>
                <Grid2 size={{ xs: 12, md: 7, lg: 8 }}>
                    <Paper className={cx(classes.FormPanel)}>
                        <Typography variant='h4' className={cx(classes.StepIndicator)}>
                            <Box component='span' className={cx(classes.StepNumber)}>
                                1
                            </Box>
                            Set your budget
                        </Typography>

                        <Box className={cx(classes.Section)}>
                            <Typography variant='h6' className={cx(classes.SectionTitle)}>
                                Choose the cloud provider(s) on which you want to create the budget:
                            </Typography>
                            <CloudProviderSelector providers={cloudProviders} onProviderSelect={handleProviderSelect} />
                        </Box>

                        <Box className={cx(classes.Section)}>
                            <Typography variant='h6' className={cx(classes.SectionTitle)}>
                                Choose which Org Entity this budget is for:
                            </Typography>
                            <Button variant='outlined' className={cx(classes.OrgEntityButton)}>
                                Select an Org Entity
                            </Button>
                        </Box>

                        <Box className={cx(classes.Section)}>
                            <Typography variant='h6' className={cx(classes.SectionTitle)}>
                                Apply data filters to narrow down the cost information tracked in this budget:
                            </Typography>
                            <Button
                                onClick={() => setIsFiltersOpen(true)}
                                variant='outlined'
                                startIcon={<FilterListIcon />}
                                className={cx(classes.FilterButton)}
                            >
                                Configure Filters
                                {appliedFilters?.rules.length ? (
                                    <Chip label={appliedFilters.rules.length} size='small' className={cx(classes.FilterCount)} />
                                ) : null}
                            </Button>

                            <DataFilters
                                open={isFiltersOpen}
                                onClose={() => setIsFiltersOpen(false)}
                                onApply={handleFiltersApply}
                                initialFilters={appliedFilters}
                            />
                        </Box>

                        <Box className={cx(classes.Section)}>
                            <Typography variant='h6' className={cx(classes.SectionTitle)}>
                                Enter budget details:
                            </Typography>
                            <BudgetPeriodSelector selectedPeriod={selectedPeriod} onPeriodSelect={handlePeriodSelect} />
                            <BudgetDetailsForm details={budgetDetails} onDetailsChange={handleDetailsChange} />
                        </Box>
                    </Paper>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 5, lg: 4 }}>
                    <Paper className={cx(classes.PreviewPanel)}>
                        <Box className={cx(classes.PreviewHeader)}>
                            <Box>
                                <Typography variant='h6' className={cx(classes.PreviewTitle)}>
                                    Cost trend
                                </Typography>
                                <Typography variant='body2' color='text.secondary' className={cx(classes.DateRange)}>
                                    Dec 28, 2022 - Mar 27, 2023
                                </Typography>
                            </Box>
                            <Link href='#' className={cx(classes.CostExplorerLink)} onClick={handleViewInCostExplorer}>
                                View in Cost Explorer
                                <OpenInNewIcon fontSize='small' />
                            </Link>
                        </Box>
                        <CostTrendChart data={costTrendData} budgetAmount={budgetDetails.amount} />
                    </Paper>
                </Grid2>
            </Grid2>

            <Box className={cx(classes.Navigation)}>
                <BlackOnWhiteButton onClick={handleCancel}>Cancel</BlackOnWhiteButton>
                <Button variant='contained' onClick={handleSubmit}>
                    Next
                </Button>
            </Box>
        </Container>
    );
};

const useStyles = customMakeStyles<ICreateBudgetProps>()((theme) => ({
    Container: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        minHeight: '100vh',
    },
    FormPanel: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius * 1.5,
        boxShadow: theme.shadows[1],
    },
    PreviewPanel: {
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius * 1.5,
        boxShadow: theme.shadows[1],
        position: 'sticky',
        top: theme.spacing(2),
        height: 'fit-content',
    },
    StepIndicator: {
        fontWeight: 600,
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
    },
    StepNumber: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '50%',
        fontSize: '1.25rem',
    },
    Section: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2.5),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.palette.divider}`,
    },
    SectionTitle: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '&::before': {
            content: '""',
            display: 'block',
            width: 3,
            height: theme.spacing(2.5),
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.shape.borderRadius,
        },
    },
    OrgEntityButton: {
        width: 'fit-content',
    },
    FilterButton: {
        width: '100%',
        maxWidth: 300,
        justifyContent: 'flex-start',
        textTransform: 'none',
        padding: theme.spacing(1, 2),
        fontSize: '1rem',
    },
    FilterCount: {
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.action.hover,
        color: theme.palette.text.primary,
    },
    PreviewHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    PreviewTitle: {
        fontWeight: 600,
        marginBottom: theme.spacing(0.5),
    },
    DateRange: {
        fontSize: '0.95rem',
    },
    CostExplorerLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontSize: '0.95rem',
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.shape.borderRadius,
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            textDecoration: 'none',
        },
    },
    Navigation: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
    },
}));

export { CreateBudget };
