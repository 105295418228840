import React, { useEffect, useMemo, useRef, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CommonPageHeader, IsDashboardShareReady, QuicksightReportLoading, ViewPortHeightAndWidth } from '../jotai/atom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DashboardContentOptions, FrameOptions, createEmbeddingContext, DashboardExperience } from 'amazon-quicksight-embedding-sdk';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

export interface IQuickSightReportProps {
    dashboardUrl: string;
    dashboardId: string;
    viewId?: string | null;
    isTabbed: boolean;
    setDashboardExperience?: React.Dispatch<React.SetStateAction<DashboardExperience | undefined>>;
}

export const QuicksightReport: React.FC<IQuickSightReportProps> = (props) => {
    const [dashboardLoading, setDashboardLoading] = useAtom(QuicksightReportLoading);
    const viewPortHeightAndWidth = useAtomValue(ViewPortHeightAndWidth);
    const commonPageHeader = useAtomValue(CommonPageHeader);
    const { cx, classes } = useStyles({ viewPortHeightAndWidth, commonPageHeader, isTabbed: props.isTabbed });
    const ref = useRef(null);
    const [container, setContainer] = useState<HTMLElement | null>(null);
    const [dashboardFrame, setDashboardFrame] = useState<DashboardExperience | undefined>(undefined);
    const setIsDashboardShareReady = useSetAtom(IsDashboardShareReady);

    useEffect(() => {
        if (ref.current) {
            setContainer(ref.current);
        }
    }, []);

    const frameOptions: FrameOptions = {
        url: decodeURIComponent(props.dashboardUrl),
        height: '100%',
        width: '100%',
        withIframePlaceholder: false,
        resizeHeightOnSizeChangedEvent: false,
        container: 'qsContainer',
    };

    const contentOptions: DashboardContentOptions = useMemo(() => {
        return {
            toolbarOptions: {
                export: true,
                reset: !props.viewId,
                undoRedo: !props.viewId,
                bookmarks: !props.viewId,
            },
            viewId: props.viewId ? props.viewId : undefined,
            onMessage: async (messageEvent, metadata) => {
                switch (messageEvent.eventName) {
                    case 'CONTENT_LOADED': {
                        setIsDashboardShareReady(true);
                        break;
                    }
                    case 'ERROR_OCCURRED': {
                        console.error(messageEvent.message);
                    }
                }
            },
            locale: navigator.language,
        };
    }, [props.viewId]);

    useEffect(() => {
        if (!dashboardFrame) {
            const embedDashboard = async (container: HTMLElement) => {
                const { embedDashboard } = await createEmbeddingContext();

                const df = await embedDashboard({ ...frameOptions, container: container }, contentOptions);
                return df;
            };

            if (container) {
                embedDashboard(container).then((df) => {
                    setDashboardFrame(df);
                    props.setDashboardExperience && props.setDashboardExperience(df);
                });
            }
        } else {
            dashboardFrame?.navigateToDashboard(props.dashboardId);
            setDashboardLoading(false);
        }
    }, [container, props.dashboardId, contentOptions]);

    return <div className={cx(classes.ReportContainer)} ref={ref}></div>;
};

interface IQuicksightStyles {
    commonPageHeader: React.ReactNode;
    isTabbed: boolean;
    viewPortHeightAndWidth: {
        height: number;
        width: number;
    };
}

const useStyles = customMakeStyles<IQuicksightStyles>()((theme, input) => ({
    ReportContainer: {
        overflowY: 'hidden',
        /**
         * Height set here because Quicksight doesn't fill to the container its in when set to 100%, numbers are the total height of the viewport minus
         * the static sizes of the Appbar and Page header if it is there.
         */
        height: input.commonPageHeader ? `calc(100vh - ${input.isTabbed ? '13rem' : '9rem'})` : `calc(100vh - ${input.isTabbed ? '7rem' : '4rem'})`,
        quicksightEmbeddingIframe: {
            quicksightEmbeddingIframe_clickableQuicksightAttribution: {
                display: 'none',
            },
        },
    },
}));
