import React, { useEffect, useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { BusinessGroupingOwnerFormField } from '../business-grouping-owner-form-field';
import { IBusinessGroupingChangeOwnerForm, IBusinessGroupingDetailsForm, IUser } from '@vegaplatformui/models';
import { Control, Controller, FieldErrors, useForm, UseFormHandleSubmit, UseFormRegister, UseFormReset, UseFormSetValue } from 'react-hook-form';
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import { FormField } from '../../../../forms';
import { triggers } from 'aws-cdk-lib';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupingWizardChangeOwnerDialogProps {
    isChangeOwnerDialogOpen: boolean;
    onCloseDialog: () => void;
    owner: IUser | undefined;
    assignedUsers: IUser[];
    control: Control<IBusinessGroupingDetailsForm>;
    setValue: UseFormSetValue<IBusinessGroupingDetailsForm>;
}

const validationSchema: yup.ObjectSchema<IBusinessGroupingChangeOwnerForm> = yup.object().shape({
    business_grouping_owner: yup.mixed<IUser>().required('Owner is required'),
});

const BusinessGroupingWizardChangeOwnerDialog: React.FC<IBusinessGroupingWizardChangeOwnerDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const [previousOwner, setPreviousOwner] = useState<IUser | undefined>(props.owner);
    const theme = useTheme();

    const {
        watch,
        register,
        handleSubmit,
        control,
        reset,
        formState,
        trigger,
        formState: { errors, isDirty, isValid, isSubmitting, touchedFields, isSubmitSuccessful },
    } = useForm<IBusinessGroupingChangeOwnerForm>({
        reValidateMode: 'onChange',
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            business_grouping_owner: props.owner ?? null,
        },
    });

    const onCloseDialog = () => {
        if (props.owner !== undefined) {
            reset({ business_grouping_owner: props.owner });
        }
        props.onCloseDialog();
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
        }
    }, [formState, reset, isSubmitSuccessful]);

    return (
        <Dialog id={'change-owner-form'} open={props.isChangeOwnerDialogOpen} onClose={onCloseDialog} fullWidth maxWidth={'sm'}>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)} variant={'h6'} id='business-grouping-add-users-dialog'>
                <Stack>
                    Switch Owner
                    <Typography variant={'caption'}>You must click the 'Save Business Grouping' button to apply changes.</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <>
                    <FormField label='Owner' htmlFor='business_grouping_owner'>
                        <Controller
                            name={'business_grouping_owner'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Autocomplete
                                    {...register('business_grouping_owner')}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    value={value}
                                    size={'small'}
                                    id='business_grouping_owner'
                                    options={props.assignedUsers}
                                    fullWidth={true}
                                    onChange={(event: any, user: IUser | null, reason) => {
                                        setPreviousOwner(
                                            props.control._formValues.business_grouping_owner !== null
                                                ? control._formValues.business_grouping_owner
                                                : undefined
                                        );
                                        onChange(user);
                                    }}
                                    getOptionLabel={(option) => {
                                        return `${option.email + ' - ' + option.given_name + ' ' + option.family_name}`;
                                    }}
                                    renderOption={(props, user: IUser, { selected }) => (
                                        <li {...props} key={user.id}>
                                            <ListItemIcon>
                                                <Checkbox checked={user.id === value?.id} />
                                            </ListItemIcon>
                                            <Tooltip title={`${user.given_name} ${user.family_name} - ${user.email}`}>
                                                <ListItemText
                                                    primary={`${user.given_name} ${user.family_name}`}
                                                    slotProps={{ primary: { noWrap: true }, secondary: { noWrap: true } }}
                                                    secondary={user.email}
                                                />
                                            </Tooltip>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField placeholder={'Assign an owner'} {...params} error={!!errors.business_grouping_owner} />
                                    )}
                                />
                            )}
                        />
                    </FormField>
                    <FormHelperText>
                        <Typography variant={'caption'} sx={{ color: theme.palette.error.main }}>
                            {errors.business_grouping_owner?.message}
                        </Typography>
                    </FormHelperText>
                </>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack direction={'row'} spacing={1.5} width={'100%'} justifyContent={'flex-end'}>
                    <Button variant={'cancel'} disableElevation={true} color={'secondary'} autoFocus onClick={onCloseDialog}>
                        Cancel
                    </Button>
                    <Button
                        disableElevation={true}
                        type={'button'}
                        variant={'contained'}
                        onClick={(e) => {
                            e.preventDefault();
                            const newOwner = watch('business_grouping_owner');
                            trigger('business_grouping_owner')
                                .then((result) => {
                                    if (newOwner !== null && result) {
                                        props.setValue('business_grouping_owner', newOwner, { shouldValidate: true, shouldDirty: true });
                                        props.onCloseDialog();
                                    }
                                })
                                .finally(() => reset({}));
                        }}
                    >
                        Switch
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IBusinessGroupingWizardChangeOwnerDialogProps>()((theme, props) => ({}));

export { BusinessGroupingWizardChangeOwnerDialog };
