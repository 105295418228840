import React, { useEffect, useRef } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IconButton, ListItem, ListItemButton, ListItemText, Stack, TextField, Tooltip } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { TopicOptions } from './topic-options';
import { IChatbotPutTopicRequest, IChatbotTopic } from '@vegaplatformui/models';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITopicItemProps {
    top: IChatbotTopic;
    loadTopic: (topicId: string) => void;
    renameTopic: (hookRequest: IChatbotPutTopicRequest) => void;
    isRenaming: boolean;
    selectedTopic: IChatbotTopic | undefined;
    setSelectedTopic: React.Dispatch<React.SetStateAction<IChatbotTopic | undefined>>;
    setTopicToDelete: React.Dispatch<React.SetStateAction<IChatbotTopic | undefined>>;
    setIsDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    reopenSelectedTopic: () => void;
    setRenaming: (id: string, isBeingRenamed: boolean) => void;
    isSelected: boolean;
}

const TopicItem: React.FC<ITopicItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const {
        top,
        loadTopic,
        renameTopic,
        isRenaming,
        setTopicToDelete,
        selectedTopic,
        setSelectedTopic,
        setIsDeleteDialogOpen,
        setRenaming,
        isSelected,
        reopenSelectedTopic,
    } = props;
    const popupState = usePopupState({ variant: 'popover', popupId: 'topic-menu-options' });

    const endRenameTopic = (topic: IChatbotTopic) => {
        setRenaming(topic.conversation_id, false);
    };

    const startRenameTopic = (topic: IChatbotTopic) => {
        setRenaming(topic.conversation_id, true);
        popupState.close();
    };

    const onStartTopicDeletion = (topic: IChatbotTopic) => {
        popupState.close();
        setTopicToDelete(topic);
        setIsDeleteDialogOpen(true);
    };

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (isRenaming) {
            inputRef.current && inputRef.current?.focus();
        }
    }, [isRenaming, inputRef]);

    return (
        <Stack component={'template'} key={top.conversation_id} direction='row' alignItems='center' spacing={1}>
            <ListItem
                secondaryAction={
                    !isRenaming && (
                        <IconButton
                            {...bindTrigger(popupState)}
                            color={'inherit'}
                            key={top.conversation_id + '-options-button'}
                            title={`${top.conversation_name}'s Options`}
                            aria-label={`view options for ${top.conversation_name}`}
                            className={cx(classes.IconButton)}
                            onFocus={(e) => e.stopPropagation()}
                            onClick={(e) => {
                                e.stopPropagation();
                                bindTrigger(popupState).onClick(e);
                                setSelectedTopic(top);
                            }}
                        >
                            <MoreHoriz />
                        </IconButton>
                    )
                }
                disablePadding
                className={cx(classes.TopicBox, isRenaming && classes.TopicListItem)}
                key={top.conversation_id + 'menu-item'}
            >
                {!isRenaming ? (
                    <ListItemButton
                        selected={isSelected}
                        onClick={() => {
                            if (!isSelected) {
                                loadTopic(top.conversation_id);
                            } else {
                                reopenSelectedTopic();
                            }
                        }}
                        key={top.conversation_id + '-text'}
                        className={cx(classes.ConversationText)}
                    >
                        <ListItemText
                            className={cx(classes.ListItemText)}
                            primaryTypographyProps={{ className: cx(classes.ListItemTextOverflow) }}
                            primary={top.conversation_name}
                        />
                    </ListItemButton>
                ) : (
                    <TextField
                        type={'text'}
                        key={top.conversation_id + '-text-field'}
                        inputRef={inputRef}
                        className={cx(classes.TextField)}
                        size={'small'}
                        fullWidth
                        inputProps={{ className: cx(classes.TextFieldInput) }}
                        value={selectedTopic?.conversation_name}
                        onBlur={(event) => {
                            endRenameTopic(top);
                        }}
                        onKeyDown={(event) => {
                            event.stopPropagation();
                            if (event.key === 'Enter' && selectedTopic) {
                                renameTopic({ topicId: top.conversation_id, new_name: selectedTopic.conversation_name });
                            }
                            if (event.key === 'Escape') {
                                endRenameTopic(top);
                            }
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => selectedTopic && setSelectedTopic({ ...selectedTopic, conversation_name: e.target.value })}
                    />
                )}
            </ListItem>
            <TopicOptions
                {...bindMenu(popupState)}
                key={top.conversation_id + '-options'}
                elevation={1}
                className={cx(classes.OptionsMenu)}
                startRenameTopic={startRenameTopic}
                onStartTopicDeletion={onStartTopicDeletion}
                topic={selectedTopic ?? top}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<ITopicItemProps>()((theme, props) => ({
    ChatBoxStack: { paddingInlineStart: '0px' },
    IconButton: {
        color: theme.palette.grey[400],
        '&:hover': { backgroundColor: `${theme.palette.grey[200]}` },
    },
    ListItemTextOverflow: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
    ListItemText: { maxWidth: '98%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
    TopicBox: {
        borderRadius: '4px',
        width: '100%',
        minHeight: '62px',
        whiteSpace: 'pre-wrap',
        backgroundColor: theme.palette.common.white,
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
        '& .MuiListItemButton-root': {
            '&.Mui-focusVisible': {
                border: `1px solid ${theme.palette.primary.main}`,
                backgroundColor: `${theme.palette.primary.main}30`,
            },
            '&.Mui-selected': { border: `2px solid ${theme.palette.grey[300]}`, boxShadow: '0 6px 12px 3px rgba(0, 0, 0, 0.1)' },
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.main}30`,
        },
    },
    TopicListItem: { paddingLeft: '14px', paddingRight: '14px' },
    TextField: { flexGrow: 1, maxWidth: '100%' },
    TextFieldInput: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
        color: theme.typography.body2.color,
        paddingLeft: '2px',
    },
    ConversationText: { height: '100%', width: '100%', borderRadius: '4px', minHeight: '62px' },
    OptionsMenu: {
        zIndex: '1303 !important',
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
}));

export { TopicItem };
