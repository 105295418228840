import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Avatar, Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { StyledToolTip } from './styled-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserAvatarNameCellProps {
    params: GridRenderCellParams;
}

const UserAvatarNameCell: React.FC<IUserAvatarNameCellProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { params } = props;

    return (
        <Stack direction='row' height={'100%'} width={'100%'} justifyContent='flex-start' alignItems='center' spacing={1}>
            <Avatar className={cx(classes.MuiDataGridAvatar)} alt={'avatar'}>
                {params.row.given_name.charAt(0).toUpperCase() + params.row.family_name.charAt(0).toUpperCase()}
            </Avatar>
            <Stack className={cx(classes.TypographyOverflow)} direction='column' justifyContent='center' spacing={0}>
                <StyledToolTip placement={'left'} title={`${params.row.given_name} ${params.row.family_name}`} arrow>
                    <Typography className={cx(classes.TypographyOverflow)} variant={'body1'}>
                        {params.row.given_name} {params.row.family_name}
                    </Typography>
                </StyledToolTip>
                <StyledToolTip placement={'left'} title={params.row.email} arrow>
                    <Typography className={cx(classes.TypographyOverflow, classes.EmailTypography)} variant={'caption'}>
                        {params.row.email}
                    </Typography>
                </StyledToolTip>
            </Stack>
        </Stack>
    );
};

const useStyles = customMakeStyles<IUserAvatarNameCellProps>()((theme, props) => ({
    MuiDataGridAvatar: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.getContrastText(theme.palette.grey[100]),
    },
    EmailTypography: { color: theme.palette.text.secondary },
    TypographyOverflow: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 400,
    },
}));

export { UserAvatarNameCell };
