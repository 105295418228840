import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import { IParkingSchedule } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRemoveAdvancedParkingDialogProps {
    show: boolean;
    onClose: () => void;
    onRemoveAdvancedParking: (schedule: IParkingSchedule) => void;
    scheduleToEdit?: IParkingSchedule;
}

const RemoveAdvancedParkingDialog: React.FC<IRemoveAdvancedParkingDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();

    return (
        <Dialog sx={{ zIndex: 1301 }} open={props.show} onClose={props.onClose} maxWidth={'sm'} fullWidth>
            <DialogTitle className={cx(commonStyles.classes.FormTitlePadding)}>
                <Typography fontWeight={500}>
                    Are you sure you want to remove advanced scheduling{props.scheduleToEdit ? ` on ${props.scheduleToEdit.name}?` : '?'}
                </Typography>
            </DialogTitle>
            <DialogContent className={cx(commonStyles.classes.FormContentPadding)}>
                <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                    This will convert the advanced schedule configuration to a basic schedule with no priority groups.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={cx(commonStyles.classes.FormActionsPadding)}>
                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1.5} width={'100%'}>
                    <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                        Cancel
                    </Button>
                    <Button color={'error'} onClick={() => props.onRemoveAdvancedParking(props.scheduleToEdit!)}>
                        Remove
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IRemoveAdvancedParkingDialogProps>()((theme, props) => ({
    GridContainer: { marginTop: '-1.5rem' },
    CancelButton: { padding: '4px' },
}));

export { RemoveAdvancedParkingDialog };
