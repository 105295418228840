import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import DropdownTreeSelect, { TreeNode, TreeNodeProps } from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import { IBusinessGroupingNode } from '@vegaplatformui/models';
import { setSelectedNodes } from '../utilities/selecting-assigned-business-grouping';

interface IBusinessGroupingPickerProps {
    mode: 'hierarchical' | 'radioSelect';
    changeHandler: (selectedNodeIDs: IBusinessGroupingNode[]) => void;
    data: TreeNodeProps[];
    setData: (tree: TreeNodeProps[]) => void;
    texts?: {
        placeholder: string;
        inlineSearchPlaceholder: string;
        noMatches: string;
    };
    keepTreeOnSearch?: boolean;
    inlineSearchInput?: boolean;
    clearSearchOnChange?: boolean;
    keepOpenOnSelect?: boolean;
    showPartiallySelected?: boolean;
    onNodeToggle?: (currentNode: TreeNode) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    disablePoppingOnBackspace?: boolean;
    className?: string;
    readonly?: boolean;
    disabled?: boolean;
    hasError?: boolean;
}

const DropDownWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hasError',
    name: 'BusinessGroupingPicker',
})<{ hasError?: boolean }>(({ theme, hasError }) => ({
    '& .react-dropdown-tree-select': {
        width: '100%',
        '& .dropdown': {
            width: '100%',
        },
        '& .dropdown-content': {
            width: '100%',
            maxHeight: '600px',
            overflowY: 'scroll',
            zIndex: 2,
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
        '& .dropdown-trigger.arrow': {
            borderRadius: '4px',
            borderColor: hasError ? theme.palette.error.main : 'rgb(196, 196, 196)',
            paddingLeft: '10px',
            width: '100%',
            position: 'relative',
            '&::after': {
                color: 'rgba(26, 27, 96, 0.54) !important',
                fontSize: '10px',
                position: 'absolute',
                top: '10px',
                right: '10px',
            },
            '& .tag-list': {
                display: 'inline-block',
                width: '97%',
                '& .tag-item': {
                    margin: '1px 4px',
                },
                '& .placeholder': {
                    color: 'rgba(85, 86, 108)',
                    lineHeight: '28px',
                },
            },
        },
    },
}));

const BusinessGroupingPicker: React.FC<IBusinessGroupingPickerProps> = (props) => {
    const {
        texts = {
            placeholder: 'Select Business Grouping',
            inlineSearchPlaceholder: 'Search',
            noMatches: 'No matches found',
        },
        hasError,
        setData,
        data,
    } = props;

    const onChangeSelection = (current: TreeNode, selectedNodes: TreeNode[]) => {
        setData(
            setSelectedNodes(
                data,
                selectedNodes.map((node) => node.value)
            )
        );
        props.changeHandler(selectedNodes.map((node) => ({ id: node.value, name: node.label })));
    };

    const onNodeToggle = (currentNode: TreeNode) => {
        const searchAndUpdateData: any = (tree: TreeNode[], currentNode: TreeNode) => {
            return tree.map((item) => {
                if (item.value === currentNode.value) {
                    return { ...currentNode, children: item.children };
                } else if (!!item.children) {
                    return { ...item, children: searchAndUpdateData(item.children, currentNode) };
                }
                return item;
            });
        };
        setData(searchAndUpdateData(data, currentNode));
        props.onNodeToggle && props.onNodeToggle(currentNode);
    };

    return (
        <DropDownWrapper hasError={hasError}>
            <DropdownTreeSelect
                texts={{
                    placeholder: texts.placeholder,
                    inlineSearchPlaceholder: texts.inlineSearchPlaceholder,
                    noMatches: texts.noMatches,
                }}
                mode={props.mode}
                data={data}
                onChange={onChangeSelection}
                keepTreeOnSearch={props.keepTreeOnSearch || true}
                inlineSearchInput={props.inlineSearchInput || true}
                clearSearchOnChange={props.clearSearchOnChange || true}
                keepOpenOnSelect={props.keepOpenOnSelect || true}
                showPartiallySelected={props.showPartiallySelected || true}
                readOnly={props.readonly || false}
                disabled={props.disabled || false}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onNodeToggle={onNodeToggle}
                disablePoppingOnBackspace={true}
                className={props.className}
            ></DropdownTreeSelect>
        </DropDownWrapper>
    );
};

export { BusinessGroupingPicker };
