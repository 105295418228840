import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { MfaTile } from './mfa-tile';
import { IUserSettingMFAStatusEnum } from '@vegaplatformui/models';
import { Box } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMfaInputProps {
    status: IUserSettingMFAStatusEnum;
    onSubmit: (data: string) => void;
}

const MfaInput: React.FC<IMfaInputProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Box width={'100%'} sx={{ paddingLeft: '1rem' }}>
            <MfaTile
                mfaDescription={'Input code generated from an authentication app'}
                mfaType={'Authenticator'}
                status={props.status}
                onSubmit={props.onSubmit}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<IMfaInputProps>()((theme, props) => ({}));

export { MfaInput };
