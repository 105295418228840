import React from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { IUser, IUserSettingRealmRole } from '@vegaplatformui/models';
import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridPagination, GridSlotsComponent } from '@mui/x-data-grid';
import { DataGridCustomToolbar } from '../../../../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../../../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { CustomGridColSingleSelectOperatorDef, CustomGridColStringOperatorDef } from '../../../../utilities/custom-grid-col-string-operator-def';
import { UserAvatarNameCell } from '../../../../utilities/user-avatar-name-cell';
import dayjs from 'dayjs';
import { tableIdentifierKeys } from '../../../../use-table-utilities/table-identifier-keys';
import { useTableUtilities } from '../../../../use-table-utilities/use-table-utilities';
import { DataGridCellTooltip } from '../../../../utilities/datagrid-cell-tooltip';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRoleDetailMembersProps {
    members: IUser[];
    realmRole?: IUserSettingRealmRole | undefined;
    isLoading: boolean;
}

const RoleDetailMembersTab: React.FC<IRoleDetailMembersProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const { members, realmRole, isLoading } = props;

    const permissionDetailsMemberTableUtilities = useTableUtilities(tableIdentifierKeys.permissionDetailsMemberTable);
    React.useEffect(() => {
        permissionDetailsMemberTableUtilities.registerTableControl();
        return () => {
            permissionDetailsMemberTableUtilities.unregisterTableControl();
        };
    }, []);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'User',
            flex: 2.5,
            display: 'flex',
            valueGetter: (value: string, row: IUser) => {
                return `${row.given_name} ${row.family_name}`;
            },
            renderCell: (params: GridRenderCellParams<IUser>) => <UserAvatarNameCell params={params} />,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'created_at',
            headerName: 'User Creation Date',
            flex: 1.5,
            valueFormatter: (value: string, row: IUser) => {
                if (!value) {
                    return '';
                }
                return dayjs(value).format('L');
            },
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'emailVerified',
            headerName: 'Status',
            display: 'flex',
            flex: 1,
            renderCell: (params: GridRenderCellParams<IUser>) => (
                <span>
                    <Chip
                        variant={'outlined'}
                        sx={{
                            color: `${params.value ? theme.palette.success.dark : theme.palette.warning.dark}`,
                            borderColor: `${params.value ? theme.palette.success.main : theme.palette.warning.main}`,
                        }}
                        size={'small'}
                        label={<Typography variant={'caption'}>{`${params.value ? 'Active' : 'Invited'}`}</Typography>}
                    />
                </span>
            ),
            type: 'singleSelect',
            valueOptions: [
                { label: 'Active', value: true },
                { label: 'Invited', value: false },
            ],
        },
    ];

    columns.map((column) => {
        if (!column.renderCell) column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions').map((column) => column.field);
    };

    return (
        <Stack direction={'column'}>
            <Stack alignItems={'center'} justifyContent={'flex-start'} direction={'row'}>
                <Stack direction={'column'}>
                    <Typography className={cx(classes.RoleMemberListSubtitle)} variant={'subtitle2'}>
                        Role members
                    </Typography>
                    <Typography className={cx(classes.RoleMemberListValue)} variant={'h6'}>
                        {members.length}
                    </Typography>
                </Stack>
            </Stack>
            <Box className={cx(classes.DatagridBox)}>
                <DataGridPremium
                    className={
                        members.length > 0
                            ? cx(commonStyles.classes.MuiDataGridAutoRowSize)
                            : cx(commonStyles.classes.MuiDataGridAutoRowSize, commonStyles.classes.MuiDataGridMinHeightLoader)
                    }
                    slots={{
                        toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                        columnMenu: CustomDatagridColumnMenu,
                        pagination: GridPagination,
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: { email: false },
                        },
                    }}
                    slotProps={{
                        pagination: {
                            showLastButton: true,
                            showFirstButton: true,
                            slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                        },
                        toolbar: {
                            tableIdentifier: tableIdentifierKeys.permissionDetailsMemberTable,
                            defaultHidden: { email: false },
                        },
                        columnsManagement: {
                            getTogglableColumns,
                        },
                        noRowsOverlay: {
                            action: 'assigning this role to a user',
                        },
                    }}
                    filterMode={'client'}
                    sortingMode={'client'}
                    getEstimatedRowHeight={() => 77}
                    getRowHeight={() => 'auto'}
                    density={permissionDetailsMemberTableUtilities.currentTableControl?.density ?? 'standard'}
                    onDensityChange={permissionDetailsMemberTableUtilities.onDensityChange}
                    sortModel={permissionDetailsMemberTableUtilities.currentTableControl?.sortModel}
                    onSortModelChange={permissionDetailsMemberTableUtilities.onSortModelChange}
                    filterModel={permissionDetailsMemberTableUtilities.currentTableControl?.filterModel}
                    onFilterModelChange={permissionDetailsMemberTableUtilities.onFilterModelChange}
                    columnVisibilityModel={permissionDetailsMemberTableUtilities.currentTableControl?.columnModel}
                    onColumnVisibilityModelChange={permissionDetailsMemberTableUtilities.onColumnVisibilityModelChange}
                    columns={columns}
                    rows={members}
                    loading={isLoading}
                />
            </Box>
        </Stack>
    );
};

const useStyles = customMakeStyles<IRoleDetailMembersProps>()((theme, props) => ({
    DatagridBox: { display: 'flex', flexDirection: 'column', height: 'auto', paddingTop: '1.5rem' },
    RoleMemberListSubtitle: { color: '#667085' },
    RoleMemberListValue: { fontWeight: theme.typography.fontWeightMedium, color: '#344054' },
}));

export { RoleDetailMembersTab };
