import React, { useEffect } from 'react';
import { FormField } from '../forms';
import { QuicksightReportLoadingSkeleton } from '../quicksight-reports/quicksight-report-loading-skeleton';
import { Grid, Stack, Typography, TextField, useTheme, Button, Select, MenuItem, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { IUser, ILogoVariation, IOrganizationThemeColors, IOrganizationThemeForm, IOrgData } from '@vegaplatformui/models';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    OrganizationId,
    SnackBarOptions,
    CustomColorsState,
    CustomPrimaryLogoState,
    CustomSecondaryLogoState,
    CustomFaviconState,
    // CustomFaviconState,
} from '../recoil/atom';
import { ImageUpload } from './image-upload';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ContentCopy, Info } from '@mui/icons-material';

export interface IOrganizationSettingsProps {
    allUsers: IUser[];
    createOrganizationTheme: (orgId: string, theme: IOrganizationThemeColors) => void;
    isLoading: boolean;
    onOrgDataUpdate: (data: IOrgData) => void;
    onPhotoRemove: (orgId: string, variation: ILogoVariation) => void;
    onPhotoUpload: (orgId: string, variation: ILogoVariation, file: File) => void;
    orgName: string;
    orgOwner?: IUser;
    orgSlug?: string;
    orgUpdateStatus: string;
    themeCreateStatus: string;
    themeUpdateStatus: string;
    updateOrganizationTheme: (orgId: string, theme: IOrganizationThemeColors) => void;
}

const orgDataValidationSchema: yup.ObjectSchema<IOrgData> = yup.object().shape({
    organizationName: yup.string().required('Organization Name is required.'),
    organizationOwner: yup.string(),
});

const validationSchema: yup.ObjectSchema<IOrganizationThemeForm> = yup.object().shape({
    primary_color: yup.string().required('Primary Color is required.'),
    secondary_color: yup.string().required('Secondary Color is required.'),
});

const OrganizationSettings: React.FC<IOrganizationSettingsProps> = ({
    allUsers,
    createOrganizationTheme,
    isLoading,
    onOrgDataUpdate,
    onPhotoRemove,
    onPhotoUpload,
    orgName,
    orgOwner,
    orgSlug,
    orgUpdateStatus,
    themeCreateStatus,
    themeUpdateStatus,
    updateOrganizationTheme,
}) => {
    const flags = useFlags();
    const { classes, cx } = useStyles();
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const organizationId = useRecoilValue(OrganizationId);
    const customColors = useRecoilValue(CustomColorsState);
    const primaryLogoUrl = useRecoilValue(CustomPrimaryLogoState).url;
    const secondaryLogoUrl = useRecoilValue(CustomSecondaryLogoState).url;
    const faviconUrl = useRecoilValue(CustomFaviconState).url;

    const handleLogoRemoval = (variation: ILogoVariation) => {
        onPhotoRemove(organizationId, variation);
    };

    const fileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/ico', 'image/vnd.microsoft.icon', 'image/x-icon'];
    const validateFileType = (type: string) => {
        return fileTypes.includes(type);
    };

    const validateFileSize = (size: any) => {
        // Restrict max size to 1MB
        return size <= 1000000;
    };

    const handleLogoUpload = (evt: React.ChangeEvent<HTMLInputElement>, variation: ILogoVariation) => {
        if (evt.target.files?.length) {
            const logoFile = evt.target.files[0];
            if (!validateFileType(logoFile.type)) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'The file type is not supported.',
                });
                evt.target.value = '';
            } else if (!validateFileSize(logoFile.size)) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'The file size exceeds the maximum allowed 1MB.',
                });
                evt.target.value = '';
            } else {
                onPhotoUpload(organizationId, variation, logoFile);
            }
        }
    };

    const handleCopy = (fieldCopied: string, fieldToCopy: string) => {
        navigator.clipboard
            .writeText(fieldToCopy)
            .then(
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'success' },
                        message: `${fieldCopied} copied to clipboard`,
                    });
                },
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'error' },
                        message: `Failed to copy ${fieldCopied} to clipboard`,
                    });
                }
            )
            .catch(() => {
                alert(`Failed to copy ${fieldCopied} to clipboard`);
            });
    };

    const {
        handleSubmit: orgDataHandleSubmit,
        control: orgDataControl,
        reset: orgDataReset,
        formState: { isDirty: isOrgDataDirty, errors: orgDataErrors },
    } = useForm<any>({
        resolver: yupResolver(orgDataValidationSchema),
        defaultValues: {
            organizationName: orgName,
            organizationOwner: orgOwner?.id || '',
        },
    });

    useEffect(() => {
        orgDataReset({
            organizationName: orgName,
            organizationOwner: orgOwner?.id || '',
        });
    }, [orgName]);

    const onOrgDataFormSubmit: SubmitHandler<IOrgData> = (data) => {
        onOrgDataUpdate(data);
    };

    const {
        handleSubmit: themeHandleSubmit,
        control: themeControl,
        reset: themeReset,
        formState: { isDirty: themeIsDirty, isValid: themeIsValid, defaultValues: themeDefaultValues },
    } = useForm<IOrganizationThemeForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            primary_color: customColors.primary_color,
            secondary_color: customColors.secondary_color,
        },
    });

    useEffect(() => {
        themeReset({
            primary_color: customColors.primary_color,
            secondary_color: customColors.secondary_color,
        });
    }, [customColors]);

    const onSubmitThemeForm: SubmitHandler<IOrganizationThemeForm> = (data) => {
        return customColors.isCustomColors ? updateOrganizationTheme(organizationId, data) : createOrganizationTheme(organizationId, data);
    };

    return (
        <>
            {/* Org Data */}
            {!orgName || isLoading ? (
                <QuicksightReportLoadingSkeleton />
            ) : (
                <Stack direction={'column'} spacing={5}>
                    <Grid
                        container
                        component={'form'}
                        id={'organization-data-form'}
                        onSubmit={orgDataHandleSubmit((data: any) => onOrgDataFormSubmit(data))}
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography variant='h3'>Organization</Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    Manage your organization's details.
                                </Typography>
                            </Stack>
                        </Grid>
                        {/* Organization ID (Readonly) */}
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label={
                                    <Stack width={'100%'} alignItems='flex-start' spacing={0.5} direction={'row'}>
                                        <Typography color={'inherit'} fontSize={'inherit'} fontWeight={'inherit'}>
                                            Organization ID
                                        </Typography>
                                        <Tooltip title={`Your organization's Unique Identifier used by Vega.`} placement={'top'} arrow>
                                            <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                                        </Tooltip>
                                    </Stack>
                                }
                                htmlFor='orgId'
                            >
                                <TextField
                                    fullWidth
                                    id='orgId'
                                    value={organizationId}
                                    size={'small'}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    disabled={!organizationId}
                                                    aria-label='copy organization id'
                                                    onClick={() => handleCopy('Organization ID', organizationId)}
                                                >
                                                    <ContentCopy />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormField>
                        </Grid>
                        {/* Organization Slug (Readonly) */}
                        <Grid item xs={12} sm={6}>
                            <FormField
                                label={
                                    <Stack width={'100%'} alignItems='flex-start' spacing={0.5} direction={'row'}>
                                        <Typography color={'inherit'} fontSize={'inherit'} fontWeight={'inherit'}>
                                            Organization Slug
                                        </Typography>
                                        <Tooltip title={`Your organization's slug used by Vega.`} placement={'top'} arrow>
                                            <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                                        </Tooltip>
                                    </Stack>
                                }
                                htmlFor='orgSlug'
                            >
                                <TextField
                                    fullWidth={true}
                                    id='orgSlug'
                                    value={orgSlug}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    disabled={!orgSlug}
                                                    aria-label='copy organization slug'
                                                    onClick={() => orgSlug && handleCopy('Organization Slug', orgSlug)}
                                                >
                                                    <ContentCopy />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    size={'small'}
                                />
                            </FormField>
                        </Grid>
                        {orgName && allUsers && (
                            <>
                                {/* Organziation Name */}
                                <Grid item xs={12} sm={6}>
                                    <FormField label={'Organization Name'} htmlFor='organizationName'>
                                        <Controller
                                            name='organizationName'
                                            control={orgDataControl}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    id={'organizationName'}
                                                    onChange={onChange}
                                                    size='small'
                                                    fullWidth={true}
                                                    value={value}
                                                    error={!!orgDataErrors.organizationName}
                                                />
                                            )}
                                        ></Controller>
                                    </FormField>
                                </Grid>
                                {/* Organization Owner */}
                                {flags.isVegaAdmin && false && (
                                    <Grid item xs={12} sm={6}>
                                        <FormField label={'Organization Owner'} htmlFor='organizationOwner'>
                                            <Controller
                                                name={'organizationOwner'}
                                                control={orgDataControl}
                                                render={({ field: { onChange, value } }) => (
                                                    <Select
                                                        id={'organizationOwner'}
                                                        name={'organizationOwner'}
                                                        size={'small'}
                                                        fullWidth={true}
                                                        value={value}
                                                        onChange={(evt: any, owner: any) => {
                                                            onChange(evt.target.value);
                                                        }}
                                                    >
                                                        {allUsers.map((user: IUser) => {
                                                            return (
                                                                <MenuItem key={user.id} value={user.id}>
                                                                    {user.given_name} {user.family_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                )}
                                            ></Controller>
                                        </FormField>
                                    </Grid>
                                )}
                                {/* Form Handler Buttons */}
                                <Grid item xs={12}>
                                    <Stack className={cx(classes.FormButtonStack)} direction='row' spacing={2} justifyContent={'flex-end'}>
                                        <Button
                                            variant='cancel'
                                            onClick={() =>
                                                orgDataReset({
                                                    organizationName: orgName,
                                                    organizationOwner: orgOwner ? orgOwner.id : '',
                                                })
                                            }
                                            disabled={!isOrgDataDirty || orgUpdateStatus === 'pending'}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            form={'organization-data-form'}
                                            variant='contained'
                                            type='submit'
                                            disabled={!isOrgDataDirty || orgUpdateStatus === 'pending'}
                                        >
                                            Save
                                        </Button>
                                    </Stack>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    {/* Images */}
                    <Grid container spacing={2} mb={'40px'}>
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography variant='h3'>Images</Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    Customize your own logos in the platform.
                                </Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    File type: .png, .jpg, .jpeg, .ico.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ mb: '10px' }}>
                            <ImageUpload
                                imageSrc={primaryLogoUrl}
                                alt={'Primary Organization Logo'}
                                sizes={{ maxWidth: 200, maxHeight: 200 }}
                                handleUpload={handleLogoUpload}
                                handleRemove={handleLogoRemoval}
                                title={'Primary Logo'}
                                subtitle={'Max dimensions: 200x200'}
                                variation={ILogoVariation.PRIMARY}
                            ></ImageUpload>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ mb: '10px' }}>
                            <ImageUpload
                                imageSrc={secondaryLogoUrl}
                                alt={'Secondary Organization Logo'}
                                sizes={{ maxWidth: 50, maxHeight: 50 }}
                                handleUpload={handleLogoUpload}
                                handleRemove={handleLogoRemoval}
                                title={'Secondary Logo'}
                                subtitle={'Max dimensions: 50x50'}
                                variation={ILogoVariation.SECONDARY}
                            ></ImageUpload>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ mb: '10px' }}>
                            <ImageUpload
                                imageSrc={faviconUrl}
                                alt={'Organization Favicon'}
                                sizes={{ maxWidth: 16, maxHeight: 16 }}
                                handleUpload={handleLogoUpload}
                                handleRemove={handleLogoRemoval}
                                title={'Favicon'}
                                subtitle={'Max dimensions: 16x16'}
                                variation={ILogoVariation.FAVICON}
                            ></ImageUpload>
                        </Grid>
                    </Grid>
                    {/* Theme Info */}
                    <Grid
                        onSubmit={themeHandleSubmit((data) => {
                            onSubmitThemeForm(data);
                        })}
                        id={'organization-theme-form'}
                        component={'form'}
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <Stack spacing={0.5}>
                                <Typography variant='h3'>Theme</Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    Customize your own palette in the platform.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField label={'Primary Color'} htmlFor='orgThemePrimaryColor'>
                                <Controller
                                    name={'primary_color'}
                                    control={themeControl}
                                    render={({ field: { onChange, value } }) => (
                                        <MuiColorInput
                                            id={'orgThemePrimaryColor'}
                                            onChange={onChange}
                                            value={value}
                                            format={'hex'}
                                            isAlphaHidden={true}
                                            fallbackValue={'#7C3AED'}
                                            className={cx(classes.colorPickerInput)}
                                        />
                                    )}
                                />
                            </FormField>
                        </Grid>
                        {/*<Grid item xs={12} sm={6}>*/}
                        {/*    <FormField label={'Secondary Color'} htmlFor='orgThemeSecondaryColor'>*/}
                        {/*        <Controller*/}
                        {/*            name={'secondary_color'}*/}
                        {/*            control={themeControl}*/}
                        {/*            render={({ field: { onChange, value } }) => (*/}
                        {/*                <MuiColorInput*/}
                        {/*                    id={'orgThemeSecondaryColor'}*/}
                        {/*                    value={value}*/}
                        {/*                    onChange={onChange}*/}
                        {/*                    format={'hex'}*/}
                        {/*                    isAlphaHidden={true}*/}
                        {/*                    fallbackValue={'#1A1B60'}*/}
                        {/*                    className={cx(classes.colorPickerInput)}*/}
                        {/*                />*/}
                        {/*            )}*/}
                        {/*        />*/}
                        {/*    </FormField>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sx={{ mt: '20px' }}>
                            <Stack className={cx(classes.FormButtonStack)} direction='row' spacing={2} justifyContent={'flex-end'}>
                                <Button
                                    variant='cancel'
                                    onClick={() => {
                                        themeReset(
                                            {
                                                primary_color: customColors.primary_color,
                                                secondary_color: customColors.secondary_color,
                                            },
                                            { keepDefaultValues: true, keepIsValid: false }
                                        );
                                    }}
                                    disabled={!themeIsDirty || !themeIsValid || themeUpdateStatus === 'pending' || themeCreateStatus === 'pending'}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    form={'organization-theme-form'}
                                    variant='contained'
                                    type='submit'
                                    disabled={!themeIsDirty || !themeIsValid || themeUpdateStatus === 'pending' || themeCreateStatus === 'pending'}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            )}
        </>
    );
};

const useStyles = customMakeStyles()((theme) => ({
    colorPickerInput: {
        width: '100%',
        input: {
            padding: '10px',
        },
    },
    FormButtonStack: { marginRight: '1rem' },
}));

export { OrganizationSettings };
