import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ITimeZone } from '@vegaplatformui/utils';
import { IParkingScheduleSummary } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangeScheduleStatusNonScheduledProps {
    onClose: () => void;
    onChangeScheduleStatuses: (
        schedules: IParkingScheduleSummary[],
        isEnabled: boolean,
        disableStartDateTime?: Date,
        enableStartDateTime?: Date,
        timeZone?: ITimeZone
    ) => void;
    schedulesToChangeStatusOn: IParkingScheduleSummary[];
}

const ChangeScheduleStatusNonScheduledContent: React.FC<IChangeScheduleStatusNonScheduledProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [enabled, setIsEnabled] = React.useState<boolean>(true);

    return (
        <>
            <DialogTitle>
                <Grid container className={cx(classes.GridContainer)} direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                    <Grid xs={11} item>
                        <Typography fontWeight={500}>
                            {props.schedulesToChangeStatusOn.length === 1
                                ? `Do you want to turn ${props.schedulesToChangeStatusOn[0]?.is_enabled ? `off ` : 'on '}`
                                : props.schedulesToChangeStatusOn.length > 1 &&
                                  `Do you want to ${enabled ? 'enable' : 'disable'} ${props.schedulesToChangeStatusOn.length} schedules`}
                            {props.schedulesToChangeStatusOn.length === 1 && props.schedulesToChangeStatusOn[0]?.name}?
                        </Typography>
                    </Grid>
                    <Grid xs={1} item className={cx(classes.CancelButton)}>
                        <IconButton size={'small'} onClick={props.onClose}>
                            <Close fontSize={'small'} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {props.schedulesToChangeStatusOn.length === 1 ? (
                    <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                        This will mean the schedule will
                        {props.schedulesToChangeStatusOn[0]?.is_enabled ? ' not ' : ' '}
                        execute during the scheduled times.
                    </DialogContentText>
                ) : (
                    <>
                        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={0}>
                            <Typography>Disabled</Typography>
                            <Switch onChange={() => setIsEnabled(!enabled)} checked={enabled} />
                            <Typography>Enabled</Typography>
                        </Stack>
                        <DialogContentText variant={'body2'} id='confirm-close-dialog-description'>
                            This will mean the selected schedules will
                            {!enabled ? ' not ' : ' '}
                            execute during the scheduled times.
                        </DialogContentText>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onClose} autoFocus>
                    Cancel
                </Button>
                <Button
                    onClick={() =>
                        props.onChangeScheduleStatuses(
                            props.schedulesToChangeStatusOn,
                            props.schedulesToChangeStatusOn.length === 1 ? !props.schedulesToChangeStatusOn[0].is_enabled : enabled
                        )
                    }
                >
                    {props.schedulesToChangeStatusOn.length === 1
                        ? props.schedulesToChangeStatusOn[0]?.is_enabled
                            ? 'Yes, Turn Schedule Off'
                            : ' Yes, Turn Schedule On'
                        : props.schedulesToChangeStatusOn.length > 1 && `Yes, turn schedules ${enabled ? 'on' : 'off'}`}
                </Button>
            </DialogActions>
        </>
    );
};

const useStyles = customMakeStyles<IChangeScheduleStatusNonScheduledProps>()((theme, props) => ({
    GridContainer: { marginTop: '-1.5rem' },
    CancelButton: { marginTop: '-.5rem', marginRight: '-1.25rem' },
}));

export { ChangeScheduleStatusNonScheduledContent };
