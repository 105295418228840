import { getGridBooleanOperators, getGridSingleSelectOperators, getGridStringOperators, GridColDef } from '@mui/x-data-grid-premium';

export const CustomGridColStringOperatorDef = {
    filterOperators: [...getGridStringOperators().filter((op) => op.value !== 'isEmpty' && op.value !== 'isNotEmpty')],
};

export const CustomGridColSingleSelectOperatorDef = {
    filterOperators: [...getGridSingleSelectOperators().filter((op) => op.value !== 'not')],
};

export const CustomGridColSingleSelectOperatorDef_OnlyIs = {
    filterOperators: [...getGridSingleSelectOperators().filter((op) => op.value !== 'not' && op.value !== 'isAnyOf')],
};

export const CustomGridColDateOperatorDef = {
    filterOperators: [...getGridSingleSelectOperators().filter((op) => op.value !== 'isEmpty' && op.value !== 'isNotEmpty')],
};

export const CustomGridColStringContainsOnlyDef = {
    filterOperators: getGridStringOperators().filter((op) => op.value === 'contains'),
};
