import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMenuItemCategoryProps {
    categoryTitle: string;
}

const MenuItemCategory: React.FC<IMenuItemCategoryProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Typography variant={'caption'} className={cx(classes.MenuGroup)}>
            {props.categoryTitle}
        </Typography>
    );
};

const useStyles = customMakeStyles<IMenuItemCategoryProps>()((theme, props) => ({
    MenuGroup: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        cursor: 'default',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[400],
    },
}));

export { MenuItemCategory };
