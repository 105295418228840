import React, { StrictMode } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SnackbarErrorOutput, SnackBarOptions } from '@vegaplatformui/sharedcomponents';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { maintenancePageHtml } from '../components/maintenance/maintenance-page';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { UserDataLoadingHandler } from './user-data-loading-handler';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAppLoadingHandlerProps {}

const AppLoadingHandler: React.FC<IAppLoadingHandlerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { maintenance } = useFlags();
    const setSnackbarOptions = useSetAtom(SnackBarOptions);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: false,
            },
            mutations: {
                retry: false,
            },
        },
        queryCache: new QueryCache({
            onError: (error, query) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `${query?.meta?.errorMessage ?? 'Something went wrong'}: ${SnackbarErrorOutput(error ?? '')}`,
                });
            },
        }),
    });

    function Maintenance() {
        return <div dangerouslySetInnerHTML={{ __html: maintenancePageHtml }} />;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <StrictMode>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BrowserRouter>{maintenance ? <Maintenance /> : <UserDataLoadingHandler />}</BrowserRouter>
                </LocalizationProvider>
            </StrictMode>
            {process.env.NX_PUBLIC_REACT_APP_HIDE_DEV_TOOLS === 'true' ? null : <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
};

const useStyles = customMakeStyles<IAppLoadingHandlerProps>()((theme, props) => ({}));

export { AppLoadingHandler };
