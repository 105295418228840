import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    CreateContainerDialog,
    DrawerScheduler,
    ResourcesTable,
    ResourcesSummaryCard,
    useResourcesApi,
    useBusinessGroupingsApi,
    tableIdentifierKeys,
} from '@vegaplatformui/sharedcomponents';
import { Button, Card, CardContent, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { ContainerType } from '@vegaplatformui/models';
import { Add, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { GlobalCardHeight } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IResourcesControllerProps {}

const ResourcesController: React.FC<IResourcesControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const tableIdentifier = tableIdentifierKeys.resourcesTable;
    const { spaces, vOperate } = useFlags();

    const {
        anchorEl,
        containerToEditForm,
        isCreateMenuOpen,
        isCreateResourcePoolDialog,
        isDrawerScheduleOpen,
        isLoading,
        onClickCreateResourcePoolItem,
        onClickCreateSchedule,
        onClickParkingScheduleItem,
        onClickToggleCreateDropDown,
        onCloseCreateContainerDialog,
        onCloseDrawerScheduler,
        onCloseToggleCreateDropDown,
        onSubmitCreateContainerForm,
        parkingScheduleType,
        resources,
        selectedResources,
        setContainerToEditForm,
        setResourcesSelectedForParking,
        setSelectedResources,
        workloads,
        summaryActiveResources,
        summaryTotalResources,
        summaryScheduledResources,
    } = useResourcesApi({ tableIdentifier: tableIdentifier });
    const businessGroupingApi = useBusinessGroupingsApi({});

    return (
        <Stack className={cx(classes.StackHeight)} direction={'column'} spacing={1}>
            <ResourcesSummaryCard
                summaryActiveResources={summaryActiveResources}
                summaryScheduledResources={summaryScheduledResources}
                summaryTotalResources={summaryTotalResources}
            />
            <Card className={cx(classes.CardHeight)} elevation={0}>
                <CardContent>
                    <Grid container direction={'column'} marginBottom={4}>
                        <Grid item xs={12} container>
                            <Typography variant={'h1'}>Resources</Typography>
                        </Grid>
                        <Grid item xs={12} container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Stack direction={'column'} spacing={0}>
                                <Typography variant={'subtitle1'}>
                                    Cloud resources are refreshed hourly, potentially varying in count, and can be populated via manual resource
                                    discovery in provider accounts.
                                </Typography>
                            </Stack>
                            {(spaces || vOperate) && (
                                <Stack direction={'row'}>
                                    <Button
                                        startIcon={<Add />}
                                        variant={'contained'}
                                        onClick={onClickToggleCreateDropDown}
                                        endIcon={isCreateMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                                        disabled={!selectedResources.length}
                                    >
                                        Create
                                    </Button>
                                    <Menu id='resource-create-menu' anchorEl={anchorEl} open={isCreateMenuOpen} onClose={onCloseToggleCreateDropDown}>
                                        {spaces && <MenuItem onClick={onClickCreateResourcePoolItem}>Resource Pool</MenuItem>}
                                        {/*{isOperateEnabled && (*/}
                                        {/*    <Tooltip*/}
                                        {/*        placement='left'*/}
                                        {/*        title={*/}
                                        {/*            !businessGroupingApi.myBusinessUnitTree || businessGroupingApi.myBusinessUnitTree.length === 0*/}
                                        {/*                ? 'User has no assigned business groupings.'*/}
                                        {/*                : selectedResources.every((resource) => resource.is_parking_capable)*/}
                                        {/*                  ? ''*/}
                                        {/*                  : 'Current selection contains non-parkable resources.'*/}
                                        {/*        }*/}
                                        {/*    >*/}
                                        {/*        <Stack>*/}
                                        {/*            <MenuItem*/}
                                        {/*                disabled={*/}
                                        {/*                    !selectedResources.every((resource) => resource.is_parking_capable) ||*/}
                                        {/*                    !businessGroupingApi.myBusinessUnitTree ||*/}
                                        {/*                    businessGroupingApi.myBusinessUnitTree.length === 0*/}
                                        {/*                }*/}
                                        {/*                onClick={() => onClickParkingScheduleItem(ParkingScheduleType.Basic)}*/}
                                        {/*            >*/}
                                        {/*                Basic Parking Schedule*/}
                                        {/*            </MenuItem>*/}
                                        {/*            <MenuItem*/}
                                        {/*                disabled={*/}
                                        {/*                    !selectedResources.every((resource) => resource.is_parking_capable) ||*/}
                                        {/*                    !businessGroupingApi.myBusinessUnitTree ||*/}
                                        {/*                    businessGroupingApi.myBusinessUnitTree.length === 0*/}
                                        {/*                }*/}
                                        {/*                onClick={() => onClickParkingScheduleItem(ParkingScheduleType.Advanced)}*/}
                                        {/*            >*/}
                                        {/*                Advanced Parking Schedule*/}
                                        {/*            </MenuItem>*/}
                                        {/*        </Stack>*/}
                                        {/*    </Tooltip>*/}
                                        {/*)}*/}
                                    </Menu>
                                </Stack>
                            )}
                        </Grid>
                    </Grid>
                    <Stack direction='column'>
                        <ResourcesTable
                            resourcesTableIdentifier={tableIdentifier}
                            selectedResources={selectedResources}
                            setSelectedResources={setSelectedResources}
                            resources={resources}
                            isLoading={isLoading}
                            isServerPaginated={true}
                            isDynamicRowSize={true}
                            shouldRegisterTableControl={true}
                        />
                    </Stack>
                </CardContent>
            </Card>
            <CreateContainerDialog
                createContainerLocation={ContainerType.ResourcePool}
                containerToEditForm={containerToEditForm}
                setContainerToEditForm={setContainerToEditForm}
                isDialogOpen={isCreateResourcePoolDialog}
                onCloseDialog={onCloseCreateContainerDialog}
                onSubmitCreateContainerForm={onSubmitCreateContainerForm}
                containerToEdit={undefined}
                onClickDeleteSpace={(space) => {}}
                allContainers={[]}
                spaces={[]}
                workloads={workloads}
                resourcePools={[]}
                resources={[]}
                enableResourcesTableDisplay={false}
                isResourcesLoading={false}
                selectedResources={selectedResources}
            />
            <DrawerScheduler
                setResourcesSelectedForParking={setResourcesSelectedForParking}
                parkingScheduleType={parkingScheduleType}
                isServerPaginated={true}
                resources={selectedResources}
                isLoading={false}
                width={'60%'}
                isOpen={isDrawerScheduleOpen}
                onCloseDrawer={onCloseDrawerScheduler}
                onSave={onClickCreateSchedule}
                hideResourcesTab={false}
                usersBusinessGroupings={businessGroupingApi.myBusinessUnitTree || []}
                selectedResources={selectedResources}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<IResourcesControllerProps>()((theme, props) => ({
    Tabpanel: {
        paddingBlock: theme.spacing(2),
    },
    StopButton: {
        marginLeft: '1rem',
        marginRight: '1rem',
    },
    StackHeight: { minHeight: '100%' },
    CardHeight: { minHeight: `calc(${GlobalCardHeight} - 6.5rem)` },
}));

export { ResourcesController };
