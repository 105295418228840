import { Button, Card, Grid2, Popper, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Form, FormField } from '../../forms';
import { SubmitHandler } from 'react-hook-form';
import { PasswordStrengthIndicator, strengthCheckList } from './password-strength-indicator';
import { IUserSettingPassword } from '@vegaplatformui/models';

import { useKeycloak } from '@react-keycloak-fork/web';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPasswordSettingsProps {
    onUpdatePassword: (data: IUserSettingPassword) => void;
}

const PasswordSettings: React.FC<IPasswordSettingsProps> = (props) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [passwordToCheck, setPasswordToCheck] = useState('');
    const [isPasswordStrong, setIsPasswordStrong] = useState<boolean>(false);
    const { keycloak } = useKeycloak();

    const onSubmit: SubmitHandler<IUserSettingPassword> = (data) => {
        props.onUpdatePassword(data);
    };

    return (
        <div>
            <Form onSubmit={onSubmit}>
                {({ errors, register, formState, reset, watch }) => {
                    return (
                        <Grid2 container spacing={2}>
                            <Grid2 size={12}>
                                <Stack spacing={0.5}>
                                    <Typography variant='h3'>Password</Typography>
                                    <Typography variant='body2' sx={{ color: theme.palette.grey[600] }}>
                                        Update or reset your password.
                                    </Typography>
                                </Stack>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6 }}>
                                <FormField label='Current Password' htmlFor='currentPassword'>
                                    <TextField
                                        id='currentPassword'
                                        type='password'
                                        autoComplete='current-password'
                                        size='small'
                                        fullWidth={true}
                                        {...register('current_password', {
                                            required: {
                                                value: true,
                                                message: 'Required',
                                            },
                                        })}
                                        error={!!errors.current_password}
                                        helperText={errors.current_password?.message}
                                    />
                                </FormField>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6 }}>
                                <FormField label='New Password' htmlFor='newPassword'>
                                    <TextField
                                        id='newPassword'
                                        type='password'
                                        autoComplete='new-password'
                                        size='small'
                                        fullWidth={true}
                                        onFocus={(e) => {
                                            setAnchorEl(e.currentTarget);
                                        }}
                                        {...register('new_password', {
                                            required: {
                                                value: true,
                                                message: 'Required',
                                            },
                                            validate: (password: string) => {
                                                if (password.includes(keycloak.tokenParsed?.email)) {
                                                    return 'Password can not contain your email';
                                                }
                                            },
                                            onChange: (e) => setPasswordToCheck(e.currentTarget.value),
                                            onBlur: () => {
                                                setAnchorEl(null);
                                            },
                                        })}
                                        error={!!errors.new_password || (!isPasswordStrong && passwordToCheck !== '')}
                                        helperText={
                                            errors.new_password?.message ??
                                            (!isPasswordStrong && passwordToCheck !== '' && 'Password does not meet requirements')
                                        }
                                    />
                                </FormField>
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6 }}>
                                <FormField label='Confirm New Password' htmlFor='confirmPassword'>
                                    <TextField
                                        id='confirmPassword'
                                        type='password'
                                        autoComplete='confirm-password'
                                        size='small'
                                        fullWidth={true}
                                        {...register('confirm_password', {
                                            required: {
                                                value: true,
                                                message: 'Required',
                                            },
                                            validate: (value: string) => {
                                                const newPassword = watch('new_password');
                                                if (value !== newPassword) return 'Password mismatched';
                                            },
                                        })}
                                        error={!!errors.confirm_password}
                                        helperText={errors.confirm_password?.message}
                                    />
                                </FormField>
                            </Grid2>
                            <Grid2 size={12}>
                                <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                                    {/*<Button variant='text' type='button'>*/}
                                    {/*    Forgot Password?*/}
                                    {/*</Button>*/}
                                    <Stack spacing={1} direction={'row'} justifyContent={'end'}>
                                        <Button
                                            variant={'cancel'}
                                            onClick={() => {
                                                setPasswordToCheck('');
                                                reset({
                                                    // TODO: Pass in initial values
                                                    new_password: '',
                                                    current_password: '',
                                                    confirm_password: '',
                                                });
                                            }}
                                            disabled={!formState.isDirty}
                                            disableElevation
                                        >
                                            Cancel
                                        </Button>
                                        <Button variant='contained' disabled={!formState.isDirty} type='submit' disableElevation>
                                            Save
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Grid2>
                        </Grid2>
                    );
                }}
            </Form>
            <Popper open={Boolean(anchorEl)} placement={'bottom-start'} anchorEl={anchorEl}>
                <Card elevation={3}>
                    <PasswordStrengthIndicator password={passwordToCheck} setIsPasswordStrong={setIsPasswordStrong} />
                </Card>
            </Popper>
        </div>
    );
};

export { PasswordSettings };
