import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button } from '@mui/material';
import { IosShare } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { IsDashboardShareReady } from '../../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IReportShareButtonProps {}

const ShareButton: React.FC<IReportShareButtonProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const isShareButtonReady = useRecoilValue(IsDashboardShareReady);

    return (
        <Button disabled={!isShareButtonReady} id={'share-button'} endIcon={<IosShare />}>
            Share
        </Button>
    );
};

const useStyles = customMakeStyles<IReportShareButtonProps>()((theme, props) => ({}));

export { ShareButton };
