import React, { ReactNode, useEffect } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Grid, IconButton, InputAdornment, MenuItem, Select, Skeleton, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { ContentCopy, Info } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IOrgData, IUser } from '@vegaplatformui/models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormField } from '../../../forms';
import { OrganizationId, SnackBarOptions } from '../../../recoil/atom';
import { FormFieldLoadingSkeleton } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationDetailsInputProps {
    allUsers: IUser[];
    orgName: string;
    orgOwner?: IUser;
    orgSlug?: string;
    onOrgDataUpdate: (data: IOrgData) => void;
    orgUpdateStatus: string;
}

const orgDataValidationSchema: yup.ObjectSchema<IOrgData> = yup.object().shape({
    organizationName: yup.string().required('Organization Name is required.'),
    organizationOwner: yup.string(),
});

const OrganizationDetailsInput: React.FC<IOrganizationDetailsInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { orgName, orgOwner, orgSlug, onOrgDataUpdate, allUsers, orgUpdateStatus } = props;
    const flags = useFlags();
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const organizationId = useRecoilValue(OrganizationId);

    const handleCopy = (fieldCopied: string, fieldToCopy: string) => {
        navigator.clipboard
            .writeText(fieldToCopy)
            .then(
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'success' },
                        message: `${fieldCopied} copied to clipboard`,
                    });
                },
                () => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 3000 },
                        alertProps: { severity: 'error' },
                        message: `Failed to copy ${fieldCopied} to clipboard`,
                    });
                }
            )
            .catch(() => {
                alert(`Failed to copy ${fieldCopied} to clipboard`);
            });
    };

    const {
        handleSubmit: orgDataHandleSubmit,
        control: orgDataControl,
        reset: orgDataReset,
        formState: { isDirty: isOrgDataDirty, errors: orgDataErrors },
    } = useForm<any>({
        resolver: yupResolver(orgDataValidationSchema),
        defaultValues: {
            organizationName: orgName,
            organizationOwner: orgOwner?.id || '',
        },
    });

    useEffect(() => {
        orgDataReset({
            organizationName: orgName,
            organizationOwner: orgOwner?.id || '',
        });
    }, [orgName]);

    const onOrgDataFormSubmit: SubmitHandler<IOrgData> = (data) => {
        onOrgDataUpdate(data);
    };

    return (
        <Grid
            container
            component={'form'}
            id={'organization-data-form'}
            onSubmit={orgDataHandleSubmit((data: any) => onOrgDataFormSubmit(data))}
            spacing={2}
        >
            {/* Organization ID (Readonly) */}
            {organizationId ? (
                <Grid item xs={12}>
                    <FormField
                        label={
                            <Stack width={'100%'} alignItems='flex-start' spacing={0.5} direction={'row'}>
                                <Typography color={'inherit'} fontSize={'inherit'} fontWeight={'inherit'}>
                                    Organization ID
                                </Typography>
                                <Tooltip title={`Your organization's Unique Identifier used by Vega.`} placement={'top'} arrow>
                                    <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                                </Tooltip>
                            </Stack>
                        }
                        htmlFor='orgId'
                    >
                        <TextField
                            fullWidth
                            id='orgId'
                            value={organizationId}
                            size={'small'}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            disabled={!organizationId}
                                            aria-label='copy organization id'
                                            onClick={() => handleCopy('Organization ID', organizationId)}
                                        >
                                            <ContentCopy />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormField>
                </Grid>
            ) : (
                <FormFieldLoadingSkeleton
                    formField={
                        <Stack width={'100%'} alignItems='flex-start' spacing={0.5} direction={'row'}>
                            <Typography color={'inherit'} fontSize={'inherit'} fontWeight={'inherit'}>
                                Organization ID
                            </Typography>
                            <Tooltip title={`Your organization's Unique Identifier used by Vega.`} placement={'top'} arrow>
                                <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                            </Tooltip>
                        </Stack>
                    }
                    htmlFor={'orgId'}
                />
            )}
            {/* Organization Slug (Readonly) */}
            {orgSlug ? (
                <Grid item xs={12}>
                    <FormField
                        label={
                            <Stack width={'100%'} alignItems='flex-start' spacing={0.5} direction={'row'}>
                                <Typography color={'inherit'} fontSize={'inherit'} fontWeight={'inherit'}>
                                    Organization Slug
                                </Typography>
                                <Tooltip title={`Your organization's slug used by Vega.`} placement={'top'} arrow>
                                    <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                                </Tooltip>
                            </Stack>
                        }
                        htmlFor='orgSlug'
                    >
                        <TextField
                            fullWidth={true}
                            id='orgSlug'
                            value={orgSlug}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            disabled={!orgSlug}
                                            aria-label='copy organization slug'
                                            onClick={() => orgSlug && handleCopy('Organization Slug', orgSlug)}
                                        >
                                            <ContentCopy />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            size={'small'}
                        />
                    </FormField>
                </Grid>
            ) : (
                <FormFieldLoadingSkeleton
                    formField={
                        <Stack width={'100%'} alignItems='flex-start' spacing={0.5} direction={'row'}>
                            <Typography color={'inherit'} fontSize={'inherit'} fontWeight={'inherit'}>
                                Organization Slug
                            </Typography>
                            <Tooltip title={`Your organization's slug used by Vega.`} placement={'top'} arrow>
                                <Info tabIndex={0} aria-hidden={'false'} className={commonStyles.classes.TooltipIcons} />
                            </Tooltip>
                        </Stack>
                    }
                    htmlFor={'orgSlug'}
                />
            )}
            {/* Organziation Name */}
            {orgName ? (
                <Grid item xs={12}>
                    <FormField label={'Organization Name'} htmlFor='organizationName'>
                        <Controller
                            name='organizationName'
                            control={orgDataControl}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id={'organizationName'}
                                    onChange={onChange}
                                    size='small'
                                    fullWidth={true}
                                    value={value}
                                    error={!!orgDataErrors.organizationName}
                                />
                            )}
                        ></Controller>
                    </FormField>
                </Grid>
            ) : (
                <FormFieldLoadingSkeleton formField={'Organization Name'} htmlFor={'organizationName'} />
            )}
            {/* Organization Owner */}
            {flags.isVegaAdmin && false && (
                <Grid item xs={12}>
                    <FormField label={'Organization Owner'} htmlFor='organizationOwner'>
                        <Controller
                            name={'organizationOwner'}
                            control={orgDataControl}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id={'organizationOwner'}
                                    name={'organizationOwner'}
                                    size={'small'}
                                    fullWidth={true}
                                    value={value}
                                    onChange={(evt: any, owner: any) => {
                                        onChange(evt.target.value);
                                    }}
                                >
                                    {allUsers.map((user: IUser) => {
                                        return (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.given_name} {user.family_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                        ></Controller>
                    </FormField>
                </Grid>
            )}
            {/* Form Handler Buttons */}
            <Grid item xs={12}>
                <Stack className={cx(classes.FormButtonStack)} direction='row' spacing={2} justifyContent={'flex-start'}>
                    <Button
                        form={'organization-data-form'}
                        variant='contained'
                        type='submit'
                        disabled={!isOrgDataDirty || orgUpdateStatus === 'pending'}
                    >
                        Save
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<IOrganizationDetailsInputProps>()((theme, props) => ({
    FormButtonStack: { marginRight: '1rem' },
}));

export { OrganizationDetailsInput };
