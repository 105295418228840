import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Divider, Grid } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISettingsV2DividerProps {}

const SettingsV2Divider: React.FC<ISettingsV2DividerProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid item xs={12}>
            <Divider />
        </Grid>
    );
};

const useStyles = customMakeStyles<ISettingsV2DividerProps>()((theme, props) => ({}));

export { SettingsV2Divider };
