export interface INewClient {
    client_name: string;
    expiration_duration: string;
}

export enum ClientStatus {
    Expired = 'Expired',
    Active = 'Active',
}

export interface IClient {
    client_name: string;
    client_id: string;
    client_secret: string;
    client_secret_hidden: string;
    created_at: string;
    expiration_date: string;
    expiration_duration: string;
    id: number;
    keycloak_id: string;
    organization_id: string;
    updated_at: string;
    user_id: string;
    deleted_from_idp_client: boolean;
}

export interface INewClientRegistrationInformation {
    client_id: string;
    client_secret: string;
}

export interface INewClientRegistrationResponse {
    $schema: string;
    client: IClient;
}

export interface IGetClientRegistrationsResponse {
    $schema: string;
    clients: IClient[];
}
