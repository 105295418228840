import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { LinearProgress, Stack, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinearIndeterminateLoadingProps {}

const LinearIndeterminateLoading: React.FC<ILinearIndeterminateLoadingProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Stack width={'100%'} height={'100vh'} direction='column' justifyContent='center' alignItems='center'>
            <LinearProgress aria-labelledby={'loading-title'} className={cx(classes.LoadingItem)} />
            <Typography id={'loading-title'} variant='h5'>
                Loading the Vega Platform
            </Typography>
        </Stack>
    );
};

const useStyles = customMakeStyles<ILinearIndeterminateLoadingProps>()((theme, props) => ({
    VCow: { width: '100%' },
    ContentGrid: {},
    LoadingGrid: { width: '100%' },
    LoadingItem: { height: '1.5rem', width: '15rem', borderRadius: '8px' },
    ProgressText: { color: theme.palette.text.secondary },
}));

export { LinearIndeterminateLoading };
