import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Stack, Tab, tabClasses, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    NotificationCommunicationSettings,
    NotificationHistory,
    NotificationPreferences,
    NotificationScheduleReports,
    useNotificationCommunicationSettingsApi,
    useNotificationPreferencesApi,
} from '@vegaplatformui/sharedcomponents';
import { ITab, NotificationTabsType } from '@vegaplatformui/models';
import { useRouteUrls } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationsControllerProps {
    parentTab: string;
    notificationTab: string;
    setNotificationTab: React.Dispatch<React.SetStateAction<string>>;
    notificationTabs: ITab[];
}

const NotificationSettingsController: React.FC<INotificationsControllerProps> = (props) => {
    const { notificationTab, setNotificationTab, parentTab, notificationTabs } = props;
    const { classes, cx } = useStyles(props);
    const navigate = useNavigate();
    const { routeUrls } = useRouteUrls({});
    const { defaultCommunicationItems, isDefaultCommunicationItemsLoading, isPhoneNumberSet } = useNotificationCommunicationSettingsApi({});
    const {
        updateChannelPreference,
        updateNotificationStatus,
        isVegaNotificationsLoading,
        vegaNotifications,
        notificationChannels,
        loadingStates,
        setLoading,
        isInitialNotificationsLoading,
    } = useNotificationPreferencesApi({});

    const onTabChange = (event: React.ChangeEvent<object>, newValue: string) => {
        setNotificationTab(newValue);
        navigate(`/${routeUrls.settings.route.path}/${parentTab}/${newValue}`);
    };

    return (
        <Stack>
            {notificationTabs.length > 1 && (
                <Tabs
                    className={cx(classes.TabsContainerStyle)}
                    aria-label={'notification settings tabs'}
                    variant='scrollable'
                    TabIndicatorProps={{
                        //Put the indicator for selection at the top of the tab
                        style: { top: 0 },
                    }}
                    value={notificationTab}
                    onChange={onTabChange}
                >
                    {notificationTabs &&
                        notificationTabs.map((currentTab) => (
                            <Tab
                                className={cx(classes.TabStyle)}
                                id={currentTab.id}
                                aria-controls={`${currentTab.id}-panel`}
                                key={currentTab.id}
                                label={currentTab.label}
                                aria-label={currentTab.label}
                                value={currentTab.id}
                            />
                        ))}
                </Tabs>
            )}
            <Box
                component={'div'}
                role={'tabpanel'}
                aria-describedby={notificationTab}
                id={`${notificationTab}-panel`}
                className={cx(classes.Tabpanel)}
            >
                {(() => {
                    switch (notificationTab) {
                        case NotificationTabsType.Notifications:
                            return (
                                <NotificationPreferences
                                    setLoading={setLoading}
                                    loadingStates={loadingStates}
                                    notifications={vegaNotifications}
                                    notificationChannels={notificationChannels}
                                    isInitialNotificationsLoading={isInitialNotificationsLoading}
                                    isDefaultCommunicationItemsLoading={isDefaultCommunicationItemsLoading}
                                    isPhoneNumberSet={isPhoneNumberSet}
                                    updateChannelPreference={updateChannelPreference}
                                    updateNotificationStatus={updateNotificationStatus}
                                />
                            );
                        case NotificationTabsType.ScheduleReports:
                            return (
                                <NotificationScheduleReports
                                    reports={[
                                        { id: '123', name: 'Report 1', enabled: false },
                                        { id: '321', name: 'Report 2', enabled: true },
                                    ]}
                                    isDefaultCommunicationItemsLoading={isDefaultCommunicationItemsLoading}
                                    isPhoneNumberSet={isPhoneNumberSet}
                                />
                            );
                        case NotificationTabsType.CommunicationSettings:
                            return (
                                <NotificationCommunicationSettings
                                    defaultCommunicationSettings={defaultCommunicationItems}
                                    isDefaultCommunicationItemsLoading={isDefaultCommunicationItemsLoading}
                                    communicationSettings={[]}
                                />
                            );
                        case NotificationTabsType.History:
                            return <NotificationHistory />;
                        default:
                            return <></>;
                    }
                })()}
            </Box>
        </Stack>
    );
};

const useStyles = customMakeStyles<INotificationsControllerProps>()((theme, props) => ({
    TabsContainerStyle: {
        borderBottom: `2px solid ${theme.palette.grey[100]}`,
    },
    //Started this style from https://mui-treasury.com/?path=/story/tabs-chrome--chrome
    TabStyle: {
        opacity: 1,
        overflow: 'initial',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        transition: '0.2s',
        zIndex: 2,
        textTransform: 'initial',
        [theme.breakpoints.up('md')]: {
            minWidth: 140,
        },
        '&:before': {
            transition: '0.2s',
        },
        '&:not(:first-of-type)': {
            '&:before': {
                content: '" "',
                position: 'absolute',
                left: 0,
                display: 'block',
                zIndex: 1,
                marginTop: theme.spacing(0.5),
            },
        },
        [`& + .${tabClasses.selected}::before`]: {
            opacity: 0,
        },
        '&:hover': {
            '&::before': {
                opacity: 0,
            },
            [`& + .${tabClasses.root}::before`]: {
                opacity: 0,
            },
        },
        [`&.${tabClasses.selected}`]: {
            color: theme.palette.primary.main,
            boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
        },
        [`&:not(.${tabClasses.selected})`]: {
            backgroundColor: theme.palette.grey[50],
        },
        [`&.${tabClasses.selected} + .${tabClasses.root}`]: {
            zIndex: 1,
        },
        [`&.${tabClasses.selected} + .${tabClasses.root}::before`]: {
            opacity: 0,
        },
    },
    Tabpanel: {
        paddingRight: theme.spacing(2),
    },
}));

export { NotificationSettingsController };
