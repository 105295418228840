import { Box, Button, Chip, Grid, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Add, Delete, Edit, Workspaces } from '@mui/icons-material';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { GridActionsCellItem, GridPagination, GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import React, { useEffect, useRef, useState } from 'react';
import { UserFormDialog } from './user-form-dialog';
import { UserDeleteDialog } from './user-delete-dialog';
import {
    IBusinessGrouping,
    IBusinessGroupingType,
    IUserSettingRealmRole,
    IUser,
    IPutUserV2HookRequest,
    IPostUserV2Request,
    ISubmitUserV2,
} from '@vegaplatformui/models';
import { DataGridCustomToolbar } from '../../utilities/datagrid-custom-toolbar';
import { useKeycloak } from '@react-keycloak-fork/web';
import { DataGridCellTooltip } from '../../utilities/datagrid-cell-tooltip';
import { LoadingButton } from '@mui/lab';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { CustomGridColSingleSelectOperatorDef, CustomGridColStringOperatorDef } from '../../utilities/custom-grid-col-string-operator-def';
import { CustomDatagridColumnMenu } from '../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { UsersBusinessGroupingsDrawer } from './users-business-groupings-drawer';
import useBusinessGroupingsApi from '../../api-hooks/business-groupings-hooks/use-business-groupings-api';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../utilities/custom-data-grid-render-cell-button';
import { tableIdentifierKeys } from '../../use-table-utilities/table-identifier-keys';
import { selectNewMenuItem } from '@vegaplatformui/utils';
import { useMenuItems } from '@vegaplatformui/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MenuItems, ShowUpdatedUi } from '../../recoil/atom';
import dayjs from 'dayjs';
import { SettingsContentHeader } from '../settingsV2/settings-content-header';
import { SettingsV2Divider } from '../settingsV2/settings-v2-divider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUsersSettingsProps {
    users: IUser[];
    roles: IUserSettingRealmRole[];
    isLoading: boolean;
    onUpdateUser: (data: IPutUserV2HookRequest) => void;
    onDeleteUsers: (data: IUser[]) => void;
    onInviteUser: (data: IPostUserV2Request) => void;
    isCreatingUser: boolean;
    businessGroupingTypes: IBusinessGroupingType[];
}

const UsersSettings: React.FC<IUsersSettingsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const userSettingsTableUtilities = useTableUtilities(tableIdentifierKeys.userSettingsTable);
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
    const { keycloak } = useKeycloak();
    const [isUserBusinessGroupingsDetailOpen, setIsUserBusinessGroupingsDetailOpen] = useState<boolean>(false);
    const businessGroupingApi = useBusinessGroupingsApi({});
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);
    const { menuItems } = useMenuItems({});
    const setSidebarMenuItems = useSetRecoilState(MenuItems);
    const showUpdatedUi = useRecoilValue(ShowUpdatedUi);

    React.useEffect(() => {
        userSettingsTableUtilities.registerTableControl();
    }, []);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'User',
            flex: 2,
            valueGetter: (value: string, row: IUser) => {
                return `${row.given_name} ${row.family_name}`;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'email',
            headerName: 'Email',
            flex: 2,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'platform_roles',
            headerName: 'Role',
            flex: 3,
            valueGetter: (value: string[], row: IUser) => (row.platform_roles !== null ? row.platform_roles?.join(', ') : row.platform_roles),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'business_groupings',
            headerName: 'Business Groupings',
            flex: 2,
            valueGetter: (value: IBusinessGrouping, row: IUser) =>
                row.business_groupings !== null
                    ? row.business_groupings.map((bg: IBusinessGrouping) => bg.display_name).join(', ')
                    : row.business_groupings,
            renderCell: (params: GridRenderCellParams<IUser>) => {
                if (params.value !== null) {
                    return (
                        <StyledToolTip
                            title={
                                <div style={{ whiteSpace: 'pre-line' }}>
                                    {params.row.business_groupings.length > 0 ? 'Click to view business groupings' : 'User has no business groupings'}
                                </div>
                            }
                        >
                            <span>
                                <CustomDataGridRenderCellButton
                                    ref={ref}
                                    iconButtonProps={{
                                        tabIndex: params.tabIndex,
                                        onClick: () => {
                                            setUserToEdit(params.row);
                                            setIsUserBusinessGroupingsDetailOpen(true);
                                        },
                                        'aria-label':
                                            params.row.business_groupings.length > 0
                                                ? 'Click to view business groupings'
                                                : 'User has no business groupings',
                                        disabled: params.row.business_groupings.length < 1,
                                        children: (
                                            <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
                                                <Workspaces sx={{ marginRight: '.25rem' }} />
                                                <Typography
                                                    variant='body2'
                                                    color={params.row.business_groupings.length > 0 ? theme.palette.text.primary : 'inherit'}
                                                >
                                                    {params.row.business_groupings.length}
                                                </Typography>
                                            </Stack>
                                        ),
                                    }}
                                    params={params}
                                />
                            </span>
                        </StyledToolTip>
                    );
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'created_at',
            headerName: 'Date Added',
            flex: 1.5,
            valueFormatter: (value: string, row: IUser) => {
                if (!value) {
                    return '';
                }
                return dayjs(value).format('L');
            },
        },
        // {
        //     ...CustomGridColStringOperatorDef,
        //     field: 'updated_at',
        //     headerName: 'Last Updated',
        //     flex: 2,
        //     valueFormatter: (value: string, row: IUser) => {
        //         console.log(
        //             value !== null
        //                 ? Intl.DateTimeFormat('en-US', {
        //                       month: '2-digit',
        //                       day: '2-digit',
        //                       year: 'numeric',
        //                   }).format(Date.parse(value))
        //                 : value
        //         );
        //         return value !== null
        //             ? Intl.DateTimeFormat('en-US', {
        //                   month: '2-digit',
        //                   day: '2-digit',
        //                   year: 'numeric',
        //               }).format(Date.parse(value))
        //             : value;
        //     },
        // },
        //     UsersBox: { display: 'flex', alignItems: 'center' },
        //     PeopleIcon: { fill: theme.palette.grey[300], marginRight: '.25rem' },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'emailVerified',
            headerName: 'Status',
            flex: 1,
            renderCell: (params: GridRenderCellParams<IUser>) => (
                <span>
                    <Chip
                        sx={{ backgroundColor: `${params.value ? theme.palette.success.light : theme.palette.warning.light}` }}
                        size={'small'}
                        label={<Typography variant={'caption'}>{`${params.value ? 'Active' : 'Invited'}`}</Typography>}
                    />
                </span>
            ),
            type: 'singleSelect',
            valueOptions: [
                { label: 'Active', value: true },
                { label: 'Invited', value: false },
            ],
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'actions',
            type: 'actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 1,
            align: 'center',
            headerName: 'Actions',
            getActions: (params: GridRowParams<IUser>) => [
                <StyledToolTip arrow title={selectedUsers.length > 1 ? 'Unselect users to edit.' : 'Edit'}>
                    <GridActionsCellItem
                        icon={<Edit />}
                        label={'Edit'}
                        aria-label='edit user'
                        onClick={() => {
                            setIsFormOpen(true);
                            setUserToEdit(params.row);
                            businessGroupingApi.setUserId(params.row.id);
                        }}
                    />
                </StyledToolTip>,
                <StyledToolTip
                    arrow
                    title={params.row.email === (keycloak.tokenParsed && keycloak.tokenParsed.email) ? 'You can not delete your own user.' : 'Delete'}
                >
                    {params.row.email === (keycloak.tokenParsed && keycloak.tokenParsed.email) ? (
                        <span>
                            <GridActionsCellItem
                                icon={<Delete />}
                                aria-label='delete user'
                                label={'Delete'}
                                disabled={params.row.email === (keycloak.tokenParsed && keycloak.tokenParsed.email)}
                                onClick={() => {
                                    setIsDeleteOpen(true);
                                    setUsersToDelete([params.row]);
                                }}
                            />
                        </span>
                    ) : (
                        <GridActionsCellItem
                            icon={<Delete />}
                            aria-label='delete user'
                            label={'Delete'}
                            onClick={() => {
                                setIsDeleteOpen(true);
                                setUsersToDelete([params.row]);
                            }}
                        />
                    )}
                </StyledToolTip>,
            ],
        },
    ];

    columns.map((column) => {
        if (!column.renderCell && column.field !== 'actions') column.renderCell = DataGridCellTooltip;
        return column;
    });

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== 'actions' && column.field !== '__check__').map((column) => column.field);
    };

    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        setSelectedUsers(gridSelectionModel.map((id) => props.users?.find((user) => user.id === id) as IUser));
    };

    const [isFormOpen, setIsFormOpen] = useState(false);
    const [userToEdit, setUserToEdit] = useState<IUser>();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [usersToDelete, setUsersToDelete] = useState<IUser[]>([]);

    useEffect(() => {
        setSelectionModel([...new Set([...selectionModel, ...(usersToDelete.map((user) => user.id) ?? [])])]);
    }, [usersToDelete]);

    const closeUserFormDialog = () => {
        setIsFormOpen(false);
        setUserToEdit(undefined);
        businessGroupingApi.setUserId(undefined);
    };
    const closeDeleteDialog = () => {
        setIsDeleteOpen(false);
        setUsersToDelete([]);
    };
    const inviteUser = (data: ISubmitUserV2) => {
        closeUserFormDialog();
        props.onInviteUser({
            email: data.email,
            business_group_ids: data.business_groupings?.map((group) => group.id),
            family_name: data.family_name,
            given_name: data.given_name,
            platform_roles: data.platform_roles,
        });
    };
    const updateUser = (data: ISubmitUserV2, user_id: string) => {
        closeUserFormDialog();
        props.onUpdateUser({
            request: {
                given_name: data.given_name,
                family_name: data.family_name,
                platform_roles: data.platform_roles,
                business_groupings: data.business_groupings,
            },
            user_id: user_id,
        });
    };
    const deleteUsers = (data: IUser[]) => {
        closeDeleteDialog();
        props.onDeleteUsers(data);
    };

    return (
        <>
            <Grid container direction='column'>
                {showUpdatedUi ? (
                    <Grid item xs={12} container direction={'row'} justifyContent={'space-between'} spacing={2}>
                        <SettingsContentHeader
                            title={'Users'}
                            subtitle={'Invite and manage users.'}
                            children={
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    {selectedUsers.length > 1 && (
                                        <Button
                                            startIcon={<Delete />}
                                            className={commonStyles.classes.MultipleDeleteButton}
                                            variant={'contained'}
                                            onClick={() => {
                                                setUsersToDelete(selectedUsers);
                                                setIsDeleteOpen(true);
                                            }}
                                            aria-label={'Delete Selected Users'}
                                        >
                                            Delete Selected Users
                                        </Button>
                                    )}
                                    <LoadingButton
                                        loading={props.isCreatingUser}
                                        loadingPosition={'start'}
                                        variant={'contained'}
                                        startIcon={props.isCreatingUser ? <Icon></Icon> : <></>}
                                        onClick={() => setIsFormOpen(true)}
                                        aria-label={'Invite User'}
                                    >
                                        Invite User
                                    </LoadingButton>
                                </Stack>
                            }
                        />
                        <SettingsV2Divider />
                    </Grid>
                ) : (
                    <Grid item xs={12} container direction={'row'} justifyContent={'space-between'}>
                        <Grid xs={6} item>
                            <Stack spacing={0.5} marginBottom={1}>
                                <Typography variant='h3'>Users</Typography>
                                <Typography variant='body2' color={theme.palette.grey[600]}>
                                    Invite and manage users.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid xs={6} item container justifyContent={'flex-end'}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                {selectedUsers.length > 1 && (
                                    <Button
                                        startIcon={<Delete />}
                                        className={commonStyles.classes.MultipleDeleteButton}
                                        variant={'contained'}
                                        onClick={() => {
                                            setUsersToDelete(selectedUsers);
                                            setIsDeleteOpen(true);
                                        }}
                                        aria-label={'Delete Selected Users'}
                                    >
                                        Delete Selected Users
                                    </Button>
                                )}
                                <LoadingButton
                                    loading={props.isCreatingUser}
                                    loadingPosition={'start'}
                                    variant={'contained'}
                                    startIcon={props.isCreatingUser ? <Icon></Icon> : <></>}
                                    onClick={() => setIsFormOpen(true)}
                                    aria-label={'Invite User'}
                                >
                                    Invite User
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Box>
                <DataGridPremium
                    isRowSelectable={(params: GridRowParams<IUser>) => params.row.email !== (keycloak.tokenParsed && keycloak.tokenParsed.email)}
                    loading={props.isLoading}
                    autoHeight={true}
                    columns={columns}
                    rows={props.users}
                    filterMode={'client'}
                    paginationMode={'client'}
                    sortingMode={'client'}
                    rowSelectionModel={selectionModel}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                    checkboxSelectionVisibleOnly
                    slots={{
                        toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                        columnMenu: CustomDatagridColumnMenu,
                        pagination: GridPagination,
                    }}
                    slotProps={{
                        pagination: {
                            showLastButton: true,
                            showFirstButton: true,
                            slotProps: { actions: { firstButton: { color: 'inherit' }, lastButton: { color: 'inherit' } } },
                        },
                        toolbar: {
                            tableIdentifier: tableIdentifierKeys.userSettingsTable,
                        },
                        columnsManagement: {
                            getTogglableColumns,
                        },
                        noRowsOverlay: {
                            action: 'inviting a user',
                        },
                    }}
                    pagination
                    density={userSettingsTableUtilities.currentTableControl?.density}
                    onDensityChange={userSettingsTableUtilities.onDensityChange}
                    sortModel={userSettingsTableUtilities.currentTableControl?.sortModel}
                    onSortModelChange={userSettingsTableUtilities.onSortModelChange}
                    filterModel={userSettingsTableUtilities.currentTableControl?.filterModel}
                    onFilterModelChange={userSettingsTableUtilities.onFilterModelChange}
                    paginationModel={userSettingsTableUtilities.currentTableControl?.paginationModel}
                    onPaginationModelChange={userSettingsTableUtilities.onPaginationModelChange}
                    columnVisibilityModel={userSettingsTableUtilities.currentTableControl?.columnModel}
                    onColumnVisibilityModelChange={userSettingsTableUtilities.onColumnVisibilityModelChange}
                    onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                />
            </Box>

            {/* Dialogs */}
            <UserFormDialog
                businessGroupingTypes={props.businessGroupingTypes}
                usersBusinessGroupings={businessGroupingApi.myBusinessUnitTree ?? []}
                ownedBusinessGroupingsByUser={businessGroupingApi.ownedBusinessGroupingsByUser}
                isOpen={isFormOpen}
                user={userToEdit}
                users={props.users}
                roles={props.roles}
                onClose={closeUserFormDialog}
                onUpdate={updateUser}
                onInvite={inviteUser}
            />
            <UserDeleteDialog isOpen={isDeleteOpen} users={usersToDelete} onClose={closeDeleteDialog} onDelete={deleteUsers} />
            <UsersBusinessGroupingsDrawer
                user={userToEdit}
                onBackDrawer={() => {
                    setIsUserBusinessGroupingsDetailOpen(false);
                    setUserToEdit(undefined);
                }}
                isDrawerOpen={isUserBusinessGroupingsDetailOpen}
                menuItems={menuItems}
                selectMenuItem={selectNewMenuItem}
                setSidebarMenuItems={setSidebarMenuItems}
            />
        </>
    );
};

const useStyles = customMakeStyles<IUsersSettingsProps>()((theme, props) => ({
    ToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    ToolBarFilter: {
        color: theme.palette.grey[500],
        marginBottom: '1rem',
    },
}));

export { UsersSettings };
