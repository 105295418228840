import { useKeycloak } from '@react-keycloak-fork/web';
import { useQuery } from '@tanstack/react-query';
import { ITimezone } from '@vegaplatformui/models';
import { useMemo } from 'react';
import { TimezonesApi } from '@vegaplatformui/apis';
import { queryKeys } from './query-keys';

export interface IUseTimezonesApiHook {
    timezones: ITimezone[];
}
export interface IUseTimezonesApiProps {}

//this is a hook
export function useTimezonesApi(props: IUseTimezonesApiProps): IUseTimezonesApiHook {
    //created so we can use it in the tiemzone api 'memo'
    const { keycloak } = useKeycloak();

    //useMemo is usd to monitor the 'keycloak' variable declared above and wil execute the anonymous function
    //when that happens
    const timezonesApi = useMemo(() => {
        const apiInstance = new TimezonesApi();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const { data: timezones } = useQuery({
        queryKey: [queryKeys.timezonesApi.getTimezones],
        queryFn: async () => {
            const response = await timezonesApi.getTimezones();
            //turn the response into the ITimezone type
            const timezones = response.data.map((timezone) => ({
                id: timezone.id,
                tzCode: timezone.tz_code,
                label: timezone.label,
            }));
            //set the response in localStorage for offline/initial access
            localStorage.setItem('timezones', JSON.stringify(timezones));
            return timezones;
        },
        // Add staleTime to reduce unnecessary refetches
        staleTime: 1000 * 60 * 60, // 1 hour
        enabled: keycloak.token !== undefined,
        meta: {
            errorMessage: 'Unable to get timezones list',
        },
    });

    return { timezones: timezones ?? [] };
}
