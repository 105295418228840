import React, { useEffect, useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { fieldToStr, regexList } from '@vegaplatformui/utils';
import {
    IBusinessGroupingNode,
    IBusinessUnitTreeItem,
    IPutUserV2BusinessGroupings,
    ISubmitUserV2,
    IUser,
    IUserSettingRealmRole,
} from '@vegaplatformui/models';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { Button, DialogActions, DialogContent, DialogTitle, Drawer, Grid, IconButton, Stack, TextField } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { BusinessGroupingPicker } from '../../../../business-grouping-picker/business-grouping-picker';
import { PlatformRoleListItem } from './platform-role-list-item';
import { Close } from '@mui/icons-material';
import { FormField } from '../../../../forms/form-field';
import { BlackOnWhiteButton } from '../../../../utilities/black-on-white-button';

const userFormFields = ['given_name', 'family_name', 'email'] as const;
type UserFormFieldUnion = (typeof userFormFields)[number];

const regex = {
    ...regexList,
    given_name: regexList.alphanumeric_contains_space_and_hyphen,
    family_name: regexList.alphanumeric_contains_space_and_hyphen,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserFormDrawerV2Props {
    user?: IUser;
    users: IUser[];
    roles: IUserSettingRealmRole[];
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (data: ISubmitUserV2, user_id: string) => void;
    onInvite: (data: ISubmitUserV2) => void;
    usersBusinessGroupings: IBusinessUnitTreeItem[];
    ownedBusinessGroupingsByUser: string[];
}

const validationSchema: yup.ObjectSchema<ISubmitUserV2> = yup.object().shape({
    family_name: yup
        .string()
        .required('Required')
        .matches(regex.family_name, 'Must begin and end with an alphanumeric, and contains hyphens or spaces.'),
    given_name: yup
        .string()
        .required('Required')
        .matches(regex.given_name, 'Must begin and end with an alphanumeric, and contains hyphens or spaces.'),
    email: yup.string().required('Required').matches(regex.email, 'Invalid email address.'),
    business_groupings: yup.array<IPutUserV2BusinessGroupings[]>(),
    platform_roles: yup.array<string[]>().required(),
});

const UserFormDrawerV2: React.FC<IUserFormDrawerV2Props> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const { user, users, roles, isOpen, onClose, onInvite, onUpdate, usersBusinessGroupings, ownedBusinessGroupingsByUser } = props;
    const [selectedBusinessGroups, setSelectedBusinessGroups] = useState<string[] | undefined>(user?.business_groupings?.map((item) => item.id));
    const [businessGroupings, setBusinessGroupings] = useState<TreeNodeProps[]>(usersBusinessGroupings);
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        formState,
        register,
        formState: { errors, isSubmitSuccessful },
    } = useForm<ISubmitUserV2>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            family_name: user?.family_name ?? '',
            given_name: user?.given_name ?? '',
            email: user?.email ?? '',
            business_groupings:
                user && user.business_groupings
                    ? user.business_groupings.map((item) => {
                          return { id: item.id };
                      })
                    : [],
            platform_roles: user && user.platform_roles ? user.platform_roles : ['read_only'],
        },
    });

    useEffect(() => {
        if (user !== undefined) {
            reset(
                {
                    family_name: user?.family_name ?? '',
                    given_name: user?.given_name ?? '',
                    email: user?.email ?? '',
                    business_groupings:
                        user && user.business_groupings
                            ? user.business_groupings.map((item) => {
                                  return { id: item.id };
                              })
                            : [],
                    platform_roles: user && user.platform_roles ? user.platform_roles : ['read_only'],
                },
                { keepDirty: true, keepIsValid: true }
            );
        }
    }, [user]);

    useEffect(() => {
        if (user && user.business_groupings) {
            setSelectedBusinessGroups(user.business_groupings.map((item) => item.id));
        }
    }, [user]);

    const onSubmit: SubmitHandler<ISubmitUserV2> = (data: ISubmitUserV2) => {
        data.business_groupings = selectedBusinessGroups?.map((groupingId) => {
            const grouping: IPutUserV2BusinessGroupings = { id: groupingId };
            return grouping;
        });
        if (user) {
            onUpdate(data, user.id);
        } else {
            onInvite(data);
        }
    };

    const handleRoleSelect = (checked: boolean, selectedRole: string) => {
        if (!checked) {
            const newRoleList = control._formValues.platform_roles.filter((role: string) => role !== selectedRole);
            setValue('platform_roles', newRoleList);
        } else {
            const newRoleList = [...control._formValues.platform_roles, selectedRole];
            setValue('platform_roles', newRoleList);
        }
    };

    const handleBusinessGroupSelect = (businessGroupings: IBusinessGroupingNode[]) => {
        setSelectedBusinessGroups(businessGroupings.map((item) => item.id));
    };

    const onCloseAndResetState = () => {
        reset({});
        setSelectedBusinessGroups([]);
        onClose();
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({});
            setSelectedBusinessGroups([]);
        }
    }, [formState, reset, isSubmitSuccessful]);

    useEffect(() => {
        if (!selectedBusinessGroups) return;
        const searchAndUpdateSelectedBusinessGroups = (BGs: TreeNodeProps[], selectedBusinessGroups: string[]) => {
            return BGs.map((item): TreeNodeProps => {
                if (item.children) {
                    return {
                        ...item,
                        checked: selectedBusinessGroups.includes(item.value),
                        children: searchAndUpdateSelectedBusinessGroups(item.children, selectedBusinessGroups),
                        disabled: ownedBusinessGroupingsByUser.includes(item.value),
                    };
                }
                return { ...item, checked: selectedBusinessGroups.includes(item.value) };
            });
        };
        setBusinessGroupings(
            searchAndUpdateSelectedBusinessGroups(businessGroupings.length > 0 ? businessGroupings : usersBusinessGroupings, selectedBusinessGroups)
        );
    }, [usersBusinessGroupings, selectedBusinessGroups, ownedBusinessGroupingsByUser]);

    return (
        <Drawer
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={isOpen}
            onClose={() => onCloseAndResetState()}
            aria-labelledby='user-form-drawer-title'
            id={'user-form-drawer'}
        >
            <DialogTitle variant={'h6'} id='user-form-drawer-title'>
                <Stack direction={'column'} spacing={0}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <Stack direction={'row'} justifyContent='flex-start' alignItems='flex-start' spacing={1}>
                            {user ? 'Edit User' : 'Invite User'}
                        </Stack>
                        <IconButton
                            aria-label={'Close SSO Configuration Drawer'}
                            id={'sso-config-drawer-close'}
                            className={cx(classes.CloseButton)}
                            onClick={() => onCloseAndResetState()}
                        >
                            <Close className={commonStyles.classes.DarkIconColor} />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    id={'user-form-drawer-form'}
                    component={'form'}
                    onSubmit={handleSubmit((data) => {
                        onSubmit(data);
                    })}
                >
                    {userFormFields.map((field: UserFormFieldUnion) => (
                        <Grid item xs={12} sm={field === 'email' ? 12 : 6} key={`${field}`}>
                            <FormField
                                label={fieldToStr(field === 'family_name' ? 'last_name' : field === 'given_name' ? 'first_name' : field)}
                                htmlFor={field}
                            >
                                <Controller
                                    name={field}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id={field}
                                            size='small'
                                            fullWidth={true}
                                            disabled={user && field === 'email'}
                                            // inputProps={{ readOnly: field === 'email' && user }}
                                            {...register(field, {
                                                validate: (value: string) => {
                                                    if (field === 'email' && !user) {
                                                        const filtered = users.filter((x) => x.email === value);
                                                        if (filtered.length > 0) {
                                                            return 'That email address is already taken.';
                                                        }
                                                    }
                                                },
                                            })}
                                            value={value}
                                            onChange={onChange}
                                            error={!!errors[field]}
                                            helperText={
                                                (errors[field]?.message as string) ?? (field === 'email' && 'Cannot be edited after creation')
                                            }
                                        />
                                    )}
                                />
                            </FormField>
                        </Grid>
                    ))}
                    {user && (
                        <Grid item xs={12} sm={12}>
                            <BusinessGroupingPicker
                                data={businessGroupings}
                                mode='hierarchical'
                                changeHandler={handleBusinessGroupSelect}
                                setData={setBusinessGroupings}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormField label={'Platform Role'} id={'platform_roles'} htmlFor='platform_roles'>
                            <Controller
                                name={'platform_roles'}
                                control={control}
                                render={() => (
                                    <Stack className={cx(classes.PlatformRolesList)} component={'ol'} direction={'column'} spacing={1}>
                                        {roles.map((option) => (
                                            <PlatformRoleListItem
                                                handleRoleSelect={handleRoleSelect}
                                                key={option.name}
                                                role={option.name}
                                                description={option.description}
                                                isSelected={control._formValues.platform_roles.includes(option.name)}
                                            />
                                        ))}
                                    </Stack>
                                )}
                            />
                        </FormField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Stack height={'100%'} direction={'row'} spacing={1.5}>
                    <BlackOnWhiteButton onClick={() => onCloseAndResetState()}>Cancel</BlackOnWhiteButton>
                    <Button
                        className={commonStyles.classes.LowercaseTextButton}
                        disableElevation={true}
                        type={'submit'}
                        variant={'contained'}
                        form={'user-form-drawer-form'}
                    >
                        {user ? 'Save' : 'Create'}
                    </Button>
                </Stack>
            </DialogActions>
        </Drawer>
    );
};

const useStyles = customMakeStyles<IUserFormDrawerV2Props>()((theme, props) => ({
    DrawerRoot: {
        zIndex: '1300 !important' as any,
    },
    PlatformRolesList: { paddingLeft: 0, margin: 0 },
    DrawerPaper: { width: '30%' },
    DialogActions: {
        paddingRight: '1.5rem',
        paddingTop: '2px',
        paddingBottom: '1.5rem',
    },
    CloseButton: { paddingRight: 0, paddingBottom: 0, paddingTop: 0 },
}));

export { UserFormDrawerV2 };
