import { KeyboardArrowUp, KeyboardArrowDown, Refresh, RestartAlt } from '@mui/icons-material';
import { Box, Button, Collapse, Stack } from '@mui/material';
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarProps, ToolbarPropsOverrides } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { LoadingButton } from '@mui/lab';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-premium';
import { useTableUtilities } from '../use-table-utilities/use-table-utilities';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DataGridCustomToolbarProps extends GridToolbarProps, ToolbarPropsOverrides {
    tableIdentifier: string;
    defaultHidden?: any;
}

const DataGridCustomToolbar: React.FC<DataGridCustomToolbarProps> = (props) => {
    const { showRefresh, requestToInvalidate, ...remainingProps } = props;
    const { classes, cx } = useStyles(props);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const currentTableUtilities = useTableUtilities(props.tableIdentifier, props.defaultHidden);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        if (currentTableUtilities.currentTableControl) {
            const isDirty =
                currentTableUtilities.currentTableControl.filterModel.items.length > 0 ||
                currentTableUtilities.currentTableControl.sortModel.length > 0 ||
                (props.defaultHidden
                    ? JSON.stringify(currentTableUtilities.currentTableControl.columnModel) !== JSON.stringify(props.defaultHidden)
                    : JSON.stringify(currentTableUtilities.currentTableControl.columnModel) !== '{}') ||
                currentTableUtilities.currentTableControl.density !== undefined;
            setIsDirty(isDirty);
            !isOpen && setIsOpen(isDirty);
        }
    }, [
        currentTableUtilities.currentTableControl?.filterModel,
        currentTableUtilities.currentTableControl?.sortModel,
        currentTableUtilities.currentTableControl?.columnModel,
        currentTableUtilities.currentTableControl?.density,
    ]);

    const onShowOrHide = () => {
        setIsOpen(!isOpen);
    };

    const onClickReset = () => {
        currentTableUtilities.resetTableControl();
    };

    return (
        <Stack alignItems={'start'} direction={'row'} justifyContent={'space-between'}>
            <Stack alignItems={'start'}>
                <Button
                    className={cx(classes.FilterOptionButton)}
                    endIcon={isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    onClick={onShowOrHide}
                    variant='text'
                >
                    {isOpen ? 'Hide' : 'Show'} table controls
                </Button>
                <Collapse in={isOpen} timeout='auto' unmountOnExit>
                    <GridToolbarContainer>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                        <GridToolbarDensitySelector slotProps={{ tooltip: { title: 'Change density' } }} />
                        <Button
                            aria-label={'Reset table controls'}
                            startIcon={<RestartAlt />}
                            variant={'text'}
                            disabled={!isDirty}
                            onClick={onClickReset}
                        >
                            Reset
                        </Button>
                        <Box sx={{ flexGrow: 1 }} />
                        <GridToolbarExport
                            printOptions={{ disableToolbarButton: true }}
                            slotProps={{
                                tooltip: { title: 'Export data' },
                                button: { variant: 'outlined' },
                            }}
                        />
                    </GridToolbarContainer>
                </Collapse>
            </Stack>
            {showRefresh && (
                <LoadingButton
                    loading={isRefreshing}
                    startIcon={<Refresh />}
                    variant={'outlined'}
                    onClick={() => {
                        setIsRefreshing(true);
                        requestToInvalidate().finally(() => setIsRefreshing(false));
                    }}
                >
                    Refresh All
                </LoadingButton>
            )}
        </Stack>
    );
};

const useStyles = customMakeStyles<DataGridCustomToolbarProps>()((theme, props) => ({
    FilterOptionButton: {
        color: theme.palette.grey[900],
        fontWeight: 'bold',
        textTransform: 'none',
    },
}));

export { DataGridCustomToolbar };
