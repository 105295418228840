import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SettingsContentHeader } from '../settings-content-header';
import { PasswordSettingsInput } from './password-settings-input';
import { SettingsV2Divider } from '../settings-v2-divider';
import { Grid } from '@mui/material';
import { IUserSettingPassword } from '@vegaplatformui/models';
import { SettingsDetailTile } from '../settings-detail-tile';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPasswordSettingsV2Props {
    onUpdatePassword: (data: IUserSettingPassword) => void;
}

const PasswordSettingsV2: React.FC<IPasswordSettingsV2Props> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid container spacing={2}>
            <SettingsContentHeader title={'Password'} subtitle={'Manage your password.'} />
            <SettingsV2Divider />
            <SettingsDetailTile title={'Password'} children={<PasswordSettingsInput onUpdatePassword={props.onUpdatePassword} />} />
        </Grid>
    );
};

const useStyles = customMakeStyles<IPasswordSettingsV2Props>()((theme, props) => ({}));

export { PasswordSettingsV2 };
