import React, { useState } from 'react';
import { NotificationSettingsTab } from '../../utilities/notification-settings-tab';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Divider, Grid2, Typography } from '@mui/material';
import { ScheduleItem } from './schedule-item';
import { ScheduleReportDialog } from './schedule-report-dialog/schedule-report-dialog-content';
import { IconButtonWithAlertPopover } from '../../icon-button-with-alert-popover/icon-button-with-alert-popover';
import { ErrorOutline } from '@mui/icons-material';
import { LinkToProfile } from '../../utilities/link-to-profile';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationScheduleReportsProps {
    reports: any[];
    isDefaultCommunicationItemsLoading: boolean;
    isPhoneNumberSet: boolean;
}

const NotificationScheduleReports: React.FC<INotificationScheduleReportsProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedReport, setSelectedReport] = useState<any>(undefined);

    const onDialogClose = () => {
        setIsOpen(false);
        setSelectedReport(undefined);
    };
    return (
        <>
            <NotificationSettingsTab
                title={'Schedule Reports'}
                subtitle={'Set up schedules for curated reports to ensure timely delivery and visibility.'}
                isLoading={false}
            >
                <Grid2 className={cx(classes.ColumnMinWidth)} size={2}>
                    <Typography>Status</Typography>
                </Grid2>
                <Grid2 className={cx(classes.ColumnMinWidth)} size={5.3}>
                    <Typography>Type</Typography>
                </Grid2>
                <Grid2 className={cx(classes.ColumnCheckboxMinWidth)} size={1.3}>
                    <Typography>Email</Typography>
                </Grid2>
                <Grid2
                    className={cx(classes.ColumnCheckboxMinWidth)}
                    size={1.3}
                    container
                    spacing={0}
                    justifyContent='flex-start'
                    alignItems='center'
                >
                    <Grid2>
                        <Typography>SMS</Typography>
                    </Grid2>
                    {!props.isPhoneNumberSet && !props.isDefaultCommunicationItemsLoading && (
                        <Grid2>
                            <IconButtonWithAlertPopover
                                buttonTitle={'SMS Issue'}
                                buttonColor={'error'}
                                buttonIcon={<ErrorOutline />}
                                alertSeverity={'error'}
                                shouldBindOnHover={true}
                                alertContent={
                                    <>
                                        Unable to check SMS checkbox. Please add a phone number in {<LinkToProfile color={'inherit'} />} to enable SMS
                                        notifications.
                                    </>
                                }
                                iconButtonSize={'small'}
                            />
                        </Grid2>
                    )}
                </Grid2>
                {/*<Grid2 className={cx(classes.ColumnCheckboxMinWidth)} item xs={1.3}>*/}
                {/*    <Typography>Slack</Typography>*/}
                {/*</Grid2>*/}
                <Grid2 className={cx(classes.ColumnCheckboxMinWidth)} size={2.1}>
                    <Typography>Schedule</Typography>
                </Grid2>
                <Grid2 className={cx(classes.Divider)} size={12}>
                    <Divider variant={'fullWidth'} />
                </Grid2>
                <Grid2 size={12}>
                    {props.reports.map((report) => (
                        <ScheduleItem
                            key={report.id}
                            report={report}
                            setSelectedReport={setSelectedReport}
                            setIsOpen={setIsOpen}
                            isPhoneNumberSet={props.isPhoneNumberSet}
                        />
                    ))}
                </Grid2>
            </NotificationSettingsTab>
            <ScheduleReportDialog isOpen={isOpen} onDialogClose={onDialogClose} selectedReport={selectedReport} />
        </>
    );
};

const useStyles = customMakeStyles<INotificationScheduleReportsProps>()((theme, props) => ({
    ColumnMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '100px',
        },
    },
    ColumnCheckboxMinWidth: {
        [theme.breakpoints.down('md')]: {
            minWidth: '50px',
        },
    },
    Divider: { marginBottom: '1rem' },
}));

export { NotificationScheduleReports };
