import React, { useEffect } from 'react';
import { DataGrid, GridRowSelectionModel, GridSlotsComponent, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Box, Button, IconButton } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid-premium';
import { Delete, Edit } from '@mui/icons-material';
import { DataGridCustomToolbar, DataGridCustomToolbarProps } from '../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../custom-datagrid-column-menu/custom-datagrid-column-menu';

export type VegaTag = { id: string; key: string; values: string[]; resources: string; required: boolean; description: string; createdAt: string };

export interface ITagManagerTableProps {
    tags: VegaTag[];
    setSelectedTags: React.Dispatch<React.SetStateAction<VegaTag[]>>;
    selectedTags: VegaTag[];
    isLoading: boolean;
    onClickEditTag: (tag: VegaTag) => void;
    onClickDeleteTag: (tag: VegaTag) => void;
}

const TagManagerTable: React.FC<ITagManagerTableProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const columns: GridColDef[] = [
        { field: 'key', headerName: 'Tag', flex: 1 },
        {
            field: 'values',
            headerName: 'Values',
            flex: 1,
        },
        {
            field: 'resources',
            headerName: 'Resources',
            flex: 1,
        },
        {
            field: 'required',
            headerName: 'Required',
            flex: 1,
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 1,
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
        },
        {
            field: 'actions',
            /*
            type: 'actions',
*/
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 1,
            headerClassName: cx(classes.ActionsHeader),
            renderCell: (params: GridRenderCellParams<VegaTag>) => (
                <strong>
                    <IconButton onClick={() => props.onClickEditTag(params.row)} size='small' tabIndex={params.hasFocus ? 0 : -1}>
                        <Edit />
                    </IconButton>
                    <IconButton onClick={() => props.onClickDeleteTag(params.row)} size='small' tabIndex={params.hasFocus ? 0 : -1}>
                        <Delete />
                    </IconButton>
                </strong>
            ),
        },
    ];

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);
        props.setSelectedTags(gridSelectionModel.map((id) => props.tags.find((tag) => tag.id === id) as VegaTag));
    };

    // check if this is needed when the tag-manager is used
    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => column.field !== '__check__').map((column) => column.field);
    };

    return (
        <Box>
            <DataGrid
                autoHeight={true}
                columns={columns}
                rows={props.tags}
                onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
                rowSelectionModel={selectionModel}
                checkboxSelection={true}
                disableRowSelectionOnClick={true}
                slotProps={{
                    toolbar: { tableIdentifier: 'tag-manager-table' } as DataGridCustomToolbarProps,
                    columnsManagement: {
                        getTogglableColumns,
                    },
                }}
                slots={{
                    toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                    columnMenu: CustomDatagridColumnMenu,
                }}
                loading={props.isLoading}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<ITagManagerTableProps>()((theme, props) => ({
    ToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    ToolBarFilter: {
        color: theme.palette.grey[500],
        marginBottom: '1rem',
    },
    ActionsHeader: { fontSize: 0 },
}));

export { TagManagerTable };
