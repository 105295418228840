import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import {
    Box,
    Button,
    Checkbox,
    Drawer,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Add, Close, PlaylistAdd } from '@mui/icons-material';
import { IAssignmentRule, IAssignmentRuleFormValue, IAssignmentRulesForm, IBusinessGrouping, IBusinessGroupingNode } from '@vegaplatformui/models';

import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue } from 'recoil';
import { OrganizationId } from '../../recoil/atom';
import { setSelectedNodes } from '../../utilities/selecting-assigned-business-grouping';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AssignmentRulesConditonMultiGroups } from '../assignment-rules-conditions/assignment-rules-condition-group/assignment-rules-conditon-multi-groups';
import { BusinessGroupingPicker } from '../../business-grouping-picker/business-grouping-picker';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { FormField } from '../../forms';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesDrawerProps {
    isOpen: boolean;
    width: string;
    onCloseDrawer: () => void;
    assignmentRuleToEdit?: IAssignmentRule;
    assignmentRuleFormValues: IAssignmentRuleFormValue[];
    businessGroupings: TreeNodeProps[];
    onClickSaveAssignmentRule: (assignmentRule: IAssignmentRule, isEdit: boolean) => void;
    isAssignmentRuleSaving: boolean;
    businessGroupingsFlat: IBusinessGrouping[];
}

const validationSchema: yup.ObjectSchema<IAssignmentRulesForm> = yup.object().shape({
    name: yup.string().required('Rule name is required'),
    is_active: yup.boolean().required('Active is required'),
    business_grouping: yup.mixed<IBusinessGroupingNode[]>().required('Business grouping is required'),
    condition_groups: yup
        .array()
        .of(
            yup.object().shape({
                idx: yup.number().required('Index is required'),
                combinator: yup.string().required('Combinator is required'),
                conditions: yup
                    .array()
                    .of(
                        yup.object().shape({
                            field: yup.string().required('Field is required'),
                            operator: yup.string().required('Operator is required'),
                            value: yup.string().required('Value is required'),
                        })
                    )
                    .required('Conditions are required'),
            })
        )
        .required('Condition groups are required'),
});

const AssignmentRulesDrawer: React.FC<IAssignmentRulesDrawerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const organizationId = useRecoilValue(OrganizationId);
    const [BGs, setBGs] = React.useState<TreeNodeProps[]>(props.businessGroupings);
    const defaultValues = {
        is_active: props.assignmentRuleToEdit?.is_active ?? true,
        name: props.assignmentRuleToEdit?.name ?? '',
        business_grouping: props.assignmentRuleToEdit
            ? [props.businessGroupingsFlat.find((bg) => bg.id === props.assignmentRuleToEdit?.business_unit_id)]
            : null,
        condition_groups: props.assignmentRuleToEdit?.condition_groups || [
            { idx: 1, combinator: 'and', conditions: [{ id: uuidv4(), operator: '', field: '', value: '' }] },
        ],
    };

    const {
        handleSubmit,
        control,
        reset,
        formState,
        setValue,
        getValues,
        formState: { errors, isSubmitSuccessful },
    } = useForm<IAssignmentRulesForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
    });

    useEffect(() => {
        reset(
            {
                ...defaultValues,
            },
            { keepDirty: true, keepIsValid: true }
        );
        if (props.assignmentRuleToEdit) {
            setBGs(setSelectedNodes(props.businessGroupings, [props.assignmentRuleToEdit.business_unit_id]));
        } else {
            setBGs(props.businessGroupings);
        }
    }, [props.assignmentRuleToEdit, props.isOpen]);

    const onClose = () => {
        reset({});
        props.onCloseDrawer();
    };

    const onClickSave = (form: IAssignmentRulesForm) => {
        //check that all conditions have values
        const assignmentRule: IAssignmentRule = {
            ...props.assignmentRuleToEdit,
            name: form.name,
            is_active: form.is_active,
            business_unit_id:
                form.business_grouping && form.business_grouping.length > 0 && (form.business_grouping as TreeNodeProps[])[0].value
                    ? (form.business_grouping as TreeNodeProps[])[0].value
                    : form.business_grouping && form.business_grouping.length > 0 && (form.business_grouping as IBusinessGroupingNode[])[0].id
                      ? (form.business_grouping as IBusinessGroupingNode[])[0].id
                      : '',
            organization_id: organizationId,
            condition_groups: form.condition_groups ?? [],
        };
        props.onClickSaveAssignmentRule(assignmentRule, !!props.assignmentRuleToEdit);
    };

    const onClickAddConditionGroup = () => {
        const update = getValues('condition_groups');
        update?.push({ idx: update.length + 1, combinator: 'and', conditions: [{ id: uuidv4(), operator: '', field: '', value: '' }] });

        setValue('condition_groups', update);
    };

    return (
        <Drawer
            id={'assignment-rule-form'}
            component={'form'}
            onSubmit={handleSubmit((data) => {
                onClickSave(data);
            })}
            PaperProps={{
                className: cx(classes.DrawerPaper),
            }}
            classes={{ root: cx(classes.DrawerRoot) }}
            anchor={'right'}
            open={props.isOpen}
            onClose={() => {}}
        >
            {props.isAssignmentRuleSaving && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Grid className={cx(classes.Container)} container direction={'row'}>
                <Grid container direction={'row'}>
                    <Grid
                        className={cx(classes.TitleContainer)}
                        container
                        direction='column'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        item
                        xs={8}
                    >
                        <Typography variant={'h6'}>{props.assignmentRuleToEdit ? `Edit assignment rule` : 'Add assignment rule'}</Typography>
                        {!props.assignmentRuleToEdit && <Typography>Create assignment rules to target business groupings.</Typography>}
                    </Grid>
                    <Grid container alignItems={'center'} justifyContent={'flex-end'} item xs={4} className={cx(classes.CloseButton)}>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid spacing={2} item xs={12} container className={cx(classes.TitleContainer, classes.ContentContainer)}>
                    <Grid item xs={12}>
                        <Box component={'label'} htmlFor={'name'} fontWeight={600}>
                            Rule name
                        </Box>
                        <Controller
                            name={'name'}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    sx={{ marginTop: '1rem' }}
                                    value={value}
                                    onChange={onChange}
                                    placeholder={'Enter assignment rule name'}
                                    id='name'
                                    size='small'
                                    fullWidth={true}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid className={cx(classes.AddTopSpacing)} item xs={12}>
                        <Grid container direction={'row'} justifyContent={'space-between'}>
                            <Grid item xs={6}>
                                <Typography fontWeight={600}>Conditions</Typography>
                                <Typography variant={'body2'}>Resources will be assigned to these rules if they satisfy all conditions.</Typography>
                            </Grid>
                            <Grid alignItems='center' container justifyContent={'end'} item xs={6}>
                                <Button variant={'outlined'} size={'small'} startIcon={<Add />} onClick={onClickAddConditionGroup}>
                                    Conditions Group
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction={'column'} spacing={1}>
                            <Controller
                                name={'condition_groups'}
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <AssignmentRulesConditonMultiGroups
                                        assignmentRuleFormValues={props.assignmentRuleFormValues}
                                        assignmentRuleConditionGroups={value ?? []}
                                        errors={errors.condition_groups}
                                        onChangeAssignmentRuleConditionGroups={onChange}
                                    />
                                )}
                            />
                        </Stack>
                    </Grid>
                    <Grid className={cx(classes.AddTopSpacing)} item xs={12}>
                        <Typography className={cx(classes.AssignToTypography)} fontWeight={600}>
                            Assign to
                        </Typography>
                        <Controller
                            name={'business_grouping'}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <BusinessGroupingPicker
                                    mode='radioSelect'
                                    data={BGs}
                                    setData={setBGs}
                                    changeHandler={(nodes) => {
                                        onChange(nodes);
                                    }}
                                    hasError={!!errors.business_grouping}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={cx(classes.ActionButtonContainer)}>
                <Grid item xs={6}>
                    <Controller
                        name={'is_active'}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel control={<Checkbox checked={value} onChange={(e, checked) => onChange(checked)} />} label='Active' />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Stack direction='column' justifyContent='center' alignItems='flex-end'>
                        <Grid justifyContent={'flex-end'} container>
                            <Button variant={'cancel'} className={cx(classes.CancelButton)} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type={'submit'} form={'assignment-rule-form'}>
                                {props.assignmentRuleToEdit ? 'Save' : 'Add assignment rule'}
                            </Button>
                        </Grid>
                        {errors.condition_groups && <FormHelperText error>All fields require values.</FormHelperText>}
                    </Stack>
                </Grid>
            </Grid>
        </Drawer>
    );
};

const useStyles = customMakeStyles<IAssignmentRulesDrawerProps>()((theme, props) => ({
    TitleContainer: {
        padding: '2rem',
    },
    Container: { overflow: 'auto', paddingBottom: '200px' },
    ContentContainer: { marginTop: '-3rem' },
    DrawerRoot: {
        zIndex: '1300 !important',
    },
    AddTopSpacing: {
        marginTop: '1rem',
    },
    CancelButton: { marginRight: '1rem' },

    DrawerPaper: { width: props.width },
    CloseButton: { float: 'right', marginRight: '-2rem', marginTop: '-4.5rem' },
    ActionButtonContainer: {
        padding: '2rem',
        marginTop: 'auto',
    },
    AssignToTypography: {
        marginBottom: '1rem',
    },
}));

export { AssignmentRulesDrawer };
