import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IApplyAssignmentRulesDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onApplyAssignmentRules: (rule: string) => void;
}

const ApplyAssignmentRulesDialog: React.FC<IApplyAssignmentRulesDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{`Re-apply rules`}</DialogTitle>
            <DialogContent>
                <DialogContentText variant={'body2'}>
                    Active rules will be reapplied to all resources in the selectd business grouping. Processing might take some time.{' '}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'cancel'} onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    color={'primary'}
                    onClick={() => {
                        props.onApplyAssignmentRules('ALL');
                        props.onClose();
                    }}
                >
                    Re-apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IApplyAssignmentRulesDialogProps>()((theme, props) => ({}));

export { ApplyAssignmentRulesDialog };
