import { useEffect } from 'react';
import { LinearProgress, Typography, Grid2 } from '@mui/material';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinearDeterminateLoadingProps {
    progress: number;
    setProgress: React.Dispatch<React.SetStateAction<number>>;
    intervalProgression: number;
    timerDelay: number;
}

const LinearDeterminateLoading: React.FC<ILinearDeterminateLoadingProps> = (props) => {
    const { progress, setProgress, intervalProgression, timerDelay } = props;
    const { classes, cx } = useStyles(props);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + intervalProgression));
        }, timerDelay);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Grid2 container direction='column' justifyContent='space-between' alignItems='center' spacing={30}>
            <Grid2 className={cx(classes.LoadingGrid)} size={12}>
                <LinearProgress variant={'determinate'} value={progress} />
            </Grid2>
            <Grid2 size={12} container direction='column' justifyContent='center' alignItems='center' wrap='nowrap' spacing={2}>
                <Grid2 size={12}>
                    <Typography variant='h5'>Please wait a moment while we set things up.</Typography>
                </Grid2>
                <Grid2 size={12}>
                    <Typography className={cx(classes.ProgressText)} variant='h6'>{`${Math.round(progress)}%`}</Typography>
                </Grid2>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<ILinearDeterminateLoadingProps>()((theme, props) => ({
    VCow: { width: '100%' },
    ContentGrid: {},
    LoadingGrid: { width: '100%' },
    ProgressText: { color: theme.palette.text.secondary },
}));

export { LinearDeterminateLoading };
