import React from 'react';
import { useCommonStyles, customMakeStyles } from '@vegaplatformui/styling';
import { DataGridPremium, GridColDef, GridRenderCellParams, useGridApiRef, GridGroupNode, GridRowParams } from '@mui/x-data-grid-premium';
import { Box } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { CloudProviderIcons } from '../../utilities/logo-selector';
import { ContainerType, IVegaContainer } from '@vegaplatformui/models';
import { ContainerTypeFormatter } from '../../utilities/container-type-formatter';
import { GridActionsCellItem, GridRowId, GridSlotsComponent } from '@mui/x-data-grid';
import { FormatNumberUSDHundredth } from '../../utilities/value-formatter-methods';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { DataGridCustomToolbar } from '../../utilities/datagrid-custom-toolbar';
import { CustomGridColStringOperatorDef } from '../../utilities/custom-grid-col-string-operator-def';
import { CustomDatagridColumnMenu } from '../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { GetVegaContainerDescendantCount } from '../../utilities/get-vega-container-descendent-count';
import { useTableUtilities } from '../../use-table-utilities/use-table-utilities';
import { tableIdentifierKeys } from '../../use-table-utilities/table-identifier-keys';
import { CustomNoRowsOverlay, CustomNoResultsOverlay } from '../../utilities/datagrid-custom-norowsoverlay';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISpacesTableProps {
    spaces: IVegaContainer[];
    isLoading: boolean;
    onClickEditSpace: (space: IVegaContainer) => void;
    onClickTableItem: (container: IVegaContainer[], containerType?: ContainerType) => void;
}

const SpacesTable: React.FC<ISpacesTableProps> = (props) => {
    const localStyles = useStyles(props);
    const commonHeaderStyles = useCommonStyles();
    const apiRef = useGridApiRef();
    const spacesTableUtilities = useTableUtilities(tableIdentifierKeys.spacesTable);
    const expansionLookup = React.useRef<Record<GridRowId, boolean | undefined>>({});

    React.useEffect(() => {
        spacesTableUtilities.registerTableControl({ name: false, budget: false });
    }, []);

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Name',
            disableExport: false,
            filterable: false,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'container_type',
            headerName: 'Type',
            flex: 1,
            valueGetter: (value: string, row: IVegaContainer) => ContainerTypeFormatter(row?.container_type),
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'provider',
            headerName: 'Providers',
            flex: 2,
            description: 'This container level has resources from the provider(s) listed',
            headerClassName: commonHeaderStyles.cx(commonHeaderStyles.classes.AggregatedHeader),
            valueFormatter: (value: string[], row) => {
                return value?.join(',');
            },
            renderCell: (params: GridRenderCellParams) => {
                if (params.aggregation && params.row?.container_type !== ContainerType.Resource) {
                    return params.formattedValue;
                } else if (params.row?.container_type === ContainerType.Resource) {
                    return <CloudProviderIcons cloudProviders={params.row?.provider} />;
                } else if (params.row?.provider === '' || params.row?.provider === null || params.row?.provider === undefined) {
                    return '';
                } else {
                    return <CloudProviderIcons cloudProviders={params.row?.provider} />;
                }
            },
        },
        /* {
            field: 'cost',
            headerName: 'Cost',
            description: 'Total cost at each container level',
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            headerClassName: commonHeaderStyles.cx(commonHeaderStyles.classes.AggregatedHeader),
            valueFormatter: (params: GridValueFormatterParams) => FormatNumberUSDHundredth(params.value),
        },*/
        {
            ...CustomGridColStringOperatorDef,
            field: 'budget',
            headerName: 'Budget',
            flex: 1,
            filterable: false,
            valueFormatter: (value: number, row) => FormatNumberUSDHundredth(value),
        },
        /*        {
            field: 'itemsExceededBudget',
            headerName: 'Items Exceeding Budget',
            flex: 1,
        },*/
        // {
        //     field: 'nested',
        //     headerName: 'Nested',
        //     flex: 1,
        //     renderCell: (params: GridRenderCellParams<VegaSpace>) => {
        //         return RenderDescendentCount(params);
        //     },
        // },
        {
            ...CustomGridColStringOperatorDef,
            disableExport: true,
            field: 'actions',
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            flex: 1,
            type: 'actions',
            headerName: 'Actions',
            headerAlign: 'right',
            align: 'right',
            getActions: (params: GridRowParams<IVegaContainer>) => [
                <StyledToolTip arrow title='Edit'>
                    <GridActionsCellItem
                        icon={<Edit />}
                        label={'Edit'}
                        aria-label={`Edit ${params.row.name}`}
                        onClick={() => props.onClickEditSpace(params.row)}
                        size='small'
                    />
                </StyledToolTip>,
            ],
        },
    ];

    React.useEffect(() => {
        apiRef.current.subscribeEvent('rowExpansionChange', (node) => {
            expansionLookup.current[node.id] = node.childrenExpanded;
        });
    }, [apiRef]);

    const isGroupExpandedByDefault = React.useCallback(
        (node: GridGroupNode) => {
            return !!expansionLookup.current[node.id];
        },
        [expansionLookup]
    );

    const hiddenFields = ['name', 'budget', 'actions'];

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);
    };

    return (
        <Box>
            <DataGridPremium
                apiRef={apiRef}
                slotProps={{
                    columnsManagement: { getTogglableColumns },
                    toolbar: {
                        tableIdentifier: tableIdentifierKeys.spacesTable,
                        defaultHidden: { name: false, budget: false },
                        printOptions: { fields: ['name', 'container_type', 'provider', 'cost'] },
                        csvOptions: { fields: ['name', 'container_type', 'provider', 'cost'] },
                        excelOptions: { fields: ['name', 'container_type', 'provider', 'cost'] },
                    },
                    pagination: {
                        page: spacesTableUtilities.currentTableControl?.paginationModel?.page,
                        onPageChange: spacesTableUtilities.onPaginationModelPageChange,
                        count: spacesTableUtilities.currentTableControl?.totalRows,
                        rowsPerPage: spacesTableUtilities.currentTableControl?.paginationModel?.pageSize,
                    },
                    noRowsOverlay: {
                        action: 'creating a space, workload or resource pool',
                    },
                }}
                groupingColDef={{
                    ...CustomGridColStringOperatorDef,
                    disableColumnMenu: false,
                    filterable: true,
                    headerName: 'Name',
                    hideDescendantCount: true,
                    sortable: true,
                    disableExport: true,
                    flex: 2,
                    valueGetter: (value: string, row: IVegaContainer) => {
                        return row.name;
                    },
                    valueFormatter: (value: string, row: IVegaContainer) => {
                        return (
                            <GetVegaContainerDescendantCount
                                row={row}
                                apiRef={apiRef}
                                spaces={props.spaces}
                                onClickTableItem={props.onClickTableItem}
                            />
                        );
                    },
                }}
                treeData
                getTreeDataPath={(row) => row.path.split('/')}
                autoHeight={true}
                columns={columns}
                rows={props.spaces}
                checkboxSelection={false}
                disableRowSelectionOnClick={true}
                columnVisibilityModel={{
                    ...spacesTableUtilities.currentTableControl?.columnModel,
                    name: false,
                    budget: false,
                }}
                density={spacesTableUtilities.currentTableControl?.density}
                onDensityChange={spacesTableUtilities.onDensityChange}
                onColumnVisibilityModelChange={spacesTableUtilities.onColumnVisibilityModelChange}
                filterModel={spacesTableUtilities.currentTableControl?.filterModel}
                onFilterModelChange={spacesTableUtilities.onFilterModelChange}
                paginationModel={spacesTableUtilities.currentTableControl?.paginationModel}
                onPaginationModelChange={spacesTableUtilities.onPaginationModelChange}
                loading={props.isLoading}
                isGroupExpandedByDefault={isGroupExpandedByDefault}
            />
        </Box>
    );
};

const useStyles = customMakeStyles<ISpacesTableProps>()((theme, props) => ({
    ToolBar: {
        color: theme.palette.grey[100],
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    ToolBarFilter: {
        color: theme.palette.primary.main,
        marginBottom: '1rem',
    },
    CloudProviderIcon: {
        width: '1.5rem',
    },
    ContainerButton: {
        textTransform: 'none',
    },
}));

export { SpacesTable };
