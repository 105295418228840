import React, { useRef } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { CustomDataGridRenderCellButton, CustomDataGridRenderCellButtonRef } from '../../utilities/custom-data-grid-render-cell-button';
import { MoreHoriz } from '@mui/icons-material';
import { ICloudProviderAccount } from '@vegaplatformui/models';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Menu, MenuItem } from '@mui/material';
import { DiscoveryDetails } from '../../jotai/atom';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { useAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICloudProviderAccountsActionMenuProps {
    onClickEditAccount: (account: ICloudProviderAccount) => void;
    handleClickSendDiscoveryRequest: (accounts: string[]) => void;
    selectedAccounts: ICloudProviderAccount[];
    params: GridRenderCellParams<ICloudProviderAccount>;
}

const CloudProviderAccountsActionMenu: React.FC<ICloudProviderAccountsActionMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const ref = useRef<CustomDataGridRenderCellButtonRef>(null);
    const [discoveryDetails] = useAtom(DiscoveryDetails);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleToggleCreateDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <StyledToolTip title={'Options'}>
                <CustomDataGridRenderCellButton
                    ref={ref}
                    iconButtonProps={{
                        title: 'Options',
                        tabIndex: props.params.tabIndex,
                        onClick: handleToggleCreateDropDown,
                        children: <MoreHoriz />,
                        'aria-label': `${props.params.row.canonical_name ?? props.params.row.account_id}'s Options`,
                        'aria-expanded': !!anchorEl,
                        size: 'small',
                        'aria-owns': anchorEl ? 'cloud-provider-accounts-table-menu' : undefined,
                    }}
                    params={props.params}
                />
            </StyledToolTip>
            <Menu
                id='cloud-provider-accounts-table-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <StyledToolTip
                    arrow
                    placement={'left'}
                    title={
                        !props.params.row.enabled
                            ? 'Account is disabled'
                            : discoveryDetails.is_discovery
                            ? 'Running Discovery'
                            : discoveryDetails.in_cooldown
                            ? 'Discovery on Cooldown'
                            : ''
                    }
                >
                    {props.selectedAccounts.length > 0 ||
                    !props.params.row.enabled ||
                    discoveryDetails.is_discovery ||
                    discoveryDetails.in_cooldown ? (
                        <span>
                            <MenuItem
                                tabIndex={props.params.tabIndex}
                                aria-label={
                                    !props.params.row.enabled
                                        ? 'Account is disabled'
                                        : discoveryDetails.is_discovery
                                        ? 'Running Discovery'
                                        : discoveryDetails.in_cooldown
                                        ? 'Discovery on Cooldown'
                                        : `Discover ${props.params.row.canonical_name ?? props.params.row.account_id}`
                                }
                                disabled={
                                    props.selectedAccounts.length > 0 ||
                                    !props.params.row.enabled ||
                                    discoveryDetails.is_discovery ||
                                    discoveryDetails.in_cooldown
                                }
                                onClick={(event) => {
                                    props.handleClickSendDiscoveryRequest([props.params.row.id]);
                                    handleClose();
                                }}
                            >
                                Discover
                            </MenuItem>
                        </span>
                    ) : (
                        <MenuItem
                            tabIndex={props.params.tabIndex}
                            aria-label={`Discover ${props.params.row.canonical_name ?? props.params.row.account_id}`}
                            onClick={(event) => {
                                props.handleClickSendDiscoveryRequest([props.params.row.id]);
                                handleClose();
                            }}
                        >
                            Discover
                        </MenuItem>
                    )}
                </StyledToolTip>
                <MenuItem
                    autoFocus={
                        props.selectedAccounts.length > 0 ||
                        !props.params.row.enabled ||
                        discoveryDetails.is_discovery ||
                        discoveryDetails.in_cooldown
                    }
                    tabIndex={props.params.tabIndex}
                    aria-label={`edit ${props.params.row.canonical_name ?? props.params.row.account_id}`}
                    onClick={(event) => {
                        props.onClickEditAccount(props.params.row);
                        handleClose();
                    }}
                >
                    Edit
                </MenuItem>
            </Menu>
        </>
    );
};

const useStyles = customMakeStyles<ICloudProviderAccountsActionMenuProps>()((theme, props) => ({}));

export { CloudProviderAccountsActionMenu };
