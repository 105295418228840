import React from 'react';
import { ClientRegistrationCard, useClientRegistrationApi } from '@vegaplatformui/sharedcomponents';
import { INewClient } from '@vegaplatformui/models';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IClientRegistrationControllerProps {}

const ClientRegistrationController: React.FC<IClientRegistrationControllerProps> = (props) => {
    const {
        isCreateApiClientRegistrationLoading,
        isDeleteLoading,
        callClientRegistration,
        areRegisteredClientsLoading,
        registeredClients,
        expirationOptions,
        isClientRegistrationSuccess,
        deleteClientRegistration,
        isClientDeletionSuccess,
        newClientInformation,
        unsetClientInformation,
    } = useClientRegistrationApi({});

    const createNewClientRegistration = (payload: INewClient) => {
        callClientRegistration(payload);
    };

    const deleteClientRegistrationEntry = (clientId: string) => {
        deleteClientRegistration(clientId);
    };

    return (
        <ClientRegistrationCard
            clients={registeredClients}
            areRegisteredClientsLoading={areRegisteredClientsLoading}
            isClientRegistrationLoading={isCreateApiClientRegistrationLoading}
            isDeleteLoading={isDeleteLoading}
            createNewClientRegistration={createNewClientRegistration}
            expirationOptions={expirationOptions || {}}
            deleteClientRegistration={deleteClientRegistrationEntry}
            isClientRegistrationSuccess={isClientRegistrationSuccess}
            isClientDeletionSuccess={isClientDeletionSuccess}
            newClientInformation={newClientInformation}
            unsetClientInformation={unsetClientInformation}
        />
    );
};

export { ClientRegistrationController };
