import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { DailyReoccurrenceField } from './daily-reoccurrence-field';
import { ScheduleReportReoccurrenceType } from '@vegaplatformui/models';
import { customMakeStyles } from '@vegaplatformui/styling';
import { WeeklyReoccurrenceField } from './weekly-reoccurrence-field';
import { MonthlyReoccurrenceField } from './monthly-reoccurrence-field';
import { TimezoneFormField } from '../../../utilities/timezone-form-field';
import { getSignedTimezoneOffset, ITimeZone, timezoneSet } from '@vegaplatformui/utils';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface IScheduleReportDialogProps {
    isOpen: boolean;
    onDialogClose: () => void;
    selectedReport: any;
}

const ScheduleReportDialog: React.FC<IScheduleReportDialogProps> = (props) => {
    const { isOpen, onDialogClose, selectedReport } = props;
    return (
        <Dialog fullWidth={true} maxWidth={'sm'} open={isOpen} onClose={onDialogClose}>
            <ScheduleReportDialogContent onClose={onDialogClose} selectedReport={selectedReport} />
        </Dialog>
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IScheduleReportDialogContentProps {
    onClose: () => void;
    selectedReport: any;
}

const ScheduleReportDialogContent: React.FC<IScheduleReportDialogContentProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [reoccurrenceType, setReoccurrenceType] = React.useState(ScheduleReportReoccurrenceType.Daily);
    const [time, setTime] = useState<Dayjs | null>(null);
    const [day, setDay] = useState<string>('sunday');
    const [dateTime, setDateTime] = useState<Date | undefined>(undefined);
    const [timezone, setTimezone] = React.useState<ITimeZone | null>(
        timezoneSet.find((t: ITimeZone) => {
            const zoneTime = dayjs().local().tz(dayjs.tz.guess());
            const offsetText = getSignedTimezoneOffset(zoneTime);
            return t.offset === offsetText;
        }) ?? null
    );

    return (
        <>
            <DialogTitle>
                <Grid container spacing={0} direction={'row'}>
                    <Grid item xs={12}>
                        <Typography fontWeight={'600'} variant={'h5'}>
                            Schedule for {props.selectedReport && props.selectedReport.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography role={'presentation'} variant={'subtitle1'}>
                            Indicate the preferred timing for report delivery.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TimezoneFormField timezone={timezone} setTimezone={setTimezone} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id='schedule-reports-reoccurrance-radio-buttons-group-label'>Reoccurrence</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby='schedule-reports-reoccurrance-radio-buttons-group-label'
                                value={reoccurrenceType}
                                onChange={(event, value) => {
                                    setDateTime(undefined);
                                    setTime(null);
                                    setDay('sunday');
                                    switch (value as ScheduleReportReoccurrenceType) {
                                        case ScheduleReportReoccurrenceType.Daily:
                                            setReoccurrenceType(ScheduleReportReoccurrenceType.Daily);
                                            break;
                                        case ScheduleReportReoccurrenceType.Weekly:
                                            setReoccurrenceType(ScheduleReportReoccurrenceType.Weekly);
                                            break;
                                        case ScheduleReportReoccurrenceType.Monthly:
                                            setReoccurrenceType(ScheduleReportReoccurrenceType.Monthly);
                                            break;
                                        default:
                                            setReoccurrenceType(ScheduleReportReoccurrenceType.Daily);
                                            break;
                                    }
                                }}
                                name='schedule-reports-reoccurrance-radio-buttons-group'
                            >
                                <FormControlLabel value={ScheduleReportReoccurrenceType.Daily} control={<Radio />} label='Daily' />
                                <FormControlLabel value={ScheduleReportReoccurrenceType.Weekly} control={<Radio />} label='Weekly' />
                                <FormControlLabel value={ScheduleReportReoccurrenceType.Monthly} control={<Radio />} label='Monthly' />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {(() => {
                            switch (reoccurrenceType) {
                                case ScheduleReportReoccurrenceType.Daily:
                                    return <DailyReoccurrenceField time={time} setTime={setTime} timezone={timezone} />;
                                case ScheduleReportReoccurrenceType.Weekly:
                                    return <WeeklyReoccurrenceField time={time} setTime={setTime} day={day} setDay={setDay} timezone={timezone} />;
                                case ScheduleReportReoccurrenceType.Monthly:
                                    return <MonthlyReoccurrenceField dateTime={dateTime} setDateTime={setDateTime} timezone={timezone} />;
                                default:
                                    return <></>;
                            }
                        })()}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={'outlined'} onClick={props.onClose}>
                    Cancel
                </Button>
                <Button onClick={() => {}}>Schedule</Button>
            </DialogActions>
        </>
    );
};

const useStyles = customMakeStyles<IScheduleReportDialogContentProps>()((theme, props) => ({}));

export { ScheduleReportDialog };
