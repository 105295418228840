import React, { useEffect } from 'react';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { AssignmentRulesConditionValue } from './assignment-rules-condition-value/assignment-rules-condition-value';
import IconButton from '@mui/material/IconButton';
import { Delete } from '@mui/icons-material';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { customMakeStyles } from '@vegaplatformui/styling';
import { IAssignmentRuleCondition, IAssignmentRuleFormOperator, IAssignmentRuleFormValue } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAssignmentRulesConditionProps {
    assignmentRuleCondition: IAssignmentRuleCondition;
    onChangeAssignmentRuleCondition: (assignmentRuleCondition: IAssignmentRuleCondition) => void;
    onClickRemoveCondition: (assignmentRuleCondition: IAssignmentRuleCondition) => void;
    isRemovable?: boolean;
    assignmentRuleFormValues: IAssignmentRuleFormValue[];
    error: Merge<FieldError, FieldErrorsImpl<IAssignmentRuleCondition>> | undefined;
}

const AssignmentRulesCondition: React.FC<IAssignmentRulesConditionProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedAssignmentRuleFormValue, setSelectedAssignmentRuleFormValue] = React.useState<IAssignmentRuleFormValue | null>(null);
    const [selectedOperator, setSelectedOperator] = React.useState<IAssignmentRuleFormOperator | null>(null);
    const [value, setValue] = React.useState<string>('');

    useEffect(() => {
        const currentAssignmentRuleFormValue =
            props.assignmentRuleFormValues.find((assignmentRuleFormValue) => assignmentRuleFormValue.field === props.assignmentRuleCondition.field) ??
            null;

        setSelectedAssignmentRuleFormValue(currentAssignmentRuleFormValue);

        setSelectedOperator(
            currentAssignmentRuleFormValue?.operators.find((operator) => operator.operator === props.assignmentRuleCondition.operator) ?? null
        );

        setValue(props.assignmentRuleCondition.value ?? '');
    }, [props.assignmentRuleCondition]);

    const onChangeValue = (value: string) => {
        setValue(value);

        const assignmentRuleCondition: IAssignmentRuleCondition = {
            id: props.assignmentRuleCondition.id,
            field: selectedAssignmentRuleFormValue?.field ?? '',
            operator: selectedOperator?.operator ?? '',
            value: value,
        };

        props.onChangeAssignmentRuleCondition(assignmentRuleCondition);
    };

    const onChangeFormFieldValue = (value: IAssignmentRuleFormValue) => {
        setSelectedAssignmentRuleFormValue(value);

        props.onChangeAssignmentRuleCondition({
            ...props.assignmentRuleCondition,
            field: value.field,
            value: '',
        });
    };

    const onChangeFormOperatorValue = (value: IAssignmentRuleFormOperator) => {
        setSelectedOperator(value);

        props.onChangeAssignmentRuleCondition({
            ...props.assignmentRuleCondition,
            operator: value.operator,
        });
    };

    return (
        <Stack direction={'row'}>
            <Grid key={props.assignmentRuleCondition.id} spacing={1} container direction='row' justifyContent='flex-start' alignItems='center'>
                <Grid item xs={4}>
                    <FormControl size='small' fullWidth>
                        <InputLabel id='condition-select-label'>Condition</InputLabel>
                        <Select
                            error={props.error?.field?.message !== undefined}
                            value={selectedAssignmentRuleFormValue !== null ? selectedAssignmentRuleFormValue?.field ?? '' : ''}
                            size={'small'}
                            labelId='condition-select-label'
                            label='Condition'
                            id='condition-select'
                        >
                            {props.assignmentRuleFormValues.map((assignmentRuleFormValue, index) => (
                                <MenuItem
                                    onClick={() => {
                                        onChangeFormFieldValue(assignmentRuleFormValue);
                                        selectedOperator && setSelectedOperator(null);
                                    }}
                                    value={assignmentRuleFormValue.field}
                                    key={`${assignmentRuleFormValue.field}_${index}`}
                                >
                                    {assignmentRuleFormValue.field_description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl size={'small'} fullWidth>
                        <InputLabel id='condition-operator-label'>Operator</InputLabel>
                        <Select
                            error={props.error?.operator?.message !== undefined}
                            value={selectedOperator?.operator ?? ''}
                            labelId='condition-operator-label'
                            label='Operator'
                            id='operator-select'
                        >
                            {selectedAssignmentRuleFormValue?.operators.map((assignmentRuleFormValueOperator, index) => (
                                <MenuItem
                                    onClick={() => onChangeFormOperatorValue(assignmentRuleFormValueOperator)}
                                    value={assignmentRuleFormValueOperator.operator}
                                    key={`${assignmentRuleFormValueOperator.operator}_${index}`}
                                >
                                    {assignmentRuleFormValueOperator.operator_description}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <AssignmentRulesConditionValue
                        isMissingValues={props.error?.value !== undefined}
                        currentValue={value}
                        onChangeValue={onChangeValue}
                        assignmentRuleFormValue={selectedAssignmentRuleFormValue}
                        assignmentRuleCondition={props.assignmentRuleCondition}
                        onChangeAssignmentRuleCondition={props.onChangeAssignmentRuleCondition}
                    />
                </Grid>
            </Grid>
            {props.isRemovable && (
                <IconButton onClick={() => props.onClickRemoveCondition(props.assignmentRuleCondition)}>
                    <Delete />
                </IconButton>
            )}
        </Stack>
    );
};

const useStyles = customMakeStyles<IAssignmentRulesConditionProps>()((theme, props) => ({}));

export { AssignmentRulesCondition };
