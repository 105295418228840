import { ICloudProviderAccount } from './cloud-provider-account-types';

export interface IOrganizationOwner {
    firstName: string;
    lastName: string;
    emailAddress: string;
}

// Api Path: /api/organizations/:id/children
export interface IOrganizationMetadata {
    id: string;
    parent_id: string;
    name: string;
    domain_url: string;
    children: IOrganizationMetadata[];
}

// Api Path: /api/organizations POST/PATCH
export interface IOrganizationPostRequest {
    name: string;
    slug: string;
    owner: IOrganizationOwner;
    keycloakRealm: string;
    domain: string;
    sku: number;
    isTrial: boolean;
    trialStart?: string;
    trialEnd?: string;
    isMSP: boolean;
    isChildOrg: boolean;
    childOrg?: string;
    created_by: string;
}
// old API - used only in adminui/
// Api Path: /api/organizations/:id GET
export interface IOrganizationResponse {
    id: string;
    name: string;
    status: string;
    sku: number;
    is_enabled: number;
    registered_at: string;
}

// Api Path /api/organizations GET
export interface IOrganizationsResponse {
    data: IOrganizationResponse[];
}

// Api Path /api/organizations/:id/<enable|disable> PATCH
export interface IOrganizationStatusUpdateResponse {
    message: string;
}

// Api Path /api/organization/:id/
export interface IGetOrganizationResponse {
    id: string; // uuid
    realm: string;
    organization_type_id: number;
    organization_owner_id: string; // uuid
    name: string;
    status_id: number;
    slug: string;
    domain_url: string;
    sku_id: number;
    deleted_by: string; // date-time
    deleted_at: string; // date-time
    created_by: string; // uuid
    created_at: string; // date-time
    updated_by: string; // uuid
    updated_at: string; // date-time
}

// Api Path /api/organization/:id/branding/style GET, POST, PUT
export interface IOrganizationBrandingResponse {
    id: string;
    primary_color: string;
    secondary_color: string;
    primary_logo_key: string;
    secondary_logo_key: string;
    favicon_key: string;
    style_override: {};
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
}

export interface IOrgData {
    organizationOwner?: string; // TODO: change it to mandatory when BE makes it non nullable parameter
    organizationName: string;
}

export interface IOrgDataUpdate {
    updated_by: string;
    updated_at: string;
    name?: string;
    organization_owner_id?: string;
}

export enum ILogoVariation {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    FAVICON = 'favicon',
}

interface IUrl {
    url: string;
}

export interface IGetLogoResponse {
    primary: IUrl;
    secondary: IUrl;
    favicon: IUrl;
}

export interface IOrganizationLogoState {
    isCustom: boolean;
    url: string;
}

export interface IOrganizationLogo {
    orgId: string;
    variation: ILogoVariation;
}

export interface ISaveOrgLogoVariables extends IOrganizationLogo {
    file: FormData;
}

export interface IOrganizationThemeColors {
    primary_color: string;
    secondary_color: string;
}

export interface IOrganizationThemeColorState extends IOrganizationThemeColors {
    isCustomColors: boolean;
}

export interface IPostOrganizationThemeRequest {
    primary_color: string;
    secondary_color: string;
    created_by: string;
    created_at: string;
}

export interface IPutOrganizationThemeRequest {
    primary_color: string;
    secondary_color: string;
    updated_by: string;
    updated_at: string;
}

export interface IPostOrganizationThemeHookObject {
    orgId: string;
    primary_color: string;
    secondary_color: string;
    created_by: string;
    created_at: string;
}

export interface IPutOrganizationThemeHookObject {
    orgId: string;
    primary_color: string;
    secondary_color: string;
    updated_by: string;
    updated_at: string;
}

export interface IOrganizationThemeForm {
    primary_color: string;
    secondary_color: string;
}
