import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Chip, Grid, Link, Typography } from '@mui/material';
import { StyledToolTip } from '@vegaplatformui/sharedcomponents';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IExpandableRolesCellProps {
    roles: string[];
    tabIndex: 0 | -1;
}

const ExpandableRolesCell: React.FC<IExpandableRolesCellProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { roles, tabIndex } = props;
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Grid container spacing={1}>
            {expanded
                ? roles.map((role) => {
                      return (
                          <Grid key={role} item xs={'auto'}>
                              <StyledToolTip arrow title={role} placement='top'>
                                  <Chip
                                      size={'small'}
                                      variant='outlined'
                                      label={
                                          <Typography className={cx(classes.Typography)} variant={'body2'}>
                                              {role}
                                          </Typography>
                                      }
                                  />
                              </StyledToolTip>
                          </Grid>
                      );
                  })
                : roles.slice(0, 3).map((role) => {
                      return (
                          <Grid key={role} item xs={'auto'}>
                              <StyledToolTip arrow title={role} placement='top'>
                                  <Chip
                                      size={'small'}
                                      variant='outlined'
                                      label={
                                          <Typography className={cx(classes.Typography)} variant={'body2'}>
                                              {role}
                                          </Typography>
                                      }
                                  />
                              </StyledToolTip>
                          </Grid>
                      );
                  })}
            {roles.length > 3 && (
                <Grid item xs={12}>
                    <Typography variant={'body2'} align={'left'}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                        <Link
                            tabIndex={tabIndex}
                            className={cx(classes.LinkStyle)}
                            type={'button'}
                            component={'button'}
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? 'Show less' : `+ ${roles.length - 3} more`}
                        </Link>
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

const useStyles = customMakeStyles<IExpandableRolesCellProps>()((theme, props) => ({
    Typography: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    LinkStyle: { textDecoration: 'none', '&:hover': { textDecoration: 'underline', color: theme.palette.primary.main } },
}));

export { ExpandableRolesCell };
