import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { MfaTile } from './mfa-tile';
import { IUserSettingMFAStatusEnum } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IMfaInputProps {
    status: IUserSettingMFAStatusEnum;
    onSubmit: (data: string) => void;
}

const MfaInput: React.FC<IMfaInputProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <>
            <MfaTile
                mfaDescription={'Input code generated from an authentication app'}
                mfaType={'Authenticator'}
                status={props.status}
                onSubmit={props.onSubmit}
            />
        </>
    );
};

const useStyles = customMakeStyles<IMfaInputProps>()((theme, props) => ({}));

export { MfaInput };
