import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { GeminiResponse } from '@vegaplatformui/utils';
import { IApplyAssignmentRulesResponse, IAssignmentRule } from '@vegaplatformui/models';

export class RulesApi extends HttpClient {
    protected static classInstance?: RulesApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public getAssignmentRules = (orgId: string): GeminiResponse<IAssignmentRule[]> => this.instance.get(`/rules/${orgId}`);

    public postAssignmentRule = (assignmentRule: IAssignmentRule): GeminiResponse<string> => this.instance.post(`/rules/`, assignmentRule);

    public putAssignmentRule = (assignmentRule: IAssignmentRule): GeminiResponse<string> =>
        this.instance.put(`/rules/${assignmentRule.id}`, assignmentRule);

    public applyAssignmentRules = (orgId: string, rule: string): GeminiResponse<IApplyAssignmentRulesResponse> =>
        this.instance.post(`/rules/apply`, null, { params: { rule: rule, org_id: orgId, reapply: true } });

    public getApplyAssignmentRulesStatus = (job_id: string): GeminiResponse<string> => this.instance.get(`/rules/apply/status/${job_id}`);

    public deleteAssignmentRule = (assignmentRule: IAssignmentRule): GeminiResponse<string> =>
        this.instance.delete(`/rules/`, { params: { rule_id: assignmentRule.id } });
}
