import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GridPagination, GridRowSelectionModel, GridSlotsComponent } from '@mui/x-data-grid';
import { CloudProvidersTypes, ICloudProviderAccount, IParkingPriorityGroup, IResource } from '@vegaplatformui/models';
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams, GridValueOptionsParams, ValueOptions } from '@mui/x-data-grid-premium';
import { Button, CircularProgress, MenuItem, Select } from '@mui/material';
import { CloudProviderIcon } from '../../../utilities/logo-selector';
import { useTableUtilities } from '../../../use-table-utilities/use-table-utilities';
import { DataGridCellTooltip } from '../../../utilities/datagrid-cell-tooltip';
import { DataGridCustomToolbar, DataGridCustomToolbarProps } from '../../../utilities/datagrid-custom-toolbar';
import { CustomDatagridColumnMenu } from '../../../custom-datagrid-column-menu/custom-datagrid-column-menu';
import { CustomGridColSingleSelectOperatorDef, CustomGridColStringOperatorDef } from '../../../utilities/custom-grid-col-string-operator-def';
import { tableIdentifierKeys } from '../../../use-table-utilities/table-identifier-keys';
import { SoftDeletedResourceNotice } from '../soft-deleted-resource-notice';
import { useProviderFilterOptions } from '@vegaplatformui/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateAdvancedParkingResourcesTableProps {
    resources: IResource[];
    onDeleteResources: (resource: IResource[]) => void;
    isLoading: boolean;
    isServerPaginated?: boolean;
    priorityGroups: IParkingPriorityGroup[];
    onChangeResources: (resources: IPrioritizedResource[], selectionModel: GridRowSelectionModel) => void;
    setSelectedResources: (priorityGroups: IResource[]) => void;
    selectedResources: IResource[];
}

export interface IPrioritizedResource extends IResource {
    priority: number;
}

const CreateAdvancedParkingResourcesTable: React.FC<ICreateAdvancedParkingResourcesTableProps> = (props) => {
    const createAdvancedScheduleResourceTableControls = useTableUtilities(tableIdentifierKeys.createAdvancedParkingScheduleResourcesTable);
    const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const { providerOptions } = useProviderFilterOptions({});

    React.useEffect(() => {
        createAdvancedScheduleResourceTableControls.registerTableControl();

        return () => {
            createAdvancedScheduleResourceTableControls.unregisterTableControl();
        };
    }, []);

    useEffect(() => {
        setSelectionModel(props.selectedResources.map((resource) => resource?.id) ?? []);
    }, [props.selectedResources]);

    const [resourcesWithPriorityGroups, setResourcesWithPriorityGroups] = useState<IPrioritizedResource[]>(
        props.resources.map((resource) => {
            return {
                ...resource,
                priority: props.priorityGroups.find((pg) => pg.resources.filter((res) => res.id === resource.id))?.priority ?? -1,
            };
        })
    );

    const [resourcesInPriorityGroups, setResourcesInPriorityGroups] = React.useState<string[]>(
        props.priorityGroups.flatMap((pg) => pg.resources.map((res) => res.id))
    );

    useEffect(() => {
        const newSelectionModel = props.priorityGroups.flatMap((pg) => pg.resources.map((res) => res.id));
        setResourcesInPriorityGroups(newSelectionModel);
    }, [props.priorityGroups]);

    useEffect(() => {
        setResourcesWithPriorityGroups(
            props.resources.map((resource) => {
                return {
                    ...resource,
                    priority: props.priorityGroups.find((pg) => pg.resources.find((res) => res.id === resource.id))?.priority ?? -1,
                };
            })
        );
    }, [props.resources]);

    const onChangeResourcePriority = (resource: IPrioritizedResource, priority: number) => {
        const update = resourcesWithPriorityGroups.map((res) => {
            return res.id === resource.id ? { ...res, priority: priority } : res;
        });
        setResourcesWithPriorityGroups(update);
        props.onChangeResources(update, resourcesInPriorityGroups);
        setResourcesInPriorityGroups(resourcesInPriorityGroups.filter((res) => res !== resource.id));
    };

    const columns: GridColDef[] = [
        {
            ...CustomGridColStringOperatorDef,
            field: 'alert',
            headerName: '',
            flex: 0.25,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: (value: boolean, row: IPrioritizedResource, column, apiRef) => (row.deleted_at ? row.deleted_at : undefined),
            renderCell: (params: GridRenderCellParams<IPrioritizedResource>) => {
                switch (params.value) {
                    case undefined:
                    case null:
                        return <></>;
                    default:
                        return <SoftDeletedResourceNotice />;
                }
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'name',
            headerName: 'Name',
            flex: 1,
            valueGetter: (value: string, row: IPrioritizedResource) => {
                return value ?? '';
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'resource_id',
            headerName: 'Resource ID',
            flex: 1,
        },
        {
            ...CustomGridColSingleSelectOperatorDef,
            field: 'provider_str',
            headerName: 'Provider',
            flex: 0.7,
            renderCell: (params: GridRenderCellParams<IPrioritizedResource>) =>
                params.value ? <CloudProviderIcon cloudProvider={params.value} /> : '',
            valueFormatter: (value: string, row: IPrioritizedResource) => (value ? value : ''),
            type: 'singleSelect',
            valueOptions: (params: GridValueOptionsParams<IPrioritizedResource>): ValueOptions[] => {
                return providerOptions;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'provider_account_canonical_name',
            headerName: 'Account',
            flex: 1,
            valueGetter: (value: string, row: IPrioritizedResource) => {
                return row.provider_account?.canonical_name;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'cloud_account_id',
            headerName: 'Account ID',
            flex: 1,
            valueGetter: (value: string, row: IPrioritizedResource) => {
                return row.provider_account?.account_id;
            },
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'type_str',
            headerName: 'Type',
            flex: 0.75,
        },
        /*  {
            field: 'cost',
            headerName: 'Cost/Mo',
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams) => FormatNumberUSDHundredth(params.value),
        },*/
        {
            ...CustomGridColStringOperatorDef,
            field: 'region',
            headerName: 'Region',
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'Priority Group',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams<IPrioritizedResource>) => {
                return resourcesInPriorityGroups.includes(params.row.id) && params.row.priority === -1 ? (
                    <CircularProgress />
                ) : (
                    <Select
                        variant={'standard'}
                        disableUnderline
                        disabled={props.selectedResources.length > 1}
                        size='small'
                        labelId='priority_group'
                        id='priority_group'
                        value={params.row.priority}
                        onChange={(e) => onChangeResourcePriority(params.row, e.target.value as number)}
                    >
                        <MenuItem value={-1}>None</MenuItem>
                        {props.priorityGroups
                            .sort((a, b) => a.priority - b.priority)
                            .map((group: IParkingPriorityGroup) => (
                                <MenuItem key={group.priority} value={group.priority}>
                                    {group.priority + 1}
                                </MenuItem>
                            ))}
                    </Select>
                );
            },
            flex: 1,
        },
        {
            ...CustomGridColStringOperatorDef,
            field: 'Actions',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            disableExport: true,
            renderCell: (params: GridRenderCellParams<IPrioritizedResource>) => {
                return (
                    <Button
                        onClick={() => {
                            props.onDeleteResources([params.row]);
                        }}
                        disabled={props.selectedResources.length > 1}
                        variant={'text'}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    columns.map((column) => {
        if (!column.renderCell) column.renderCell = DataGridCellTooltip;
        return column;
    });

    const onRowsSelectionHandler = (gridSelectionModel: GridRowSelectionModel) => {
        setSelectionModel(gridSelectionModel);

        props.setSelectedResources(gridSelectionModel.map((id) => props.resources.find((resource) => resource.id === id) as IResource));
    };

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns
            .filter((column) => column.field !== 'Actions' && column.field !== '__check__' && column.field !== 'alert')
            .map((column) => column.field);
    };

    return (
        <DataGridPremium
            onRowSelectionModelChange={(gridSelectionModel: GridRowSelectionModel) => onRowsSelectionHandler(gridSelectionModel)}
            isRowSelectable={(params: GridRowParams<IPrioritizedResource>) => {
                return !params.row.deleted_at;
            }}
            rowSelectionModel={selectionModel}
            autoHeight={true}
            pagination={true}
            paginationMode={'client'}
            keepNonExistentRowsSelected={true}
            columns={columns}
            rows={resourcesWithPriorityGroups}
            checkboxSelection={true}
            disableRowSelectionOnClick={true}
            slots={{
                toolbar: DataGridCustomToolbar as GridSlotsComponent['toolbar'],
                columnMenu: CustomDatagridColumnMenu,
                pagination: GridPagination,
            }}
            slotProps={{
                toolbar: {
                    tableIdentifier: tableIdentifierKeys.createAdvancedParkingScheduleResourcesTable,
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                } as DataGridCustomToolbarProps,
                columnsManagement: {
                    getTogglableColumns,
                },
                noRowsOverlay: {
                    action: 'attaching a resource',
                },
            }}
            filterMode={'client'}
            sortingMode={'client'}
            loading={props.isLoading}
            checkboxSelectionVisibleOnly
            density={createAdvancedScheduleResourceTableControls.currentTableControl?.density}
            onDensityChange={createAdvancedScheduleResourceTableControls.onDensityChange}
            filterModel={createAdvancedScheduleResourceTableControls.currentTableControl?.filterModel}
            onSortModelChange={createAdvancedScheduleResourceTableControls.onSortModelChange}
            onPaginationModelChange={createAdvancedScheduleResourceTableControls.onPaginationModelChange}
            onFilterModelChange={createAdvancedScheduleResourceTableControls.onFilterModelChange}
            paginationModel={createAdvancedScheduleResourceTableControls.currentTableControl?.paginationModel}
            columnVisibilityModel={createAdvancedScheduleResourceTableControls.currentTableControl?.columnModel}
            onColumnVisibilityModelChange={createAdvancedScheduleResourceTableControls.onColumnVisibilityModelChange}
        />
    );
};

export { CreateAdvancedParkingResourcesTable };
