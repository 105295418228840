import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Grid2 } from '@mui/material';
import { ILogoVariation } from '@vegaplatformui/models';
import {
    CustomFaviconState,
    CustomPrimaryLogoState,
    CustomSecondaryLogoState,
    OrganizationId,
    OrganizationLoading,
    SnackBarOptions,
} from '../../../jotai/atom';
import { ImageUpload } from '../../image-upload';
import { invalidFilenameMessage, isValidFilename, validateFile } from '@vegaplatformui/utils';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationLogoInputProps {
    onPhotoRemove: (orgId: string, variation: ILogoVariation) => void;
    onPhotoUpload: (orgId: string, variation: ILogoVariation, file: File) => void;
    isOrganizationLogoDataLoading: boolean;
}

const OrganizationLogoInput: React.FC<IOrganizationLogoInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { onPhotoRemove, onPhotoUpload } = props;
    const [isOrganizationIDLoading, setIsOrganizationIDLoading] = useAtom(OrganizationLoading);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const organizationId = useAtomValue(OrganizationId);
    const primaryLogoUrl = useAtomValue(CustomPrimaryLogoState).url;
    const secondaryLogoUrl = useAtomValue(CustomSecondaryLogoState).url;
    const faviconUrl = useAtomValue(CustomFaviconState).url;
    const allowedMimeTypes: { [key: string]: string[] } = {
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/vnd': ['.ico'],
        'image/ico': ['.ico'],
        'image/vnd.microsoft.icon': ['.ico'],
        'image/x-icon': ['.ico'],
    };

    const handleLogoRemoval = (variation: ILogoVariation) => {
        onPhotoRemove(organizationId, variation);
    };

    const fileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/ico', 'image/vnd.microsoft.icon', 'image/x-icon'];
    const validateFileType = (type: string) => {
        return fileTypes.includes(type);
    };

    const handleLogoUpload = (files: File[], variation: ILogoVariation) => {
        if (files.length) {
            const logoFile = files[0];
            // Regular Expression: Ensures a single extension
            const isValid = isValidFilename(logoFile.name);

            if (!isValid) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: invalidFilenameMessage,
                });
                return;
            }

            if (!validateFileType(logoFile.type)) {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'The file type is not supported.',
                });
            } else {
                validateFile(logoFile, allowedMimeTypes)
                    .then(() => {
                        onPhotoUpload(organizationId, variation, logoFile);
                    })
                    .catch((error: Error) =>
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 6000 },
                            alertProps: { severity: 'error' },
                            message: error.message,
                        })
                    );
            }
        }
    };

    return (
        <Grid2 width={'100%'} container spacing={2} mb={'40px'}>
            <Grid2 className={cx(classes.ImageGrid)} size={{ md: 12, lg: 'auto', xl: 4 }}>
                <ImageUpload
                    allowedMimeTypes={allowedMimeTypes}
                    isLoading={isOrganizationIDLoading || props.isOrganizationLogoDataLoading}
                    imageSrc={primaryLogoUrl}
                    alt={'Primary Organization Logo'}
                    sizes={{ maxWidth: 200, maxHeight: 200 }}
                    handleUpload={handleLogoUpload}
                    handleRemove={handleLogoRemoval}
                    title={'Primary Logo'}
                    subtitle={'Max dimensions: 200x200'}
                    variation={ILogoVariation.PRIMARY}
                />
            </Grid2>
            <Grid2 className={cx(classes.ImageGrid)} size={{ md: 12, lg: 'auto', xl: 4 }}>
                <ImageUpload
                    allowedMimeTypes={allowedMimeTypes}
                    isLoading={isOrganizationIDLoading || props.isOrganizationLogoDataLoading}
                    imageSrc={secondaryLogoUrl}
                    alt={'Secondary Organization Logo'}
                    sizes={{ maxWidth: 50, maxHeight: 50 }}
                    handleUpload={handleLogoUpload}
                    handleRemove={handleLogoRemoval}
                    title={'Secondary Logo'}
                    subtitle={'Max dimensions: 50x50'}
                    variation={ILogoVariation.SECONDARY}
                />
            </Grid2>
            <Grid2 className={cx(classes.ImageGrid)} size={{ md: 12, lg: 'auto', xl: 4 }}>
                <ImageUpload
                    allowedMimeTypes={allowedMimeTypes}
                    isLoading={isOrganizationIDLoading || props.isOrganizationLogoDataLoading}
                    imageSrc={faviconUrl}
                    alt={'Organization Favicon'}
                    sizes={{ maxWidth: 16, maxHeight: 16 }}
                    handleUpload={handleLogoUpload}
                    handleRemove={handleLogoRemoval}
                    title={'Favicon'}
                    subtitle={'Max dimensions: 16x16'}
                    variation={ILogoVariation.FAVICON}
                ></ImageUpload>
            </Grid2>
        </Grid2>
    );
};

const useStyles = customMakeStyles<IOrganizationLogoInputProps>()((theme, props) => ({
    ImageGrid: { marginBottom: '10px' },
}));

export { OrganizationLogoInput };
