import { HttpClient } from './http-common';
import { ICloudAccountsRequest, ICloudProviderAccount, IDataGridRequest, IGetParkableResourcesResponse, IResource } from '@vegaplatformui/models';
import { GeminiResponse } from '@vegaplatformui/utils';
import Keycloak from 'keycloak-js';
import { GenericAbortSignal } from 'axios';

export class ResourcesApi extends HttpClient {
    protected static classInstance?: ResourcesApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_PUBLIC_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ResourcesApi();
        }

        return this.classInstance;
    }

    public loadResources = (request: IDataGridRequest): GeminiResponse<IResource[]> =>
        this.instance.get(`vegaapi/resources/`, {
            params: {
                filters: JSON.stringify(request.filterModel),
                sorted_by: JSON.stringify(request.sortModel),
                grid_pagination_model: JSON.stringify(request.paginationModel),
            },
        });

    public loadParkableResources = (
        scheduleToEditId: string,
        request: IDataGridRequest,
        bg_id?: string,
        signal?: GenericAbortSignal
    ): GeminiResponse<IGetParkableResourcesResponse> =>
        this.instance.get(`vegaapi/resources/get_parkable_resources/${scheduleToEditId}`, {
            params: bg_id
                ? {
                      business_group_id: bg_id,
                      filters: JSON.stringify(request.filterModel),
                      sorted_by: JSON.stringify(request.sortModel),
                      grid_pagination_model: JSON.stringify(request.paginationModel),
                  }
                : {
                      filters: JSON.stringify(request.filterModel),
                      sorted_by: JSON.stringify(request.sortModel),
                      grid_pagination_model: JSON.stringify(request.paginationModel),
                  },
            signal,
        });

    public loadParkableResourcesWorkaround = (
        request: IDataGridRequest,
        bg_id?: string,
        signal?: GenericAbortSignal
    ): GeminiResponse<IGetParkableResourcesResponse> =>
        this.instance.post(
            `vegaapi/resources/get_parkable_resources/`,
            bg_id
                ? {
                      business_group_id: bg_id,
                      filters: JSON.stringify(request.filterModel),
                      sorted_by: JSON.stringify(request.sortModel),
                      grid_pagination_model: JSON.stringify(request.paginationModel),
                  }
                : {
                      filters: JSON.stringify(request.filterModel),
                      sorted_by: JSON.stringify(request.sortModel),
                      grid_pagination_model: JSON.stringify(request.paginationModel),
                  },
            { signal }
        );
}
