import { fileTypeFromBuffer } from 'file-type';

export const validateFile = async (file: File, allowedMimeTypes: { [key: string]: string[] }) => {
    const buffer = await file.arrayBuffer(); // Convert File to ArrayBuffer
    const detectedType = await fileTypeFromBuffer(new Uint8Array(buffer));
    let inferredMimeType = detectedType ? detectedType.mime : file.type;
    const inferredExtension = detectedType ? detectedType.ext : file.name.split('.').pop()?.toLowerCase();

    // Fallback for text-based formats
    if (!detectedType) {
        const textContent = new TextDecoder().decode(buffer.slice(0, 100)); // Read first 100 bytes
        if (file.type === 'text/plain' || /\.(txt|md|csv|tsv|json)$/i.test(file.name)) {
            inferredMimeType = inferTextMimeType(file.name, textContent);
        } else {
            throw new Error(
                `The file format is not recognized. Please upload a supported file type such as: ${Object.values(allowedMimeTypes).flat().join(', ')}.`
            );
        }
    }

    const declaredMimeType = file.type;

    if (!allowedMimeTypes[inferredMimeType]) {
        // File extension mismatch. Detected: .${inferredExtension}, Expected: ${allowedExtensions.join(', ')}.
        throw new Error(
            `The file type you're trying to upload is not allowed. please upload a valid file such as: ${Object.values(allowedMimeTypes).flat().join(', ')}`
        );
    }

    // Optional: Validate file extension
    const allowedExtensions = allowedMimeTypes[inferredMimeType];
    if (!allowedExtensions.includes(`.${inferredExtension}`)) {
        throw new Error(`The file extension doesn't match the expected file type. Please make sure the file extension is correct.`);
    }

    if (declaredMimeType && declaredMimeType !== inferredMimeType && declaredMimeType !== 'application/octet-stream') {
        throw new Error(`Mismatch detected, there seems to be a problem with the file format. Please upload a valid file with the correct format.`);
    }

    return { ...file, detectedExt: inferredExtension };
};

// Helper function to infer text-based MIME types
const inferTextMimeType = (fileName: string, contentPreview: string) => {
    if (fileName.endsWith('.csv')) return 'text/csv';
    if (fileName.endsWith('.tsv')) return 'text/tab-separated-values';
    if (fileName.endsWith('.json') && contentPreview.trim().startsWith('{')) return 'application/json';
    if (fileName.endsWith('.md')) return 'text/markdown';
    return 'text/plain'; // Default for .txt or unrecognized text files
};
