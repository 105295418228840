import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';

import { ISSORoleMapping, IUserSettingRealmRole } from '@vegaplatformui/models';
import { RoleMappingItem } from '../role-mapping-item';
import { useRecoilValue } from 'recoil';
import { OrganizationId } from '../../../../recoil/atom';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateEditRoleMappingDialogProps {
    roleMappingToEdit?: ISSORoleMapping;
    roleMappings: ISSORoleMapping[];
    isDialogOpen: boolean;
    onCloseDialog: () => void;
    roles: IUserSettingRealmRole[];
    onSaveRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onCreateRoleMapping: (roleMapping: ISSORoleMapping) => void;
}

const CreateEditRoleMappingDialog: React.FC<ICreateEditRoleMappingDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const organizationId = useRecoilValue(OrganizationId);
    const BlankRoleMapping: ISSORoleMapping = {
        roleAssignments: [
            {
                roleId: props.roles.find((role) => role.name === 'read_only') ? props.roles.find((role) => role.name === 'read_only')!.id : '',
                orgId: organizationId,
            },
        ],
        externalGroupName: '',
    };
    const [roleMappingState, setRoleMappingState] = useState<ISSORoleMapping>(props.roleMappingToEdit ?? BlankRoleMapping);
    const [isGroupNameUnique, setIsGroupNameUnique] = useState<boolean>(
        props.roleMappings.filter(
            (roleMappingItem) =>
                roleMappingItem.externalGroupName === roleMappingState.externalGroupName &&
                props.roleMappingToEdit?.externalGroupName !== roleMappingState.externalGroupName
        ).length > 1
    );
    const [isGroupNameEmpty, setIsGroupNameEmpty] = useState<boolean>(
        roleMappingState.externalGroupName === '' || !roleMappingState.externalGroupName.match(/\S{1}/i)
    );
    const [isGroupNameSpaceless, setIsGroupNameSpaceless] = useState<boolean>(!roleMappingState.externalGroupName.match('(.*?)\\s(.*?)'));

    useEffect(() => {
        setIsGroupNameUnique(
            props.roleMappings.filter(
                (roleMappingItem) =>
                    roleMappingItem.externalGroupName === roleMappingState.externalGroupName &&
                    props.roleMappingToEdit?.externalGroupName !== roleMappingState.externalGroupName
            ).length >= 1
        );
        setIsGroupNameEmpty(roleMappingState.externalGroupName === '' || !roleMappingState.externalGroupName.match(/\S{1}/i));
        setIsGroupNameSpaceless(!roleMappingState.externalGroupName.match('(.*?)\\s(.*?)'));
    }, [roleMappingState]);

    useEffect(() => {
        props.roleMappingToEdit && setRoleMappingState(props.roleMappingToEdit);
    }, [props.roleMappingToEdit]);

    const onCloseDialog = () => {
        props.onCloseDialog();
        setTimeout(() => {
            setRoleMappingState(BlankRoleMapping);
        }, 200);
    };

    const onSaveOrCreateRoleMapping = (roleMapping: ISSORoleMapping) => {
        switch (props.roleMappingToEdit) {
            case undefined:
                return props.onCreateRoleMapping(roleMapping);
            default:
                return props.onSaveRoleMapping(roleMapping);
        }
    };

    const onChangeRoleMapping = (roleMapping: ISSORoleMapping) => {
        setRoleMappingState(roleMapping);
    };

    return (
        <Dialog maxWidth={'sm'} fullWidth open={props.isDialogOpen} onClose={onCloseDialog} aria-labelledby='create-edit-role-mapping-dialog'>
            <DialogTitle sx={{ marginLeft: '-0.2rem' }} variant={'h6'} id='create-edit-role-mapping-dialog-title'>
                {props.roleMappingToEdit ? 'Edit' : 'Create'} SSO Role Mapping
            </DialogTitle>
            <DialogContent sx={{ paddingLeft: 2.5 }}>
                <RoleMappingItem
                    roleMapping={roleMappingState}
                    roles={props.roles}
                    isGroupNameUnique={isGroupNameUnique}
                    isGroupNameEmpty={isGroupNameEmpty}
                    isGroupNameSpaceless={isGroupNameSpaceless}
                    onChangeRoleMapping={onChangeRoleMapping}
                />
            </DialogContent>
            <DialogActions className={cx(classes.DialogActions)}>
                <Stack direction='row' spacing={1}>
                    <Button
                        variant={'cancel'}
                        onClick={() => {
                            onCloseDialog();
                        }}
                        disableElevation
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isGroupNameUnique || isGroupNameEmpty || !isGroupNameSpaceless}
                        disableElevation
                        onClick={() => {
                            onSaveOrCreateRoleMapping(roleMappingState);
                            onCloseDialog();
                        }}
                    >
                        {props.roleMappingToEdit ? 'Save' : 'Create'}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<ICreateEditRoleMappingDialogProps>()((theme, props) => ({
    DialogActions: {
        marginRight: '1rem',
        marginBottom: '0.5rem',
    },
    DialogActionsButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 10,
    },
    FormTitle: {
        fontWeight: 600,
        marginTop: '1rem',
        marginBottom: '.5rem',
    },
}));

export { CreateEditRoleMappingDialog };
