import React, { useEffect, useMemo, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Button, Grid2, Stack, TextField } from '@mui/material';
import { NotificationIntegrationTile } from './notification-integration-tile';
import { useTheme } from '@mui/material/styles';
import { FormField } from '../../../forms';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    IDeleteOrganizationIntegrationRequest,
    IOrganizationConfigurationForm,
    IOrganizationNotificationConfiguration,
    IPostOrganizationIntegrationRequest,
    IPutOrganizationIntegrationRequest,
    NotificationChannelName,
    NotificationIntegrationTypeType,
} from '@vegaplatformui/models';
import { FormFieldLoadingSkeleton } from '../../../utilities/form-field-loading-skeleton';
import { MicrosoftTeams, Slack } from '@vegaplatformui/sharedassets';
import { useKeycloak } from '@react-keycloak-fork/web';
import { BlackOnWhiteButton } from '../../../utilities/black-on-white-button';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganizationNotificationInputProps {
    organizationNotificationConfigurations: IOrganizationNotificationConfiguration[];
    areWebhooksLoading: boolean;
    deletingLoadingStates: Record<string, boolean>;
    submittingLoadingStates: Record<string, boolean>;
    updateWebhook: (hookRequest: IPutOrganizationIntegrationRequest) => void;
    createWebhook: (hookRequest: IPostOrganizationIntegrationRequest) => void;
    deleteWebhook: (hookRequest: IDeleteOrganizationIntegrationRequest) => void;
}

const teamsValidationSchema: yup.ObjectSchema<IOrganizationConfigurationForm> = yup.object().shape({
    webhook_url: yup
        .string()
        .required('Webhook URL is required.')
        .url('Make sure the webhook url is a properly formatted url.')
        .matches(/^https?:\/\/([a-zA-Z0-9-]+\.)?webhook\.office\.com(\/|$)/i, 'Teams webhook URL should contain "webhook.office.com" in the domain')
        .required('Webhook URL is required.'),
});

const slackValidationSchema: yup.ObjectSchema<IOrganizationConfigurationForm> = yup.object().shape({
    webhook_url: yup
        .string()
        .required('Webhook URL is required.')
        .url('Make sure the webhook url is a properly formatted url.')
        .matches(/^https?:\/\/hooks\.slack\.com\/services(\/|$)/i, 'Slack webhook URL should contain "hooks.slack.com/services" in the URL')
        .required('Webhook URL is required.'),
});

const OrganizationNotificationInput: React.FC<IOrganizationNotificationInputProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { keycloak } = useKeycloak();
    const {
        organizationNotificationConfigurations,
        deletingLoadingStates,
        submittingLoadingStates,
        deleteWebhook,
        updateWebhook,
        createWebhook,
        areWebhooksLoading,
    } = props;

    const { teamsWebhook, teamsWebhookId } = useMemo(() => {
        let teamsWebhook = '';
        let teamsWebhookId = undefined;

        if (organizationNotificationConfigurations.length > 0) {
            const teamsConfig = organizationNotificationConfigurations.find(
                (config) => config.notification_channel === NotificationChannelName.Teams
            );
            teamsWebhook = teamsConfig?.webhook_url ?? '';
            teamsWebhookId = teamsConfig?.id ?? undefined;
        }
        return { teamsWebhook, teamsWebhookId };
    }, [organizationNotificationConfigurations]);

    const { slackWebhook, slackWebhookId } = useMemo(() => {
        let slackWebhook = '';
        let slackWebhookId = undefined;

        if (organizationNotificationConfigurations.length > 0) {
            const slackConfig = organizationNotificationConfigurations.find(
                (config) => config.notification_channel === NotificationChannelName.Slack
            );
            slackWebhook = slackConfig?.webhook_url ?? '';
            slackWebhookId = slackConfig?.id ?? undefined;
        }
        return { slackWebhook, slackWebhookId };
    }, [organizationNotificationConfigurations]);

    const theme = useTheme();
    const [isTeamsFieldsChanged, setIsTeamsFieldsChanged] = useState(false);
    const [isSlackFieldsChanged, setIsSlackFieldsChanged] = useState(false);

    const onTeamsFieldChange = () => {
        setIsTeamsFieldsChanged(true);
    };

    const onSlackFieldChange = () => {
        setIsSlackFieldsChanged(true);
    };

    const {
        handleSubmit: teamFormsHandleSubmit,
        control: teamsFormControl,
        reset: teamsFormReset,
        formState: { isDirty: isTeamsFormDirty, errors: teamsFormErrors },
    } = useForm<IOrganizationConfigurationForm>({
        resolver: yupResolver(teamsValidationSchema),
        defaultValues: {
            webhook_url: teamsWebhook ?? '',
        },
    });

    const {
        handleSubmit: slackFormsHandleSubmit,
        control: slackFormControl,
        reset: slackFormReset,
        formState: { isDirty: isSlackFormDirty, errors: slackFormErrors },
    } = useForm<IOrganizationConfigurationForm>({
        resolver: yupResolver(slackValidationSchema),
        defaultValues: {
            webhook_url: slackWebhook ?? '',
        },
    });

    useEffect(() => {
        teamsFormReset(
            {
                webhook_url: teamsWebhook,
            },
            { keepDirty: true, keepIsValid: true }
        );
    }, [teamsWebhook, keycloak.realm]);

    useEffect(() => {
        slackFormReset(
            {
                webhook_url: slackWebhook,
            },
            { keepDirty: true, keepIsValid: true }
        );
    }, [slackWebhook, keycloak.realm]);

    const onWebhookDelete = (channel: NotificationChannelName) => {
        switch (channel) {
            case NotificationChannelName.Teams:
                if (teamsWebhookId !== undefined) {
                    deleteWebhook({
                        id: teamsWebhookId,
                        notification_channel: channel,
                    });
                }
                break;
            case NotificationChannelName.Slack:
                if (slackWebhookId !== undefined) {
                    deleteWebhook({
                        id: slackWebhookId,
                        notification_channel: channel,
                    });
                }
                break;
            default:
                break;
        }
    };

    const onTeamsSubmit = (data: IOrganizationConfigurationForm) => {
        setIsTeamsFieldsChanged(false);
        teamsWebhookId
            ? updateWebhook({
                  id: teamsWebhookId,
                  webhook_url: data.webhook_url,
                  is_enabled: true,
                  notification_channel: NotificationChannelName.Teams,
              })
            : createWebhook({
                  notification_channel: NotificationChannelName.Teams,
                  notification_type: NotificationIntegrationTypeType.AnomalyDetected,
                  org_slug: keycloak.realm!,
                  webhook_url: data.webhook_url,
              });
    };

    const onSlackSubmit = (data: IOrganizationConfigurationForm) => {
        setIsSlackFieldsChanged(false);
        slackWebhookId
            ? updateWebhook({
                  id: slackWebhookId,
                  webhook_url: data.webhook_url,
                  is_enabled: true,
                  notification_channel: NotificationChannelName.Slack,
              })
            : createWebhook({
                  notification_channel: NotificationChannelName.Slack,
                  notification_type: NotificationIntegrationTypeType.AnomalyDetected,
                  org_slug: keycloak.realm!,
                  webhook_url: data.webhook_url,
              });
    };

    return (
        <Grid2 className={cx(classes.GridList)} size={12} container spacing={2}>
            <NotificationIntegrationTile
                integrationName={'Microsoft Teams'}
                customIcon={<MicrosoftTeams />}
                children={
                    <Stack
                        onChange={onTeamsFieldChange}
                        onSubmit={teamFormsHandleSubmit((data) => {
                            onTeamsSubmit(data);
                        })}
                        width={'100%'}
                        component={'form'}
                        id={'teams-integration-form'}
                        spacing={1.5}
                    >
                        {areWebhooksLoading ? (
                            <FormFieldLoadingSkeleton formField={'Channel Webhook'} htmlFor={'teams_webhook_url'} />
                        ) : (
                            <FormField label={'Channel Webhook'} htmlFor={'teams_webhook_url'}>
                                <Controller
                                    name={'webhook_url'}
                                    control={teamsFormControl}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id={'teams_webhook_url'}
                                            size='small'
                                            fullWidth={true}
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            error={!!teamsFormErrors.webhook_url}
                                            helperText={teamsFormErrors.webhook_url?.message}
                                        />
                                    )}
                                />
                            </FormField>
                        )}
                        <Stack width={'100%'} height={'100%'} direction={'row'} spacing={1.5} alignItems={'center'} justifyContent={'flex-start'}>
                            <Button
                                loading={submittingLoadingStates[NotificationChannelName.Teams]}
                                loadingPosition={'start'}
                                disabled={!!teamsWebhook && (!isTeamsFormDirty || !isTeamsFieldsChanged)}
                                form={'teams-integration-form'}
                                type={'submit'}
                                variant={'contained'}
                            >
                                Save
                            </Button>
                            {teamsWebhookId !== undefined && (
                                <BlackOnWhiteButton
                                    loading={deletingLoadingStates[NotificationChannelName.Teams]}
                                    loadingPosition={'start'}
                                    disabled={!teamsWebhookId}
                                    onClick={() => {
                                        onWebhookDelete(NotificationChannelName.Teams);
                                    }}
                                >
                                    Delete
                                </BlackOnWhiteButton>
                            )}
                        </Stack>
                    </Stack>
                }
            />
            <NotificationIntegrationTile
                integrationName={'Slack'}
                customIcon={<Slack />}
                children={
                    <Stack
                        onChange={onSlackFieldChange}
                        onSubmit={slackFormsHandleSubmit((data) => {
                            onSlackSubmit(data);
                        })}
                        width={'100%'}
                        component={'form'}
                        id={'slack-integration-form'}
                        spacing={1.5}
                    >
                        {areWebhooksLoading ? (
                            <FormFieldLoadingSkeleton formField={'Incoming Webhook'} htmlFor={'slack_webhook_url'} />
                        ) : (
                            <FormField label={'Incoming Webhook'} htmlFor={'slack_webhook_url'}>
                                <Controller
                                    name={'webhook_url'}
                                    control={slackFormControl}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id={'slack_webhook_url'}
                                            size='small'
                                            fullWidth={true}
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            error={!!slackFormErrors.webhook_url}
                                            helperText={slackFormErrors.webhook_url?.message}
                                        />
                                    )}
                                />
                            </FormField>
                        )}
                        <Stack width={'100%'} height={'100%'} direction={'row'} spacing={1.5} alignItems={'center'} justifyContent={'flex-start'}>
                            <Button
                                loading={submittingLoadingStates[NotificationChannelName.Slack]}
                                loadingPosition={'start'}
                                disabled={!!slackWebhook && (!isSlackFormDirty || !isSlackFieldsChanged)}
                                form={'slack-integration-form'}
                                type={'submit'}
                                variant={'contained'}
                            >
                                Save
                            </Button>
                            {slackWebhookId !== undefined && (
                                <BlackOnWhiteButton
                                    loading={deletingLoadingStates[NotificationChannelName.Slack]}
                                    loadingPosition={'start'}
                                    disabled={!slackWebhookId}
                                    onClick={() => {
                                        onWebhookDelete(NotificationChannelName.Slack);
                                    }}
                                >
                                    Delete
                                </BlackOnWhiteButton>
                            )}
                        </Stack>
                    </Stack>
                }
            />
        </Grid2>
    );
};

const useStyles = customMakeStyles<IOrganizationNotificationInputProps>()((theme, props) => ({
    SlackNotice: { border: '1px solid  #D0D5DD', borderRadius: '8px', padding: '0.5rem' },
    GridList: {
        padding: '0rem 1rem 1rem 1rem',
    },
    AlertColor: { color: theme.palette.action.active },
}));

export { OrganizationNotificationInput };
