import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { IUser, IUserSettingRealmRole, PermissionMetadata } from '@vegaplatformui/models';
import { useKeycloak } from '@react-keycloak-fork/web';
import React, { useMemo, useState } from 'react';
import { UserSettingApi } from '@vegaplatformui/apis';
import { queryKeys } from './query-keys';

export interface IUsePermissionAndRolesApiHook {
    realmRoles: IUserSettingRealmRole[];
    areRealmRolesLoading: boolean;
    realmRole: IUserSettingRealmRole | undefined;
    isRealmRoleLoading: boolean;
    setRoleId: React.Dispatch<React.SetStateAction<string | undefined>>;
    members: IUser[];
    permissionSets: Map<string, PermissionMetadata[]>;
    rolePermissions: string[];
}

export interface IUsePermissionAndRolesApiProps {
    users: IUser[];
}

export function usePermissionAndRolesApi(props: IUsePermissionAndRolesApiProps): IUsePermissionAndRolesApiHook {
    const { keycloak } = useKeycloak();
    const [roleId, setRoleId] = useState<string | undefined>(undefined);
    const queryClient = useQueryClient();

    const userSettingApi = useMemo(() => {
        const apiInstance = new UserSettingApi();
        apiInstance.keycloak = keycloak;
        return apiInstance;
    }, [keycloak]);

    const { data: realmRoles, isLoading: areRealmRolesLoading } = useQuery({
        queryKey: [queryKeys.permissionAndRoles.getRealmRoles, keycloak.realm],
        queryFn: async ({ signal }) => {
            if (keycloak.realm !== undefined) {
                await queryClient.cancelQueries({
                    queryKey: [queryKeys.permissionAndRoles.getRealmRoles],
                    exact: false,
                });
                const response = await userSettingApi.getRealmRoles(keycloak.realm, signal);
                return response.data;
            }
        },
        meta: {
            errorMessage: 'Failed to retrieve realm roles.',
        },
        enabled: keycloak.realm !== undefined,
        placeholderData: keepPreviousData,
    });

    const { data: realmRole, isLoading: isRealmRoleLoading } = useQuery({
        queryKey: [queryKeys.permissionAndRoles.getRealmRole, roleId],
        queryFn: async () => {
            if (roleId !== undefined && realmRoles !== undefined && realmRoles.length > 0) {
                await queryClient.cancelQueries({
                    queryKey: [queryKeys.permissionAndRoles.getRealmRole],
                    exact: false,
                });
                return realmRoles.find((r) => r.id === roleId);
            }
        },
        meta: {
            errorMessage: 'Failed to retrieve realm role details.',
        },
        enabled: roleId !== undefined && realmRoles !== undefined && realmRoles.length > 0,
    });

    const members = useMemo(() => {
        if (realmRole) {
            return (
                props.users.filter((user: IUser) => {
                    return user.platform_roles && user.platform_roles.includes(realmRole.name);
                }) ?? []
            );
        }
        return [];
    }, [props.users, realmRole]);

    const rolePermissions = useMemo(() => {
        if (realmRole) {
            return realmRole.permissions;
        }
        return [];
    }, [realmRole]);

    const getActionAndTarget = (permission: string) => {
        // Find the index of the first underscore
        const firstUnderscoreIndex = permission.indexOf('_');
        // Grab the action
        const action = permission.slice(0, firstUnderscoreIndex);
        // Grab the target
        const target = permission.slice(firstUnderscoreIndex + 1);
        return { action, target };
    };

    const permissionSets = useMemo(() => {
        const permissionMap = new Map<string, PermissionMetadata[]>([]);
        rolePermissions.forEach((permission) => {
            const { action, target } = getActionAndTarget(permission);

            // If the key (the target) already exists, push the object to the array
            if (permissionMap && permissionMap.has(target)) {
                permissionMap.get(target)?.push({ action, permission });
            } else {
                // Otherwise, create a new array with the object
                permissionMap.set(target, [{ action, permission }]);
            }
        });
        return permissionMap;
    }, [rolePermissions]);

    return {
        realmRoles: realmRoles ?? [],
        areRealmRolesLoading,
        realmRole,
        isRealmRoleLoading,
        setRoleId,
        members: members,
        permissionSets,
        rolePermissions,
    };
}

export default usePermissionAndRolesApi;
