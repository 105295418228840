import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { Button, IconButton, PaletteMode, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { Article, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, MenuBook } from '@mui/icons-material';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { closedDrawerWidth, openDrawerWidth } from '../constants/constants';
import { CustomAppbarHelpMenu } from './custom-appbar-help-menu';
import { IsDashboardView, MenuItems, ShowUpdatedUi } from '../jotai/atom';
import { CustomAppbarProfileMenu } from './custom-appbar-profile-menu';
import { selectNewMenuItem, useMenuItems, useRouteUrls } from '@vegaplatformui/utils';
import { useNavigate } from 'react-router';
import { CustomAppbarSettingsMenu } from './custom-appbar-settings-menu';
import { AppbarUrlNavigator } from './appbar-url-navigator';
import { ToggleUpdatedUi } from '../toggle-updated-ui/toggle-updated-ui';
import { ShareButton } from '../shared-reports/share-button/share-button';
import { CustomSnackBarOptions } from '../custom-snackbar/custom-snackbar';
import { useAtomValue, useSetAtom } from 'jotai';

export interface ICustomAppBarProps {
    selectedMenuItem: GeminiMenuItem | undefined;
    menuItems: GeminiMenuItem[];
    themeState: PaletteMode;
    isDrawerOpen: boolean;
    setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    profilePhotoUrl: string;
    setAuthenticated: (update: boolean | ((prev: boolean) => boolean)) => void;
    logoutUrl: string;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.background.default,
    boxShadow: 'none',

    ...(open
        ? {
              marginLeft: openDrawerWidth,
              width: `calc(100% - ${openDrawerWidth}px)`,
              transition: theme.transitions.create(['all'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
              }),
          }
        : {
              marginLeft: closedDrawerWidth,
              width: `calc(100% - ${closedDrawerWidth}px)`,
              transition: theme.transitions.create(['all'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
              }),
          }),
}));

const NavToolBar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const CircularIconButton = styled(IconButton)(({ theme }) => ({
    height: '1.75rem',
    width: '1.75rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: '16px',
    boxShadow: theme.shadows[2],
    '&:hover': {
        backgroundColor: theme.palette.grey.A200,
    },
}));

const CustomAppBar: React.FC<ICustomAppBarProps> = (props) => {
    const theme = useTheme();
    const { cx, classes } = useStyles(props);
    const flags = useFlags();
    const { routeUrls } = useRouteUrls({});
    const { menuItems } = useMenuItems({});
    const navigate = useNavigate();
    const setSidebarMenuItems = useSetAtom(MenuItems);
    const showUpdatedUi = useAtomValue(ShowUpdatedUi);
    const isDashboardView = useAtomValue(IsDashboardView);

    const handleDrawer = () => {
        props.setIsDrawerOpen(!props.isDrawerOpen);
    };
    const onNavigateAppBarButton = (menuItem: GeminiMenuItem, tabId?: string) => {
        localStorage.removeItem('previous_route');
        selectNewMenuItem(menuItems, menuItem, setSidebarMenuItems);
        tabId ? menuItem.route && navigate(`${menuItem.route}/${tabId}`) : menuItem.route && navigate(menuItem.route);
    };

    return (
        <AppBar position='fixed' open={props.isDrawerOpen}>
            <NavToolBar className={cx(classes.NavToolBar)}>
                <Stack alignItems={'center'} spacing={1} direction={'row'} className={cx(classes.SidebarControl)}>
                    <CircularIconButton onClick={handleDrawer} title={'toggle side bar'}>
                        {props.isDrawerOpen ? (
                            <StyledToolTip key={'minimize'} arrow={true} title={'Minimize sidebar'}>
                                <KeyboardDoubleArrowLeft className={cx(classes.IconFillBlack)} />
                            </StyledToolTip>
                        ) : (
                            <StyledToolTip key={'expand'} arrow={true} title={'Expand sidebar'}>
                                <KeyboardDoubleArrowRight className={cx(classes.IconFillBlack)} />
                            </StyledToolTip>
                        )}
                    </CircularIconButton>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1.5}>
                        <Stack alignItems={'center'} spacing={1} direction={'row'}>
                            {props.selectedMenuItem && props.selectedMenuItem.icon && (
                                <props.selectedMenuItem.icon data-testid={'page-icon'} className={cx(classes.IconFillBlack)} />
                            )}
                            <Typography data-testid={'page-title'} variant={'h6'} className={cx(classes.LocationText)}>
                                {props.selectedMenuItem && props.selectedMenuItem.title}
                            </Typography>
                        </Stack>
                        {flags.isUiPreviewOn ? <ToggleUpdatedUi /> : undefined}
                    </Stack>
                </Stack>
                <Stack width={'50%'} direction={'row'} spacing={1} alignItems='center' justifyContent={'flex-end'}>
                    {flags.portalNavigationDropdown && <AppbarUrlNavigator />}
                    {flags.sharedReports && isDashboardView && <ShareButton />}
                    {showUpdatedUi
                        ? [
                              <CustomAppbarSettingsMenu
                                  key={'settings-menu'}
                                  selectedMenuItem={props.selectedMenuItem}
                                  onNavigateAppBarButton={onNavigateAppBarButton}
                              />,
                              <CustomAppbarHelpMenu key={'help-menu'} selectedMenuItem={props.selectedMenuItem} />,
                              <CustomAppbarProfileMenu
                                  key={'profile-menu'}
                                  selectedMenuItem={props.selectedMenuItem}
                                  logoutUrl={props.logoutUrl}
                                  setAuthenticated={props.setAuthenticated}
                                  photoUrl={props.profilePhotoUrl}
                                  onNavigateAppBarButton={onNavigateAppBarButton}
                              />,
                          ]
                        : [
                              <div role={'presentation'} key={'release-notes'}>
                                  <StyledToolTip arrow={true} title={'Release notes'}>
                                      <IconButton
                                          key={'release notes'}
                                          href={'https://docs.vegacloud.io/docs/releasenotes'}
                                          target='_blank'
                                          rel='noreferrer noopener'
                                      >
                                          <Article />
                                      </IconButton>
                                  </StyledToolTip>
                              </div>,
                              <div role={'presentation'} key={'vega-docs'}>
                                  <StyledToolTip arrow={true} title={'Vega documentation'}>
                                      <IconButton
                                          key={'documentation'}
                                          href={props.selectedMenuItem?.documentationLink ?? ''}
                                          target='_blank'
                                          rel='noreferrer noopener'
                                      >
                                          <MenuBook />
                                      </IconButton>
                                  </StyledToolTip>
                              </div>,
                          ]}
                </Stack>
            </NavToolBar>
        </AppBar>
    );
};

const useStyles = customMakeStyles<ICustomAppBarProps>()((theme, props) => ({
    SidebarControl: { marginLeft: '-2.5rem' },
    IconFillBlack: {
        fill: props.themeState === 'light' ? theme.palette.text.primary : theme.palette.text.secondary,
    },
    LocationText: { color: props.themeState === 'light' ? theme.palette.text.primary : theme.palette.text.secondary },
    TitleText: {
        marginTop: '-4.5rem',
    },
    NavToolBar: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : 'rgba(0,0,0,0)',
    },
}));

export { CustomAppBar };
