import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import ReactJson, { OnCopyProps } from '@microlink/react-json-view';
import { Close } from '@mui/icons-material';
import { customMakeStyles } from '@vegaplatformui/styling';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IJsonViewDialogProps {
    isDialogOpen: boolean;
    onCloseDialog: () => void;
    json: string;
    title: string;
}

const JsonViewDialog: React.FC<IJsonViewDialogProps> = (props) => {
    const { classes, cx } = useStyles(props);

    const handleCopy = (copyProps: OnCopyProps) => {
        navigator.clipboard.writeText(JSON.stringify(copyProps.src, null, '\t'));
    };

    return (
        <Dialog className={cx(classes.Dialog)} fullWidth maxWidth={'md'} open={props.isDialogOpen} onClose={props.onCloseDialog}>
            <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid className={cx(classes.GridContainer)} xs={11} item>
                    <Typography variant={'h6'}>{props.title}</Typography>
                </Grid>
                <Grid xs={1} item className={cx(classes.CancelButton)}>
                    <IconButton size={'small'} onClick={props.onCloseDialog}>
                        <Close fontSize={'small'} />
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                <ReactJson enableClipboard={handleCopy} src={JSON.parse(props.json)} />
            </DialogContent>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IJsonViewDialogProps>()((theme, props) => ({
    GridContainer: { marginTop: '1rem', marginLeft: '1rem' },
    CancelButton: { marginRight: '-1.5rem' },
    Dialog: {
        '.MuiPaper-root': {
            overflow: 'hidden',
        },
    },
}));

export { JsonViewDialog };
