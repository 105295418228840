import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { GeminiMenuItem } from '@vegaplatformui/sharedassets';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Link, Menu, MenuItem, ToggleButton } from '@mui/material';
import { Help } from '@mui/icons-material';
import { StyledToolTip } from '../utilities/styled-tooltip';
import { useSetRecoilState } from 'recoil';
import { ShowSupportForm } from '../recoil/atom';
import { SupportEmailRecipient } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICustomAppbarHelpMenuProps {
    selectedMenuItem: GeminiMenuItem;
}

const CustomAppbarHelpMenu: React.FC<ICustomAppbarHelpMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setShowSupportForm = useSetRecoilState(ShowSupportForm);

    return (
        <PopupState variant='popover' popupId={'help-menu-popover'} parentPopupState={undefined} disableAutoFocus={undefined}>
            {(popupState) => (
                <>
                    <StyledToolTip arrow={true} title={'Help'}>
                        <ToggleButton
                            className={cx(classes.ToggleButton)}
                            value={'Help-Menu'}
                            size={'small'}
                            aria-label={'open help menu'}
                            selected={popupState.isOpen}
                            aria-owns={popupState.isOpen ? 'help-menu' : undefined}
                            {...bindTrigger(popupState)}
                        >
                            <Help />
                        </ToggleButton>
                    </StyledToolTip>
                    <Menu
                        id={'help-menu'}
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem
                            component={Link}
                            onClick={() => {
                                popupState.close();
                                setShowSupportForm({ showSupportForm: true, contactType: SupportEmailRecipient.Support });
                            }}
                        >
                            Contact Us
                        </MenuItem>
                        <MenuItem
                            href={props.selectedMenuItem?.documentationLink ?? ''}
                            component={Link}
                            target={'_blank'}
                            rel={'noreferrer noopener'}
                            onClick={() => {
                                popupState.close();
                            }}
                        >
                            Documentation
                        </MenuItem>
                        <MenuItem
                            href={'https://docs.vegacloud.io/docs/releasenotes'}
                            component={Link}
                            target={'_blank'}
                            rel={'noreferrer noopener'}
                            onClick={() => {
                                popupState.close();
                            }}
                        >
                            Release Notes
                        </MenuItem>
                    </Menu>
                </>
            )}
        </PopupState>
    );
};

const useStyles = customMakeStyles<ICustomAppbarHelpMenuProps>()((theme, props) => ({
    ToggleButton: {
        border: 'none',
        borderRadius: '10px',
        '&:hover': { backgroundColor: `${theme.palette.primary.main}30` },
        '&.Mui-selected': { backgroundColor: `${theme.palette.primary.main}60`, color: theme.palette.primary.main },
    },
}));

export { CustomAppbarHelpMenu };
