import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ISSORoleMapping, IUserSettingRealmRole } from '@vegaplatformui/models';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { ConfirmDeleteSsoRoleMappingDialog } from './sso-role-mapping-dialogs/confirm-delete-sso-role-mapping-dialog';
import { CreateEditRoleMappingDialog } from './sso-role-mapping-dialogs/create-edit-role-mapping-dialog';
import { RoleMappingTable } from './role-mapping-table';
import { Add } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoRoleMappingCardProps {
    roleMappings: ISSORoleMapping[];
    roles: IUserSettingRealmRole[];
    onSaveRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onCreateRoleMapping: (roleMapping: ISSORoleMapping) => void;
    onDeleteRoleMapping: (roleMapping: ISSORoleMapping) => void;
    isRoleMappingsLoading: boolean;
}

const RoleMappingCard: React.FC<ISsoRoleMappingCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState<boolean>(false);
    const BlankRoleMapping: ISSORoleMapping = { roleAssignments: [], externalGroupName: '' };
    const [roleMappingToEdit, setRoleMappingToEdit] = useState<ISSORoleMapping | undefined>(undefined);
    const [roleMappingToDelete, setRoleMappingToDelete] = useState<ISSORoleMapping>(BlankRoleMapping);

    //TODO Keeping this in to show how we could always focus the scroll bar to the bottom as items get added
    // When adding or removing an item always go to the bottom of the list
    // useEffect(() => {
    //     const textarea = document.getElementById('role_mapping_items');
    //     textarea && (textarea.scrollTop = textarea.scrollHeight);
    //     if (props.roleMappings.length < 2) {
    //         setIsDeleteEnabled(false);
    //     } else {
    //         setIsDeleteEnabled(true);
    //     }
    // }, [props.roleMappings.length]);

    const onClickEditRoleMapping = (roleMapping: ISSORoleMapping) => {
        setRoleMappingToEdit(roleMapping);
        setIsCreateDialogOpen(true);
    };

    const onOpenDeleteRoleMappingDialog = (roleMapping: ISSORoleMapping) => {
        setRoleMappingToDelete(roleMapping);
        setIsConfirmDeleteDialogOpen(true);
    };

    const onCloseCreateEditRoleMappingDialog = () => {
        setIsCreateDialogOpen(false);
        setTimeout(() => {
            setRoleMappingToEdit(undefined);
        }, 200);
    };

    const onCloseConfirmDeleteDialog = () => {
        setIsConfirmDeleteDialogOpen(false);
        setTimeout(() => {
            setRoleMappingToDelete(BlankRoleMapping);
        }, 200);
    };

    return (
        <>
            <CreateEditRoleMappingDialog
                roleMappings={props.roleMappings}
                isDialogOpen={isCreateDialogOpen}
                roleMappingToEdit={roleMappingToEdit}
                onCloseDialog={onCloseCreateEditRoleMappingDialog}
                roles={props.roles}
                onSaveRoleMapping={props.onSaveRoleMapping}
                onCreateRoleMapping={props.onCreateRoleMapping}
            />
            <ConfirmDeleteSsoRoleMappingDialog
                roleMappingToDelete={roleMappingToDelete}
                isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
                onCloseConfirmDeleteDialog={onCloseConfirmDeleteDialog}
                confirmDeleteSchedule={props.onDeleteRoleMapping}
            />
            <Card elevation={0}>
                <CardContent>
                    <Stack direction={'column'} spacing={3}>
                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                            <Stack spacing={0.5}>
                                <Typography variant='h3'>Role Mappings</Typography>
                                <Typography variant='body2'>
                                    Map IdP groups with one or more Vega Platform roles to streamline authorization.
                                </Typography>
                            </Stack>
                            <Button
                                disableElevation
                                startIcon={<Add />}
                                className={cx(classes.CardUpdateButton)}
                                onClick={() => {
                                    setIsCreateDialogOpen(true);
                                }}
                            >
                                Role Mapping
                            </Button>
                        </Stack>
                        <RoleMappingTable
                            roleMappings={props.roleMappings}
                            roles={props.roles}
                            isRoleMappingsLoading={props.isRoleMappingsLoading}
                            onOpenDeleteRoleMappingDialog={onOpenDeleteRoleMappingDialog}
                            onClickEditRoleMapping={onClickEditRoleMapping}
                        />
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
};

const useStyles = customMakeStyles<ISsoRoleMappingCardProps>()((theme, props) => ({
    CardContent: { marginLeft: '0.5rem', maxHeight: '15rem', overflowY: 'auto', overflowX: 'hidden' },
    CardActions: { marginRight: '2rem' },
    CardUpdateButton: { marginRight: '1.5rem' },
    AddItemButton: { marginTop: '0.5rem', marginLeft: '1rem' },
    GroupNameHeaderMargin: { marginLeft: '0.6rem' },
    RoleHeaderMargin: { marginLeft: '0rem' },
}));

export { RoleMappingCard };
