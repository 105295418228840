import React, { useState } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Button, Card, CardContent, Grid2, Stack, Typography } from '@mui/material';
import { FileDropZone } from '../file-drop-zone/file-drop-zone';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFileTransferCardProps {
    selectedFiles: File[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
    onClickUploadFile: (data: File[]) => void;
    isLoading: boolean;
}

const FileTransferCard: React.FC<IFileTransferCardProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const allowedMimeTypes: { [key: string]: string[] } = {
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'text/csv': ['.csv'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'text/plain': ['.txt'],
        'application/json': ['.json'],
        'text/tab-separated-values': ['.tsv'],
        'text/markdown': ['.md'],
        'application/vnd.ms-publisher': ['.pub'],
    };

    return (
        <Card elevation={0}>
            <CardContent>
                <Grid2 container direction={'column'}>
                    <Grid2 size={12} justifyContent={'space-between'}>
                        <Grid2>
                            <Typography variant={'h1'}>To Vega</Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={12} justifyContent={'space-between'}>
                        <Grid2>
                            <Typography variant={'subtitle1'} className={commonStyles.classes.SubTitle}>
                                Upload your files to Vega analysts.
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={12} justifyContent={'space-between'}>
                        <Stack direction='column' justifyContent='center' alignItems='center' spacing={2}>
                            <FileDropZone
                                allowedMimeTypes={allowedMimeTypes}
                                setSelectedFiles={props.setSelectedFiles}
                                selectedFiles={props.selectedFiles}
                                isLoading={props.isLoading}
                                inputOptions={{
                                    maxSize: 512 * 1024 * 1024,
                                    minSize: 1,
                                    maxFiles: 1,
                                    multiple: false,
                                    accept: allowedMimeTypes,
                                }}
                                acceptedFileList={[
                                    'xls',
                                    'xlsx',
                                    'csv',
                                    'doc',
                                    'docx',
                                    'pdf',
                                    'jpg',
                                    'jpeg',
                                    'png',
                                    'gif',
                                    'txt',
                                    'json',
                                    'tsv',
                                    'md',
                                    'pub',
                                ]}
                                onClickRemoveFile={() => props.setSelectedFiles([])}
                            />
                            {props.selectedFiles.length > 0 && (
                                <Button
                                    variant={'contained'}
                                    disableElevation
                                    color={'primary'}
                                    disabled={props.isLoading}
                                    onClick={() => {
                                        props.onClickUploadFile(props.selectedFiles);
                                    }}
                                >
                                    <Typography>Upload Files</Typography>
                                </Button>
                            )}
                        </Stack>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    );
};

const useStyles = customMakeStyles<IFileTransferCardProps>()((theme, props) => ({}));

export { FileTransferCard };
