import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { VegaCowOnRocket } from '@vegaplatformui/sharedassets';
import { Grid, LinearProgress, Typography } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinearIndeterminateLoadingProps {}

const LinearIndeterminateLoading: React.FC<ILinearIndeterminateLoadingProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <Grid container direction='column' justifyContent='space-between' alignItems='center' spacing={30}>
            <Grid className={cx(classes.LoadingGrid)} item xs={12}>
                <LinearProgress variant={'indeterminate'} />
            </Grid>
            <Grid xs={12} item container direction='column' justifyContent='center' alignItems='center' wrap='nowrap' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5'>Please wait a moment.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <VegaCowOnRocket className={cx(classes.VCow)} />
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = customMakeStyles<ILinearIndeterminateLoadingProps>()((theme, props) => ({
    VCow: { width: '100%' },
    ContentGrid: {},
    LoadingGrid: { width: '100%' },
    ProgressText: { color: theme.palette.text.secondary },
}));

export { LinearIndeterminateLoading };
