import React, { useMemo } from 'react';
import { customMakeStyles, useCommonStyles } from '@vegaplatformui/styling';
import { Divider, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { IParkingSchedule, IParkingScheduleSummary, ParkingScheduleType } from '@vegaplatformui/models';
import { useSetRecoilState } from 'recoil';
import { IsEditingExemption } from '../recoil/atom';
import { MenuItemCategory } from '../utilities/menu-item-category';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IParkingScheduleActionMenuProps {
    anchorEl: HTMLElement | null;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    selectedSchedules: IParkingScheduleSummary[];
    scheduleSummary?: IParkingScheduleSummary;
    onClickAction?: (schedule: IParkingScheduleSummary, scheduleType?: ParkingScheduleType) => void;
    onClickOpenDeleteDialog?: (schedule?: IParkingScheduleSummary) => void;
    onOpenEnableDisableSchedulesDialog?: (schedules: IParkingScheduleSummary[] | undefined, isScheduledChangeStatus: boolean) => void;
    onChangeSingleScheduleStatus?: (schedule: IParkingSchedule, status: boolean) => void;
    onClickOpenDeleteExemptionDialog?: (schedule: IParkingScheduleSummary) => void;
    onOpenRemoveAdvancedParkingDialog?: (schedule: any) => void;
}

const ParkingScheduleActionMenu: React.FC<IParkingScheduleActionMenuProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const commonStyles = useCommonStyles();
    const setIsEditingExemption = useSetRecoilState(IsEditingExemption);
    const doesScheduleListContainExemptions = useMemo(() => {
        return props.selectedSchedules.filter((schedule) => !!schedule.exemption).length > 0;
    }, [props.selectedSchedules]);

    const handleClose = () => {
        props.setAnchorEl(null);
    };

    return (
        <Menu
            id='schedule-table-menu'
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <MenuItemCategory categoryTitle={'Schedule'} />
            {props.selectedSchedules?.length <= 1 && props.scheduleSummary && (
                <MenuItem
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        props.scheduleSummary && props.onClickAction && props.onClickAction(props.scheduleSummary, props.scheduleSummary.type_str);
                        handleClose();
                    }}
                >
                    Details
                </MenuItem>
            )}
            {props.selectedSchedules?.length <= 1 && props.scheduleSummary && (
                <MenuItem
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        switch (props.scheduleSummary?.type_str) {
                            case ParkingScheduleType.Basic:
                                handleClose();
                                return props.onClickAction && props.onClickAction(props.scheduleSummary, ParkingScheduleType.Advanced);
                            case ParkingScheduleType.Advanced:
                                handleClose();
                                return props.onOpenRemoveAdvancedParkingDialog && props.onOpenRemoveAdvancedParkingDialog(props.scheduleSummary);
                        }
                    }}
                >
                    {props.scheduleSummary.type_str === ParkingScheduleType.Advanced ? 'Remove' : 'Add'} Advanced Scheduling
                </MenuItem>
            )}
            {
                <MenuItem
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        handleClose();
                        return props.onOpenEnableDisableSchedulesDialog && props.scheduleSummary
                            ? props.onOpenEnableDisableSchedulesDialog([props.scheduleSummary], false)
                            : props.onOpenEnableDisableSchedulesDialog &&
                                  props.selectedSchedules &&
                                  props.onOpenEnableDisableSchedulesDialog(props.selectedSchedules, false);
                    }}
                >
                    {props.scheduleSummary && !props.scheduleSummary.is_enabled
                        ? 'Set to Active'
                        : !props.scheduleSummary && props.selectedSchedules.length > 1
                          ? 'Toggle Status'
                          : 'Set to Inactive'}
                </MenuItem>
            }
            {props.onClickOpenDeleteDialog && (
                <MenuItem
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        props.onClickOpenDeleteDialog && props.scheduleSummary
                            ? props.onClickOpenDeleteDialog(props.scheduleSummary)
                            : props.onClickOpenDeleteDialog && props.onClickOpenDeleteDialog();
                        handleClose();
                    }}
                >
                    {`Delete`}
                </MenuItem>
            )}
            <MenuItemCategory categoryTitle={'Disable Between'} />
            {props.scheduleSummary && props.scheduleSummary.exemption ? (
                <MenuItem
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        handleClose();
                        setIsEditingExemption(true);
                        return (
                            props.onOpenEnableDisableSchedulesDialog &&
                            props.scheduleSummary &&
                            props.onOpenEnableDisableSchedulesDialog([props.scheduleSummary], true)
                        );
                    }}
                >
                    {/*Toggle status between*/}
                    Edit
                </MenuItem>
            ) : doesScheduleListContainExemptions ? (
                <Tooltip title={doesScheduleListContainExemptions ? 'Selection contains schedules with exemptions.' : ''} placement={'left'} arrow>
                    <div>
                        <MenuItem
                            aria-disabled={doesScheduleListContainExemptions}
                            tabIndex={0}
                            className={commonStyles.classes.MenuItemWithCategorySpacing}
                            onClick={(event) => {
                                handleClose();
                                setIsEditingExemption(false);
                                return props.onOpenEnableDisableSchedulesDialog && props.scheduleSummary
                                    ? props.onOpenEnableDisableSchedulesDialog([props.scheduleSummary], true)
                                    : props.onOpenEnableDisableSchedulesDialog &&
                                          props.selectedSchedules &&
                                          props.onOpenEnableDisableSchedulesDialog(props.selectedSchedules, true);
                            }}
                            disabled={doesScheduleListContainExemptions}
                        >
                            Create
                        </MenuItem>
                    </div>
                </Tooltip>
            ) : (
                <MenuItem
                    aria-disabled={doesScheduleListContainExemptions}
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        handleClose();
                        setIsEditingExemption(false);
                        return props.onOpenEnableDisableSchedulesDialog && props.scheduleSummary
                            ? props.onOpenEnableDisableSchedulesDialog([props.scheduleSummary], true)
                            : props.onOpenEnableDisableSchedulesDialog &&
                                  props.selectedSchedules &&
                                  props.onOpenEnableDisableSchedulesDialog(props.selectedSchedules, true);
                    }}
                    disabled={doesScheduleListContainExemptions}
                >
                    Create
                </MenuItem>
            )}
            {props.onClickOpenDeleteExemptionDialog && props.scheduleSummary && props.scheduleSummary.exemption && (
                <MenuItem
                    tabIndex={0}
                    className={commonStyles.classes.MenuItemWithCategorySpacing}
                    onClick={(event) => {
                        handleClose();
                        return (
                            props.onClickOpenDeleteExemptionDialog &&
                            props.scheduleSummary &&
                            props.onClickOpenDeleteExemptionDialog(props.scheduleSummary)
                        );
                    }}
                >
                    Delete
                </MenuItem>
            )}
        </Menu>
    );
};

const useStyles = customMakeStyles<IParkingScheduleActionMenuProps>()((theme, props) => ({}));

export { ParkingScheduleActionMenu };
