import { useKeycloak } from '@react-keycloak-fork/web';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { DiscoveryDetails, SnackBarOptions } from '../recoil/atom';
import { DiscoveryEvents, IDiscoveryResponse } from '@vegaplatformui/models';
import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';

export interface IUseWebsocketApiHook {
    sendJsonMessage: SendJsonMessage;
}
export interface IUseWebsocketApiProps {
    loadData?: () => void;
}

export function useWebsocketApi(props: IUseWebsocketApiProps): IUseWebsocketApiHook {
    const { keycloak } = useKeycloak();
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);
    const [discoveryDetails, setDiscoveryDetails] = useRecoilState(DiscoveryDetails);
    const defaultWebsocketUrl = process.env.NX_WEBSOCKET_URL!;

    useEffect(() => {
        switch (keycloak.token) {
            case undefined:
                return setDiscoveryDetails({ ...discoveryDetails, shouldConnect: false });
            default: {
                return setDiscoveryDetails({ ...discoveryDetails, shouldConnect: true });
            }
        }
    }, [keycloak.token]);

    //Not used since the websocket is always connected
    //const [socketUrl, setSocketUrl] = useState(defaultWebsocketUrl);
    //Not used since the websocket is always connected
    // const getSocketUrl = useCallback(() => {
    //     return new Promise((resolve: (value: string) => void) => {
    //         resolve(socketUrl);
    //     });
    // }, [socketUrl]);

    const { sendJsonMessage } = useWebSocket(
        defaultWebsocketUrl,
        {
            // protocols: ['Authorization', `${keycloak.token}`],
            queryParams: {
                token: (defaultWebsocketUrl === process.env.NX_WEBSOCKET_URL) !== undefined ? `${keycloak.token}` : '',
                //client_id: localStorage.getItem('websocket_client_id') ?? 'null',
            }, //retryOnError: false,
            share: true,
            onOpen: (event) => {
                // const clientId = localStorage.getItem('websocket_client_id') ?? null;
                // sendJsonMessage({ event: DiscoveryEvents.NewConnection, data: { client_id: clientId } });
            },
            onMessage: (event) => {
                const data: IDiscoveryResponse = JSON.parse(event.data);
                switch (data.event) {
                    case DiscoveryEvents.ClientDisconnected:
                        setDiscoveryDetails({
                            ...discoveryDetails,
                        });
                        //Hiding the message for client disconnected for now
                        // setSnackbarOptions({
                        //       snackBarProps: { open: true, autoHideDuration: 5000 },
                        //       alertProps: { severity: 'error' },
                        //       message: `There Was A Problem With Resource Discovery, The Client Disconnected`,
                        //   });
                        break;
                    case DiscoveryEvents.AuthenticationFailed:
                        setDiscoveryDetails({
                            ...discoveryDetails,
                            in_cooldown: false,
                            is_discovery: false,
                            request_id: '',
                            client_id: '',
                            datetime_in_30min: 0,
                        });
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 5000 },
                            alertProps: { severity: 'error' },
                            message: `There Was A Problem With Resource Discovery, Authentication Failed`,
                        });
                        break;
                    case DiscoveryEvents.ClientConnected:
                        //localStorage.setItem('websocket_client_id', JSON.stringify(data.data.client_id!));
                        setDiscoveryDetails({ ...discoveryDetails, client_id: data.data.client_id! });
                        break;
                    case DiscoveryEvents.DiscoveryInProgress:
                        setDiscoveryDetails({ ...discoveryDetails, is_discovery: true });
                        break;
                    case DiscoveryEvents.DiscoveryStarted:
                        setDiscoveryDetails({ ...discoveryDetails, is_discovery: true, request_id: data.data.request_id! });
                        break;
                    case DiscoveryEvents.DiscoveryRequestFailed:
                        setDiscoveryDetails({ ...discoveryDetails, is_discovery: false, request_id: '' });
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 5000 },
                            alertProps: { severity: 'error' },
                            message: `Resource Discovery Request Failed`,
                        });
                        break;
                    case DiscoveryEvents.DiscoveryComplete:
                        setDiscoveryDetails({ ...discoveryDetails, is_discovery: false, request_id: '' });
                        props.loadData && props.loadData();
                        break;
                    case DiscoveryEvents.DiscoveryCompleteWithFailures:
                        setDiscoveryDetails({ ...discoveryDetails, is_discovery: false, request_id: '' });
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 5000 },
                            alertProps: { severity: 'warning' },
                            message: `Resource Discovery Complete With Errors`,
                        });
                        props.loadData && props.loadData();
                        break;
                    case DiscoveryEvents.DiscoveryCooldown:
                        setDiscoveryDetails({
                            ...discoveryDetails,
                            in_cooldown: true,
                            datetime_in_30min:
                                data.data.cooldown_time_remaining !== undefined
                                    ? //The message sends back the number of seconds left in the cooldown so I have to get the current timestamp and add the milliseconds left
                                      Date.now() + Number(data.data.cooldown_time_remaining) * 1000
                                    : Date.now() + 30 * 60000, // Date.parse(data.data.cooldown_time_remaining * 1000 ?? new Date(Date.now() + 30 * 60000).toString()),
                        });
                        setSnackbarOptions({
                            snackBarProps: { open: true, autoHideDuration: 5000 },
                            alertProps: { severity: 'warning' },
                            message: `Discovery Has Ran Recently, Please Wait To Run Again`,
                        });
                        break;
                    default:
                        break;
                }
            },
            onClose: (event) => {
                setDiscoveryDetails({
                    ...discoveryDetails,
                    in_cooldown: false,
                    is_discovery: false,
                    request_id: '',
                    client_id: '',
                    datetime_in_30min: 0,
                });
            },
            onError: (e) => {
                setDiscoveryDetails({ ...discoveryDetails, is_discovery: false, request_id: '' });
            },
            shouldReconnect: (closeEvent) => {
                return true;
            },
        },
        discoveryDetails.shouldConnect
    );

    return {
        sendJsonMessage,
    };
}
