import React, { useEffect, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ReportsApi } from '@vegaplatformui/apis';
import {
    QuicksightConsole,
    QuicksightReportLoadingSkeleton,
    QuicksightReportTabs,
    SnackbarErrorOutput,
    SnackBarOptions,
} from '@vegaplatformui/sharedcomponents';
import { useKeycloak } from '@react-keycloak-fork/web';
import { useSetRecoilState } from 'recoil';
import { InitialConsolePathType } from '@vegaplatformui/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQuicksightConsoleControllerProps {}

const QuicksightConsoleController: React.FC<IQuicksightConsoleControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [isLoading, setIsLoading] = useState(true);
    const { keycloak } = useKeycloak();
    const [embeddedUrl, setEmbeddedUrl] = useState<string>('');
    const setSnackbarOptions = useSetRecoilState(SnackBarOptions);

    useEffect(() => {
        const reportsApi = new ReportsApi();
        reportsApi.keycloak = keycloak;
        reportsApi
            .postEmbeddedConsoleURL({ initialConsolePath: InitialConsolePathType.Start })
            .then((response) => {
                setEmbeddedUrl(response.data);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: `There was a problem getting embedded Console Url. ${SnackbarErrorOutput(error)}`,
                });
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (embeddedUrl !== '' && embeddedUrl !== undefined) {
            setIsLoading(false);
        }
    }, [embeddedUrl]);

    return isLoading ? <QuicksightReportLoadingSkeleton /> : <QuicksightConsole consoleUrl={embeddedUrl} />;
};

const useStyles = customMakeStyles<IQuicksightConsoleControllerProps>()((theme, props) => ({}));

export { QuicksightConsoleController };
