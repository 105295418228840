import React, { useEffect } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { Box, Collapse, Grid, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { IBusinessGroupingType, IBusinessGroupTreeItem, IBusinessGroupTreeItemParkingSchedules } from '@vegaplatformui/models';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/material/styles';
import { CalendarToday, People, Visibility } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';
import { useSpring, animated } from '@react-spring/web';
import { TreeItem, TreeItemProps } from '@mui/x-tree-view';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import useBusinessGroupingsTreeItemApi from '../../api-hooks/business-groupings-hooks/use-business-groupings-tree-item-api';
import useBusinessGroupingsApi from '../../api-hooks/business-groupings-hooks/use-business-groupings-api';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBusinessGroupTreeItemProps {
    isInitialGroup: boolean;
    amountToShiftColumnsLeft: number;
    businessGroupingTypes: IBusinessGroupingType[];
    businessGroupTreeItem: IBusinessGroupTreeItem | undefined;
    expandedNodes: string[];
    setExpandedNodes: React.Dispatch<React.SetStateAction<string[]>>;
    isExpandAll: boolean;
    onChangeExpandAll: () => void;
    onClickOpenEditBusinessGroupingDialog: (grouping: IBusinessGroupTreeItem) => void;
    childNodesToClear?: IBusinessGroupTreeItem[] | undefined;
    setChildNodesToClear?: React.Dispatch<React.SetStateAction<IBusinessGroupTreeItem[] | undefined>>;
    isSmaller922PxWidth: boolean;
    isSmaller650PxWidth: boolean;
}

type StyledTreeItemProps = TreeItemProps & {
    amountToShiftColumnsLeft: number;
    isInitialGroup: boolean;
    bgColor?: string;
    bgColorForDarkMode?: string;
    color?: string;
    colorForDarkMode?: string;
    parking_schedules: IBusinessGroupTreeItemParkingSchedules[];
    name: string;
    type: string;
    owner: string;
    users: string[];
    onClickOpenEditBusinessGroupingDialog: (grouping: IBusinessGroupTreeItem) => void;
    businessGroupTreeItem: IBusinessGroupTreeItem | undefined;
    isSmaller922PxWidth: boolean;
    isSmaller650PxWidth: boolean;
};

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props: StyledTreeItemProps, ref: React.Ref<HTMLLIElement>) {
    const { classes, cx } = useStyledTreeItemStyles(props);
    const { vOperate } = useFlags();

    const theme = useTheme();
    const {
        isInitialGroup,
        amountToShiftColumnsLeft,
        onClickOpenEditBusinessGroupingDialog,
        owner,
        name,
        users,
        type,
        parking_schedules,
        businessGroupTreeItem,
        color,
        colorForDarkMode,
        bgColor,
        bgColorForDarkMode,
        isSmaller922PxWidth,
        isSmaller650PxWidth,
        ...other
    } = props;
    const businessGroupingApi = useBusinessGroupingsApi({});

    const styleProps = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    const tip = props.users.join('\n');
    const schedules = parking_schedules.map((schedule) => schedule.name).join(', ');
    return (
        <TreeItem
            slots={{ groupTransition: TransitionComponent }}
            label={
                <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                    <Grid item xs={2} sm={!isSmaller922PxWidth ? 4 : false}>
                        <Typography noWrap variant='body2' sx={{ fontWeight: 'inherit' }}>
                            {name}
                        </Typography>
                    </Grid>
                    <Grid sx={{ marginLeft: !isInitialGroup ? amountToShiftColumnsLeft : '0rem' }} item xs={2}>
                        <Typography noWrap variant='body2' color='inherit'>
                            {type}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography noWrap variant='body2' color='inherit'>
                            {owner}
                        </Typography>
                    </Grid>
                    <Grid container item xs={1.5}>
                        <Grid className={cx(classes.NavigationButtons)} item>
                            <StyledToolTip title={props.users.length > 0 ? <div style={{ whiteSpace: 'pre-line' }}>{tip}</div> : ''}>
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    to={`${businessGroupTreeItem?.id}/users`}
                                    aria-label={`view ${props.users.length} assigned users`}
                                >
                                    <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                                        <People className={cx(classes.PeopleIcon)} fontSize={'small'} />
                                        {!isSmaller650PxWidth && (
                                            <Typography sx={{ color: 'black' }} variant='body2' color='inherit'>
                                                {users.length}
                                            </Typography>
                                        )}
                                    </Stack>
                                </Link>
                            </StyledToolTip>
                        </Grid>
                    </Grid>
                    {vOperate && (
                        <Grid container item xs={1.5}>
                            <Grid className={cx(classes.NavigationButtons)} item>
                                <StyledToolTip title={parking_schedules.length > 0 ? <div style={{ whiteSpace: 'pre-line' }}>{schedules}</div> : ''}>
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={`${businessGroupTreeItem?.id}/scheduler`}
                                        aria-label={`view ${parking_schedules.length} enterprise schedules`}
                                    >
                                        <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                                            <CalendarToday className={cx(classes.PeopleIcon)} fontSize={'small'} />
                                            {!isSmaller650PxWidth && (
                                                <Typography sx={{ color: 'black' }} variant='body2' color='inherit'>
                                                    {parking_schedules.length}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Link>
                                </StyledToolTip>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={'auto'}>
                        <StyledToolTip
                            title={
                                businessGroupingApi.businessGroupings === undefined ? (
                                    <div style={{ whiteSpace: 'pre-line' }}>{'Loading Business Grouping'}</div>
                                ) : (
                                    <div style={{ whiteSpace: 'pre-line' }}>{'Click to view details'}</div>
                                )
                            }
                        >
                            <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Link
                                    style={{ textDecoration: 'none' }}
                                    to={`${businessGroupTreeItem?.id}`}
                                    aria-label={'view business group details'}
                                >
                                    <Stack direction='row' justifyContent='flex-start' alignItems='center'>
                                        <Visibility className={cx(classes.PeopleIcon)} fontSize={'small'} aria-label='visibility icon' />
                                    </Stack>
                                </Link>
                            </Box>
                        </StyledToolTip>
                    </Grid>
                </Grid>
            }
            style={styleProps as any}
            {...other}
            ref={ref}
        />
    );
});

const useStyledTreeItemStyles = customMakeStyles<StyledTreeItemProps>()((theme, props) => ({
    ActionButton: { p: 0.5, marginRight: '0.5rem' },
    NameTypography: { fontWeight: 'inherit' },
    UsersBox: { display: 'flex', alignItems: 'center' },
    PeopleIcon: { fill: theme.palette.grey[300], marginRight: '.25rem' },
    NavigationButtons: { width: 'fit-content' },
}));

const BusinessGroupingTreeItem: React.FC<IBusinessGroupTreeItemProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [childNodes, setChildNodes] = React.useState<IBusinessGroupTreeItem[] | undefined>(undefined);
    const [childNodesToClear, setChildNodesToClear] = React.useState<IBusinessGroupTreeItem[] | undefined>(undefined);

    const businessGroupingTreeItemApi = useBusinessGroupingsTreeItemApi({
        businessGroupTreeItem: props.businessGroupTreeItem,
        childNodesToClear: props.childNodesToClear,
        setChildNodesToClear: props.setChildNodesToClear,
    });

    useEffect(() => {
        if (
            props.businessGroupTreeItem &&
            props.expandedNodes.includes(props.businessGroupTreeItem.id) &&
            props.businessGroupTreeItem.has_children &&
            childNodes === undefined
        ) {
            businessGroupingTreeItemApi.setIsEnabled(true);
        } else {
            businessGroupingTreeItemApi.setIsEnabled(false);
        }
    }, [props.expandedNodes, props.isExpandAll]);

    useEffect(() => {
        if (props.businessGroupTreeItem && props.expandedNodes.includes(props.businessGroupTreeItem.id)) {
            setChildNodes(businessGroupingTreeItemApi.businessGroupingTreeChildNodes);
        }
    }, [
        businessGroupingTreeItemApi.businessGroupingTreeChildNodes,
        props.expandedNodes,
        businessGroupingTreeItemApi.isBusinessGroupingTreeChildNodesLoading,
    ]);
    const handleRowClick = (x: any) => {
        if (props.expandedNodes.includes(props.businessGroupTreeItem!.id)) {
            const update = [...new Set(props.expandedNodes.filter((f) => f !== props.businessGroupTreeItem!.id))];
            const update2 = [...new Set(update.filter((f) => !childNodesToClear?.some((item) => item.id === f)))];
            props.setExpandedNodes(update2);
            setChildNodes(undefined);
        }
    };

    return props.businessGroupTreeItem ? (
        <StyledTreeItem
            amountToShiftColumnsLeft={props.amountToShiftColumnsLeft}
            isInitialGroup={props.isInitialGroup}
            onClick={(x) => {
                handleRowClick(x);
            }}
            parking_schedules={props.businessGroupTreeItem.parking_schedules}
            key={props.businessGroupTreeItem.id}
            itemId={props.businessGroupTreeItem.id}
            name={props.businessGroupTreeItem.name}
            type={props.businessGroupingTypes?.find((bg) => bg.id === props.businessGroupTreeItem?.type)?.name ?? 'Unknown'}
            owner={props.businessGroupTreeItem.owner}
            users={props.businessGroupTreeItem.users}
            onClickOpenEditBusinessGroupingDialog={props.onClickOpenEditBusinessGroupingDialog}
            businessGroupTreeItem={props.businessGroupTreeItem}
            isSmaller922PxWidth={props.isSmaller922PxWidth}
            isSmaller650PxWidth={props.isSmaller650PxWidth}
        >
            {props.businessGroupTreeItem.has_children
                ? childNodes
                    ? childNodes?.map((child) => (
                          <div key={child.id}>
                              <Box className={cx(classes.Spacer)} />
                              <BusinessGroupingTreeItem
                                  amountToShiftColumnsLeft={props.amountToShiftColumnsLeft - 1.2}
                                  isInitialGroup={false}
                                  businessGroupingTypes={props.businessGroupingTypes}
                                  childNodesToClear={childNodesToClear}
                                  setChildNodesToClear={setChildNodesToClear}
                                  onClickOpenEditBusinessGroupingDialog={props.onClickOpenEditBusinessGroupingDialog}
                                  onChangeExpandAll={props.onChangeExpandAll}
                                  setExpandedNodes={props.setExpandedNodes}
                                  key={child.id}
                                  isExpandAll={props.isExpandAll}
                                  businessGroupTreeItem={child}
                                  expandedNodes={props.expandedNodes}
                                  isSmaller922PxWidth={props.isSmaller922PxWidth}
                                  isSmaller650PxWidth={props.isSmaller650PxWidth}
                              />
                          </div>
                      ))
                    : [<Skeleton key={uuidv4()} variant='text' width={'100%'} height={'2rem'} />]
                : undefined}
        </StyledTreeItem>
    ) : (
        <></>
    ); // return empty fragment if no businessGroupTreeItem
};

const useStyles = customMakeStyles<IBusinessGroupTreeItemProps>()((theme, props) => ({
    Spacer: {
        marginBottom: '.15rem',
    },
}));

export { BusinessGroupingTreeItem };
