import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { TimePicker } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';
import { ITimeZone } from '@vegaplatformui/utils';
import { TimePickerWithTimezone } from '../../../utilities/time-picker-with-timezone';
import { Dayjs } from 'dayjs';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDailyReoccurrenceFormProps {
    time: Dayjs | null;
    setTime: React.Dispatch<React.SetStateAction<Dayjs | null>>;
    timezone: ITimeZone | null;
}

const DailyReoccurrenceField: React.FC<IDailyReoccurrenceFormProps> = (props) => {
    const { classes, cx } = useStyles(props);

    return (
        <FormControl fullWidth aria-label={'Daily reoccurrence time selection'}>
            <TimePickerWithTimezone
                timezone={props.timezone}
                time={props.time}
                setTime={props.setTime}
                label={'Select daily time'}
                slotProps={{ textField: { fullWidth: true, size: 'small' } }}
            />
        </FormControl>
    );
};

const useStyles = customMakeStyles<IDailyReoccurrenceFormProps>()((theme, props) => ({}));

export { DailyReoccurrenceField };
