import React, { useEffect, useMemo, useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { ParkingSchedulesCard, SnackBarOptions, useParkingApi, useBusinessGroupingsApi, tableIdentifierKeys } from '@vegaplatformui/sharedcomponents';
import { useSetAtom } from 'jotai';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IParkingControllerProps {}

const ParkingController: React.FC<IParkingControllerProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const setSnackbarOptions = useSetAtom(SnackBarOptions);
    const parkingScheduleTableIdentifier = tableIdentifierKeys.parkingScheduleTable;
    const businessGroupingApi = useBusinessGroupingsApi({});

    const {
        setSelectedSchedules,
        selectedSchedules,
        parkingSchedules,
        resources,
        setIsDrawerOpen,
        isDrawerOpen,
        loadSelectedParkingSchedule,
        scheduleToEdit,
        onClickCreateSchedule,
        onClickEditSchedule,
        onClickDeleteSchedule,
        onClickDeleteSchedules,
        onClickCopySchedule,
        onOpenScheduleChangeDialog,
        isChangeScheduleDialogOpen,
        setIsChangeScheduleDialogOpen,
        onOpenRemoveAdvancedParkingDialog,
        onRemoveAdvancedParking,
        isRemoveAdvancedParkingDialogOpen,
        setIsRemoveAdvancedParkingDialogOpen,
        userHasGroupings,
        loadParkableResources,
        isParkableResourcesLoading,
        isParkingSchedulesLoading,
        setResourcesSelectedForParking,
        scheduleToDelete,
        setScheduleToDelete,
        onChangeScheduleStatuses,
        isScheduledChangeStatus,
        isConfirmDeleteDialogOpen,
        setIsConfirmDeleteDialogOpen,
        onClickOpenDeleteDialog,
        schedulesToChangeStatusOn,
        onOpenEnableDisableSchedulesDialog,
        isDeleteExemptionDialogOpen,
        setIsDeleteExemptionDialogOpen,
        scheduleWithExemption,
        setScheduleWithExemption,
        onClickOpenDeleteExemptionDialog,
        onClickDeleteExemption,
    } = useParkingApi({ parkingScheduleTableIdentifier: parkingScheduleTableIdentifier });

    return (
        <ParkingSchedulesCard
            isDeleteExemptionDialogOpen={isDeleteExemptionDialogOpen}
            setIsDeleteExemptionDialogOpen={setIsDeleteExemptionDialogOpen}
            scheduleWithExemption={scheduleWithExemption}
            setScheduleWithExemption={setScheduleWithExemption}
            onClickOpenDeleteExemptionDialog={onClickOpenDeleteExemptionDialog}
            onClickDeleteExemption={onClickDeleteExemption}
            scheduleToDelete={scheduleToDelete}
            setScheduleToDelete={setScheduleToDelete}
            parkingScheduleTableIdentifier={parkingScheduleTableIdentifier}
            setResourcesSelectedForParking={setResourcesSelectedForParking}
            isParkingLoading={isParkingSchedulesLoading}
            onToggleDrawer={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            setScheduleToEdit={loadSelectedParkingSchedule}
            scheduleToEdit={scheduleToEdit}
            parkableResources={resources}
            parkingSchedules={parkingSchedules}
            selectedSchedules={selectedSchedules}
            setSelectedSchedules={setSelectedSchedules}
            usersBusinessGroupings={businessGroupingApi.myBusinessUnitTree || []}
            isParkableResourcesLoading={isParkableResourcesLoading}
            onClickCreateSchedule={onClickCreateSchedule}
            onClickEditSchedule={onClickEditSchedule}
            onClickDeleteSchedule={onClickDeleteSchedule}
            onClickDeleteSelectedSchedules={onClickDeleteSchedules}
            onClickCopySchedule={onClickCopySchedule}
            onClickSetCreateSnackbarTemp={(context: string) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'warning' },
                    message: `${context} button pressed`,
                });
            }}
            onOpenScheduleChangeDialog={onOpenScheduleChangeDialog}
            onOpenEnableDisableSchedulesDialog={onOpenEnableDisableSchedulesDialog}
            isChangeScheduleDialogOpen={isChangeScheduleDialogOpen}
            setIsChangeScheduleDialogOpen={setIsChangeScheduleDialogOpen}
            onOpenRemoveAdvancedParkingDialog={onOpenRemoveAdvancedParkingDialog}
            onRemoveAdvancedParking={onRemoveAdvancedParking}
            isRemoveAdvancedParkingDialogOpen={isRemoveAdvancedParkingDialogOpen}
            setIsRemoveAdvancedParkingDialogOpen={setIsRemoveAdvancedParkingDialogOpen}
            userHasGroupings={userHasGroupings}
            loadParkableResources={loadParkableResources}
            onChangeScheduleStatuses={onChangeScheduleStatuses}
            isScheduled={isScheduledChangeStatus}
            isConfirmDeleteDialogOpen={isConfirmDeleteDialogOpen}
            setIsConfirmDeleteDialogOpen={setIsConfirmDeleteDialogOpen}
            onClickOpenDeleteDialog={onClickOpenDeleteDialog}
            schedulesToChangeStatusOn={schedulesToChangeStatusOn}
        />
    );
};

const useStyles = customMakeStyles<IParkingControllerProps>()((theme, props) => ({}));

export { ParkingController };
