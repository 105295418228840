import React, { useState } from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { SsoConfigurationTile } from './sso-configuration-tile';
import { ISSONameIDPolicyFormat, IUserSettingSSOSaml, IUserSettingSSOSamlForm } from '@vegaplatformui/models';
import { Grid2, Typography } from '@mui/material';
import { SsoDeleteConfigurationDialog } from './sso-delete-configuration-dialog';
import { SsoConfigurationDrawer } from './sso-configuration-drawer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISsoConfigurationListProps {
    idpList: IUserSettingSSOSaml[];
    nameIDPolicyFormatOptions: ISSONameIDPolicyFormat[];
    onUpdate: (data: IUserSettingSSOSamlForm) => void;
    onCreate: (data: IUserSettingSSOSamlForm) => void;
    onDelete: (data: string) => void;
    isConfigurationDrawerOpen: boolean;
    onClickCloseConfigurationDrawer: () => void;
    onClickOpenConfigurationDrawer: () => void;
}

const SsoConfigurationList: React.FC<ISsoConfigurationListProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const [selectedIdp, setSelectedIdp] = useState<IUserSettingSSOSaml | undefined>(undefined);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const onSubmitConfiguration = (data: IUserSettingSSOSamlForm) => {
        if (!selectedIdp) {
            props.onCreate(data);
        } else {
            props.onUpdate(data);
        }
        onClickCloseConfiguration();
    };

    const onClickDeleteTile = (idpToDelete: IUserSettingSSOSaml) => {
        setSelectedIdp(idpToDelete);
        setIsDeleteDialogOpen(true);
    };

    const onClickOpenEditConfiguration = (idpToEdit: IUserSettingSSOSaml) => {
        setSelectedIdp(idpToEdit);
        props.onClickOpenConfigurationDrawer();
    };

    const onClickCloseDeleteConfiguration = () => {
        setIsDeleteDialogOpen(false);
        setTimeout(() => {
            setSelectedIdp(undefined);
        }, 150);
    };

    const onClickCloseConfiguration = () => {
        props.onClickCloseConfigurationDrawer();
        setTimeout(() => {
            setSelectedIdp(undefined);
        }, 150);
    };

    return (
        <Grid2 className={cx(classes.GridList)} size={12} container spacing={2}>
            {props.idpList.length > 0 ? (
                props.idpList.map((item) => (
                    <SsoConfigurationTile
                        key={item.alias + '-list-item'}
                        ssoItem={item}
                        onClickDeleteTile={onClickDeleteTile}
                        onClickOpenEditConfiguration={onClickOpenEditConfiguration}
                    />
                ))
            ) : (
                <Grid2 textAlign={'center'} container size={12} spacing={1} justifyContent={'flex-start'} alignItems={'self-start'}>
                    <Grid2 size={12}>
                        <Typography variant={'h6'}>No data to show</Typography>
                    </Grid2>
                    <Grid2 size={12}>
                        <Typography variant={'body2'}>Start by creating a SSO Configuration</Typography>
                    </Grid2>
                </Grid2>
            )}
            {selectedIdp && (
                <SsoDeleteConfigurationDialog
                    key={selectedIdp?.alias + '-delete-dialog'}
                    selectedSSO={selectedIdp}
                    isOpen={isDeleteDialogOpen}
                    onClose={onClickCloseDeleteConfiguration}
                    onDelete={props.onDelete}
                />
            )}
            <SsoConfigurationDrawer
                key={selectedIdp?.alias + '-config-drawer'}
                onSubmit={onSubmitConfiguration}
                configToEdit={selectedIdp}
                isOpen={props.isConfigurationDrawerOpen}
                onClose={onClickCloseConfiguration}
            />
        </Grid2>
    );
};

const useStyles = customMakeStyles<ISsoConfigurationListProps>()((theme, props) => ({
    GridList: { padding: '0rem 1rem 1rem 1rem' },
}));

export { SsoConfigurationList };
