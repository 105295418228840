import { GeminiResponse } from '@vegaplatformui/utils';
import {
    IActionResponse,
    IDataGridRequest,
    IGetOptimizedResourceSchedules,
    IGetParkingPolicySummariesResponse,
    IParkingSchedule,
    IParkingSchedule_Deprecated,
    IParkingScheduleExemption,
    IParkingScheduleSummary,
    IPostBatchPolicyExemptionRequest,
    IPostBatchPolicyStatusUpdateRequest,
    IPostTakeActionRequest,
    IPostTakeImmediateActionRequest,
    IPutPoliciesRequest,
    IPutPolicyExemptionRequest,
    IResource,
} from '@vegaplatformui/models';
import { HttpClient } from './http-common';
import Keycloak from 'keycloak-js';
import { GenericAbortSignal } from 'axios';

export class ActionsApi extends HttpClient {
    protected static classInstance?: ActionsApi;
    public keycloak!: Keycloak;
    public baseURL!: string;

    constructor() {
        super(`https://${process.env.NX_API_URL!}`);
        this._initializeRequestInterceptor();
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new ActionsApi();
        }

        return this.classInstance;
    }

    public postTakeAction = (request: IPostTakeActionRequest): GeminiResponse<IActionResponse> =>
        this.instance.post(`vegaapi/scheduled-events/take-action?rec_id=${request.recommendationId}`, {
            resources: request.resources,
            schedule: request.schedule,
            timezone: request.timezone,
        });

    public postParking_deprecated = (request: IParkingSchedule_Deprecated): GeminiResponse<any> =>
        this.instance.post(`vegaapi/vega-policies/`, request);

    public postParking = (request: IParkingSchedule): GeminiResponse<any> => this.instance.post(`vegaapi/vega-policies/`, request);

    public putPolicies = (request: IPutPoliciesRequest): GeminiResponse<IActionResponse> =>
        this.instance.put(`vegaapi/policies?policy_id=${request.policyId}`, request.data);

    public getParkingPolicySummaries = (request: IDataGridRequest, signal: GenericAbortSignal): GeminiResponse<IGetParkingPolicySummariesResponse> =>
        this.instance.get(`vegaapi/vega-policies/`, {
            params: {
                filters: JSON.stringify(request.filterModel),
                sorted_by: JSON.stringify(request.sortModel),
                grid_pagination_model: JSON.stringify(request.paginationModel),
            },
            signal,
        });

    public getParkingPolicySummariesForResource = (
        request: IDataGridRequest,
        resourceId: string,
        signal: GenericAbortSignal
    ): GeminiResponse<IGetParkingPolicySummariesResponse> =>
        this.instance.get(`vegaapi/vega-policies/resource/${resourceId}`, {
            params: {
                filters: JSON.stringify(request.filterModel),
                sorted_by: JSON.stringify(request.sortModel),
                grid_pagination_model: JSON.stringify(request.paginationModel),
            },
            signal,
        });

    public getParkingPolicy_deprecated = (policyId: string): GeminiResponse<IParkingSchedule_Deprecated> =>
        this.instance.get(`vegaapi/vega-policies/${policyId}`);

    public getParkingPolicy = (policyId: string): GeminiResponse<IParkingSchedule> => this.instance.get(`vegaapi/vega-policies/${policyId}`);

    public getOptimizedResourceSchedules = (resourceIds: string[]): GeminiResponse<IGetOptimizedResourceSchedules> =>
        this.instance.get(`vegaapi/vega-policies/optimized/resource_schedules`, { params: { resource_ids: resourceIds.join(',') } });

    public deleteParkingSchedule = (policyId: string): GeminiResponse<any> => this.instance.delete(`vegaapi/vega-policies/${policyId}`);

    public deleteParkingSchedules = (policyIds: string[]): GeminiResponse<any> =>
        this.instance.delete(`vegaapi/vega-policies/batch/`, { data: policyIds });

    public putParking_deprecated = (parkingScheduleId: string, request: IParkingSchedule_Deprecated): GeminiResponse<any> =>
        this.instance.put(`vegaapi/vega-policies/${parkingScheduleId}`, request);

    public putParking = (parkingScheduleId: string, request: IParkingSchedule): GeminiResponse<any> =>
        this.instance.put(`vegaapi/vega-policies/${parkingScheduleId}`, request);

    public deleteScheduledActions = (resources: IResource[]): GeminiResponse<any> =>
        this.instance.delete(`vegaapi/scheduled-events/take-action`, {
            data: resources,
        });

    public putTakeAction = (request: IPostTakeActionRequest): GeminiResponse<IActionResponse> =>
        this.instance.put(`vegaapi/scheduled-events/take-action?rec_id=${request.recommendationId}`, {
            resources: request.resources,
            schedule: request.schedule,
            timezone: request.timezone,
        });

    public postTakeImmediateAction = (request: IPostTakeImmediateActionRequest): GeminiResponse<IActionResponse> =>
        this.instance.post(`vegaapi/actions`, {
            resource_id: request.resourceId,
            action: request.action,
        });

    public putBatchPolicyStatusUpdate = (request: IPostBatchPolicyStatusUpdateRequest): GeminiResponse<any> =>
        this.instance.put('vegaapi/vega-policies/batch/schedule/', request);

    public postBatchPolicyExemptions = (request: IPostBatchPolicyExemptionRequest): GeminiResponse<any> =>
        this.instance.post('vegaapi/vega-policy-exemptions/policy/batch/', request);

    public putPolicyExemptionsByScheduleId = (request: IPutPolicyExemptionRequest, scheduleId: string): GeminiResponse<any> =>
        this.instance.put(`vegaapi/vega-policy-exemptions/policy/${scheduleId}`, request);

    public deletePolicyExemptionsByScheduleId = (scheduleId: string): GeminiResponse<any> =>
        this.instance.delete(`vegaapi/vega-policy-exemptions/policy/${scheduleId}`);

    public deletePolicyExemption = (exemptionId: string): GeminiResponse<any> =>
        this.instance.delete(`vegaapi/vega-policy-exemptions/${exemptionId}`);
}
