import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { QuicksightReportsController } from '../quicksight-reports-controller/quicksight-reports-controller';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFinanceProps {}

const Finance: React.FC<IFinanceProps> = (props) => {
    return <QuicksightReportsController folderNames={['finance']} />;
};

export { Finance };
