import React from 'react';
import { customMakeStyles } from '@vegaplatformui/styling';
import { AlertTitle, Box, Stack, Typography } from '@mui/material';
import { IconButtonWithAlertPopover } from '../../icon-button-with-alert-popover/icon-button-with-alert-popover';
import { InfoOutlined } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISoftDeletedResourceNoticeProps {}

const SoftDeletedResourceNotice: React.FC<ISoftDeletedResourceNoticeProps> = (props) => {
    const { classes, cx } = useStyles(props);

    function ResourceSoftDeleteNotice() {
        return (
            <Stack direction={'column'} spacing={1}>
                <AlertTitle>Resource undiscoverable</AlertTitle>
                <Typography variant={'body2'}>
                    The resource is currently undiscoverable by your account provider. You have the option to{' '}
                    {
                        <Box component={'span'} display='inline' fontWeight={'bold'}>
                            Remove
                        </Box>
                    }{' '}
                    or keep it listed should it become discoverable again in the future.
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack height={'100%'}>
            <IconButtonWithAlertPopover
                buttonTitle={'Resource Soft Deleted Notice'}
                buttonColor={'error'}
                buttonIcon={<InfoOutlined />}
                alertSeverity={'info'}
                alertColor={'error'}
                shouldBindOnHover={false}
                popperClassName={cx(classes.PopperStyle)}
                iconButtonClassName={cx(classes.InfoButtonFocus)}
                alertContent={<ResourceSoftDeleteNotice />}
                iconButtonSize={'small'}
            />
        </Stack>
    );
};

const useStyles = customMakeStyles<ISoftDeletedResourceNoticeProps>()((theme, props) => ({
    InfoButtonFocus: {
        '&:hover': {
            backgroundColor: theme.palette.action.focus,
        },
    },
    PopperStyle: { width: '390px' },
}));

export { SoftDeletedResourceNotice };
