import * as ReactDOM from 'react-dom/client';
import 'react-js-cron/dist/styles.css';
import '../../../global.css';
import { pdfjs } from 'react-pdf';
import 'react-js-cron/dist/styles.css';
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { AppLoadingHandler } from './app/app-loading-handler';
import { RecoilRoot } from 'recoil';
import { CustomKeycloakProvider } from '@vegaplatformui/sharedcomponents';
import axios from 'axios';

async function fetchData(inputParam: string): Promise<any> {
    try {
        const response = await axios.get(`https://${process.env.NX_AUTH_SERVER_URL}/proxy/auth_server?realm=${inputParam}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error performing auth server request:', error);
        throw error;
    }
}

(async () => {
    const domainSlug = window.location.hostname.split('.')[0];
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env['NX_LAUNCH_DARKLY_CLIENT_ID'] ?? '',
        context: {
            name: window.location.hostname,
            key: window.location.hostname,
            custom: {
                domainSlug: domainSlug,
            },
        },
    });

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    const authServerUrl: string = process.env.NX_AUTH_SERVER_URL!; // default to base auth server URL
    // await fetchData(domainSlug)
    //     .then(data => {
    //         authServerUrl = data.auth_server_host;
    //     })
    //     .catch(() => console.error('Encountered error fetching auth server URL'));

    root.render(
        <LDProvider>
            <RecoilRoot>
                <CustomKeycloakProvider authServerUrl={`https://${authServerUrl}/`} authClientId={process.env.NX_AUTH_RESOURCE!}>
                    <AppLoadingHandler />
                </CustomKeycloakProvider>
            </RecoilRoot>
        </LDProvider>
    );
})();

pdfjs.GlobalWorkerOptions.workerSrc = new URL('node_modules/pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
