import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { customMakeStyles } from '@vegaplatformui/styling';
import { StyledToolTip } from '../../utilities/styled-tooltip';
import { BlackOnWhiteButton } from '../../utilities/black-on-white-button';
import { NotificationType, ThresholdConfig } from '@vegaplatformui/models';

export interface IBudgetThresholdModalProps {
    open: boolean;
    onClose: () => void;
    onSave: (thresholds: ThresholdConfig[]) => void;
    initialThresholds?: ThresholdConfig[];
    budgetName: string;
    budgetId: string;
    readOnly?: boolean;
}

// Available threshold notification types
const notificationTypes: NotificationType[] = [
    {
        id: 'percentage',
        name: 'Percentage of Budget',
        description: 'Trigger notification when a specific percentage of the budget is reached',
    },
    {
        id: 'amount',
        name: 'Absolute Amount',
        description: 'Trigger notification when spending reaches a specific dollar amount',
    },
    {
        id: 'remaining',
        name: 'Remaining Budget',
        description: 'Trigger notification when budget remaining falls below a specified amount',
    },
    {
        id: 'forecast',
        name: 'Forecasted Overage',
        description: 'Trigger when forecasted spending is projected to exceed budget',
    },
];

// Available notification methods
const notificationMethods = [
    { id: 'email', name: 'Email' },
    { id: 'slack', name: 'Slack' },
    { id: 'teams', name: 'Microsoft Teams' },
    { id: 'webhook', name: 'Webhook' },
];

const BudgetThresholdModal: React.FC<IBudgetThresholdModalProps> = (props) => {
    const { classes, cx } = useStyles(props);
    const { open, onClose, onSave, initialThresholds = [], budgetName, readOnly = false } = props;

    // State to manage thresholds
    const [thresholds, setThresholds] = useState<ThresholdConfig[]>(
        initialThresholds.length > 0
            ? initialThresholds
            : [
                  {
                      id: `threshold-${Date.now()}`,
                      type: 'percentage',
                      value: 80,
                      enabled: true,
                      notificationMethod: 'email',
                      recipients: [],
                  },
              ]
    );

    // State for recipient input
    const [recipientInput, setRecipientInput] = useState<string>('');

    // Add a new threshold
    const handleAddThreshold = () => {
        setThresholds([
            ...thresholds,
            {
                id: `threshold-${Date.now()}`,
                type: 'percentage',
                value: 80,
                enabled: true,
                notificationMethod: 'email',
                recipients: [],
            },
        ]);
    };

    // Remove a threshold
    const handleRemoveThreshold = (id: string) => {
        setThresholds(thresholds.filter((threshold) => threshold.id !== id));
    };

    // Toggle threshold enabled state
    const handleToggleThreshold = (id: string) => {
        setThresholds(thresholds.map((threshold) => (threshold.id === id ? { ...threshold, enabled: !threshold.enabled } : threshold)));
    };

    // Update threshold properties
    const handleThresholdChange = (id: string, field: keyof ThresholdConfig, value: string | number | boolean | string[]) => {
        setThresholds(thresholds.map((threshold) => (threshold.id === id ? { ...threshold, [field]: value } : threshold)));
    };

    // Add a recipient to a threshold
    const handleAddRecipient = (thresholdId: string) => {
        if (recipientInput.trim()) {
            setThresholds(
                thresholds.map((threshold) =>
                    threshold.id === thresholdId
                        ? {
                              ...threshold,
                              recipients: [...threshold.recipients, recipientInput.trim()],
                          }
                        : threshold
                )
            );
            setRecipientInput('');
        }
    };

    // Remove a recipient from a threshold
    const handleRemoveRecipient = (thresholdId: string, recipientIndex: number) => {
        setThresholds(
            thresholds.map((threshold) =>
                threshold.id === thresholdId
                    ? {
                          ...threshold,
                          recipients: threshold.recipients.filter((_, index) => index !== recipientIndex),
                      }
                    : threshold
            )
        );
    };

    // Save all thresholds
    const handleSave = () => {
        onSave(thresholds);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='md'
            fullWidth
            slotProps={{
                paper: {
                    className: cx(classes.DialogPaper),
                },
            }}
        >
            <DialogTitle className={cx(classes.DialogTitle)}>
                <Typography variant='h6'>Budget Threshold Notifications</Typography>
                <StyledToolTip title='Close'>
                    <IconButton onClick={onClose} size='small' className={cx(classes.CloseButton)}>
                        <CloseIcon fontSize='small' />
                    </IconButton>
                </StyledToolTip>
            </DialogTitle>

            <DialogContent className={cx(classes.DialogContent)}>
                <Typography variant='subtitle1' className={cx(classes.BudgetTitle)}>
                    Configure notification thresholds for <strong>{budgetName}</strong>
                </Typography>

                <Typography variant='body2' color='text.secondary' className={cx(classes.Description)}>
                    Set up alerts that will trigger when your budget reaches specified thresholds. You can configure multiple thresholds with
                    different notification methods.
                </Typography>

                <Stack spacing={3}>
                    {thresholds.map((threshold) => (
                        <Card key={threshold.id} variant='outlined' className={cx(classes.ThresholdCard)}>
                            <CardContent>
                                <Box className={cx(classes.ThresholdTypeContainer)}>
                                    {/* Threshold Type Selection */}
                                    <FormControl fullWidth size='small'>
                                        <InputLabel>Threshold Type</InputLabel>
                                        <Select
                                            value={threshold.type}
                                            label='Threshold Type'
                                            onChange={(e) => handleThresholdChange(threshold.id, 'type', e.target.value)}
                                            disabled={readOnly}
                                        >
                                            {notificationTypes.map((type) => (
                                                <MenuItem key={type.id} value={type.id}>
                                                    <Typography variant='body2' component='span'>
                                                        {type.name}
                                                    </Typography>
                                                    <Typography variant='caption' color='text.secondary' display='block'>
                                                        {type.description}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {/* Threshold Value */}
                                    <FormControl fullWidth size='small'>
                                        <TextField
                                            label='Threshold Value'
                                            type='number'
                                            size='small'
                                            value={threshold.value}
                                            onChange={(e) => handleThresholdChange(threshold.id, 'value', Number(e.target.value))}
                                            slotProps={{
                                                input: {
                                                    endAdornment: (
                                                        <InputAdornment position='end'>{threshold.type === 'percentage' ? '%' : '$'}</InputAdornment>
                                                    ),
                                                },
                                            }}
                                            disabled={readOnly}
                                        />
                                    </FormControl>
                                </Box>

                                {/* Notification Method */}
                                <FormControl fullWidth size='small' className={cx(classes.NotificationMethod)}>
                                    <InputLabel>Notification Method</InputLabel>
                                    <Select
                                        value={threshold.notificationMethod}
                                        label='Notification Method'
                                        onChange={(e) => handleThresholdChange(threshold.id, 'notificationMethod', e.target.value)}
                                        disabled={readOnly}
                                    >
                                        {notificationMethods.map((method) => (
                                            <MenuItem key={method.id} value={method.id}>
                                                {method.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Recipients */}
                                <Typography variant='body2' gutterBottom>
                                    Recipients
                                </Typography>

                                <Box className={cx(classes.RecipientInputContainer)}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        placeholder={
                                            threshold.notificationMethod === 'email'
                                                ? 'Enter email address'
                                                : threshold.notificationMethod === 'slack'
                                                  ? 'Enter Slack channel or user ID'
                                                  : 'Enter recipient'
                                        }
                                        value={recipientInput}
                                        onChange={(e) => setRecipientInput(e.target.value)}
                                        disabled={readOnly}
                                        className={cx(classes.RecipientInput)}
                                    />
                                    <Button variant='contained' size='small' onClick={() => handleAddRecipient(threshold.id)} disabled={readOnly}>
                                        Add
                                    </Button>
                                </Box>

                                {/* Recipient Chips */}
                                <Box className={cx(classes.RecipientChipsContainer)}>
                                    {threshold.recipients.map((recipient, index) => (
                                        <Box key={index} className={cx(classes.RecipientChip)}>
                                            <Typography variant='body2'>{recipient}</Typography>
                                            {!readOnly && (
                                                <StyledToolTip title='Remove recipient'>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => handleRemoveRecipient(threshold.id, index)}
                                                        className={cx(classes.RemoveRecipientButton)}
                                                    >
                                                        <CloseIcon fontSize='small' />
                                                    </IconButton>
                                                </StyledToolTip>
                                            )}
                                        </Box>
                                    ))}
                                </Box>

                                {/* Threshold Actions */}
                                <Box className={cx(classes.ThresholdCard)}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={threshold.enabled}
                                                onChange={() => handleToggleThreshold(threshold.id)}
                                                disabled={readOnly}
                                            />
                                        }
                                        label='Enable Threshold'
                                    />
                                    {!readOnly && thresholds.length > 1 && (
                                        <StyledToolTip title='Remove threshold'>
                                            <IconButton
                                                onClick={() => handleRemoveThreshold(threshold.id)}
                                                size='small'
                                                className={cx(classes.RemoveThresholdButton)}
                                            >
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </StyledToolTip>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>

                {!readOnly && (
                    <Button startIcon={<AddIcon />} onClick={handleAddThreshold} className={cx(classes.AddThresholdButton)}>
                        Add Another Threshold
                    </Button>
                )}
            </DialogContent>

            <DialogActions className={cx(classes.DialogActions)}>
                <BlackOnWhiteButton onClick={onClose}>Cancel</BlackOnWhiteButton>
                <Button onClick={handleSave} variant='contained' color='primary' disabled={readOnly}>
                    Save Thresholds
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const useStyles = customMakeStyles<IBudgetThresholdModalProps>()((theme, props) => ({
    DialogPaper: {
        borderRadius: theme.shape.borderRadius * 2,
        boxShadow: theme.shadows[24],
    },
    DialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingBottom: theme.spacing(2),
    },
    CloseButton: {
        color: theme.palette.text.secondary,
    },
    DialogContent: {
        paddingTop: theme.spacing(3),
    },
    BudgetTitle: {
        marginBottom: theme.spacing(2),
    },
    Description: {
        marginBottom: theme.spacing(3),
    },
    ThresholdCard: {
        position: 'relative',
        border: `1px solid ${theme.palette.divider}`,
    },
    ThresholdTypeContainer: {
        display: 'flex',
        flexDirection: 'column',
        [`${theme.breakpoints.up('sm')}`]: {
            flexDirection: 'row',
        },
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    NotificationMethod: {
        marginBottom: theme.spacing(2),
    },
    RecipientInputContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
    },
    RecipientInput: {
        marginRight: theme.spacing(1),
    },
    RecipientChipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    RecipientChip: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.action.hover,
    },
    RemoveRecipientButton: {
        marginLeft: theme.spacing(0.5),
    },
    ThresholdActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    RemoveThresholdButton: {
        color: theme.palette.error.main,
    },
    AddThresholdButton: {
        marginTop: theme.spacing(2),
    },
    DialogActions: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export { BudgetThresholdModal };
